import { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    InputGroup,
    Button,
    FormControl,
    Form,
} from "react-bootstrap";
import EventService from "service/eventService";
import { getDeviceListByGatewayId } from "service/gatewayService";
import DefaultModal from "components/modals/ModalTemplate";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import FormDeviceValueControl from "components/form-control/FormDeviceValueControl";
import { InputNumberPatterns, SensorDataType } from "constant";
import "assets/css/event.css";
import { EventCondtionSensorOperator } from "generated/models";
import {
    displaySensorType,
    handleAccuracyException,
    isHttpSuccess,
} from "utils/functions";
import { convertBEOperator } from "utils/eventFunctions";

const CONST_UNKNOWN_SENSOR = "Unknown sensor";
const singleFieldOption: string[] = [">", "<", "==", "===", ">=", "<="];
const doubleFieldOption: string[] = ["in", "not in", "in=", "not in="];
const NoteText: Record<string, string> = {
    ">=": "This condition will trigger when the sensor value changes from below {value}{unit} to above {value}{unit} and remains there for {duration} seconds.",
    "<=": "This condition will trigger when the sensor value changes from above {value}{unit} to below {value}{unit} and remains there for {duration} seconds.",
    ">": "When the sensor value changes from below {value}{unit} to above {value}{unit} and remains there for {duration} seconds, this condition will trigger every {interval} seconds until the sensor value drops below {value}{unit}.",
    "<": "When the sensor value changes from above {value}{unit} to below {value}{unit} and remains there for {duration} seconds, this condition will trigger every {interval} seconds until the sensor value rises above {value}{unit}.",
    "===": "This condition will trigger when the sensor value changes from {boolean1} to {boolean2} and remains there for {duration} seconds.",
    "==": "When the sensor value changes from {boolean1} to {boolean2} and remains there for {duration} seconds, this condition will trigger every {interval} seconds until the sensor value {action} to {boolean1} again.",
    in: "When the sensor value enters a range from {min}{unit} to {max}{unit} and remains there for {duration} seconds, this condition will trigger every {interval} seconds until the sensor value exits from the range.",
    "not in":
        "When the sensor value exits from a range from {min}{unit} to {max}{unit} and remains there for {duration} seconds, this condition will trigger every {interval} seconds until the sensor value enters the range.",
    "in=": "This condition will trigger when the sensor value enters a range from {min}{unit} to {max}{unit} and remains there for {duration} seconds.",
    "not in=":
        "This condition will trigger when the sensor value exits from a range from {min}{unit} to {max}{unit} and remains there for {duration} seconds.",
};

const SensorValue = () => {
    const location: any = useLocation();
    const history: any = useHistory();
    const params: any = useParams();

    const [modalShow, setModalShow] = useState(false);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const selectedSensorDetails = location?.state?.selectedSensorDetails;
    const selectedOperator = location?.state?.selectedOperator;
    const slotCount = location?.state?.slotCount;
    const selectedSlotIndex = location?.state?.selectedSlotIndex;
    const [selectedSensorOperator, setSelectedSensorOperator] = useState(">=");
    const [singleValue, setSingleValue] = useState("");
    const [dualValue, setDualValue] = useState({
        min: "",
        max: "",
    });
    const [currentSensorDetails, setCurrentSensorDetails]: any = useState(
        selectedSensorDetails || {}
    );
    const [duration, setDuration] = useState("");
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [hasFieldError, setHasFieldError] = useState(false);
    const accuracyException = handleAccuracyException(
        currentSensorDetails.accuracy
    );

    const updateFieldError = () =>
        setHasFieldError(
            document.getElementsByClassName("invalid-input").length > 0
        );

    useEffect(() => {
        // Ensure button is disabled if field is invalid
        document.addEventListener("keyup", () => {
            updateFieldError();
        });
        updateFieldError();
    });

    const fetchDevice = async (
        gatewayId: string,
        ldsuUUID: string,
        said: number
    ) => {
        const devices = await getDeviceListByGatewayId(gatewayId);

        if (devices?.length) {
            const device = devices.find(
                ({
                    gatewayId: gatewayId_,
                    ldsu_uuid,
                    SAID,
                }: {
                    gatewayId: string;
                    ldsu_uuid: string;
                    SAID: number;
                }) =>
                    gatewayId_ === gatewayId &&
                    ldsu_uuid === ldsuUUID &&
                    SAID === said
            );

            const {
                ldsu_uuid,
                SAID,
                bus,
                device_name,
                gatewayName,
                min,
                max,
                mode,
                accuracy,
                unit,
                CLS,
                format,
            } = device ?? {};

            setCurrentSensorDetails({
                gatewayId,
                bus,
                unit,
                ldsuUUID: ldsu_uuid,
                said: SAID,
                name: device_name ?? CONST_UNKNOWN_SENSOR,
                gatewayName: gatewayName ?? CONST_UNKNOWN_SENSOR,
                min: min ?? mode?.[0]?.MIN,
                max: max ?? mode?.[0]?.MAX,
                accuracy: accuracy ?? mode?.[0]?.ACCURACY,
                type: displaySensorType(device),
                cls: CLS,
                format
            });

            if (SensorDataType.Boolean === format && !params.conditionId) {
                setSelectedSensorOperator("===");
                setSingleValue("0=");
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (params.conditionId) {
                return;
            }
            setIsPageLoading(true);
            await fetchDevice(
                currentSensorDetails.gatewayId,
                currentSensorDetails.ldsuUUID,
                currentSensorDetails.said
            );
            setIsPageLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentSensorDetails.gatewayId,
        currentSensorDetails.ldsuUUID,
        currentSensorDetails.said,
        params.conditionId,
    ]);

    useEffect(() => {
        if (params.conditionId) {
            setIsPageLoading(true);
            (async () => {
                const conditionResponse = await EventService.readOneCondition(
                    params.conditionId,
                    params.eventId
                );
                if (isHttpSuccess(conditionResponse.status)) {
                    const {
                        resource: {
                            device: {
                                device_id,
                                for_,
                                gateway_id,
                                operator,
                                said,
                                thresholds,
                                edge_trigger,
                            },
                        },
                    } = conditionResponse?.data ?? {};

                    setSelectedSensorOperator(
                        convertBEOperator(operator, edge_trigger)
                    );
                    if (doubleFieldOption.includes(operator)) {
                        setDualValue({
                            ...dualValue,
                            min: Number(thresholds[0]).toFixed(
                                accuracyException
                            ),
                            max: Number(thresholds[1]).toFixed(
                                accuracyException
                            ),
                        });
                    } else {
                        const singleValue = `${thresholds[0]}${
                            edge_trigger && currentSensorDetails.format === SensorDataType.Boolean ? "=" : ""
                        }`;
                        setSingleValue(singleValue);
                    }
                    setDuration(for_);

                    await fetchDevice(gateway_id, device_id, said);
                }
                setIsPageLoading(false);
            })();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.conditionId, accuracyException]);

    const validateFields = () => {
        const isValid = true;
        let errorMsg = {
            msg: "",
            field: "",
        };

        // 1. Empty value
        switch (selectedSensorOperator) {
            case ">":
            case "<":
                if (!InputNumberPatterns.float.test(singleValue)) {
                    errorMsg = {
                        msg: "",
                        field: "value",
                    };
                    return { isValid: false, errorMsg };
                }
                break;
            case "in":
            case "not in":
            case "in=":
            case "not in=":
                if (
                    !InputNumberPatterns.float.test(dualValue.min) ||
                    !InputNumberPatterns.float.test(dualValue.max)
                ) {
                    errorMsg = {
                        msg: "",
                        field: "value",
                    };
                    return { isValid: false, errorMsg };
                }
                break;
        }

        // 2. Range
        const { min, max } = currentSensorDetails;

        if (
            doubleFieldOption.indexOf(selectedSensorOperator) >= 0 &&
            !(
                min <= Number(dualValue.max) &&
                Number(dualValue.max) <= max &&
                min <= Number(dualValue.min) &&
                Number(dualValue.min) <= max
            )
        ) {
            errorMsg = {
                msg: ``,
                field: "value",
            };
            return { isValid: false, errorMsg };
        }

        switch (selectedSensorOperator) {
            case ">":
            case "<":
            case ">=":
            case "<=":
                if (
                    Number(singleValue) < Number(min) ||
                    Number(singleValue) > Number(max)
                ) {
                    errorMsg = {
                        msg: ``,
                        field: "value",
                    };
                    return { isValid: false, errorMsg };
                }
                break;
            case "in":
            case "in=":
                if (Number(dualValue.max) <= Number(dualValue.min)) {
                    errorMsg = {
                        msg: `The second value in range can't be equal or less than the first value.`,
                        field: "value",
                    };
                    return { isValid: false, errorMsg };
                }
                break;
            case "not in":
            case "not in=":
                if (
                    Number(dualValue.min) < Number(min) ||
                    Number(dualValue.min) > Number(max) ||
                    Number(dualValue.max) < Number(min) ||
                    Number(dualValue.max) > Number(max)
                ) {
                    errorMsg = {
                        msg: ``,
                        field: "value",
                    };
                    return { isValid: false, errorMsg };
                }
                if (Number(dualValue.max) <= Number(dualValue.min)) {
                    errorMsg = {
                        msg: `The second value in range can't be equal or less than the first value.`,
                        field: "value",
                    };
                    return { isValid: false, errorMsg };
                }
                break;
        }

        // 3. Duration
        if (
            Number(duration) < 5 ||
            Number(duration) > 3600 ||
            Number(duration) % 5 !== 0
        ) {
            errorMsg = {
                msg: "Duration must be a multiple of 5 and between 5 - 3600s.",
                field: "duration",
            };
            return { isValid: false, errorMsg };
        }

        return { isValid, errorMsg };
    };
    const inValidObj = validateFields();

    const renderErrorMessage = (field: any = "") => {
        if (field === inValidObj.errorMsg.field) {
            return <p className="text-danger">{inValidObj.errorMsg.msg}</p>;
        }
    };

    const handleAddUpdateCondition = async () => {
        let response: any;

        const { isValid } = validateFields();
        if (!isValid) {
            return;
        }

        let slot = 0;
        if (selectedOperator === "AND") {
            slot = selectedSlotIndex;
        } else if (selectedOperator === "OR") {
            slot = slotCount;
        }

        let value: any;

        if (singleFieldOption.includes(selectedSensorOperator)) {
            value = [Number(singleValue.replace("=", ""))];
        } else if (doubleFieldOption.includes(selectedSensorOperator)) {
            value = [Number(dualValue.min), Number(dualValue.max)];
        }

        let edgeTrigger = false;
        let operator = selectedSensorOperator;
        if (
            [">=", "<=", "in=", "not in=", "==="].includes(
                selectedSensorOperator
            )
        ) {
            operator = operator.replace("=", "");
            edgeTrigger = true;
        }

        if (!params.conditionId) {
            // To CREATE sensor value condition
            response = await EventService.createCondition(params.eventId, {
                slot: slot,
                type_: "SENSOR",
                resource: {
                    device: {
                        gateway_id: currentSensorDetails.gatewayId,
                        device_id: currentSensorDetails.ldsuUUID,
                        said: currentSensorDetails.said,
                        for_: Number(duration),
                        operator: operator as EventCondtionSensorOperator,
                        thresholds: value,
                        edge_trigger: edgeTrigger,
                    },
                },
            });
        } else {
            const { gatewayId, said, ldsuUUID } = currentSensorDetails;

            response = await EventService.updateCondition(
                params.eventId,
                params.conditionId,
                {
                    slot: currentSensorDetails.slot,
                    type_: "SENSOR",
                    resource: {
                        device: {
                            gateway_id: gatewayId,
                            device_id: ldsuUUID,
                            said: said,
                            for_: Number(duration),
                            operator: operator as EventCondtionSensorOperator,
                            thresholds: value,
                            edge_trigger: edgeTrigger,
                        },
                    },
                }
            );
        }

        if (isHttpSuccess(response.status)) {
            closeCondition();
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const closeCondition = () => {
        history.push(`/event-details/${params.eventId}`);
    };

    const renderBinaryValue = () => {
        return (
            <InputGroup className="mt-2">
                <div className="flex-fill">
                    <Form.Control
                        as="select"
                        custom
                        aria-label="binary"
                        value={singleValue}
                        onChange={(e: any) => {
                            const value: string = e.target.value;
                            setSingleValue(e.target.value);
                            setSelectedSensorOperator(
                                value.includes("=") ? "===" : "=="
                            );
                        }}
                    >
                        <option value="0">Is equal to 0</option>
                        <option value="1">Is equal to 1</option>
                        <option value="0=">Changes to 0</option>
                        <option value="1=">Changes to 1</option>
                    </Form.Control>
                </div>
            </InputGroup>
        );
    };

    const renderValue = () => {
        if (currentSensorDetails.format === SensorDataType.Boolean) {
            return renderBinaryValue();
        }

        return (
            <>
                <Form.Control
                    as="select"
                    custom
                    defaultValue={selectedSensorOperator}
                    value={selectedSensorOperator}
                    onChange={(e: any) => {
                        setSelectedSensorOperator(e.target.value);
                    }}
                >
                    <option value=">=">Rises above</option>
                    <option value="<=">Falls below</option>
                    <option value=">">Is above</option>
                    <option value="<">Is below</option>
                    <option value="not in=">Exits from a range</option>
                    <option value="in=">Enters a range</option>
                    <option value="in">Is within a range</option>
                    <option value="not in">Is outside a range</option>
                </Form.Control>

                {renderValueField()}
                {renderErrorMessage("value")}
            </>
        );
    };

    const renderValueField = () => {
        const { format, min, max } = currentSensorDetails;
        if (singleFieldOption.includes(selectedSensorOperator)) {
            return (
                <InputGroup className="mt-2">
                    <div className="flex-fill">
                        <FormDeviceValueControl
                            value={singleValue}
                            {...{
                                format,
                                accuracy: accuracyException,
                                min,
                                max,
                            }}
                            onChange={(e: any) => {
                                setSingleValue(e.target.value);
                            }}
                        />
                    </div>

                    {renderSensorUnit() && (
                        <InputGroup.Text className="unit-container">
                            {renderSensorUnit()}
                        </InputGroup.Text>
                    )}
                </InputGroup>
            );
        } else if (doubleFieldOption.includes(selectedSensorOperator)) {
            return (
                <Form.Row>
                    <Col md={5} sm={12}>
                        <InputGroup className="mt-2">
                            <div className="flex-fill">
                                <FormDeviceValueControl
                                    value={dualValue.min}
                                    {...{
                                        format,
                                        accuracy: accuracyException,
                                        min,
                                        max,
                                    }}
                                    onChange={(e: any) => {
                                        setDualValue({
                                            ...dualValue,
                                            min: e.target.value,
                                        });
                                    }}
                                />
                            </div>

                            {renderSensorUnit() && (
                                <InputGroup.Text className="unit-container">
                                    {renderSensorUnit()}
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </Col>
                    <Col md={2} sm={1} className="mt-1">
                        <InputGroup className="mt-1 justify-content-sm-around">
                            <InputGroup.Text>to</InputGroup.Text>
                        </InputGroup>
                    </Col>
                    <Col md={5} sm={12}>
                        <InputGroup className="mt-2">
                            <div className="flex-fill">
                                <FormDeviceValueControl
                                    value={dualValue.max}
                                    {...{
                                        format,
                                        accuracy: accuracyException,
                                        min,
                                        max,
                                    }}
                                    onChange={(e: any) => {
                                        setDualValue({
                                            ...dualValue,
                                            max: e.target.value,
                                        });
                                    }}
                                />
                            </div>

                            {renderSensorUnit() && (
                                <InputGroup.Text className="unit-container">
                                    {renderSensorUnit()}
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </Col>
                </Form.Row>
            );
        }
    };

    const renderSensorInfo = () => {
        const { name, gatewayName, bus, ldsuUUID, type } =
            currentSensorDetails || {};

        return (
            <div className="form-box mb-3">
                <h5 className="mb-4">When Sensor</h5>
                <div className="action-device-details">
                    <h6>{name}</h6>
                    <p>
                        {gatewayName} - LDS BUS {bus}
                    </p>
                    <p>LDSU UUID: {ldsuUUID}</p>
                    <p>{type}</p>
                </div>
            </div>
        );
    };

    const renderSensorUnit = () => {
        if (currentSensorDetails?.unit === "C") {
            return `°C`;
        }
        if (currentSensorDetails?.unit === "na") {
            return "";
        } else {
            return currentSensorDetails?.unit || "";
        }
    };

    const getNoteText = () => {
        if (singleFieldOption.includes(selectedSensorOperator)) {
            const value = Number(singleValue.replace("=", ""));
            return NoteText[selectedSensorOperator].fill({
                value: value,
                duration,
                unit: renderSensorUnit(),
                boolean1: Number(!Number(value)),
                boolean2: value,
                interval: 5,
                action: value ? "drops" : "rises",
            });
        }

        return NoteText[selectedSensorOperator].fill({
            min: dualValue.min,
            max: dualValue.max,
            duration,
            unit: renderSensorUnit(),
            interval: 5,
        });
    };

    return (
        <ContentWrapper
            simpleModalData={simpleModalData}
            isLoading={isPageLoading}
        >
            <Container>
                <Row>
                    <Col sm="12" className="event-detail-head">
                        <h5 className="page-title overflow-text">
                            Sensor Value
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Breadcrumb className="w-100">
                            <Breadcrumb.Item>
                                <Link to="/events">Events</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/event-details/${params.eventId}`}>
                                    Event Details
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Condition</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="action-detail-box">
                    <Col>
                        {renderSensorInfo()}
                        <div className="form-box mb-3">
                            <h5 className="mb-4">Value</h5>
                            {renderValue()}
                        </div>
                        <div className="form-box mb-3">
                            <h5 className="mb-4">Last for (Duration)</h5>
                            <InputGroup className="mt-2 ">
                                <div className="flex-fill">
                                    <FormControl
                                        defaultValue=""
                                        value={duration}
                                        isInvalid={
                                            !inValidObj.isValid &&
                                            inValidObj.errorMsg?.field ===
                                                "duration"
                                        }
                                        onChange={(e: any) => {
                                            setDuration(e.target.value);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {renderErrorMessage("duration")}
                                    </Form.Control.Feedback>
                                </div>
                                <div>
                                    <InputGroup.Text>sec(s)</InputGroup.Text>
                                </div>
                            </InputGroup>
                        </div>

                        <div className="form-box mb-3">
                            <h5 className="mb-3">Note:</h5>
                            {inValidObj.isValid && (
                                <p className="reusable-description">
                                    {getNoteText()}
                                </p>
                            )}
                        </div>
                        <Button
                            variant="secondary"
                            className="mr-2 default-button-width"
                            onClick={closeCondition}
                        >
                            CANCEL
                        </Button>

                        <Button
                            variant="primary"
                            className="pl-4 pr-4 default-button-width"
                            onClick={handleAddUpdateCondition}
                            disabled={
                                hasFieldError || !validateFields().isValid
                            }
                        >
                            {params.conditionId ? "UPDATE" : "ADD"}
                        </Button>
                    </Col>
                </Row>
            </Container>
            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                okAction={() => {
                    setModalShow(false);
                }}
            />
        </ContentWrapper>
    );
};

export default SensorValue;
