import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

interface State {
    hasError: boolean;
    pathname?: string;
}

class ErrorBoundary extends Component<React.PropsWithChildren<any>, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromProps(
        props: React.PropsWithChildren<any>,
        state: State
    ) {
        if (window.location.pathname !== state.pathname) {
            return { hasError: false, pathname: window.location.pathname };
        }

        return {};
    }

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true, pathname: window.location.pathname };
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col sm="12" className="mt-2">
                                <div className="empty-widget p-5">
                                    <h3>Something went wrong.</h3>
                                    <p>
                                        If the problem persists, please contact
                                        support at{" "}
                                        <a
                                            target="_blank"
                                            href="https://brtsys.com/iotportal/"
                                            rel="noreferrer"
                                        >
                                            https://brtsys.com/iotportal/
                                        </a>
                                    </p>
                                    <Button
                                        className="mt-2 mb-2"
                                        onClick={() => {
                                            window.location.reload();
                                        }}
                                    >
                                        Reload
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
