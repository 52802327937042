export const updateFormFields = (e: any, formValues: any, setFormValues: Function) =>
    setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
    });

export const getGeneralColProps = (value: number)=>{
    return ({
        xs: 24,
        sm: value,
        md: value,
        lg: value
    })
}
