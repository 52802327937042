import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Button, Form } from "react-bootstrap";
import { updateActuatorCommand } from "service/gatewayService";

import Pulse from "./Pulse";
import Level from "./Level";
import Loop from "./Loop";
import AnalogConfig from "./AnalogConfig";
import {
    ACTUATOR_CONFIG_SUCCESS_MESSAGE,
    ACTUATOR_INVALID_MESSAGE,
    LEVEL_MODE,
    LOOP_MODE,
    MaxActuatorLimit,
    MAX_LOOP_LIMIT,
    NUM_OF_LOOPS_ERROR_MESSAGE,
    PULSE_MODE,
    PWM_MODE,
    TRY_AGAIN_ERROR_MESSAGE,
} from "constant";

import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import PWM from "./PWM";
import ControlOptions from "./ControlOptions";
import ModeOptions from "./ModeOptions";
import { getActuatorConfigTemplate } from "utils/actuatorFunctions";
import {
    getAPIError,
    showErrorAlert,
    showInfoAlert,
    showSuccessAlert,
} from "utils/alert";
import { isHttpSuccess } from "utils/functions";
import { get } from "lodash";

type Timing = Array<[number, number]>;

export type Actuator = {
    name: string;
    mode: string;
    auto: boolean;
    polarity?: boolean;
    deactivate?: string;
    cycle_count?: number;
    timing?: Timing | undefined;
    activate_value?: number;
    deactivate_value?: number;
};

type isIOControllerState = {
    isIOController: boolean;
    isAnalog: boolean;
    minmax: string[];
    unit: string;
    mode: string[];
};

export interface modeConfigs {
    [key: string]: {};
}

const ActuatorConfiguration = () => {
    const {
        gatewayId,
        ldsuId: deviceId,
        said,
    }: { gatewayId: string; ldsuId: string; said: string } = useParams();

    const {
        setActuator,
        setEditedActuator,
        editedActuator,
        editedActuator: {
            auto,
            mode,
            cycle_count,
            timing,
            activate_value,
            deactivate_value,
        },
        isIOControllerState,
        editMode,
    }: {
        actuator: Actuator;
        setActuator: React.Dispatch<React.SetStateAction<Actuator>>;
        setEditedActuator: React.Dispatch<React.SetStateAction<Actuator>>;
        editedActuator: Actuator;
        isIOControllerState: isIOControllerState;
        editMode: boolean;
    } = useActuatorConfigurationContext();

    const { minmax, unit, isAnalog } = isIOControllerState || {};

    const [error, setError] = useState({
        hasError: false,
        message: "",
    });

    useEffect(() => {
        if (editMode) {
            toast(
                <div>
                    <b className="title">Info</b>
                    <p className="description">
                        Configuration needs to be saved before it can be
                        activated/deactivated.
                    </p>
                </div>,
                {
                    type: "info",
                    theme: "light",
                    hideProgressBar: true,
                }
            );
        }
    }, [editMode]);

    const activationInfoReminder = () => {
        return showInfoAlert({
            message: (
                <>
                    To activate new configuration, click on{" "}
                    <span className="font-weight-bold text-primary-black-1">
                        ACTIVATE
                    </span>{" "}
                    button. The actuator will be activated after the completion
                    of any on-going activation.
                </>
            ),
        });
    };

    const validateForm = () => {
        const errorBody = {
            hasError: false,
            message: "",
        };

        if (mode === PULSE_MODE) {
            const t1 = get(timing, "[0][0]");
            const t2 = get(timing, "[0][1]");

            if (
                !t1 ||
                !t2 ||
                t1 > MaxActuatorLimit ||
                t2 > MaxActuatorLimit ||
                Number(t1 + t2) > MaxActuatorLimit
            ) {
                errorBody.hasError = true;
                errorBody.message = ACTUATOR_INVALID_MESSAGE;
            }
        } else if (mode === LOOP_MODE) {
            const t1 = get(timing, "[0][0]");
            const t2 = get(timing, "[0][1]");
            if (!t1 || !t2 || Number(t1 + t2) > MaxActuatorLimit) {
                errorBody.hasError = true;
                errorBody.message = ACTUATOR_INVALID_MESSAGE;
            } else if (
                cycle_count &&
                (cycle_count < 0 || cycle_count > MAX_LOOP_LIMIT)
            ) {
                errorBody.hasError = true;
                errorBody.message = NUM_OF_LOOPS_ERROR_MESSAGE;
            }
        } else if (isAnalog && activate_value && deactivate_value) {
            if (
                activate_value > Number(minmax[1]) ||
                activate_value < Number(minmax[0]) ||
                deactivate_value > Number(minmax[1]) ||
                deactivate_value < Number(minmax[0])
            ) {
                errorBody.hasError = true;
                errorBody.message = `Unable to save. Please ensure Activation/Deactivation Voltage is between ${minmax[0]}${unit} and ${minmax[1]}${unit}.`;
            }
        } else if (mode === PWM_MODE && (cycle_count || 0) >= 0) {
            timing?.forEach((pair) => {
                const t1 = pair?.[0];
                const t2 = pair?.[1];

                if (!t1 || !t2 || Number(t1 + t2) > MaxActuatorLimit) {
                    errorBody.hasError = true;
                    errorBody.message = ACTUATOR_INVALID_MESSAGE;
                }
            });

            if (
                cycle_count &&
                (cycle_count < 0 || cycle_count > MAX_LOOP_LIMIT)
            ) {
                errorBody.hasError = true;
                errorBody.message = NUM_OF_LOOPS_ERROR_MESSAGE;
            }
        }

        return errorBody;
    };

    const saveConfig = async () => {
        const validate = validateForm();

        if (validate.hasError) {
            setError(validate);
            return;
        }

        const body: any = getActuatorConfigTemplate(editedActuator);

        const saveConfigRes: any = await updateActuatorCommand(
            gatewayId,
            deviceId,
            said,
            body
        );

        if (isHttpSuccess(saveConfigRes.status)) {
            const { data } = saveConfigRes;
            const edited = { ...editedActuator, ...data };
            setActuator(edited);
            setEditedActuator(edited);
            showSuccessAlert({
                message: ACTUATOR_CONFIG_SUCCESS_MESSAGE,
            });
            setError({ hasError: false, message: "" });

            if (!auto) activationInfoReminder();
        } else {
            showErrorAlert(getAPIError(saveConfigRes, TRY_AGAIN_ERROR_MESSAGE));
        }
    };

    const renderMode = () => {
        // this function will only run if it's sensor actuator or digital output actuator for iocontroller
        switch (mode) {
            case LEVEL_MODE:
                return <Level />;
            case PULSE_MODE:
                return <Pulse />;
            case LOOP_MODE:
                return <Loop />;
            case PWM_MODE:
                return <PWM />;

            // for IO controller
            default:
                // if digital output, return Level
                return <Level />;
        }
    };

    return (
        <Col lg={8} xs={12}>
            <Form>
                <fieldset>
                    <div className="form-box-head pb-4">
                        <h5 className="float-left">Configuration</h5>
                    </div>
                    <div className="form-box">
                        <ControlOptions />

                        <ModeOptions />

                        {isIOControllerState.mode ||
                        !isIOControllerState.isIOController ||
                        (isIOControllerState.isIOController && !isAnalog) ? (
                            renderMode()
                        ) : (
                            <AnalogConfig />
                        )}
                    </div>
                    <div className="form-box-footer">
                        {error.hasError ? (
                            <p className="text-danger mb-3" role="alert">
                                {error.message}
                            </p>
                        ) : (
                            ""
                        )}
                        <HoverAuthorizeTooltip permission="gateway:update">
                            <Button
                                variant="primary"
                                onClick={saveConfig}
                                disabled={!editMode}
                            >
                                SAVE
                            </Button>
                        </HoverAuthorizeTooltip>
                    </div>
                </fieldset>
            </Form>
        </Col>
    );
};

export default ActuatorConfiguration;
