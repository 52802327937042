import styled from "@emotion/styled";
import { Form } from "react-bootstrap";

export const PowerCycleCheckBox = styled(Form.Check)`
    color: #000000;

    .custom-control-label:before {
        background-color: #dddddd;
        border-color: #dddddd;
    }
`;