import { Patterns } from "constant";
import { get } from "lodash";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { Col, Button, Form } from "react-bootstrap";
import authService from "service/authenticationService";
import { isHttpSuccess } from "utils/functions";
import { Messages } from "components/authentication/SignUpForm";

type ForgotPasswordFormProps = {
    onSuccess: (email: string) => void;
};

const ForgotPasswordForm = ({ onSuccess }: ForgotPasswordFormProps) => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async () => {
        if (email.length > 128) {
            setErrorMessage(Messages.emailLength);
            return;
        }

        if (!Patterns.emailPattern.test(email)) {
            setErrorMessage(Messages.email);
            return;
        }

        setIsLoading(true);

        const { status, data } = await authService.sendForgotPasswordEmail(
            email
        );

        if (isHttpSuccess(status)) {
            setErrorMessage("");
            onSuccess(email);
        } else {
            setErrorMessage(
                get(
                    data,
                    "description",
                    "Incorrect email or email doesn't exist"
                )
            );
        }

        setIsLoading(false);
    };

    const renderButton = () => {
        return (
            <>
                {isLoading && <Button>Loading...</Button>}
                {!isLoading && (
                    <Button onClick={handleSubmit}>RESET PASSWORD</Button>
                )}
            </>
        );
    };

    const onKeyDownHandler = (e: KeyboardEvent<HTMLFormElement>) => {
        const keyCode = get(e, "which", e.key);
        if (keyCode === 13) {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <Col lg="6" sm="12" className="login-form">
            <div className="login-box">
                <div className="login-table">
                    <div className="login-text">
                        <h4>Forgot Password</h4>
                    </div>
                    <div className="mt-4 mb-5">
                        Enter the email address you signed up with
                    </div>
                    <Form name="forgot" onKeyDown={onKeyDownHandler}>
                        <Form.Group className="mb-4">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                required
                                aria-label="email"
                                value={email}
                                placeholder="Enter email"
                                onChange={handleEmailChange}
                            />
                        </Form.Group>
                        {renderButton()}
                        <div className="loginf">
                            {errorMessage && <p role="alert">{errorMessage}</p>}
                        </div>
                    </Form>
                </div>
            </div>
        </Col>
    );
};

export default ForgotPasswordForm;
