import { Col, Form, Button } from "react-bootstrap";
import { useEffect } from "react";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import MinuteSecondPicker, {
    MinuteSecondPickerProps,
} from "components/common/MinuteSecondPicker";
import { Actuator } from "./ActuatorConfiguration";
import { useParams } from "react-router-dom";

const defaultPairing: any = [
    [0, 0],
    [0, 0],
];

const CyclesInput = () => {
    const {
        editedActuator,
        editedActuator: { timing },
        setEditedActuator,
    } = useActuatorConfigurationContext();

    const params: any = useParams();
    const { ldsuId } = params;

    useEffect(() => {
        setEditedActuator((prevState: Actuator) => ({
            ...prevState,
            timing: timing?.length >= 2 ? timing : defaultPairing,
        }));
    }, [timing, setEditedActuator]);

    const addPair = () => {
        const updatedTiming = [...timing, [0, 0]];
        setEditedActuator((prevState: Actuator) => ({
            ...prevState,
            timing: updatedTiming,
        }));
    };

    const removePair = (i: number) => {
        const updatedTiming = timing.filter(
            (pair: any, index: number) => i !== index
        );

        setEditedActuator((prevState: Actuator) => ({
            ...prevState,
            timing: updatedTiming,
        }));
    };

    const minuteSecondPickerProps: MinuteSecondPickerProps = {
        editedActuator,
        setEditedActuator,
        hasCycles: true,
        cycleIndex: 0,
    };

    const renderPairs = (pairs: any) => {
        if (pairs) {
            return pairs.map((p: any, index: number) => {
                const i = index + 1;

                return (
                    <Form.Row className="mt-4 mb-1" key={`${ldsuId}-${index}`}>
                        <MinuteSecondPicker
                            {...{
                                ...minuteSecondPickerProps,
                                cycleIndex: i,
                            }}
                        />

                        <Col md={1}>
                            <div
                                className="btn-remove"
                                onClick={() => removePair(index)}
                                onKeyDown={() => removePair(index)}
                                hidden={pairs.length <= 2}
                            >
                                <span className="material-icons ">close</span>
                            </div>
                        </Col>
                    </Form.Row>
                );
            });
        }
    };
    return (
        <>
            {renderPairs(timing)}
            <Form.Row className="mt-5 mb-5">
                <Col>
                    <Button
                        className="mr-3"
                        onClick={addPair}
                        disabled={(timing || defaultPairing).length >= 8}
                    >
                        More +
                    </Button>{" "}
                    (Maximum 8 pairs)
                </Col>
            </Form.Row>
        </>
    );
};

export default CyclesInput;
