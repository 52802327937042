import { useContext, useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Tab, Nav, Button, Modal } from "react-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";

import MembersList from "components/organizations/MemberList";
import GroupsList from "components/organizations/GroupsList";
import RolesList from "components/organizations/RoleList";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";

import OrganisationService from "service/organisationService";
import RolesService from "service/rolesService";
import UserGroupsService from "service/userGroupsService";
import { AppContext } from "context/appContext";
import {
    storeOrgMembersAction,
    storeOrgGroupsAction,
    storeOrgRolesAction,
    storeOgrActiveTabAction,
    changeOrganizationAction,
    storeOrganizationAction,
} from "store/actions";
import { EventKey, HttpStatus } from "constant";
import LocalStorageService from "service/localStorageService";
import dltWhiteIcon from "assets/svg/delete-white.svg";
import editIcon from "assets/svg/edit.svg";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { get } from "lodash";
import { OrganisationDto } from "types";
import { getOrgOwnerName } from "utils/organisationFunction";
import { canAccess } from "utils/authorize-action";
import { isHttpSuccess } from "utils/functions";
import { getAPIError, showErrorAlert } from "utils/alert";
import { FETCH_ORGANIZATION_FAIL_MESSAGE } from "constant";

const OrganizationDetails = () => {
    const { storeData, storeDispatchActions } = useContext(AppContext);
    const params: any = useParams();
    const history = useHistory();
    const { organization, user } = storeData;
    const { currentOrgId, currentActiveTab, currentOrgInfo, orgList } =
        organization;
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [orgInfo, setOrgInfo] = useState<OrganisationDto>(currentOrgInfo);
    const orgId = params.orgId || currentOrgId;
    const { userData } = user;
    const [numberOfMembers, setNumberOfMembers] = useState(0);
    const [numberOfGroups, setNumberOfGroups] = useState(0);
    const [numberOfRoles, setNumberOfRoles] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [modalType, setModalType] = useState("");
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const fetchOrg = async () => {
        const fetchResponse = await OrganisationService.getOrgById(orgId);
        if (fetchResponse.status === HttpStatus.OK) {
            const org = toCamelCaseObject<OrganisationDto>(fetchResponse.data);

            setOrgInfo(org);

            setNumberOfMembers(org.members.length);

            setNumberOfGroups(org.groups.length);

            setNumberOfRoles(org.roles.length);

            storeDispatchActions(
                storeOrgMembersAction({
                    orgMembers: org.members,
                })
            );

            storeDispatchActions(
                storeOrgGroupsAction({
                    orgGroups: org.groups,
                })
            );

            storeDispatchActions(
                storeOrgRolesAction({
                    orgRoles: org.roles,
                })
            );
        } else if (fetchResponse.status === HttpStatus.FORBIDDEN) {
            setIsForbiddenResource(true);
        } else if (!isHttpSuccess(fetchResponse.status)) {
            showErrorAlert(
                getAPIError(fetchResponse, FETCH_ORGANIZATION_FAIL_MESSAGE)
            );
        }

        setIsPageLoading(false);
    };

    useEffect(() => {
        if (canAccess("organisation:read")) {
            fetchOrg();
        } else {
            setIsForbiddenResource(true);
            setIsPageLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    const handleDeleteMember = async (members: string[]) => {
        const res = await OrganisationService.deleteMembers(
            currentOrgId,
            members
        );
        if (res.status === HttpStatus.NO_CONTENT) {
            await fetchOrg();
        }
        return res;
    };

    const handleDeleteRole = async (id: string) => {
        const res = await RolesService.deleteRole(orgId, id);
        if (isHttpSuccess(res.status)) {
            const roleRes = await OrganisationService.getOrgRoles(currentOrgId);
            if (isHttpSuccess(roleRes.status)) {
                storeDispatchActions(
                    storeOrgRolesAction({
                        orgRoles: roleRes.data.toCamelCase(),
                    })
                );

                setNumberOfRoles(roleRes.data.length);
            }
        }

        return res;
    };

    const handleDeleteGroup = async (id: string) => {
        const res = await UserGroupsService.deleteGroup(orgId, id);
        if (isHttpSuccess(res.status)) {
            const groupsRes = await OrganisationService.getOrgGroups(
                currentOrgId
            );
            if (isHttpSuccess(groupsRes.status)) {
                storeDispatchActions(
                    storeOrgGroupsAction({
                        orgGroups: groupsRes.data,
                    })
                );
            }
        }
        return res;
    };

    const generateOrgDisplayName = useCallback(() => {
        let name = get(orgInfo, "name", "_");
        if (orgInfo.isDefault) {
            name = get(userData, "given_name");
        }
        return name;
    }, [orgInfo, userData]);

    const handleTabSelect = (tab: any) => {
        storeDispatchActions(
            storeOgrActiveTabAction({
                tab,
            })
        );
    };

    const generateOrgCreationDate = () => {
        return new Date(orgInfo.createTime).toLocaleDateString("en-GB");
    };

    const alertResourcesWhenDelOrg = (res: any) => {
        setSimpleModalData({
            resObj: res,
            icon: "help",
            renderModalButtons: (setErrModalVisible: any) => {
                return (
                    <>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setErrModalVisible(false);
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="danger"
                            disabled
                            onClick={handleConfirmDelete}
                        >
                            DELETE
                        </Button>
                    </>
                );
            },
        } as SimpleModalDataType);
    };

    const handleConfirmDelete = async () => {
        setModalShow(false);
        const res = await OrganisationService.deleteOrganisation(orgId);
        if (isHttpSuccess(res.status)) {
            setSimpleModalData({
                resObj: res,
            } as SimpleModalDataType);
            // Change state variable to default org
            storeDispatchActions(
                storeOrganizationAction({
                    orgList: orgList.filter(
                        (i) => i.uuid !== currentOrgInfo.uuid
                    ),
                })
            );
            storeDispatchActions(
                changeOrganizationAction({
                    orgInfo: orgList.find((i) => i.isDefault),
                })
            );
            LocalStorageService.setItem("orgId", orgList[0].uuid);
            history.push("/");
        } else {
            alertResourcesWhenDelOrg(res);
        }
    };

    const showConfirmDeletion = async () => {
        setModalType("dlt");
        setModalShow(true);
        setModalContent(generateOrgDisplayName());
    };

    const showConfirmLeave = async () => {
        setSimpleModalData({
            title: null,
            subTitle: "Leave Organisation",
            body: `Are you sure you want to leave this organisation?`,
            icon: "warning",
            renderModalButtons: (setErrModalVisible: any) => {
                return (
                    <>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setErrModalVisible(false);
                            }}
                        >
                            NO
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setSimpleModalData({
                                    title: null,
                                    subTitle: "Loading",
                                    renderModalButtons: null,
                                    renderLoading: true,
                                });
                                handleLeaveOrg();
                            }}
                        >
                            YES
                        </Button>
                    </>
                );
            },
        });
    };

    const handleLeaveOrg = async () => {
        const res = await OrganisationService.leaveOrganisation(orgId);

        if (res.status === HttpStatus.NO_CONTENT) {
            const defaultOrg = orgList.find((i) => i.isDefault);
            LocalStorageService.setItem("orgId", defaultOrg?.uuid);
            await window.loadAuthorizationConfig();
            storeDispatchActions(
                storeOrganizationAction({
                    orgList: orgList.filter(
                        (i) => i.uuid !== currentOrgInfo.uuid
                    ),
                })
            );
            storeDispatchActions(
                changeOrganizationAction({
                    orgInfo: defaultOrg,
                })
            );

            setSimpleModalData({
                title: null,
                subTitle: "Success",
                body: `Successfully left organisation.`,
                icon: "done",
                renderModalButtons: () => {
                    return (
                        <>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    history.push("/");
                                }}
                            >
                                OK
                            </Button>
                        </>
                    );
                },
            });
        } else {
            setSimpleModalData({
                resObj: res,
            });
        }
    };

    const renderModalTitle = () => {
        if (modalType === "err") {
            return <h3 className="mb-3">Error</h3>;
        }
        if (modalType === "dlt") {
            return <h3 className="mb-3">Delete Organisation?</h3>;
        }
        return <h3 className="mb-3">Success</h3>;
    };

    const renderModalIcon = () => {
        if (modalType === "err") {
            return <span className="material-icons">close</span>;
        }
        if (modalType === "dlt") {
            return <span className="material-icons">delete</span>;
        }
        return <span className="material-icons">done</span>;
    };

    const renderModalButtons = () => {
        if (modalType === "dlt") {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        DELETE
                    </Button>
                </>
            );
        }
        if (modalType === "dltok") {
            return (
                <Button
                    variant="primary"
                    onClick={() => {
                        // Push to personal account
                        history.push("/");
                    }}
                >
                    OK
                </Button>
            );
        }
        if (modalType === "err") {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        OK
                    </Button>
                </>
            );
        }
    };

    return (
        <ContentWrapper
            isForbiddenResource={isForbiddenResource}
            title="Organisation"
            isLoading={isPageLoading}
            simpleModalData={simpleModalData}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12} className="org-details-head">
                            <h5 className="page-title">Organisation</h5>

                            <div className="org-detail-btn">
                                <HoverAuthorizeTooltip
                                    permission="organisation:update"
                                    className="org-btn"
                                >
                                    <Link
                                        to={`/edit-organisation/${orgId}`}
                                        className="btn btn-primary"
                                    >
                                        <span>
                                            <img
                                                className="mr-2 ml-1"
                                                src={editIcon}
                                                alt="edit"
                                            />
                                        </span>
                                        Edit
                                    </Link>
                                </HoverAuthorizeTooltip>

                                {orgInfo.owner === userData.email ? (
                                    <HoverAuthorizeTooltip
                                        permission="organisation:delete"
                                        className="org-btn"
                                    >
                                        <Button
                                            variant="danger"
                                            onClick={showConfirmDeletion}
                                        >
                                            <span>
                                                <img
                                                    className="mr-2 ml-2"
                                                    src={dltWhiteIcon}
                                                    alt="delete"
                                                />
                                            </span>
                                            Delete
                                        </Button>
                                    </HoverAuthorizeTooltip>
                                ) : (
                                    <Button
                                        variant="danger"
                                        className="pl-4 org-btn"
                                        onClick={showConfirmLeave}
                                    >
                                        Leave Organisation
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div className="form-box">
                                <Row>
                                    <Col
                                        lg={4}
                                        xs={12}
                                        className="overflow-text"
                                    >
                                        <h4 className="org-name overflow-text">
                                            {generateOrgDisplayName()}
                                        </h4>
                                    </Col>
                                    <Col
                                        lg
                                        xs={12}
                                        className="org-detail-content overflow-text"
                                    >
                                        <h6>Owner</h6>
                                        <h6 className="ginfo overflow-text">
                                            {getOrgOwnerName(orgInfo)}
                                        </h6>
                                    </Col>
                                    <Col
                                        lg
                                        xs={6}
                                        className="org-detail-content"
                                    >
                                        <h6>Created On</h6>
                                        <h6 className="ginfo">
                                            {generateOrgCreationDate()}
                                        </h6>
                                    </Col>
                                    <Col
                                        lg
                                        xs={6}
                                        className="org-detail-content"
                                    >
                                        <h6>Members</h6>
                                        <h6 className="ginfo">
                                            {numberOfMembers || "0"}
                                        </h6>
                                    </Col>
                                    <Col
                                        lg
                                        xs={6}
                                        className="org-detail-content"
                                    >
                                        <h6>Groups</h6>
                                        <h6 className="ginfo">
                                            {numberOfGroups || "0"}
                                        </h6>
                                    </Col>
                                    <Col
                                        lg
                                        xs={6}
                                        className="org-detail-content"
                                    >
                                        <h6>Roles</h6>
                                        <h6 className="ginfo">
                                            {numberOfRoles || "0"}
                                        </h6>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <div className="page-tabs">
                                <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey={currentActiveTab}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Nav>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={
                                                            EventKey.members
                                                        }
                                                        onSelect={
                                                            handleTabSelect
                                                        }
                                                    >
                                                        <h6>
                                                            Members{" "}
                                                            <span className="count">
                                                                {
                                                                    numberOfMembers
                                                                }
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={
                                                            EventKey.groups
                                                        }
                                                        onSelect={
                                                            handleTabSelect
                                                        }
                                                    >
                                                        <h6>
                                                            Groups{" "}
                                                            <span className="count">
                                                                {numberOfGroups}
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={
                                                            EventKey.roles
                                                        }
                                                        onSelect={
                                                            handleTabSelect
                                                        }
                                                    >
                                                        <h6>
                                                            Roles{" "}
                                                            <span className="count">
                                                                {numberOfRoles}
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="members">
                                                    <MembersList
                                                        orgId={currentOrgId}
                                                        membersData={
                                                            organization.currentOrgMembers
                                                        }
                                                        onDelete={
                                                            handleDeleteMember
                                                        }
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="groups">
                                                    <GroupsList
                                                        orgId={currentOrgId}
                                                        groupsData={
                                                            organization.currentOrgGroups
                                                        }
                                                        onDelete={
                                                            handleDeleteGroup
                                                        }
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="roles">
                                                    <RolesList
                                                        orgId={currentOrgId}
                                                        rolesData={
                                                            organization.currentOrgRoles
                                                        }
                                                        onDelete={
                                                            handleDeleteRole
                                                        }
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Confirmation Modal */}
            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    modalType === "dltok" ? "primary" : "danger"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    {modalType === "dlt" ? (
                        <>
                            <p>Are you sure you want to delete</p>
                            <p className="overflow-text font-weight-bold">
                                {modalContent}?
                            </p>
                            <p className="mb-4">
                                By deleting all Groups, Roles and other items
                                related to this organisation will be lost.
                            </p>
                        </>
                    ) : (
                        <p className="mb-4">{modalContent}</p>
                    )}
                    {renderModalButtons()}
                </Modal.Body>
            </Modal>
        </ContentWrapper>
    );
};

export default OrganizationDetails;
