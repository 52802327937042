import React, { BaseSyntheticEvent } from "react";
import Modal from "react-bootstrap/Modal";

export type DashboardCreateModalProps = {
    title?: string;
    show?: boolean;
    footer?: React.FunctionComponent;
    wide?: boolean;
    onHide?: () => void;
    onSave?: (event: Event | BaseSyntheticEvent, data: any) => void;
    [key: string]: any;
};

const DashboardCreateModal: React.FunctionComponent<
    DashboardCreateModalProps
> = (props) => {
    const { title, wide, show, onHide, footer, ...rest } = props;
    const [showModal, setShowModal] = React.useState(show);
    const Footer = footer || (() => <></>);

    React.useEffect(() => {
        setShowModal(show);
    }, [show]);

    return (
        <Modal
            size={wide ? "xl" : undefined}
            show={showModal}
            backdrop="static"
            onHide={() => {
                setShowModal(false);
                onHide && onHide();
            }}
            animation={false}
            centered
            {...rest}
        >
            <Modal.Header closeButton>
                <Modal.Title aria-label="select-gateway-title">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
            <Modal.Footer>
                <Footer />
            </Modal.Footer>
        </Modal>
    );
};

export default DashboardCreateModal;
