/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import {
  CreateMemberGroupData,
  CreateOrganisationData,
  CreateRoleData,
  DeleteMemberGroupByUuidData,
  DeleteOrganisationByUuidData,
  DeleteRoleByUuidData,
  EditMembersData,
  ExitMemberData,
  GetMemberByUuidData,
  GetMemberGroupByUuidData,
  GetMemberGroupsData,
  GetMembersData,
  GetOrganisationByUuidData,
  GetOrganisationsData,
  GetRoleByUuidData,
  GetRolesData,
  MemberGroupPatch2,
  MemberGroupPost,
  MemberPatch2,
  OrganisationPatch,
  OrganisationPost,
  RolePatch,
  RolePost,
  UpdateMemberGroupByUuidData,
  UpdateOrganisationByUuidData,
  UpdateRoleByUuidData,
} from "../models";

export class Organisation<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags organisation
   * @name GetOrganisations
   * @summary Get all organisations
   * @request GET:/organisation
   * @secure
   */
  getOrganisations = (params: RequestParams = {}) =>
    this.http.request<GetOrganisationsData, void>({
      path: `/organisation`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags organisation
   * @name CreateOrganisation
   * @summary Create new organisation
   * @request POST:/organisation
   * @secure
   */
  createOrganisation = (data: OrganisationPost, params: RequestParams = {}) =>
    this.http.request<CreateOrganisationData, void>({
      path: `/organisation`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags organisation
   * @name DeleteOrganisationByUuid
   * @summary Delete organisation by uuid
   * @request DELETE:/organisation/{organisation_uuid}
   * @secure
   */
  deleteOrganisationByUuid = (organisationUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteOrganisationByUuidData, void>({
      path: `/organisation/${organisationUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags organisation
   * @name GetOrganisationByUuid
   * @summary Get organisation info by uuid
   * @request GET:/organisation/{organisation_uuid}
   * @secure
   */
  getOrganisationByUuid = (organisationUuid: string, params: RequestParams = {}) =>
    this.http.request<GetOrganisationByUuidData, void>({
      path: `/organisation/${organisationUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags organisation
   * @name UpdateOrganisationByUuid
   * @summary Update organisation info by uuid
   * @request PATCH:/organisation/{organisation_uuid}
   * @secure
   */
  updateOrganisationByUuid = (organisationUuid: string, data: OrganisationPatch, params: RequestParams = {}) =>
    this.http.request<UpdateOrganisationByUuidData, void>({
      path: `/organisation/${organisationUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags member_group
   * @name GetMemberGroups
   * @summary Get all member groups
   * @request GET:/organisation/{organisation_uuid}/group
   * @secure
   */
  getMemberGroups = (organisationUuid: string, params: RequestParams = {}) =>
    this.http.request<GetMemberGroupsData, void>({
      path: `/organisation/${organisationUuid}/group`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags member_group
   * @name CreateMemberGroup
   * @summary Create new member group
   * @request POST:/organisation/{organisation_uuid}/group
   * @secure
   */
  createMemberGroup = (organisationUuid: string, data: MemberGroupPost, params: RequestParams = {}) =>
    this.http.request<CreateMemberGroupData, void>({
      path: `/organisation/${organisationUuid}/group`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags member_group
   * @name DeleteMemberGroupByUuid
   * @summary Delete member group by uuid
   * @request DELETE:/organisation/{organisation_uuid}/group/{group_uuid}
   * @secure
   */
  deleteMemberGroupByUuid = (organisationUuid: string, groupUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteMemberGroupByUuidData, void>({
      path: `/organisation/${organisationUuid}/group/${groupUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags member_group
   * @name GetMemberGroupByUuid
   * @summary Get member group info by uuid
   * @request GET:/organisation/{organisation_uuid}/group/{group_uuid}
   * @secure
   */
  getMemberGroupByUuid = (organisationUuid: string, groupUuid: string, params: RequestParams = {}) =>
    this.http.request<GetMemberGroupByUuidData, void>({
      path: `/organisation/${organisationUuid}/group/${groupUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags member_group
   * @name UpdateMemberGroupByUuid
   * @summary Update member group info by uuid
   * @request PATCH:/organisation/{organisation_uuid}/group/{group_uuid}
   * @secure
   */
  updateMemberGroupByUuid = (
    organisationUuid: string,
    groupUuid: string,
    data: MemberGroupPatch2,
    params: RequestParams = {},
  ) =>
    this.http.request<UpdateMemberGroupByUuidData, void>({
      path: `/organisation/${organisationUuid}/group/${groupUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags role
   * @name GetRoles
   * @summary Get all roles
   * @request GET:/organisation/{organisation_uuid}/role
   * @secure
   */
  getRoles = (organisationUuid: string, params: RequestParams = {}) =>
    this.http.request<GetRolesData, void>({
      path: `/organisation/${organisationUuid}/role`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags role
   * @name CreateRole
   * @summary Create new role
   * @request POST:/organisation/{organisation_uuid}/role
   * @secure
   */
  createRole = (organisationUuid: string, data: RolePost, params: RequestParams = {}) =>
    this.http.request<CreateRoleData, void>({
      path: `/organisation/${organisationUuid}/role`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags role
   * @name DeleteRoleByUuid
   * @summary Delete role by uuid
   * @request DELETE:/organisation/{organisation_uuid}/role/{role_uuid}
   * @secure
   */
  deleteRoleByUuid = (organisationUuid: string, roleUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteRoleByUuidData, void>({
      path: `/organisation/${organisationUuid}/role/${roleUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags role
   * @name GetRoleByUuid
   * @summary Get role info by uuid
   * @request GET:/organisation/{organisation_uuid}/role/{role_uuid}
   * @secure
   */
  getRoleByUuid = (organisationUuid: string, roleUuid: string, params: RequestParams = {}) =>
    this.http.request<GetRoleByUuidData, void>({
      path: `/organisation/${organisationUuid}/role/${roleUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags role
   * @name UpdateRoleByUuid
   * @summary Update role info by uuid
   * @request PATCH:/organisation/{organisation_uuid}/role/{role_uuid}
   * @secure
   */
  updateRoleByUuid = (organisationUuid: string, roleUuid: string, data: RolePatch, params: RequestParams = {}) =>
    this.http.request<UpdateRoleByUuidData, void>({
      path: `/organisation/${organisationUuid}/role/${roleUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags member
   * @name GetMembers
   * @summary Get all members
   * @request GET:/organisation/{organisation_uuid}/member
   * @secure
   */
  getMembers = (
    organisationUuid: string,
    query?: {
      /** Offset for paginated result */
      offset?: number;
      /** Limit for paginated result */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetMembersData, void>({
      path: `/organisation/${organisationUuid}/member`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags member
   * @name EditMembers
   * @summary Invite/Re-invite or remove members
   * @request PATCH:/organisation/{organisation_uuid}/member
   * @secure
   */
  editMembers = (organisationUuid: string, data: MemberPatch2, params: RequestParams = {}) =>
    this.http.request<EditMembersData, void>({
      path: `/organisation/${organisationUuid}/member`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags member
   * @name GetMemberByUuid
   * @summary Get member info by uuid
   * @request GET:/organisation/{organisation_uuid}/member/{member_uuid}
   * @secure
   */
  getMemberByUuid = (organisationUuid: string, memberUuid: string, params: RequestParams = {}) =>
    this.http.request<GetMemberByUuidData, void>({
      path: `/organisation/${organisationUuid}/member/${memberUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags member
   * @name ExitMember
   * @summary Exit the specified organisation
   * @request POST:/organisation/{organisation_uuid}/exit
   * @secure
   */
  exitMember = (organisationUuid: string, params: RequestParams = {}) =>
    this.http.request<ExitMemberData, void>({
      path: `/organisation/${organisationUuid}/exit`,
      method: "POST",
      secure: true,
      ...params,
    });
}
