import styled from "@emotion/styled";
import React from "react";
import { FunctionComponent } from "react";

type ChartBoxProps = JSX.IntrinsicAttributes & {
    className?: string;
    ref?: any;
    style?: any;
};

const ChartDraggable = styled.div`
    width: 100%;
    padding: 6px 0;
    cursor: move;
    position: absolute;
    z-index: 1;
`;

const ChartBox: FunctionComponent<ChartBoxProps> = React.forwardRef(
    ({ children, className, ...rest }, ref) => {
        return (
            <div
                className={`col widget-box temperature ${className}`}
                ref={ref}
                {...rest}
            >
                <ChartDraggable className="widget-drag" />
                {children}
            </div>
        );
    }
);

export default ChartBox;
