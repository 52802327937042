import { debounce } from "lodash";
import { useEffect, useState } from "react";

const useChartWidth = ({ ref, occupiedWidth }: any) => {
    const [boxWidth, setBoxWidth] = useState(100);

    useEffect(() => {
        const box = ref?.current;
        if (box) {
            setBoxWidth(box.clientWidth - occupiedWidth);

            const resizeObserver = new ResizeObserver(
                debounce((entries) => {
                    setBoxWidth(entries[0]?.target.clientWidth - occupiedWidth);
                }, 300)
            );

            resizeObserver.observe(box);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [ref, occupiedWidth]);

    return { boxWidth };
};

export default useChartWidth;
