import { Form } from "react-bootstrap";
import "assets/css/subscription.css";

const TokenQuantityField = ({
    quantity,
    setQuantity,
    customOnChange,
}: {
    quantity: string;
    setQuantity: (value: string) => void;
    customOnChange?: ((e: any) => void) | null;
}) => {
    return (
        <>
            <Form.Label className="quantity-field-form-label">
                <div className="default-white-color">Qty</div>
                <span className="text-primary-blue-4">(Max 100)</span>
            </Form.Label>
            <Form.Control
                type="number"
                min={1}
                max={100}
                maxLength={3}
                id="qtyField"
                value={quantity}
                onKeyDown={(e: any) => {
                    if (e.key === "Backspace" && e.target.value.length === 1) {
                        e.preventDefault();
                    }
                }}
                className="quantity-field-form-control"
                onChange={(e) => {
                    if (
                        Number(e.target.value) < 1 ||
                        Number(e.target.value) > 100
                    ) {
                        return;
                    }
                    customOnChange
                        ? customOnChange(e)
                        : setQuantity(e.target.value);
                }}
            ></Form.Control>
        </>
    );
};

export default TokenQuantityField;
