import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import addressImg from "assets/svg/address.svg";
import "assets/css/subscription.css";
import { Countries } from "types/Subscription";
import { faker } from "@faker-js/faker";
import pycountry from "utils/pycountry_list.json";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import {
    BILLING_ADDRESS_VALIDATION_MESSAGE,
    MAX_BILLING_ADDRESS_CHAR,
} from "constant/messages";
import { Patterns } from "constant";

const billingAddressSchema = object({
    first_name: string()
        .trim()
        .required(BILLING_ADDRESS_VALIDATION_MESSAGE.FIRST_NAME)
        .max(
            MAX_BILLING_ADDRESS_CHAR,
            BILLING_ADDRESS_VALIDATION_MESSAGE.FIRST_NAME
        )
        .matches(
            Patterns.asciiPattern,
            BILLING_ADDRESS_VALIDATION_MESSAGE.FIRST_NAME
        ),
    last_name: string()
        .trim()
        .required(BILLING_ADDRESS_VALIDATION_MESSAGE.LAST_NAME)
        .max(
            MAX_BILLING_ADDRESS_CHAR,
            BILLING_ADDRESS_VALIDATION_MESSAGE.LAST_NAME
        )
        .matches(
            Patterns.asciiPattern,
            BILLING_ADDRESS_VALIDATION_MESSAGE.LAST_NAME
        ),
    street: string()
        .trim()
        .required(BILLING_ADDRESS_VALIDATION_MESSAGE.STREET)
        .max(
            MAX_BILLING_ADDRESS_CHAR,
            BILLING_ADDRESS_VALIDATION_MESSAGE.STREET
        )
        .matches(
            Patterns.asciiPattern,
            BILLING_ADDRESS_VALIDATION_MESSAGE.STREET
        ),
    extended: string()
        .trim()
        .max(
            MAX_BILLING_ADDRESS_CHAR,
            BILLING_ADDRESS_VALIDATION_MESSAGE.BUILDING
        )
        .matches(
            Patterns.asciiPattern,
            BILLING_ADDRESS_VALIDATION_MESSAGE.BUILDING
        ),
    line3: string()
        .trim()
        .required(BILLING_ADDRESS_VALIDATION_MESSAGE.FLOOR_UNIT)
        .max(
            MAX_BILLING_ADDRESS_CHAR,
            BILLING_ADDRESS_VALIDATION_MESSAGE.FLOOR_UNIT
        )
        .matches(
            Patterns.asciiPattern,
            BILLING_ADDRESS_VALIDATION_MESSAGE.FLOOR_UNIT
        ),
    city: string()
        .trim()
        .required(BILLING_ADDRESS_VALIDATION_MESSAGE.CITY)
        .max(MAX_BILLING_ADDRESS_CHAR, BILLING_ADDRESS_VALIDATION_MESSAGE.CITY)
        .matches(
            Patterns.asciiPattern,
            BILLING_ADDRESS_VALIDATION_MESSAGE.CITY
        ),
    code: string()
        .trim()
        .required(BILLING_ADDRESS_VALIDATION_MESSAGE.ZIP_POSTAL_CODE)
        .min(4, BILLING_ADDRESS_VALIDATION_MESSAGE.ZIP_POSTAL_CODE)
        .max(9, BILLING_ADDRESS_VALIDATION_MESSAGE.ZIP_POSTAL_CODE)
        .matches(
            Patterns.zipCodePattern,
            BILLING_ADDRESS_VALIDATION_MESSAGE.ZIP_POSTAL_CODE
        ),
    country: string()
        .trim()
        .required(BILLING_ADDRESS_VALIDATION_MESSAGE.COUNTRY),
});

const BillingAddressCheckoutForm = (props: any) => {
    const { billingAddress, updateBillingAddress, updateAddressError } = props;
    const location = useLocation();
    const isCheckout = location.pathname === "/checkout/billing-address";
    const tooltip = (
        <Tooltip id="tooltip">Editing country is not allowed</Tooltip>
    );

    const { handleChange, errors } = useFormik({
        initialValues: billingAddress,
        enableReinitialize: true,
        validationSchema: billingAddressSchema,
        ...(isCheckout && { validateOnMount: true }),
        onSubmit: () => {},
    });

    useEffect(() => {
        updateAddressError?.(errors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const updateFields = (e: any) => {
        updateBillingAddress({
            ...billingAddress,
            [e.target.name]: e.target.value,
        });
    };

    let listOfCountries: any = Object.keys(pycountry);

    const memoizedRenderCountries = useMemo(() => {
        let countryOption: any = listOfCountries
            .sort()
            .map((country: string) => {
                return (
                    <option value={country} key={faker.datatype.uuid()}>
                        {country}
                    </option>
                );
            });
        countryOption.unshift(
            <option value="" key="empty">
                --- Select a country ---
            </option>
        );
        return countryOption;
    }, [listOfCountries]);

    const renderRegions = (selectedCountry: string) => {
        let regionOption: any = [];
        const pycountries: Countries = pycountry;

        listOfCountries.forEach((i: any) => {
            if (i === selectedCountry) {
                regionOption = pycountries[selectedCountry];
            }
        });

        const sortedRegionOption = regionOption.sort().map((region: any) => (
            <option value={region} key={faker.datatype.uuid()}>
                {region}
            </option>
        ));
        sortedRegionOption.unshift(
            <option value="" key="empty">
                --- Select a region ---
            </option>
        );
        return sortedRegionOption;
    };

    const validateAndUpdateField = (e: any) => {
        updateFields(e);
        handleChange(e);
    };

    const renderErrorMessage = (field: string) => {
        if (errors[field]) {
            return (
                <span
                    className="billing-address-form-error"
                    aria-label="address-error-message"
                >
                    {errors[field]}
                </span>
            );
        }
    };

    return (
        <div className="form-box mb-3">
            <div className="address-title">
                <img src={addressImg} className="address-img" alt="address" />
                <h5 aria-label="billing-address-title">Billing Address</h5>
                <p>Address is required according to regulatory requirement.</p>
            </div>
            <Form>
                <Form.Group className="mb-4">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="first_name"
                        name="first_name"
                        onChange={validateAndUpdateField}
                        value={billingAddress.first_name}
                    />
                    {renderErrorMessage("first_name")}
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="last_name"
                        name="last_name"
                        onChange={validateAndUpdateField}
                        value={billingAddress.last_name}
                    />
                    {renderErrorMessage("last_name")}
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="street"
                        name="street"
                        onChange={validateAndUpdateField}
                        value={billingAddress.street}
                    />
                    {renderErrorMessage("street")}
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Building (Optional)</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="building"
                        name="extended"
                        value={billingAddress.extended}
                        onChange={validateAndUpdateField}
                    />
                    {renderErrorMessage("extended")}
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Floor, Unit</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="floor_unit"
                        name="line3"
                        onChange={validateAndUpdateField}
                        value={billingAddress.line3}
                    />
                    {renderErrorMessage("line3")}
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="city"
                        name="city"
                        onChange={validateAndUpdateField}
                        value={billingAddress.city}
                    />
                    {renderErrorMessage("city")}
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Zip/Postal Code</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="zipcode"
                        name="code"
                        onChange={validateAndUpdateField}
                        value={billingAddress.code}
                    />
                    {renderErrorMessage("code")}
                </Form.Group>
                {isCheckout ? (
                    <Form.Group className="mb-4">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            required
                            aria-label="country"
                            name="country"
                            value={billingAddress.country}
                            onChange={(e: any) => {
                                handleChange(e);
                                updateBillingAddress({
                                    ...billingAddress,
                                    region: "",
                                    [e.target.name]: e.target.value,
                                });
                            }}
                        >
                            {memoizedRenderCountries}
                        </Form.Control>
                        {renderErrorMessage("country")}
                    </Form.Group>
                ) : (
                    <OverlayTrigger placement="auto-start" overlay={tooltip}>
                        <Form.Group className="mb-4">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                aria-label="country"
                                name="country"
                                disabled
                                className="not-allowed-input"
                                value={billingAddress.country}
                            >
                                {memoizedRenderCountries}
                            </Form.Control>
                        </Form.Group>
                    </OverlayTrigger>
                )}
                <Form.Group className="mb-4">
                    <Form.Label>State/Province (Optional)</Form.Label>
                    <Form.Control
                        as="select"
                        required
                        aria-label="state"
                        name="region"
                        value={billingAddress.region}
                        onChange={validateAndUpdateField}
                    >
                        {renderRegions(billingAddress.country)}
                    </Form.Control>
                </Form.Group>
            </Form>
        </div>
    );
};

export default BillingAddressCheckoutForm;
