import { Link } from "react-router-dom";
import addressImg from "assets/svg/address.svg";

const EditBillingAddress = ({ state, bundleQty, currentAddress }: any) => {
    return (
        <div className="form-box mb-3">
            <div className="address-title position-relative">
                <img src={addressImg} className="address-img" alt="address" />
                <h5>Billing Address</h5>
                <p>Address is required according to regulatory requirement.</p>

                <Link
                    to={{
                        pathname: "/checkout/billing-address",
                        state: {
                            ...state,
                            bundleQty,
                        },
                    }}
                    className="link billing-address-edit"
                >
                    Edit
                </Link>
                <div className="mt-3 billing-ad-info">
                    {currentAddress.first_name} {currentAddress.last_name}
                </div>
                <div className="mt-3 billing-ad-info">{`${
                    currentAddress.city
                }, ${currentAddress.street},  ${
                    currentAddress.extended
                        ? `${currentAddress.extended}, `
                        : ""
                }${currentAddress.line3}, ${currentAddress.country}, ${
                    currentAddress.code
                }`}</div>
            </div>
        </div>
    );
};

export default EditBillingAddress;
