import { useEffect, useState, useRef, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    Dropdown,
    Modal,
    Button,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import logo from "assets/svg/logo.svg";
import profile from "assets/svg/profile.svg";
import settings from "assets/svg/settings.svg";
import Logout from "assets/svg/login.svg";
import Invitation from "assets/svg/invitation.svg";
import Subscription from "assets/svg/subscriptions.svg";
import { getWallet } from "service/subscriptionService";
import { HttpStatus } from "constant";
import _ from "lodash";
import moment from "moment";
import { useWalletStore } from "hooks/wallet";
import { styled } from "@mui/system";
import styledConst from "styles";
import { OrganisationDto, UserDto } from "types";
import { SubscriptionPlanType } from "components/subscriptions/CurrentPlanInformation";

const StyledBuyMoreTokens = styled("li")`
    margin: 11.5px;
    width: 140px;
    .cover {
        padding: 5.5px 8px;
        background-color: ${styledConst.Primary_Blue_3};
        border-radius: 5px;

        p {
            font-size: 12px;
            color: white;
            margin-bottom: 0;
        }

        .nav-link {
            font-size: 14px;
            padding: 0;
            color: ${styledConst.linkColor};
            text-decoration: underline;
        }
    }
`;
interface Token {
    amount: number;
    create_time: string;
    expire_time: null | string;
    modify_time: string;
    type_: string;
    uuid: string;
    wallet_uuid: string;
}

type TopMenuProps = {
    orgList: OrganisationDto[];
    userInfo: UserDto;
    currentOrgId: string;
    onOrgChange: any;
    currentOrgInfo: OrganisationDto;
    subscription: any;
    sideMenu: any;
    updateSideMenu: any;
    logout: any;
    subscriptionType: string;
};
const TopMenu = (props: TopMenuProps) => {
    const history = useHistory();
    const { wallet } = useWalletStore();

    const {
        orgList,
        userInfo,
        currentOrgId,
        onOrgChange,
        currentOrgInfo,
        sideMenu,
        updateSideMenu,
        subscription: { isSubscribed, hasSubscribed, tokenBalance },
        logout,
        subscriptionType,
    } = props;

    const [primaryOrgs, setPrimaryOrgs] = useState<OrganisationDto[]>([]);
    const [anotherOrgs, setAnotherOrgs] = useState<OrganisationDto[]>([]);

    const [profileClicked, setProfileClicked] = useState(false);
    const [tokensExpired, setTokensExpired] = useState(false);
    const [hasBalanceTokens, setHasBalanceTokens] = useState(false);

    const handleRedirectToProfile = () => {
        history.push("/user/profile");
    };

    const handleRedirectToSubscription = () => {
        history.push("/manage-subscription");
    };

    const findLastExpiredTokenDate = (tokens: []) => {
        return tokens.reduce((acc: any, t: any) => {
            if (
                !acc ||
                (t.expire_time &&
                    moment(t.expire_time).isSameOrAfter(moment(acc)))
            ) {
                acc = t.expire_time;
            }
            return acc;
        }, "");
    };

    const findTokenSum = (tokens: []) => {
        return _.sumBy(tokens, (t: Token) => t.amount);
    };

    useEffect(() => {
        const fetch = async () => {
            const res: any = await getWallet();
            if (res.status === HttpStatus.OK) {
                const tokens = _.get(res, "data.tokens", null);
                if (tokens) {
                    const currentTime = moment();

                    const lastExpiryDate = findLastExpiredTokenDate(tokens);
                    const tokenSum = findTokenSum(tokens);

                    if (!lastExpiryDate && currentTime.isAfter(lastExpiryDate))
                        setTokensExpired(true);
                    if (tokenSum > 0) {
                        setHasBalanceTokens(true);
                    }
                }
            }
        };
        fetch();
    }, []);

    useEffect(() => {
        const _primaryOrgs: OrganisationDto[] = [];
        const _anotherOrgs: OrganisationDto[] = [];
        orgList.forEach((org) => {
            if (org.isDefault && org.owner === userInfo.email) {
                _primaryOrgs.push(org);
            } else {
                _anotherOrgs.push(org);
            }
        });
        setPrimaryOrgs(_primaryOrgs);
        setAnotherOrgs(_anotherOrgs);
    }, [orgList, userInfo]);
    const menuRef = useRef<HTMLDivElement>(null);
    const useOutsideAlerter = (ref: any) => {
        useEffect(() => {
            const handleClickOutside = (event: any) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setProfileClicked(false);
                }
            };
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    useOutsideAlerter(menuRef);
    const [changeModalShow, setChangeModalShow] = useState(false);
    const [changeOrgDetails, setChangeOrgDetails] = useState<any>({});

    const checkSelectedIsSame = (id: string) => {
        if (id === currentOrgId) return;
        setChangeModalShow(true);
    };

    const SmallScreenBurgerIcon = () => {
        return (
            <div
                className={sideMenu ? "burger show" : "burger"}
                onClick={updateSideMenu}
                onKeyDown={updateSideMenu}
            >
                <div className="menuToggle">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        );
    };

    const isOwner = useMemo(() => {
        return  userInfo.email === currentOrgInfo.owner;
    }, [userInfo, currentOrgInfo])

    return (
        <header className="navbar navbar-expand sticky-top flex-md-nowrap">
            <SmallScreenBurgerIcon />
            <Link className="logo" to="/">
                <img src={logo} alt="logo" />
            </Link>
            <div className="topbar-divider"></div>

            <Dropdown className="switch-dropdown">
                <Dropdown.Toggle
                    variant=""
                    className="switch"
                    id="toggle-nav-dropdown"
                >
                    <h5 className="overflow-text org-info">
                        {!currentOrgInfo.isDefault
                            ? currentOrgInfo.name
                            : `${userInfo.givenName} ${userInfo.familyName}`}
                    </h5>
                    <p className="text-left">
                        {!currentOrgInfo.isDefault
                            ? "Organisation"
                            : "Your Personal Account"}
                    </p>
                    <i className="material-icons up">keyboard_arrow_up</i>
                    <i className="material-icons down">keyboard_arrow_down</i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="switch-drop">
                    {primaryOrgs.length > 0 && <p>Your Personal Account</p>}
                    {primaryOrgs.map((org, index: any) => (
                        <Dropdown.Item
                            eventKey={`${index}`}
                            className={
                                org.uuid === currentOrgId ? "selected" : ""
                            }
                            key={org.uuid}
                            onClick={() => {
                                checkSelectedIsSame(org.uuid);
                                setChangeOrgDetails(org);
                            }}
                        >
                            <div className="first-letter">
                                {userInfo.givenName[0]}
                            </div>
                            <h6>
                                {`${userInfo.givenName} ${userInfo.familyName}`}
                                <span className="material-icons star">
                                    star
                                </span>
                            </h6>
                            <span className="material-icons check">done</span>
                        </Dropdown.Item>
                    ))}
                    {orgList.length > 0 && (
                        <p className="mt-3">Organisations</p>
                    )}
                    {anotherOrgs.map((org, index: any) => (
                        <Dropdown.Item
                            eventKey={`${index}`}
                            className={
                                org.uuid === currentOrgId ? "selected" : ""
                            }
                            key={org.uuid}
                            onClick={() => {
                                checkSelectedIsSame(org.uuid);
                                setChangeOrgDetails(org);
                            }}
                        >
                            <div className="first-letter">{org.name[0]}</div>
                            <h6
                                className={
                                    org.owner === userInfo.email ? "admin" : ""
                                }
                                title={org.name}
                            >
                                {org.name}
                                {org.owner === userInfo.email && (
                                    <span className="material-icons star">
                                        star
                                    </span>
                                )}
                            </h6>
                            <span className="material-icons check">done</span>
                        </Dropdown.Item>
                    ))}
                    <div className="new-org-button">
                        <Dropdown.Item as={Link} to="/create-organisation">
                            <Button variant="primary" className="w-100">
                                Create New Organisation
                            </Button>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
            <ul className="navbar-nav ml-auto">
                {/* Token Balance details */}
                {subscriptionType !== SubscriptionPlanType.BASIC &&
                    isOwner &&
                    wallet.isTooLow && <BuyMoreToken />}

                {/* Before subscription */}
                {subscriptionType !== SubscriptionPlanType.BASIC &&
                    isOwner && (
                        <SubscribeButtonOrTokenBalance
                            hasSubscribed={hasSubscribed}
                            isSubscribed={isSubscribed}
                            tokenBalance={tokenBalance}
                            tokensExpired={tokensExpired}
                            hasBalanceTokens={hasBalanceTokens}
                            handleRedirectToSubscription={
                                handleRedirectToSubscription
                            }
                        />
                    )}

                <li className="nav-item no-arrow">
                    <Link className="nav-link" to="/user/invitations">
                        <img src={Invitation} alt="invitation" />
                        {/* NOTE: Might include badge in the future */}
                        {/* <span className="badge badge-counter">04</span> */}
                    </Link>
                </li>

                <li className="nav-item no-arrow">
                    <div ref={menuRef}>
                        <button
                            className="nav-link profile-link-btn"
                            onClick={() => {
                                setProfileClicked(!profileClicked);
                            }}
                        >
                            <img src={profile} alt="profile" />
                        </button>
                        <ul
                            className={
                                profileClicked
                                    ? "profile-drop d-block"
                                    : "profile-drop d-none"
                            }
                        >
                            <li className="profile-link">
                                <Link
                                    to=""
                                    onClick={(e) => {
                                        setProfileClicked(!profileClicked);
                                        e.preventDefault();
                                        handleRedirectToProfile();
                                    }}
                                >
                                    <img src={profile} alt="cart" />
                                    <div className="text">
                                        <OverlayTrigger
                                            key="user-name-tooltip"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="user-name-tooltip">
                                                    {`${userInfo.givenName} ${userInfo.familyName}`}
                                                </Tooltip>
                                            }
                                        >
                                            <h5 className="profile-user-name">{`${userInfo.givenName} ${userInfo.familyName}`}</h5>
                                        </OverlayTrigger>
                                        <p>{userInfo.email}</p>
                                    </div>
                                </Link>
                            </li>
                            { isOwner &&
                            <li >
                                <Link
                                    to="/manage-subscription"
                                    onClick={() => {
                                        setProfileClicked(!profileClicked);
                                    }}
                                >
                                    <img
                                        src={Subscription}
                                        alt="subscription"
                                    />
                                    <div className="text">
                                        <h5>Subscription</h5>
                                    </div>
                                </Link>
                            </li> }
                            <li>
                                <Link
                                    to={{
                                        pathname: `/user/settings`,
                                    }}
                                >
                                    <img
                                        src={settings}
                                        alt="Account settings"
                                    />
                                    <div className="text">
                                        <h5>Account Settings </h5>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to=""
                                    onClick={(e) => {
                                        setProfileClicked(!profileClicked);
                                        e.preventDefault();
                                        logout();
                                    }}
                                >
                                    <img src={Logout} alt="Logout" />
                                    <div className="text">
                                        <h5>Logout </h5>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            {/* Confirmation Modal */}
            <Modal
                centered
                show={changeModalShow}
                onHide={() => setChangeModalShow(false)}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header primary"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons help">
                            help_outline
                        </span>
                    </div>
                    <h3 className="mb-3">Confirmation</h3>
                    <p className="mb-4 overflow-text">
                        Do you want to switch to{" "}
                        {!changeOrgDetails.isPrimary && changeOrgDetails.name
                            ? `${changeOrgDetails.name} (Organisation)`
                            : `your Personal account`}
                        ?
                    </p>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            setChangeModalShow(false);
                        }}
                    >
                        No
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            onOrgChange(changeOrgDetails);
                            setChangeModalShow(false);
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Body>
            </Modal>
        </header>
    );
};

export default TopMenu;

const BuyMoreToken = () => {
    return (
        <StyledBuyMoreTokens>
            <div className="cover">
                <p>Your token count is low</p>
                <Link className="nav-link" to="/manage-subscription">
                    Buy Tokens
                </Link>
            </div>
        </StyledBuyMoreTokens>
    );
};

const CurrentTokenBalance = ({ tokenBalance }: any) => {
    return (
        <li className="nav-item no-arrow">
            <div className="header-balance-details">
                <p>Current Balance:</p>
                <h5>
                    <span>{tokenBalance.toLocaleString()}</span> Tokens
                </h5>
            </div>
        </li>
    );
};

const SubscribeButton = ({ handleRedirectToSubscription }: any) => {
    return (
        <li className="nav-item no-arrow">
            <Button
                className="mt-3 header-subscribe-button"
                onClick={handleRedirectToSubscription}
            >
                Subscribe Now
            </Button>
        </li>
    );
};

const SubscribeButtonOrTokenBalance = ({
    hasSubscribed,
    isSubscribed,
    tokensExpired,
    hasBalanceTokens,
    tokenBalance,
    handleRedirectToSubscription,
}: any) => {
    const location = useLocation();

    if (
        !hasSubscribed ||
        tokensExpired ||
        (!isSubscribed && !hasBalanceTokens)
    ) {
        if (!location.pathname.includes("/manage-subscription")) {
            return (
                <SubscribeButton
                    handleRedirectToSubscription={handleRedirectToSubscription}
                />
            );
        } else if (
            !hasSubscribed &&
            location.pathname.includes("/manage-subscription")
        )
            return null;
    }
    return <CurrentTokenBalance tokenBalance={tokenBalance} />;
};
