import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import LeftContent from "components/authentication/LeftContent";
import ForgotPasswordForm from "components/authentication/ForgotPasswordForm";
import ResetPasswordForm from "components/authentication/ResetPasswordForm";
import ResetSuccess from "components/authentication/ResetSuccess";

const ForgotPassword = () => {
    const [userName, setUserName] = useState("");
    const [isSuccess, setSuccess] = useState(false);

    const onSendEmailSuccess = async (email: string) => {
        setUserName(email);
    };

    const onChangePasswordSuccess = async () => {
        setSuccess(true);
    };

    let rightContent;
    if (userName) {
        rightContent = (
            <ResetPasswordForm
                email={userName}
                onSuccess={onChangePasswordSuccess}
            />
        );
    } else {
        rightContent = <ForgotPasswordForm onSuccess={onSendEmailSuccess} />;
    }

    if (isSuccess) {
        rightContent = <ResetSuccess />;
    }

    return (
        <section>
            <Container fluid>
                <Row className="flex-row-reverse">
                    {rightContent}
                    <LeftContent />
                </Row>
            </Container>
        </section>
    );
};

export default ForgotPassword;
