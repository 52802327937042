import LocalStorageService from "service/localStorageService";
import { getWallet } from "service/subscriptionService";
import { ResponseDto, DashboardTagOptions } from "types";
import moment, { Moment } from "moment";
import { get, set, orderBy, isEqual } from "lodash";
import {
    DefaultMinCharacter,
    DefaultMaxCharacter,
    HttpStatus,
    DATE_MONTH_YEAR_FORMAT,
    Patterns,
} from "constant";
import { AxiosError, AxiosResponse } from "axios";
import { Dashboard } from "generated/models/Dashboard";
import { faker } from "@faker-js/faker";

export const orgIdQuery = () => {
    return {
        organization_id: orgId(),
    };
};

export const orgId = () => {
    return LocalStorageService.getItem("orgId");
};

export function createResponseStandard<T = any>(
    res: AxiosResponse
): ResponseDto<T> {
    return {
        status: res.status,
        data: res.data.data || res.data,
        message: res?.data?.message,
        total: res?.data?.total,
    };
}

export function createErrorResponseStandard<T = any>(
    error: AxiosError | any
): ResponseDto<T> {
    return {
        status: error.response
            ? error.response.status
            : HttpStatus.INTERNAL_SERVER_ERROR,
        data: error.response ? error.response.data : null,
        error,
        message:
            get(error, "response.data.message") ??
            get(error, "response.data.description", null),
    };
}

/**
 * Create status for created property
 * @param { number } dateNumber - Date in number
 * @param { string } dateFormat - Date format (default: "DD/MM/YYYY")
 * @returns { string }
 */
export const formatDate = (
    dateNumber: number | string | Date,
    dateFormat = DATE_MONTH_YEAR_FORMAT
): string => {
    if (!dateNumber) return "-";
    return moment
        .utc(dateNumber)
        .utcOffset(moment().utcOffset())
        .format(dateFormat);
};

export const tagOptions = (dashboards: Dashboard[]): DashboardTagOptions[] => {
    return dashboards.map(({ uuid, name, icon_color }: Dashboard) => ({
        key: uuid,
        text: name,
        value: name,
        label: {
            color:
                get(icon_color, "[0]", "") === "#"
                    ? icon_color
                    : `#${icon_color}`,
            empty: false,
            circular: true,
        },
    }));
};

export const getAllTokens = () => {
    let total = 0;
    const fetchWallet = async () => {
        const response: any = await getWallet();
        if (isHttpSuccess(response?.status)) {
            const { tokens: wallet } = response.data;
            for (const token of wallet) {
                total += token.amount;
            }
            return total;
        } else {
            return total;
        }
    };
    return fetchWallet();
};

/**
 * Convert number of minutes to hours for gateway charts
 * @param { any } timeValue  - Number of minutes
 * @returns { string } - In two decimal place.
 */
export const convertMinutesToHours = (timeValue: any) => {
    let hours: any = Number(timeValue) / 60;
    if (Number(hours) % 1 !== 0) {
        hours = hours.toFixed(2);
    }
    return String(hours);
};

/**
 * Convert chart data with >= 1 dp to 2dp
 * @param array - Array of chart data
 * @returns {Array} - Array of 2dp chart data
 */
export const convertChartToTwoDecimal = (array: any = []) => {
    const updatedArr = array.map((a: any) => {
        if (Number(a[1]) % 1 === 0) {
            return [a[0], a[1]];
        } else {
            return [a[0], Number(a[1]).toFixed(2).toString()];
        }
    });

    return updatedArr;
};

export const formatUnitWithSymbol = (unit: any) => {
    if (unit === "C") {
        return `°C`;
    }
    return unit;
};

export const renderCountdown = (countdown: any) => {
    const mins = Math.floor(countdown / 60);
    const secs = countdown % 60;

    return `${mins < 10 ? "0" : ""}${mins}:${secs < 10 ? "0" : ""}${secs}`;
};

export const combineResultArrays = (res: any) => {
    if (res) {
        const combinedValues = res?.data?.data?.result?.reduce(
            (acc: any, curr: any) => {
                acc.push(...curr.values);
                return acc;
            },
            []
        );

        combinedValues.sort((a: any, b: any) => {
            return a[0] - b[0];
        });

        const uniqueCombinedValues: any = [];
        const timestamps: any = [];
        for (const value of combinedValues) {
            if (!timestamps.includes(value[0])) {
                timestamps.push(value[0]);
                uniqueCombinedValues.push([value[0], value[1]]);
            }
        }

        set(res, "data.data.result[0].values", uniqueCombinedValues);
    }
};

export const getPrimaryUnit = (appSensor: any) => {
    for (const unit of appSensor?.UNITS || []) {
        if (unit === "BINARY") return "";
        if (appSensor?.[unit]?.["PRI"] === "TRUE") {
            return appSensor[unit]["SYMBOL"];
        }
    }
};
export const getPrimaryUnitKey = (appSensor: any) => {
    for (const unit of appSensor.UNITS || []) {
        if (appSensor[unit]["PRI"] === "TRUE") {
            return unit.toLowerCase();
        }
    }
};

export const getUnitSymbolArray = (appSensor: any) => {
    return appSensor.UNITS.reduce((acc: any, unitName: string) => {
        if (["BINARY", "COMPASS"].includes(unitName)) {
            acc.push("");
        } else {
            acc.push(appSensor[unitName].SYMBOL);
        }
        return acc;
    }, []);
};

export const addDecimalPlace = (data: any, accuracy: any) => {
    return parseFloat(data).toFixed(parseInt(accuracy));
};

export const getDaysInMonths = (month: any) => {
    const date = new Date();
    const currentYear = date.getFullYear();
    return new Date(currentYear, month, 0).getDate();
};

export const getPrimaryUnitName = (appSensor: any) => {
    if (!appSensor?.UNITS) return "";
    for (const unit of appSensor?.UNITS || []) {
        if (appSensor?.[unit]?.["PRI"] === "TRUE") {
            return appSensor[unit];
        }
    }
    return "";
};
declare global {
    interface String {
        fill: (data: { [key: string]: any }) => string;
        capitalize: () => string;
        isNumeric: () => boolean;
        toNumFixedDown(digits: number): string;
        toHex: () => string;
    }

    interface Number {
        toMoment(): moment.Moment;
    }

    interface Array<T> {
        pluck: (key: string, fields?: string[]) => { [key: string]: T };
    }

    var getId: () => string;

    var objectFluent: (obj: any, data: any) => any;

    interface HTMLFormElement {
        toData: <T = {}>() => T;
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.fill = function (data) {
    // @ts-ignore
    const str = this.replace(/\{([a-zA-Z0-9_]+)\}/g, function (matched) {
        const key = matched.replace("{", "").replace("}", "");
        return get(data, key, matched);
    });

    return String(str);
};

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
    return (
        this.toLowerCase().charAt(0).toUpperCase() + this.toLowerCase().slice(1)
    );
};

// eslint-disable-next-line no-extend-native
Array.prototype.pluck = function (key: string, fields?: string[]) {
    return this.reduce((acc: any, curr: any) => {
        if (fields) {
            if (fields.includes(curr[key])) {
                acc[curr[key]] = curr;
            }
        } else {
            acc[curr[key]] = curr;
        }
        return acc;
    }, {});
};

global.getId = () => faker.datatype.uuid();

global.objectFluent = (obj: any, data: any) => {
    for (let key in obj) {
        if (typeof obj[key] === "function") {
            obj[key] = obj[key](data[key], data);
        } else {
            obj[key] = data[key] ?? obj[key];
        }
    }

    return obj;
};

// eslint-disable-next-line no-extend-native
String.prototype.isNumeric = function () {
    const str = this;
    if (typeof str != "string") return false;
    return !isNaN(Number(str)) && !isNaN(parseFloat(str));
};

declare module "moment" {
    interface Moment {
        toSeconds(): number;
    }
}

// eslint-disable-next-line no-extend-native
Number.prototype.toMoment = function () {
    return moment(
        `${Math.floor(Number(this) / 3600)}:${Math.floor(
            (Number(this) % 3600) / 60
        )}:${(Number(this) % 3600) % 60}`,
        "HH:mm:ss"
    );
};

// https://stackoverflow.com/a/4912870/7746224
// eslint-disable-next-line no-extend-native
String.prototype.toNumFixedDown = function (digits = 0) {
    const str = this;
    const re = new RegExp("([-]?\\d+\\.\\d{" + digits + "})(\\d)"),
        m = str.toString().match(re);
    return (m ? m[1] : str.valueOf()).replace(/^0+\B/g, "");
};

// eslint-disable-next-line no-extend-native
moment.prototype.toSeconds = function () {
    return this.hours() * 60 * 60 + this.minutes() * 60 + this.seconds();
};

export const humanReadableFileSize = (bytes: any, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
};

export const getFirstSubscriptionInfo = (subscriptionList: any) => {
    const firstSub = orderBy(subscriptionList, ["create_time"], ["asc"])[0];
    if (firstSub) {
        const firstSubDate: any = new Date(firstSub.create_time);
        return {
            startMonth: firstSubDate.getMonth() + 1,
            startYear: firstSubDate.getFullYear(),
        };
    }
    return {
        startMonth: 1,
        startYear: moment().year(),
    };
};

export const calcPercentDiff = (curr: number, prev: number) => {
    if (curr === 0 && prev === 0) return 0;
    if (prev === 0) return 100;
    return ((curr - prev) / prev) * 100;
};

export const isValidValueInForm = (
    path: string,
    data: any,
    defaultData: any,
    regex?: any
) => {
    const text = get(data, path, "");
    const defaultValue = get(defaultData, path, "");
    const textTrim = text.trim();
    const textLength = text.length;

    return (text && isEqual(defaultValue, text)) || regex
        ? regex.test(textTrim)
        : textLength >= DefaultMinCharacter &&
              textLength <= DefaultMaxCharacter;
};

export const checkIfReachable = (
    lastTimestamp: any,
    reportingIntvl: any = 5
) => {
    // If checking GW, reporting intvl is 60
    // If checking LDSU, use reporting intvl from config API, by default is 5

    const now = moment().unix();
    const n = 1.5;
    const offset = 30;

    if (now - lastTimestamp <= reportingIntvl * n + offset) {
        return true;
    }
    return false;
};

export const isSensor = (CLS: number) => CLS < 32768;
export const isActuator = (CLS: number) => CLS >= 32768;

// if CLS < sensor range => sensor
// if CLS > sensor range => actuator
// Current actuator are all 32768
export const clsToAttributeTable = () => {
    let clsNameObj: any = {};
    for (const [key, value] of Object.entries(
        LocalStorageService.getItem("metricInfo").cls
    )) {
        clsNameObj[key] = (value as any).name;
    }
    return clsNameObj;
};

// eslint-disable-next-line no-extend-native
HTMLFormElement.prototype.toData = function () {
    const data: any = {};
    const formData = new FormData(this);
    formData.forEach((value, key) => {
        data[key] = value;
    });

    return data;
};

// eslint-disable-next-line no-extend-native
String.prototype.toHex = function () {
    var hex, i;

    var result = "";
    for (i = 0; i < this.length; i++) {
        hex = this.charCodeAt(i).toString(16);
        result += ("000" + hex).slice(-4);
    }

    return result;
};

export const isHttpSuccess = (status: number) => status >= 200 && status < 300;

export const parseColorToHex = (color: string) => {
    let hexColor = color;
    if (color?.length === 9) {
        const a = color.substring(1, 3);
        hexColor = `#${color.substring(3)}${a}`;
    }

    return hexColor;
};
export const isValidText = (
    path: string,
    data = {},
    defaultData = {},
    regex?: RegExp
) => isValidValueInForm(path, data, defaultData, regex);

export const convertSecondsToPrometheusTime = (seconds: number) => {
    const units = { y: 31536000, w: 604800, d: 86400, h: 3600, m: 60, s: 1 };

    let secs = Number(seconds);

    if (secs <= 0) return "0s";

    let y = Math.floor(secs / units.y);
    let w = Math.floor((secs % units.y) / units.w);
    let d = Math.floor(((secs % units.y) % units.w) / units.d);
    let h = Math.floor((((secs % units.y) % units.w) % units.d) / units.h);
    let m = Math.floor(
        ((((secs % units.y) % units.w) % units.d) % units.h) / units.m
    );
    let s = Math.floor(
        ((((secs % units.y) % units.w) % units.d) % units.h) % units.m
    );

    let yDisplay = y > 0 ? `${y}y` : "";
    let wDisplay = w > 0 ? `${w}w` : "";
    let dDisplay = d > 0 ? `${d}d` : "";
    let hDisplay = h > 0 ? `${h}h` : "";
    let mDisplay = m > 0 ? `${m}m` : "";
    let sDisplay = s > 0 ? `${s}s` : "";
    return yDisplay + wDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
};

export const convertPrometheusTimeToSeconds = (timeExpression: string) => {
    const units: any = {
        y: 31536000,
        w: 604800,
        d: 86400,
        h: 3600,
        m: 60,
        s: 1,
    };
    const regex = /(\d+)([ywdhms])?/g;

    let seconds = 0;
    let match;
    while ((match = regex.exec(timeExpression))) {
        seconds += parseInt(match[1]) * units[match[2]];
    }

    return seconds;
};

export const padTimeFormat = (num: string) => {
    return (Number(num) < 10 ? "0" : "") + Number(num);
};

export const displaySensorType = (sensor: any) => {
    let name = get(sensor, "sensor_type", sensor?.name);

    if (sensor?.APP) {
        name = sensor.APP[sensor.SAID].NAME;
    }
    return name ?? "-";
};

export const convertStringTo2dp = (str: string) => {
    return Number(str).toFixed(2);
};

export const convertSecondsToTimeString = (
    seconds: number,
    timeFormat: string
) => {
    const duration = moment.duration(seconds, "seconds");
    return moment.utc(duration.asMilliseconds()).format(timeFormat);
};

export const getLocalTimezone = () => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timezone === "Asia/Saigon") {
        timezone = "Asia/Ho_Chi_Minh";
    }

    return timezone;
};

export const parseDateWithTimezone = (d: Moment, utcOffset: string) => {
    return moment(d.format(`YYYY-MM-DDTHH:mm:ss${utcOffset}`));
};

export const monthsToSeconds = (months: number) => {
    const ONE_MONTH_IN_SECONDS = 2592000;
    return ONE_MONTH_IN_SECONDS * months;
};

export const totalDoesNotExceedMax = ({
    total,
    numberOfChart,
}: {
    total: number;
    numberOfChart: number;
}) => {
    const arbitraryGranularityValue = 50000 / numberOfChart;
    return total <= arbitraryGranularityValue;
};
// Limits min/max by 1d.p
export const customMinMax = ({
    min,
    max,
    acc,
}: {
    min: number;
    max: number;
    acc: number;
}) => {
    const scale = 10 ** acc;
    const max_ = Math.floor(max * scale) / scale;
    const min_ = Math.ceil(min * scale) / scale;

    return {
        min: min_,
        max: max_,
    };
};

export const getAccuracyFromOBJ = ({ object, jsonList, said, appId }: any) => {
    if (object) {
        const sensor = jsonList.find(
            ({ OBJ }: { OBJ: string }) => OBJ === object
        ).SNS[said];

        if (sensor?.APPLICATION?.length) {
            const app = sensor.APPLICATION.find(({ APPID }: any) => {
                return APPID === appId;
            });
            const defaultUnit = getPrimaryUnitName(app);
            if (defaultUnit) {
                return defaultUnit["ACCURACY"];
            }
        }

        return get(sensor, "ACCURACY", sensor?.MODE?.[0]?.ACCURACY);
    }
};

export const handleAccuracyException = (accuracy: number) => {
    return accuracy > 0 ? accuracy - 1 : accuracy;
};

export const isInteger = (num: number | string) => {
    return Patterns.integerPattern.test(num + "");
};

export const isFloat = (num: number | string) => {
    return Patterns.float2DigitsPattern.test(num + "");
};
