import { useState, ChangeEvent, useContext } from "react";
import { Dropdown, Button, FormControl } from "react-bootstrap";
import { DashboardTagOptions } from "types";
import { ReactComponent as DashboardInactiveIcon } from "assets/svg/dashboards-not-active.svg";
import { WalletAlertComponent, walletModalTrigger } from "hooks/wallet";
import { DashboardModal } from "components/modals/DashboardModal";
import { FormType, CREATED_DASHBOARD_MESSAGE } from "constant";
import dashboardService from "service/dashboardService";
import { isHttpSuccess, tagOptions } from "utils/functions";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { AppContext } from "context/appContext";
import { storeDashboardAction, changeDashboardAction } from "store/actions";
import LocalStorageService from "service/localStorageService";
import _ from "lodash";
import { ReactComponent as DashboardCheck } from "assets/svg/tick-circle-solid.svg";
import { ColorPickerBackground } from "components/common";

export const DashboardSelect = () => {
    const { storeData, storeDispatchActions } = useContext(AppContext);
    const { organization, dashboard } = storeData;
    const { currentOrgId } = organization;
    const { currentDashboard, dashboardList } = dashboard;
    const [backdrop, setBackdrop] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showDashboardForm, setShowDashboardForm] = useState(false);
    const [formType, setFormType] = useState<FormType>(FormType.DEFAULT);

    const handleBackdrop = () => {
        setBackdrop(!backdrop);
        setSearchText("");
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleCloseDashboardModal = () => {
        setShowDashboardForm(false);
    };

    const updateDashboardState = async (dashboardId?: string) => {
        const dashboardsResp = await dashboardService.getDashboards(currentOrgId);

        if (!isHttpSuccess(dashboardsResp.status)) {
            showErrorAlert({
                message: _.get(
                    dashboardsResp,
                    "message",
                    "Unable to get Dashboards. Please try again."
                ),
            });
            return;
        }

        storeDispatchActions(
            storeDashboardAction({
                dashboardList: tagOptions(dashboardsResp.data),
                targetId: dashboardId,
            })
        );
    };

    const createDashboard = async (name: string, color: string) => {
        handleCloseDashboardModal();
        const addResp = await dashboardService.addNewDashBoard(name, color);

        if (!isHttpSuccess(addResp.status)) {
            showErrorAlert({
                message: _.get(
                    addResp,
                    "message",
                    "Unable to add Dashboard. Please try again."
                ),
            });
            return;
        }

        showSuccessAlert({
            message: CREATED_DASHBOARD_MESSAGE,
        });

        LocalStorageService.setItem("dbInfo", tagOptions([addResp.data])[0]);
        await updateDashboardState(addResp.data.uuid);
    };

    const selectDashboard = (dashboardOption: DashboardTagOptions) => {
        if (currentDashboard.key === dashboardOption.key) {
            return;
        }

        storeDispatchActions(
            changeDashboardAction({
                dashboard: dashboardOption,
            })
        );
        LocalStorageService.setItem("dbInfo", dashboardOption);
    };

    return (
        <>
            <Dropdown onToggle={handleBackdrop}>
                <Dropdown.Toggle
                    variant=""
                    className="page-title change-dashboard"
                >
                    <p
                        className="selected-dashboard-name my-0"
                        title={currentDashboard.value}
                    >
                        {currentDashboard.value}
                    </p>
                    <i className="material-icons down">keyboard_arrow_down</i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dashboard-drop">
                    <Button
                        variant="primary"
                        onClick={() =>
                            walletModalTrigger(() => {
                                setFormType(FormType.CREATE);
                                setShowDashboardForm(true);
                            })
                        }
                    >
                        + New Dashboard
                    </Button>

                    <FormControl
                        className="search mt-4"
                        placeholder="Search for.."
                        aria-label="search-dashboard"
                        onChange={handleSearch}
                    />

                    <div className="dashboard-list">
                        {dashboardList
                            .filter((option) =>
                                searchText
                                    ? option.value
                                          .toLowerCase()
                                          .includes(searchText.toLowerCase())
                                    : option.value
                            )
                            .map((option: any) => (
                                <Dropdown.Item
                                    key={option.key}
                                    aria-label="dashboard-list-item"
                                    onClick={() => selectDashboard(option)}
                                >
                                    <div className="d-flex align-items-center">
                                        <ColorPickerBackground
                                            $inputColor={option.label.color}
                                            className="dash-icon"
                                        >
                                            <DashboardInactiveIcon />
                                        </ColorPickerBackground>
                                        <h6 title={option.text}>
                                            {option.text}
                                        </h6>
                                        {currentDashboard.key ===
                                            option.key && (
                                            <DashboardCheck
                                                width="15px"
                                                height="15px"
                                            />
                                        )}
                                    </div>
                                </Dropdown.Item>
                            ))}
                    </div>
                </Dropdown.Menu>
            </Dropdown>

            <div className={backdrop ? "bg-overlay" : ""}></div>

            <WalletAlertComponent />

            <DashboardModal
                show={showDashboardForm}
                onClose={handleCloseDashboardModal}
                type={formType}
                onSubmit={createDashboard}
            />
        </>
    );
};
