import { Link } from "react-router-dom";

const SMSRecipientNoVerified = () => (
    <div className="added-item position-relative">
        <p className="mb-0 mr-4 font-italic">
            Mobile number not added or verified.
        </p>{" "}
        <Link className="text-primary-green-1" to="/user/profile">
            Go to profile page
        </Link>
    </div>
);

export default SMSRecipientNoVerified;
