import { Container, Row, Col } from "react-bootstrap";
import { useContext } from "react";
import { AppContext } from "context/appContext";
import MonthlySpending from "./MonthlySpending";
import OverviewSpending from "./OverviewSpending";

import "assets/css/subscription.css";

const TokenSpending = () => {
    const { storeData } = useContext(AppContext);
    const { organization } = storeData;
    const { currentOrgInfo: { owner } } = organization;

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="token-spend-title">
                        <h5 className="page-title">Token Spending</h5>
                        <p>Detailed Statistics</p>
                    </Col>
                </Row>
                <Row>
                    {owner ? (
                        <>
                            <MonthlySpending ownerEmail={owner} />
                            <OverviewSpending ownerEmail={owner} />
                        </>
                    ) : (
                        ""
                    )}
                </Row>
            </Container>
        </>
    );
};

export default TokenSpending;
