import React, { useContext, useEffect, useState } from "react";
import useOrganizationVanilla, {
    changeToOrganization,
} from "hooks/organization";
import { Modal, Button, Alert } from "react-bootstrap";
import LocalStorageService from "service/localStorageService";
import { useLocation } from "react-router-dom";
import { AppContext } from "context/appContext";

const OrganizationAlert = () => {
    return (
        <div className="page-content forbidden-resource organization-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="text-center content col">
                        <Alert
                            variant="danger"
                            className="my-0 mt-4 text-center"
                        >
                            Permission denied.
                        </Alert>
                    </div>
                </div>
            </div>
        </div>
    );
};

const OrganizationWrapperModal: React.FunctionComponent = ({ children }) => {
    const { storeData } = useContext(AppContext);
    const {
        organization: { currentUserIsOwner },
    } = storeData;
    const [isShow, setIsShow] = useState(false);
    const [errorPermission, setErrorPermission] = useState(false);
    const [currentOrganizationId, setCurrentOrganizationId] =
        useState<string>("");
    const getCurrentOrganizationId = () => LocalStorageService.getItem("orgId");
    const modalContent = "This resource belong to {organization_name}.";

    const location = useLocation();
    const ignoreAffectPath = [
        "/dashboard",
        "/gateways-all",
        "/about",
        "/settings",
        "/settings/manage-api-access-keys",
        "/user/download-data",
    ];

    useOrganizationVanilla.subscribe((organization) =>
        setCurrentOrganizationId(organization.orgId)
    );

    useEffect(() => {
        setErrorPermission(false);
        setIsShow(false);
        useOrganizationVanilla.setState({
            orgId: getCurrentOrganizationId(),
            errorPermission: false,
        });
        changeToOrganization.setState({
            toOrganization: {},
        });
    }, [location.pathname]);

    useEffect(() => {
        if (
            currentOrganizationId &&
            currentOrganizationId !== getCurrentOrganizationId() &&
            !ignoreAffectPath.includes(location.pathname)
        ) {
            if (!isShow) {
                setIsShow(true);
                const organization = getOrganization(currentOrganizationId);
                changeToOrganization.setState({
                    toOrganization: organization,
                });
            }

            if (
                useOrganizationVanilla.getState().isChangeOrganization !==
                undefined
            ) {
                useOrganizationVanilla
                    .getState()
                    .setIsChangeOrganization(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganizationId]);

    useEffect(() => {
        if (
            !errorPermission &&
            useOrganizationVanilla.getState().errorPermission
        ) {
            setErrorPermission(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useOrganizationVanilla.getState().errorPermission]);

    const getOrganizationName = (orgId: string) => {
        const organization = getOrganization(orgId);

        return !organization.isDefault
            ? "organisation " + organization.name
            : "your Personal account";
    };

    const getOrganization = (orgId: string) => {
        const organization =
            useOrganizationVanilla
                .getState()
                .organizationList.find((org) => org.uuid === orgId) || {};

        return organization;
    };

    // Temporary solution to handle permission access to settings page if user is not org owner as current permission API does not support yet
    // Update when implementing new permission API
    useEffect(() => {
        if (
            ["/settings/manage-api-access-keys"].includes(
                location.pathname
            )
        ) {
            currentUserIsOwner
                ? setErrorPermission(false)
                : setErrorPermission(true);
        }
    }, [currentUserIsOwner, location.pathname]);

    return (
        <>
            {errorPermission ? (
                <OrganizationAlert />
            ) : isShow ? (
                <Modal
                    centered
                    show={isShow}
                    onHide={() => setIsShow(false)}
                    backdrop="static"
                    keyboard="false"
                    aria-labelledby="example-modal-sizes-title-sm"
                    className="no-header primary"
                >
                    <Modal.Body className="text-center mt-3 mb-3">
                        <div className="modal-icon-box">
                            <span className="material-icons error_outline ">
                                error_outline
                            </span>
                        </div>
                        <h3 className="mb-3">Confirmation</h3>
                        <p className="mb-4 overflow-text">
                            {modalContent.fill({
                                organization_name: getOrganizationName(
                                    useOrganizationVanilla.getState().orgId
                                ),
                            })}
                        </p>

                        <Button
                            variant="danger"
                            onClick={() => {
                                useOrganizationVanilla
                                    .getState()
                                    .setIsChangeOrganization(true);
                                setIsShow(false);
                            }}
                        >
                            Switch to this organisation
                        </Button>
                    </Modal.Body>
                </Modal>
            ) : (
                children
            )}
        </>
    );
};

export default OrganizationWrapperModal;
