import { ComponentType, useMemo } from "react";
import { useAppContext } from "context/appContext";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import { ForbiddenErrorMessage } from "constant";



function withSubscriptionPermissionCheck<T>(SubscriptionComponent: ComponentType<T>) {
    return  (props: any) => {
        const { storeData: {
            organization: { currentUserIsOwner, isUserOrgLoaded },
        } } = useAppContext();

        const isOwner = useMemo(() => currentUserIsOwner, [currentUserIsOwner])

        if(!isUserOrgLoaded) {
            return  <Spinner animation="border" variant="primary" />
        }

        if(!isOwner) {
            return (
                <div className="page-content forbidden-resource">
                    <Container fluid>
                        <Row>
                            <Col className="text-center content">
                                <Alert
                                    variant="danger"
                                    className="my-0 mt-4 text-center"
                                >
                                    {ForbiddenErrorMessage}
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                </div> 
            )
        }

        return <SubscriptionComponent {...props} />;
    }
}

export default withSubscriptionPermissionCheck;
