import React, { useMemo, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { Container, Row, Col, Button } from "react-bootstrap";
import { GridStack } from "gridstack";

import { DashboardHeader } from "components/dashboard/DashboardHeader";
import SingleSensorChart from "components/dashboard/elements/charts/SingleSensorChart";
import StackedChart from "components/dashboard/elements/charts/StackedChart";
import StatusChart from "components/dashboard/elements/charts/StatusChart";
import StorageChart from "components/dashboard/elements/charts/StorageChart";
import ActuatorChart from "components/dashboard/elements/charts/ActuatorChart";
import MetricChart from "components/dashboard/elements/charts/MetricChart";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import dashboardService from "service/dashboardService";
import { isHttpSuccess, tagOptions } from "utils/functions";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { storeDashboardAction } from "store/actions";
import { AppContext } from "context/appContext";
import {
    FormType,
    CREATED_DASHBOARD_MESSAGE,
    FETCH_CHART_FAIL_MESSAGE,
    FETCH_DASHBOARD_FAIL_MESSAGE,
    HttpStatus,
} from "constant";
import VisibleView from "components/hoc/VisibleView";
import { WalletAlertComponent, walletModalTrigger } from "hooks/wallet";
import { canAccess } from "utils/authorize-action";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
    Dashboard as DashboardModel,
    GetPanelsByDashboardIdParamsSource,
} from "generated/models";
import GridStackElement, { GridStackChangeEvent } from "utils/gridstack";
import DashboardGridStack from "components/hoc/DashboardGridStack";
import { DashboardModal } from "components/modals/DashboardModal";
import LocalStorageService from "service/localStorageService";
import { useBasicPlanModal } from "hooks/useBasicPlanModal";
import { BasicPlanModal } from "components/modals/BasicPlanModal";
import { useCheckIfBasicPlan } from "hooks/useCheckIfBasicPlan";
import {
    getAllRegistry,
    getGatewayAlertNotification,
} from "service/gatewayService";

export const NUMBER_OF_SCROLL = 100;

const sourceDict: { [id: string]: undefined | string } = {
    All: undefined,
    Gateways: "GATEWAY",
    Sensors: "SENSOR",
    Actuators: "ACTUATOR",
    Others: "OTHER",
};

const Dashboard: React.FC = () => {
    const history = useHistory();
    const { IS_BASIC_PLAN } = useCheckIfBasicPlan();

    const {
        storeData: {
            organization: { currentOrgId },
            dashboard,
            systemPermissions: { permissions },
        },
        storeDispatchActions,
    } = useContext(AppContext);
    const { dashboardList, currentSelectedTab, currentDashboard } = dashboard;
    const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isDashboardLoading, setIsDashboardLoading] = useState(true);
    const [isPanelLoading, setIsPanelLoading] = useState(true);
    const [infinityState, setInfinityState] = useState<{
        items: any;
        hasMore: null | boolean;
        count: null | number;
        page: number;
    }>({
        items: [],
        hasMore: null,
        count: null,
        page: 0,
    });
    const [showDashboardForm, setShowDashboardForm] = useState(false);
    const [formType, setFormType] = useState<FormType>(FormType.DEFAULT);
    const { toggleShowBasicPlanModal, showBasicPlanModal }: any =
        useBasicPlanModal();
    const [grid, setGrid] = useState<GridStack | null>(null);
    const [gatewaysConfig, setGatewaysConfig] = useState({});
    const [allAlerts, setAllAlerts] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await getAllRegistry();
            if (isHttpSuccess(response.status)) {
                const gatewaysConfigObj = response.data.reduce(
                    (acc: any, curr: any) => {
                        acc[curr.gateway_id] = curr.configs;
                        return acc;
                    },
                    {}
                );
                setGatewaysConfig(gatewaysConfigObj);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const gatewayList = Object.keys(gatewaysConfig);
            const promiseArray = gatewayList.map((gatewayId: string) => {
                return getGatewayAlertNotification(gatewayId);
            });

            const response = await Promise.all(promiseArray);
            const consolidatedResponses = response?.reduce(
                (acc: any, curr: any) => {
                    acc.push(...curr.data);
                    return acc;
                },
                []
            );
            setAllAlerts(consolidatedResponses);
        })();
    }, [gatewaysConfig]);

    const hasPermission = useMemo(
        () => canAccess("dashboard:read") && !isForbiddenResource,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [permissions, isForbiddenResource]
    );

    const handleOnChangeDashboardUUID = async (value: string) => {
        if (value) {
            setIsPanelLoading(true);
            await fetchNextInfiniteScroll();
        }
    };

    const handleAddChart = async () => {
        walletModalTrigger(() => {
            history.push(
                `/dashboard/${currentDashboard.key}/new-charts?organization_id=${currentOrgId}`
            );
        });
    };

    const fetchNextInfiniteScroll = async () => {
        const _infinityState: any = {
            ...infinityState,
        };

        _infinityState.items = [];
        _infinityState.count = 0;
        _infinityState.page = 0;
        window.panelQueryToken = {};

        const fetchDashboardResponse = await dashboardService.getPanelDashboard(
            currentDashboard.key,
            {
                offset: NUMBER_OF_SCROLL * _infinityState.page,
                limit: NUMBER_OF_SCROLL,
                source: sourceDict[
                    currentSelectedTab
                ] as GetPanelsByDashboardIdParamsSource,
            }
        );

        if (isHttpSuccess(fetchDashboardResponse.status)) {
            _infinityState.items = [
                ..._infinityState.items,
                ...(fetchDashboardResponse.data?.sort(
                    (a: any, b: any) => a.order - b.order
                ) || []),
            ];

            for (let index = 0; index < _infinityState.items.length; index++) {
                const panel = _infinityState.items[index];
                if (!panel?.token[0]) {
                    continue;
                }

                // eslint-disable-next-line no-loop-func
                panel.queries.forEach((q: string, index: number) => {
                    window.panelQueryToken[q] = panel.token[index]; // update the token for each panel
                });
            }
            _infinityState.count = fetchDashboardResponse.total;

            _infinityState.hasMore =
                _infinityState.items.length < _infinityState.count;
            setInfinityState(_infinityState);
        } else {
            showErrorAlert(
                getAPIError(fetchDashboardResponse, FETCH_CHART_FAIL_MESSAGE)
            );
        }
        setIsPanelLoading(false);
    };

    const onGridChange = ({
        data: dataItems,
        items: targetItems,
    }: GridStackChangeEvent) => {
        const data = {
            order: {} as any,
            size: {} as any,
        };

        dataItems
            .sort((a: any, b: any) => {
                return a.y * 1e6 + a.x - (b.y * 1e6 + b.x);
            })
            .forEach((item: any, index: number) => {
                data.order[item.id] = index;
            });

        // For change items only
        if (!targetItems) return;
        targetItems.forEach((item: any) => {
            data.size[item.el.id] = item.w;
        });

        dashboardService.updateOrderPanel(currentDashboard.key, data);
    };

    const renderDashboardPanels = () => (
        <>
            {infinityState.items.length > 0 && renderPanels()}
            {infinityState.items.length === 0 && renderEmpty()}
        </>
    );

    const handleChangeWidgetDimension = (uuid: string, width: number) => {
        if (!uuid || !grid) {
            return;
        }

        const gridNode = grid?.engine.nodes.find(
            (node) => node?.el?.id === uuid
        );
        if (!gridNode?.el) {
            return;
        }

        grid.update(gridNode.el, { w: width });
        grid.engine.save(); // Recalculate internal positions
        grid.compact(); // Compact the grid to adjust any overlaps
    };

    const renderPanels = () => {
        return (
            <>
                {!IS_BASIC_PLAN && (
                    <WalletAlertComponent showWarningWhen="LowBalance" />
                )}

                <GridStackElement
                    className="grid w-100"
                    id="dashboard-grid"
                    itemCount={infinityState.items.length}
                    gap={0}
                    cellHeight={410}
                    reRender={false}
                    options={{
                        disableResize: false,
                        autoPosition: true,
                        alwaysShowResizeHandle: true,
                        resizable: {
                            handles: "e, w",
                        },
                        draggable: {
                            handle: ".widget-drag",
                        },
                    }}
                    onChange={onGridChange}
                    onInit={setGrid}
                >
                    {infinityState.items.map(
                        (
                            items: {
                                source: string;
                                chart: string;
                                attributes: {
                                    [x: string]: any;
                                    time_series: any;
                                    gateway_id: any;
                                    device_id: any;
                                    said: any;
                                    type_: string;
                                }[];
                                uuid: string;
                            },
                            index: React.Key | null | undefined
                        ) => {
                            if (
                                items.source.toUpperCase() === "SENSOR" &&
                                items.chart.toUpperCase() === "TIME" &&
                                (currentSelectedTab === "Sensors" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={items.uuid}
                                        role="gridcell"
                                        onSizeChange={
                                            handleChangeWidgetDimension
                                        }
                                    >
                                        <VisibleView>
                                            <SingleSensorChart
                                                gatewaysConfig={gatewaysConfig}
                                                allAlerts={allAlerts}
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={index}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "SENSOR" &&
                                items.chart.toUpperCase() === "STACKED" &&
                                (currentSelectedTab === "Sensors" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={items.uuid}
                                        role="gridcell"
                                        onSizeChange={
                                            handleChangeWidgetDimension
                                        }
                                    >
                                        <VisibleView>
                                            <StackedChart
                                                gatewaysConfig={gatewaysConfig}
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={index}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "GATEWAY" &&
                                items.attributes[0].type_?.toUpperCase() ===
                                    "STORAGE" &&
                                (currentSelectedTab === "Gateways" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={items.uuid}
                                        role="gridcell"
                                        onSizeChange={
                                            handleChangeWidgetDimension
                                        }
                                    >
                                        <VisibleView>
                                            <StorageChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={index}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "GATEWAY" &&
                                items.attributes[0].type_?.toUpperCase() ===
                                    "STATUS" &&
                                (currentSelectedTab === "Gateways" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={items.uuid}
                                        role="gridcell"
                                        onSizeChange={
                                            handleChangeWidgetDimension
                                        }
                                    >
                                        <VisibleView>
                                            <StatusChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={JSON.stringify(items)}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "ACTUATOR" &&
                                (currentSelectedTab === "Actuators" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={items.uuid}
                                        role="gridcell"
                                        onSizeChange={
                                            handleChangeWidgetDimension
                                        }
                                    >
                                        <VisibleView>
                                            <ActuatorChart
                                                gatewaysConfig={gatewaysConfig}
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={JSON.stringify(items)}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "OTHER" &&
                                items.attributes[0].type_?.toUpperCase() ===
                                    "COUNT_ALERT_TRIGGER" &&
                                (currentSelectedTab === "Others" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={items.uuid}
                                        role="gridcell"
                                        onSizeChange={
                                            handleChangeWidgetDimension
                                        }
                                    >
                                        <VisibleView>
                                            <MetricChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={JSON.stringify(items)}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                                displayConfig={{
                                                    title: `Count of events`,
                                                    subTitle: `Count of Events enabled vs triggered`,
                                                    chartColor: [
                                                        "#FDA8A8",
                                                        "#D25A5A",
                                                    ],
                                                    getChartSeries: (
                                                        monthlyData: any
                                                    ) => [
                                                        {
                                                            name: "Events enabled",
                                                            type: "bar",
                                                            stack: "one",
                                                            barWidth: 20,
                                                            data: monthlyData.eventsEnabledData,
                                                        },
                                                        {
                                                            name: "Events triggered",
                                                            type: "bar",
                                                            stack: "two",
                                                            barWidth: 20,
                                                            data: monthlyData.eventsTriggeredData,
                                                        },
                                                    ],
                                                }}
                                                showLabels={[
                                                    "Events enabled",
                                                    "Events triggered",
                                                ]}
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "OTHER" &&
                                items.attributes[0].type_?.toUpperCase() ===
                                    "COUNT_ALERT" &&
                                (currentSelectedTab === "Others" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={items.uuid}
                                        role="gridcell"
                                        onSizeChange={
                                            handleChangeWidgetDimension
                                        }
                                    >
                                        <VisibleView>
                                            <MetricChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={JSON.stringify(items)}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                                displayConfig={{
                                                    title: `Count of Email, SMS, Notification`,
                                                    subTitle: `Count of emails, SMS, and notifications sent`,
                                                    chartColor: [
                                                        "#EE9EC6",
                                                        "#F6C39D",
                                                        "#FFEFB5",
                                                    ],
                                                    getChartSeries: (
                                                        monthlyData: any
                                                    ) => [
                                                        {
                                                            name: "Email",
                                                            type: "bar",
                                                            stack: "one",
                                                            barWidth: 25,
                                                            data: monthlyData.emailData,
                                                        },
                                                        {
                                                            name: "SMS",
                                                            type: "bar",
                                                            stack: "one",
                                                            barWidth: 25,
                                                            data: monthlyData.smsData,
                                                        },
                                                        {
                                                            name: "Notification",
                                                            type: "bar",
                                                            stack: "one",
                                                            barWidth: 25,
                                                            data: monthlyData.notificationData,
                                                        },
                                                    ],
                                                }}
                                                showLabels={[
                                                    "Email",
                                                    "SMS",
                                                    "Notification",
                                                ]}
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else {
                                return <></>;
                            }
                        }
                    )}
                </GridStackElement>
            </>
        );
    };

    const renderEmpty = () => (
        <Col sm="12" className="mt-2">
            <div className="empty-widget text-center">
                <h3 className="mb-3">No Charts added</h3>
                <h5 className="mb-3">Add a new chart to your dashboard.</h5>
                <Button
                    variant="primary"
                    className="mt-3"
                    onClick={handleAddChart}
                >
                    Add Chart
                </Button>
            </div>
        </Col>
    );

    useEffect(() => {
        const handleResize = () => {
            const newZoomLevel = window.devicePixelRatio;
            if (newZoomLevel !== zoomLevel) setZoomLevel(newZoomLevel);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [zoomLevel]);
    useEffect(() => {
        if (currentDashboard?.key) {
            handleOnChangeDashboardUUID(currentDashboard.key);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDashboard, currentSelectedTab]);

    const jsonListLoaded = LocalStorageService.getItem("listOfJSON") !== "";

    useEffect(() => {
        (async () => {
            if (!currentOrgId || !jsonListLoaded) return;

            const dashboardsResp = await dashboardService.getDashboards(
                currentOrgId
            );

            if (dashboardsResp?.status === HttpStatus.FORBIDDEN) {
                setIsForbiddenResource(true);
                return;
            }

            if (!isHttpSuccess(dashboardsResp?.status)) {
                showErrorAlert(
                    getAPIError(dashboardsResp, FETCH_DASHBOARD_FAIL_MESSAGE)
                );
                return;
            }
            setIsPanelLoading(!jsonListLoaded);

            if (!LocalStorageService.getItem("dbInfo")) {
                LocalStorageService.setItem(
                    "dbInfo",
                    tagOptions(dashboardsResp.data)[0]
                );
            }

            const dbInfo = LocalStorageService.getItem("dbInfo");
            const hasActiveDashboard = dashboardsResp.data.some(
                (dashboard: DashboardModel) => dashboard.uuid === dbInfo?.key
            );

            if (hasActiveDashboard) {
                storeDispatchActions(
                    storeDashboardAction({
                        dashboardList: tagOptions(dashboardsResp.data),
                        targetId: dbInfo.key,
                    })
                );
            } else {
                storeDispatchActions(
                    storeDashboardAction({
                        dashboardList: tagOptions(dashboardsResp.data),
                    })
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrgId, jsonListLoaded]);

    useEffect(() => {
        if (!currentOrgId || !jsonListLoaded) {
            return;
        }

        const checkPermission = () => {
            if (!hasPermission) {
                setIsDashboardLoading(false);
                return setIsForbiddenResource(true);
            }

            setIsDashboardLoading(false);
            setIsForbiddenResource(false);
        };

        checkPermission();
    }, [currentOrgId, jsonListLoaded, hasPermission]);

    const handleCloseDashboardModal = () => {
        setShowDashboardForm(false);
    };

    const updateDashboardState = async (dashboardId?: string) => {
        const { status, data, message } = await dashboardService.getDashboards(
            currentOrgId
        );

        if (!isHttpSuccess(status)) {
            showErrorAlert({
                message:
                    message ?? "Unable to get Dashboards. Please try again.",
            });
            return;
        }

        storeDispatchActions(
            storeDashboardAction({
                dashboardList: tagOptions(data),
                targetId: dashboardId,
            })
        );

        handleCloseDashboardModal();
    };

    const createDashboard = async (name: string, color: string) => {
        const { status, data, message } =
            await dashboardService.addNewDashBoard(name, color);

        if (!isHttpSuccess(status)) {
            showErrorAlert({
                message:
                    message ?? "Unable to add Dashboard. Please try again.",
            });
            return;
        }
        LocalStorageService.setItem("dbInfo", tagOptions([data]));

        showSuccessAlert({
            message: CREATED_DASHBOARD_MESSAGE,
        });

        await updateDashboardState(data.uuid);
    };

    return (
        <ContentWrapper
            isForbiddenResource={isForbiddenResource}
            title="Dashboard"
            isLoading={isDashboardLoading}
        >
            {dashboardList.length !== 0 && (
                <div className="page-head">
                    <Container fluid>
                        <DashboardHeader />
                    </Container>
                </div>
            )}

            <div className="page-content">
                <Container fluid>
                    <Row>
                        {isPanelLoading ? (
                            <ContentWrapper isLoading={isPanelLoading} />
                        ) : dashboardList.length > 0 ? (
                            renderDashboardPanels()
                        ) : (
                            <Col sm="12" className="mt-2">
                                <div className="empty-widget text-center">
                                    <h3 className="mb-3">
                                        No Dashboards created yet
                                    </h3>
                                    <h5 className="mb-3">
                                        Create Dashboards to visualize your
                                        data.
                                    </h5>
                                    <Button
                                        variant="primary"
                                        className="mt-3"
                                        onClick={() => {
                                            if (IS_BASIC_PLAN) {
                                                toggleShowBasicPlanModal();
                                            } else {
                                                walletModalTrigger(() => {
                                                    setShowDashboardForm(true);
                                                    setFormType(
                                                        FormType.CREATE
                                                    );
                                                });
                                            }
                                        }}
                                    >
                                        Create New Dashboard
                                    </Button>
                                </div>
                                <WalletAlertComponent />
                                {IS_BASIC_PLAN && (
                                    <BasicPlanModal
                                        showBasicPlanModal={showBasicPlanModal}
                                        toggleShowBasicPlanModal={
                                            toggleShowBasicPlanModal
                                        }
                                    />
                                )}
                                <DashboardModal
                                    show={showDashboardForm}
                                    onClose={handleCloseDashboardModal}
                                    type={formType}
                                    onSubmit={createDashboard}
                                />
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default Dashboard;
