import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    InputGroup,
    FormControl,
    Button,
    Form,
} from "react-bootstrap";

import { formatDate, isHttpSuccess } from "utils/functions";
import { useDebounce } from "hooks/common";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";

import { ReactComponent as DeleteIcon } from "assets/svg/delete-white.svg";
import dlt from "assets/svg/delete.svg";
import { MemberDto, MemberStatus } from "types";
import useCollectSort from "hooks/useCollectSort";

type ConfirmStatus = "cancel" | "ok";

type MembersListProps = {
    membersData: MemberDto[];
    orgId: string;
    onDelete: (emails: string[]) => Promise<any>;
};

type Member = MemberDto & { displayName: string };

const MembersList = (props: MembersListProps) => {
    const { membersData, orgId, onDelete } = props;
    const [members, setMembers] = useState<Member[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const customDebounce = useDebounce((value: string) => {
        const _searchStr = value
            .toLowerCase()
            .replace(/\s{2,}/, "")
            .trim();

        if (!_searchStr)
            setMembers(
                membersData.map((m) => ({ ...m, displayName: getName(m) }))
            );

        const results = membersData
            .filter((member) => {
                return getName(member).toLowerCase().includes(_searchStr);
            })
            .map((m) => ({ ...m, displayName: getName(m) }));
        setMembers(results);
    });
    const { data: memberCollect, sortIcon } = useCollectSort(members);

    useEffect(() => {
        setMembers(membersData.map((m) => ({ ...m, displayName: getName(m) })));
    }, [membersData]);

    const isDefault = (member: MemberDto) =>
        member.status === MemberStatus.Owner;

    const handleSearchInput = (e: any) => {
        const { value } = e.target;
        customDebounce(value);
    };

    const generateDateWithStatus = (member: any) => {
        const statusString: any = {
            Pending: "Invited on",
            Accepted: "Joined on",
            Declined: "Invited on",
            Cancel: "Invited on",
            Owner: "Created on",
        };
        const { status, modifyTime } = member;

        return `${statusString[status]} ${formatDate(modifyTime)}`;
    };

    const handleDeleteMember = (member?: MemberDto) => {
        let message = `Do you want to delete ${selectedIds.length} selected member(s)?`;
        let title = "Delete Member(s)?";
        if (member) {
            message = `Do you want to delete member  <strong>${getName(
                member
            )}</strong>?`;
            title = "Delete Member?";
        }

        setSimpleModalData({
            resObj: {
                data: {
                    title,
                },
                message,
            },
            icon: "delete",
            renderModalButtons: (setErrModalVisible: any) => {
                return (
                    <>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setErrModalVisible(false);
                                handleCloseConfirmModal("cancel");
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setErrModalVisible(false);
                                handleCloseConfirmModal("ok", member);
                            }}
                        >
                            DELETE
                        </Button>
                    </>
                );
            },
        } as SimpleModalDataType);
    };

    const handleSelectDeleteMember = (e: any, email: string) => {
        const { checked } = e.target;
        let deleteIds: Array<string> = [...selectedIds];

        if (checked) {
            deleteIds = [...deleteIds, email];
        } else {
            deleteIds = deleteIds.filter((e: string) => e !== email);
        }

        setSelectedIds(deleteIds);
        setIsSelectAll(deleteIds.length === members.length - 1);
    };

    const handleSelectAll = (e: any) => {
        const { checked } = e.target;
        let deleteIds: Array<string> = [];

        if (checked) {
            deleteIds = members.reduce((results: Array<string>, member) => {
                if (!isDefault(member)) {
                    results.push(member.username);
                }

                return results;
            }, []);
        } else {
            deleteIds = [];
        }

        setSelectedIds(deleteIds);
        setIsSelectAll(checked);
    };

    const handleCloseConfirmModal = async (
        status: ConfirmStatus = "cancel",
        member?: MemberDto
    ) => {
        if (status === "ok") {
            const deleteIds = member ? [member.username] : selectedIds;

            const res = await onDelete(deleteIds);
            const { status } = res;
            let message = "";

            if (isHttpSuccess(status)) {
                setSelectedIds([]);
                setIsSelectAll(false);
                message = member
                    ? "User has been removed successfully."
                    : `${selectedIds.length} user(s) removed successfully from organisation.`;
            }

            setSimpleModalData({
                resObj: res,
                body: message,
            } as SimpleModalDataType);
        }
    };

    const getName = (member: MemberDto) => {
        if (
            [MemberStatus.Accepted, MemberStatus.Owner].includes(member.status)
        ) {
            return member.name.join(" ");
        } else {
            return member.username;
        }
    };

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <Row>
                <Col
                    md={{ span: 4, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    className="mt-4"
                >
                    <HoverAuthorizeTooltip permission="organisation:delete">
                        <Button
                            variant="danger"
                            className={
                                selectedIds.length > 0
                                    ? "px-2 px-sm-3 opacity-btn-enable"
                                    : "px-2 px-sm-3 opacity-btn-disable"
                            }
                            hidden={!selectedIds.length}
                            onClick={() => handleDeleteMember()}
                        >
                            <DeleteIcon className="delete-icon" /> (
                            {selectedIds.length})
                        </Button>
                    </HoverAuthorizeTooltip>
                </Col>
                <Col
                    md={{ span: 8, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    className="d-md-flex justify-content-end table-head-options mt-4"
                >
                    <HoverAuthorizeTooltip
                        permission="organisation:create"
                        className="mr-3 mb-3"
                    >
                        <Link
                            to={`/invite-users/${orgId}`}
                            className="btn btn-primary"
                        >
                            + INVITE NEW MEMBERS
                        </Link>
                    </HoverAuthorizeTooltip>
                    <div className="search">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                aria-describedby="button-addon2"
                                ref={searchRef}
                                onChange={handleSearchInput}
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Row className="cstm-table">
                <Col sm={12}>
                    <div className="table-head">
                        <Row>
                            <Col sm="1">
                                <HoverAuthorizeTooltip permission="organisation:delete">
                                    <span className="d-inline-block">
                                        <Form.Check
                                            className="mr-sm-2"
                                            type="checkbox"
                                            id="select-all-members"
                                            custom
                                            checked={isSelectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </span>
                                </HoverAuthorizeTooltip>
                            </Col>
                            <Col>Name {sortIcon("displayName")}</Col>
                            <Col>Date {sortIcon("modifyTime")}</Col>
                            <Col>Status {sortIcon("status")}</Col>
                            <Col sm="1">Action</Col>
                            <Col sm="1"></Col>
                        </Row>
                    </div>
                    {memberCollect.map((member, index: any) => (
                        <div className="table-row" key={member.uuid}>
                            <Row>
                                <Col xs={{ span: 1, order: 1 }} className="">
                                    <Form.Check
                                        className="mr-sm-2"
                                        type="checkbox"
                                        id={`custom-${member.uuid}`}
                                        custom
                                        checked={selectedIds.includes(
                                            member.username
                                        )}
                                        disabled={isDefault(member)}
                                        onChange={(e) =>
                                            handleSelectDeleteMember(
                                                e,
                                                member.username
                                            )
                                        }
                                    />
                                </Col>
                                <Col md xs={{ span: 10, order: 2 }}>
                                    <Link
                                        to={`/organisation/${orgId}/member/${member.uuid}`}
                                    >
                                        {getName(member)}
                                    </Link>
                                </Col>
                                <Col
                                    md={{ offset: 0 }}
                                    xs={{ span: 11, offset: 1, order: 4 }}
                                >
                                    {generateDateWithStatus(member)}
                                </Col>
                                <Col
                                    md={{ offset: 0 }}
                                    xs={{ span: 10, offset: 1, order: 5 }}
                                >
                                    {
                                        MemberStatus[
                                            member.status as keyof typeof MemberStatus
                                        ]
                                    }
                                </Col>
                                <Col xs={{ span: 1, order: 6 }}>
                                    {!isDefault(member) ? (
                                        <HoverAuthorizeTooltip permission="organisation:delete">
                                            <span
                                                className="btn"
                                                onClick={() => {
                                                    handleDeleteMember(member);
                                                }}
                                                onKeyDown={() => {
                                                    handleDeleteMember(member);
                                                }}
                                            >
                                                <img
                                                    src={dlt}
                                                    alt="delete member"
                                                />
                                            </span>
                                        </HoverAuthorizeTooltip>
                                    ) : null}
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 3 }}
                                >
                                    <Link
                                        to={`/organisation/${orgId}/member/${member.uuid}`}
                                    >
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    ))}

                    {memberCollect.length === 0 && (
                        <div className="table-row">
                            <Row>
                                <Col className="text-center">
                                    No items to manage here.
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
            </Row>
        </ContentWrapper>
    );
};

export default MembersList;
