import { useContext } from "react";
import { useHistory } from "react-router";
import { Row, Col, Button } from "react-bootstrap";

import { AppContext } from "context/appContext";
import { DashboardSelect } from "components/dashboard/DashboardSelect";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { walletModalTrigger } from "hooks/wallet";
import { DashboardActionDropdown } from "components/dashboard/DashboardActionDropdown";
import { SelectedButton } from "../common/form";
import { updateSelectedTab } from "store/actions";

export const DashboardHeader = () => {
    const history = useHistory();
    const { storeData, storeDispatchActions } = useContext(AppContext);
    const { dashboard, organization } = storeData;
    const { currentOrgId } = organization;
    const { currentDashboard, currentSelectedTab } = dashboard;

    const selectChart = (value: string) => {
        storeDispatchActions(updateSelectedTab(value));
    };

    return (
        <Row>
            <Col sm="12">
                <div className="dashboard-header">
                    <div className="dashboard-action">
                        <DashboardSelect />
                        <HoverAuthorizeTooltip
                            permission="dashboard:create"
                            className="float-left"
                        >
                            <Button
                                variant="primary"
                                onClick={() => {
                                    walletModalTrigger(() => {
                                        history.push(
                                            `/dashboard/${currentDashboard.key}/new-charts?organization_id=${currentOrgId}`
                                        );
                                    });
                                }}
                            >
                                Add Chart
                            </Button>
                        </HoverAuthorizeTooltip>
                        <DashboardActionDropdown />
                    </div>

                    <div className="dashboard-nav">
                        <SelectedButton
                            text="All"
                            selected={currentSelectedTab}
                            handleOnClick={selectChart}
                        />
                        <SelectedButton
                            text="Gateways"
                            selected={currentSelectedTab}
                            handleOnClick={selectChart}
                        />
                        <SelectedButton
                            text="Sensors"
                            selected={currentSelectedTab}
                            handleOnClick={selectChart}
                        />
                        <SelectedButton
                            text="Actuators"
                            selected={currentSelectedTab}
                            handleOnClick={selectChart}
                        />
                        <SelectedButton
                            text="Others"
                            selected={currentSelectedTab}
                            handleOnClick={selectChart}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    );
};
