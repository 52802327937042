import { useState, FC, PropsWithChildren, useEffect } from "react";
import * as _ from "lodash";
import { useScreen } from "hooks/useScreen";
import { getItemWidth } from "utils/gridstack";
import ErrorBoundary from "utils/errorBoundary";

const DASHBOARD_MIN_WIDTH = 3;
const OLD_DASHBOARD_WIDTH = 4;

const DashboardGridStack: FC<
    PropsWithChildren<
        Partial<{
            id: string;
            idKey: string;
            width: number;
            onSizeChange: any;
            [key: string]: any;
        }>
    >
> = (props) => {
    const { children, id, idKey, width, onSizeChange, ...rest } = props as any;
    const [minWidth, setMinWidth] = useState<number>(DASHBOARD_MIN_WIDTH);
    const [itemWidth, setItemWidth] = useState<number>(OLD_DASHBOARD_WIDTH);
    const { width: screenWidth } = useScreen();
    const uuid =
        id ??
        _.get(children, (idKey || "props.children.props.panel") + ".uuid");
    const panelWidth =
        width ??
        _.get(children, (idKey || "props.children.props.panel") + ".size");

    useEffect(() => {
        const newItemWidth = getItemWidth(screenWidth, panelWidth || OLD_DASHBOARD_WIDTH);

        setItemWidth(newItemWidth);
        setMinWidth(getItemWidth(screenWidth));

        if (typeof onSizeChange === "function") {
            onSizeChange(uuid, newItemWidth);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenWidth, panelWidth, uuid]);

    return (
        <div
            className="grid-stack-item"
            id={uuid}
            key={uuid}
            gs-w={itemWidth}
            gs-min-w={minWidth}
            data-gs-auto-position="1"
            {...rest}
        >
            <div className="grid-stack-item-content">
                <ErrorBoundary>{children}</ErrorBoundary>
            </div>
        </div>
    );
};

export default DashboardGridStack;
