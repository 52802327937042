import { ChangeEvent, useState } from "react";
import {
    Row,
    Col,
    InputGroup,
    Button,
    Form,
    FormControl,
    Modal,
} from "react-bootstrap";
import { MonthYearTimeScheduleTemplate } from "components/events/ConditionDetail/MonthYearTimeScheduleTemplate";
import { RangeOfRecurrenceTemplate } from "components/modals/RangeOfRecurrenceTemplate";
import {
    DaysMap,
    EventConfigLimit,
    MonthsMap,
    Patterns,
    START_OF_DAY_AT_FIRST_MINUTE,
} from "constant";
import { getDaysInMonths } from "utils/functions";
import { showErrorAlert } from "utils/alert";

import "assets/css/event.css";
import { HelpText } from "components/modals/EventModals/HelpTextModal";
import { RepeatDateTime } from "types/Event";
import { RemoveIcon } from "components/common";
import { getDefaultRecurrenceEndTime } from "utils/eventFunctions";

const Yearly = ({
    allDates,
    setAllDates,
    repeatInterval,
    setRepeatInterval,
    setRecurrenceRange,
    recurrenceRange,
    renderErrorMsg,
    onChangeTimeOption,
    recurrenceErrorMsg,
}: any) => {
    const [onDayOption, setOnDayOption] = useState("1");
    const [onMonthOption, setOnMonthOption] = useState("january");
    const [firstLastOption, setFirstLastOption] = useState("first");
    const [weekdayOption, setWeekdayOption] = useState("day");
    const [monthOption, setMonthOption] = useState("january");
    const [checkedOption, setCheckedOption] = useState("checked_day");

    const [yearDayModalShow, setYearDayModalShow] = useState(false);
    const handleCloseYearDayModal = () => setYearDayModalShow(false);

    const addDay = () => {
        let newYearlyDate: any = {};
        switch (checkedOption) {
            case "checked_day":
                newYearlyDate = {
                    type: "yearly_recurrence:day",
                    day: onDayOption,
                    month: onMonthOption,
                    selectedTimeOption: "all_day",
                    priTime: START_OF_DAY_AT_FIRST_MINUTE,
                    secTime: START_OF_DAY_AT_FIRST_MINUTE,
                    timeList: [],
                    timeRangeList: [],
                    selectedInterval: "hours",
                    duration: "",
                };

                break;
            case "checked_first_last":
                // if first/last day
                if (weekdayOption === "day") {
                    newYearlyDate = {
                        type: "yearly_recurrence:day",
                        day: firstLastOption === "first" ? "1" : "-1",
                        month: monthOption,
                        selectedTimeOption: "all_day",
                        priTime: START_OF_DAY_AT_FIRST_MINUTE,
                        secTime: START_OF_DAY_AT_FIRST_MINUTE,
                        timeList: [],
                        timeRangeList: [],
                        selectedInterval: "hours",
                        duration: "",
                    };
                } else {
                    const weekdayVal: any = Object.keys(DaysMap).find(
                        (key) => DaysMap[key].toLowerCase() === weekdayOption
                    );
                    newYearlyDate = {
                        type: "yearly_recurrence:firstLast",
                        day:
                            firstLastOption === "first"
                                ? weekdayVal
                                : weekdayVal * -1,
                        month: monthOption,
                        selectedTimeOption: "all_day",
                        priTime: START_OF_DAY_AT_FIRST_MINUTE,
                        secTime: START_OF_DAY_AT_FIRST_MINUTE,
                        timeList: [],
                        timeRangeList: [],
                        selectedInterval: "hours",
                        duration: "",
                    };
                }

                break;
        }

        // Check date is exists or not
        const invalid = allDates.some(({ type, day, month }: any) => {
            return (
                type === newYearlyDate.type &&
                day === newYearlyDate.day &&
                month === newYearlyDate.month
            );
        });

        if (invalid) {
            return showErrorAlert({
                message: "You can not add two schedule times in same day!",
            });
        }

        setAllDates([...allDates, newYearlyDate]);
    };

    const deleteSelection = (indexToDelete: number) => {
        // eslint-disable-next-line array-callback-return
        const updatedAllDates = allDates.filter((d: any, index: number) => {
            if (indexToDelete !== index) {
                return d;
            }
        });
        setAllDates(updatedAllDates);
    };

    const renderMonthDates = () => {
        const renderDateTitle = (monthDate: RepeatDateTime) => {
            // if checked option - on day is chosen
            if (monthDate.type === "yearly_recurrence:day") {
                switch (monthDate.day) {
                    case "-1":
                        return (
                            <span>
                                Last day of {monthDate.month.capitalize()}
                            </span>
                        );
                    case "1":
                    case "21":
                    case "31":
                        return (
                            <span>
                                {monthDate.month.capitalize()} {monthDate.day}
                                st
                            </span>
                        );
                    case "2":
                    case "22":
                        return (
                            <span>
                                {monthDate.month.capitalize()} {monthDate.day}
                                nd
                            </span>
                        );
                    case "3":
                    case "23":
                        return (
                            <span>
                                {monthDate.month.capitalize()} {monthDate.day}
                                rd
                            </span>
                        );
                    default:
                        return (
                            <span>
                                {monthDate.month.capitalize()} {monthDate.day}
                                th
                            </span>
                        );
                }
            }

            // if on first last is chosen

            let reformatText: any;

            if (Number(monthDate.day) > 0) {
                reformatText = `First ${DaysMap[monthDate.day]}`;
            } else {
                reformatText = `Last ${DaysMap[Number(monthDate.day) * -1]}`;
            }
            return (
                <span>
                    {reformatText} of {monthDate.month.capitalize()}
                </span>
            );
        };
        // eslint-disable-next-line array-callback-return
        return allDates.map((monthDate: RepeatDateTime, index: number) => {
            if (monthDate.type.includes("yearly_recurrence")) {
                return (
                    <div
                        className="monthday"
                        key={`${monthDate.type}_${monthDate.day}_${monthDate.month}`}
                    >
                        <div className="monthday-date">
                            {renderDateTitle(monthDate)}
                            <RemoveIcon
                                onClick={() => {
                                    deleteSelection(index);
                                }}
                                className="float-right"
                            />
                        </div>
                        <div className="monthday-box">
                            <MonthYearTimeScheduleTemplate
                                allDates={allDates}
                                monthDate={monthDate}
                                setAllDates={setAllDates}
                                index={index}
                                recurrence={"yearly"}
                                renderErrorMsg={renderErrorMsg}
                                onChangeTimeOption={onChangeTimeOption}
                            />
                        </div>
                    </div>
                );
            }
        });
    };

    const renderDates = () => {
        const month: any = Object.keys(MonthsMap).find(
            (key: any) => MonthsMap[key] === onMonthOption.capitalize()
        );
        let numOfDays = getDaysInMonths(month);

        //Special exception for February
        if (onMonthOption === "february" && numOfDays === 28) numOfDays += 1;

        const days: any = [];
        for (let n = 1; n <= numOfDays; n++) {
            days.push(<option key={n}>{n}</option>);
        }
        return days;
    };

    const handleRepeatFieldInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.value &&
            !Patterns.repeatYearlyPattern.test(e.target.value)
        )
            return;
        setRepeatInterval({
            ...repeatInterval,
            year: e.target.value,
        });
    };

    const setYearlyRecurrenceRange = (recurrence: any) => {
        const { yearly } = recurrence;
        if (
            yearly.endDateEnabled &&
            yearly.endDateEnabled !== recurrenceRange["yearly"].endDateEnabled
        ) {
            setRecurrenceRange({
                ...recurrence,
                yearly: {
                    ...recurrence["yearly"],
                    endDate: getDefaultRecurrenceEndTime({
                        startTime: recurrence["yearly"].startDate,
                        amount: 5,
                        unit: "years",
                    }),
                },
            });
        } else {
            setRecurrenceRange(recurrence);
        }
    };

    return (
        <>
            <div className="form-box mb-3">
                <h5 className="mb-4">Repeat Every</h5>
                <InputGroup className="mt-2">
                    <FormControl
                        value={repeatInterval.year}
                        onChange={handleRepeatFieldInput}
                    />
                    <InputGroup.Text>Year(s)</InputGroup.Text>
                </InputGroup>
                <HelpText
                    min={1}
                    max={EventConfigLimit.REPEAT_YEARLY_MAX}
                    timeUnit="year"
                />

                {renderErrorMsg("yearly", "repeat")}
            </div>
            <div className="form-box mb-3">
                <h5 className="mb-4">On</h5>

                <Button
                    variant="primary"
                    className="mb-3"
                    onClick={() => setYearDayModalShow(true)}
                >
                    Add a Day
                </Button>
                {renderMonthDates()}
            </div>
            <RangeOfRecurrenceTemplate
                recurrence={"yearly"}
                recurrenceRange={recurrenceRange}
                setRecurrenceRange={setYearlyRecurrenceRange}
                renderErrorMsg={renderErrorMsg}
                recurrenceErrorMsg={recurrenceErrorMsg}
            />
            <Modal
                show={yearDayModalShow}
                onHide={() => setYearDayModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Add a year day
                    </Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleCloseYearDayModal}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="add-a-day">
                            <div className="modal-option">
                                <Form.Check
                                    name="yearDay"
                                    id={"checked_day"}
                                    checked={checkedOption === "checked_day"}
                                    onChange={(e: any) => {
                                        setCheckedOption("checked_day");
                                    }}
                                    custom
                                    type="radio"
                                    className="float-left"
                                    label={
                                        <Row className="mt-2 mb-2">
                                            <Col sm={2}>
                                                <h4 className="mt-2">On</h4>{" "}
                                            </Col>
                                            <Col sm={5}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    value={onMonthOption}
                                                    onChange={(e: any) => {
                                                        setOnMonthOption(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="january">
                                                        January
                                                    </option>
                                                    <option value="february">
                                                        February
                                                    </option>
                                                    <option value="march">
                                                        March
                                                    </option>
                                                    <option value="april">
                                                        April
                                                    </option>
                                                    <option value="may">
                                                        May
                                                    </option>
                                                    <option value="june">
                                                        June
                                                    </option>
                                                    <option value="july">
                                                        July
                                                    </option>
                                                    <option value="august">
                                                        August
                                                    </option>
                                                    <option value="september">
                                                        September
                                                    </option>
                                                    <option value="october">
                                                        October
                                                    </option>
                                                    <option value="november">
                                                        November
                                                    </option>
                                                    <option value="december">
                                                        December
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                            <Col sm={5}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    value={onDayOption}
                                                    onChange={(e: any) => {
                                                        setOnDayOption(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {renderDates()}
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    }
                                />
                            </div>
                            <div className="modal-option mt-3">
                                <Form.Check
                                    name="yearDay"
                                    id={"checked_first_last"}
                                    checked={
                                        checkedOption === "checked_first_last"
                                    }
                                    onChange={(e: any) => {
                                        setCheckedOption("checked_first_last");
                                    }}
                                    custom
                                    type="radio"
                                    className="float-left"
                                    label={
                                        <Row className="mt-2 mb-1">
                                            <Col sm={2}>
                                                <h4 className="mt-2">On</h4>{" "}
                                            </Col>
                                            <Col sm={5}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    className="mb-2"
                                                    value={firstLastOption}
                                                    onChange={(e: any) => {
                                                        setFirstLastOption(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="first">
                                                        First
                                                    </option>

                                                    <option value="last">
                                                        Last
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                            <Col sm={5}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    value={weekdayOption}
                                                    onChange={(e: any) => {
                                                        setWeekdayOption(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="day">
                                                        Day
                                                    </option>
                                                    <option value="sunday">
                                                        Sunday
                                                    </option>
                                                    <option value="monday">
                                                        Monday
                                                    </option>
                                                    <option value="tuesday">
                                                        Tuesday
                                                    </option>
                                                    <option value="wednesday">
                                                        Wednesday
                                                    </option>
                                                    <option value="thursday">
                                                        Thursday
                                                    </option>
                                                    <option value="friday">
                                                        Friday
                                                    </option>
                                                    <option value="saturday">
                                                        Saturday
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                            <Col sm={2}>
                                                <h4 className="mt-2">of</h4>{" "}
                                            </Col>
                                            <Col sm={10}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    value={monthOption}
                                                    onChange={(e: any) => {
                                                        setMonthOption(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="january">
                                                        January
                                                    </option>
                                                    <option value="february">
                                                        February
                                                    </option>
                                                    <option value="march">
                                                        March
                                                    </option>
                                                    <option value="april">
                                                        April
                                                    </option>
                                                    <option value="may">
                                                        May
                                                    </option>
                                                    <option value="june">
                                                        June
                                                    </option>
                                                    <option value="july">
                                                        July
                                                    </option>
                                                    <option value="august">
                                                        August
                                                    </option>
                                                    <option value="september">
                                                        September
                                                    </option>
                                                    <option value="october">
                                                        October
                                                    </option>
                                                    <option value="november">
                                                        November
                                                    </option>
                                                    <option value="december">
                                                        December
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    }
                                />
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="float-right"
                        onClick={() => {
                            addDay();
                            setYearDayModalShow(false);
                        }}
                    >
                        ADD
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default Yearly;
