import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TimePicker from "@mui/lab/DesktopTimePicker";

import DateAdapter from "@mui/lab/AdapterMoment";
import TextField from "@mui/material/TextField";
import moment, { Moment } from "moment-timezone";

export const RangeOfRecurrenceTemplate = ({
    recurrence,
    recurrenceRange,
    setRecurrenceRange,
    renderErrorMsg,
    recurrenceErrorMsg,
}: any) => {
    const START_DATE = "start_date";
    const END_DATE = "end_date";
    const ENABLE_END_DATE = true;
    const [startDate, setStartDate] = useState<moment.Moment>(moment());
    const [startTime, setStartTime] = useState<moment.Moment>(moment());
    const [endDate, setEndDate] = useState<moment.Moment>(moment());
    const [endTime, setEndTime] = useState<moment.Moment>(moment());

    useEffect(() => {
        setStartDate(recurrenceRange[recurrence].startDate);
        setEndDate(recurrenceRange[recurrence].endDate);
        setStartTime(recurrenceRange[recurrence].startDate);
        setEndTime(recurrenceRange[recurrence].endDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderDate = (startOrEnd: any, enableEndDate?: any) => {
        const handleDateChange = (dateChanged: Moment | null) => {
            if (!dateChanged) return;

            let updatedData: any = {};
            if (startOrEnd === START_DATE) {
                setStartDate(dateChanged);
                updatedData.startDate = moment().set({
                    year: dateChanged?.year(),
                    month: dateChanged?.month(),
                    date: dateChanged?.date(),
                    hour: startTime.hour(),
                    minute: startTime.minute(),
                });
            } else {
                setEndDate(dateChanged);
                updatedData.endDate = moment().set({
                    year: dateChanged?.year(),
                    month: dateChanged?.month(),
                    date: dateChanged?.date(),
                    hour: endTime.hour(),
                    minute: endTime.minute(),
                });
            }

            setRecurrenceRange({
                ...recurrenceRange,
                [recurrence]: {
                    ...recurrenceRange[recurrence],
                    ...updatedData,
                },
            });
        };

        const handleTimeChange = (dateChanged: Moment | null) => {
            if (!dateChanged) return;

            let updatedData: any = {};
            if (startOrEnd === START_DATE) {
                setStartTime(dateChanged);
                updatedData.startDate = moment().set({
                    year: startDate?.year(),
                    month: startDate?.month(),
                    date: startDate?.date(),
                    hour: dateChanged?.hour(),
                    minute: dateChanged?.minute(),
                });
            } else {
                setEndTime(dateChanged);
                updatedData.endDate = moment(
                    recurrenceRange[recurrence].endDate
                ).set({
                    year: endDate?.year(),
                    month: endDate?.month(),
                    date: endDate?.date(),
                    hour: dateChanged?.hour(),
                    minute: dateChanged?.minute(),
                });
            }

            setRecurrenceRange({
                ...recurrenceRange,
                [recurrence]: {
                    ...recurrenceRange[recurrence],
                    ...updatedData,
                },
            });
        };

        if (enableEndDate) {
            return (
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <div className="date-and-time-picker mt-3">
                        <DesktopDatePicker
                            label="Date desktop"
                            inputFormat="DD/MM/YYYY"
                            value={recurrenceRange[recurrence].endDate}
                            onChange={handleDateChange}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    label=""
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                            )}
                        />

                        <TimePicker
                            label="Time"
                            inputFormat="h:mm A"
                            value={recurrenceRange[recurrence].endDate}
                            onChange={handleTimeChange}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    label=""
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                            )}
                        />
                    </div>
                </LocalizationProvider>
            );
        }

        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <div className="date-and-time-picker">
                    <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="DD/MM/YYYY"
                        value={recurrenceRange[recurrence].startDate}
                        onChange={(newValue: any) => {
                            handleDateChange(newValue);
                        }}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                label=""
                                InputLabelProps={{
                                    shrink: false,
                                }}
                            />
                        )}
                    />

                    <TimePicker
                        label="Time"
                        inputFormat="h:mm A"
                        value={recurrenceRange[recurrence].startDate}
                        onChange={handleTimeChange}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                label=""
                                InputLabelProps={{
                                    shrink: false,
                                }}
                            />
                        )}
                    />
                </div>
            </LocalizationProvider>
        );
    };

    return (
        <div className="form-box mb-3">
            <h5 className="mb-4">Range of Recurrence</h5>
            <div className="recurrence-start-time">
                <p>Start</p>
                {renderDate(START_DATE)}
            </div>
            <div className="recurrence-end-time-option">
                <p>End</p>
                <div className="end-time-check">
                    <Form.Check
                        name="end"
                        id={"no_end"}
                        custom
                        checked={
                            recurrenceRange[recurrence].endDateEnabled === false
                        }
                        type="radio"
                        label={<span className="ml-2">No end date</span>}
                        onChange={(e: any) => {
                            setRecurrenceRange({
                                ...recurrenceRange,
                                [recurrence]: {
                                    ...recurrenceRange[recurrence],
                                    endDateEnabled: false,
                                },
                            });
                        }}
                    />
                    <Form.Check
                        name="end"
                        id={"end_by"}
                        custom
                        checked={
                            recurrenceRange[recurrence].endDateEnabled === true
                        }
                        type="radio"
                        label={<span className="ml-2">End by</span>}
                        onChange={(e: any) => {
                            setRecurrenceRange({
                                ...recurrenceRange,
                                [recurrence]: {
                                    ...recurrenceRange[recurrence],
                                    endDateEnabled: true,
                                },
                            });
                        }}
                    />
                </div>
            </div>
            <div className="recurrence-start-time">
                <div></div>
                {recurrenceRange[recurrence].endDateEnabled
                    ? renderDate(END_DATE, ENABLE_END_DATE)
                    : ""}
            </div>
            {recurrenceErrorMsg && (
                <div className="recurrence-start-time mt-2">
                    <div></div>
                    <span className="text-secondary-red-1 text-size-12">
                        {recurrenceErrorMsg}
                    </span>
                </div>
            )}

            {renderErrorMsg(recurrence, "range")}
        </div>
    );
};
