import moment, { Moment } from "moment";
import { showErrorAlert } from "./alert";
import {
    Direction,
    EVENT_VARIABLE_TIMER_FORMAT,
    MAX_SECONDS_PER_DAY,
    MAX_STEP_SIZE_VALUE,
    MAX_TERMINAL_VALUE,
    TypeOption,
} from "constant";
import { isFloat, isInteger } from "./functions";
const getEventInfo = (
    schedule: any,
    range: any,
    points: any,
    interval: any
) => {
    switch (schedule) {
        case "all_day":
            return <p>All day</p>;
        case "between":
            let btwTimeStr = "";
            if (range.length > 1) {
                for (let t = 1; t < range.length; t++) {
                    btwTimeStr =
                        btwTimeStr +
                        `, ${moment(range[t][0], `HH:mm:ss`).format(
                            "h:mm A"
                        )} - ${moment(range[t][1], `HH:mm:ss`).format(
                            "h:mm A"
                        )}`;
                }
            }
            return (
                <p>
                    Between{" "}
                    {`${moment(range[0][0], `HH:mm:ss`).format(
                        "h:mm A"
                    )} - ${moment(range[0][1], `HH:mm:ss`).format("h:mm A")}`}
                    {btwTimeStr}
                </p>
            );
        case "before":
            return (
                <p>Before {moment(range[0][1], `HH:mm:ss`).format("h:mm A")}</p>
            );
        case "before_or_after":
            return (
                <p>
                    Before {moment(range[0][1], `HH:mm:ss`).format("h:mm A")} or
                    after {moment(range[1][0], `HH:mm:ss`).format("h:mm A")}
                </p>
            );
        case "every":
            return <p>{`Every ${interval} min(s)`}</p>;
        case "at":
            let timeStr = "";
            if (points.length > 1) {
                for (let t = 1; t < points.length; t++) {
                    timeStr =
                        timeStr +
                        `, ${moment(points[t], `HH:mm:ss`).format(`h:mm A`)}`;
                }
            }
            return (
                <p>
                    At {moment(points[0], `HH:mm:ss`).format("h:mm A")}
                    {timeStr}
                </p>
            );

        case "after":
            return (
                <p>After {moment(range[0][0], `HH:mm:ss`).format("h:mm A")}</p>
            );
    }
};

export const isValid = (event: any, gateways: any) => {
    const invalid = (event.conditions || [])
        .filter(
            ({ resource: { device }, type_ }: any) =>
                !!device && type_ !== "VARIABLE"
        )
        .some((condition: any) => {
            const {
                type_,
                resource: { device: { gateway_id = "", device_id = "" } = {} },
            } = condition;

            const gateway: any = gateways.find(
                ({ gateway_id: g_id }: any) => g_id === gateway_id
            );
            const ldsu: any = gateway
                ? gateway.configs.find((l: any) => {
                      return l.UID === device_id;
                  })
                : null;

            let valid = false;

            if (!gateway || (type_ !== "GATEWAY" && !ldsu)) {
                valid = true;
            }

            return valid;
        });

    return !invalid;
};

export const showWarning = () => {
    return showErrorAlert({
        message: "Encounter some error with Event condition(s) or Action(s).",
    });
};

export default getEventInfo;

export const getTypeOptionKeyByValue = (value: string): string => {
    for (const key in TypeOption) {
        if (TypeOption[key as keyof typeof TypeOption] === value) {
            return key;
        }
    }
    return "";
};

export const getScheduledTimeAsSeconds = (scheduledTime: string) => {
    return scheduledTime === "00:00:00"
        ? MAX_SECONDS_PER_DAY
        : moment.duration(scheduledTime).asSeconds().toString();
};

const isExceedRange = (value: number, min: number, max: number) =>
    value < min || value > max;

export const validateEventVariableValue = (
    eventVariableType: TypeOption,
    value: string
) => {
    let errorMsg = "";
    const MAX_INITIAL_VALUE = 65535;

    switch (eventVariableType) {
        case TypeOption.Integer:
            if (
                !isInteger(value) ||
                isExceedRange(Number(value), 0, MAX_INITIAL_VALUE)
            ) {
                errorMsg = `Invalid ${eventVariableType.toLowerCase()} value. Please input an integer value between 0 and ${MAX_INITIAL_VALUE}.`;
            }
            break;
        case TypeOption.Float:
            if (
                !isFloat(value) ||
                isExceedRange(Number(value), 0, MAX_INITIAL_VALUE)
            ) {
                errorMsg = `Invalid ${eventVariableType.toLowerCase()} value. Please input a value between 0 and ${MAX_INITIAL_VALUE} with up to 2 decimal places.`;
            }
            break;
        case TypeOption.Counter:
            if (
                !isInteger(value) ||
                isExceedRange(Number(value), 0, MAX_INITIAL_VALUE)
            ) {
                errorMsg = `Invalid initial value. Please input an integer value between 0 and ${MAX_INITIAL_VALUE}.`;
            }
            break;
        case TypeOption.Timer:
            if (
                moment(value, EVENT_VARIABLE_TIMER_FORMAT).format(
                    EVENT_VARIABLE_TIMER_FORMAT
                ) !== value
            ) {
                errorMsg = `Invalid timer value. Please input a value between 00:00:01 and 23:59:59, and in ${EVENT_VARIABLE_TIMER_FORMAT} format.`;
            }
            break;
    }

    return errorMsg;
};

export const validateTerminalValue = (
    value: string,
    initialValue: string,
    direction: Direction
) => {
    let errorMessage = "";

    const terminalValue = Number(value);
    const maxValue = Number(MAX_TERMINAL_VALUE);
    const initialVal = Number(initialValue);

    const isInvalidValue =
        !isInteger(value) || isExceedRange(terminalValue, 0, maxValue);
    const isInvalidDirection = direction
        ? terminalValue <= initialVal
        : initialVal <= terminalValue;

    if (isInvalidValue || isInvalidDirection) {
        errorMessage += direction
            ? `Invalid terminal value. Please input an integer value between 0 and ${MAX_TERMINAL_VALUE}, and the terminal value cannot be less than or equal to the initial value.`
            : `Invalid terminal value. Please input an integer value between 0 and ${MAX_TERMINAL_VALUE}, and the terminal value cannot be greater than or equal to the initial value.`;
    }

    return errorMessage;
};

export const validateStepSizeValue = (
    value: string,
    initialValue: string,
    terminalValue: string
) => {
    // Calculate the absolute difference between the initial and terminal values
    const difference = Math.abs(Number(terminalValue) - Number(initialValue));

    // Check if the difference is a multiple of the step size
    if (
        !isInteger(value) ||
        isExceedRange(Number(value), 1, Number(MAX_STEP_SIZE_VALUE)) ||
        difference % Number(value) !== 0
    ) {
        return `Invalid step size value. Please input an integer value between 1 and ${MAX_STEP_SIZE_VALUE}, and the absolute difference between the initial value and terminal value must be a multiple of step size.`;
    }

    return "";
};

export const convertBEOperator = (operator: string, edgeTrigger = false) => {
    return edgeTrigger ? `${operator}=` : operator;
};

export const getDefaultRecurrenceEndTime = ({
    startTime,
    amount,
    unit,
}: {
    startTime: Moment;
    amount: number;
    unit: moment.unitOfTime.DurationConstructor;
}) => {
    return moment(startTime)
        .add(amount, unit)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
};

export const getInitialValue = (
    eventVariableType: TypeOption,
    initialValue: string
) => {
    switch (eventVariableType) {
        case TypeOption.Timer:
            return getScheduledTimeAsSeconds(initialValue).trim();
        case TypeOption.Counter:
        case TypeOption.Integer:
            return Number(initialValue).toString().trim();
        case TypeOption.Float:
            return Number(initialValue).toFixed(2);
        default:
            return initialValue.trim();
    }
};
