import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { updateAddress } from "service/subscriptionService";
import "assets/css/subscription.css";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { isHttpSuccess } from "utils/functions";
import { AddressPostCountry } from "generated/models";
import { initBillingAddress } from "utils/subscriptionFunctions";
import { useSubscriptionContext } from "context/subscriptionContext";
import { BillingAddressProp } from "types/Subscription";
import BillingAddressCheckoutForm from "components/subscriptions/BillingAddressCheckoutForm";
import { isEmpty } from "lodash";

const BillingAddress = () => {
    const {
        subscription: { address },
    }: any = useSubscriptionContext();

    const [billingAddress, setBillingAddress] = useState<BillingAddressProp>(
        initBillingAddress()
    );
    const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);

    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    useEffect(() => {
        const fetchAddress = async () => {
            setBillingAddress({
                first_name: address?.first_name,
                last_name: address?.last_name,
                city: address?.city,
                street: address?.street,
                line3: address?.line3,
                country: address?.country,
                code: address?.code,
                extended: address?.extended || "",
                region: address?.region || "",
            });
        };
        fetchAddress();
    }, [address]);

    const handleUpdate = async () => {
        setIsUpdatingAddress(true);

        const body = {
            first_name: billingAddress.first_name.trim(),
            last_name: billingAddress.last_name.trim(),
            city: billingAddress.city.trim(),
            street: billingAddress.street.trim(),
            line3: billingAddress.line3.trim(),
            country: billingAddress.country.trim() as AddressPostCountry,
            code: billingAddress.code.trim(),
            extended: billingAddress?.extended?.trim(),
            region: billingAddress?.region?.trim(),
        };

        const response: any = await updateAddress(address.uuid, body);

        if (isHttpSuccess(response.status)) {
            setIsUpdatingAddress(false);
            setSimpleModalData({
                resObj: response,
                body: `Successfully updated billing address.`,
            } as SimpleModalDataType);
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);

            setIsUpdatingAddress(false);
        }
    };

    const [addressError, setAddressError] = useState({});

    const updateAddressError = (newAddressError: any) => {
        setAddressError(newAddressError);
    };

    const updateBillingAddress = (newBillingAddress: any) => {
        setBillingAddress((prevBillingAddress) => ({
            ...prevBillingAddress,
            ...newBillingAddress,
        }));
    };

    const billingAddressCheckoutFormProps: any = {
        billingAddress,
        updateBillingAddress,
        updateAddressError,
    };

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <div className="subscription-page billing-address-component">
                <Container className="subscription-container">
                    <Row>
                        <Col xs="12">
                            <BillingAddressCheckoutForm
                                {...billingAddressCheckoutFormProps}
                            />

                            <Button
                                variant="primary"
                                className="mt-4"
                                onClick={handleUpdate}
                                disabled={!isEmpty(addressError)}
                            >
                                UPDATE
                            </Button>
                        </Col>
                    </Row>

                    <Modal
                        centered
                        show={isUpdatingAddress}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="example-modal-sizes-title-sm"
                        className="no-header"
                    >
                        <Modal.Body className="text-center mt-3 mb-5 mr-4">
                            <Spinner
                                className="centered-spinner"
                                animation="border"
                                variant="primary"
                            />
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default BillingAddress;
