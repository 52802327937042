import { cloneElement, isValidElement, useRef } from "react";
import PropTypes from "prop-types";
import { useIntersection } from "utils/useIntersection";
function VisibleView(props: any) {
    const { rootMargin = "-100px", children } = props;
    const visibleRef: any = useRef();
    const isVisible = useIntersection(visibleRef, rootMargin);
    if (isValidElement(children)) {
        const Container = cloneElement<any>(children, {
            isVisible,
            visibleRef,
        });
        return Container;
    }
    return children;
}

VisibleView.propTypes = {
    rootMargin: PropTypes.string,
    children: PropTypes.element,
};

export default VisibleView;
