import { flatten, forEach, isNumber, sortBy } from "lodash";
import { Col } from "react-bootstrap";
import { uniqBy, get, isEmpty } from "lodash";
import {
    getDeviceListByGatewayId,
    getGatewayAlertNotification,
    getGwAndLDSUStatus,
    getRegistryGroups,
} from "service/gatewayService";
import LocalStorageService from "service/localStorageService";
import { GatewayDeviceList, PortStatus, SensorType } from "types/Gateways";
import { ActuatorObjectList, HeartbeatUpdateAfter } from "constant";
import {
    clsToAttributeTable,
    getPrimaryUnit,
    getPrimaryUnitKey,
    getPrimaryUnitName,
    isHttpSuccess,
    isActuator,
} from "utils/functions";
import { MemberDto } from "types";

/**
 * Render activity status for gateway
 * If the difference between current time and heartbeat is > 120 (2 minutes), render offline
 * @param { number } heartbeat - unix timestamp
 * @param { boolean } info - status of information
 * @return { JSX } - Time is included if it is online
 */

export const checkActive = (heartbeat: number, info: boolean) => {
    if (info) {
        // Heartbeat exists
        // Get user current unixtime
        const timestamp = Math.round(new Date().getTime() / 1000);
        const userDate = new Date().toLocaleDateString("en-GB");
        const date = new Date(heartbeat * 1000).toLocaleDateString("en-GB");
        const time = new Date(heartbeat * 1000).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        });
        const difference = timestamp - heartbeat;
        if (userDate === date) {
            return (
                <>
                    <i
                        className={`material-icons circle ${
                            difference < HeartbeatUpdateAfter
                                ? "online"
                                : "offline"
                        }`}
                    >
                        circle
                    </i>

                    {difference < HeartbeatUpdateAfter
                        ? `Online`
                        : `Offline from today ${time}`}
                </>
            );
        }
        return (
            <>
                <i
                    className={`material-icons circle ${
                        difference < HeartbeatUpdateAfter ? "online" : "offline"
                    }`}
                >
                    circle
                </i>

                {difference < HeartbeatUpdateAfter
                    ? `Online`
                    : `Offline from ${date} ${time}`}
            </>
        );
    }
    return (
        // No info means it is not onboarded
        <>
            <i className="material-icons circle offline">circle</i>
            Not On-boarded
        </>
    );
};

/**
 * Render activity status for gateway
 * If the difference between current time and heartbeat is > 120 (2 minutes), render offline
 * @param { number } heartbeat - unix timestamp
 * @param { boolean } info - status of information
 * @return { JSX } - Time is not included.
 */

export const checkActiveStatus = (heartbeat: number, info: boolean) => {
    if (info) {
        // Heartbeat exists
        // Get user current unixtime
        const timestamp = Math.round(new Date().getTime() / 1000);
        const difference = timestamp - heartbeat;
        return (
            <>
                <i
                    className={`material-icons circle ${
                        difference < HeartbeatUpdateAfter ? "online" : "offline"
                    }`}
                >
                    circle
                </i>
                {difference < HeartbeatUpdateAfter ? "Online" : "Offline"}
            </>
        );
    }
    return (
        // No info means it is not onboarded
        <Col md={{ offset: 0, order: 4 }} xs={{ span: 10, order: 6 }}>
            <i className="material-icons circle offline">circle</i>
            Not On-boarded
        </Col>
    );
};

export const getSensorTypes = (devices: any[] = []): SensorType[] => {
    const devicesTypes: SensorType[] = devices.reduce((acc: any, d: any) => {
        let types = {
            id: d.sensor_type,
            name: d.sensor_type,
        };

        if (d.APP) {
            types.id = d.APP[d.SAID].NAME;
            types.name = d.APP[d.SAID].NAME;
        }

        acc.push(types);
        return acc;
    }, []);

    return sortBy(uniqBy(devicesTypes, "name"), "name");
};

export const fetchGWStatus = async (gatewayId: string) => {
    const res: any = await getGwAndLDSUStatus(gatewayId);

    if (res.status === 200) {
        const { heartbeat = null } = res.data;
        if (heartbeat) {
            const timestamp = Math.round(new Date().getTime() / 1000);
            const difference = timestamp - heartbeat;
            return difference < HeartbeatUpdateAfter;
        }
    }
    return false;
};

const getLdsuList = (
    registryInfoList: any,
    configList: any,
    gwLDSUStatusList: any
) => {
    const combined: any = {};
    [registryInfoList, configList, gwLDSUStatusList].forEach((arr) => {
        arr.forEach((item: any) => {
            if (!combined[item.UID]) {
                combined[item.UID] = {};
            }
            Object.assign(combined[item.UID], item);
        });
    });

    return uniqBy(Object.values(combined), "uuid");
};

const getSensorList = (ldsuList: any, jsonList: any) => {
    let sensorFilteredLst: any = [];

    ldsuList.forEach((i: any) => {
        jsonList.forEach((j: any) => {
            if (i.OBJ === j.OBJ) {
                for (let k = 0; k < i?.SAIDS?.length; k++) {
                    // Could be (1)IO controller or (2) sensor

                    // === IF it's IO controller sensor && has been configured ===
                    if (i.APP && !isEmpty(i.APP[k]) && j.SNS[k]?.CLS < 32768) {
                        const defaultUnit = getPrimaryUnitName(i.APP[k]);
                        let body: any = {
                            ldsu_uuid: i.UID,
                            ldsu_name: i.name,
                            SAID: k,
                            app_id: i.APP[k]?.APPID,
                            obj: i.OBJ,
                            cls: j.SNS[k].CLS,
                            sensor_name: i.sensor_names[k],
                            sensor_type: i.APP[k]?.NAME,
                            primaryUnitKey: getPrimaryUnitKey(i.APP[k]),
                            unit: getPrimaryUnit(i.APP[k]),
                            minmax: [defaultUnit.MIN, defaultUnit.MAX],
                            accuracy: defaultUnit.ACCURACY,
                        };

                        sensorFilteredLst.push(body);
                        // === IF it's sensor ===
                    } else {
                        if (!i.APP && j.SNS[k]?.CLS < 32768) {
                            let body: any = {
                                ldsu_uuid: i.UID,
                                ldsu_name: i.name,
                                SAID: k,
                                MODE: i.MODES[k],
                                obj: i.OBJ,
                                cls: j.SNS[k].CLS,
                                sensor_name: i.sensor_names[k],
                                sensor_type: get(
                                    clsToAttributeTable(),
                                    j.SNS[k]?.CLS
                                ),
                                unit: j.SNS[k]?.UNIT,
                                minmax: [j.SNS[k]?.MIN, j.SNS[k]?.MAX],
                                accuracy: j.SNS[k]?.ACCURACY
                                    ? j.SNS[k]?.ACCURACY
                                    : j.SNS[k]?.MODE?.[0]?.ACCURACY,
                            };
                            // If the sensor has opmode
                            if (j.SNS[k]?.MODE) {
                                body["opmodes"] = [j.SNS[k]?.MODE[0]];
                            }

                            sensorFilteredLst.push(body);
                        }
                    }
                }
            }
        });
    });
    return sensorFilteredLst;
};

const getActuatorList = (
    registryInfoList: any,
    configList: any,
    gwLDSUStatusList: any,
    jsonList: any,
    busNumber: string
) => {
    let actuatorFilteredLst: any = [];

    let extractedActuatorLst = registryInfoList.filter((i: any) =>
        ActuatorObjectList.includes(i.OBJ)
    );

    extractedActuatorLst.forEach((i: any) => {
        configList.forEach((j: any) => {
            gwLDSUStatusList?.forEach((k: any) => {
                if (
                    i.UID === j.UID &&
                    i.PORT === busNumber &&
                    i.UID === k?.UID
                ) {
                    let body = {
                        UID: i.UID,
                        OBJ: i.OBJ,
                        SAID: j.SAIDS,
                        sensor_name: j.sensor_names,
                        CMDS: j.CMDS,
                        timestamp: k.ts,
                        status: k.value,
                        APP: i.APP,
                    };
                    actuatorFilteredLst.push(body);
                }
                return null;
            });
        });
    });

    const filteredJsonList = jsonList.filter((j: any) => {
        for (let k of j.SNS) {
            // CLS >= 32768 are actuators, filter out 256 (old sensor not removed by BE yet)
            if (Number(k.CLS) >= 32768 && j.OBJ !== "256") {
                return true;
            }
        }

        return false;
    });

    let allActuators: any = [];
    for (let a of actuatorFilteredLst) {
        for (let j of filteredJsonList) {
            if (a.OBJ === j.OBJ) {
                let actuatorIndex = [];

                for (let k = 0; k < j.SNS.length; k++) {
                    if (Number(j.SNS[k]?.CLS) >= 32768) {
                        // Actuators can be (1) IO controller actuators, (2) other actuators

                        // === IO controller ===

                        if (a.APP && !isEmpty(a.APP[k])) {
                            actuatorIndex.push({
                                index: k,
                                type: a.APP[k]?.NAME,
                            });
                        } else {
                            // === actuators ===
                            if (!a.APP) {
                                actuatorIndex.push({
                                    index: k,
                                    type: get(
                                        clsToAttributeTable(),
                                        j.SNS[k]?.CLS
                                    ),
                                });
                            }
                        }
                    }
                }
                let CMDS = [];
                for (let i of actuatorIndex) {
                    CMDS.push(a.CMDS[i.index]);
                    allActuators.push({
                        ...a,
                        CMDS,
                        name: a.sensor_name[i.index],
                        type: i.type,
                        info: a.CMDS[i.index],
                        said: i.index,
                        CLS: j.SNS[i.index].CLS,
                    });
                }
            }
        }
    }
    return allActuators;
};

export const getLDSBusInfo = (
    busNumber: string,
    registryInfoList: any[],
    configList: any[],
    gwLDSUStatusList: any[]
) => {
    let jsonList = LocalStorageService.getItem("listOfJSON");

    const ldsuList = getLdsuList(
        registryInfoList,
        configList,
        gwLDSUStatusList
    ).filter((l: any) => l.PORT === busNumber);

    const sensorList = getSensorList(ldsuList, jsonList);

    const actuatorList = getActuatorList(
        registryInfoList,
        configList,
        gwLDSUStatusList,
        jsonList,
        busNumber
    );

    return {
        ldsus: ldsuList,
        sensors: sensorList,
        actuators: actuatorList,
    };
};

export const fetchGWStatuses = async (gatewayIdArr: any = []) => {
    const unresolvedStatuses: any = gatewayIdArr.map(async (g: any) => {
        return await fetchGWStatus(g);
    });
    const statuses = await Promise.all(unresolvedStatuses);
    return statuses;
};

export const formatReading = (reading: number, decimal: number) => {
    if (reading === undefined) {
        return "-";
    } else if (isNumber(reading) && decimal) {
        return reading.toFixed(decimal);
    }

    return reading;
};

export const decimalPlaceMatches = (reading: any, acceptedDecimal: any) => {
    return (
        (reading.toString().split(".")[1]?.length || 0) <=
        Number(acceptedDecimal)
    );
};

export const extractedIdList = (resource: MemberDto[] = []) => {
    return resource.map((member) => member.username);
};

export const convertIdToUserInfoArray = (
    orgList: MemberDto[],
    resourceList: string[]
) => {
    return orgList.reduce((acc: MemberDto[], member: MemberDto) => {
        if (resourceList.includes(member.username)) {
            acc.push(member);
        }
        return acc;
    }, []);
};

export const getPowerStatusText = (power: boolean, status: PortStatus) => {
    let powersStatus = power ? "On" : "Off";

    if (isFaulty(status)) {
        powersStatus = "Faulty";
    }

    return powersStatus;
};

export const isFaulty = (status: PortStatus = PortStatus.On) => {
    return [
        PortStatus.FaultyAll,
        PortStatus.FaultyHardware,
        PortStatus.FaultyIndividual,
    ].includes(status);
};

export const fetchDevices = async (): Promise<GatewayDeviceList> => {
    const result = {} as GatewayDeviceList;

    const registryRes = await getRegistryGroups();

    if (isHttpSuccess(registryRes.status)) {
        const gateWayList: any[] = [];
        const data = get(registryRes, "data", []);
        forEach(data, (group) => {
            let groupName = get(group, "name", "Standalone");
            groupName = groupName === "ungrouped" ? "Standalone" : groupName;
            get(group, "gateways", []).forEach((gateway: any) => {
                gateWayList.push({
                    ...gateway,
                    groupName,
                });
            });
        });

        result.allRegistry = gateWayList;

        const deviceListRes = await Promise.all(
            gateWayList.map((gateway) => {
                return getDeviceListByGatewayId(gateway.gateway_id);
            })
        );

        const responses = await Promise.all(
            result.allRegistry.map((registry) =>
                getGatewayAlertNotification(registry.gateway_id)
            )
        );

        const deviceAlerts = flatten(
            responses.map((response) => get(response, "data", []))
        );

        result.deviceList = flatten(deviceListRes ?? []).map((device) => {
            let device_unit = "";

            if (device.APP) {
                const appSensor = device.APP.find(
                    (d: any) => d.APPID === device.APPID
                );
                device_unit = getPrimaryUnit(appSensor) ?? "";
            } else {
                device_unit = device.unit;
            }

            return {
                device_id: device.ldsu_uuid + "/" + device.SAID,
                ...device,
                unit: device_unit,
                busName: "{gatewayName} - LDS BUS {bus}".fill(device),
                isActuator: isActuator(device.CLS),
                alert_available: deviceAlerts.find(
                    (alert) =>
                        device.ldsu_uuid === alert.device_id &&
                        device.SAID === alert.said
                )?.enabled,
            };
        });
    }

    return result;
};
