import { Button } from "react-bootstrap";

export const SelectedButton = (props: any) => {
    return (
        <Button
            variant={props.selected === props.text ? "primary" : "secondary"}
            aria-selected={props.selected === props.text}
            onClick={() => props.handleOnClick(props.text)}
            role="tab"
        >
            {props.text}
        </Button>
    );
};
