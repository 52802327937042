import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import EventService from "service/eventService";

import SendSMS from "components/events/ActionDetail/SendSMS";
import SendEmail from "components/events/ActionDetail/SendEmail";
import SendNotification from "components/events/ActionDetail/SendNotification";
import TriggerActuator from "components/events/ActionDetail/TriggerActuator";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { canAccess } from "utils/authorize-action";
import ModifyEventVariable from "components/events/ConditionDetail/ModifyEventVariable";

import "assets/css/event.css";
import { HttpStatus } from "constant";

const ActionDetails = (props: any) => {
    const params: any = useParams();
    const [isForbiddenResource, updateIsForbiddenResource] = useState(false);

    useEffect(() => {
        const fetchOneEvent = async () => {
            const res: any = await EventService.readOneEvent(params.eventId);
            if (res.status === HttpStatus.FORBIDDEN) {
                updateIsForbiddenResource(true);
            }
        };

        if(!canAccess("event:read")){
            updateIsForbiddenResource(true);
        } else {
            fetchOneEvent()
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.eventId, window.authorizationConfig]);

    const renderActions = () => {
        if (params.action === "sms") {
            return <SendSMS />;
        } else if (params.action === "email") {
            return <SendEmail />;
        } else if (params.action === "notification") {
            return <SendNotification />;
        } else if (params.action === "actuator") {
            return <TriggerActuator />;
        } else if (params.action === "event-variable") {
            return <ModifyEventVariable />
        }
    };
    return (
        <ContentWrapper isForbiddenResource={isForbiddenResource}>
            <div className="page-content">{renderActions()}</div>
        </ContentWrapper>
    );
};

export default ActionDetails;
