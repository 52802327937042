import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as ManageAPIAccessKeysSvg } from "assets/svg/manage-api-access-key.svg";
import { ReactComponent as TimezoneSVG } from "assets/svg/timezone.svg";
import "assets/css/settings.css";
import { useAppContext } from "context/appContext";
import TimezoneDescription from "components/settings/TimezoneDescription";
import { ArrowIcon, RowBox } from "components/common";

const pathToManageAccessKeys: string = "/settings/manage-api-access-keys";
const pathToTimezone: string = "/settings/timezone";

const Settings = () => {
    const {
        storeData: {
            organization: {
                currentOrgInfo: { isDefault, name },
                currentUserIsOwner,
            },
        },
    } = useAppContext();

    const renderText = () => {
        if (isDefault) {
            return `You can use this Access Keys for your personal account only. For your organisations, please switch to an organisation and create Access Keys under that organisation.`;
        } else {
            return (
                <>
                    You can use this Access Keys for your organisation{" "}
                    <span className="default-white-color">{name}</span> only.
                    For other organisations or personal account, please switch
                    to it and create Access Keys under that
                    organisation/personal account.
                </>
            );
        }
    };

    return (
        <div className="page-content">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h5 className="page-title">Settings</h5>
                    </Col>
                </Row>
                <Row className="cstm-table">
                    <Col sm="12">
                        <RowBox className="d-flex align-items-center">
                            <Col
                                md={{ span: 1 }}
                                xs={{ span: 2 }}
                                className="align-self-start text-left"
                            >
                                <TimezoneSVG />
                            </Col>

                            <Col className="height-auto">
                                <Link
                                    to={{
                                        pathname: pathToTimezone,
                                    }}
                                >
                                    Time Zone Settings{" "}
                                </Link>
                                <div className="mt-4">
                                    <TimezoneDescription />
                                </div>
                            </Col>
                            <Col
                                md={{ span: 1, order: "last" }}
                                xs={{ span: 1, order: 2 }}
                                className="text-right"
                            >
                                <Link
                                    to={{
                                        pathname: pathToTimezone,
                                    }}
                                >
                                    <ArrowIcon />
                                </Link>
                            </Col>
                        </RowBox>
                        <fieldset disabled={!currentUserIsOwner}>
                            <RowBox className="d-flex align-items-center">
                                <Col
                                    md={{ span: 1 }}
                                    xs={{ span: 2 }}
                                    className="align-self-start text-left"
                                >
                                    <ManageAPIAccessKeysSvg />
                                </Col>

                                <Col className="height-auto">
                                    <Link
                                        to={{
                                            pathname: pathToManageAccessKeys,
                                        }}
                                    >
                                        Manage API Access Keys{" "}
                                    </Link>
                                    <p className="reusable-description mt-4">
                                        Use API access key to retrieve and
                                        analyze the sensor data of gateways and
                                        send commands to actuators on your
                                        application or script.
                                        <br />
                                        {renderText()}
                                    </p>
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 2 }}
                                    className="text-right"
                                >
                                    <Link
                                        to={{
                                            pathname: pathToManageAccessKeys,
                                        }}
                                    >
                                        <ArrowIcon />
                                    </Link>
                                </Col>
                            </RowBox>
                        </fieldset>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Settings;
