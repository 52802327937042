import { Dropdown, Form } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import "assets/css/daterangepicker.css";
import { ChangeEvent, FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import styledConst from "styles";
import { forEach, map } from "lodash";
import localStorageService from "service/localStorageService";
import { getLocalTimezone } from "utils/functions";

type TimezoneDropdownProps = {
    value?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    className?: string;
};

const CustomDropDown = styled(Dropdown)`
    > .dropdown-toggle {
        color: ${styledConst.Neutral_Grey_2};
        background-color: #f0f0f0;
        border-color: #f0f0f0;
        padding: 0.375rem;
        text-align: left;
        width: 100%;

        &.btn-primary:hover,
        &.btn-primary:not(:disabled):not(.disabled):active,
        &.btn-primary:focus {
            color: ${styledConst.Neutral_Grey_2};
            background-color: #f0f0f0;
            border-color: #f0f0f0;
        }

        &::after {
            display: none;
        }
    }

    &.dark {
        > .dropdown-toggle {
            color: #ffffff;
            background-color: #242d41;
            border-color: #36425c;

            &.btn-primary:hover,
            &.btn-primary:not(:disabled):not(.disabled):active,
            &.btn-primary:focus {
                color: #ffffff;
                background-color: #242d41;
                border-color: #36425c;
            }

            .material-icons {
                color: ${styledConst.Primary_Blue_4};
            }
        }

        &.show {
            > .dropdown-toggle {
                color: #ffffff;
                background-color: #242d41;
                border-color: #36425c;
            }
        }

        .dropdown-menu {
            background: #242d41;
        }

        .dropdown-item {
            color: #ffffff;
        }

        .form-control.search {
            background-color: #242d41 !important;
            border-color: ${styledConst.Primary_Blue_2}!important;
            color: ${styledConst.Primary_Blue_4}!important;
        }
    }

    &.show {
        > .dropdown-toggle {
            color: ${styledConst.Neutral_Grey_2};
            background-color: #f0f0f0;
            border-color: #f0f0f0;
        }
    }

    .dropdown-menu-content {
        overflow-y: scroll;
        max-height: 300px;

        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: #d9d9d9;
        }
    }

    .dropdown-item.active{
        background-color: ${styledConst.Primary_Green_1};
    }

    .form-control.search {
        background-color: #ffffff !important;
        border-color: #f0f0f0 !important;
        color: #2f3a51 !important;
    }

    @media screen and (max-width: 768px) {
        .dropdown-header,
        .dropdown-item {
            padding: 0.5rem;
        }

        .dropdown-menu-content {
            max-height: 250px;
        }
    }
`;

const TimezoneDropdown: FC<TimezoneDropdownProps> = ({
    value,
    onChange,
    disabled,
    ...restProps
}) => {
    const [selected, setSelected] = useState<string>(getLocalTimezone());
    const [searchText, setSearchText] = useState("");
    const [timeZoneDict, setTimeZoneDict] = useState<Record<string, string>>(
        localStorageService.getItem("timezones")
    );
    const timeZoneObject = localStorageService.getItem("timezones");

    const displayTimezone = (timezone: string) => {
        const timeOffset = timeZoneObject[timezone];
        return `${timezone} (UTC ${timeOffset})`;
    };

    useEffect(() => {
        setTimeZoneDict(timeZoneObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        value && setSelected(value);
    }, [value]);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchText(value);
        _filter(value);
    };

    const _filter = (search: string) => {
        let filtered: Record<string, string> = {};
        forEach(timeZoneObject, (value: string, key: string) => {
            if (
                !search ||
                `${key} (UTC ${value})`.toLowerCase().includes(search.toLowerCase())
            ) {
                filtered[key] = value;
            }
        });

        setTimeZoneDict(filtered);
    };

    const handleSelect = (value: string | null, e: Object) => {
        const _value = value ?? "";
        setSelected(_value);
        onChange?.(_value);
    };

    const onToggle = (isOpen: boolean) => {
        if (!isOpen) {
            setSearchText("");
            _filter("");
        }
    };

    return (
        <CustomDropDown
            {...restProps}
            onSelect={handleSelect}
            onToggle={onToggle}
        >
            <Dropdown.Toggle disabled={disabled}>
                {selected && displayTimezone(selected)}
                <i className="material-icons float-right">expand_more</i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
                <Dropdown.Header>
                    <Form.Control
                        autoFocus
                        className="search w-100"
                        placeholder="Search for region/city"
                        aria-label="search"
                        value={searchText}
                        onChange={handleSearch}
                    />
                </Dropdown.Header>
                <div className="dropdown-menu-content">
                    {map(timeZoneDict, (value: string, key: string) => (
                        <Dropdown.Item
                            key={key}
                            eventKey={key}
                            active={key === selected}
                        >
                            {key} (UTC {value})
                        </Dropdown.Item>
                    ))}
                </div>
            </Dropdown.Menu>
        </CustomDropDown>
    );
};

export default TimezoneDropdown;
