import moment from "moment";
import { convertPrometheusTimeToSeconds } from "utils/functions";

const CHART_CUSTOM_TIME_RANGE_FORMAT = "DD/MM/YYYY hh:mm A";

export const CustomRangeDetails = ({ lastTimeRangeSelect }: any) => {
    return (
        <div className="custom-range-info">
            {moment(lastTimeRangeSelect.query_time, "X")
                .subtract(
                    convertPrometheusTimeToSeconds(
                        lastTimeRangeSelect.time_range
                    ),
                    "second"
                )
                .format(CHART_CUSTOM_TIME_RANGE_FORMAT)}{" "}
            <br />-{" "}
            {moment(lastTimeRangeSelect.query_time, "X").format(
                CHART_CUSTOM_TIME_RANGE_FORMAT
            )}
        </div>
    );
};
