import { Col, InputGroup, Button, FormControl, Form } from "react-bootstrap";
import moment from "moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/DesktopTimePicker";
import DateAdapter from "@mui/lab/AdapterMoment";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useState } from "react";
import { faker } from "@faker-js/faker";
import {
    TimeSchedule,
    ERROR_DUPLICATE,
    ERROR_INVALID,
    ERROR_BEFORE_OR_AFTER,
    EventConfigLimit,
    Patterns,
    ERROR_TIME_RANGE,
} from "constant";
import { HelpText } from "../../modals/EventModals/HelpTextModal";

export const TimeScheduleTemplate = ({
    priTime,
    setPriTime,
    secTime,
    setSecTime,
    timeList,
    setTimeList,
    timeRangeList,
    setTimeRangeList,
    duration,
    setDuration,
    selectedTimeOption,
    setSelectedTimeOption,
    errorType,
    setErrorType,
    day,
    renderErrorMsg,
    recurrence,
}: any) => {
    const [_errorMessage, _setErrorMessage] = useState<null | string>("");
    const [appointment, setAppointment] = useState(moment().startOf("day"));
    const [startTime, setStartTime] = useState(moment().startOf("day"));
    const [endTime, setEndTime] = useState(moment().startOf("day"));

    const deleteTime = (index: number, type: string) => {
        if (type === "time") {
            const cloneTimeList = [...timeList[day]];
            cloneTimeList.splice(index, 1);
            const newTimeList = { ...timeList };
            newTimeList[day] = cloneTimeList;
            setTimeList(newTimeList);
        } else if (type === "range") {
            const cloneTimeRangeList = [...timeRangeList[day]];
            cloneTimeRangeList.splice(index, 1);
            const newTimeRangeList = { ...timeRangeList };
            newTimeRangeList[day] = cloneTimeRangeList;
            setTimeRangeList(newTimeRangeList);
        }
    };

    const addToTimeList = (type: string) => {
        if (type === "time") {
            const cloneTimeList: any = [...timeList[day]];

            const newTime = appointment.format("HH:mm:ss");

            if (!moment(newTime, "HH:mm:ss").isValid()) {
                setErrorType({
                    day: day,
                    errorType: ERROR_INVALID,
                });
                return;
            }

            if (cloneTimeList.includes(newTime)) {
                setErrorType({
                    day: day,
                    errorType: ERROR_DUPLICATE,
                });
                return;
            }
            setErrorType("");
            cloneTimeList.push(newTime);

            const newTimeList = { ...timeList };
            newTimeList[day] = cloneTimeList;

            setTimeList(newTimeList);
        } else if (type === "range") {
            const cloneTimeRangeList: any = [...timeRangeList[day]];
            if (!startTime || !endTime) return false;

            const newTimeRange = [
                startTime.format("HH:mm:ss"),
                endTime.format("HH:mm:ss"),
            ];

            if (
                !moment(startTime.format("HH:mm:ss"), "HH:mm:ss").isValid() ||
                !moment(endTime.format("HH:mm:ss"), "HH:mm:ss").isValid()
            ) {
                setErrorType({
                    day: day,
                    errorType: ERROR_INVALID,
                });
                return;
            }

            for (const c of cloneTimeRangeList) {
                if (c[0] === newTimeRange[0] && c[1] === newTimeRange[1]) {
                    setErrorType({
                        day: day,
                        errorType: ERROR_DUPLICATE,
                    });
                    return;
                }
            }

            if (startTime.isAfter(endTime)) {
                setErrorType({
                    day: day,
                    errorType: ERROR_TIME_RANGE,
                });
                return;
            }

            setErrorType("");
            cloneTimeRangeList.push(newTimeRange);

            const newTimeRangeList = { ...timeRangeList };
            newTimeRangeList[day] = cloneTimeRangeList;
            setTimeRangeList(newTimeRangeList);
        }
    };

    const renderTimeOrRangeList = (type: string) => {
        let array: any;
        if (type === "time") {
            array = timeList[day];
        } else if (type === "range") {
            array = timeRangeList[day];
        }

        const renderListFormat = (t: any, type: string) => {
            if (type === "time") {
                return moment(t, "HH:mm:ss").format("h:mm A");
            } else if (type === "range") {
                return `${moment(t[0], "HH:mm:ss").format(
                    "h:mm A"
                )} to ${moment(t[1], "HH:mm:ss").format("h:mm A")}`;
            }
        };

        return array.map((t: any, index: number) => (
            <div className="added-item mt-3" key={faker.datatype.uuid()}>
                {renderListFormat(t, type)}
                <span
                    className="material-icons"
                    onClick={() => deleteTime(index, type)}
                    onKeyDown={() => deleteTime(index, type)}
                >
                    close
                </span>
            </div>
        ));
    };

    const renderErrorMessage = (day?: any) => {
        if (_errorMessage) {
            return (
                <Col sm="12" className="mt-2">
                    <p className="text-danger">{_errorMessage}</p>
                </Col>
            );
        } else if (day === errorType.day) {
            if (errorType.errorType === ERROR_DUPLICATE) {
                return (
                    <Col sm="12" className="mt-2">
                        <p className="text-danger">
                            This time schedule has already been added.
                        </p>
                    </Col>
                );
            } else if (errorType.errorType === ERROR_INVALID) {
                return (
                    <Col sm="12" className="mt-2">
                        <p className="text-danger">Invalid time format.</p>
                    </Col>
                );
            } else if (errorType.errorType === ERROR_BEFORE_OR_AFTER) {
                return (
                    <Col sm="12" className="mt-2">
                        <p className="text-danger">{ERROR_BEFORE_OR_AFTER}</p>
                    </Col>
                );
            } else if (errorType.errorType === ERROR_TIME_RANGE) {
                return (
                    <Col sm="12" className="mt-2">
                        <p className="text-danger">{ERROR_TIME_RANGE}</p>
                    </Col>
                );
            }
        }
    };

    const renderAllday = () => {
        return (
            <Form.Row>
                <Col>
                    <div className="added-item mt-3">
                        12:00:00 AM to 11:59:59 PM
                    </div>
                </Col>
            </Form.Row>
        );
    };

    const renderAt = () => {
        return (
            <Form.Row>
                <Col className="mt-3">
                    <InputGroup>
                        {renderTime(TimeSchedule.AT)}
                        <Button
                            variant="primary"
                            className="flex-zero-point-one"
                            onClick={() => {
                                addToTimeList("time");
                            }}
                        >
                            Add
                        </Button>
                    </InputGroup>
                </Col>

                <Col sm="12">{renderTimeOrRangeList("time")}</Col>
                {renderErrorMessage(day) ??
                    renderErrorMsg(recurrence, "timeSchedule", day)}
            </Form.Row>
        );
    };

    const renderBetween = () => {
        return (
            <Form.Row>
                <Col sm="12" className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>Between</InputGroup.Text>
                        {renderTime(TimeSchedule.BETWEEN)}
                        <InputGroup.Text>and</InputGroup.Text>
                        {renderTime(TimeSchedule.BETWEEN, true)}
                        <Button
                            disabled={!!_errorMessage}
                            variant="primary"
                            onClick={() => {
                                addToTimeList("range");
                            }}
                        >
                            Add
                        </Button>
                    </InputGroup>
                </Col>

                <Col sm="12">{renderTimeOrRangeList("range")}</Col>
                {renderErrorMessage(day) ??
                    renderErrorMsg(recurrence, "timeSchedule", day)}
            </Form.Row>
        );
    };

    const renderBeforeAfter = () => {
        return (
            <Form.Row>
                <Col sm="6" className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>Before</InputGroup.Text>
                        {renderTime(TimeSchedule.BEFORE_OR_AFTER)}
                    </InputGroup>
                </Col>
                <Col sm="6" className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>After</InputGroup.Text>
                        {renderTime(TimeSchedule.BEFORE_OR_AFTER, true)}
                    </InputGroup>
                </Col>
                {renderErrorMessage(day) ??
                    renderErrorMsg(recurrence, "timeSchedule", day)}
            </Form.Row>
        );
    };

    const renderBefore = () => {
        return (
            <Form.Row>
                <Col className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>Before</InputGroup.Text>
                        {renderTime(TimeSchedule.BEFORE)}
                    </InputGroup>
                </Col>
                {renderErrorMsg(recurrence, "timeSchedule", day)}
            </Form.Row>
        );
    };

    const renderAfter = () => {
        return (
            <Form.Row>
                <Col className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>After</InputGroup.Text>
                        {renderTime(TimeSchedule.AFTER)}
                    </InputGroup>
                </Col>

                {renderErrorMsg(recurrence, "timeSchedule", day)}
            </Form.Row>
        );
    };

    const renderEvery = () => {
        return (
            <>
                <Form.Row>
                    <Col className="mt-2">
                        <InputGroup className="justify-content-end align-items-center">
                            <FormControl
                                value={duration[day]}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (
                                        e.target.value &&
                                        !Patterns.timeScheduleEveryPattern.test(
                                            e.target.value
                                        )
                                    )
                                        return;
                                    setDuration({
                                        ...duration,
                                        [day]: e.target.value,
                                    });
                                }}
                            />
                            <div className="every-input-unit">Minute(s)</div>
                        </InputGroup>
                        {renderErrorMsg(recurrence, "timeSchedule", day)}
                    </Col>
                </Form.Row>
                <HelpText
                    min={1}
                    max={EventConfigLimit.TIME_SCHEDULE_MINUTES_MAX}
                    timeUnit="minute"
                />
            </>
        );
    };
    const renderTime = (timeOption: string, additional?: any) => {
        const handleChange = (newValue: any) => {
            _setErrorMessage("");
            setErrorType("");

            if (timeOption === TimeSchedule.AT) {
                setAppointment(newValue);
                return;
            }

            if (timeOption === TimeSchedule.BETWEEN && !additional) {
                setStartTime(newValue);
                return;
            }

            if (timeOption === TimeSchedule.BETWEEN && additional) {
                setEndTime(newValue);
                return;
            }

            if (timeOption === TimeSchedule.BEFORE_OR_AFTER && additional) {
                setSecTime({ ...secTime, [day]: newValue });
                return;
            }

            setPriTime({
                ...priTime,
                [day]: newValue,
            });
        };

        const checkBeforeOrAfter = (
            value: moment.Moment,
            compare: moment.Moment,
            additional = false
        ) => {
            if (!value?.isValid()) {
                setErrorType({
                    day: day,
                    errorType: ERROR_INVALID,
                });

                return false;
            }

            if (
                compare?.isValid() &&
                value[additional ? "isSameOrBefore" : "isSameOrAfter"]?.(
                    compare
                ) &&
                selectedTimeOption["default"] !== TimeSchedule.AT &&
                timeOption === TimeSchedule.BEFORE_OR_AFTER
            ) {
                _setErrorMessage(ERROR_BEFORE_OR_AFTER);

                return false;
            }

            return true;
        };

        if (
            (timeOption === TimeSchedule.BEFORE_OR_AFTER ||
                timeOption === TimeSchedule.BETWEEN) &&
            additional
        ) {
            return (
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                        label="Time"
                        inputFormat="h:mm A"
                        value={
                            timeOption === TimeSchedule.BETWEEN
                                ? endTime
                                : secTime[day]
                        }
                        onChange={(newValue: any) => {
                            handleChange(newValue);

                            if (
                                !checkBeforeOrAfter(
                                    newValue,
                                    priTime[day],
                                    additional
                                )
                            ) {
                                return false;
                            }

                            if (
                                timeRangeList[day].find(
                                    (timeRange: any = []) => {
                                        const format = "hh:mm:ss";
                                        return moment(
                                            newValue.format(format),
                                            format
                                        ).isBetween(
                                            moment(timeRange[0], format),
                                            moment(timeRange[1], format)
                                        );
                                    }
                                )
                            ) {
                                _setErrorMessage(
                                    "Time range must not overlap."
                                );
                            } else {
                                _setErrorMessage("");
                            }
                        }}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                label=""
                                InputLabelProps={{
                                    shrink: false,
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            );
        }

        let defaultStartTime = priTime[day];
        if (timeOption === TimeSchedule.AT) defaultStartTime = appointment;
        if (timeOption === TimeSchedule.BETWEEN && !additional)
            defaultStartTime = startTime;

        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                    label="Time"
                    inputFormat="h:mm A"
                    value={defaultStartTime}
                    onChange={(newValue: any) => {
                        handleChange(newValue);

                        if (!checkBeforeOrAfter(newValue, secTime[day])) {
                            return false;
                        }

                        if (
                            timeOption === TimeSchedule.BETWEEN &&
                            timeRangeList[day].find((timeRange: any = []) => {
                                const format = "hh:mm:ss";
                                return moment(
                                    newValue.format(format),
                                    format
                                ).isBetween(
                                    moment(timeRange[0], format),
                                    moment(timeRange[1], format)
                                );
                            })
                        ) {
                            _setErrorMessage("Time range must not be overlap.");
                        } else {
                            _setErrorMessage("");
                        }
                    }}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label=""
                            InputLabelProps={{
                                shrink: false,
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        );
    };

    const renderFormat = () => {
        switch (selectedTimeOption[day]) {
            case TimeSchedule.ALL_DAY:
                return renderAllday();
            case TimeSchedule.AT:
                return renderAt();

            case TimeSchedule.BETWEEN:
                return renderBetween();

            case TimeSchedule.BEFORE_OR_AFTER:
                return renderBeforeAfter();

            case TimeSchedule.BEFORE:
                return renderBefore();
            case TimeSchedule.AFTER:
                return renderAfter();

            case TimeSchedule.EVERY:
                return renderEvery();
        }
    };

    return (
        <>
            <Form.Row>
                <Col>
                    <Form.Control
                        as="select"
                        custom
                        defaultValue={selectedTimeOption[day]}
                        value={selectedTimeOption[day]}
                        onChange={(e: any) => {
                            setErrorType("");
                            _setErrorMessage("");
                            setSelectedTimeOption({
                                ...selectedTimeOption,
                                [day]: e.target.value,
                            });
                        }}
                    >
                        <option value={TimeSchedule.ALL_DAY}>All Day</option>
                        <option value={TimeSchedule.AT}>At</option>
                        <option value={TimeSchedule.BETWEEN}>Between</option>
                        <option value={TimeSchedule.BEFORE_OR_AFTER}>
                            Before or After
                        </option>
                        <option value={TimeSchedule.BEFORE}>Before</option>
                        <option value={TimeSchedule.AFTER}>After</option>
                        <option value={TimeSchedule.EVERY}>Every</option>
                    </Form.Control>
                </Col>
            </Form.Row>
            {renderFormat()}
        </>
    );
};
