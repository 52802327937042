import { capitalize } from "lodash";
import { SUBSCRIPTION_DATE_FORMAT } from "constant";
import moment from "moment";

export const renderPlanStatus = (subscriptionStatus: string) => {
    if (subscriptionStatus === "ACTIVE") {
        return (
            <span className="default-green-color" aria-label="plan-status">
                {capitalize(subscriptionStatus)}
            </span>
        );
    } else if (["CANCELED", "EXPIRED"].includes(subscriptionStatus)) {
        return (
            <span className="default-red-color" aria-label="plan-status">
                {capitalize(
                    subscriptionStatus === "CANCELED" ? "CANCELLED" : "EXPIRED"
                )}
            </span>
        );
    } else if (subscriptionStatus === "PAST_DUE") {
        return (
            <span className="default-red-color" aria-label="plan-status">
                Past Due
            </span>
        );
    }
};

export const initBillingAddress = () => {
    return {
        first_name: "",
        last_name: "",
        street: "",
        extended: "",
        line3: "",
        city: "",
        code: "",
        country: "",
        region: "",
    };
};

export const subscriptionPeriodDetail = (
    billing_start_date: any,
    billing_cycles: number
) => {
    return (
        <>
            {billing_start_date} -{" "}
            {moment()
                .add(billing_cycles, "M")
                .subtract(1, "days")
                .format(SUBSCRIPTION_DATE_FORMAT)}{" "}
            {`(${billing_cycles} ${billing_cycles > 1 ? "Months" : "Month"})`}
        </>
    );
};
