/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import {
  ActuatorCommandPatch,
  ActuatorCommandPost,
  AlertNoticePatch,
  AlertPatch,
  AlertPost,
  BusPowerPost,
  ChangePowerStateByPortData,
  CreateAlertData,
  CreateNewDeletionTaskData,
  CreateRegisteredGwData,
  CreateTakeoutTask2Data,
  DeleteAlertByIdData,
  DeleteDeviceByIdData,
  DeleteDeviceByPortData,
  DeleteRegisteredGwByIdData,
  DeletionPost,
  DeviceConfigPatch,
  DeviceInfoPost,
  DevicesConfigPatch,
  ErrorResponse,
  FirmwareVersion,
  GatewayStatusPost,
  GetActuatorConfigBySaidData,
  GetAlertByIdData,
  GetAlertBySaidData,
  GetAlertsData,
  GetAvailableTimeRangesData,
  GetDeletionTaskByIdData,
  GetDeletionTasksData,
  GetDeviceConfigByIdData,
  GetDevicesConfigByPortData,
  GetDevicesReadingByDeviceIdData,
  GetDevicesReadingByGwIdData,
  GetDevicesReadingByPortData,
  GetGwByIdData,
  GetGwConfigByIdData,
  GetGwFirmwareByGwIdData,
  GetGwReadingByOrganizationIdData,
  GetGWsData,
  GetGwStatusByIdData,
  GetGwStatusesData,
  GetGwuartByIdData,
  GetMessageConfigByGwIdData,
  GetMessageNotificationByGwIdData,
  GetNotificationByIdData,
  GetPortStatusByGwIdData,
  GetPortStatusesData,
  GetRegisteredGwByIdData,
  GetRegisteredGWsData,
  GetRegistriesByOrganisationIdsData,
  GetSensorConfigByIdData,
  GetTakeoutTask2ByIdData,
  GetTakeoutTasksByOrgData,
  MessageNoticePatch,
  MultipleDevicesConfigPatch,
  RegistryPatch,
  RegistryPost,
  RequestTriggerDeviceBlinkingLedByIdData,
  ResetDeviceConfigByIdData,
  ResetGwConfigByIdData,
  SensorConfigPatch,
  Takeout2Post,
  ToggleActuatorBySaidData,
  UartConfigPost,
  UpdateActuatorConfigBySaidData,
  UpdateAlertByIdData,
  UpdateDeviceConfigByIdData,
  UpdateDeviceConfigByPortData,
  UpdateGwByIdData,
  UpdateGwFirmwareByGwIdData,
  UpdateGwStatusByIdData,
  UpdateMessageNotificationByGwIdData,
  UpdateMultipleDevicesConfigData,
  UpdateNotificationByIdData,
  UpdateRegisteredGwByIdData,
  UpdateSensorConfigBySaidData,
  UpdateUartByIdData,
} from "../models";

export class Gateways<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags takeout
   * @name GetTakeoutTasksByOrg
   * @summary Get all takeout task info
   * @request GET:/gateways/takeout
   * @secure
   */
  getTakeoutTasksByOrg = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetTakeoutTasksByOrgData, void>({
      path: `/gateways/takeout`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags takeout
   * @name CreateTakeoutTask2
   * @summary Create a new takeout task
   * @request POST:/gateways/takeout
   * @secure
   */
  createTakeoutTask2 = (data: Takeout2Post, params: RequestParams = {}) =>
    this.http.request<CreateTakeoutTask2Data, void>({
      path: `/gateways/takeout`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags takeout
   * @name GetTakeoutTask2ById
   * @summary Get takeout task info by uuid
   * @request GET:/gateways/takeout/{takeout_uuid}
   * @secure
   */
  getTakeoutTask2ById = (takeoutUuid: string, params: RequestParams = {}) =>
    this.http.request<GetTakeoutTask2ByIdData, void>({
      path: `/gateways/takeout/${takeoutUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags deletion
   * @name GetDeletionTasks
   * @summary Get all deletion task info of specified gateway
   * @request GET:/gateways/{gateway_id}/deletion
   * @secure
   */
  getDeletionTasks = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetDeletionTasksData, void>({
      path: `/gateways/${gatewayId}/deletion`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags deletion
   * @name CreateNewDeletionTask
   * @summary Create a new deletion task for specified gateway
   * @request POST:/gateways/{gateway_id}/deletion
   * @secure
   */
  createNewDeletionTask = (gatewayId: string, data: DeletionPost, params: RequestParams = {}) =>
    this.http.request<CreateNewDeletionTaskData, void>({
      path: `/gateways/${gatewayId}/deletion`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags deletion
   * @name GetDeletionTaskById
   * @summary Get deletion task info by uuid
   * @request GET:/gateways/{gateway_id}/deletion/{deletion_uuid}
   * @secure
   */
  getDeletionTaskById = (gatewayId: string, deletionUuid: string, params: RequestParams = {}) =>
    this.http.request<GetDeletionTaskByIdData, void>({
      path: `/gateways/${gatewayId}/deletion/${deletionUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags timerange
   * @name GetAvailableTimeRanges
   * @summary Get available time ranges for deletion task
   * @request GET:/gateways/timerange
   * @secure
   */
  getAvailableTimeRanges = (params: RequestParams = {}) =>
    this.http.request<GetAvailableTimeRangesData, any>({
      path: `/gateways/timerange`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags registry
   * @name GetRegisteredGWs
   * @summary Get all registered gateway info
   * @request GET:/gateways/registry
   * @secure
   */
  getRegisteredGWs = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetRegisteredGWsData, void>({
      path: `/gateways/registry`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags registry
   * @name CreateRegisteredGw
   * @summary Register a new gateway
   * @request POST:/gateways/registry
   * @secure
   */
  createRegisteredGw = (data: RegistryPost, params: RequestParams = {}) =>
    this.http.request<CreateRegisteredGwData, void>({
      path: `/gateways/registry`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags registry
   * @name GetRegistriesByOrganisationIds
   * @summary Get the all registered gateways' info of specified organisations
   * @request GET:/gateways/registry2
   * @secure
   */
  getRegistriesByOrganisationIds = (
    query: {
      /** Organisation IDs separated by "," */
      organisation_ids: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetRegistriesByOrganisationIdsData, void>({
      path: `/gateways/registry2`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags registry
   * @name DeleteRegisteredGwById
   * @summary Delete gateway info by ID
   * @request DELETE:/gateways/{gateway_id}/registry
   * @secure
   */
  deleteRegisteredGwById = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<DeleteRegisteredGwByIdData, void>({
      path: `/gateways/${gatewayId}/registry`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags registry
   * @name GetRegisteredGwById
   * @summary Get registered gateway info by ID
   * @request GET:/gateways/{gateway_id}/registry
   * @secure
   */
  getRegisteredGwById = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetRegisteredGwByIdData, void>({
      path: `/gateways/${gatewayId}/registry`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags registry
   * @name UpdateRegisteredGwById
   * @summary Update gateway info by ID
   * @request PATCH:/gateways/{gateway_id}/registry
   * @secure
   */
  updateRegisteredGwById = (gatewayId: string, data: RegistryPatch, params: RequestParams = {}) =>
    this.http.request<UpdateRegisteredGwByIdData, void>({
      path: `/gateways/${gatewayId}/registry`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags registry
   * @name DeleteDeviceById
   * @summary Delete device info by ID
   * @request DELETE:/gateways/{gateway_id}/{device_id}/registry
   * @secure
   */
  deleteDeviceById = (gatewayId: string, deviceId: string, params: RequestParams = {}) =>
    this.http.request<DeleteDeviceByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/registry`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags registry
   * @name DeleteDeviceByPort
   * @summary Delete device info by port number
   * @request DELETE:/gateways/{gateway_id}/bus/{port}/registry
   * @secure
   */
  deleteDeviceByPort = (gatewayId: string, port: string, params: RequestParams = {}) =>
    this.http.request<DeleteDeviceByPortData, void>({
      path: `/gateways/${gatewayId}/bus/${port}/registry`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name GetGWs
   * @summary Get all gateway info
   * @request GET:/gateways/info
   * @secure
   */
  getGWs = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetGWsData, void>({
      path: `/gateways/info`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name GetGwById
   * @summary Get gateway info by ID
   * @request GET:/gateways/{gateway_id}/info
   * @secure
   */
  getGwById = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetGwByIdData, void>({
      path: `/gateways/${gatewayId}/info`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name UpdateGwById
   * @summary Request to update device info
   * @request POST:/gateways/{gateway_id}/info
   * @secure
   */
  updateGwById = (gatewayId: string, data: DeviceInfoPost, params: RequestParams = {}) =>
    this.http.request<UpdateGwByIdData, void>({
      path: `/gateways/${gatewayId}/info`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config device
   * @name UpdateMultipleDevicesConfig
   * @summary Change the config of multiple devices
   * @request PATCH:/gateways/config
   * @secure
   */
  updateMultipleDevicesConfig = (data: MultipleDevicesConfigPatch, params: RequestParams = {}) =>
    this.http.request<UpdateMultipleDevicesConfigData, void>({
      path: `/gateways/config`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config
   * @name GetGwuartById
   * @summary Get gateway UART config by ID
   * @request GET:/gateways/{gateway_id}/uart
   * @secure
   */
  getGwuartById = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetGwuartByIdData, void>({
      path: `/gateways/${gatewayId}/uart`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags config
   * @name UpdateUartById
   * @summary Change gateway UART config by ID
   * @request POST:/gateways/{gateway_id}/uart
   * @secure
   */
  updateUartById = (gatewayId: string, data: UartConfigPost, params: RequestParams = {}) =>
    this.http.request<UpdateUartByIdData, void>({
      path: `/gateways/${gatewayId}/uart`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config
   * @name GetGwConfigById
   * @summary Get gateway config by ID
   * @request GET:/gateways/{gateway_id}/config
   * @secure
   */
  getGwConfigById = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetGwConfigByIdData, void>({
      path: `/gateways/${gatewayId}/config`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags config device
   * @name GetDeviceConfigById
   * @summary Get device config by ID
   * @request GET:/gateways/{gateway_id}/{device_id}/config
   * @secure
   */
  getDeviceConfigById = (gatewayId: string, deviceId: string, params: RequestParams = {}) =>
    this.http.request<GetDeviceConfigByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/config`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags config device
   * @name UpdateDeviceConfigById
   * @summary Change device config by ID
   * @request PATCH:/gateways/{gateway_id}/{device_id}/config
   * @secure
   */
  updateDeviceConfigById = (gatewayId: string, deviceId: string, data: DeviceConfigPatch, params: RequestParams = {}) =>
    this.http.request<UpdateDeviceConfigByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/config`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config sensor
   * @name GetSensorConfigById
   * @summary Get sensor config by ID
   * @request GET:/gateways/{gateway_id}/{device_id}/{said}/config
   * @secure
   */
  getSensorConfigById = (gatewayId: string, deviceId: string, said: string, params: RequestParams = {}) =>
    this.http.request<GetSensorConfigByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/config`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags config sensor
   * @name UpdateSensorConfigBySaid
   * @summary Change sensor config by SAID
   * @request PATCH:/gateways/{gateway_id}/{device_id}/{said}/config
   * @secure
   */
  updateSensorConfigBySaid = (
    gatewayId: string,
    deviceId: string,
    said: string,
    data: SensorConfigPatch,
    params: RequestParams = {},
  ) =>
    this.http.request<UpdateSensorConfigBySaidData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/config`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config device
   * @name GetDevicesConfigByPort
   * @summary Get device config by port number
   * @request GET:/gateways/{gateway_id}/bus/{port}/config
   * @secure
   */
  getDevicesConfigByPort = (gatewayId: string, port: string, params: RequestParams = {}) =>
    this.http.request<GetDevicesConfigByPortData, void>({
      path: `/gateways/${gatewayId}/bus/${port}/config`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags config device
   * @name UpdateDeviceConfigByPort
   * @summary Change device config by port number
   * @request PATCH:/gateways/{gateway_id}/bus/{port}/config
   * @secure
   */
  updateDeviceConfigByPort = (gatewayId: string, port: string, data: DevicesConfigPatch, params: RequestParams = {}) =>
    this.http.request<UpdateDeviceConfigByPortData, void>({
      path: `/gateways/${gatewayId}/bus/${port}/config`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config device
   * @name ChangePowerStateByPort
   * @summary Change gateway bus/port power state
   * @request PATCH:/gateways/{gateway_id}/bus/{port}/power
   * @secure
   */
  changePowerStateByPort = (gatewayId: string, port: string, data: BusPowerPost, params: RequestParams = {}) =>
    this.http.request<ChangePowerStateByPortData, void>({
      path: `/gateways/${gatewayId}/bus/${port}/power`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config actuator
   * @name GetActuatorConfigBySaid
   * @summary Get actuator command config by SAID
   * @request GET:/gateways/{gateway_id}/{device_id}/{said}/command
   * @secure
   */
  getActuatorConfigBySaid = (gatewayId: string, deviceId: string, said: string, params: RequestParams = {}) =>
    this.http.request<GetActuatorConfigBySaidData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/command`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags config actuator
   * @name UpdateActuatorConfigBySaid
   * @summary Change actuator command config by SAID
   * @request PATCH:/gateways/{gateway_id}/{device_id}/{said}/command
   * @secure
   */
  updateActuatorConfigBySaid = (
    gatewayId: string,
    deviceId: string,
    said: string,
    data: ActuatorCommandPatch,
    params: RequestParams = {},
  ) =>
    this.http.request<UpdateActuatorConfigBySaidData, ErrorResponse>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/command`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config actuator
   * @name ToggleActuatorBySaid
   * @summary Activate/deactivate actuator by SAID
   * @request POST:/gateways/{gateway_id}/{device_id}/{said}/command
   * @secure
   */
  toggleActuatorBySaid = (
    gatewayId: string,
    deviceId: string,
    said: string,
    data: ActuatorCommandPost,
    params: RequestParams = {},
  ) =>
    this.http.request<ToggleActuatorBySaidData, ErrorResponse>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/command`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags config
   * @name ResetGwConfigById
   * @summary Reset gateway config by ID
   * @request POST:/gateways/{gateway_id}/config/reset
   * @secure
   */
  resetGwConfigById = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<ResetGwConfigByIdData, void>({
      path: `/gateways/${gatewayId}/config/reset`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags config device
   * @name ResetDeviceConfigById
   * @summary Reset LDSU config by ID
   * @request POST:/gateways/{gateway_id}/{device_id}/config/reset
   * @secure
   */
  resetDeviceConfigById = (gatewayId: string, deviceId: string, params: RequestParams = {}) =>
    this.http.request<ResetDeviceConfigByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/config/reset`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name GetGwStatuses
   * @summary Get all gateways' status
   * @request GET:/gateways/status
   * @secure
   */
  getGwStatuses = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetGwStatusesData, void>({
      path: `/gateways/status`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name GetGwStatusById
   * @summary Get gateway status by ID
   * @request GET:/gateways/{gateway_id}/status
   * @secure
   */
  getGwStatusById = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetGwStatusByIdData, void>({
      path: `/gateways/${gatewayId}/status`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name UpdateGwStatusById
   * @summary Request to change gateway status by ID
   * @request POST:/gateways/{gateway_id}/status
   * @secure
   */
  updateGwStatusById = (gatewayId: string, data: GatewayStatusPost, params: RequestParams = {}) =>
    this.http.request<UpdateGwStatusByIdData, void>({
      path: `/gateways/${gatewayId}/status`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name RequestTriggerDeviceBlinkingLedById
   * @summary Request to trigger the device blinking LED by ID
   * @request POST:/gateways/{gateway_id}/{device_id}/identify
   * @secure
   */
  requestTriggerDeviceBlinkingLedById = (gatewayId: string, deviceId: string, params: RequestParams = {}) =>
    this.http.request<RequestTriggerDeviceBlinkingLedByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/identify`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name GetPortStatuses
   * @summary Get port status
   * @request GET:/gateways/port
   * @secure
   */
  getPortStatuses = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetPortStatusesData, void>({
      path: `/gateways/port`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name GetPortStatusByGwId
   * @summary Get port status by ID
   * @request GET:/gateways/{gateway_id}/port
   * @secure
   */
  getPortStatusByGwId = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetPortStatusByGwIdData, void>({
      path: `/gateways/${gatewayId}/port`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name GetGwReadingByOrganizationId
   * @summary Get gateway reading for specified organization
   * @request GET:/gateways/reading
   * @secure
   */
  getGwReadingByOrganizationId = (
    query: {
      /** Organization ID */
      organization_id: string;
      /** PromQL like time duration */
      time_range?: string;
      /** PromQL like offset modifier */
      offset?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetGwReadingByOrganizationIdData, void>({
      path: `/gateways/reading`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name GetDevicesReadingByGwId
   * @summary Get devices' readings of specified gateway by ID
   * @request GET:/gateways/{gateway_id}/reading2
   * @secure
   */
  getDevicesReadingByGwId = (
    gatewayId: string,
    query?: {
      /** PromQL like time duration */
      time_range?: string;
      /** PromQL like offset modifier */
      offset?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetDevicesReadingByGwIdData, void>({
      path: `/gateways/${gatewayId}/reading2`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name GetDevicesReadingByPort
   * @summary Get devices' readings of specified gateway and port
   * @request GET:/gateways/{gateway_id}/bus/{port}/reading2
   * @secure
   */
  getDevicesReadingByPort = (
    gatewayId: string,
    port: string,
    query?: {
      /** PromQL like time duration */
      time_range?: string;
      /** PromQL like offset modifier */
      offset?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetDevicesReadingByPortData, void>({
      path: `/gateways/${gatewayId}/bus/${port}/reading2`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags status
   * @name GetDevicesReadingByDeviceId
   * @summary Get device readings by ID
   * @request GET:/gateways/{gateway_id}/{device_id}/reading2
   * @secure
   */
  getDevicesReadingByDeviceId = (
    gatewayId: string,
    deviceId: string,
    query?: {
      /** PromQL like time duration */
      time_range?: string;
      /** PromQL like offset modifier */
      offset?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetDevicesReadingByDeviceIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/reading2`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags message
   * @name GetMessageConfigByGwId
   * @summary Get device config by ID
   * @request GET:/gateways/{gateway_id}/message
   * @secure
   */
  getMessageConfigByGwId = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetMessageConfigByGwIdData, void>({
      path: `/gateways/${gatewayId}/message`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags message
   * @name GetMessageNotificationByGwId
   * @summary Get gateway message notification config
   * @request GET:/gateways/{gateway_id}/message/notice
   * @secure
   */
  getMessageNotificationByGwId = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetMessageNotificationByGwIdData, void>({
      path: `/gateways/${gatewayId}/message/notice`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags message
   * @name UpdateMessageNotificationByGwId
   * @summary Update gateway message notification config
   * @request PATCH:/gateways/{gateway_id}/message/notice
   * @secure
   */
  updateMessageNotificationByGwId = (gatewayId: string, data: MessageNoticePatch, params: RequestParams = {}) =>
    this.http.request<UpdateMessageNotificationByGwIdData, void>({
      path: `/gateways/${gatewayId}/message/notice`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ota
   * @name GetGwFirmwareByGwId
   * @summary Get gateway firmware upgrade statue
   * @request GET:/gateways/{gateway_id}/firmware
   * @secure
   */
  getGwFirmwareByGwId = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetGwFirmwareByGwIdData, void>({
      path: `/gateways/${gatewayId}/firmware`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ota
   * @name UpdateGwFirmwareByGwId
   * @summary Request to upgrade gateway firmware
   * @request POST:/gateways/{gateway_id}/firmware
   * @secure
   */
  updateGwFirmwareByGwId = (gatewayId: string, data: FirmwareVersion, params: RequestParams = {}) =>
    this.http.request<UpdateGwFirmwareByGwIdData, void>({
      path: `/gateways/${gatewayId}/firmware`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags alert
   * @name GetAlerts
   * @summary Get alerts by gateway ID
   * @request GET:/gateways/{gateway_id}/alert
   * @secure
   */
  getAlerts = (gatewayId: string, params: RequestParams = {}) =>
    this.http.request<GetAlertsData, void>({
      path: `/gateways/${gatewayId}/alert`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags alert
   * @name GetAlertBySaid
   * @summary Get alerts by SAID
   * @request GET:/gateways/{gateway_id}/{device_id}/{said}/alert
   * @secure
   */
  getAlertBySaid = (gatewayId: string, deviceId: string, said: string, params: RequestParams = {}) =>
    this.http.request<GetAlertBySaidData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/alert`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags alert
   * @name CreateAlert
   * @summary Create a new alert
   * @request POST:/gateways/{gateway_id}/{device_id}/{said}/alert
   * @secure
   */
  createAlert = (gatewayId: string, deviceId: string, said: string, data: AlertPost, params: RequestParams = {}) =>
    this.http.request<CreateAlertData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/alert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags alert
   * @name DeleteAlertById
   * @summary Delete alert info by uuid
   * @request DELETE:/gateways/{gateway_id}/{device_id}/{said}/alert/{alert_uuid}
   * @secure
   */
  deleteAlertById = (
    gatewayId: string,
    deviceId: string,
    said: string,
    alertUuid: string,
    params: RequestParams = {},
  ) =>
    this.http.request<DeleteAlertByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/alert/${alertUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags alert
   * @name GetAlertById
   * @summary Get alert info by uuid
   * @request GET:/gateways/{gateway_id}/{device_id}/{said}/alert/{alert_uuid}
   * @secure
   */
  getAlertById = (gatewayId: string, deviceId: string, said: string, alertUuid: string, params: RequestParams = {}) =>
    this.http.request<GetAlertByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/alert/${alertUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags alert
   * @name UpdateAlertById
   * @summary Update alert info by ID
   * @request PATCH:/gateways/{gateway_id}/{device_id}/{said}/alert/{alert_uuid}
   * @secure
   */
  updateAlertById = (
    gatewayId: string,
    deviceId: string,
    said: string,
    alertUuid: string,
    data: AlertPatch,
    params: RequestParams = {},
  ) =>
    this.http.request<UpdateAlertByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/alert/${alertUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags alert
   * @name GetNotificationById
   * @summary Get notification config
   * @request GET:/gateways/{gateway_id}/{device_id}/{said}/alert/{alert_uuid}/notice
   * @secure
   */
  getNotificationById = (
    gatewayId: string,
    deviceId: string,
    said: string,
    alertUuid: string,
    params: RequestParams = {},
  ) =>
    this.http.request<GetNotificationByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/alert/${alertUuid}/notice`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags alert
   * @name UpdateNotificationById
   * @summary Update notification config
   * @request PATCH:/gateways/{gateway_id}/{device_id}/{said}/alert/{alert_uuid}/notice
   * @secure
   */
  updateNotificationById = (
    gatewayId: string,
    deviceId: string,
    said: string,
    alertUuid: string,
    data: AlertNoticePatch,
    params: RequestParams = {},
  ) =>
    this.http.request<UpdateNotificationByIdData, void>({
      path: `/gateways/${gatewayId}/${deviceId}/${said}/alert/${alertUuid}/notice`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
