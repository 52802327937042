import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { useParams } from "react-router-dom";

import { getRegistryConfig, getRegistryInfo } from "service/gatewayService";
import { get } from "lodash";

const HierarchyTab = (props: any) => {
    const params: any = useParams();
    type hierarchyDetailsType = {
        name: string;
        id?: string;
        children: Array<any>;
    };
    const [currentLoading, setCurrentLoading] = useState<number>(Date.now());
    const [hierarchyDetails, setHierarchyDetails] =
        useState<hierarchyDetailsType>({
            name: "",
            children: [],
        });

    const { ldsuStatuses } = props;

    useEffect(() => {
        const fetch = async () => {
            const registryConfig: any = await getRegistryConfig(
                params.gatewayId
            );
            if (
                registryConfig.status === 200 &&
                !!registryConfig.data?.length
            ) {
                const registryInfo: any = await getRegistryInfo(
                    params.gatewayId
                );
                if (registryInfo.status === 200) {
                    const table = {
                        name: props.name,
                        ...getItemStyle(params.gatewayId),
                        children: [
                            {
                                name: "Machine-to-Machine (M2M)",
                            },
                            {
                                name: "LDS BUS 1",
                                children: renderLDSChildren(
                                    registryInfo.data.devices,
                                    registryConfig.data,
                                    "1"
                                ),
                            },
                            {
                                name: "LDS BUS 2",
                                children: renderLDSChildren(
                                    registryInfo.data.devices,
                                    registryConfig.data,
                                    "2"
                                ),
                            },
                            {
                                name: "LDS BUS 3",
                                children: renderLDSChildren(
                                    registryInfo.data.devices,
                                    registryConfig.data,
                                    "3"
                                ),
                            },
                        ],
                    };
                    setHierarchyDetails(table);
                }
            } else {
                // Registry is not Onboarded
                const table = {
                    name: props.name,
                    children: [
                        {
                            name: "Machine-to-Machine (M2M)",
                        },
                        {
                            name: "LDS BUS 1",
                        },
                        {
                            name: "LDS BUS 2",
                        },
                        {
                            name: "LDS BUS 3",
                        },
                    ],
                };
                setHierarchyDetails(table);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.gatewayId, props.name]);
    const [width, setWidth] = useState(window.innerWidth);
    const updateWindowWidth = () => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    };
    useEffect(() => {
        updateWindowWidth();
        return () => {
            window.removeEventListener("resize", () => {});
        };
    });

    useEffect(() => {
        const newHierarchyDetails = { ...hierarchyDetails };
        const setItemStyleRecursive = (data: any) => {
            if (data?.id) {
                data = {
                    ...data,
                    ...getItemStyle(data.id),
                };
            }

            if (data?.children?.length) {
                data.children = data.children.map((child: any) =>
                    setItemStyleRecursive(child)
                );
            }

            return data;
        };

        setHierarchyDetails(setItemStyleRecursive(newHierarchyDetails));
        setCurrentLoading(Date.now());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ldsuStatuses]);

    const getItemStyle = (device_id: string) => {
        device_id = device_id.split("-")[0];
        const status = ldsuStatuses[device_id] || false;

        return {
            id: device_id,
            symbol: "circle",
            itemStyle: {
                color: status ? "green" : "red",
                borderWidth: 1.5,
                borderColor: "#b0c4de",
            },
        };
    };

    const renderLDSChildren = (
        infoList: any,
        configList: any,
        BUSNumber: string
    ) => {
        const combined: any = {};

        const filteredInfoList = infoList.filter(
            ({ PORT }: { PORT: string }) => PORT === BUSNumber
        );

        filteredInfoList.forEach((i: any) => {
            if (!combined[i.UID]) {
                combined[i.UID] = {};
            }

            const config = configList.find(
                ({ UID }: { UID: string }) => UID === i.UID
            );

            Object.assign(combined[i.UID], {
                uuid: i.uuid,
                name: config?.name ?? i.NAME,
                children: get(config, "sensor_names", []).map(
                    (k: any, index: number) => {
                        let sensorObj = {
                            name: k,
                            ...getItemStyle(i.UID + "-" + index),
                        };
                        return sensorObj;
                    }
                ),
                ...getItemStyle(i.UID),
            });
        });

        return Object.values(combined);
    };

    const option = {
        tooltip: {
            trigger: "item",
            triggerOn: "mousemove",
        },
        series: [
            {
                type: "tree",
                data: [hierarchyDetails],

                top: "0%",
                left: "15%",
                bottom: "0%",
                right: "15%",

                symbolSize: 10,

                label: {
                    position: "left",
                    verticalAlign: "middle",
                    align: "right",
                    fontSize: 16,
                    color: "#ffffff",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    overflow: "truncate",
                    width: 250,
                },

                leaves: {
                    label: {
                        position: "right",
                        verticalAlign: "middle",
                        align: "left",
                    },
                },

                emphasis: {
                    focus: "ancestor",
                },

                expandAndCollapse: true,
                animationDuration: 550,
                animationDurationUpdate: 750,
            },
        ],
    };

    // Update label width based on window width
    if (width <= 1024) {
        option.series[0].label.width = 100;
    } else if (width <= 1440) {
        option.series[0].label.width = 175;
    }
    return (
        <div className="height-90vh">
            <div>
                <ReactECharts
                    className="height-90vh"
                    option={option}
                    loadingOption={{
                        ts: currentLoading,
                    }}
                />
            </div>
        </div>
    );
};

export default HierarchyTab;
