import { useState, useEffect, ChangeEvent } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { uniq } from "lodash";
import { Registry, Group } from "generated/models";
import { UNGROUPED_NAME } from "constant";

type SelectGatewayModalProps = {
    isShow: boolean;
    showAllGroup?: boolean;
    selectedGWs?: string[];
    initGroups: Group[];
    onClose: Function;
    handleAddGWsToGroup: Function;
};

const SelectGatewayModal = ({
    isShow,
    selectedGWs,
    onClose,
    handleAddGWsToGroup,
    initGroups,
    showAllGroup,
}: SelectGatewayModalProps) => {
    const [groups, setGroups] = useState<Group[]>([]);
    const [gatewayIds, setGatewayIds] = useState<string[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    useEffect(() => {
        setSelectedIds([]);
        setGatewayIds([]);
        setGroups([]);
        let listGWIds: string[] = [];
        setGroups(
            initGroups
                .map((group: Group) => {
                    if (!showAllGroup && group.name !== UNGROUPED_NAME) {
                        return null;
                    }
                    const name =
                        group.name === UNGROUPED_NAME
                            ? "Standalone"
                            : group.name;
                    const listGatewayIds = group.gateways.map(
                        ({ gateway_id }: Registry) => {
                            return gateway_id;
                        }
                    );
                    listGWIds = listGWIds.concat(listGatewayIds);
                    return listGatewayIds.length && { ...group, name };
                })
                .filter(Boolean) as Group[]
        );
        setGatewayIds((gwIds) => uniq(gwIds.concat(listGWIds)));
        if (selectedGWs) {
            setSelectedIds(selectedGWs);
        }
    }, [isShow, initGroups, showAllGroup, selectedGWs]);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const searchingText = e.target.value;
        const groupsSearched: Group[] = initGroups
            .map((group: Group) => {
                if (!showAllGroup && group.name !== UNGROUPED_NAME) {
                    return null;
                }
                const gateways: Registry[] = searchingText.length
                    ? (group.gateways
                          .map(
                              (gateway: Registry) =>
                                  gateway.name
                                      .toLowerCase()
                                      .includes(searchingText.toLowerCase()) &&
                                  gateway
                          )
                          .filter(Boolean) as Registry[])
                    : group.gateways;
                const name =
                    group.name === UNGROUPED_NAME ? "Standalone" : group.name;
                return gateways.length && { ...group, gateways, name };
            })
            .filter(Boolean) as Group[];
        setGroups(groupsSearched);
    };

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedIds(gatewayIds);
        } else {
            setSelectedIds([]);
        }
    };

    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedIds(selectedIds.concat(e.target.value));
        } else {
            setSelectedIds(
                selectedIds.filter((gwId) => gwId !== e.target.value)
            );
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleAddGatewaysToGroup = () => {
        handleAddGWsToGroup(selectedIds);
        handleClose();
    };

    const renderGroupGateways = () => {
        return groups.map(({ name, gateways }: Group) => {
            return (
                <div key={name}>
                    <h4 className="mb-0">{name}</h4>
                    {renderGateways(gateways)}
                </div>
            );
        });
    };

    const renderGateways = (gateways: Registry[]) => {
        return gateways
            .map(({ name, gateway_id }: Registry) => {
                return (
                    <div className="modal-option" key={gateway_id}>
                        <Form.Check
                            aria-label="select-gateway-checkbox"
                            name="selected-gateway"
                            id={gateway_id}
                            custom
                            type="checkbox"
                            value={gateway_id}
                            onChange={handleSelect}
                            checked={selectedIds.includes(gateway_id)}
                            label={
                                <div>
                                    <h4 className="select-gw-name">{name}</h4>{" "}
                                    <p className="select-gw-id">
                                        UUID: {gateway_id}
                                    </p>
                                </div>
                            }
                            className="float-left"
                        />
                    </div>
                );
            })
            .filter(Boolean);
    };

    return (
        <>
            <Modal show={isShow} animation={false} centered onHide={() => {}}>
                <Modal.Header>
                    <Modal.Title>Select Gateway</Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleClose}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-flex flex-wrap">
                        <div className="d-flex align-items-center w-100 mb-4 modal-filter">
                            <div className="modal-option">
                                <Form.Check
                                    id="gateway-check-all"
                                    type="checkbox"
                                    className="text-gray"
                                    label="Select All"
                                    custom
                                    onChange={handleSelectAll}
                                    checked={
                                        selectedIds.length ===
                                            gatewayIds.length &&
                                        gatewayIds.length !== 0
                                    }
                                />
                            </div>
                            <FormControl
                                aria-label="search-standalone-gateways"
                                type="text"
                                placeholder="Search"
                                onChange={handleSearch}
                            ></FormControl>
                        </div>

                        <div className="scroll-list float-left">
                            {renderGroupGateways()}
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={handleAddGatewaysToGroup}
                    >
                        DONE
                    </Button>
                </Modal.Footer>
            </Modal>
            <style>{`
                    .modal-filter .modal-option {
                        flex: 0 0 160px;
                        margin-right: 0.5rem;
                        padding: 0;
                    }

                    .modal-filter .modal-option .custom-control-label:before,
                    .modal-filter .modal-option .custom-control-label:after {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                `}</style>
        </>
    );
};

export default SelectGatewayModal;
