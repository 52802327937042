import React from "react";
import { useParams, useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import create from "zustand";
import styled from "@emotion/styled";
import { fetchDevices } from "utils/gatewayFunctions";
import {
    COLORS,
    DASHBOARD_CHART_MAX,
    DASHBOARD_CHART_LIMIT_MESSAGE,
    CHART_DUPLICATE_COLOR_MESSAGE,
} from "constant";
import { getPrimaryUnitKey, parseColorToHex } from "utils/functions";
import * as _ from "lodash";
import { metricStore, useMetricDefined } from "hooks/useMetricDefined";
import { ColorResult, SketchPicker } from "react-color";
import dashboardService from "service/dashboardService";
import { PanelV2 } from "generated/models";
import { showErrorAlert } from "utils/alert";
import { ColorPickerBackground } from "components/common";

// Component width from design
const ComponentWidth = 1477;

export const ContainerStyled = styled(Container)`
    max-width: ${ComponentWidth}px;
`;

// eslint-disable-next-line no-control-regex
export const CHART_NAME_PATTERN = /^.{1,64}$/;
export const CHART_ERROR_MESSAGE =
    "Invalid chart name. Chart names should consist of 1 to 64 characters only.";

enum ChartTypeList {
    Gateway = "Gateway",
    Sensor = "Sensor",
    Actuator = "Actuator",
    Other = "Other chart options",
}

type ChartTypeListKey = keyof typeof ChartTypeList;

// Disable select chart type for current release version
const enableChartTypeSelection = true;

// Create color picker component with Dropdown bootstrap component
// https://react-bootstrap.github.io/components/dropdowns/#dropdown-button-props
type ColorPickerProps = {
    data?: string[];
    name?: string;
    value?: string;
    defaultValue?: string;
    disabled?: boolean;
    onChange?: (event: Event | React.BaseSyntheticEvent, value: string) => void;
    style?: React.CSSProperties;
    className?: string;
};

type ColorEvent = { source: string } & ColorResult;

const decimalToHex = (alpha: number) =>
    alpha === 0 ? "00" : Math.round(255 * alpha).toString(16);

export const ColorPicker: React.FunctionComponent<ColorPickerProps> = (
    props
) => {
    const { defaultValue, onChange, disabled } = props;
    const [color, setColor] = React.useState<string>(
        parseColorToHex(defaultValue || COLORS[0])
    );

    const handleColorChange = (color: ColorEvent) => {
        let hexCode = color.hex;
        let rgbaCode = color.hex;
        if (color.source === "rgb") {
            hexCode = `${color.hex}${decimalToHex(color.rgb.a as number)}`;
            rgbaCode = `#${decimalToHex(
                color.rgb.a as number
            )}${color.hex.substring(1)}`;
        }

        setColor(hexCode);

        onChange && onChange(new Event("change"), rgbaCode);
    };

    React.useEffect(() => {
        defaultValue && setColor(parseColorToHex(defaultValue));
    }, [defaultValue]);

    return (
        <Dropdown className="color-picker">
            <Dropdown.Toggle
                variant="success"
                id="dropdown-color-picker"
                className={
                    props.className
                        ? "color-picker__toggle " + props.className
                        : "color-picker__toggle unit-picker-toggle"
                }
                disabled={disabled}
                role="div"
            >
                <ColorPickerBackground $inputColor={color} className="w-32px">
                    &nbsp;
                </ColorPickerBackground>
            </Dropdown.Toggle>

            <Dropdown.Menu className="color-picker-dropdown-menu">
                <SketchPicker
                    color={color}
                    onChangeComplete={(color) => {
                        handleColorChange(color as ColorEvent);
                    }}
                    presetColors={COLORS}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export const UnitPicker: React.FunctionComponent<ColorPickerProps> = (
    props
) => {
    const { data, defaultValue, onChange } = props;
    const [value, setValue] = React.useState<string>(defaultValue || "");

    React.useEffect(() => {
        onChange &&
            onChange(
                new Event("change", {
                    bubbles: true,
                }),
                value
            );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Dropdown className="unit-picker">
            <Dropdown.Toggle
                variant="success"
                id="dropdown-unit-picker"
                role="div"
                className={
                    props.className
                        ? "unit-picker__toggle " + props.className
                        : "unit-picker__toggle unit-picker-toggle"
                }
                disabled
            >
                <span className="h-17px d-flex align-items-center">
                    {value}
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {data?.map((item, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => {
                            setValue(item);
                        }}
                    >
                        <div className="unit-picker__input">{item}</div>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const speltOutPrimaryUnit = (device: any) => {
    if (device.APP) {
        if (device.unit === "BINARY" || !device.unit) return [""];
        const appSensor = device.APP.find((d: any) => d.APPID === device.APPID);
        return getPrimaryUnitKey(appSensor);
    } else {
        return device.units || device.unit.split(",");
    }
};

export const getMQTTQueryByDevice = (device: any) => {
    return metricStore
        .getState()
        .getMQTTQuery(device.CLS, device.APPID, speltOutPrimaryUnit(device));
};

export type CreateChartState = {
    title?: string;
    multiple?: boolean;
    singleSensor?: boolean;
    data: any[];
    columns: {
        key: string;
        name: string;
        className?: string;
        render?: (data: any) => JSX.Element | null;
    }[];
    transform: (data: any) => { [key: string]: any };
    validate?: () =>
        | {
              path: string;
              isValid: boolean | "true" | "false";
              message: string;
          }[]
        | undefined;
};

export const useCreateDashboardStore = create<{
    loading: boolean;
    setLoading: (loading: boolean) => void;
    dashboardId: string;
    setDashboardId: (dashboard_id: string) => void;
    chartType: string;
    setChartType: (chart_type: string) => void;
    createData: CreateChartState | undefined;
    createModalShow: boolean;
    setCreateModalShow: (show: boolean) => void;
    allRegistry: any[];
    deviceList: any[];
    currentCharts: PanelV2[];
    setCurrentCharts: (charts: PanelV2[]) => void;
}>((set, get) => ({
    loading: false,
    setLoading: (loading) => set({ loading }),
    dashboardId: "",
    currentCharts: [],
    setCurrentCharts: (charts: PanelV2[]) => set({ currentCharts: charts }),
    setDashboardId: (dashboard_id: string) =>
        set({ dashboardId: dashboard_id }),
    chartType: ChartTypeList.Gateway,
    setChartType: (chart_type: string) => set({ chartType: chart_type }),
    createData: undefined,
    createModalShow: false,
    setCreateModalShow: (show: boolean) => {
        if (show) {
            const createData = get().createData;
            const validate = createData?.validate?.() || [];
            const isValid = validate.every((item) => item.isValid);
            const currentCharts = get().currentCharts;

            const totalChart =
                currentCharts.length + (createData?.data?.length || 0);

            if (
                get().chartType === ChartTypeList.Sensor &&
                createData?.multiple
            ) {
                const invalid = createData?.data.some(({ attributes }: any) => {
                    const uniqueColors = _.unionBy(attributes, ({ color }) =>
                        _.lowerCase(color)
                    );
                    if (uniqueColors.length < attributes.length) {
                        return true;
                    }

                    return false;
                });

                if (invalid) {
                    showErrorAlert({
                        message: CHART_DUPLICATE_COLOR_MESSAGE,
                    });
                    return;
                }
            }

            if (totalChart > DASHBOARD_CHART_MAX) {
                showErrorAlert({
                    message: DASHBOARD_CHART_LIMIT_MESSAGE,
                });

                return;
            }

            if (isValid) {
                set({ createModalShow: true });
            } else {
                validate.forEach(
                    ({ message, isValid }) =>
                        !isValid && showErrorAlert({ message })
                );
            }
        } else {
            set({ createModalShow: false });
        }
    },
    allRegistry: [],
    deviceList: [],
}));

const DashboardCreateBody: React.FunctionComponent<{ chartType: string }> = (
    props
) => {
    let LoadComponent = null;

    switch (props.chartType) {
        case ChartTypeList.Sensor:
            LoadComponent = require("./SensorCharts").default;
            break;
        case ChartTypeList.Actuator:
            LoadComponent = require("./ActuatorCharts").default;
            break;
        case ChartTypeList.Other:
            LoadComponent = require("./OtherCharts").default;
            break;
        default:
            LoadComponent = require("./GatewayCharts").default;
    }

    return <LoadComponent />;
};

const DashboardCreate = () => {
    const params: { dashboardId: string } = useParams();
    const history = useHistory();
    useMetricDefined();
    const {
        chartType,
        setDashboardId,
        setChartType,
        setCreateModalShow,
        setLoading,
        createData,
    } = useCreateDashboardStore();

    React.useEffect(() => {
        setChartType(ChartTypeList.Gateway);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const { dashboardId } = params;
        setDashboardId(dashboardId);

        const fetch = async () => {
            setLoading(true);
            const devices = await fetchDevices();

            const { data = [] } = await dashboardService.getPanelDashboard(
                dashboardId,
                {
                    offset: 0,
                    limit: 100,
                }
            );

            useCreateDashboardStore.setState({
                ...devices,
                currentCharts: data,
            });

            setLoading(false);
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.dashboardId]);

    return (
        <>
            <div className="page-content">
                <ContainerStyled>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title">Create charts</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" className="form-box">
                            {enableChartTypeSelection && (
                                <>
                                    <Form>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="select-chart-type"
                                        >
                                            <Form.Label>Charts for</Form.Label>
                                            <Form.Control
                                                as="select"
                                                aria-label="Select chart type"
                                                value={chartType}
                                                onChange={(e) => {
                                                    useCreateDashboardStore.setState(
                                                        {
                                                            createData:
                                                                undefined,
                                                        }
                                                    );

                                                    setChartType(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                {Object.keys(ChartTypeList).map(
                                                    (key) => {
                                                        const value =
                                                            ChartTypeList[
                                                                key as ChartTypeListKey
                                                            ];

                                                        return (
                                                            <option
                                                                key={key}
                                                                value={value}
                                                            >
                                                                {value}
                                                                {[
                                                                    ChartTypeList.Actuator,
                                                                    ChartTypeList.Gateway,
                                                                    ChartTypeList.Sensor,
                                                                ].includes(
                                                                    value
                                                                ) && "s"}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                    <hr className="mb-4" />
                                </>
                            )}
                            <DashboardCreateBody chartType={chartType} />
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            sm="12"
                            className="d-flex justify-content-center mt-4"
                        >
                            <Button
                                className="text-uppercase"
                                variant="secondary"
                                onClick={() => {
                                    history.push("/dashboard");
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="ml-2 text-uppercase"
                                variant="primary"
                                onClick={() => setCreateModalShow(true)}
                                disabled={!createData?.data.length}
                            >
                                Create
                            </Button>
                        </Col>
                    </Row>
                </ContainerStyled>
            </div>
            <style>{`
                @media(min-width: 576px) {
                    .wide-modal {
                        width: 80vw;
                        max-width: 80vw;
                    }
                }

                #chart-edit-form .table-row {
                    background-color: var(--create-chart-from-background);
                }

                #chart-edit-form .form-control + .form-control,
                #chart-edit-form .custom-control + .custom-control {
                    margin-top: .75rem;
                }

                #chart-edit-form .custom-control {
                    height: 38px;
                    display: flex;
                    align-items: center;
                }

                #chart-edit-form .form-control,
                .custom-control-label:before,
                #device-form .form-control {
                    background-color: var(--create-chart-form-secondary);
                }

                #chart-edit-form .custom-control-label{
                    padding-left: 10px;
                }
            `}</style>
        </>
    );
};

export default DashboardCreate;
