import React from 'react';

export const useScreen = () => {
    const [screen, setScreen] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    React.useEffect(() => {
        const handler = () => {
            setScreen({
                width: document.body.clientWidth,
                height: document.body.clientHeight,
            });
        };

        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    return screen;
}