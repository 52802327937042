import { Form } from "react-bootstrap";

import MinuteSecondPicker, {
    MinuteSecondPickerProps,
} from "components/common/MinuteSecondPicker";

import { DeactivateOption } from "constant";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { DeactivateOptions } from "./DeactivateOptions";
import ActuatorCount from "./ActuatorCount";
import PolarityOptions from "./PolarityOptions";

const Loop = () => {
    const {
        editedActuator,
        editedActuator: { mode },
        setEditedActuator,
    } = useActuatorConfigurationContext();

    const minuteSecondPickerProps: MinuteSecondPickerProps = {
        editedActuator,
        setEditedActuator,
    };

    return (
        <>
            <PolarityOptions />

            <MinuteSecondPicker {...minuteSecondPickerProps} />

            <hr />

            <Form.Label className="mt-3">Number of loops</Form.Label>

            <ActuatorCount />

            <DeactivateOptions options={DeactivateOption[`${mode}`]} />
        </>
    );
};

export default Loop;
