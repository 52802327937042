import { apiFactory } from "generated";
import {
    createErrorResponseStandard,
    createResponseStandard,
    orgIdQuery,
} from "../utils/functions";
import AxiosInstance from "./axiosInstance";
import { GetMeteringInfoParamsService } from "generated/models";

const meteringClient = apiFactory.getMeteringInstance(AxiosInstance);

export const meteringService = {
    getMeteringInfo: async (searchParams: {
        /** Year */
        year: string;
        /** Month to filter */
        month?: string;
        /** Organization ID to filter */
        organization_id?: string;
        /** Service to filter */
        service?: GetMeteringInfoParamsService;
        /** Do not return service usage with 100% discount (false by default) */
        ignore_discount?: boolean;
        /** Return also the previous month data if 'month' is provided (false by default) */
        previous_month?: boolean;
        /** Return service usage in payment token (false by default) */
        in_token?: boolean;
    }) => {
        try {
            const response: any = await meteringClient.getMeteringInfo(
                searchParams
            );
            return createResponseStandard(response);
        } catch (err: any) {
            return createErrorResponseStandard(err);
        }
    },
    getStorageInfo: async (gatewayId: string) => {
        try {
            const response: any = await meteringClient.getStorageInfo({
                ...orgIdQuery(),
                gateway_id: gatewayId,
            });
            return createResponseStandard(response);
        } catch (err: any) {
            return createErrorResponseStandard(err);
        }
    },
};
