export class MathExpressionValidator {
    private isOperator(char: string): boolean {
        return ["+", "-", "*", "/"].includes(char);
    }

    private isUnaryOperator(char: string): boolean {
        return ["+", "-"].includes(char);
    }

    private validateParentheses(expression: string): boolean {
        let stack: string[] = [];
        for (let char of expression) {
            if (char === "(") {
                stack.push(char);
            } else if (char === ")") {
                if (stack.length === 0) return false;
                stack.pop();
            }
        }
        return stack.length === 0;
    }

    private validateTokenOperator(
        token: string,
        tokens: string[],
        i: number
    ): boolean {
        // An operator should not be at the end, except for unary operators at the start
        if (i === tokens.length - 1) {
            return false;
        }

        // An operator should not be preceded or followed by another operator
        if (this.isOperator(tokens[i - 1]) || this.isOperator(tokens[i + 1])) {
            // Handle unary operators
            if (
                this.isUnaryOperator(token) &&
                tokens[i + 1] !== undefined &&
                !this.isOperator(tokens[i + 1])
            ) {
                return true;
            }
            return false;
        }

        return true;
    }

    private validateTokenParenthesis(
        token: string,
        tokens: string[],
        i: number
    ): boolean {
        // Check for empty parentheses
        if (token === "(" && tokens[i + 1] === ")") return false;

        // A closing parenthesis should not be preceded by an operator or another opening parenthesis
        if (
            token === ")" &&
            (this.isOperator(tokens[i - 1]) || tokens[i - 1] === "(")
        )
            return false;

        // An opening parenthesis should not be followed by an operator or another closing parenthesis
        if (
            token === "(" &&
            (this.isOperator(tokens[i + 1]) || tokens[i + 1] === ")") &&
            !this.isUnaryOperator(tokens[i + 1])
        )
            return false;

        // A multiplication or division directly followed by a parenthesis with unary operator should be invalid
        if (
            (token === "*" || token === "/") &&
            tokens[i + 1] === "(" &&
            this.isUnaryOperator(tokens[i + 2])
        )
            return false;

        // Closing parenthesis should not be directly followed by an opening parenthesis or a number
        if (
            token === ")" &&
            (tokens[i + 1] === "(" || /^\d+(\.\d+)?$/.test(tokens[i + 1]))
        )
            return false;

        return true;
    }

    private validateTokens(tokens: string[]): boolean {
        for (let i = 0; i < tokens.length; i++) {
            let token = tokens[i];

            // Check if the token is a number (integer or float)
            if (/^\d+(\.\d+)?$/.test(token)) continue;

            // Check if the token is an operator
            if (this.isOperator(token)) {
                const isValidOperator = this.validateTokenOperator(
                    token,
                    tokens,
                    i
                );
                if (isValidOperator) {
                    continue;
                } else {
                    return false;
                }
            }

            // Check if the token is a parenthesis
            if (token === "(" || token === ")") {
                const isValidTokenParenthesis = this.validateTokenParenthesis(
                    token,
                    tokens,
                    i
                );
                if (isValidTokenParenthesis) {
                    continue;
                } else {
                    return false;
                }
            }

            // If the token is not valid
            return false;
        }
        return true;
    }

    // Function to validate the expression
    public validateExpression(expression: string): boolean {
        // Remove spaces
        expression = expression.replace(/\s+/g, "");

        // Check for invalid characters
        if (!/^[0-9+\-*/().]*$/.test(expression)) return false;

        // Validate parentheses
        if (!this.validateParentheses(expression)) return false;

        // Split the expression into tokens
        let tokens = expression.split(/([+\-*/()])/).filter((token) => token);

        // Ensure unary operators are properly placed at the beginning or after another operator or parenthesis
        if (tokens.length > 0 && this.isUnaryOperator(tokens[0])) {
            tokens.unshift("0"); // prepend a zero to handle cases like +4 or -5 at the beginning
        }

        return this.validateTokens(tokens);
    }
}
