import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button, Form, Modal, FormControl, Row, Col } from "react-bootstrap";
import {
    getActuatorListByGatewayIds,
    getAllRegistry,
    getSensorListByGatewayIds,
} from "service/gatewayService";
import { getSensorTypes } from "utils/gatewayFunctions";
import { updateFormFields, getGeneralColProps } from "utils/react-bootstrap";
import { styled } from "@mui/system";
import styledConst from "styles";
import { DeviceTypeDict } from "constant";
import { displaySensorType, isHttpSuccess } from "utils/functions";
import { get } from "lodash";

const StyledContainer = styled(Modal)`
    .modal-body {
        .filter-container {
            color: ${styledConst.thirdTextColor};

            .filterBy {
                background: #f8f8f8;
                border: 1px solid #eaeaea;
                border-radius: 4px;
                margin: 10px 0px;
                align-items: center;
                padding: 10px;

                select {
                    height: fit-content;
                    padding: 5px;
                    background: white;
                    border: 1px solid #ced4da;
                    border-radius: 3px;
                }
            }
        }

        .scroll-multiple-list {
            max-height: 55vh;
        }

        .warning-message {
            color: ${styledConst.warningTextColor};
        }
    }
`;

const serviceDict: { [key: string]: any } = {
    [DeviceTypeDict.sensor.label]: {
        title: "Select Sensor",
        // allDeviceLabel: "All Sensors",
        getListByGwId: getSensorListByGatewayIds,
        getListType: getSensorTypes,
    },
    [DeviceTypeDict.actuator.label]: {
        title: "Select Actuator",
        // allDeviceLabel: "All Actuators",
        getListByGwId: getActuatorListByGatewayIds,
        getListType: getSensorTypes,
    },
};

const SensorActuatorSelectModal = (props: any) => {
    const {
        isVisible,
        onClose,
        onFinish,
        defaultSearchValue = {
            gatewayId: "",
            sensorType: "",
            search: "",
        },
        unitType = DeviceTypeDict.sensor.label,
    } = props;

    const { getListByGwId, getListType, title } = serviceDict[unitType];

    const [searchValues, setSearchValue] = useState(defaultSearchValue);
    const [isLoading, setIsLoading] = useState(true);
    const [registries, setRegistries] = useState([]);
    const [sensorTypes, setSensorTypes] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [_sensors, _setSensors] = useState([]);
    const [selectedSensor, setSelectedSensor] = useState<null | any>(null);
    const fetchAll = async () => {
        setIsLoading(true);

        getAllRegistry().then((resAllRegistry) => {
            let gatewayIds = [];
            if (isHttpSuccess(resAllRegistry.status)) {
                const { data = [] } = resAllRegistry;
                const onBoardedGateways = data.filter(
                    ({ info }: { info: any }) => info
                );
                gatewayIds = onBoardedGateways.map(
                    ({ gateway_id }: any) => gateway_id
                );
                setRegistries(onBoardedGateways);
            }
            getListByGwId(gatewayIds).then((_sensors: any) => {
                setSensorTypes(getListType(_sensors));
                setSensors(_sensors);
                _setSensors(_sensors);
                setIsLoading(false);
            });
        });
    };
    //
    useEffect(() => {
        if (isVisible) {
            fetchAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    useEffect(() => {
        const { gatewayId = "", sensorType = "", search = "" } = searchValues;
        setSelectedSensor(null);
        _setSensors(
            [...(sensors || [])]
                .filter(
                    ({ gatewayId: _gatewayId }) =>
                        gatewayId === "" || gatewayId === _gatewayId
                )
                .filter(
                    ({ sensor_type: _sensorType, SAID, APP }) =>
                        sensorType === "" ||
                        sensorType === _sensorType ||
                        sensorType === get(APP, `[${SAID}].NAME`)
                )
                .filter(
                    ({ device_name }: { device_name: string }) =>
                        search === "" ||
                        device_name
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) >= 0
                )
        );
    }, [searchValues, sensors]);

    const selectSensor = (i: string) => {
        setSelectedSensor(i);
    };

    return (
        <StyledContainer
            show={isVisible}
            onHide={onClose}
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <Button variant="" className="close-button" onClick={onClose}>
                    <span className="material-icons">close</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form className="filter-container">
                    <Row className="filterBy">
                        <Col {...getGeneralColProps(2)}>Filter by:</Col>
                        <Col {...getGeneralColProps(5)}>
                            <FormControl
                                as="select"
                                aria-label="org-name"
                                name="gatewayId"
                                disabled={isLoading}
                                // placeholder={allDeviceLabel}
                                value={searchValues.gatewayId}
                                onChange={(e: any) =>
                                    updateFormFields(
                                        e,
                                        searchValues,
                                        setSearchValue
                                    )
                                }
                            >
                                <option value="">All Gateways</option>

                                {(registries || []).map(
                                    ({ name, gateway_id: gatewayId }: any) => {
                                        return (
                                            <option
                                                key={gatewayId}
                                                value={gatewayId}
                                            >
                                                {name}
                                            </option>
                                        );
                                    }
                                )}
                            </FormControl>
                        </Col>
                        <Col {...getGeneralColProps(5)}>
                            <FormControl
                                as="select"
                                name="sensorType"
                                placeholder="All types"
                                disabled={isLoading}
                                value={searchValues.sensorType}
                                onChange={(e: any) =>
                                    updateFormFields(
                                        e,
                                        searchValues,
                                        setSearchValue
                                    )
                                }
                            >
                                <option value="">All types</option>

                                {(sensorTypes || []).map(({ id, name }) => {
                                    return (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })}
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <FormControl
                            type="text"
                            name="search"
                            placeholder="Search"
                            className="mb-4"
                            onChange={(e) => {
                                updateFormFields(
                                    e,
                                    searchValues,
                                    setSearchValue
                                );
                            }}
                        />
                    </Row>
                </Form>

                {isLoading ? (
                    <div className="warning-message">loading...</div>
                ) : sensors.length === 0 ? (
                    <div className="error-message">
                        You don't have any device connected yet. Please ensure
                        your gateway is onboarded.
                    </div>
                ) : (
                    <div className="scroll-multiple-list float-left">
                        {_sensors.map((sensor: any = {}, i: number) => {
                            const {
                                ldsu_uuid: _ldsu_uuid,
                                SAID,
                                bus,
                                CLS,
                                gatewayId: _gw_id,
                            } = sensor;
                            const gw: any = registries.find(
                                (r: any) => r.gateway_id === _gw_id
                            );
                            const _sensor_actuator =
                                gw.configs.find(
                                    (sa: any) => sa.UID === _ldsu_uuid
                                ) ?? {};
                            const _auto: any =
                                _sensor_actuator.CMDS?.[SAID]?.auto ?? [];

                            return (
                                <div
                                    key={`${bus}_${_ldsu_uuid}_${SAID}`}
                                    className="modal-option"
                                >
                                    <Form.Check
                                        name="selected-sensor"
                                        value={`${bus}_${_ldsu_uuid}_${SAID}`}
                                        checked={
                                            selectedSensor &&
                                            `${bus}_${_ldsu_uuid}_${SAID}` ===
                                                `${selectedSensor.bus}_${selectedSensor.ldsu_uuid}_${selectedSensor.SAID}`
                                        }
                                        custom
                                        disabled={
                                            !_auto && Number(CLS) >= 32768
                                        }
                                        onChange={(e) => {
                                            selectSensor(sensor);
                                        }}
                                        type="radio"
                                        id={`${bus}_${_ldsu_uuid}_${SAID}`}
                                        label={
                                            <div
                                                onClick={(e) => {
                                                    if (!_auto) return;
                                                    selectSensor(sensor);
                                                }}
                                                onKeyDown={(e) => {
                                                    if (!_auto) return;
                                                    selectSensor(sensor);
                                                }}
                                            >
                                                <h4>{sensor.device_name}</h4>
                                                <p>
                                                    {sensor.gatewayName} - LDS
                                                    BUS {sensor.bus}
                                                </p>
                                                <p>UUID: {sensor.ldsu_uuid}</p>
                                                <p>
                                                    {displaySensorType(sensor)}
                                                </p>
                                            </div>
                                        }
                                        className="float-left"
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </Modal.Body>
            {selectedSensor !== null && (
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => onFinish(selectedSensor)}
                    >
                        DONE
                    </Button>
                </Modal.Footer>
            )}
        </StyledContainer>
    );
};

SensorActuatorSelectModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    defaultSearchValue: PropTypes.object,
};

export default SensorActuatorSelectModal;
