import { useState, useEffect, useRef, useCallback } from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import { getSampleQuery } from "service/proqueryService";
import { getDeviceConfigById } from "service/gatewayService";
import CandlestickChartIcon from "assets/svg/candlestick-chart-icon.svg";
import LineChartIcon from "assets/svg/line-chart-icon.svg";
import { get, set, cloneDeep, startCase, debounce, isEmpty } from "lodash";
import {
    HttpStatus,
    Operator,
    UnknownSensor,
    DEFAULT_OPTION,
    PANEL_TOKEN_ZERO_TOKEN,
    PANEL_DATE_TIME_FORMAT,
} from "constant";
import {
    addDecimalPlace,
    convertPrometheusTimeToSeconds,
    getAccuracyFromOBJ,
    isHttpSuccess,
    monthsToSeconds,
    parseColorToHex,
    totalDoesNotExceedMax,
} from "utils/functions";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import ChartBox from "./ChartBox";
import { DISABLE_MISSING_DATA } from "utils/config";
import LocalStorageService from "service/localStorageService";
import { BasicSensorChartProps, ChartState } from "types/Charts";
import { getMode } from "./ActuatorChart";
import useChartRefreshRate from "hooks/useChartRefreshRate";
import RefreshRateDropdown from "components/dashboard/RefreshRateDropdown";
import useSelectChartTimeRange from "hooks/useSelectChartTimeRange";
import ChartTimeSelectionDropdown from "components/dashboard/ChartTimeSelectionDropdown";
import ChartActionDropdown from "../../ChartActionDropdown";
import useChartWidth from "hooks/useChartWidth";
import { CustomRangeDetails } from "components/dashboard/CustomRangeDetails";

// ----------------- http status -----------------
const { FORBIDDEN } = HttpStatus;

const sensorChartNoDataStyleOption = (
    sensorType: string,
    sensorUnit: string
) => [
    {
        path: "title.subtext",
        value: `{s6|${sensorType}}\n{s2|${`- ${sensorUnit}`}} {s5|    Min/Max} {s3| ${"-"}/${"-"}${
            sensorUnit || ""
        }}\n{s1|${moment().format("DD/MM/YYYY hh:mm:ss")}}`,
    },
];

const actuatorChartNoDataStyleOption = (
    sensorType: string,
    sensorUnit: string,
    sensorConfigRes: any
) => [
    {
        path: "title.subtext",
        value: `{s6|${startCase(sensorType)}}\n{s3|Mode: }{s5|${getMode({
            sensorConfigRes,
            sensorType,
        })}}\n{s2|${"-" + (sensorUnit || "")}}{s1|    ${moment().format(
            "DD/MM/YYYY hh:mm:ss"
        )}}`,
    },
];

const getChartAreaAlertConfig = ({ vars_, ops, sensorUnit }: any) => {
    const thresholdLine: any = [];
    const thresholdArea: any = [];
    let position = "";

    const primaryAlertThreshold = vars_[1];
    const secondaryAlertThreshold = vars_[3];

    if (ops.length === 1) {
        // ops.length === 1 means single alert value

        position =
            ops[0] === Operator.LESS_THAN
                ? "insideStartTop"
                : "insideStartBottom";
        thresholdArea.push([
            {
                name: `${primaryAlertThreshold}`,
                yAxis: `${primaryAlertThreshold}`,
            },
            {
                yAxis:
                    ops[0] === Operator.LESS_THAN
                        ? `${Number.MIN_SAFE_INTEGER}`
                        : `${Number.MAX_SAFE_INTEGER}`,
            },
        ]);

        thresholdLine.push({
            yAxis: Number(primaryAlertThreshold),
            label: {
                show: true,
                position,
                color: "#E86881",
                formatter: `{c} ${sensorUnit}`,
            },
        });
    } else {
        // ops.length !== 1 means dual alert value

        if (ops.toString() === Operator.WITHIN_RANGE.toString()) {
            // and - within range
            thresholdArea.push([
                {
                    yAxis: `${primaryAlertThreshold}`,
                },
                {
                    yAxis: `${secondaryAlertThreshold}`,
                },
            ]);
        } else if (ops.toString() === Operator.OUT_OF_RANGE.toString()) {
            // or - out of range
            thresholdArea.push(
                [
                    {
                        yAxis: `${primaryAlertThreshold}`,
                    },
                    {
                        yAxis: `${Number.MIN_SAFE_INTEGER}`,
                    },
                ],
                [
                    {
                        yAxis: `${secondaryAlertThreshold}`,
                    },
                    {
                        yAxis: `${Number.MAX_SAFE_INTEGER}`,
                    },
                ]
            );
        }
        thresholdLine.push(
            {
                yAxis: Number(primaryAlertThreshold),
                label: {
                    show: true,
                    position: "insideStartBottom",
                    color: "#E86881",
                    formatter: `{c} ${sensorUnit}`,
                },
            },
            {
                yAxis: Number(secondaryAlertThreshold),
                label: {
                    show: true,
                    position: "insideStartTop",
                    color: "#E86881",
                    formatter: `{c} ${sensorUnit}`,
                },
            }
        );
    }

    return { thresholdLine, thresholdArea };
};

const chartAlertAreaOptionConfig = ({ thresholdLine, thresholdArea }: any) => ({
    markLine: {
        symbol: "none",
        data: thresholdLine,
        animationDuration: 0,
        lineStyle: {
            color: "#E86881",
            width: 2,
            type: "solid",
        },
        silent: true,
    },
    markArea: {
        itemStyle: {
            color: "rgba(255, 173, 177, 0.2)",
        },

        label: {
            show: false,
        },
        silent: true,
        data: thresholdArea,
    },
    smooth: true,
    type: "line",
    data: [],
});
const candlestickChartAlertAreaOptionConfig = ({
    thresholdLine,
    thresholdArea,
}: any) => ({
    markLine: {
        label: {
            show: false,
        },
        animationDuration: 0,
        symbol: "none",
        data: thresholdLine,
        lineStyle: {
            color: "#E86881",
            width: 2,
            type: "solid",
        },
        silent: true,
    },
    markArea: {
        itemStyle: {
            color: "rgba(255, 173, 177, 0.05)",
        },

        label: {
            show: false,
        },
        silent: true,
        data: thresholdArea,
    },
    smooth: true,
    type: "line",
    data: [],
});

const getMinMaxFromArray = ({
    array,
    accuracy,
}: {
    array: any;
    accuracy: any;
}) => {
    if (!array.length) return { min: NaN, max: NaN };

    const minValue = Number(array.sort((a: any, b: any) => a[1] - b[1])[0][1]);
    const maxValue = Number(array.sort((a: any, b: any) => b[1] - a[1])[0][1]);

    return {
        min: parseFloat(addDecimalPlace(minValue, accuracy)),
        max: parseFloat(addDecimalPlace(maxValue, accuracy)),
    };
};

const yAxisMinMaxChartOptions = ({ min, max }: { min: any; max: any }) => [
    {
        path: "yAxis.max",
        value: max,
    },
    {
        path: "yAxis.min",
        value: min,
    },
];

const DEFAULT_ACCURACY: string = "1";

const updateSeriesPayloadWithNameAndDecimal = ({
    series,
    accuracy,
    sensorName,
}: {
    series: any;
    accuracy: string;
    sensorName: string;
}) => {
    series.forEach((element: { name: string; data: [number, string][] }) => {
        element.name = sensorName;
        element.data = element.data.map(
            ([timestamp, value]: [number, string]) => [
                timestamp,
                addDecimalPlace(value, accuracy),
            ]
        );
    });
};

const getLiveDataInfo = ({
    series,
    accuracy,
}: {
    series: any;
    accuracy: string;
}) => {
    const now = moment().unix() * 1000;

    const pathToLatestData = `[${series.length - 1}].data[${
        get(series, `[${series.length - 1}].data`, []).length - 1
    }]`;

    const liveData: any = addDecimalPlace(
        get(series, `${pathToLatestData}[1]`, "-"),
        accuracy
    );

    const unix_timestamp = get(series, `${pathToLatestData}[0]`, now);
    const liveDate = new Date(unix_timestamp).toLocaleDateString("en-GB");
    const liveTime = new Date(unix_timestamp).toLocaleTimeString("en-US", {
        hour12: false,
    });

    return { liveData, liveDate, liveTime };
};

const BasicSensorChart = (props: BasicSensorChartProps) => {
    const jsonList = LocalStorageService.getItem("listOfJSON");
    // ----------------- props -----------------
    const {
        panel,
        panel: {
            query_time,
            time_range,
            queries,
            attributes,
            token,
            refresh_rate,
            uuid: panelId,
            source,
            name,
        },
        isVisible,
        visibleRef,
        customEChartStyle,
        customEChartData,
        displayName,
        onDelete,
        zoomLevel,
        selectedDashboardUUID,
        gatewaysConfig,
        allAlerts,
    } = props;

    const panelToken = token[0];
    const panelQuery = queries[0];
    const {
        said,
        device_id: ldsuId,
        gateway_id: gatewayId,
        name: sensorType,
        unit: sensorUnit,
        color,
        show_alert,
    } = attributes[0];
    const chartColor = parseColorToHex(color);
    const controller = new AbortController();
    const { signal } = controller;
    const chartRef = useRef(null as any);
    const chartInstance: any = chartRef.current?.getEchartsInstance();

    // ----------------- chart -----------------
    const [chartState, setChartState] = useState<ChartState>({
        option: DEFAULT_OPTION,
        hasFetchedConfig: false,
        sensorConfigRes: undefined,
    });
    const [chartZoom, setChartZoom] = useState<{ start: number; end: number }>({
        start: 0,
        end: 100,
    });
    // ----------------- alertArea -----------------
    const [alertState, setAlertState] = useState<{
        showAlertArea: string;
        isAlertEnabled: boolean;
        ops: any;
        vars_: string;
    }>({
        showAlertArea: show_alert,
        isAlertEnabled: false,
        ops: [],
        vars_: "",
    });
    const { showAlertArea, isAlertEnabled, ops, vars_ } = alertState;
    // ----------------- refresh rate -----------------
    const {
        refreshRate,
        setRefreshRate,
        editableCustomRate,
        setEditableCustomRate,
    } = useChartRefreshRate(refresh_rate);

    // ----------------- time range -----------------
    const {
        lastTimeRangeSelect,
        setLastTimeRangeSelect,
        getStartDateTime,
        getEndDateTime,
        getTimeGapObject,
    } = useSelectChartTimeRange({
        initial_time_range: time_range,
        initial_query_time: query_time,
        setRefreshRate,
        dashboardId: selectedDashboardUUID,
        panelId,
    });

    // ----------------- run out of token -----------------
    const [isOutOfPaymentToken, setIsOutOfPaymentToken] = useState(false);

    const { boxWidth } = useChartWidth({
        ref: visibleRef,
        occupiedWidth: 200,
    });

    // ! ----------------- functions -----------------

    const shouldShowAggregation = (selected_time_value: number) => {
        const sensorReportRate = lastTimeRangeSelect.INTVL;
        const totalSample = Math.floor(selected_time_value / sensorReportRate);
        return (
            selected_time_value > monthsToSeconds(6) ||
            !totalDoesNotExceedMax({ total: totalSample, numberOfChart: 1 })
        );
    };

    const getUpdatedChartConfig = async (selected_time_value: number) => {
        const newLastTimeRangeSelect: any = {};
        if (!DISABLE_MISSING_DATA) {
            const deviceConfigResponse = await getDeviceConfigById(
                gatewayId,
                ldsuId,
                { signal }
            );
            if (isHttpSuccess(deviceConfigResponse.status)) {
                newLastTimeRangeSelect.INTVL = get(
                    deviceConfigResponse,
                    "data.INTVL",
                    10
                );
            }
        }

        newLastTimeRangeSelect.showAggregated =
            shouldShowAggregation(selected_time_value);
        newLastTimeRangeSelect.showCandlestickChart = false;
        return newLastTimeRangeSelect;
    };

    const getNewOptions = (
        baseOption: EChartsOption,
        newConfigArr: any = []
    ) => {
        const newOption = cloneDeep(baseOption); // immutable

        newOption.title.subtextStyle.rich.s2.color = chartColor;
        newConfigArr.map(({ path, value }: any) => set(newOption, path, value));

        return newOption;
    };

    const fetchNewData = async () => {
        if (!isVisible || !chartState.hasFetchedConfig) return;

        const sensorName = displayName(chartState);

        const sampleRes: any = await getSampleQuery({
            query: panelQuery,
            chartColor,
            sensorType,
            panelToken,
            gapTime: lastTimeRangeSelect.INTVL * 1000,
            time_range: lastTimeRangeSelect.time_range,
            query_time: lastTimeRangeSelect.query_time,
            name: sensorName,
        });

        if (Array.isArray(sampleRes.series)) {
            let {
                series = [],
                aggregatedSeries = [],
                info: { object, said, app: appId },
            } = sampleRes ?? {};

            const { showCandlestickChart } = lastTimeRangeSelect;

            const accuracy: string =
                getAccuracyFromOBJ({
                    object,
                    jsonList,
                    said,
                    appId,
                }) || DEFAULT_ACCURACY;

            updateSeriesPayloadWithNameAndDecimal({
                series,
                accuracy,
                sensorName,
            });

            const { liveData, liveDate, liveTime } = getLiveDataInfo({
                series,
                accuracy,
            });

            // show alert area if sensor alert is enabled
            const thresholdLine: any = [];
            const thresholdArea: any = [];

            const primaryAlertThreshold = vars_[1];
            const secondaryAlertThreshold = vars_[3];

            if (showAlertArea && isAlertEnabled) {
                const { thresholdLine, thresholdArea } =
                    getChartAreaAlertConfig({ vars_, ops, sensorUnit });
                series.push(
                    chartAlertAreaOptionConfig({
                        thresholdLine,
                        thresholdArea,
                    })
                );
            }

            let min: number, max: number;

            // showCandlestickChart -> toggle to candlestick
            if (showCandlestickChart && aggregatedSeries) {
                const minList = cloneDeep(aggregatedSeries[2].data);
                const maxList = cloneDeep(aggregatedSeries[1].data);

                min = getMinMaxFromArray({ array: minList, accuracy }).min;
                max = getMinMaxFromArray({ array: maxList, accuracy }).max;

                if (showAlertArea && isAlertEnabled) {
                    series = aggregatedSeries.map((a: any) => {
                        return {
                            ...a,
                            ...candlestickChartAlertAreaOptionConfig({
                                thresholdLine,
                                thresholdArea,
                            }),
                        };
                    });
                } else {
                    series = aggregatedSeries;
                }
            } else {
                const arrayData = series.reduce(
                    (arr: any, chartSegment: any) => [
                        ...arr,
                        ...get(chartSegment, "data", []),
                    ],
                    []
                );

                min = getMinMaxFromArray({
                    array: arrayData,
                    accuracy,
                }).min;
                max = getMinMaxFromArray({
                    array: arrayData,
                    accuracy,
                }).max;
            }

            const _options = [
                {
                    path: "series",
                    value: series,
                },
                {
                    path: "legend.data",
                    value: lastTimeRangeSelect.showCandlestickChart
                        ? ""
                        : [sensorName],
                },
                {
                    path: "legend.selectedMode",
                    value: lastTimeRangeSelect.showCandlestickChart,
                },
                {
                    path: "title.subtext",
                    value: `{s2|${
                        (isNaN(liveData) || liveData === "NaN"
                            ? "-"
                            : liveData) +
                        (sensorUnit === undefined ? "" : sensorUnit)
                    }} {s5|    Min/Max} {s3| ${
                        !isNaN(min) ? addDecimalPlace(min, accuracy) : "-"
                    }/${!isNaN(max) ? addDecimalPlace(max, accuracy) : "-"}${
                        sensorUnit || ""
                    }}\n{s4| }\n{s1|${liveDate + " " + liveTime}}`,
                },
                {
                    path: "tooltip",
                    value: lastTimeRangeSelect.showCandlestickChart
                        ? {
                              trigger: "axis",

                              formatter: (params: any) => {
                                  return `
                          ${moment(params[0].value[0], "x").format(
                              PANEL_DATE_TIME_FORMAT
                          )}<br/>
                          <div class="mt-1 mb-1">
                            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
                                params[0].color
                            };"></span>
                            ${sensorName}
                            \u00A0\u00A0\u00A0
                            <b>${params[0].value[1]}${sensorUnit}</b><br />
                          </div>
                          Min ${
                              params[2]?.value?.[1]
                          }${sensorUnit} \u00A0\u00A0\u00A0
                          Max ${params[1]?.value?.[1]}${sensorUnit} <br />
                          `;
                              },
                          }
                        : {
                              trigger: "axis",
                              formatter: (params: any) => {
                                  return `
                        ${moment(params[0].value[0], "x").format(
                            PANEL_DATE_TIME_FORMAT
                        )}<br/>
                        <div class="mt-1 mb-1">
                          <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
                              params[0].color
                          };"></span>
                          ${params[0].seriesName}
                          \u00A0\u00A0\u00A0
                          <b>${addDecimalPlace(
                              params[0].value[1],
                              accuracy
                          )}${sensorUnit}</b><br />
                        </div>
                        `;
                              },
                          },
                },
                {
                    path: "xAxis.min",
                    value: Number(getStartDateTime()),
                },
                {
                    path: "xAxis.max",
                    value: Number(getEndDateTime()),
                },
                ...(customEChartData?.({
                    liveData,
                    chartState,
                    liveTime,
                    liveDate,
                    sensorUnit,
                    min,
                    max,
                    accuracy,
                    sensorType,
                }) ?? []),
            ];

            const haveAlertConfig: boolean = !!vars_;
            if (haveAlertConfig) {
                const largestMax = Math.max(
                    max,
                    // single
                    ...(vars_?.[1] ? [Number(primaryAlertThreshold)] : []),
                    // dual
                    ...(vars_?.[3] ? [Number(secondaryAlertThreshold)] : [])
                );
                const smallestMin = Math.min(
                    min,
                    // single
                    ...(vars_?.[1] ? [Number(primaryAlertThreshold)] : []),
                    // dual
                    ...(vars_?.[3] ? [Number(secondaryAlertThreshold)] : [])
                );
                _options.push(
                    ...yAxisMinMaxChartOptions({
                        min:
                            smallestMin !== undefined
                                ? (
                                      smallestMin -
                                      (largestMax - smallestMin) * 0.1
                                  ).toFixed(2)
                                : undefined,
                        max:
                            largestMax !== undefined
                                ? (
                                      largestMax +
                                      (largestMax - smallestMin) * 0.1
                                  ).toFixed(2)
                                : undefined,
                    })
                );
            } else {
                _options.push(...yAxisMinMaxChartOptions({ min, max }));
            }

            const newOption: any = getNewOptions(chartState.option, _options);

            setChartState((chartState) => ({
                ...chartState,
                option: newOption,
            }));
        }
        // Error handler
        if (sampleRes.response?.status === FORBIDDEN) {
            if (sampleRes.config.url.includes(PANEL_TOKEN_ZERO_TOKEN)) {
                setIsOutOfPaymentToken(true);
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeZoomHandler = useCallback(
        debounce(() => {
            const { start, end } = chartInstance.getOption().dataZoom[0];
            setChartZoom({
                start,
                end,
            });
        }, 500),
        [chartInstance]
    );

    chartInstance?.on("dataZoom", onChangeZoomHandler);

    // ! ----------------- useEffect -----------------

    useEffect(() => {
        if (isOutOfPaymentToken || !isVisible || !chartState.hasFetchedConfig)
            return;
        if (chartState.hasFetchedConfig && isVisible) {
            fetchNewData();
        }
        if (Number(refreshRate.seconds) === 0) return;

        const { INTVL: sensorReportRate } = lastTimeRangeSelect;

        let getChartRefreshRateInSecs = () => {
            return refreshRate.seconds === "auto"
                ? sensorReportRate
                : refreshRate.seconds;
        };

        const timer = setInterval(() => {
            fetchNewData();
        }, getChartRefreshRateInSecs() * 1000);
        return () => clearInterval(timer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isVisible,
        chartState.hasFetchedConfig,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify({ lastTimeRangeSelect, alertState }),
        refreshRate.seconds,
        isOutOfPaymentToken,
    ]);

    useEffect(() => {
        (async () => {
            if (!isVisible || chartState.hasFetchedConfig) return;

            const sensor = gatewaysConfig?.[gatewayId]?.find(
                ({ UID }: { UID: string }) => UID === ldsuId
            );

            const sensorConfigRes = {
                ...sensor,
                ASSOC: sensor?.ASSOC[said],
                CMD: sensor?.CMDS[said],
                MODE: sensor?.MODES[said],
                SAID: sensor?.SAIDS[said],
                sensor_name: sensor?.sensor_names[said],
            };

            let newOption = getNewOptions(chartState.option, [
                ...(source === "SENSOR"
                    ? sensorChartNoDataStyleOption(sensorType, sensorUnit)
                    : actuatorChartNoDataStyleOption(
                          sensorType,
                          sensorUnit,
                          sensorConfigRes
                      )),
                ...(customEChartStyle(chartColor) ?? []),
                {
                    path: "title.text",
                    value: name
                        ? name
                        : sensor
                        ? sensorConfigRes?.sensor_name
                        : UnknownSensor,
                },
            ]);

            sensor && setIsOutOfPaymentToken(false);

            setLastTimeRangeSelect({
                ...lastTimeRangeSelect,
                showAggregated: shouldShowAggregation(
                    convertPrometheusTimeToSeconds(
                        lastTimeRangeSelect.time_range
                    )
                ),
                INTVL: get(sensorConfigRes, "INTVL", 10),
            });

            setChartState((chartState) => ({
                ...chartState,
                hasFetchedConfig: true,
                option: newOption,
                sensorConfigRes,
            }));
        })();

        /* eslint-disable */
    }, [chartState, isVisible, isOutOfPaymentToken, gatewaysConfig]);

    useEffect(() => {
        const checkIfAlertEnabled = async () => {
            const _alertRes =
                allAlerts?.find((alert: any) => {
                    return (
                        alert.gateway_id === gatewayId &&
                        alert.device_id === ldsuId &&
                        alert.said === said
                    );
                }) || {};

            if (!isEmpty(_alertRes)) {
                const { enabled, ops, vars_ } = _alertRes;
                if (enabled) {
                    setAlertState({
                        ...alertState,
                        isAlertEnabled: true,
                        ops,
                        vars_,
                    });
                }
            }
        };
        checkIfAlertEnabled();
    }, [allAlerts]);

    useEffect(() => {
        chartInstance?.setOption({
            dataZoom: [
                {
                    type: "inside",
                    start: chartZoom.start,
                    end: chartZoom.end,
                },
                {
                    type: "slider",
                    realtime: true,
                    start: chartZoom.start,
                    end: chartZoom.end,
                    zoomLock: false,
                    textStyle: {
                        overflow: "break",
                        width: 60,
                        fontSize: 10,
                        lineHeight: 15,
                    },
                    bottom: 50,
                },
            ],
            title: {
                textStyle: {
                    width: boxWidth,
                },
                subtextStyle: {
                    width: boxWidth,
                },
            },
        });
    }, [
        chartInstance,
        boxWidth,
        getEndDateTime,
        getStartDateTime,
        chartZoom.start,
        chartZoom.end,
    ]);

    // Delta min max and change split for I/O Chart
    const changeAxisYMaxMin = (
        option: EChartsOption,
        deltaPercent: number = 2
    ) => {
        const newOption = cloneDeep(option);
        const { yAxis } = newOption;

        if (yAxis.max === 1 && yAxis.min === 0) {
            yAxis.splitNumber = 1;
        } else {
            const devi = Number(yAxis.max) - Number(yAxis.min);
            const roundedDecimal =
                String(yAxis.max).split(".")?.[1]?.length ||
                String(yAxis.min).split(".")?.[1]?.length ||
                0;
            // delta rounded with percent and roundedDecimal must always > 0
            const delta: number =
                Math.ceil(devi * deltaPercent * 10 ** (roundedDecimal - 2)) /
                10 ** roundedDecimal;
            yAxis.max =
                Number(yAxis.max) === 0
                    ? 0
                    : Number(Number(yAxis.max) + delta).toFixed(roundedDecimal);
            yAxis.min =
                Number(yAxis.min) === 0
                    ? 0
                    : Number(Number(yAxis.min) - delta).toFixed(roundedDecimal);
        }

        return newOption;
    };

    const onClickAggregate = () => {
        setLastTimeRangeSelect({
            ...lastTimeRangeSelect,
            showCandlestickChart: !lastTimeRangeSelect.showCandlestickChart,
        });
    };

    return (
        <ChartBox
            ref={visibleRef}
            className={
                zoomLevel <= 0.5
                    ? "widget-box temperature w-100 h-30vh"
                    : "widget-box temperature w-100"
            }
        >
            <div className="widget">
                <ChartActionDropdown
                    panelId={panelId}
                    selectedDashboardUUID={selectedDashboardUUID}
                    onDelete={onDelete}
                    panel={panel}
                    deviceConfig={chartState.sensorConfigRes}
                />
                <ChartTimeSelectionDropdown
                    lastTimeRangeSelect={lastTimeRangeSelect}
                    setLastTimeRangeSelect={setLastTimeRangeSelect}
                    getStartDateTime={getStartDateTime}
                    getEndDateTime={getEndDateTime}
                    dashboardId={selectedDashboardUUID}
                    panelId={panelId}
                    getTimeGapObject={getTimeGapObject}
                    getUpdatedChartConfig={getUpdatedChartConfig}
                />
                {lastTimeRangeSelect.query_time ? (
                    <CustomRangeDetails
                        lastTimeRangeSelect={lastTimeRangeSelect}
                    />
                ) : (
                    <></>
                )}
                <div className="widget-tool">
                    <img
                        onClick={onClickAggregate}
                        onKeyDown={onClickAggregate}
                        className={
                            lastTimeRangeSelect.showAggregated
                                ? "aggregate-icon d-block mr-3"
                                : "aggregate-icon d-none"
                        }
                        src={
                            lastTimeRangeSelect.showCandlestickChart
                                ? LineChartIcon
                                : CandlestickChartIcon
                        }
                        alt="aggregate-icon"
                    />
                    <RefreshRateDropdown
                        refreshRate={refreshRate}
                        setRefreshRate={setRefreshRate}
                        editableCustomRate={editableCustomRate}
                        setEditableCustomRate={setEditableCustomRate}
                        dashboardId={selectedDashboardUUID}
                        panelId={panelId}
                        lastTimeRangeSelect={lastTimeRangeSelect}
                    />
                </div>

                <ContentWrapper
                    key={JSON.stringify({
                        lastTimeRangeSelect,
                        refreshRate,
                    })}
                    isLoading={
                        isOutOfPaymentToken
                            ? false
                            : !chartState.hasFetchedConfig
                    }
                >
                    <ReactECharts
                        ref={chartRef}
                        className="chart-box"
                        theme={"dark"}
                        option={changeAxisYMaxMin(chartState.option)}
                        notMerge={true}
                        lazyUpdate={true}
                        onChartReady={(chartInstance) => {
                            chartInstance.getZr().on("click", (a: any) => {
                                if (a?.target?.__title === "Restore") {
                                    fetchNewData();
                                    setChartZoom({
                                        start: 0,
                                        end: 100,
                                    });
                                }
                            });
                        }}
                    />
                </ContentWrapper>
            </div>
        </ChartBox>
    );
};

export default BasicSensorChart;
