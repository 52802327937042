import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { faker } from "@faker-js/faker";
import "./style.css";

const GlobalModal = (props: any) => {
    const { isShow, mainMessage, subMessage, onClose } = props;
    return (
        <Modal
            show={isShow}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            className="global-modal"
        >
            <Modal.Header>
                <Modal.Title>IoTPortal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {mainMessage && (
                    <h2 className="main-message mb-2">{mainMessage}</h2>
                )}
                {subMessage.map((message: string, index: number) => (
                    <p
                        className="sub-message pl-3"
                        key={faker.datatype.uuid()}
                        dangerouslySetInnerHTML={{ __html: message }}
                    ></p>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GlobalModal;
