import {
    Dropdown,
    Form,
    FormControl,
    InputGroup,
    Button,
    FormLabel,
} from "react-bootstrap";

import {
    ONE_HOUR_IN_SECONDS,
    Patterns,
    defaultChartRefreshRate,
    refreshRateArr,
} from "constant";

import { RefreshRate } from "types/Charts";
import { convertPrometheusTimeToSeconds, isHttpSuccess } from "utils/functions";
import dashboardService from "service/dashboardService";
import { getAPIError, showErrorAlert } from "utils/alert";
import { useEffect, useState } from "react";

const RefreshRateDropdown = ({
    refreshRate,
    setRefreshRate,
    editableCustomRate,
    setEditableCustomRate,
    dashboardId,
    panelId,
    lastTimeRangeSelect,
}: any) => {
    const {
        time_range,
        INTVL: sensorReportRate,
        query_time,
    } = lastTimeRangeSelect;
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const disableCustomRate =
        query_time ||
        convertPrometheusTimeToSeconds(time_range) > ONE_HOUR_IN_SECONDS;

    useEffect(() => {
        if (refreshRate.seconds === "auto") {
            setEditableCustomRate(sensorReportRate);
        } else {
            setEditableCustomRate(refreshRate.seconds);
        }
    }, [refreshRate, setEditableCustomRate, sensorReportRate]);

    const getRefreshRateLabel = () => {
        const refreshRate_ = refreshRateArr.find((r) => {
            if (refreshRate.seconds === "auto" && r.seconds === "auto")
                return true;
            return Number(refreshRate.seconds) === r.seconds;
        });
        if (refreshRate_?.label === "Auto") return "Auto";
        return refreshRate_?.label ?? `${refreshRate.seconds} Secs`;
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCustomRateOnChange = (e: any) => {
        const value = e.target.value;
        setEditableCustomRate(value);
        setShowErrorMessage(!Patterns.chartRefreshRatePattern.test(value));
    };

    const handleUpdateRefreshRate = async (
        type: "fixed" | "custom",
        seconds?: number | "auto"
    ) => {
        const refreshRate_ = type === "fixed" ? seconds : editableCustomRate;

        const panelRes = await dashboardService.updatePanel(
            dashboardId,
            panelId,
            {
                refresh_rate: seconds === "auto" ? "auto" : refreshRate_ + "s",
            }
        );
        if (isHttpSuccess(panelRes?.status)) {
            toggleDropdown();
            setRefreshRate({
                ...refreshRate,
                seconds: seconds === "auto" ? "auto" : refreshRate_,
            });
        } else {
            showErrorAlert(
                getAPIError(
                    panelRes,
                    "Something went wrong when changing the refresh rate. Please try again."
                )
            );
        }
    };
    return (
        <div className="refresh-drop d-flex justify-content-end align-items-center">
            <div className="refresh-rate-label mr-1">Refresh Rate:</div>
                <Dropdown alignRight show={showDropdown} onToggle={setShowDropdown}>
                    <Dropdown.Toggle
                        onClick={toggleDropdown}
                        variant=""
                        className="refresh-rate"
                        aria-label="display-label"
                    >
                        {getRefreshRateLabel()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {refreshRateArr.map((refreshRate: RefreshRate) => {
                            const { seconds, label } = refreshRate;
                            const shouldDisableAuto =
                                label === "Auto" &&
                                (query_time ||
                                    convertPrometheusTimeToSeconds(time_range) >
                                        ONE_HOUR_IN_SECONDS);
                            return (
                                <Dropdown.Item
                                    key={seconds}
                                    onClick={() => {
                                        handleUpdateRefreshRate("fixed", seconds);
                                    }}
                                    value={seconds}
                                    disabled={shouldDisableAuto}
                                >
                                    {label}
                                </Dropdown.Item>
                            );
                        })}
                        <div
                            className={`custom-refresh ${
                                disableCustomRate ? `disabled` : ""
                            }`}
                        >
                            <Form
                                onSubmit={(e: any) => {
                                    e.preventDefault();
                                    handleUpdateRefreshRate("custom");
                                }}
                            >
                                <FormLabel>Custom Rate (Secs): </FormLabel>
                                <InputGroup>
                                    <FormControl
                                        type="number"
                                        min={5}
                                        max={3600}
                                        step={5}
                                        value={
                                            editableCustomRate ??
                                            defaultChartRefreshRate
                                        }
                                        name="customValue"
                                        aria-label="customValue"
                                        onChange={handleCustomRateOnChange}
                                        disabled={disableCustomRate}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                handleUpdateRefreshRate("custom");
                                            }}
                                            disabled={
                                                showErrorMessage ||
                                                disableCustomRate
                                            }
                                        >
                                            <span className="material-icons done">
                                                done
                                            </span>
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                                {showErrorMessage && (
                                    <p className="pt-1 mb-0 warning-message-red small-text">
                                        Please enter an integer between 5-3600
                                    </p>
                                )}
                            </Form>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
        </div>
    );
};

export default RefreshRateDropdown;
