import { Col, Form } from "react-bootstrap";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import levelHighIcon from "assets/svg/level-high.svg";
import levelLowIcon from "assets/svg/level-low.svg";
import pulsePositiveIcon from "assets/svg/pulse-positive.svg";
import pulseNegativeIcon from "assets/svg/pulse-negative.svg";
import loopPositiveIcon from "assets/svg/loop-positive.svg";
import loopNegativeIcon from "assets/svg/loop-negative.svg";
import pwmPositiveIcon from "assets/svg/pwm-positive.svg";
import pwmNegativeIcon from "assets/svg/pwm-negative.svg";
import { LEVEL_MODE, LOOP_MODE, PULSE_MODE, PWM_MODE } from "constant";

const POSITIVE = "POSITIVE";
const NEGATIVE = "NEGATIVE";

const PolarityOptions = () => {
    const {
        editedActuator,
        editedActuator: { polarity, mode },
        setEditedActuator,
    } = useActuatorConfigurationContext();

    const togglePolarity = (polarity: string) => {
        setEditedActuator({
            ...editedActuator,
            polarity: polarity === POSITIVE ? true : false,
        });
    };

    const renderLabel = ({
        mode,
        isPositive,
    }: {
        mode: string;
        isPositive: boolean;
    }) => {
        if (mode === LEVEL_MODE) return isPositive ? "High" : "Low";
        return isPositive ? "Positive" : "Negative";
    };

    const renderImage = ({
        mode,
        isPositive,
    }: {
        mode: string;
        isPositive: boolean;
    }) => {
        switch (mode) {
            case LEVEL_MODE:
                return isPositive ? levelHighIcon : levelLowIcon;
            case PULSE_MODE:
                return isPositive ? pulsePositiveIcon : pulseNegativeIcon;
            case LOOP_MODE:
                return isPositive ? loopPositiveIcon : loopNegativeIcon;
            case PWM_MODE:
                return isPositive ? pwmPositiveIcon : pwmNegativeIcon;
        }
    };

    return (
        <>
            <Form.Label className="mt-3">Polarity</Form.Label>

            <Form.Row className="mt-3">
                <Col md={4} className="polarity-box">
                    <Form.Check
                        className="polarity-selection"
                        name="polarity-positive"
                        id={"polarity-positive"}
                        onChange={() => {
                            togglePolarity(POSITIVE);
                        }}
                        checked={polarity}
                        custom
                        type="radio"
                        label={
                            <>
                                <img
                                    src={renderImage({
                                        mode: mode,
                                        isPositive: true,
                                    })}
                                    alt=""
                                />
                                <p className="mt-4">
                                    {renderLabel({
                                        mode: mode,
                                        isPositive: true,
                                    })}
                                </p>
                            </>
                        }
                    />
                </Col>
                <Col md={4} className="polarity-box">
                    <Form.Check
                        className="polarity-selection"
                        name="polarity-negative"
                        id={"polarity-negative"}
                        onChange={() => {
                            togglePolarity(NEGATIVE);
                        }}
                        checked={!polarity}
                        custom
                        type="radio"
                        label={
                            <>
                                <img
                                    src={renderImage({
                                        mode: mode,
                                        isPositive: false,
                                    })}
                                    alt=""
                                />
                                <p className="mt-4">
                                    {renderLabel({
                                        mode: mode,
                                        isPositive: false,
                                    })}
                                </p>
                            </>
                        }
                    />
                </Col>
            </Form.Row>
        </>
    );
};

export default PolarityOptions;
