import { Col, Dropdown } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useAppContext } from "context/appContext";
import { calcPercentDiff, getFirstSubscriptionInfo } from "utils/functions";
import moment from "moment";
import _ from "lodash";
import { useDebounce } from "hooks/common";
import { meteringService } from "service/meteringService";
import { faker } from "@faker-js/faker";

import "assets/css/subscription.css";
import { SUBSCRIPTION_DATE_FORMAT } from "constant";

const MonthlySpending = (props: any) => {
    const { storeData } = useAppContext();

    const [serviceCat, setServiceCat] = useState({
        sms: 0,
        dashboard: 0,
        notification: 0,
        email: 0,
        offload: 0,
    });

    const [monthDropdown, setMonthDropdown] = useState([]);
    const monthStart = moment().utcOffset(0, true).startOf("month");
    const monthEnd = moment().utcOffset(0, true).endOf("month");
    const [displayedMonthRange, setDisplayedMonthRange] = useState({
        monthStart: monthStart,
        monthEnd: monthEnd,
    });
    const [percentage, setPercentage] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const updateWindowWidth = useDebounce(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    });

    useEffect(() => {
        updateWindowWidth();

        return () => {
            window.removeEventListener("resize", () => {});
        };
    }, [width, updateWindowWidth]);

    // For token spend data
    useEffect(() => {
        const selected: any = {
            year: displayedMonthRange.monthStart.year(),
            month: displayedMonthRange.monthStart.month() + 1,
        };

        const getMonthSpend = async () => {
            const res: any = await meteringService.getMeteringInfo({
                year: selected.year,
                month: selected.month,
                in_token: true,
                previous_month: true,
            });

            if (res.status === 200) {
                const listOfOwnerOrg = Object.keys(res.data);

                let currMonthTotal = 0;
                let prevMonthTotal = 0;

                let email = 0;
                let offload = 0;
                let sms = 0;
                let dashboard = 0;
                let notification = 0;

                let prevEmail = 0;
                let prevOffload = 0;
                let prevSms = 0;
                let prevDashboard = 0;
                let prevNotification = 0;

                if (!_.isEmpty(res?.data)) {
                    listOfOwnerOrg.forEach((orgId: string) => {
                        const {
                            email: _email = 0,
                            // eslint-disable-next-line no-useless-computed-key
                            ["data offload"]: _offload = 0,
                            sms: _sms = 0,
                            dashboard: _dashboard = 0,
                            notification: _notification = 0,
                        } = res?.data?.[orgId]?.[selected?.month] ?? {};

                        email += _email;
                        offload += _offload;
                        sms += _sms;
                        dashboard += _dashboard;
                        notification += _notification;

                        if (res?.data?.[orgId]?.[-1]) {
                            const {
                                email: _email = 0,
                                // eslint-disable-next-line no-useless-computed-key
                                ["data offload"]: _offload = 0,
                                sms: _sms = 0,
                                dashboard: _dashboard = 0,
                                notification: _notification = 0,
                            } = res?.data?.[orgId]?.[-1] ?? {};

                            prevEmail += _email;
                            prevOffload += _offload;
                            prevSms += _sms;
                            prevDashboard += _dashboard;
                            prevNotification += _notification;
                        }
                    });
                }

                currMonthTotal =
                    email + sms + notification + dashboard + offload;

                prevMonthTotal =
                    prevEmail +
                    prevSms +
                    prevNotification +
                    prevDashboard +
                    prevOffload;

                setPercentage(calcPercentDiff(currMonthTotal, prevMonthTotal));

                setServiceCat({
                    sms: sms,
                    dashboard: dashboard,
                    notification: notification,
                    email: email,
                    offload: offload,
                });
            }
        };
        getMonthSpend();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedMonthRange]);

    // For dropdown menu
    useEffect(() => {
        const { subList } = storeData.subscription;
        const { startMonth: firstSubMonth, startYear: firstSubYear } =
            getFirstSubscriptionInfo(subList);

        const subDateStart = moment({
            year: firstSubYear,
            month: firstSubMonth - 1,
            day: 1,
        }).utcOffset(0, true);

        const subDateEnd = moment({
            year: firstSubYear,
            month: firstSubMonth - 1,
            day: 1,
        })
            .utcOffset(0, true)
            .endOf("month");

        const dropdownOptions: any = [
            {
                monthStart: subDateStart,
                monthEnd: subDateEnd,
            },
        ];

        let cloneMonthStart = subDateStart.clone();
        while (cloneMonthStart.isBefore(monthStart)) {
            cloneMonthStart = cloneMonthStart.clone().add(1, "M");
            dropdownOptions.push({
                monthStart: cloneMonthStart,
                monthEnd: cloneMonthStart.clone().endOf("month"),
            });
        }

        setMonthDropdown(dropdownOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const option: any = {
        backgroundColor: "#2f3a51",
        color: ["#EE9EC6", "#F6C39D", "#FFEFB5", "#50DAF4", "#84F5E8"],
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
            trigger: "item",
        },
        title: {
            text:
                Number(
                    serviceCat.dashboard +
                        serviceCat.email +
                        serviceCat.notification +
                        serviceCat.offload +
                        serviceCat.sms
                ) || 0,
            subtext: "Tokens Spent",
            textStyle: {
                fontSize: 19,
                color: "#ffffff",
            },
            subtextStyle: {
                fontSize: 15,
            },
            left: "center",
            top: "38%",
        },

        legend: {
            bottom: 0,
            left: "center",
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontSize: 14,
                fontWeight: 400,
            },
            padding: 0,
            itemGap: 10,
            itemWidth: 10,
            itemHeight: 10,
        },

        series: [
            {
                height: "90%",
                name: "Monthly Token Spend",
                type: "pie",
                radius: ["45%", "70%"],
                avoidLabelOverlap: false,
                top: 0,
                label: {
                    formatter: "{d}%",
                    fontSize: 14,
                    fontWeight: 800,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },

                data: [
                    {
                        value: serviceCat.email || 0,
                        name: `Email`,
                    },
                    {
                        value: serviceCat.sms || 0,
                        name: `SMS`,
                    },
                    {
                        value: serviceCat.notification || 0,
                        name: `Notification`,
                    },
                    {
                        value: serviceCat.dashboard || 0,
                        name: `Dashboard Data`,
                    },
                    {
                        value: serviceCat.offload || 0,
                        name: `Data Download`,
                    },
                ],
            },
        ],
    };

    const renderDropdown = () => {
        const dropdown = monthDropdown.map((monthRange: any) => {
            return (
                <Dropdown.Item
                    key={faker.datatype.uuid()}
                    onClick={() => {
                        setDisplayedMonthRange(monthRange);
                    }}
                >
                    {monthRange.monthStart.format(SUBSCRIPTION_DATE_FORMAT)} -{" "}
                    {monthRange.monthEnd.format(SUBSCRIPTION_DATE_FORMAT)}
                </Dropdown.Item>
            );
        });
        return dropdown;
    };

    if (width <= 1400) {
        option.series[0].height = "75%";
        option.title.top = "30%";
    }

    const PercentBox = ({ _percentage }: any) => {
        const percentDict = {
            increase: {
                color: "increase-btn-color",
                label: "increase",
                icon: "north",
                factor: 1,
            },
            decrease: {
                color: "decrease-btn-color",
                label: "decrease",
                icon: "south",
                factor: -1,
            },
        };
        const percent = parseFloat(_percentage);
        if (percent !== 0) {
            const { color, label, icon, factor } =
                percent > 0 ? percentDict.increase : percentDict.decrease;
            return (
                <div id="monthlyPercent">
                    <span className={"material-icons " + color}>{icon}</span>
                    <span className="percent">{percent * factor}%</span>
                    <p>
                        <span className={color}>
                            {percent * factor}% {label}{" "}
                        </span>{" "}
                        in token usage from last month
                    </p>
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            <Col lg={4} md={6} sm={6} xs={12} className="mb-3">
                <div className="monthly-spending form-box">
                    <div className="d-flex justify-content-between flex-wrap">
                        <h5>Monthly Spending</h5>
                        <Dropdown
                            alignRight
                            id="monthlySpendingDropdown"
                            className="monthly-spending-dropdown"
                        >
                            <Dropdown.Toggle variant="" className="date-filter">
                                {displayedMonthRange.monthStart.format(
                                    SUBSCRIPTION_DATE_FORMAT
                                )}{" "}
                                -{" "}
                                {displayedMonthRange.monthEnd.format(
                                    SUBSCRIPTION_DATE_FORMAT
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="monthly-spending-date-dropdown">
                                {renderDropdown()}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <PercentBox _percentage={percentage.toFixed(2)} />

                    <ReactECharts
                        className="chart-box"
                        option={option}
                        theme={"dark"}
                    />
                </div>
            </Col>
        </>
    );
};

export default MonthlySpending;
