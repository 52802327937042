/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import {
  AddressPatch2,
  AddressPost,
  CreateAddressData,
  DeleteAddressByIdData,
  GetAddressByIdData,
  GetAddressesData,
  GetBtClientTokenData,
  GetTransactionInfoByIdData,
  GetTransactionsData,
  UpdateAddressByIdData,
} from "../models";

export class Payment<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags payment
   * @name GetTransactions
   * @summary Get all transaction info
   * @request GET:/payment
   * @secure
   */
  getTransactions = (params: RequestParams = {}) =>
    this.http.request<GetTransactionsData, void>({
      path: `/payment`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment
   * @name GetTransactionInfoById
   * @summary Get transaction info by uuid
   * @request GET:/payment/{transaction_uuid}
   * @secure
   */
  getTransactionInfoById = (transactionUuid: string, params: RequestParams = {}) =>
    this.http.request<GetTransactionInfoByIdData, void>({
      path: `/payment/${transactionUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment
   * @name GetBtClientToken
   * @summary Request for BT client token
   * @request POST:/payment/token
   * @secure
   */
  getBtClientToken = (params: RequestParams = {}) =>
    this.http.request<GetBtClientTokenData, void>({
      path: `/payment/token`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags address
   * @name GetAddresses
   * @summary Get all address info
   * @request GET:/payment/address
   * @secure
   */
  getAddresses = (params: RequestParams = {}) =>
    this.http.request<GetAddressesData, void>({
      path: `/payment/address`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags address
   * @name CreateAddress
   * @summary Create a new address
   * @request POST:/payment/address
   * @secure
   */
  createAddress = (data: AddressPost, params: RequestParams = {}) =>
    this.http.request<CreateAddressData, void>({
      path: `/payment/address`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags address
   * @name DeleteAddressById
   * @summary Delete address info by uuid
   * @request DELETE:/payment/address/{address_uuid}
   * @secure
   */
  deleteAddressById = (addressUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteAddressByIdData, void>({
      path: `/payment/address/${addressUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags address
   * @name GetAddressById
   * @summary Get address info by uuid
   * @request GET:/payment/address/{address_uuid}
   * @secure
   */
  getAddressById = (addressUuid: string, params: RequestParams = {}) =>
    this.http.request<GetAddressByIdData, void>({
      path: `/payment/address/${addressUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags address
   * @name UpdateAddressById
   * @summary Update address info by uuid
   * @request PATCH:/payment/address/{address_uuid}
   * @secure
   */
  updateAddressById = (addressUuid: string, data: AddressPatch2, params: RequestParams = {}) =>
    this.http.request<UpdateAddressByIdData, void>({
      path: `/payment/address/${addressUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
