import { HighlightList, ServerResReplaceDict } from "constant";

export const replaceServerResStr = (text: any) => {
    if (text && typeof text === "string") {
        let ret = text;
        const _highlightDict = [
            ...Object.keys(ServerResReplaceDict).map((k: string) => ({
                pattern: new RegExp(`${k}`, 'gi'),
                replaceText: `<b class="${k}">${ServerResReplaceDict[k]}</b>`,
            })),
            ...HighlightList,
        ];
        ret = _highlightDict.reduce(
            (html, { pattern, key, replaceText }: any) => {
                return html.replace(
                    pattern,
                    (t) => {
                        return replaceText || `<b class="${key}">${t}</b>`
                    }
                );
            },
            text
        );
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: ret,
                }}
            />
        );
    }
    return text;
};
