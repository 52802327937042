import { MemberDto } from "./Organisation";
import { RecipientMode } from "./common";

export type MessageType = {
    subject: string;
    text: string;
};

export enum PortStatus {
    On,
    FaultyHardware,
    FaultyIndividual,
    FaultyAll,
    NoDevice,
}

export type LDSBus = {
    id: number;
    power: boolean;
    status: PortStatus;
};

export type GatewayDeviceList = {
    allRegistry: any[];
    deviceList: any[];
};

export type SensorType = {
    id: string;
    name: string;
};



export type GatewayLDSUDetailsProps = {
    nameEditable: string;
    handleNameChange: (e: any) => void;
    isNameEditing: boolean;
    handleClickEdit: () => void;
    handleClickUpdate: () => Promise<void>;
    isRefreshing: boolean;
    refreshThisGateway: () => Promise<void>;
    timestampDetails: {
        heartbeat: number;
        info: boolean;
    };
    registryDetails: any;
    hasOnboard: React.SetStateAction<boolean>;
    gatewayId: string;
};

export type RecipientConfig = {
    recipients: MemberDto[];
    isEnabled: boolean;
    recipientMode: RecipientMode;
};

export type AlertConfig = Record<RecipientMode, RecipientConfig>;
