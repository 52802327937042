import { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Tab,
    Nav,
    Breadcrumb,
} from "react-bootstrap";
import { useLocation, useParams, useHistory } from "react-router-dom";
import LdsuList from "components/gateways/LdsuList";
import SensorList from "components/gateways/SensorList";
import ActuatorList from "components/gateways/actuators/ActuatorList";
import dltWhiteIcon from "assets/svg/delete-white.svg";
import searchIcon from "assets/svg/search.svg";
import {
    getGatewayAlertNotification,
    getRegistryConfig,
    getGwAndLDSUStatus,
    scanGateway,
    resetGateway,
    getAllLastBusReading,
    deleteLDSBus,
    getOneRegistry,
} from "service/gatewayService";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import {
    LDSUTabPanel,
    HttpStatus,
    SCAN_REQUEST_MESSAGE,
    PORT_POWERED_ON_SCAN_MESSAGE,
    PORT_POWERED_OFF_SCAN_MESSAGE,
    PORT_FAULTY_SCAN_MESSAGE,
} from "constant";
import { LDSBus as LDSBusState, PortStatus } from "types/Gateways";
import { LoadingModal, useModal } from "components/modals/ModalTemplate";
import { get } from "lodash";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { canAccess } from "utils/authorize-action";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { PowerCycleCheckBox } from "components/gateways/Gateway";
import {
    getLDSBusInfo,
    getPowerStatusText,
    isFaulty,
} from "utils/gatewayFunctions";
import { isHttpSuccess } from "utils/functions";
const { FORBIDDEN } = HttpStatus;

type LDSBusLocationState = {
    panel: LDSUTabPanel;
    gatewayName?: string;
    refresh?: boolean;
};

const LDSBus = () => {
    const params: any = useParams();
    const location = useLocation<LDSBusLocationState>();
    const history = useHistory();
    const { busNumber } = params;
    const state: LDSBusLocationState = get(location, "state", {
        panel: LDSUTabPanel.LDSU,
    });
    const activePanel = get(state, "panel", LDSUTabPanel.LDSU);
    const [ldsuLength, setLdsuLength] = useState("");
    const [sensorLength, setSensorLength] = useState("");
    const [alertUUIDList, setAlertUUIDList] = useState<Array<any>>([]);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [actionStatus, setActionStatus] = useState("idle");
    const [isLoading, setIsLoading] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("");
    const [ldsBusState, setLdsBusState] = useState<LDSBusState>({
        id: busNumber,
        power: false,
        status: PortStatus.On,
    });

    const [ldsuList, setLdsuList] = useState<Array<any>>([]);
    const [sensorList, setSensorList] = useState<Array<any>>([]);
    const [actuatorList, setActuatorList] = useState<Array<any>>([]);

    // Modal State
    const { modal, openModal, modalData, setModalData, setModalShow } =
        useModal();

    useEffect(() => {
        setModalData({
            powerCycle: false,
        });
    }, [setModalData]);

    useEffect(() => {
        const fetch = async () => {
            const [
                registryInfoRes,
                registryConfigRes,
                registryGwAndLdsuStatus,
                registryBusLastReading,
                registryAlertList,
            ]: any = await Promise.all([
                getOneRegistry(params.gatewayId),
                getRegistryConfig(params.gatewayId),
                getGwAndLDSUStatus(params.gatewayId),
                getAllLastBusReading(params.gatewayId, params.busNumber),
                getGatewayAlertNotification(params.gatewayId),
            ]);

            if (
                [
                    registryInfoRes.status,
                    registryConfigRes.status,
                    registryGwAndLdsuStatus.status,
                    registryBusLastReading.status,
                    registryAlertList.status,
                ].includes(FORBIDDEN)
            ) {
                setIsForbiddenResource(true);
                setIsPageLoading(false);
                return;
            }

            if (
                isHttpSuccess(registryConfigRes.status) &&
                isHttpSuccess(registryGwAndLdsuStatus.status) &&
                isHttpSuccess(registryInfoRes.status)
            ) {
                transformLdsuList(
                    registryInfoRes.data.info.devices,
                    registryConfigRes.data,
                    registryGwAndLdsuStatus.data.devices,
                    registryBusLastReading.data.devices,
                    registryAlertList.data
                );

                processLdsBusState(registryInfoRes.data);
            }
            setIsPageLoading(false);
        };

        if (!canAccess("gateway:read")) {
            setIsPageLoading(false);
            setIsForbiddenResource(true);
        } else {
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        params.gatewayId,
        busNumber,
        window.authorizationConfig,
        actionStatus,
        state.refresh,
    ]);

    /* eslint-disable */
    const transformAlertList = (lst1: any[], lst2: any[]) => {
        let filteredLst: any[] = [];
        lst1.forEach((i: any) => {
            lst2.some((j: any, index) => {
                if (
                    i.device_id === j.ldsu_uuid &&
                    String(i.said) === String(j.SAID)
                ) {
                    let body = {
                        index: index,
                        uuid: i.uuid,
                        source: j.ldsu_uuid,
                        said: i.said,
                        gatewayUUID: i.gateway_id,
                        status: i.enabled,
                    };
                    filteredLst.push(body);
                }
            });
        });
        setAlertUUIDList(filteredLst);
    };

    const transformLdsuList = (
        registryInfoList: any,
        configList: any,
        gwLDSUStatusList: any,
        busLastReadingList: any,
        alertList: any
    ) => {
        const { ldsus, sensors, actuators } = getLDSBusInfo(
            busNumber,
            registryInfoList,
            configList,
            gwLDSUStatusList
        );

        setActuatorList(actuators);

        // Map with Alert list
        transformAlertList(alertList, sensors);
        setSensorList(sensors);
        setSensorLength(sensors.length);
        setLdsuList(ldsus);
    };
    /* eslint-enable */

    const processLdsBusState = (data: any) => {
        const power = data.info.bus_power[busNumber - 1];
        const status = data.port?.PORT[busNumber - 1];

        setLdsBusState({
            id: busNumber,
            power,
            status,
        });
    };

    const closeModal = () => {
        setModalShow(false);
        setModalData({});
    };

    const openScanModal = () => {
        const status = getPowerStatusText(
            ldsBusState.power,
            ldsBusState.status
        );

        let modalTitle_ = "";
        let modalMessage_ = "";

        if (["On", "Off"].includes(status)) {
            modalTitle_ = `Scan in Powered ${status} Port`;
            modalMessage_ =
                status === "On"
                    ? PORT_POWERED_ON_SCAN_MESSAGE
                    : PORT_POWERED_OFF_SCAN_MESSAGE;
        } else {
            modalTitle_ = `Scan in Faulty Port`;
            modalMessage_ = PORT_FAULTY_SCAN_MESSAGE;
        }

        openModal({
            key: "open_scan_modal",
            modalType: "custom",
            modalIcon: "help_outline",
            iconVariant: "primary",
            modalTitle: modalTitle_,
            modalContent: (openedModalData: any) => {
                return (
                    <>
                        <div className="mb-3"> {modalMessage_}</div>
                        {!isFaulty(ldsBusState.status) && (
                            <PowerCycleCheckBox
                                custom
                                id="power-cycle"
                                className="mb-3"
                                checked={openedModalData.powerCycle}
                                onChange={(e) => {
                                    setModalData({
                                        ...modalData,
                                        powerCycle: !openedModalData.powerCycle,
                                    });
                                }}
                                label={
                                    <span className="ml-1">
                                        Scan with power cycle
                                    </span>
                                }
                            />
                        )}
                    </>
                );
            },
            modalButton: (openedModalData: any) => {
                return (
                    <>
                        <Button variant="danger" onClick={closeModal}>
                            CANCEL
                        </Button>
                        <Button
                            disabled={
                                status === "Off" && !openedModalData.powerCycle
                            }
                            onClick={() => {
                                scanThisLDSBus(openedModalData);
                            }}
                        >
                            SCAN
                        </Button>
                    </>
                );
            },
        });
    };

    const openWarningConfirmationModal = ({
        actionType,
    }: {
        actionType: "scan" | "restart";
    }) => {
        openModal({
            key: "handle_warning_confirmation_modal",
            modalType: "custom",
            modalIcon: "help_outline",
            iconVariant: "primary",
            modalTitle: "Disable Related Events",
            modalContent: `A ${actionType} request may cause events to be falsely triggered. This message may be ignored if you have already disabled events involving this port.`,
            modalButton: (
                <>
                    <Button variant="danger" onClick={closeModal}>
                        CANCEL
                    </Button>
                    <Button
                        onClick={() => {
                            if (actionType === "scan") {
                                openScanModal();
                            }
                        }}
                    >
                        CONTINUE
                    </Button>
                </>
            ),
        });
    };

    const scanThisLDSBus = async (openedModalData?: any) => {
        setIsLoading(true);
        setLoadingTitle("Scanning...");
        const res = await scanGateway(
            params.gatewayId,
            params.busNumber,
            isFaulty(ldsBusState.status) ? true : !!openedModalData.powerCycle
        );
        if (isHttpSuccess(res.status)) {
            // Call config API
            const registryConfigRes = await getRegistryConfig(params.gatewayId);

            if (isHttpSuccess(registryConfigRes.status)) {
                // Check if the config has any details.
                if (registryConfigRes.data.length === 0) {
                    // call reset API
                    const registryResetRes = await resetGateway(
                        params.gatewayId
                    );
                    if (isHttpSuccess(registryResetRes.status)) {
                        showSuccessAlert({
                            message: SCAN_REQUEST_MESSAGE,
                        });
                        setActionStatus("complete");
                    } else {
                        showErrorAlert({
                            message: get(
                                registryResetRes,
                                "data.description",
                                `Unable to scan. Please try again later.`
                            ),
                        });
                    }
                    setModalShow(true);
                } else {
                    showSuccessAlert({
                        message: SCAN_REQUEST_MESSAGE,
                    });
                    setActionStatus("complete");
                }
            }
        } else {
            showErrorAlert({
                message: get(
                    res,
                    "data.description",
                    `Unable to scan LDSUs. Please try again later.`
                ),
            });
        }

        setIsLoading(false);

        closeModal();
    };

    const openDeleteModal = () => {
        openModal({
            key: "open_delete_modal",
            modalType: "custom",
            modalIcon: "delete",
            iconVariant: "danger",
            modalTitle: "Delete LDSUs?",
            modalContent: `Are you sure you want to delete all the LDSUs from LDSBus ${busNumber}? This will delete associated Sensors and Actuators.`,
            modalButton: () => {
                return (
                    <>
                        <Button variant="secondary" onClick={closeModal}>
                            CANCEL
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                deleteThisBus();
                            }}
                        >
                            DELETE
                        </Button>
                    </>
                );
            },
        });
    };

    const deleteThisBus = async () => {
        setModalShow(false);
        const res: any = await deleteLDSBus(params.gatewayId, params.busNumber);

        if (isHttpSuccess(res.status)) {
            showSuccessAlert({
                message: `${ldsuLength} LDSU(s) are deleted successfully.`,
            });
            history.push(`/gateway-details/${params.gatewayId}`);
        } else {
            showErrorAlert({
                message: get(
                    res,
                    "data.description",
                    `Unable to delete LDSUs. Please try again later.`
                ),
            });
        }

        closeModal();
    };

    return (
        <ContentWrapper
            isForbiddenResource={isForbiddenResource}
            title={`LDS Bus ${params.busNumber}`}
            isLoading={isPageLoading}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={6} className="LDSBus-header">
                            <Row>
                                <Col>
                                    <h5 className="page-title">
                                        LDSBus {params.busNumber}
                                    </h5>
                                </Col>
                                <Col sm="12">
                                    <Breadcrumb className="w-100">
                                        <Breadcrumb.Item
                                            href={`/gateway-details/${params.gatewayId}`}
                                        >
                                            Gateway
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            LDS Bus {params.busNumber}
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6} className="ldsbus-buttons-container">
                            <div className="ldsbus-buttons">
                                <HoverAuthorizeTooltip permission="gateway:update">
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            openWarningConfirmationModal({
                                                actionType: "scan",
                                            })
                                        }
                                    >
                                        <span>
                                            <img
                                                className="mr-2 ml-1"
                                                src={searchIcon}
                                                alt="search"
                                            />
                                        </span>
                                        {""}
                                        Scan
                                    </Button>
                                </HoverAuthorizeTooltip>
                            </div>
                            <div className="ldsbus-buttons">
                                <HoverAuthorizeTooltip permission="gateway:delete">
                                    <Button
                                        variant="danger"
                                        onClick={openDeleteModal}
                                    >
                                        <span>
                                            <img
                                                className="mr-2 ml-2"
                                                alt="delete"
                                                src={dltWhiteIcon}
                                            />
                                        </span>
                                        {""}
                                        Delete
                                    </Button>
                                </HoverAuthorizeTooltip>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <div className="page-tabs">
                                <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey={activePanel}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Nav>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={
                                                            LDSUTabPanel.LDSU
                                                        }
                                                    >
                                                        <h6>
                                                            LDSUs{" "}
                                                            <span className="count">
                                                                {ldsuLength}
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={
                                                            LDSUTabPanel.Sensor
                                                        }
                                                    >
                                                        <h6>
                                                            Sensors{" "}
                                                            <span className="count">
                                                                {sensorLength}
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey={
                                                            LDSUTabPanel.Actuator
                                                        }
                                                    >
                                                        <h6>
                                                            Actuators{" "}
                                                            <span className="count">
                                                                {
                                                                    actuatorList.length
                                                                }
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="ldsus">
                                                    <LdsuList
                                                        ldsus={ldsuList}
                                                        setLdsuLength={
                                                            setLdsuLength
                                                        }
                                                        gatewayName={
                                                            state.gatewayName
                                                        }
                                                        portNumber={
                                                            params.busNumber
                                                        }
                                                        UUID={params.gatewayId}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="sensors">
                                                    <SensorList
                                                        sensors={sensorList}
                                                        setSensorLength={
                                                            setSensorLength
                                                        }
                                                        gatewayName={
                                                            state.gatewayName
                                                        }
                                                        portNumber={
                                                            params.busNumber
                                                        }
                                                        alertUUIDList={
                                                            alertUUIDList
                                                        }
                                                        setAlertUUIDList={
                                                            setAlertUUIDList
                                                        }
                                                        UUID={params.gatewayId}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="actuators">
                                                    <ActuatorList
                                                        gatewayName={
                                                            state.gatewayName
                                                        }
                                                        actuators={actuatorList}
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {modal}
            <LoadingModal showModal={isLoading} title={loadingTitle} />
        </ContentWrapper>
    );
};

export default LDSBus;
