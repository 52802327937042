/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "../http-client";
import { ErrorResponse, GetMeteringInfoData, GetMeteringInfoParamsService, GetStorageInfoData } from "../models";

export class Metering<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags meter
   * @name GetMeteringInfo
   * @summary Get metering info
   * @request GET:/metering
   * @secure
   */
  getMeteringInfo = (
    query: {
      /** Year */
      year: string;
      /** Month to filter */
      month?: string;
      /** Organization ID to filter */
      organization_id?: string;
      /** Service to filter */
      service?: GetMeteringInfoParamsService;
      /** Do not return service usage with 100% discount (false by default) */
      ignore_discount?: boolean;
      /** Return also the previous month data if 'month' is provided (false by default) */
      previous_month?: boolean;
      /** Return service usage in payment token (false by default) */
      in_token?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetMeteringInfoData, ErrorResponse>({
      path: `/metering`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags meter
   * @name GetStorageInfo
   * @summary Get storage info
   * @request GET:/metering/storage
   * @secure
   */
  getStorageInfo = (
    query?: {
      /** Organization ID to filter */
      organization_id?: string;
      /** Gateway ID to be included in result */
      gateway_id?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetStorageInfoData, ErrorResponse>({
      path: `/metering/storage`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
}
