import { Actuator } from "components/gateways/actuators/ActuatorConfiguration";
import {
    ANALOG_MODE,
    LEVEL_MODE,
    LOOP_MODE,
    PULSE_MODE,
    PWM_MODE,
} from "constant";
import { modeConfigs } from "components/gateways/actuators/ActuatorConfiguration";

export const getActuatorConfigTemplate = (actuator: Actuator) => {
    const {
        name,
        auto,
        mode,
        polarity,
        deactivate,
        timing,
        cycle_count,
        activate_value,
        deactivate_value,
    } = actuator;

    const defaultConfig = {
        name,
        auto,
        mode,
    };

    const modeConfigs: modeConfigs = {
        [LEVEL_MODE]: {
            polarity,
        },
        [PULSE_MODE]: {
            deactivate,
            timing,
            polarity,
        },
        [LOOP_MODE]: {
            deactivate,
            cycle_count: Number(cycle_count),
            timing,
            polarity,
        },
        [PWM_MODE]: {
            deactivate,
            cycle_count: Number(cycle_count),
            timing,
            polarity,
        },
        [ANALOG_MODE]: {
            activate_value: Number(activate_value),
            deactivate_value: Number(deactivate_value),
        },
    };

    const body: Actuator = {
        ...defaultConfig,
        ...modeConfigs[mode],
    };

    return body;
};
