import { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import dltWhiteIcon from "assets/svg/delete-white.svg";
import EventVariableService from "service/eventVariableService";
import {
    HttpStatus,
    TypeOption,
    Direction,
    Patterns,
    MAX_TERMINAL_VALUE,
    MIN_TERMINAL_VALUE,
    OVERFLOW_DEFAULT,
    STEP_DEFAULT,
    EVENT_VARIABLE_TIMER_FORMAT,
    FETCH_EVENT_VARIABLES_FAIL_MESSAGE,
    FETCH_EVENTS_FAIL_MESSAGE,
    INVALID_OPERATION_MESSAGE,
} from "constant";
import { convertSecondsToTimeString, isHttpSuccess } from "utils/functions";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import DefaultModal from "components/modals/ModalTemplate";
import eventService from "service/eventService";
import {
    getInitialValue,
    validateEventVariableValue,
    validateStepSizeValue,
    validateTerminalValue,
} from "utils/eventFunctions";
import EventVariableForm from "components/events/EventVariableForm";
import { VariablePatch, Variable, Event } from "generated/models/Event";
import { get, uniqBy } from "lodash";
import { canAccess } from "utils/authorize-action";
import ConditionCol from "components/events/ConditionCol";
import ActionCol from "components/events/ActionCol";

type IParams = {
    eventVariableId: string;
};

const EventVariableDetail = () => {
    const { eventVariableId }: IParams = useParams();
    const history = useHistory();

    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [eventVariable, setEventVariable] = useState<Variable>(
        {} as Variable
    );
    const [eventVariableName, setEventVariableName] = useState<string>("");
    const [eventVariableType, setEventVariableType] = useState<TypeOption>(
        TypeOption.Boolean
    );
    const [eventVariableValue, setEventVariableValue] = useState({
        [TypeOption.Boolean]: "true",
        [TypeOption.Integer]: "0",
        [TypeOption.Float]: "0.00",
        [TypeOption.Timer]: "00:00:00",
        [TypeOption.Counter]: "0",
    });
    const [direction, setDirection] = useState(Direction.Up);
    const [overflow, setOverflow] = useState(true);
    const [terminal, setTerminal] = useState(MAX_TERMINAL_VALUE);
    const [step, setStep] = useState("1");
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [associatedEvents, setAssociatedEvents] = useState<Event[]>([]);
    const [formChanged, setFormChanged] = useState(false);
    const [eventVariableValueError, setEventVariableValueError] = useState("");
    const [terminalValueError, setTerminalValueError] = useState("");
    const [stepSizeValueError, setStepSizeValueError] = useState("");
    const [isUpdating, setIsUpdating] = useState(false);

    const getAssociatedEvents = (variable: Variable) => {
        const in_actions = get(variable, "in_actions", []);
        const in_conditions = get(variable, "in_conditions", []);
        return uniqBy([...in_actions, ...in_conditions], "uuid");
    };

    const formatEventVariableValue = (type: string, value: string) => {
        switch (type) {
            case TypeOption.Float:
                return Number(value).toFixed(2);
            case TypeOption.Timer:
                return convertSecondsToTimeString(
                    Number(value),
                    EVENT_VARIABLE_TIMER_FORMAT
                );
            case TypeOption.Counter:
            case TypeOption.Integer:
                return Number(value);
            default:
                return value;
        }
    };

    const fetchData = async () => {
        setIsPageLoading(true);

        if (!canAccess("event:read")) {
            setIsPageLoading(false);
            setIsForbiddenResource(true);
            return;
        }

        const [respEventVariable, respEvent] = await Promise.all([
            EventVariableService.getEventVariableById(eventVariableId),
            eventService.readEvent(),
        ]);

        if (
            !isHttpSuccess(respEventVariable.status) ||
            !isHttpSuccess(respEvent.status)
        ) {
            if (
                respEvent.status === HttpStatus.FORBIDDEN ||
                respEventVariable.status === HttpStatus.FORBIDDEN
            ) {
                setIsForbiddenResource(true);
            } else {
                if (!isHttpSuccess(respEventVariable.status)) {
                    showErrorAlert(
                        getAPIError(
                            respEventVariable,
                            FETCH_EVENT_VARIABLES_FAIL_MESSAGE
                        )
                    );
                }

                if (!isHttpSuccess(respEvent.status)) {
                    showErrorAlert(
                        getAPIError(respEvent, FETCH_EVENTS_FAIL_MESSAGE)
                    );
                }
            }
        } else {
            setAssociatedEvents(getAssociatedEvents(respEventVariable.data));
            const { name, type_, initial, attributes } = respEventVariable.data;
            setEventVariable(respEventVariable.data);
            setEventVariableName(name);
            setEventVariableType(type_);
            setEventVariableValue({
                ...eventVariableValue,
                [type_]: formatEventVariableValue(type_, initial),
            });
            if (type_ === TypeOption.Counter) {
                setDirection(Number(get(attributes, "direction", true)));
                setTerminal(get(attributes, "terminal", MIN_TERMINAL_VALUE));
                setStep(get(attributes, "step", STEP_DEFAULT));
                setOverflow(get(attributes, "overflow", OVERFLOW_DEFAULT));
            }
        }

        setIsPageLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderAssociatedEvents = () => {
        if (!associatedEvents.length) {
            return (
                <div className="w-100 mt-4 text-center justify-content-center">
                    No Associated Events to display
                </div>
            );
        }
        return associatedEvents.map((associatedEvent) => {
            return (
                <div className="table-row" key={associatedEvent.uuid}>
                    <Row className="no-checkbox">
                        <Col md={{ span: 4, offset: 0 }} className="pl-4">
                            <Link
                                to={{
                                    pathname: `/event-details/${associatedEvent.uuid}`,
                                }}
                            >
                                <span>{associatedEvent.name}</span>
                            </Link>
                        </Col>

                        <Col md={{ span: 3, offset: 0 }}>
                            <ConditionCol
                                conditions={get(
                                    associatedEvent,
                                    "conditions",
                                    []
                                )}
                            />
                        </Col>

                        <Col md={{ span: 3, offset: 0 }}>
                            <ActionCol
                                actions={get(associatedEvent, "actions", [])}
                            />
                        </Col>

                        <Col md={{ span: 1, offset: 0 }}>
                            <HoverAuthorizeTooltip permission="event:update">
                                <span className="d-inline-block">
                                    <Form.Check
                                        disabled={true}
                                        type="switch"
                                        id={`switch_${associatedEvent.uuid}`}
                                        label=""
                                        checked={associatedEvent.activate}
                                    />
                                </span>
                            </HoverAuthorizeTooltip>
                        </Col>

                        <Col md={{ span: 1, offset: 0 }}>
                            <Link
                                to={{
                                    pathname: `/event-details/${associatedEvent.uuid}`,
                                }}
                            >
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </Link>
                        </Col>
                    </Row>
                </div>
            );
        });
    };

    const updateEventVariable = async (e: any) => {
        e.preventDefault();
        setIsUpdating(true);
        const initialValue = getInitialValue(
            eventVariableType,
            eventVariableValue[eventVariableType]
        );

        const payload: VariablePatch = {
            name: eventVariableName.trim(),
            type_: eventVariableType,
            initial: initialValue,
        };

        if (eventVariableType === TypeOption.Counter) {
            payload.attributes = {
                terminal: +terminal,
                step: +step,
                direction: Boolean(direction),
                overflow,
            };
        }

        const updatedResp = await EventVariableService.updateEventVariable(
            eventVariableId,
            payload
        );

        if (isHttpSuccess(updatedResp.status)) {
            await fetchData();
            showSuccessAlert({
                message: "Event variable has been updated successfully.",
            });
            setFormChanged(false);
        } else {
            showErrorAlert(getAPIError(updatedResp, INVALID_OPERATION_MESSAGE));
        }
        setIsUpdating(false);
    };

    const handleDeleteEventVariable = async () => {
        const deleteResp = await EventVariableService.deleteEventVariableById(
            eventVariableId
        );
        if (isHttpSuccess(deleteResp.status)) {
            showSuccessAlert({
                message: "Event variable has been deleted successfully.",
            });
            history.push("/events?activeKey=event-variables");
        } else {
            showErrorAlert(
                getAPIError(
                    deleteResp,
                    `Unable to delete event variable ${eventVariableName}`
                )
            );
        }
        setIsShowDeleteModal(false);
    };

    const handleEventVariableName = (value: string) => {
        setEventVariableName(value);
        if (eventVariable.name !== value) {
            setFormChanged(true);
        }
    };

    const handleEventVariableValue = (value: string) => {
        setEventVariableValue({
            ...eventVariableValue,
            [eventVariableType]: value,
        });

        const errorMsg = validateEventVariableValue(eventVariableType, value);
        setEventVariableValueError(errorMsg);
        if (eventVariableType === TypeOption.Counter) {
            const terminalErrorMsg = validateTerminalValue(
                terminal,
                value,
                direction
            );
            setTerminalValueError(terminalErrorMsg);

            setStepSizeValueError(validateStepSizeValue(step, value, terminal));
        }

        if (eventVariable.initial !== value) {
            setFormChanged(true);
        }
    };

    const handleDirection = (value: Direction) => {
        const directionUpdated = Number(value);
        const initialValue =
            directionUpdated === Direction.Up
                ? String(Number(MIN_TERMINAL_VALUE) - 1)
                : MAX_TERMINAL_VALUE;
        const terminalValue =
            directionUpdated === Direction.Up
                ? MAX_TERMINAL_VALUE
                : MIN_TERMINAL_VALUE;
        setEventVariableValue({
            ...eventVariableValue,
            [eventVariableType]: initialValue,
        });
        setTerminal(terminalValue);
        setDirection(directionUpdated);
        setOverflow(OVERFLOW_DEFAULT);
        setStep(STEP_DEFAULT);
        setEventVariableValueError("");
        setTerminalValueError("");
        setStepSizeValueError("");
        if (
            get(eventVariable.attributes, "direction", undefined) !==
            Boolean(directionUpdated)
        ) {
            setFormChanged(true);
        }
    };

    const handleTerminalValue = (value: string) => {
        setTerminal(value);
        const errorMsg = validateTerminalValue(
            value,
            eventVariableValue[eventVariableType],
            direction
        );
        setTerminalValueError(errorMsg);

        setStepSizeValueError(
            validateStepSizeValue(
                step,
                eventVariableValue[eventVariableType],
                value
            )
        );

        if (get(eventVariable.attributes, "terminal", undefined) !== +value) {
            setFormChanged(true);
        }
    };

    const handleStepValue = (value: string) => {
        setStep(value);
        setStepSizeValueError(
            validateStepSizeValue(
                value,
                eventVariableValue[eventVariableType],
                terminal
            )
        );
        if (get(eventVariable.attributes, "step", undefined) !== +value) {
            setFormChanged(true);
        }
    };

    const handleOverflowValue = (value: boolean) => {
        setOverflow(value);
        if (get(eventVariable.attributes, "overflow", undefined) !== value) {
            setFormChanged(true);
        }
    };

    return (
        <ContentWrapper
            isPageLoading={isPageLoading}
            isForbiddenResource={isForbiddenResource}
        >
            <div className="page-content">
                <Container fluid>
                    <div>
                        <Row>
                            <Col sm="6">
                                <h5 className="page-title">
                                    {eventVariable.name}
                                </h5>
                            </Col>

                            <Col xs="6" className="text-right">
                                <HoverAuthorizeTooltip permission="event:delete">
                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            setIsShowDeleteModal(true);
                                        }}
                                    >
                                        <span>
                                            <img
                                                className="mr-2 ml-2"
                                                src={dltWhiteIcon}
                                                alt="dlt"
                                            />
                                        </span>
                                        {""}
                                        Delete
                                    </Button>
                                </HoverAuthorizeTooltip>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12">
                                <Breadcrumb className="w-100">
                                    <Breadcrumb.Item active>
                                        <Link to="/events">
                                            Event Management
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        <Link to="/events?activeKey=event-variables">
                                            Event Variables
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Event Variable
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col sm="12">
                                <Form>
                                    <div className="event-variable-form-header mt-2">
                                        <h5>Event Variable Details</h5>
                                    </div>
                                    <div className="form-box event-variable-form-content">
                                        <EventVariableForm
                                            eventVariableName={
                                                eventVariableName
                                            }
                                            onChangeEventVariableName={
                                                handleEventVariableName
                                            }
                                            eventVariableType={
                                                eventVariableType
                                            }
                                            initialValue={
                                                eventVariableValue[
                                                    eventVariableType
                                                ]
                                            }
                                            onChangeInitialValue={
                                                handleEventVariableValue
                                            }
                                            eventVariableValueError={
                                                eventVariableValueError
                                            }
                                            direction={direction}
                                            onChangeDirection={handleDirection}
                                            terminalValue={terminal}
                                            onChangeTerminalValue={
                                                handleTerminalValue
                                            }
                                            terminalValueError={
                                                terminalValueError
                                            }
                                            stepValue={step}
                                            onChangeStepValue={handleStepValue}
                                            overflowValue={overflow}
                                            setOverflowValue={
                                                handleOverflowValue
                                            }
                                            isUpdateForm={true}
                                            stepSizeValueError={
                                                stepSizeValueError
                                            }
                                        />
                                    </div>
                                    <div className="event-variable-form-footer">
                                        <Button
                                            variant="primary"
                                            className="pl-4 pr-4"
                                            disabled={
                                                !!eventVariableValueError ||
                                                !!terminalValueError ||
                                                !!stepSizeValueError ||
                                                !Patterns.eventVariableNamePattern.test(
                                                    eventVariableName
                                                ) ||
                                                !formChanged ||
                                                isUpdating
                                            }
                                            onClick={updateEventVariable}
                                        >
                                            SAVE
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-5 associated-events">
                        <Row>
                            <Col sm="6">
                                <h5 className="page-title">
                                    Associated Events
                                </h5>
                            </Col>
                        </Row>

                        <Row className="cstm-table mt-1">
                            <div className="table-head">
                                <Row className="no-checkbox">
                                    <Col sm="4" className="pl-4">
                                        Event Name
                                    </Col>
                                    <Col sm="3">Event Conditions</Col>
                                    <Col sm="3">Event Actions</Col>
                                    <Col sm="1">Status</Col>
                                    <Col sm="1"></Col>
                                </Row>
                            </div>

                            {renderAssociatedEvents()}
                        </Row>
                    </div>
                </Container>
            </div>

            <DefaultModal
                modalShow={isShowDeleteModal}
                setModalShow={setIsShowDeleteModal}
                modalType={"dlt"}
                modalContent={"Do you want to delete this Event variable?"}
                deleteFunction={handleDeleteEventVariable}
            />
        </ContentWrapper>
    );
};

export default EventVariableDetail;
