import { useState, useMemo, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";

import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { MemberDto, MemberStatus, MemberGroupDto } from "types";
import { getMemberName } from "utils/organisationFunction";
import organizationService from "service/organisationService";
import {
    HttpStatus,
    FAILED_ACTION_MESSAGE,
    FETCH_MEMBER_FAIL_MESSAGE,
} from "constant";
import { formatDate, isHttpSuccess } from "utils/functions";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import DefaultModal from "components/modals/ModalTemplate";
import { showErrorAlert, showSuccessAlert, getAPIError } from "utils/alert";

const DefaultMember: MemberDto = {
    name: [],
    username: "",
    status: MemberStatus.Owner,
    createTime: new Date(),
    groups: [],
    modifyTime: new Date(),
    uuid: "",
    inviter: "",
};

type MemberDetailParams = {
    orgId: string;
    memberId: string;
};

enum USER_ACTION {
    REMOVE,
    CANCEL,
    REINVITE,
    FORGET,
}

const MembersDetails = () => {
    const { orgId, memberId }: MemberDetailParams = useParams();
    const history = useHistory();
    const [isForbidden, setIsForbidden] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [member, setMember] = useState<MemberDto>(DefaultMember);
    const [isShowConfirmation, setIsShowConfirmation] =
        useState<boolean>(false);
    const [modalType, setModalType] = useState<string>("");
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalContent, setModalContent] = useState<string>("");
    const [userAction, setUserAction] = useState<string>("");

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const fetchResponse = await organizationService.getMember(
                orgId,
                memberId
            );

            if (fetchResponse.status === HttpStatus.FORBIDDEN) {
                setIsForbidden(true);
            } else if (isHttpSuccess(fetchResponse.status)) {
                setMember(toCamelCaseObject<MemberDto>(fetchResponse.data));
            } else {
                showErrorAlert(
                    getAPIError(fetchResponse, FETCH_MEMBER_FAIL_MESSAGE)
                );
            }
            setIsLoading(false);
        })();
    }, [memberId, orgId]);

    const pageTitle = useMemo(() => {
        return getMemberName(member);
    }, [member]);

    const getOwnerMemberDetail = () => {
        return (
            <Row>
                <Col md xs={12} className="member-info-item">
                    <h6>Name</h6>
                    <h6 className="ginfo">{getMemberName(member)}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Email Address</h6>
                    <h6 className="ginfo">{member.username}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Status</h6>
                    <h6 className="ginfo">Owner</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Created organisation on</h6>
                    <h6 className="ginfo">{formatDate(member.createTime)}</h6>
                </Col>
            </Row>
        );
    };

    const getAcceptedMemberDetail = () => {
        return (
            <Row>
                <Col md xs={12} className="member-info-item">
                    <h6>Name</h6>
                    <h6 className="ginfo">{getMemberName(member)}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Email Address</h6>
                    <h6 className="ginfo">{member.username}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Status</h6>
                    <h6 className="ginfo">Invitation Accepted</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Joined On</h6>
                    <h6 className="ginfo">{formatDate(member.modifyTime)}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Invited On</h6>
                    <h6 className="ginfo">{formatDate(member.createTime)}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Invited By</h6>
                    <h6 className="ginfo">{member.inviter}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <HoverAuthorizeTooltip
                        permission="organisation:delete"
                        className="mt-2 float-right"
                    >
                        <Button
                            variant="danger"
                            onClick={() => {
                                showConfirmationModal(
                                    USER_ACTION[USER_ACTION.REMOVE]
                                );
                            }}
                        >
                            REMOVE USER
                        </Button>
                    </HoverAuthorizeTooltip>
                </Col>
            </Row>
        );
    };

    const getPendingMemberDetail = () => {
        return (
            <Row>
                <Col md xs={12} className="member-info-item">
                    <h6>Email Address</h6>
                    <h6 className="ginfo">{member.username}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Status</h6>
                    <h6 className="ginfo">Invitation Pending</h6>
                </Col>

                <Col md xs={12} className="member-info-item">
                    <h6>Invited On</h6>
                    <h6 className="ginfo">{formatDate(member.modifyTime)}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Invited By</h6>
                    <h6 className="ginfo">{member.inviter}</h6>
                </Col>
                <Col md={4} xs={12} className="member-info-item">
                    <Button
                        variant="danger"
                        className="mt-2 float-right ml-2"
                        onClick={() => {
                            showConfirmationModal(
                                USER_ACTION[USER_ACTION.CANCEL]
                            );
                        }}
                    >
                        CANCEL INVITATION
                    </Button>
                </Col>
            </Row>
        );
    };

    const getDeclinedMemberDetail = () => {
        return (
            <Row>
                <Col md xs={12} className="member-info-item">
                    <h6>Email Address</h6>
                    <h6 className="ginfo">{member.username}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Status</h6>
                    <h6 className="ginfo">Invitation Declined</h6>
                </Col>

                <Col md xs={12} className="member-info-item">
                    <h6>Invited On</h6>
                    <h6 className="ginfo">{formatDate(member.createTime)}</h6>
                </Col>
                <Col md xs={12} className="member-info-item">
                    <h6>Invited By</h6>
                    <h6 className="ginfo">{member.inviter}</h6>
                </Col>
                <Col md={4} xs={12} className="member-info-item">
                    <Button
                        variant="danger"
                        className="mt-2 float-right ml-2"
                        onClick={() => {
                            showConfirmationModal(
                                USER_ACTION[USER_ACTION.FORGET]
                            );
                        }}
                    >
                        FORGET USER
                    </Button>
                    <Button
                        variant="primary"
                        className="mt-2 float-right"
                        onClick={() => {
                            showConfirmationModal(
                                USER_ACTION[USER_ACTION.REINVITE]
                            );
                        }}
                    >
                        INVITE AGAIN
                    </Button>
                </Col>
            </Row>
        );
    };

    const getMemberDetails = () => {
        let memberDetailInfo;
        switch (member.status) {
            case MemberStatus.Accepted:
                memberDetailInfo = getAcceptedMemberDetail();
                break;
            case MemberStatus.Pending:
                memberDetailInfo = getPendingMemberDetail();
                break;
            case MemberStatus.Declined:
                memberDetailInfo = getDeclinedMemberDetail();
                break;
            default:
                memberDetailInfo = getOwnerMemberDetail();
                break;
        }

        return (
            <Row>
                <Col sm={12}>
                    <div className="form-box">{memberDetailInfo}</div>
                </Col>
            </Row>
        );
    };

    const showConfirmationModal = (action: string) => {
        setUserAction(action);
        if (action === USER_ACTION[USER_ACTION.REMOVE]) {
            setModalType("custom");
            setModalTitle("Remove User?");
            setModalContent("Do you want to remove this user?");
        } else if (action === USER_ACTION[USER_ACTION.CANCEL]) {
            setModalType("cancelConfirm");
            setModalTitle("Cancel Invitation?");
            setModalContent("Do you want to cancel the invitation?");
        } else if (action === USER_ACTION[USER_ACTION.REINVITE]) {
            setModalType("resendConfirm");
            setModalTitle("Resend Invitation?");
            setModalContent(`Do you want to resend an invitation to
            ${member.username}?`);
        } else if (action === USER_ACTION[USER_ACTION.FORGET]) {
            setModalType("cancelConfirm");
            setModalTitle("Forget User?");
            setModalContent("Do you want to forget this user?");
        }
        setIsShowConfirmation(true);
    };

    const resendInvitation = async () => {
        const { status, data } = await organizationService.inviteMembers(
            orgId,
            [member.username]
        );

        handleShowAlert(
            status,
            data,
            "A new invitation has been sent successfully."
        );

        history.push(`/organisation/${orgId}`);
    };

    const removeUser = async (successMessage: string) => {
        const { status, data } = await organizationService.deleteMembers(
            orgId,
            [member.username]
        );

        handleShowAlert(status, data, successMessage);

        history.push(`/organisation/${orgId}`);
    };

    const handleShowAlert = (
        status: number,
        data: { description: string },
        successMessage: string
    ) => {
        if (isHttpSuccess(status)) {
            showSuccessAlert({
                message: successMessage,
            });
        } else {
            showErrorAlert({
                message: data?.description ?? FAILED_ACTION_MESSAGE,
            });
        }
    };

    const handleConfirm = async () => {
        switch (userAction) {
            case USER_ACTION[USER_ACTION.CANCEL]:
                await removeUser("Invitation cancelled successfully.");
                break;
            case USER_ACTION[USER_ACTION.FORGET]:
                await removeUser("User has been forgotten successfully.");
                break;
            case USER_ACTION[USER_ACTION.REINVITE]:
                await resendInvitation();
                break;
        }
    };

    const handleCloseModal = () => {
        setIsShowConfirmation(false);
    };

    const modalButton = () => {
        return (
            <>
                <Button variant="secondary" onClick={handleCloseModal}>
                    CANCEL
                </Button>
                <Button
                    variant="danger"
                    onClick={() =>
                        removeUser("User has been removed successfully.")
                    }
                >
                    REMOVE
                </Button>
            </>
        );
    };

    const groupList = () => {
        const groupItems = member.groups.map((group: MemberGroupDto) => {
            return (
                <div className="table-row" key={group.uuid}>
                    <Row className="group-item">
                        <Col md sm="12" className="group-item-name">
                            <Link
                                to={`/organisation/${orgId}/group/${group.uuid}`}
                            >
                                {group.name}
                            </Link>
                        </Col>
                        <Col md sm="12">
                            {group.isDefault ? (
                                <span className="default">Default Group</span>
                            ) : (
                                <span className="custom">Custom Group</span>
                            )}
                        </Col>
                        <Col md sm="12">
                            <span className="reading-label default">
                                Added to this group on{" "}
                            </span>
                            {formatDate(group.modifyTime)}
                        </Col>
                    </Row>
                </div>
            );
        });

        return (
            <>
                <Row>
                    <Col sm="12" className="mt-5">
                        <h5 className="page-title">Groups</h5>
                    </Col>
                </Row>

                <Row className="cstm-table mt-1">
                    <Col sm={12}>
                        <div className="table-head">
                            <Row className="group-item">
                                <Col md sm="12" className="group-item-name">
                                    Group Name
                                </Col>
                                <Col md sm="12">
                                    Group type
                                </Col>
                                <Col md sm="12">
                                    Added to this group on
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    {groupItems}
                </Row>
            </>
        );
    };

    return (
        <ContentWrapper
            isForbiddenResource={isForbidden}
            isLoading={isLoading}
            title={pageTitle || "Member Details"}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="member-detail-head">
                            <h5 className="page-title">{pageTitle || "-"}</h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item active>
                                    <Link to={`/organisation/${orgId}`}>
                                        Organisation
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Member</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    {getMemberDetails()}
                    {[MemberStatus.Owner, MemberStatus.Accepted].includes(
                        member.status
                    ) && groupList()}
                </Container>
            </div>

            <DefaultModal
                modalShow={isShowConfirmation}
                setModalShow={handleCloseModal}
                modalType={modalType}
                modalIcon="help_outline"
                modalTitle={modalTitle}
                modalContent={modalContent}
                modalButton={modalButton()}
                resendFunction={handleConfirm}
            />
        </ContentWrapper>
    );
};

export default MembersDetails;
