
import { useAppContext } from "context/appContext";

const TimezoneDescription = () => {
    const {
        storeData: {
            organization: {
                currentOrgInfo: { name, isDefault },
            },
        },
    } = useAppContext();

    return (
        <>
            <p className="reusable-description">
                {isDefault ? (
                    <>
                        This time zone is used for time related events only. All other time information is relative to local device time. Events created in the past will continue to work in the previous time zone.This time zone is only applicable to your personal account. For organisation, switch to an organisation and set the time zone under that organisation.
                    </>
                ) : (
                    <>
                        This time zone is used for time related events only. All other time information is relative to local device time. Events created in the past will continue to work in the previous time zone.
                        The selected time zone is only applicable to organisation <span className="default-white-color">{name}</span>. For other organisations or personal account, switch to it and set the time zone under that organisation/personal account.
                    </>
                )}
            </p>
        </>
    );
};

export default TimezoneDescription;
