import { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useHistory } from "react-router";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Button,
    Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import paymentImg from "assets/svg/payment.svg";
import dropin from "braintree-web-drop-in";
import "assets/css/subscription.css";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import {
    getClientToken,
    activateSubscription,
    buyAddon,
    getSubscriptionPrice,
    getAddonPrice,
    getWalletStatus,
    getSubscriptionById,
    getAddress,
} from "service/subscriptionService";
import PromoCode from "components/subscriptions/Promocode";
import EditCustomer from "components/subscriptions/EditCustomer";
import {
    convertStringTo2dp,
    getAllTokens,
    isHttpSuccess,
} from "utils/functions";
import { AppContext } from "context/appContext";
import { storeTokenBalanceAction } from "store/actions";
import { CheckoutType, TERMS_OF_SERVICE_LINK } from "constant";
import { getAPIError, showErrorAlert } from "utils/alert";
import { BillingAddressProp } from "types/Subscription";
import { get, isEmpty } from "lodash";
import { initBillingAddress } from "utils/subscriptionFunctions";
import { LoadingModal } from "components/modals/ModalTemplate";
import EditBillingAddress from "components/subscriptions/EditBillingAddress";
import PurchasePreview from "components/subscriptions/PurchasePreview";
import PreviewItem, {
    PreviewSubscriptionBillingInfo,
} from "components/subscriptions/PreviewItem";
import { SubscriptionPlanType } from "components/subscriptions/CurrentPlanInformation";
import withSubscriptionPermissionCheck from "components/hoc/SubscriptionPermissionCheck";

const Checkout = () => {
    const history = useHistory();
    const location: any = useLocation();
    const {
        storeData: {
            organization: { currentOrgId },
            user: {
                userData: { email },
            },
            subscription: { currentSubscriptionId },
        },
        storeDispatchActions,
    } = useContext(AppContext);
    const { state } = location;
    const {
        billingStartDate,
        bundleQty: locationBundleQty,
        addonUUID,
        subscriptionUUID,
        bundleInfo: { amount, tokenQty },
        checkoutType,
        subscriptionType,
        addressUUID,
        plan,
        customer,
        monthlyChargePreview,
    } = state;

    const braintreeRef = useRef(null);
    const [brainTreeInstance, setBrainTreeInstance] = useState(Object);
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [bundleQty, setBundleQty] = useState(locationBundleQty);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [transactionLoaded, setTransactionLoaded] = useState(true);
    const [price, setPrice] = useState({
        amount: "",
        total: "",
        tax: "",
        discount: "",
        addon: "",
        tax_rate: "",
    });
    const [currentAddress, setCurrentAddress] = useState<BillingAddressProp>(
        initBillingAddress()
    );
    const [currentSubscriptionUUID, setCurrentSubscriptionUUID] = useState(
        currentSubscriptionId || ""
    );
    const [selectedPromocode, setSelectedPromocode] = useState({
        promocode_name: "",
        description: "",
    });
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [errorType, setErrorType] = useState("");
    const checkoutSubscription: boolean = checkoutType === CheckoutType.PLAN;
    const checkoutAddon: boolean = checkoutType === CheckoutType.ADDON;
    const MODAL_SUCCESS = "success";
    const MODAL_ERROR = "error";
    const INVALID = "invalid";
    const TRANSACTION_FAIL = "transactionFailed";
    const MODAL_SUSCRIPTION_SUCCESS = "subscriptionConfirmed";

    const getTokenBalance = async () => {
        const total: any = await getAllTokens();
        storeDispatchActions(
            storeTokenBalanceAction({ tokenBalance: total, isLoaded: true })
        );
    };

    useEffect(() => {
        if (
            braintreeRef.current ||
            ["prepaid", "basic"].includes(subscriptionType)
        )
            return;
        (async () => {
            const response: any = await getClientToken();

            if (isHttpSuccess(response.status)) {
                const clientToken = response.data;

                dropin.create(
                    {
                        authorization: clientToken,
                        container: "#braintree-drop-in-div",
                        threeDSecure: true,
                        card: { vault: { vaultCard: false } },
                        preselectVaultedPaymentMethod: false,
                    },
                    (error: any, instance: any) => {
                        if (error) {
                            setBrainTreeInstance(null);
                        } else {
                            setBrainTreeInstance(instance);
                            setTransactionLoaded(true);
                        }
                    }
                );
            } else {
                showErrorAlert({
                    message: `Unable to load payment interface. Please try again.`,
                });
            }
        })();
        return () => {
            !isEmpty(brainTreeInstance) && brainTreeInstance.teardown();
        };
    }, [brainTreeInstance, subscriptionType]);

    useEffect(() => {
        const fetch = async () => {
            const subscriptionRes = await getSubscriptionById(
                subscriptionUUID || currentSubscriptionId
            );

            if (isHttpSuccess(subscriptionRes.status)) {
                if (
                    checkoutAddon ||
                    (checkoutSubscription && subscriptionType === "monthly")
                ) {
                    let currentAddress: any = subscriptionRes.data?.address;
                    if (checkoutAddon) {
                        const address: any = await getAddress();
                        if (isHttpSuccess(address.status)) {
                            currentAddress = get(address, "data[0]", {});
                        }
                    }

                    const {
                        first_name,
                        last_name,
                        city,
                        street,
                        line3,
                        country,
                        code,
                        extended,
                        region,
                    } = currentAddress;

                    setCurrentAddress({
                        first_name,
                        last_name,
                        city,
                        street,
                        line3,
                        country,
                        code,
                        extended,
                        region,
                    });

                    let priceRes: any;

                    if (checkoutSubscription) {
                        if (subscriptionUUID) {
                            setCurrentSubscriptionUUID(
                                subscriptionUUID || currentSubscriptionId
                            );

                            priceRes = await getSubscriptionPrice(
                                subscriptionUUID,
                                country
                            );
                        }
                    } else if (checkoutAddon) {
                        priceRes = await getAddonPrice({
                            addon_uuid: addonUUID,
                            country,
                            quantity: bundleQty,
                            coupon: selectedPromocode.promocode_name,
                        });
                    }

                    if (isHttpSuccess(priceRes?.status)) {
                        setPrice(priceRes.data);
                    }
                } else if (
                    checkoutSubscription &&
                    [
                        SubscriptionPlanType.BASIC,
                        SubscriptionPlanType.PREPAID,
                    ].includes(subscriptionType)
                ) {
                    setCurrentSubscriptionUUID(
                        subscriptionUUID || currentSubscriptionId
                    );

                    setPrice({
                        ...price,
                        total: customer.price,
                        amount: customer.price,
                    });
                }
            } else {
                showErrorAlert({
                    message:
                        "Unable to fetch billing address information. Please reenter your billing address.",
                });
                history.push("/checkout/billing-address", {
                    checkoutType,
                });
            }

            setPageLoaded(true);
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        addonUUID,
        checkoutAddon,
        checkoutSubscription,
        checkoutType,
        currentSubscriptionId,
        history,
        subscriptionUUID,
    ]);

    const calculatePrice = async (quantity: string) => {
        const priceRes: any = await getAddonPrice({
            addon_uuid: addonUUID,
            country: currentAddress.country,
            quantity,
            coupon: selectedPromocode.promocode_name,
        });
        if (isHttpSuccess(priceRes.status)) {
            setPrice(priceRes.data);
            setBundleQty(quantity);
        } else {
            showErrorAlert(
                getAPIError(
                    priceRes,
                    "Unable to update price. Please try again."
                )
            );
        }
    };

    const proceedToMainSubscriptionPageOnSuccess = async () => {
        setModalShow(false);
        getTokenBalance();
        await getWalletStatus(currentOrgId);
        history.push("/manage-subscription");
    };

    const renderModalIcon = () => {
        if (modalType === MODAL_ERROR) {
            return <span className="material-icons">warning</span>;
        } else if (
            [MODAL_SUCCESS, MODAL_SUSCRIPTION_SUCCESS].includes(modalType)
        ) {
            return <span className="material-icons">done</span>;
        }
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR && errorType === TRANSACTION_FAIL) {
            return <h3 className="mb-3">Payment Failed!</h3>;
        } else if (modalType === MODAL_ERROR && errorType === INVALID) {
            return <h3 className="mb-3">Error</h3>;
        } else if (modalType === MODAL_SUCCESS) {
            return <h3 className="mb-3">Payment Successful!</h3>;
        } else if (modalType === MODAL_SUSCRIPTION_SUCCESS) {
            return <h3 className="mb-3">Subscription Confirmed!</h3>;
        }
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR && errorType === TRANSACTION_FAIL) {
            return (
                <Button
                    variant="secondary"
                    onClick={() => {
                        setModalShow(false);
                    }}
                >
                    TRY AGAIN
                </Button>
            );
        }
        return (
            <Button
                variant="primary"
                onClick={() => {
                    [MODAL_SUCCESS, MODAL_SUSCRIPTION_SUCCESS].includes(
                        modalType
                    )
                        ? proceedToMainSubscriptionPageOnSuccess()
                        : setModalShow(false);
                }}
            >
                OK
            </Button>
        );
    };

    const isTOSChecked = () => {
        if (!checkedTerms) {
            showErrorAlert({
                message: "Please agree with the terms of service to proceed.",
            });

            return false;
        }
        return true;
    };

    const confirmSubscription = async () => {
        const response = await activateSubscription(
            currentSubscriptionUUID,
            ""
        );
        if (isHttpSuccess(response?.status)) {
            setTransactionLoaded(true);
            setModalShow(true);
            setModalType(MODAL_SUSCRIPTION_SUCCESS);
            setModalContent(`Your subscription has started.`);
        } else {
            setTransactionLoaded(true);
            setModalShow(true);
            setModalType(MODAL_ERROR);
            setErrorType(INVALID);
            setModalContent("Something went wrong.");
        }
    };

    const pay = async (paymentMethodNonce: string) => {
        let response: any;
        if (checkoutSubscription) {
            response = await activateSubscription(
                currentSubscriptionUUID,
                paymentMethodNonce
            );
        } else {
            const addonBody: any = {
                quantity: bundleQty,
                nonce: paymentMethodNonce,
                address_uuid: addressUUID,
                coupon: selectedPromocode.promocode_name,
            };

            if (!selectedPromocode.promocode_name) {
                delete addonBody.coupon;
            }

            response = await buyAddon(addonUUID, addonBody);
        }

        if (isHttpSuccess(response.status)) {
            setTransactionLoaded(true);
            setModalShow(true);
            setModalType(MODAL_SUCCESS);
            setModalContent("Your transaction is successful.");
        } else {
            brainTreeInstance.clearSelectedPaymentMethod();
            setTransactionLoaded(true);
            setModalShow(true);
            setModalType(MODAL_ERROR);
            setErrorType(TRANSACTION_FAIL);
            setModalContent("Please try a different payment method.");
        }
    };

    const makeTransaction = () => {
        setTransactionLoaded(false);

        const { first_name, last_name, street, extended, country, code } =
            currentAddress;

        const threeDSecureParameters = {
            amount: price.total,
            email,
            billingAddress: {
                givenName: first_name,
                surname: last_name,
                streetAddress: street,
                extendedAddress: extended,
                locality: country,
                postalCode: code,
            },
        };

        if (brainTreeInstance) {
            brainTreeInstance?.requestPaymentMethod(
                { threeDSecure: threeDSecureParameters },
                (error: any, payload: any) => {
                    if (error) {
                        brainTreeInstance.clearSelectedPaymentMethod();
                        setTransactionLoaded(true);
                        setModalShow(true);
                        setModalType(MODAL_ERROR);
                        setErrorType(TRANSACTION_FAIL);
                        setModalContent(error.message);
                    } else {
                        if (payload.type === "CreditCard") {
                            const { liabilityShifted } = payload;

                            if (!liabilityShifted) {
                                brainTreeInstance.clearSelectedPaymentMethod();

                                setModalShow(true);
                                setModalType(MODAL_ERROR);
                                setErrorType(INVALID);
                                setModalContent(
                                    "Authentication unsuccessful. Please try again with another payment method."
                                );
                                setTransactionLoaded(true);
                                return;
                            }
                        }
                        const paymentMethodNonce = payload.nonce;
                        pay(paymentMethodNonce);
                    }
                }
            );
        }
    };

    return (
        <ContentWrapper isLoading={!pageLoaded}>
            <div className="page-content subscription-page">
                <Container fluid>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title">
                                {checkoutSubscription &&
                                [
                                    SubscriptionPlanType.BASIC,
                                    SubscriptionPlanType.PREPAID,
                                ].includes(subscriptionType)
                                    ? "Review and Confirm"
                                    : "Checkout"}
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item>
                                    <Link to="/manage-subscription">
                                        Subscription
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={{
                                            pathname:
                                                "/checkout/billing-address",
                                            state: {
                                                ...state,
                                                bundleQty,
                                                cancelledSubscriptionUUID:
                                                    subscriptionUUID,
                                            },
                                        }}
                                    >
                                        {checkoutSubscription
                                            ? "Customer Details"
                                            : "Billing Address"}
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {checkoutSubscription &&
                                    [
                                        SubscriptionPlanType.BASIC,
                                        SubscriptionPlanType.PREPAID,
                                    ].includes(subscriptionType)
                                        ? "Review and Confirm"
                                        : "Checkout"}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            {checkoutSubscription && (
                                <EditCustomer state={state} />
                            )}
                            {!["prepaid", "basic"].includes(
                                subscriptionType
                            ) && (
                                <EditBillingAddress
                                    state={state}
                                    bundleQty={bundleQty}
                                    currentAddress={currentAddress}
                                />
                            )}

                            {!["prepaid", "basic"].includes(
                                subscriptionType
                            ) && (
                                <div className="form-box mb-3">
                                    <div className="payment-title">
                                        <img
                                            src={paymentImg}
                                            className="address-img"
                                            alt="payment"
                                        />
                                        <h5>Payment Method</h5>
                                        <p>Credit or Debit Card</p>

                                        <div
                                            className="pt-5"
                                            ref={braintreeRef}
                                            id={"braintree-drop-in-div"}
                                        ></div>
                                    </div>
                                </div>
                            )}
                        </Col>
                        <Col md={4}>
                            <PurchasePreview
                                checkoutType={checkoutType}
                                customer={customer}
                            >
                                <PreviewItem
                                    checkoutType={checkoutType}
                                    monthlyChargePreview={
                                        subscriptionType === "monthly"
                                            ? price.amount
                                            : monthlyChargePreview
                                    }
                                    billingStartDate={billingStartDate}
                                    customer={customer}
                                    bundlePrice={amount}
                                    bundleTokenQty={tokenQty}
                                    subscriptionType={subscriptionType}
                                    bundleQty={bundleQty}
                                    setBundleQty={setBundleQty}
                                    customOnChange={(e: any) => {
                                        calculatePrice(e.target.value);
                                    }}
                                />

                                {!["prepaid", "basic"].includes(
                                    subscriptionType
                                ) && (
                                    <>
                                        <PromoCode
                                            bundleQty={
                                                bundleQty || locationBundleQty
                                            }
                                            plan={plan}
                                            subscriptionUUID={subscriptionUUID}
                                            setSelectedPromocode={
                                                setSelectedPromocode
                                            }
                                            country={currentAddress.country}
                                            setPrice={setPrice}
                                            checkoutType={checkoutType}
                                            addonUUID={addonUUID}
                                            selectedPromocode={
                                                selectedPromocode
                                            }
                                        />

                                        <PreviewCostBreakdown
                                            checkoutSubscription={
                                                checkoutSubscription
                                            }
                                            price={price}
                                            currentAddress={currentAddress}
                                        />
                                    </>
                                )}

                                {checkoutSubscription && (
                                    <PreviewSubscriptionBillingInfo
                                        billingStartDate={billingStartDate}
                                        customer={customer}
                                        subscriptionType={subscriptionType}
                                    />
                                )}

                                {!["prepaid", "basic"].includes(
                                    subscriptionType
                                ) && <PreviewTotalCost price={price} />}

                                <Form.Check
                                    className="check-term"
                                    type="checkbox"
                                    id="customControlAutosizing"
                                    custom
                                    onChange={() => {
                                        setCheckedTerms(!checkedTerms);
                                    }}
                                    label={
                                        <Form.Label>
                                            I agree to the{" "}
                                            <a
                                                href={TERMS_OF_SERVICE_LINK}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="link"
                                            >
                                                Terms of Service
                                            </a>{" "}
                                        </Form.Label>
                                    }
                                />
                                <Button
                                    variant="primary"
                                    className="proceed"
                                    onClick={() => {
                                        if (!isTOSChecked()) return;

                                        checkoutAddon ||
                                        (checkoutSubscription &&
                                            subscriptionType ===
                                                SubscriptionPlanType.MONTHLY)
                                            ? makeTransaction()
                                            : confirmSubscription();
                                    }}
                                >
                                    {checkoutAddon ||
                                    (checkoutSubscription &&
                                        subscriptionType ===
                                            SubscriptionPlanType.MONTHLY)
                                        ? "PAY"
                                        : "CONFIRM SUBSCRIPTION"}
                                </Button>
                            </PurchasePreview>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    [MODAL_SUSCRIPTION_SUCCESS, MODAL_SUCCESS].includes(
                        modalType
                    )
                        ? "primary"
                        : "danger"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>
                    {renderModalButton()}
                </Modal.Body>
            </Modal>

            <LoadingModal showModal={!transactionLoaded} />
        </ContentWrapper>
    );
};

const PreviewCostBreakdown = ({
    checkoutSubscription,
    price,
    currentAddress,
}: {
    checkoutSubscription: boolean;
    price: any;
    currentAddress: any;
}) => {
    return (
        <div className="amount-box">
            <p aria-label="subtotal-amount">
                Sub Total{" "}
                <span>
                    USD{" "}
                    {convertStringTo2dp(
                        checkoutSubscription ? price.amount : price.addon
                    )}
                </span>
            </p>

            <p aria-label="discount-amount">
                Discount{" "}
                <span> - USD {convertStringTo2dp(price.discount)}</span>
            </p>
            {currentAddress.country === "Singapore" && (
                <p aria-label="tax-amount">
                    {price.tax_rate * 100}% GST
                    <span>USD {convertStringTo2dp(price.tax)}</span>
                </p>
            )}
        </div>
    );
};

const PreviewTotalCost = ({ price }: any) => {
    return (
        <div className="total-box">
            <h5 aria-label="total-amount">
                Total <span>USD {convertStringTo2dp(price.total)}</span>
            </h5>
        </div>
    );
};

export default withSubscriptionPermissionCheck(Checkout);
