import AxiosInstance from "./axiosInstance";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "../utils/functions";
import { apiFactory } from "generated";

class PermissionService {
    private permissionClient = apiFactory.getPermissionInstance(AxiosInstance);

    // /**
    //  * Get Permission group
    //  * @returns {Promise }
    //  */
    // async getPermissionGroup() {
    //     try {
    //         const res =
    //             await this.permissionGroupClient.searchPermissionGroups();
    //         return createResponseStandard(res);
    //     } catch (error) {
    //         return createErrorResponseStandard(error);
    //     }
    // }

    // /**
    //  * Get permission for permission group
    //  * @param { string } id - Permission Group id
    //  * @returns { Promise }
    //  */
    // async getPermissionGroupPermission(id: string) {
    //     try {
    //         const res = await this.permissionGroupClient.listPermissionsById(
    //             id
    //         );
    //         return createResponseStandard(res);
    //     } catch (error) {
    //         return createErrorResponseStandard(error);
    //     }
    // }

    // /**
    //  * Get permission status to check Alert
    //  * Interim solution for 1.4.9
    //  * @param { userEmail } - user's email address
    //  * @returns { Promise }
    //  */
    // async checkAlertPermission(userEmail: string) {
    //     try {
    //         const res = await this.permissionClient.checkPes(
    //             userEmail,
    //             orgId(),
    //             "gateway-settings",
    //             "re-onboard"
    //         );
    //         return res;
    //     } catch (error) {
    //         return error;
    //     }
    // }

    /**
     * Get default Permissions
     * @returns {Promise }
     */
    async getDefaultPermissions(orgId: string) {
        try {
            const res =
                await this.permissionClient.getPermissionByUuid(orgId);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }
    
}

const permissionService = new PermissionService();

export default permissionService;
