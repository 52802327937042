/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "../http-client";
import { GetZoneInfoData } from "../models";

export class Miscellaneous<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags zone
   * @name GetZoneInfo
   * @summary Get zone info
   * @request GET:/miscellaneous/zoneinfo
   * @secure
   */
  getZoneInfo = (params: RequestParams = {}) =>
    this.http.request<GetZoneInfoData, void>({
      path: `/miscellaneous/zoneinfo`,
      method: "GET",
      secure: true,
      ...params,
    });
}
