import { AxiosInstance } from "axios";
import {
  Account,
  Dashboards,
  Events,
  Firmware,
  Gatewaygroups,
  Gateways,
  Invitation,
  Metering,
  Miscellaneous,
  Organisation,
  Payment,
  Permission,
  Subscriptions,
  Wallet,
} from "./clients";
import { HttpClient } from "./http-client";

function createHttpClient<SecurityDataType = unknown>(axios?: AxiosInstance): HttpClient<SecurityDataType> {
  const httpClient = new HttpClient<SecurityDataType>();
  if (axios) {
    httpClient.instance = axios;
  }

  return httpClient;
}

export function getAccountInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Account<SecurityDataType> {
  return new Account(createHttpClient<SecurityDataType>(axios));
}

export function getOrganisationInstance<SecurityDataType = unknown>(
  axios?: AxiosInstance,
): Organisation<SecurityDataType> {
  return new Organisation(createHttpClient<SecurityDataType>(axios));
}

export function getInvitationInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Invitation<SecurityDataType> {
  return new Invitation(createHttpClient<SecurityDataType>(axios));
}

export function getPermissionInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Permission<SecurityDataType> {
  return new Permission(createHttpClient<SecurityDataType>(axios));
}

export function getDashboardsInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Dashboards<SecurityDataType> {
  return new Dashboards(createHttpClient<SecurityDataType>(axios));
}

export function getGatewaysInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Gateways<SecurityDataType> {
  return new Gateways(createHttpClient<SecurityDataType>(axios));
}

export function getGatewaygroupsInstance<SecurityDataType = unknown>(
  axios?: AxiosInstance,
): Gatewaygroups<SecurityDataType> {
  return new Gatewaygroups(createHttpClient<SecurityDataType>(axios));
}

export function getMeteringInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Metering<SecurityDataType> {
  return new Metering(createHttpClient<SecurityDataType>(axios));
}

export function getFirmwareInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Firmware<SecurityDataType> {
  return new Firmware(createHttpClient<SecurityDataType>(axios));
}

export function getMiscellaneousInstance<SecurityDataType = unknown>(
  axios?: AxiosInstance,
): Miscellaneous<SecurityDataType> {
  return new Miscellaneous(createHttpClient<SecurityDataType>(axios));
}

export function getSubscriptionsInstance<SecurityDataType = unknown>(
  axios?: AxiosInstance,
): Subscriptions<SecurityDataType> {
  return new Subscriptions(createHttpClient<SecurityDataType>(axios));
}

export function getPaymentInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Payment<SecurityDataType> {
  return new Payment(createHttpClient<SecurityDataType>(axios));
}

export function getWalletInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Wallet<SecurityDataType> {
  return new Wallet(createHttpClient<SecurityDataType>(axios));
}

export function getEventsInstance<SecurityDataType = unknown>(axios?: AxiosInstance): Events<SecurityDataType> {
  return new Events(createHttpClient<SecurityDataType>(axios));
}
