import styled from "@emotion/styled";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import styledConst from "styles";

export const BlueText = styled.span`
    color: ${styledConst.Primary_Blue_4};
    letter-spacing: -0.224px;
    font-size: 14px;

    &.large {
        font-size: 1.125rem;
    }
`;

export const Box = styled.div`
    background-color: #3e4b67;
    border-radius: 5px;
    min-width: 220px;
    padding: 12px 15px;

    &.gray {
        background-color: #3e4b67;
    }
`;

export const RowBox = styled.div`
    background-color: ${styledConst.Primary_Blue_3};
    padding: 15px 12px;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
`;

const Arrow = styled.i`
    color: ${styledConst.Primary_Green_1};
`;

export const ArrowIcon = ({
    children = "keyboard_arrow_right",
    className,
}: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>) => (
    <Arrow className={`material-icons ${className}`}>{children}</Arrow>
);

const Remove = styled.i`
    color: ${styledConst.Secondary_Red};
    cursor: pointer;
`;

export const RemoveIcon = ({
    children = "close",
    className,
    ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>) => (
    <Remove className={`material-icons ${className}`} {...restProps}>
        {children}
    </Remove>
);

export const ColorPickerBackground = styled.div<{ $inputColor: string }>`
    background-color: ${(props) => props.$inputColor} !important;
`;
