import { useState, KeyboardEvent } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    InputGroup,
    FormControl,
    Button,
    Form,
    Modal,
} from "react-bootstrap";
import moment from "moment";
import { get, orderBy } from "lodash";
import dltImg from "assets/svg/delete.svg";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import DefaultModal from "components/modals/ModalTemplate";
import Pagination from "components/shared/Pagination";
import {
    EVENT_ENABLED_DELETE_MESSAGE,
    EventNameMaxCharacter,
    FAILED_ACTION_MESSAGE,
    Patterns,
} from "constant/index";
import EventService from "service/eventService";
import { isValid, showWarning } from "utils/eventFunctions";
import { WalletAlertComponent, walletModalTrigger } from "hooks/wallet";
import "assets/css/event.css";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { isHttpSuccess } from "utils/functions";
import ConditionCol from "components/events/ConditionCol";
import ActionCol from "components/events/ActionCol";
import { Event, Registry } from "generated/models";
import { PaginationType } from "pages/events/Events";
import { useCheckIfBasicPlan } from "hooks/useCheckIfBasicPlan";

type EventListProps = {
    eventList: Event[];
    allGateways: Registry[];
    fetchEventList: Function;
    setEventList: Function;
    eventSearchText: string;
    setEventSearchText: Function;
    pagination: PaginationType;
    setPagination: Function;
    sortIcon: Function;
};

const EventList = ({
    eventList,
    allGateways,
    fetchEventList,
    setEventList,
    eventSearchText,
    setEventSearchText,
    pagination,
    setPagination,
    sortIcon,
}: EventListProps) => {
    const history = useHistory();
    const [smShow, setSmShow] = useState(false);
    const handleClose = () => setSmShow(false);

    const [eventName, setEventName] = useState("");
    const [toDelete, setToDelete] = useState("");

    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const { IS_BASIC_PLAN } = useCheckIfBasicPlan();
    const [isProcessing, setIsProcessing] = useState(false);

    const updateField = (e: any) => {
        setEventName(e.target.value);
    };

    const createEvent = async (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        const eventNameTrim = String(eventName).trim();
        setEventName(eventNameTrim);

        if (!Patterns.eventNamePattern.test(eventNameTrim)) {
            showErrorAlert({
                title: "Invalid Event Name",
                message: `Event name must be 1-${EventNameMaxCharacter} characters long and not contain leading or trailing spaces or non-English characters.`,
            });
            setIsProcessing(false);
            return;
        }

        const response: any = await EventService.createEvent(eventNameTrim);
        if (isHttpSuccess(response.status)) {
            showSuccessAlert({
                message: get(
                    response,
                    "message",
                    "New Event has been created successfully."
                ),
            });
            history.push(`/event-details/${response.data.uuid}`);
        } else {
            showErrorAlert(getAPIError(response, FAILED_ACTION_MESSAGE));
        }
        setIsProcessing(false);
    };

    const deleteEvent = async (eventId: string) => {
        const response = await EventService.deleteEvent(eventId);
        if (isHttpSuccess(response.status)) {
            fetchEventList();
            showSuccessAlert({
                message: `Event has been deleted successfully.`,
            });
            setModalShow(false);
        } else {
            showErrorAlert(getAPIError(response, FAILED_ACTION_MESSAGE));
        }
    };

    const toggleEventStatus = async (event: any) => {
        // check the device of condition is valid
        if (!isValid(event, allGateways)) {
            return showWarning();
        }

        const { name, uuid: event_id, activate: active } = event;
        const response = await EventService.updateEvent(event_id, {
            name: name,
            activate: !active,
        });
        if (isHttpSuccess(response.status)) {
            const updatedEventList = eventList.map((e: any) => {
                if (event_id === e.uuid) {
                    e.activate = !e.activate;
                }
                return e;
            });
            setEventList(updatedEventList);

            showSuccessAlert({
                message: `Event has been ${
                    active ? "disabled" : "enabled"
                } successfully.`,
            });
        } else {
            showErrorAlert(getAPIError(response, FAILED_ACTION_MESSAGE));
        }
    };

    const showDeleteModal = (event: any) => {
        if (event.activate) {
            return showErrorAlert({
                message: EVENT_ENABLED_DELETE_MESSAGE,
            });
        }

        setModalShow(true);
        setModalType("dlt");
        setModalContent(`Do you want to delete this event?`);
    };

    const renderEventList = () => {
        if (eventSearchText && !eventList.length) {
            return <div className="mt-5 text-center">No event found</div>;
        } else if (!eventList.length) {
            return (
                <div className="mt-5 text-center">No Events to display.</div>
            );
        } else {
            return renderEvents();
        }
    };

    const renderEvents = () => {
        const sortedEventListByDate = orderBy(
            eventList,
            (e: any) => moment(e.created_at),
            "asc"
        );

        let events = sortedEventListByDate.map((event: any) => {
            return (
                <div className="table-row" key={event.uuid}>
                    <Row className="no-checkbox">
                        <Col md={{ offset: 0 }} xs={{ span: 11, order: 1 }}>
                            <Link
                                to={{
                                    pathname: `/event-details/${event.uuid}`,
                                }}
                            >
                                {event.name}
                            </Link>
                        </Col>
                        <Col
                            md={{ offset: 0, order: 3 }}
                            xs={{ span: 12, order: 3 }}
                        >
                            <ConditionCol
                                conditions={get(event, "conditions", [])}
                            />
                        </Col>
                        <Col
                            className="event-action-col"
                            md={{ offset: 0, order: 3 }}
                            xs={{ span: 12, order: 3 }}
                        >
                            <ActionCol actions={get(event, "actions", [])} />
                        </Col>
                        <Col
                            md={{ span: 1, offset: 0 }}
                            xs={{ span: 2, order: 4 }}
                        >
                            <HoverAuthorizeTooltip permission="event:update">
                                <span className="d-inline-block">
                                    <Form.Check
                                        type="switch"
                                        id={`switch_${event.uuid}`}
                                        label=""
                                        checked={event.activate}
                                        onChange={() =>
                                            toggleEventStatus(event)
                                        }
                                    />
                                </span>
                            </HoverAuthorizeTooltip>
                        </Col>
                        <Col
                            md={{ offset: 0, span: 1 }}
                            xs={{ offset: 8, span: 2, order: 5 }}
                        >
                            <HoverAuthorizeTooltip permission="event:delete">
                                <img
                                    src={dltImg}
                                    className="btn"
                                    aria-label="delete-event"
                                    alt="delete"
                                    onClick={() => {
                                        showDeleteModal(event);
                                        setToDelete(event.uuid);
                                    }}
                                    onKeyDown={() => {
                                        showDeleteModal(event);
                                        setToDelete(event.uuid);
                                    }}
                                />
                            </HoverAuthorizeTooltip>
                        </Col>
                        <Col
                            md={{ span: 1, order: "last" }}
                            xs={{ span: 1, order: 2 }}
                        >
                            <Link
                                to={{
                                    pathname: `/event-details/${event.uuid}`,
                                }}
                            >
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </Link>
                        </Col>
                    </Row>
                </div>
            );
        });
        return events;
    };

    return (
        <>
            <Row>
                <Col
                    sm={12}
                    className="d-md-flex justify-content-end table-head-options"
                >
                    <HoverAuthorizeTooltip
                        permission="event:create"
                        className="mr-3 mb-2"
                    >
                        <Button
                            className="btn btn-primary"
                            onClick={() => {
                                walletModalTrigger(() => setSmShow(true), true);
                            }}
                        >
                            + New Event
                        </Button>
                    </HoverAuthorizeTooltip>
                    <div className="search">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search for.."
                                onChange={(e) => {
                                    setEventSearchText(e.target.value);
                                }}
                                aria-describedby="button-addon2"
                                onKeyPress={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        fetchEventList();
                                    }
                                }}
                            />
                        </InputGroup>
                    </div>
                    <Button
                        className="btn btn-primary mb-2"
                        onClick={() => {
                            fetchEventList();
                        }}
                    >
                        Search
                    </Button>
                </Col>
            </Row>
            <Row className="cstm-table events-list mt-4">
                <Col sm={12}>
                    {!IS_BASIC_PLAN && (
                        <WalletAlertComponent showWarningWhen="LowBalance" />
                    )}
                </Col>
                <Col sm={12}>
                    <div className="table-head">
                        <Row className="no-checkbox">
                            <Col>Event Name {sortIcon("name")}</Col>
                            <Col>Event Conditions {sortIcon("conditions")}</Col>
                            <Col>Event Actions {sortIcon("actions")}</Col>
                            <Col md={{ span: 1 }}>
                                Status {sortIcon("activate")}
                            </Col>
                            <Col md={{ span: 1 }}>Action</Col>
                            <Col md={{ span: 1 }}></Col>
                        </Row>
                    </div>
                    {renderEventList()}
                </Col>
            </Row>
            {pagination.count > 0 && (
                <Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                />
            )}

            <Modal
                className="new-dash"
                show={smShow}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>New Event</Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleClose}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormControl
                            type="text"
                            placeholder="Event Name"
                            autoFocus
                            value={eventName || ""}
                            onChange={(e) => {
                                updateField(e);
                            }}
                            onKeyPress={(
                                e: KeyboardEvent<HTMLInputElement>
                            ) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    createEvent(e);
                                }
                            }}
                        ></FormControl>
                        <Button
                            disabled={isProcessing}
                            variant="primary"
                            className="btn-create w-100"
                            onClick={createEvent}
                        >
                            CREATE
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalType={modalType}
                modalContent={modalContent}
                deleteFunction={() => {
                    deleteEvent(toDelete);
                }}
            />
        </>
    );
};

export default EventList;
