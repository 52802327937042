/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import {
  CreateGwGroupData,
  DeleteGwGroupByIdData,
  GatewayGroupPatch,
  GetGwGroupByIdData,
  GetGwGroupFirmwareByGwIdData,
  GetGwGroupsData,
  GroupFirmwareVersion,
  GroupPost,
  UpdateGwGroupByIdData,
  UpdateGwGroupFirmwareByGwIdData,
} from "../models";

export class Gatewaygroups<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags group
   * @name GetGwGroups
   * @summary Get all gateway group
   * @request GET:/gatewaygroups
   * @secure
   */
  getGwGroups = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetGwGroupsData, void>({
      path: `/gatewaygroups`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags group
   * @name CreateGwGroup
   * @summary Create a new gateway group
   * @request POST:/gatewaygroups
   * @secure
   */
  createGwGroup = (data: GroupPost, params: RequestParams = {}) =>
    this.http.request<CreateGwGroupData, void>({
      path: `/gatewaygroups`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags group
   * @name DeleteGwGroupById
   * @summary Delete gateway group by ID
   * @request DELETE:/gatewaygroups/{group_uuid}
   * @secure
   */
  deleteGwGroupById = (groupUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteGwGroupByIdData, void>({
      path: `/gatewaygroups/${groupUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags group
   * @name GetGwGroupById
   * @summary Get gateway group by ID
   * @request GET:/gatewaygroups/{group_uuid}
   * @secure
   */
  getGwGroupById = (groupUuid: string, params: RequestParams = {}) =>
    this.http.request<GetGwGroupByIdData, void>({
      path: `/gatewaygroups/${groupUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags group
   * @name UpdateGwGroupById
   * @summary Update gateway group by ID
   * @request PATCH:/gatewaygroups/{group_uuid}
   * @secure
   */
  updateGwGroupById = (groupUuid: string, data: GatewayGroupPatch, params: RequestParams = {}) =>
    this.http.request<UpdateGwGroupByIdData, void>({
      path: `/gatewaygroups/${groupUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ota
   * @name GetGwGroupFirmwareByGwId
   * @summary Get gateway group firmware upgrade statue
   * @request GET:/gatewaygroups/{group_uuid}/firmware
   * @secure
   */
  getGwGroupFirmwareByGwId = (groupUuid: string, params: RequestParams = {}) =>
    this.http.request<GetGwGroupFirmwareByGwIdData, void>({
      path: `/gatewaygroups/${groupUuid}/firmware`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ota
   * @name UpdateGwGroupFirmwareByGwId
   * @summary Request to upgrade gateway group firmware
   * @request POST:/gatewaygroups/{group_uuid}/firmware
   * @secure
   */
  updateGwGroupFirmwareByGwId = (groupUuid: string, data: GroupFirmwareVersion, params: RequestParams = {}) =>
    this.http.request<UpdateGwGroupFirmwareByGwIdData, void>({
      path: `/gatewaygroups/${groupUuid}/firmware`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
