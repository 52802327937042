import * as React from "react";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import styledConst from "styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PaginationItem from "@mui/material/PaginationItem";
import { faker } from "@faker-js/faker";

const StyledContainer = styled("div")`
    margin: 10px 0px;
    color: ${styledConst.secondTextColor};
    display: -webkit-flex;
    display: flex;
    list-style-type: none;
    padding: 0;
    justify-content: flex-end;

    @media screen AND (max-width: 576px) {
        font-size: 15px;
        flex-wrap: wrap;
    }

    .first-container {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: min-content;
    }

    .second-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 10px;
    }

    .third-container {
        float: right;
        min-width: fit-content;
        align-self: center;
    }
`;

const StyledFirstContainer = styled("div")``;

const StyledSecondContainer = styled("div")``;

const getStyledPaginationContainer = (pagination: any) => styled(Pagination)`
    .MuiPagination-ul {
        button {
            color: ${styledConst.secondTextColor};
            background: #2f3a51;
        }

        .MuiPaginationItem-ellipsis {
            color: ${styledConst.secondTextColor};
        }
    }

    .MuiPagination-ul li:nth-of-type(${pagination.page + 2}) {
        button {
            background: rgba(0, 203, 175, 1);
            color: #ffffff;
            pointer-events: none;
        }
    }
`;

export default function CustomPagination(props: any) {
    const { pagination, setPagination } = props;

    const onChange = (event: any, newPage: number) => {
        setPagination({
            ...pagination,
            loading: true,
            page: newPage - 1,
        });
    };

    const StyledPaginationContainer = getStyledPaginationContainer(pagination);

    return (
        <StyledContainer>
            <StyledFirstContainer className="first-container">
                Show{" "}
                <Select
                    value={`${pagination?.rowsPerPage}`}
                    variant="outlined"
                    onChange={(event: SelectChangeEvent) => {
                        setPagination({
                            ...pagination,
                            page: 0,
                            rowsPerPage: event.target.value,
                        });
                    }}
                >
                    {[5, 10, 20].map((item) => {
                        return (
                            <MenuItem
                                id={`${item}`}
                                value={item}
                                key={faker.datatype.uuid()}
                            >
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>{" "}
                entries
            </StyledFirstContainer>

            <StyledSecondContainer className="second-container">
                Showing {pagination.page * pagination.rowsPerPage + 1} to{" "}
                {Math.min(
                    (pagination.page + 1) * pagination.rowsPerPage,
                    pagination.count
                )}{" "}
                {pagination.count ? `of ${pagination.count} entries` : null}
            </StyledSecondContainer>

            <StyledPaginationContainer
                className="third-container"
                {...{
                    disabled: pagination.loading,
                    count: Math.ceil(pagination.count / pagination.rowsPerPage),
                    page: pagination.page + 1,
                    variant: "outlined",
                    shape: "rounded",
                    onChange,
                    renderItem: (item) => (
                        <PaginationItem
                            {...{
                                ...item,
                                components: {
                                    previous: () => <>Previous</>,
                                    next: () => <>Next</>,
                                },
                            }}
                        />
                    ),
                }}
            />
        </StyledContainer>
    );
}
