import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import type { AxiosResponse } from "axios";
import { get, isEmpty, isNull } from "lodash";
import { useHistory, Link } from "react-router-dom";
import create from "zustand";

const walletStatusUrlPattern = /^\/wallet\/status/;

type walletProps = {
    uuid: string;
    owner: string;
    tokens: {
        uuid: string;
        amount: number;
        wallet_uuid: string;
        expire_time?: string;
        [key: string]: any;
    }[];
    isSubscribe: boolean;
    isTooLow: boolean;
    subscriptionPlanType: "basic_monthly_plan" | "monthly_plan";
    [key: string]: any;
};

export const useWalletStore = create<{
    wallet: walletProps;
    setWallet: (response: AxiosResponse) => void;
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
}>((set) => ({
    wallet: {} as any,
    setWallet: (response) =>
        set((state: any) => {
            const urlObj = new URL(response.config.url!);
            if (urlObj.pathname.match(walletStatusUrlPattern)) {
                const walletStatus = get(response, "data.data", undefined);
                let status = {
                    isSubscribe: typeof walletStatus === "string",
                    isTooLow: false,
                    subscriptionPlanType: null,
                };

                if (!isNull(walletStatus)) {
                    const [walletStatus_, subscriptionPlanType] =
                        walletStatus.split(";");

                    status = {
                        ...status,
                        isTooLow: ["low", "zero"].includes(walletStatus_),
                        subscriptionPlanType,
                    };
                }
                return {
                    ...state,
                    wallet: {
                        ...state.wallet,
                        ...status,
                    },
                };
            } else {
                return state;
            }
        }),
    modalShow: false,
    setModalShow: (show: boolean) => set({ modalShow: show }),
}));

export const walletModalTrigger = (
    fn?: any,
    exceptionForSubscribed?: boolean
): void => {
    const { wallet, setModalShow } = useWalletStore.getState();

    if (exceptionForSubscribed && wallet.isSubscribe && wallet.isTooLow) {
        fn?.();
    } else if (!wallet.isSubscribe || wallet.isTooLow) {
        setModalShow(true);
        return;
    }

    fn?.();
};

enum ShowWarningWhen {
    Unsubscribed,
    LowBalance,
}

export const WalletAlertComponent: React.FunctionComponent<{
    showWarningWhen?: keyof typeof ShowWarningWhen;
    onHide?: () => void;
    [key: string]: any;
}> = (props) => {
    const history = useHistory();
    const { wallet, setModalShow } = useWalletStore.getState();
    const [show, setShow] = useState(false);
    const [content, setContent] = useState<{
        title?: string;
        body?: string;
    }>({
        title: "Out of Tokens",
        body: "Token balance is too low for action",
    });
    const [showUnsubscribe, setShowUnsubscribe] = useState(false);
    const [showLowBalance, setShowLowBalance] = useState(false);

    const updateState = (wallet: walletProps) => {
        if (
            props.showWarningWhen ===
            ShowWarningWhen[ShowWarningWhen.Unsubscribed]
        ) {
            setShowUnsubscribe(isEmpty(wallet) || wallet.isSubscribe === false);
        }

        if (
            props.showWarningWhen ===
            ShowWarningWhen[ShowWarningWhen.LowBalance]
        ) {
            setShowLowBalance(wallet.isTooLow);
        }

        if (wallet.isSubscribe && wallet.isTooLow) {
            setContent({
                title: "Tokens balance is too low",
                body: "Some actions may not complete.",
            });
        }
    };

    useEffect(() => {
        updateState(wallet);

        const unsubscribe = useWalletStore.subscribe((state) => {
            setShow(state.modalShow);
            updateState(state.wallet);
        });

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, wallet]);

    const handleClose = () => {
        setShow(false);
        setModalShow(false);
        props.onHide?.();
    };

    return (
        <>
            <Modal className="danger" backdrop="static" show={show} centered>
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons">error_outline</span>
                    </div>
                    <h3 className="mb-3">{content.title}</h3>
                    <p className="mb-3">{content.body}</p>
                    <Button variant="secondary" onClick={handleClose}>
                        CANCEL
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => history.push("/manage-subscription")}
                    >
                        {wallet.isSubscribe && wallet.isTooLow
                            ? "BUY MORE"
                            : "SUBSCRIBE NOW"}
                    </Button>
                </Modal.Body>
            </Modal>

            {showUnsubscribe || showLowBalance ? (
                <>
                    {showUnsubscribe && (
                        <Alert
                            variant="danger"
                            className={`w-100 d-flex justify-content-center ${
                                props.className || ""
                            }`}
                        >
                            Token balance is too low for action.{" "}
                            <Link
                                className="alert-danger ml-1"
                                to="/manage-subscription"
                            >
                                <u>Subscribe Now</u>
                            </Link>
                        </Alert>
                    )}

                    {showLowBalance && (
                        <Alert
                            variant="danger"
                            className={`w-100 d-flex justify-content-center ${
                                props.className || ""
                            }`}
                        >
                            Token balance is too low. Some actions may not
                            complete.
                        </Alert>
                    )}
                </>
            ) : (
                <div className={props.className || ""} />
            )}
        </>
    );
};

export {};
