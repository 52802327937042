import { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";

import SubscribeDirect from "components/subscriptions/SubscribeDirect";
import SubscriptionBalanceDetails from "components/subscriptions/SubscriptionBalanceDetails";
import TokenSpending from "components/subscriptions/TokenSpending";
import { AppContext } from "context/appContext";
import {
    updateSubscriptionStatus,
    storeSubscriptionAction,
    storeSubscriptionID,
} from "store/actions";
import moment from "moment";
import {
    getSubscriptionInfo,
    deleteUnpaidSubscription,
} from "service/subscriptionService";
import { FESubscriptionStatus, PlanType, SubscriptionStatus } from "constant";
import { useSubscriptionContext } from "context/subscriptionContext";
import { Subscription } from "generated/models";
import { isHttpSuccess } from "utils/functions";
import {
    SubscriptionPlanType,
    getSubscriptionPlanType,
} from "components/subscriptions/CurrentPlanInformation";
import withSubscriptionPermissionCheck from "components/hoc/SubscriptionPermissionCheck";

// Added temporary type as BE has not included this status in swagger
type NewSubscription = Omit<Subscription, "status"> & {
    status: SubscriptionStatus & "PAST_DUE";
};

const Subscriptions = () => {
    const { storeDispatchActions } = useContext(AppContext);
    const [currentSubscription, setCurrentSubscription]: any = useState({});
    const [allSubscription, setAllSubscription]: any = useState({});
    const [subscriptionStatus, setSubscriptionStatus]: any = useState("");
    const [billingEndDate, setBillingEndDate] = useState(moment());
    const [refreshKey, setRefreshKey] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const MODAL_ERROR = "error";

    const subscriptionType = getSubscriptionPlanType({
        is_prepaid: currentSubscription?.customer?.is_prepaid,
        is_cancellable: currentSubscription?.customer?.is_cancellable,
    });

    const { setSubscription }: any = useSubscriptionContext();

    useEffect(() => {
        const fetchCurrentPlan = async () => {
            const subscriptionRes: any = await getSubscriptionInfo();

            if (isHttpSuccess(subscriptionRes.status)) {
                // delete all unused subscriptions
                const subscriptions_: NewSubscription[] = [];
                for (const subscription of subscriptionRes.data) {
                    if (
                        subscription.type_ === PlanType.SUBSCRIPTION &&
                        subscription.status === SubscriptionStatus.CREATED
                    ) {
                        await deleteUnpaidSubscription(subscription.uuid);
                    } else {
                        subscription.type_ === PlanType.SUBSCRIPTION &&
                            subscriptions_.push(subscription);
                    }
                }

                setAllSubscription(subscriptions_);

                // get latest subscription
                const sortedSubscriptionHistory = [...subscriptions_].sort(
                    (a: any, b: any) =>
                        b.create_time.localeCompare(a.create_time)
                );
                storeDispatchActions(
                    storeSubscriptionAction(sortedSubscriptionHistory)
                );

                const latestSubscription: NewSubscription =
                    sortedSubscriptionHistory[0];
                setCurrentSubscription(latestSubscription);
                setSubscription(latestSubscription);

                if (latestSubscription?.status === SubscriptionStatus.ACTIVE) {
                    setSubscriptionStatus(FESubscriptionStatus.SUBSCRIBED);
                    storeDispatchActions(
                        updateSubscriptionStatus({
                            isSubscribed: true,
                            hasSubscribed: true,
                        })
                    );
                    storeDispatchActions(
                        storeSubscriptionID(latestSubscription.uuid)
                    );

                    setBillingEndDate(
                        moment(latestSubscription.billing_period_end_date)
                    );
                } else if (
                    latestSubscription?.status === SubscriptionStatus.CANCELED
                ) {
                    let billingEndDate: any;

                    for (const s of subscriptions_) {
                        if (s.next_billing_date) {
                            billingEndDate = moment(s.next_billing_date).endOf(
                                "day"
                            );
                            break;
                        }
                    }
                    setBillingEndDate(billingEndDate);

                    setSubscriptionStatus(
                        moment() <= billingEndDate
                            ? FESubscriptionStatus.PENDING_CANCEL
                            : FESubscriptionStatus.CANCELLATION_PERIOD_ENDED
                    );

                    storeDispatchActions(
                        updateSubscriptionStatus({
                            isSubscribed: moment() <= billingEndDate,
                            hasSubscribed: true,
                        })
                    );
                } else if (
                    [
                        SubscriptionStatus.EXPIRED,
                        SubscriptionStatus.PAST_DUE,
                    ].includes(latestSubscription?.status)
                ) {
                    setSubscriptionStatus(
                        latestSubscription?.status ===
                            SubscriptionStatus.EXPIRED
                            ? FESubscriptionStatus.EXPIRED
                            : FESubscriptionStatus.PAST_DUE
                    );
                    storeDispatchActions(
                        updateSubscriptionStatus({
                            isSubscribed: false,
                            hasSubscribed: true,
                        })
                    );

                    const billingEndDate = moment(
                        latestSubscription.next_billing_date
                    ).endOf("day");
                    setBillingEndDate(billingEndDate);
                } else {
                    setSubscriptionStatus(FESubscriptionStatus.UNSUBSCRIBED);
                    storeDispatchActions(
                        updateSubscriptionStatus({
                            isSubscribed: false,
                            hasSubscribed: false,
                        })
                    );
                }
            } else {
                setModalShow(true);
                setModalType(MODAL_ERROR);
                setModalContent("Something went wrong. Please try again.");
            }
        };
        fetchCurrentPlan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshKey, storeDispatchActions]);

    const refreshComponent = () => {
        setRefreshKey(refreshKey + 1);
    };

    const renderModalIcon = () => {
        if (modalType === MODAL_ERROR) {
            return <span className="material-icons">warning</span>;
        }
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        }
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR) {
            return (
                <Button variant="primary" onClick={() => setModalShow(false)}>
                    OK
                </Button>
            );
        }
    };

    return (
        <>
            <div className="page-content subscription-page">
                <Container fluid>
                    <Row>
                        <Col>
                            <h5 className="page-title">Subscription</h5>
                        </Col>
                    </Row>
                </Container>

                {/* //////Before Subscribe//////// */}
                {subscriptionStatus === FESubscriptionStatus.UNSUBSCRIBED ? (
                    <SubscribeDirect />
                ) : (
                    ""
                )}

                {/* ////////After Subscribe////// */}
                {[
                    FESubscriptionStatus.SUBSCRIBED,
                    FESubscriptionStatus.RESUBSCRIBED,
                    FESubscriptionStatus.PENDING_CANCEL,
                    FESubscriptionStatus.CANCELLATION_PERIOD_ENDED,
                    FESubscriptionStatus.EXPIRED,
                    FESubscriptionStatus.PAST_DUE,
                ].includes(subscriptionStatus) ? (
                    <>
                        <SubscriptionBalanceDetails
                            refresh={refreshComponent}
                            subscriptionStatus={subscriptionStatus}
                            currentSubscription={currentSubscription}
                            billingEndDate={billingEndDate}
                            allSubscription={allSubscription}
                            subscriptionType={subscriptionType}
                        />
                        {subscriptionType !== SubscriptionPlanType.BASIC && (
                            <TokenSpending />
                        )}
                    </>
                ) : (
                    ""
                )}
            </div>
            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    modalType === MODAL_ERROR ? "danger" : "primary"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>
                    {renderModalButton()}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default withSubscriptionPermissionCheck(Subscriptions);
