import {
    SECONDS_PER_DAY,
    TimeGapToNowList,
    defaultChartRefreshRate,
} from "constant";
import { inRange } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import dashboardService from "service/dashboardService";
import { DISABLE_MISSING_DATA } from "utils/config";
import { convertPrometheusTimeToSeconds, isHttpSuccess } from "utils/functions";
const time_range_2_disable_missing_data = Number.MAX_VALUE;

type TimeRangeConfig = {
    showCandlestickChart: boolean;
    showAggregated: boolean;
    INTVL: number;
    time_range: string;
    query_time: string | number | null;
};

type SelectChartTimeRange = {
    initial_time_range: any;
    initial_query_time: any;
    setRefreshRate: React.Dispatch<any>;
    dashboardId: string;
    panelId: string;
};

const useSelectChartTimeRange = ({
    initial_time_range,
    initial_query_time,
    setRefreshRate,
    dashboardId,
    panelId,
}: SelectChartTimeRange) => {
    const [lastTimeRangeSelect, setLastTimeRangeSelect] =
        useState<TimeRangeConfig>({
            showCandlestickChart: false,
            showAggregated: false,
            INTVL: DISABLE_MISSING_DATA
                ? time_range_2_disable_missing_data
                : defaultChartRefreshRate,
            time_range: initial_time_range,
            query_time: initial_query_time,
        });

    useEffect(() => {
        // For Backward Compatibility
        convertToRecognizedTimeRange();
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [initial_time_range, initial_query_time]);

    const convertToRecognizedTimeRange = async () => {
        const currentTimeRangeSelection = [
            "15m",
            "30m",
            "1h",
            "1d",
            "4w2d",
            "12w6d",
            "25w5d",
        ];

        const timerangeSelectionInSecondsArray = currentTimeRangeSelection.map(
            (time) => convertPrometheusTimeToSeconds(time)
        );

        if (
            !initial_query_time &&
            !timerangeSelectionInSecondsArray.includes(
                convertPrometheusTimeToSeconds(initial_time_range)
            )
        ) {
            const DEFAULT_CHART_PAYLOAD_CONFIG = {
                time_range: "15m",
                query_time: 0,
            };
            const panelRes = await dashboardService.updatePanel(
                dashboardId,
                panelId,
                DEFAULT_CHART_PAYLOAD_CONFIG
            );

            if (isHttpSuccess(panelRes.status)) {
                setLastTimeRangeSelect({
                    ...lastTimeRangeSelect,
                    ...DEFAULT_CHART_PAYLOAD_CONFIG,
                });
            }
        }
    };

    useEffect(() => {
        if (
            convertPrometheusTimeToSeconds(lastTimeRangeSelect.time_range) >=
                SECONDS_PER_DAY ||
            Number(lastTimeRangeSelect.query_time) > 0
        ) {
            (async () => {
                const panelRes = await dashboardService.updatePanel(
                    dashboardId,
                    panelId,
                    {
                        refresh_rate: "0s",
                    }
                );
                if (isHttpSuccess(panelRes.status)) {
                    setRefreshRate({ seconds: 0 });
                }
            })();
        }
    }, [
        lastTimeRangeSelect.time_range,
        lastTimeRangeSelect.query_time,
        dashboardId,
        panelId,
        setRefreshRate,
    ]);

    const getStartDateTime = () =>
        getEndDateTime()
            .clone()
            .subtract(
                convertPrometheusTimeToSeconds(lastTimeRangeSelect.time_range),
                "seconds"
            );

    const getEndDateTime = () =>
        lastTimeRangeSelect.query_time
            ? moment.unix(Number(lastTimeRangeSelect.query_time))
            : moment();

    const getTimeGapObject = (timeValue: number) => {
        const timeGapObj = TimeGapToNowList.find(({ min, max }) => {
            const inclusiveMax: number = Number(max) + 1;
            if (
                (!max && timeValue >= min) ||
                inRange(timeValue, min, inclusiveMax)
            )
                return true;

            return false;
        });

        return timeGapObj;
    };

    return {
        lastTimeRangeSelect,
        setLastTimeRangeSelect,
        getStartDateTime,
        getEndDateTime,
        getTimeGapObject,
    };
};

export default useSelectChartTimeRange;
