import { useState, useEffect, MouseEvent, KeyboardEvent } from "react";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    InputGroup,
    FormControl,
    Form,
    Modal,
    Spinner,
} from "react-bootstrap";
import { cloneDeep, get } from "lodash";

import {
    getAllLastBusReading,
    getSensorLastReading,
    updateAlertNotification,
} from "service/gatewayService";

import refreshIcon from "assets/svg/refresh-green.svg";
import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import { WalletAlertComponent, walletModalTrigger } from "hooks/wallet";
import useCollectSort from "hooks/useCollectSort";
import { formatReading, getSensorTypes } from "utils/gatewayFunctions";
import { convertSecondsToPrometheusTime, isHttpSuccess } from "utils/functions";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import moment, { Moment } from "moment";
import {
    CHART_CUSTOM_RANGE_OPTION,
    CalendarFormat,
    LastSixMonthsTimeSelection,
} from "constant";
import SensorIcon from "./SensorIcon";
import { SensorTypeDropdown } from "./SensorTypeDropdown";
import TimeRangeDropdown, {
    TimeRangeSelection,
} from "components/common/TimeRangeDropdown";
import styled from "@emotion/styled";
import { useCheckIfBasicPlan } from "hooks/useCheckIfBasicPlan";

type TimeRangeQuery = {
    timeRange?: string;
    offset?: string;
};

const CustomCol = styled(Col)`
    height: auto !important;
`;

const DefaultTimeRange = LastSixMonthsTimeSelection[6].time;

const SensorList = (props: any) => {
    const {
        sensors,
        alertUUIDList,
        setAlertUUIDList,
        UUID: gatewayId,
        portNumber: busNumber,
    } = props;

    const timeRanges = LastSixMonthsTimeSelection.reduce<
        Record<string, Moment[]>
    >((acc, timeSelection) => {
        const { time, timeGapToNow } = timeSelection;
        acc[`${time}`] = [moment().subtract(timeGapToNow, "seconds"), moment()];
        return acc;
    }, {});

    const [initialSensorList, setInitialSensorList] = useState<any[]>([]);
    const [sensorList, setSensorList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [sensorTypes, setSensorTypes] = useState<any[]>([]);
    const [selectedSensorTypes, setSelectedSensorTypes] = useState<any[]>([]);
    const [busLastReadings, setBusLastReadings] = useState<any[]>([]);
    const [searchText, setSearchText] = useState("");
    const [timeRangeSelected, setTimeRangeSelected] =
        useState<TimeRangeSelection>({
            range: DefaultTimeRange,
            startDate: timeRanges[DefaultTimeRange][0],
            endDate: timeRanges[DefaultTimeRange][1],
        });
    const [timeRangeQuery, setTimeRangeQuery] = useState<TimeRangeQuery>({
        timeRange: "24h",
    });
    const { data: sensorCollect, sortIcon } = useCollectSort(sensorList);
    const { IS_BASIC_PLAN } = useCheckIfBasicPlan();

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sensors]);

    const init = async () => {
        const reading = await fetchReading(
            timeRangeQuery.timeRange,
            timeRangeQuery.offset
        );
        const sensorMapReadings = mapListWithReadings(reading);
        setInitialSensorList(sensorMapReadings);
        setSensorList(sensorMapReadings);
        setSensorTypes(getSensorTypes(sensors));
    };
    const fetchReading = async (timeRange?: string, offset?: string) => {
        let readings = [];
        const { status, data } = await getAllLastBusReading(
            gatewayId,
            busNumber,
            timeRange,
            offset
        );
        if (isHttpSuccess(status)) {
            const { devices = [] } = data;
            setBusLastReadings(devices);
            readings = devices;
        }

        return readings;
    };

    const mapListWithReadings = (lastReadings?: any[]) => {
        const readings = lastReadings ?? busLastReadings;
        return sensors.map((i: any) => {
            readings?.forEach((j: any) => {
                if (i.ldsu_uuid === j.UID) {
                    let readings = {
                        highest: get(j.max, `[${i.SAID}]`, "-"),
                        lowest: get(j.min, `[${i.SAID}]`, "-"),
                        timestamp: j.ts,
                        value: get(j.value, `[${i.SAID}]`, "-"),
                        minmax: [
                            get(j.max, `[${i.SAID}]`, 0),
                            get(j.min, `[${i.SAID}]`, 0),
                        ],
                    };

                    i["readings"] = readings;
                }
            });
            return i;
        });
    };

    const filterSensor = (sensors: any, search: string, types: string[]) => {
        setSensorList(
            initialSensorList
                .filter(
                    (s: any) =>
                        search.length === 0 ||
                        s.sensor_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                )
                .filter(
                    ({ sensor_type: _sensorType, SAID, APP }: any) =>
                        types.length === 0 ||
                        types.includes(_sensorType) ||
                        types.includes(get(APP, `[${SAID}].NAME`))
                )
        );
    };

    const refreshAllSensorList = async () => {
        setIsRefreshing(true);
        const reading = await fetchReading(
            timeRangeQuery.timeRange,
            timeRangeQuery.offset
        )

        const sensorMapReadings = mapListWithReadings(reading);
        setInitialSensorList(sensorMapReadings);
        filterSensor(sensorMapReadings, searchText, selectedSensorTypes);

        setIsRefreshing(false);
    };

    const refreshOneSensor = async (
        ldsuUUID: string,
        SAID: number,
        index: number
    ) => {
        const { data, status } = await getSensorLastReading(
            gatewayId,
            ldsuUUID,
            timeRangeQuery.timeRange,
            timeRangeQuery.offset
        );
        if (isHttpSuccess(status)) {
            const cloned = [...initialSensorList];
            const reading = {
                highest: data.max[SAID],
                lowest: data.min[SAID],
                timestamp: data.ts,
                value: data.value[SAID],
            };
            cloned[index].readings = reading;
            setInitialSensorList(cloned);
            filterSensor(cloned, searchText, selectedSensorTypes);
        }
    };

    const updateAlertStatus = async (source: string, said: string) => {
        setIsLoading(true);

        let thisAlertUUID;
        let thisAlertStatus;
        let thisGatewayUUID;
        let thisIndex: any;
        for (let i = 0; i < alertUUIDList.length; i++) {
            if (
                source === alertUUIDList[i].source &&
                said === alertUUIDList[i].said
            ) {
                thisAlertUUID = alertUUIDList[i].uuid;
                thisAlertStatus = alertUUIDList[i].status;
                thisGatewayUUID = alertUUIDList[i].gatewayUUID;
                thisIndex = i;
            }
        }
        if (thisAlertUUID) {
            // if this index have alert, allow patch
            const updateResponse: any = await updateAlertNotification(
                thisGatewayUUID,
                source,
                said,
                thisAlertUUID,
                { enabled: !thisAlertStatus }
            );
            if (isHttpSuccess(updateResponse.status)) {
                const newAlertList = cloneDeep(alertUUIDList);
                newAlertList[thisIndex].status = !thisAlertStatus;
                setAlertUUIDList(newAlertList);

                showSuccessAlert({
                    message: `Alert has been ${
                        thisAlertStatus ? "disabled" : "enabled"
                    }. It will take up to 2 minutes for the operation to take effect.`,
                });
            } else {
                showErrorAlert({
                    title: updateResponse.data.title,
                    message: updateResponse.data.description,
                });
            }
            setIsLoading(false);
        } else {
            // else, tell user to configure the sensor first.
            setIsLoading(false);
            showErrorAlert({
                message: `Sensor is not yet configured.`,
            });
        }
    };

    const returnCheckboxStatus = (source: string, said: number) => {
        for (let i = 0; i < alertUUIDList.length; i++) {
            if (
                source === alertUUIDList[i].source &&
                said === alertUUIDList[i].said
            ) {
                return alertUUIDList[i].status;
            }
        }
        return false;
    };

    const returnDisabledStatus = (source: string, said: number) => {
        for (let i = 0; i < alertUUIDList.length; i++) {
            if (
                source === alertUUIDList[i].source &&
                said === alertUUIDList[i].said
            ) {
                return false;
            }
        }
        return true;
    };

    const renderUnits = (units: string) => {
        if (units === "C") {
            return "°C";
        } else if (units === "%") {
            return units;
        } else {
            return " " + units;
        }
    };

    const renderDateTime = (reading: any) => {
        if (reading) {
            return moment(reading.timestamp, "X").calendar(CalendarFormat);
        } else {
            return <>Offline</>;
        }
    };

    const handleRefreshSensor = async (
        e: MouseEvent<HTMLImageElement> | KeyboardEvent<HTMLImageElement>,
        sensor: any,
        index: number
    ) => {
        e.currentTarget.classList.add("refresh-state");
        await refreshOneSensor(sensor.ldsu_uuid, sensor.SAID, index);

        document
            .querySelectorAll(".refresh-sensor")
            [index].classList.remove("refresh-state");
    };

    const searchSensor = (value: string) => {
        filterSensor(initialSensorList, value, selectedSensorTypes);
        setSearchText(value);
    };

    const handleFilterType = (types: string[]) => {
        setSelectedSensorTypes(types);
        filterSensor(initialSensorList, searchText, types);
    };

    const handleTimeRangeChange = async ({
        range,
        startDate,
        endDate,
    }: TimeRangeSelection) => {
        let lastTimeValue = Math.round(
            moment.duration(endDate.diff(startDate)).asSeconds()
        );
        let offsetTime;

        if (range === CHART_CUSTOM_RANGE_OPTION) {
            lastTimeValue = Math.round(
                moment.duration(moment().diff(endDate)).asSeconds()
            );

            offsetTime = convertSecondsToPrometheusTime(lastTimeValue);
            lastTimeValue = moment
                .duration(endDate.diff(startDate))
                .asSeconds();
        }

        const lastTime = convertSecondsToPrometheusTime(lastTimeValue);

        const readings = await fetchReading(lastTime, offsetTime);
        const sensorMapReadings = mapListWithReadings(readings);
        setInitialSensorList(sensorMapReadings);
        
        filterSensor(sensorMapReadings, searchText, selectedSensorTypes);

        setTimeRangeQuery({
            timeRange: lastTime,
            offset: offsetTime,
        });
        setTimeRangeSelected({
            range,
            startDate,
            endDate,
        });
    };

    const renderSensors = () => {
        const lst = sensorCollect.map((i: any, index: number) => {
            return (
                <div className="table-row" key={`${i.ldsu_uuid}-${i.SAID}`}>
                    <Row className="no-checkbox">
                        <Col
                            md
                            xs={{ span: 11, order: 1 }}
                            className="mb-2 mb-md-0"
                        >
                            <SensorIcon
                                className="mr-2"
                                sensorType={i.sensor_type}
                            />
                            <Link
                                to={{
                                    pathname: `/sensor-details/${gatewayId}/LDSBus/${busNumber}/${i.ldsu_uuid}/${i.SAID}`,
                                    state: {
                                        sensorDetails: i,
                                        gatewayName: props.gatewayName,
                                        portNumber: props.portNumber,
                                        UUID: props.UUID,
                                    },
                                }}
                            >
                                {i.sensor_name}
                            </Link>
                        </Col>
                        <Col
                            md={{ order: 3, offset: 0 }}
                            xs={{ span: 11, order: 3 }}
                            className="mt-2 mt-md-0"
                        >
                            {i.sensor_type}
                        </Col>
                        <Col
                            className="reading"
                            md={{ order: 4, offset: 0, span: 2 }}
                            xs={{ span: 11, offset: 0, order: 4 }}
                        >
                            <span className="reading-label">Reading: </span>
                            {i.readings
                                ? formatReading(i.readings.value, i.accuracy) +
                                  renderUnits(i.unit)
                                : "-"}
                        </Col>
                        <CustomCol
                            md={{ offset: 0, span: 2 }}
                            xs={{ span: 11, offset: 0, order: 6 }}
                        >
                            <span className="reading-label">Min / Max: </span>
                            {i.readings
                                ? `${formatReading(
                                      i.readings.lowest,
                                      i.accuracy
                                  )} / ${formatReading(
                                      i.readings.highest,
                                      i.accuracy
                                  )} ${renderUnits(i.unit)}`
                                : "-"}
                            <span className="d-inline-block d-md-none ml-1">
                                ({timeRangeSelected.range})
                            </span>
                        </CustomCol>
                        <Col
                            md={{ offset: 0, span: 2 }}
                            xs={{ span: 10, offset: 0, order: 7 }}
                        >
                            {renderDateTime(i.readings)}
                            <img
                                className="mr-2 refresh-sensor"
                                src={refreshIcon}
                                alt="refresh"
                                onClick={(e) =>
                                    handleRefreshSensor(e, i, index)
                                }
                                onKeyDown={(e) =>
                                    handleRefreshSensor(e, i, index)
                                }
                            ></img>
                        </Col>

                        <Col
                            md={{ span: 1 }}
                            xs={{ span: "auto", order: "last" }}
                        >
                            <HoverAuthorizeTooltip permission="gateway:update">
                                <div>
                                    <Form.Check
                                        type="switch"
                                        id={`switch-${i.ldsu_uuid}-${i.SAID}`}
                                        label=""
                                        checked={returnCheckboxStatus(
                                            i.ldsu_uuid,
                                            i.SAID
                                        )}
                                        disabled={returnDisabledStatus(
                                            i.ldsu_uuid,
                                            i.SAID
                                        )}
                                        onChange={(e: any) => {
                                            if (
                                                !returnCheckboxStatus(
                                                    i.ldsu_uuid,
                                                    i.SAID
                                                )
                                            ) {
                                                walletModalTrigger(() =>
                                                    updateAlertStatus(
                                                        i.ldsu_uuid,
                                                        i.SAID
                                                    )
                                                );
                                            } else {
                                                updateAlertStatus(
                                                    i.ldsu_uuid,
                                                    i.SAID
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </HoverAuthorizeTooltip>
                        </Col>

                        <Col
                            md={{ span: 1, order: "last" }}
                            xs={{ span: 1, order: 2 }}
                        >
                            <Link
                                to={{
                                    pathname: `/sensor-details/${gatewayId}/LDSBus/${busNumber}/${i.ldsu_uuid}/${i.SAID}`,
                                    state: {
                                        sensorDetails: i,
                                        gatewayName: props.gatewayName,
                                        portNumber: props.portNumber,
                                        UUID: props.UUID,
                                    },
                                }}
                            >
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </Link>
                        </Col>
                    </Row>
                </div>
            );
        });
        if (lst.length === 0) {
            lst.push(
                <div key={"no-sensor"} className="mt-5 text-center">
                    No Sensors to display
                </div>
            );
        }
        return lst;
    };

    return (
        <>
            <Row className="mt-4 justify-content-md-end">
                <Col
                    xs={{ order: 1, span: "auto" }}
                    sm={{ order: 1, span: "auto" }}
                >
                    <SensorTypeDropdown
                        sensorTypes={sensorTypes}
                        onChange={handleFilterType}
                        selected={selectedSensorTypes}
                        className="mr-2 order-1 order-sm-0"
                    />
                </Col>
                <Col
                    xs={{ order: "last" }}
                    sm={{ order: 2, span: "auto" }}
                    md={{ order: 2, span: "auto" }}
                    className="d-xl-none ml-auto ml-sm-0 text-right text-sm-left"
                >
                    <div className="ml-0 mr-0 mr-md-2">
                        Min / Max
                        <TimeRangeDropdown
                            className="d-inline-block ml-2"
                            selectedTimeRange={timeRangeSelected.range}
                            startDate={timeRangeSelected.startDate}
                            endDate={timeRangeSelected.endDate}
                            onChange={handleTimeRangeChange}
                            maxSpan={{
                                months: 6,
                            }}
                            ranges={timeRanges}
                        />
                    </div>
                </Col>
                <Col
                    sm={{ order: 0, span: 12 }}
                    md={{ order: 3, span: "auto" }}
                >
                    <div className="mb-2 mb-md-0">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                onChange={(e) => searchSensor(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Row className="cstm-table mt-4">
                <Col sm={12}>
                    {!IS_BASIC_PLAN && (
                        <WalletAlertComponent showWarningWhen="LowBalance" />
                    )}
                </Col>
                <Col sm={12}>
                    <div className="table-head">
                        <Row className="no-checkbox">
                            <Col>Sensor Name {sortIcon("sensor_name")}</Col>
                            <Col>Type {sortIcon("sensor_type")}</Col>
                            <Col sm="2">
                                Reading {sortIcon("readings.value")}
                            </Col>
                            <Col sm="2">
                                Min / Max
                                <TimeRangeDropdown
                                    className="d-inline-block d-lg-none d-xl-inline-block ml-2"
                                    selectedTimeRange={timeRangeSelected.range}
                                    startDate={timeRangeSelected.startDate}
                                    endDate={timeRangeSelected.endDate}
                                    onChange={handleTimeRangeChange}
                                    maxSpan={{
                                        months: 6,
                                    }}
                                    ranges={timeRanges}
                                />
                                {sortIcon("readings.minmax")}
                            </Col>
                            <Col sm="2">
                                Last Active
                                <img
                                    className={
                                        isRefreshing
                                            ? "mr-2 bigRefresh refresh-animation"
                                            : "mr-2 bigRefresh"
                                    }
                                    onClick={refreshAllSensorList}
                                    onKeyDown={refreshAllSensorList}
                                    src={refreshIcon}
                                    alt="refresh"
                                ></img>
                                {sortIcon("readings.timestamp")}
                            </Col>
                            <Col sm="1">Alert</Col>
                            <Col sm="1"></Col>
                        </Row>
                    </div>
                    {renderSensors()}
                </Col>
            </Row>

            <Modal
                centered
                show={isLoading}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-5 mr-4">
                    <Spinner
                        className="centered-spinner"
                        animation="border"
                        variant="primary"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SensorList;
