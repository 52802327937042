import { FC } from "react";
import { Col, Form } from "react-bootstrap";

type HelpTextProps = {
    min: number;
    max: number;
    timeUnit: string;
};

export const HelpText: FC<HelpTextProps> = ({ min, max, timeUnit }) => {
    return (
        <Form.Row>
            <Col className="mt-2 ml-1 text-primary-blue-4">
                {`Minimum ${min} ${timeUnit} and maximum ${max} ${timeUnit}s.`}
            </Col>
        </Form.Row>
    );
};
