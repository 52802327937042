import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { getAllPlans } from "service/subscriptionService";
import { CheckoutType } from "constant";
import TokenConversionLink from "./TokenConversionLink";
import "assets/css/subscription.css";
import { isHttpSuccess } from "utils/functions";

const SubscribeDirect = () => {
    const history = useHistory();
    const [planUUID, setPlanUUID] = useState("");
    const [planInfo, setPlanInfo] = useState({
        amount: "",
        tokenAmount: "",
    });
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const MODAL_ERROR = "error";

    useEffect(() => {
        const fetchPlans = async () => {
            const response: any = await getAllPlans();
            if (isHttpSuccess(response.status)) {
                for (const plan of response.data) {
                    if (plan.bt_id === "monthly_plan") {
                        setPlanInfo({
                            amount: plan.amount,
                            tokenAmount: plan.addons[0].content.token.amount,
                        });
                        setPlanUUID(plan.uuid);
                        return;
                    }
                }
            } else {
                setModalShow(true);
                setModalType(MODAL_ERROR);
                setModalContent("Something went wrong. Please try again.");
            }
        };
        fetchPlans();
    }, []);

    const closeModal = () => {
        setModalShow(false);
    };

    const renderModalIcon = () => {
        if (modalType === MODAL_ERROR) {
            return <span className="material-icons">warning</span>;
        }
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        }
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR) {
            return (
                <Button variant="primary" onClick={closeModal}>
                    OK
                </Button>
            );
        }
    };

    const proceedToBillingAddressAndCheckoutPage = () => {
        history.push("/checkout/billing-address", {
            checkoutType: CheckoutType.PLAN,
            planUUID: planUUID,
        });
    };

    return (
        <Container fluid className="mb-3">
            <Row>
                <Col>
                    <div className="form-box subscribe-direct-box">
                        <Row className="justify-content-md-center">
                            <Col md={6} xs={12}>
                                <h4>
                                    Subscribe to get the very best of IoTPortal
                                </h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col
                                md={4}
                                xs={12}
                                className="subscription-summary"
                            >
                                <p>Subscription includes: </p>
                                <p className="summary-item">
                                    <span className="material-icons check">
                                        done
                                    </span>
                                    Get{" "}
                                    <span>
                                        {planInfo.tokenAmount.toLocaleString()}
                                    </span>{" "}
                                    Tokens every month
                                </p>
                                <p className="summary-item">
                                    <span className="material-icons check">
                                        done
                                    </span>
                                    Use Tokens to
                                    <span>
                                        {" "}
                                        get dashboard data, send alerts (email,
                                        notifications, SMS), and download data.{" "}
                                    </span>
                                </p>
                                <TokenConversionLink className="billing-link" />
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md={6} xs={12}>
                                <Button
                                    variant="primary"
                                    className="btn-start-trial mb-4"
                                    onClick={() => {
                                        proceedToBillingAddressAndCheckoutPage();
                                    }}
                                >
                                    Subscribe
                                </Button>

                                <p className="default-white-color">
                                    {" "}
                                    <span className="text-primary-green-1">
                                        {planInfo.tokenAmount.toLocaleString()}
                                    </span>{" "}
                                    Tokens every month • Recurring billing •
                                    Cancel anytime
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Modal
                centered
                show={modalShow}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    modalType === MODAL_ERROR ? "danger" : "primary"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>
                    {renderModalButton()}
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default SubscribeDirect;
