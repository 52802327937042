import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";

import check from "assets/svg/success.svg";

const VerificationSuccess = (props: any) => {
    return (
        <Col lg="6" sm="12" className="login-form">
            <div className="login-box">
                <div className="login-table">
                    <div className="login-text">
                        <img src={check} alt="" />
                        <h4 className="mt-4">Verified Successfully</h4>
                    </div>
                    <div className="mt-5 mb-5">
                        Your account has been verified successfully. Login to
                        continue
                    </div>
                    <Link to="/" className="btn btn-primary">
                        LOGIN
                    </Link>
                </div>
            </div>
        </Col>
    );
};

export default VerificationSuccess;
