import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as _ from "lodash";
import { faker } from "@faker-js/faker";
import {
    useCreateDashboardStore,
    ColorPicker,
    getMQTTQueryByDevice,
    CreateChartState,
    UnitPicker,
    CHART_NAME_PATTERN,
    CHART_ERROR_MESSAGE,
} from ".";
import DashboardCreateSelectSensorModal from "./modal/SelectDevices";
import CreateChartModal from "./modal/CreateChart";
import singleChartSvg from "assets/svg/single-sensor-chart.svg";
import multipleChartSvg from "assets/svg/multiple-sensor-chart.svg";
import { COLORS, SensorChartTypeEnum } from "constant";
import {
    displaySensorType,
    getPrimaryUnit,
    getUnitSymbolArray,
    parseColorToHex,
} from "utils/functions";

import { BlockKeyProps } from "types/Charts";
import { MultipleChartBlock } from "./MultipleChartBlock";
import { ColorPickerBackground } from "components/common";

const maxDevicesAllowed = 8;

const SingleSensorCreateData: CreateChartState = {
    title: "Creating Single Sensor Charts...",
    singleSensor: true,
    data: [],
    columns: [
        {
            key: "chart_name",
            name: "Chart Name",
        },
        {
            key: "device_name",
            name: "Sensor",
        },
        {
            key: "sensor_type",
            name: "Type",
        },
        {
            key: "gatewayName",
            name: "Gateway",
        },
        {
            key: "ldsu_uuid",
            name: "LDSU UUID",
            className: "flex-basis-220px",
            render: (data: any) => (
                <div className="text-nowrap text-white">{data.ldsu_uuid}</div>
            ),
        },
        {
            key: "unit",
            name: "Unit",
            className: "flex-basis-120px",
        },
        {
            key: "color",
            name: "Color",
            render: (data: any) => (
                <div className="color-picker__toggle">
                    <ColorPickerBackground
                        $inputColor={parseColorToHex(data.color)}
                    />
                </div>
            ),
        },
        {
            key: "alert",
            name: "Alert line",
            render: (data: any) => (
                <Form.Check custom type="switch" checked={data.alert} />
            ),
        },
    ],
    transform: (data: any) => ({
        attributes: [
            {
                color: data.color,
                device_id: data.ldsu_uuid,
                gateway_id: data.gatewayId,
                name: data.sensor_type,
                said: data.SAID,
                show_alert: data.alert || false,
                time_series: getMQTTQueryByDevice(data),
                unit: data.unit,
            },
        ],
        source: "SENSOR",
        name: data.chart_name || "Single Chart",
        chart: "TIME",
    }),
    validate() {
        return this.data.map((item) => ({
            path: `[name="${item.device_id}.chart_name"]`,
            isValid: CHART_NAME_PATTERN.test(item.chart_name.trim()),
            message: CHART_ERROR_MESSAGE,
        }));
    },
};

export const MultipleSensorCreateData: CreateChartState = {
    title: "Creating Multiple Sensor Charts...",
    multiple: true,
    data: [],
    columns: [
        {
            key: "chart_name",
            name: "Chart Name",
        },
        {
            key: "type",
            name: "Sensor Type",
        },
        {
            key: "sensors_count",
            name: "No. of Sensors",
            render: (data: any) => data.attributes?.length,
        },
        {
            key: "unit",
            name: "Unit",
            render: (data: any) => {
                const arrayOfUnits = data.unit
                    .split(",")
                    .reduce((acc: any, symbol: any) => {
                        if (symbol.trim()) acc.push(symbol);
                        return acc;
                    }, []);

                return arrayOfUnits.join(", ");
            },
        },
    ],
    transform: (data: any) => ({
        attributes: data.attributes.map((d: any) => ({
            color: d.color,
            device_id: d.ldsu_uuid,
            gateway_id: d.gatewayId,
            name: d.sensor_type,
            said: String(d.SAID),
            show_alert: false,
            time_series: getMQTTQueryByDevice(d),
            unit: d.unit,
        })),
        source: "SENSOR",
        chart: "STACKED",
        name: data.chart_name || "Multiple Sensor Chart",
    }),
    validate() {
        return this.data.map((item) => ({
            path: "",
            isValid: CHART_NAME_PATTERN.test(item.chart_name.trim()),
            message: CHART_ERROR_MESSAGE,
        }));
    },
};

const DashboardCreateSensorComponent: React.FunctionComponent = () => {
    const [showSelectModal, setShowSelectModal] = React.useState(false);
    const [singleSelectedDevices, setSingleSelectedDevices] = React.useState<{
        [key: string]: any;
    }>({});
    const { createModalShow, setCreateModalShow, createData } =
        useCreateDashboardStore();
    const [sensorChartType, setSensorChartType] = React.useState<any>(
        SensorChartTypeEnum.single
    );
    const [multipleChartList, setMultipleChartList] = React.useState<
        BlockKeyProps[]
    >([]);
    const [multipleChartData, setMultipleChartData] = React.useState<object>(
        {}
    );

    React.useEffect(() => {
        useCreateDashboardStore.setState({
            createData: {
                ...SingleSensorCreateData,
                data: Object.values(singleSelectedDevices),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleSelectedDevices]);

    React.useEffect(() => {
        if (
            sensorChartType === SensorChartTypeEnum.single &&
            _.size(singleSelectedDevices)
        ) {
            useCreateDashboardStore.setState({
                createData: {
                    ...SingleSensorCreateData,
                    data: Object.values(singleSelectedDevices),
                },
            });
        } else {
            useCreateDashboardStore.setState({
                createData: undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sensorChartType]);

    React.useEffect(() => {
        const createData: any[] = [];

        for (let blockData of Object.values(multipleChartData)) {
            const chart_name = blockData.chart_name;
            const chartDevices: any[] = [];
            Object.values(blockData.data).forEach((column: any) =>
                chartDevices.push(...Object.values(column.devices))
            );
            const types = Object.keys(chartDevices.pluck("sensor_type")).join(
                " x "
            );
            const units = Object.keys(chartDevices.pluck("unit")).join(", ");

            !!chartDevices.length &&
                createData.push({
                    chart_name,
                    type: types,
                    unit: units,
                    attributes: chartDevices,
                });
        }

        useCreateDashboardStore.setState({
            createData: {
                ...MultipleSensorCreateData,
                data: createData,
            },
        });
    }, [multipleChartData]);

    const handleUnitOptions = (device: any) => {
        if (device.APP) {
            if (device.unit === "BINARY") return [""];

            const appSensor = device.APP.find(
                (d: any) => d.APPID === device.APPID
            );
            return getUnitSymbolArray(appSensor);
        } else {
            return device.units || device.unit.split(",");
        }
    };
    const handlePrimaryUnitOption = (device: any) => {
        if (device.APP) {
            const appSensor = device.APP.find(
                (d: any) => d.APPID === device.APPID
            );
            return getPrimaryUnit(appSensor);
        } else {
            return device.unit;
        }
    };

    const onRemove = (device: any) => {
        setSingleSelectedDevices((prev: any) => _.omit(prev, device.device_id));
    };
    return (
        <React.Fragment>
            <Row>
                <Col sm="12">
                    <p>Single sensor charts or Multiple Sensor Charts:</p>
                </Col>
                <Col sm="12">
                    <Form className="select-sensor-type">
                        <Form.Check
                            className={`select-sensor-type__radio select-sensor-type__radio--single ${
                                sensorChartType === SensorChartTypeEnum.single
                                    ? "select-sensor-type__radio--active"
                                    : ""
                            }`}
                            defaultChecked={true}
                            type="radio"
                            label="Create standalone charts for selected sensors"
                            name="sensor-type"
                            id="single-sensor-chart"
                            onClick={() =>
                                setSensorChartType(SensorChartTypeEnum.single)
                            }
                            custom
                        />
                        <Form.Check
                            className={`select-sensor-type__radio select-sensor-type__radio--multiple ${
                                sensorChartType === SensorChartTypeEnum.multiple
                                    ? "select-sensor-type__radio--active"
                                    : ""
                            }`}
                            type="radio"
                            label="Create stacked charts for multiple sensors"
                            name="sensor-type"
                            id="multiple-sensor-chart"
                            onClick={() =>
                                setSensorChartType(SensorChartTypeEnum.multiple)
                            }
                            custom
                        />
                    </Form>
                    <hr className="my-4" />
                </Col>
            </Row>

            {sensorChartType === SensorChartTypeEnum.single ? (
                <>
                    <Row>
                        <Col sm="12">
                            <p>Single sensor charts</p>
                            <Button
                                className="mt-3"
                                variant="primary"
                                onClick={() => setShowSelectModal(true)}
                            >
                                Select Sensors
                            </Button>

                            <DashboardCreateSelectSensorModal
                                show={showSelectModal}
                                onHide={() => setShowSelectModal(false)}
                                values={singleSelectedDevices}
                                onSave={(event, data) =>
                                    setSingleSelectedDevices(
                                        Object.values(data)
                                            .map(
                                                (item: any, index: number) => ({
                                                    ...item,
                                                    chart_name:
                                                        item.device_name,
                                                    color:
                                                        COLORS[index] ||
                                                        faker.color.rgb(),
                                                        alert: false
                                                })
                                            )
                                            .pluck("device_id")
                                    )
                                }
                                deviceType="Sensor"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4 cstm-table mobile-chart-info">
                        <div id="chart-edit-form">
                            {Object.values(singleSelectedDevices).map(
                                (device, index) => (
                                    <div
                                        key={getId()}
                                        id={device.device_id}
                                        className="table-row gateway-chart-info"
                                    >
                                        <div className="d-flex justify-content-end">
                                            <i
                                                className="material-icons cursor-pointer text-primary-red-1"
                                                onClick={() => onRemove(device)}
                                                onKeyDown={() =>
                                                    onRemove(device)
                                                }
                                            >
                                                close
                                            </i>
                                        </div>
                                        <Form.Group>
                                            <Form.Label>Chart Name:</Form.Label>
                                            <Form.Control
                                                name={`${device.device_id}_chart_name`}
                                                type="text"
                                                defaultValue={
                                                    device.device_name
                                                }
                                                placeholder="Chart Name"
                                                onChange={(event) =>
                                                    setSingleSelectedDevices(
                                                        (prev: any) =>
                                                            _.set(
                                                                prev,
                                                                `${device.device_id}.chart_name`,
                                                                event.target
                                                                    .value
                                                            )
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                        <div className="field-group">
                                            <p>Sensor: </p>
                                            <p>{device.device_name}</p>
                                        </div>
                                        <div className="field-group">
                                            <p>Type: </p>
                                            <p>{displaySensorType(device)}</p>
                                        </div>
                                        <div className="field-group">
                                            <p>Gateway: </p>
                                            <p>{device.gatewayName}</p>
                                        </div>
                                        <div className="field-group">
                                            <p>LDSU UUID: </p>
                                            <p>{device.ldsu_uuid}</p>
                                        </div>
                                        <div className="field-group">
                                            <Form.Label>Unit:</Form.Label>
                                            <UnitPicker
                                                disabled
                                                data={handleUnitOptions(device)}
                                                defaultValue={handlePrimaryUnitOption(
                                                    device
                                                )}
                                                onChange={(event, unit) => {
                                                    setSingleSelectedDevices(
                                                        (prev: any) =>
                                                            _.set(
                                                                prev,
                                                                `${device.device_id}.unit`,
                                                                unit
                                                            )
                                                    );
                                                }}
                                            />
                                        </div>
                                        <Form.Group className="field-group">
                                            <Form.Label>Color:</Form.Label>
                                            <ColorPicker
                                                defaultValue={_.get(
                                                    singleSelectedDevices,
                                                    `${device.device_id}.color`,
                                                    COLORS[index]
                                                )}
                                                onChange={(event, color) =>
                                                    setSingleSelectedDevices(
                                                        (prev: any) =>
                                                            _.set(
                                                                prev,
                                                                `${device.device_id}.color`,
                                                                color
                                                            )
                                                    )
                                                }
                                            />
                                        </Form.Group>

                                        <div className="field-group">
                                            <Form.Label>Alert line:</Form.Label>
                                            <Form.Check
                                                id={`${device.device_id}_alert`}
                                                type="switch"
                                                defaultChecked={device.alert}
                                                onChange={(event: any) =>
                                                    setSingleSelectedDevices(
                                                        (prev: any) =>
                                                            _.set(
                                                                prev,
                                                                `${device.device_id}.alert`,
                                                                event.target
                                                                    .checked
                                                            )
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </Row>

                    <Row className="mt-4 cstm-table desktop-chart-info">
                        <Col sm={12}>
                            <Form id="chart-edit-form">
                                {!!Object.values(singleSelectedDevices)
                                    .length && (
                                    <div className="table-head" key="head">
                                        <Row className="no-checkbox">
                                            <Col>Sensor</Col>
                                            <Col>Chart Name</Col>
                                            <Col>Type</Col>
                                            <Col>Gateway</Col>
                                            <Col className="flex-basis-220px">
                                                LDSU UUID
                                            </Col>
                                            <Col className="flex-basis-120px">
                                                Unit
                                            </Col>
                                            <Col>Color</Col>
                                            <Col>Alert line</Col>
                                            <Col md={{ span: 1 }}></Col>
                                        </Row>
                                    </div>
                                )}
                                {Object.values(singleSelectedDevices).map(
                                    (device, index) => (
                                        <div
                                            key={getId()}
                                            id={device.device_id}
                                            className="table-row gateway-chart-info"
                                        >
                                            <Row className="no-checkbox align-items-center">
                                                <Col className="text-break">
                                                    {device.device_name}
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        name={`${device.device_id}_chart_name_desktop`}
                                                        type="text"
                                                        defaultValue={
                                                            device.device_name
                                                        }
                                                        placeholder="Chart Name"
                                                        onChange={(event) =>
                                                            setSingleSelectedDevices(
                                                                (prev: any) =>
                                                                    _.set(
                                                                        prev,
                                                                        `${device.device_id}.chart_name`,
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                <Col className="text-break">
                                                    {displaySensorType(device)}
                                                </Col>
                                                <Col className="text-break">
                                                    {device.gatewayName}
                                                </Col>
                                                <Col className="flex-basis-220px">
                                                    {device.ldsu_uuid}
                                                </Col>
                                                <Col className="flex-basis-120px">
                                                    <UnitPicker
                                                        disabled
                                                        data={handleUnitOptions(
                                                            device
                                                        )}
                                                        defaultValue={handlePrimaryUnitOption(
                                                            device
                                                        )}
                                                        onChange={(
                                                            event,
                                                            unit
                                                        ) => {
                                                            setSingleSelectedDevices(
                                                                (prev: any) =>
                                                                    _.set(
                                                                        prev,
                                                                        `${device.device_id}.unit`,
                                                                        unit
                                                                    )
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <ColorPicker
                                                        defaultValue={_.get(
                                                            singleSelectedDevices,
                                                            `${device.device_id}.color`,
                                                            COLORS[index]
                                                        )}
                                                        onChange={(
                                                            event,
                                                            color
                                                        ) =>
                                                            setSingleSelectedDevices(
                                                                (prev: any) =>
                                                                    _.set(
                                                                        prev,
                                                                        `${device.device_id}.color`,
                                                                        color
                                                                    )
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                <Col className="d-flex align-items-center">
                                                    <Form.Check
                                                        id={`${device.device_id}_alert_desktop`}
                                                        disabled={
                                                            !device.alert_available
                                                        }
                                                        type="switch"
                                                        defaultChecked={device.alert}
                                                        onChange={(
                                                            event: any
                                                        ) =>
                                                            setSingleSelectedDevices(
                                                                (prev: any) =>
                                                                    _.set(
                                                                        prev,
                                                                        `${device.device_id}.alert`,
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    )
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                <Col
                                                    md={{ span: 1 }}
                                                    className="d-flex justify-content-end"
                                                >
                                                    <i
                                                        className="material-icons cursor-pointer mr-2 text-primary-red-1"
                                                        onClick={() =>
                                                            onRemove(device)
                                                        }
                                                        onKeyDown={() =>
                                                            onRemove(device)
                                                        }
                                                    >
                                                        close
                                                    </i>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                )}
                            </Form>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <p>Multiple sensor charts</p>
                    <p className="sub-text">
                        {`In a multiple sensor chart, you can add max
                        {maxDevicesAllowed} sensors (Sensors with same sensor
                        type or two different sensor type. i.e. 2 y-axis can
                        plot with different units)`.fill({
                            maxDevicesAllowed: String(maxDevicesAllowed),
                        })}
                    </p>

                    {multipleChartList.map((item) => (
                        <React.Fragment key={item.key}>
                            <MultipleChartBlock
                                blockKey={item}
                                onDelete={(deleteItem) => {
                                    setMultipleChartData((prev) =>
                                        _.omit(prev, deleteItem.key)
                                    );

                                    setMultipleChartList((prev) =>
                                        prev.filter(
                                            (item) =>
                                                item.key !== deleteItem.key
                                        )
                                    );
                                }}
                                onChange={_.debounce(
                                    (data) =>
                                        setMultipleChartData((prev: any) => ({
                                            ...prev,
                                            [item.key]: data,
                                        })),
                                    100
                                )}
                            />
                        </React.Fragment>
                    ))}

                    <Button
                        className="mt-3"
                        variant="primary"
                        onClick={() =>
                            setMultipleChartList((prev) => [
                                ...prev,
                                {
                                    key: getId(),
                                },
                            ])
                        }
                    >
                        + Create Multiple Sensor Chart
                    </Button>
                </>
            )}

            <CreateChartModal
                show={createModalShow && !_.isEmpty(createData)}
                onHide={() => setCreateModalShow(false)}
            />
            <style>{`
                .select-sensor-type {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 70%;
                }

                .select-sensor-type__radio {
                    background-color: var(--create-chart-from-background);
                    width: 49%;
                    display: flex;
                    padding: 1.5rem 1rem;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .select-sensor-type__radio label {
                    max-width: calc(100% - 100px);
                    transform: translateX(5.5rem);
                }

                .select-sensor-type__radio label::before,
                .select-sensor-type__radio label::after {
                    left: -5.5rem;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .select-sensor-type__radio::before {
                    content: url('${singleChartSvg}');
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    left: 3rem;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .select-sensor-type__radio--multiple::before {
                    content: url('${multipleChartSvg}');
                }

                .select-sensor-type__radio--active {
                    outline: 2px solid var(--create-chart-form-active);
                }

                #chart-edit-form .custom-switch {
                    margin-top: -1.75rem;
                }

                .sub-text {
                    color: var(--create-chart-sub-text-color);
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                }

                .multiple-chart-block {
                    background-color: var(--create-chart-from-background);
                }

                .multiple-chart-block .row {
                    position: relative;
                }

                .multiple-chart-block--delete {
                    position: absolute !important;
                    color: #F2495E;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                }

                .multiple-chart-block + .multiple-chart-block {
                    margin-top: 1rem;
                }

                .multiple-chart-block .form-control {
                    background-color: var(--create-chart-form-secondary);
                }

                .multiple-chart-block__select-radio {
                    padding: 1rem 2.5rem;
                    border: 2px solid var(--create-chart-form-secondary);
                    border-radius: 5px;
                    transition: all 0.2s ease-in-out;
                }

                .multiple-chart-block hr {
                    border-color: var(--create-chart-form-secondary);
                }

                .multiple-chart-block__select-radio--active {
                    border: 2px solid var(--create-chart-form-active);
                }

                .multiple-chart-block__panel {
                    background-color: var(--create-chart-multiple-sensor-background);
                }

                @media (max-width: 768px) {
                    .select-sensor-type,
                    .select-sensor-type__radio {
                        width: 100%;
                    }

                    .select-sensor-type__radio + .select-sensor-type__radio {
                        margin-top: .5rem;
                    }

                    .multiple-chart-block .delete-block {
                        position: absolute !important;
                        width: 2rem;
                        height: 2rem;
                        right: 0;
                        padding-top: 0;
                    }
                }
            `}</style>
        </React.Fragment>
    );
};

export default React.memo(DashboardCreateSensorComponent);
