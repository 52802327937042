import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";
import { isEmpty, isEqual } from "lodash";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import "assets/css/subscription.css";
import {
    createAddress,
    getAllPlans,
    getAddress,
    updateAddress,
    createSubscription,
    getLatestSubscription,
} from "service/subscriptionService";

import VerifyCustomer from "../../components/subscriptions/VerifyCustomer";
import PurchasePreview from "../../components/subscriptions/PurchasePreview";
import BillingAddressCheckoutForm from "../../components/subscriptions/BillingAddressCheckoutForm";
import {
    ACTION_FAIL_MESSAGE,
    billingStartDate,
    CheckoutType,
    SubscriptionStatus,
} from "constant";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { AddressPostCountry } from "generated/models";
import { AppContext } from "context/appContext";
import {
    BillingAddressProp,
    BillingAddressRequestBody,
} from "types/Subscription";
import { initBillingAddress } from "utils/subscriptionFunctions";
import { isHttpSuccess } from "utils/functions";
import { LoadingModal } from "components/modals/ModalTemplate";
import PreviewItem, {
    PreviewSubscriptionBillingInfo,
} from "components/subscriptions/PreviewItem";
import { SubscriptionPlanType } from "components/subscriptions/CurrentPlanInformation";
import withSubscriptionPermissionCheck from "components/hoc/SubscriptionPermissionCheck";

const CheckoutBillingAddress = () => {
    const history = useHistory();
    const location: any = useLocation();
    const {
        storeData: {
            subscription: { currentSubscriptionId },
        },
    } = useContext(AppContext);
    const [subscription, setSubscription] = useState<any>({});
    const {
        checkoutType,
        planUUID,
        status,
        addonUUID,
        subscriptionUUID = currentSubscriptionId,
        customer: {
            id: customer_id = "",
            prn = "",
            verified = false,
            uuid: customer_uuid = "",
            billing_cycles = 0,
        } = {},
        bundleQty: locationBundleQty,
        bundleInfo: {
            amount: locationAmount = "",
            tokenQty: locationTokenQty = 0,
        } = {},
        monthlyChargePreview: locationMonthlyChargePreview = "",
        subscriptionType: locationSubscriptionType,
    } = location.state;

    const [customer, setCustomer] = useState({
        prn,
        verified,
        billing_cycles,
        id: customer_id,
        uuid: customer_uuid,
    });
    const [subscriptionType, setSubscriptionType] = useState<
        | SubscriptionPlanType.MONTHLY
        | SubscriptionPlanType.PREPAID
        | SubscriptionPlanType.BASIC
    >(locationSubscriptionType);

    const [initialBillingAddress, setInitialBillingAddress] =
        useState<BillingAddressProp>(initBillingAddress());

    const [billingAddress, setBillingAddress] = useState<BillingAddressProp>(
        initBillingAddress()
    );

    const [plan, setPlan] = useState(Object);
    const [bundleQty, setBundleQty] = useState(locationBundleQty);
    const bundleInfo = {
        amount: locationAmount,
        tokenQty: locationTokenQty,
    };
    const [monthlyChargePreview, setMonthlyChargePreview] = useState(
        locationMonthlyChargePreview
    );
    const [hasAddressTemplate, setHasAddressTemplate] = useState(false);
    const [currentAddressUUID, setCurrentAddressUUID] = useState("");
    const [pageLoaded, setPageLoaded] = useState(false);
    const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
    const [addressError, setAddressError] = useState({});
    const checkoutSubscription: boolean = checkoutType === CheckoutType.PLAN;
    const checkoutAddon: boolean = checkoutType === CheckoutType.ADDON;
    const subscriptionIsCancelledOrExpired = [
        SubscriptionStatus.CANCELED,
        SubscriptionStatus.EXPIRED,
    ].includes(subscription?.status);

    const defaultLocationState = {
        checkoutType,
        planUUID,
        plan,
        addonUUID,
        bundleQty,
        bundleInfo,
        subscriptionUUID,
        status,
        billingStartDate,
        customer,
        monthlyChargePreview,
        subscriptionType,
    };

    useEffect(() => {
        (async () => {
            const haveSubscription = await getLatestSubscription();
            setSubscription(haveSubscription);

            let existingAddress;
            if (haveSubscription?.address && checkoutSubscription) {
                existingAddress = haveSubscription.address;
            } else {
                const addressRes = await getAddress();
                if (isHttpSuccess(addressRes.status)) {
                    if (addressRes.data?.length) {
                        existingAddress = addressRes.data[0];
                    }
                } else {
                    showErrorAlert(
                        getAPIError(addressRes, "Unable to fetch Address.")
                    );
                    return;
                }
            }

            if (existingAddress) {
                const { extended, region, uuid } = existingAddress;

                const existingAddress_ = {
                    ...existingAddress,
                    extended: extended || "",
                    region: region || "",
                };

                setInitialBillingAddress(existingAddress_);
                setBillingAddress(existingAddress_);
                setCurrentAddressUUID(uuid);
                setHasAddressTemplate(true);
            }
        })();
    }, [checkoutSubscription]);

    useEffect(() => {
        (async () => {
            if (checkoutSubscription) {
                const planRes = await getAllPlans();

                if (isHttpSuccess(planRes.status)) {
                    const plan = planRes.data.find(
                        ({ uuid }: { uuid: string }) => planUUID === uuid
                    );

                    plan && setPlan(plan);
                } else {
                    showErrorAlert(getAPIError(planRes, ACTION_FAIL_MESSAGE));
                }
                setPageLoaded(true);
            } else if (checkoutAddon) {
                setPageLoaded(true);
            }
        })();
    }, [checkoutSubscription, checkoutAddon, planUUID]);

    const redirectToCheckout = (locationStateObject: any) => {
        history.push("/checkout", locationStateObject);
    };

    const createSubscriptionOrRedirectAddon = async (addressUUID: string) => {
        if (checkoutSubscription) {
            const subscriptionRes = await createSubscription({
                addressUUID,
                ...(customer.uuid && {
                    customer_uuid: customer.uuid,
                }),
            });

            if (isHttpSuccess(subscriptionRes.status)) {
                redirectToCheckout({
                    ...defaultLocationState,
                    addressUUID,
                    subscriptionUUID: subscriptionRes.data.uuid,
                });

                showSuccessAlert({
                    message: `Successfully created billing address.`,
                });
            } else {
                showErrorAlert(
                    getAPIError(subscriptionRes, ACTION_FAIL_MESSAGE)
                );
            }
        } else if (checkoutAddon) {
            redirectToCheckout({
                ...defaultLocationState,
                addressUUID,
            });
        }
    };

    const handleRedirectWithExistingAddress = () => {
        if (
            checkoutSubscription &&
            subscriptionUUID &&
            !subscriptionIsCancelledOrExpired
        ) {
            redirectToCheckout({
                ...defaultLocationState,
                addressUUID: currentAddressUUID,
            });
        } else {
            createSubscriptionOrRedirectAddon(currentAddressUUID);
        }
    };

    const createSubscriptionWithoutAddress = async () => {
        const subscriptionRes = await createSubscription({
            customer_uuid: customer.uuid,
        });

        if (isHttpSuccess(subscriptionRes.status)) {
            redirectToCheckout({
                ...defaultLocationState,
                subscriptionUUID: subscriptionRes.data.uuid,
            });
        } else {
            showErrorAlert(getAPIError(subscriptionRes, ACTION_FAIL_MESSAGE));
        }
    };

    const proceedToCheckout = async () => {
        if (
            [SubscriptionPlanType.PREPAID, SubscriptionPlanType.BASIC].includes(
                subscriptionType
            ) &&
            checkoutSubscription
        ) {
            createSubscriptionWithoutAddress();
            return;
        }

        setIsUpdatingAddress(true);

        const {
            first_name,
            last_name,
            city,
            line3,
            street,
            country,
            code,
            extended,
            region,
        } = billingAddress;
        const body: BillingAddressRequestBody = {
            first_name: first_name.trim(),
            last_name: last_name.trim(),
            city: city.trim(),
            line3: line3.trim(),
            street: street.trim(),
            country: country.trim() as AddressPostCountry,
            code: code.trim(),
            extended: extended?.trim(),
            region: region?.trim(),
        };

        // NOTE: New address should be created if 1. no address, 2. cancelled sub 3. expired sub
        if (!hasAddressTemplate || subscriptionIsCancelledOrExpired) {
            const addressRes = await createAddress(body);

            if (isHttpSuccess(addressRes.status)) {
                const { uuid } = addressRes.data;
                setCurrentAddressUUID(uuid);
                createSubscriptionOrRedirectAddon(uuid);
            } else {
                showErrorAlert(getAPIError(addressRes, ACTION_FAIL_MESSAGE));
            }
        } else {
            if (isEqual(initialBillingAddress, billingAddress)) {
                handleRedirectWithExistingAddress();
            } else {
                const updateAddressTemplateRes = await updateAddress(
                    currentAddressUUID,
                    body
                );

                if (isHttpSuccess(updateAddressTemplateRes.status)) {
                    handleRedirectWithExistingAddress();
                    showSuccessAlert({
                        message: "Successfully updated billing address.",
                    });
                } else {
                    showErrorAlert(
                        getAPIError(
                            updateAddressTemplateRes,
                            ACTION_FAIL_MESSAGE
                        )
                    );
                }
            }
        }
        setIsUpdatingAddress(false);
    };

    const updateSubscriptionType = (
        type:
            | SubscriptionPlanType.MONTHLY
            | SubscriptionPlanType.PREPAID
            | SubscriptionPlanType.BASIC
    ) => {
        setSubscriptionType(type);
    };
    const updateMonthlyChargePreview = (newMonthlyCharge: any) => {
        setMonthlyChargePreview(newMonthlyCharge);
    };

    const updateCustomerInfo = (newCustomerInfo: any) => {
        setCustomer(newCustomerInfo);
    };
    const updateBillingAddress = (newBillingAddress: any) => {
        setBillingAddress(newBillingAddress);
    };
    const updateAddressError = (newAddressError: any) => {
        setAddressError(newAddressError);
    };

    const purchasePreviewProps = {
        checkoutType,
        customer,
    };

    const billingAddressCheckoutFormProps = {
        billingAddress,
        updateBillingAddress,
        updateAddressError,
    };

    const verifyCustomerProps = {
        customer,
        updateCustomerInfo,
        updateMonthlyChargePreview,
        updateSubscriptionType,
    };

    return (
        <ContentWrapper isLoading={!pageLoaded}>
            <div className="page-content subscription-page">
                <Container fluid>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title" aria-label="page-title">
                                {checkoutSubscription
                                    ? "Customer Details"
                                    : "Billing Address"}
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item>
                                    <Link to="/manage-subscription">
                                        Subscription
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {checkoutSubscription
                                        ? "Customer Details"
                                        : "Billing Address"}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} className="d-flex flex-column ">
                            {checkoutSubscription && (
                                <VerifyCustomer {...verifyCustomerProps} />
                            )}
                            {(checkoutAddon ||
                                (checkoutSubscription &&
                                    subscriptionType === "monthly" &&
                                    customer.verified)) && (
                                <BillingAddressCheckoutForm
                                    {...billingAddressCheckoutFormProps}
                                />
                            )}
                        </Col>

                        <Col md={4}>
                            <PurchasePreview {...purchasePreviewProps}>
                                <PreviewItem
                                    checkoutType={checkoutType}
                                    monthlyChargePreview={
                                        monthlyChargePreview ||
                                        locationMonthlyChargePreview
                                    }
                                    billingStartDate={billingStartDate}
                                    customer={customer}
                                    bundleInfo={bundleInfo}
                                    bundlePrice={bundleInfo.amount}
                                    bundleTokenQty={bundleInfo.tokenQty}
                                    subscriptionType={
                                        subscriptionType ||
                                        locationSubscriptionType
                                    }
                                    bundleQty={bundleQty}
                                    setBundleQty={setBundleQty}
                                />
                                {checkoutType === CheckoutType.PLAN &&
                                    customer.verified && (
                                        <PreviewSubscriptionBillingInfo
                                            billingStartDate={billingStartDate}
                                            customer={customer}
                                            subscriptionType={
                                                subscriptionType ||
                                                locationSubscriptionType
                                            }
                                        />
                                    )}
                            </PurchasePreview>
                            {(checkoutType === CheckoutType.ADDON ||
                                (checkoutType === CheckoutType.PLAN &&
                                    customer.verified)) && (
                                <Button
                                    variant="primary"
                                    className="proceed"
                                    aria-label="next-button"
                                    disabled={
                                        (checkoutType === CheckoutType.PLAN &&
                                            subscriptionType === "monthly" &&
                                            !customer.verified) ||
                                        (!isEmpty(addressError) &&
                                            subscriptionType === "monthly")
                                    }
                                    onClick={proceedToCheckout}
                                >
                                    NEXT
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <LoadingModal showModal={isUpdatingAddress} />
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default withSubscriptionPermissionCheck(CheckoutBillingAddress);
