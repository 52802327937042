import { useEffect, useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import { useParams } from "react-router-dom";

import { meteringService } from "service/meteringService";
import { getIsDeletionTaskRunning } from "service/gatewayService";

import { DownloadDataUI } from "components/modals/DownloadDataUI";
import { ClearDataUI } from "components/gateways/ClearDataUI";
import { humanReadableFileSize, orgId } from "utils/functions";

import "assets/css/gateway.css";
import { adjustedDisplayData } from "components/dashboard/elements/charts/StorageChart";
import { showErrorAlert } from "../../utils/alert";

const bytePerSample = 4;
const totalStrInBytes = 5368709120;
const DOWNLOAD_GATEWAY = "downloadGateway";

const StorageTab = ({ registryDetails }: any) => {
    const { gatewayId }: any = useParams();
    const [storageUsedByGateway, setStorageUsedByGateway] = useState(0);
    const [storageUsedByOtherGateway, setStorageUsedByOtherGateway] =
        useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeletionTaskRunning, setIsDeletionTaskRunning] = useState(true);

    const fetchAll = useCallback(() => {
        setIsLoading(true);
        getIsDeletionTaskRunning(gatewayId)
            .then((isRunning: boolean) => {
                setIsDeletionTaskRunning(isRunning);
                setIsLoading(false);
            })
            .catch(() => {
                showErrorAlert({
                    message: "Refresh status error, Please try again!",
                });
                setIsLoading(false);
            });
    }, [gatewayId]);

    useEffect(() => {
        const fetchStorage = async () => {
            const res: any = await meteringService.getStorageInfo(gatewayId);
            if (res.status === 200) {
                const totalBytesUsed: number =
                    res?.data?.[orgId()] * bytePerSample || 0;
                const currGWBytesUsed: number =
                    res?.data?.gateway?.[gatewayId] * bytePerSample || 0;
                setStorageUsedByOtherGateway(
                    Number(totalBytesUsed - currGWBytesUsed)
                );
                setStorageUsedByGateway(Number(currGWBytesUsed));
            }
        };

        fetchStorage();
        fetchAll();
    }, [gatewayId, fetchAll]);

    const options = {
        backgroundColor: "#2F3A51",
        color: ["#00CBAF", "#128D7C", "#3E4B67"],
        responsive: true,
        maintainAspectRatio: false,
        title: [
            {
                show: false,
            },
        ],
        toolbox: {
            show: false,
        },
        legend: {
            bottom: -5,
            orient: "vertical",
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontWeight: 300,
                fontSize: 15,
            },
            selectedMode: false,
        },
        series: [
            {
                name: "Storage",
                type: "pie",
                center: ["50%", "30%"],
                radius: ["30%", "55%"],
                avoidLabelOverlap: true,
                top: "5%",
                label: {
                    formatter: (params: any) => {
                        const {
                            data: { actualValue: bytes, name },
                        } = params;
                        const dataUsed: string = humanReadableFileSize(
                            bytes,
                            false,
                            2
                        );

                        const percent = (bytes / totalStrInBytes) * 100;

                        return `${name}: \n\n ${dataUsed} (${percent.toFixed(
                            2
                        )}%)`;
                    },
                    show: true,
                    position: "outside",
                    padding: [25, 0, -10, 10],
                    fontSize: 12,
                    rich: {
                        b: {
                            lineHeight: 22,
                            align: "center",
                        },
                        d: {
                            lineHeight: 22,
                            align: "center",
                        },
                    },
                },
                labelLine: {
                    show: true,
                    length: 15,
                    length2: 20,
                    showAbove: true,
                },

                data: [
                    {
                        actualValue: storageUsedByGateway,
                        name: "Sensor cache usage by this Gateway",
                        value: adjustedDisplayData(storageUsedByGateway),
                    },
                    {
                        actualValue: storageUsedByOtherGateway,
                        name: "Sensor cache usage by all other Gateway(s)",
                        value: adjustedDisplayData(storageUsedByOtherGateway),
                    },
                    {
                        actualValue:
                            totalStrInBytes -
                            storageUsedByGateway -
                            storageUsedByOtherGateway,
                        name: "Free sensor cache storage capacity",
                        value: adjustedDisplayData(
                            totalStrInBytes -
                                storageUsedByGateway -
                                storageUsedByOtherGateway
                        ),
                    },
                ],
            },
        ],
    };

    return (
        <Row>
            <Col lg={6} md={12} sm={12}>
                <div className="storage-box">
                    <h5>Storage</h5>

                    <ReactECharts className="chart-box" option={options} />

                    <ClearDataUI
                        gatewayId={registryDetails?.gateway_id}
                        isDeletionTaskRunning={isDeletionTaskRunning}
                        isLoading={isLoading}
                        onRefresh={fetchAll}
                    />
                </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
                <Row>
                    <Col
                        md={{ span: 9, offset: 1 }}
                        xs={{ span: 12, offset: 0 }}
                    >
                        <div className="download-data-box text-left">
                            <h5>Download Gateway data</h5>
                            <DownloadDataUI
                                registryDetails={registryDetails}
                                downloadType={DOWNLOAD_GATEWAY}
                                disabled={isDeletionTaskRunning}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default StorageTab;
