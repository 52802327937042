import { useState, useEffect, ChangeEvent } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { Breadcrumb, Button, Col, Container, Form, Row } from "react-bootstrap";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import EventVariableService from "service/eventVariableService";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { isHttpSuccess, convertSecondsToTimeString } from "utils/functions";
import {
    getScheduledTimeAsSeconds,
    validateEventVariableValue,
} from "utils/eventFunctions";
import {
    ConditionPost,
    Variable,
    EventCondtionVariable,
} from "generated/models";
import {
    ConditionOptions,
    EVENT_VARIABLE_TIMER_FORMAT,
    FETCH_EVENT_VARIABLES_FAIL_MESSAGE,
    Operator,
    ConditionInfos,
    TypeOption,
    variableType,
} from "constant";
import EventService from "service/eventService";
import EventVariableInfo from "components/events/EventVariableInfo";

const {
    ONCE_TRUE,
    ONCE_FALSE,
    ONCE_EQUAL_TO,
    ONCE_LESS_THAN,
    ONCE_GREATER_THAN,
    CONTINUES_TRUE,
    CONTINUES_FALSE,
    CONTINUES_EQUAL_TO,
    CONTINUES_GREATER_THAN,
    CONTINUES_LESS_THAN,
} = ConditionOptions;

type EvaluateEventVariableParam = {
    conditionId: string;
    eventId: string;
};

const TYPE_TRIGGER = {
    ONCE: "when",
    CONTINUES: "is",
};

const EvaluateEventVariable = () => {
    const { conditionId: variableId, eventId }: EvaluateEventVariableParam =
        useParams();
    const history = useHistory();
    const location: {
        state?: {
            selectedOperator: string;
            slotCount: number;
            selectedSlotIndex: number;
            conditionPayload: any;
        };
    } = useLocation();
    const selectedOperator = location?.state?.selectedOperator;
    const slotCount = location?.state?.slotCount;
    const selectedSlotIndex = location?.state?.selectedSlotIndex;
    const conditionPayload = location?.state?.conditionPayload;

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [eventVariable, setEventVariable] = useState<Variable>(
        {} as Variable
    );
    const [operator, setOperator] = useState("==");
    const [threshold, setThreshold] = useState("");
    const [counterValueOption, setCounterValueOption] = useState("terminal");
    const [errorMessage, setErrorMessage] = useState("");
    const [typeTrigger, setTypeTrigger] = useState(TYPE_TRIGGER.ONCE);
    const [showTerminal, setShowTerminal] = useState(false);

    const validateThreshold = (threshold = "") => {
        const { type_ } = eventVariable;
        let errorMsg = "";

        if (!threshold) {
            errorMsg = "Threshold should not be blank.";
        } else {
            errorMsg = validateEventVariableValue(
                type_ as TypeOption,
                threshold
            );
        }

        setErrorMessage(errorMsg);
        return errorMsg;
    };

    const setDefaultCreateData = ({ type_, initial, attributes }: Variable) => {
        setOperator(Operator.LESS_THAN);
        switch (type_) {
            case TypeOption.Boolean:
                setOperator(Operator.EQUAL_TO);
                setThreshold(initial);
                break;
            case TypeOption.Integer:
                setThreshold(Number(initial).toString());
                break;
            case TypeOption.Float:
                setThreshold(Number(initial).toFixed(2));
                break;
            case TypeOption.Timer:
                setThreshold(
                    convertSecondsToTimeString(
                        Number(initial),
                        EVENT_VARIABLE_TIMER_FORMAT
                    )
                );
                break;
            case TypeOption.Counter:
                if (attributes?.direction) {
                    setShowTerminal(true);
                    setCounterValueOption("terminal");
                    setThreshold(attributes?.terminal + "");
                } else {
                    setShowTerminal(false);
                    setCounterValueOption("initial");
                    setThreshold(initial);
                }
                break;
        }
    };

    const setDefaultUpdateData = ({ type_, initial, attributes }: Variable) => {
        setOperator(conditionPayload?.operator);
        setTypeTrigger(conditionPayload?.trigger_once ? "when" : "is");
        switch (type_) {
            case TypeOption.Boolean:
                setThreshold(conditionPayload?.threshold);
                break;
            case TypeOption.Integer:
                setThreshold(Number(conditionPayload?.threshold).toString());
                break;
            case TypeOption.Float:
                setThreshold(Number(conditionPayload?.threshold).toFixed(2));
                break;
            case TypeOption.Timer:
                setThreshold(
                    convertSecondsToTimeString(
                        Number(conditionPayload?.threshold),
                        EVENT_VARIABLE_TIMER_FORMAT
                    )
                );
                break;
            case TypeOption.Counter:
                setThreshold(Number(conditionPayload?.threshold).toString());
                setShowTerminal(
                    getIsShowTerminal(conditionPayload?.operator, attributes)
                );
                if (conditionPayload?.threshold === initial) {
                    setCounterValueOption("initial");
                } else if (
                    conditionPayload?.threshold ===
                    attributes?.terminal + ""
                ) {
                    setCounterValueOption("terminal");
                } else {
                    setCounterValueOption("custom");
                }
                break;
        }
    };

    const fetchData = async () => {
        setIsPageLoading(true);

        const fetchResp = await EventVariableService.getEventVariableById(
            variableId
        );

        if (!isHttpSuccess(fetchResp.status)) {
            showErrorAlert(
                getAPIError(fetchResp, FETCH_EVENT_VARIABLES_FAIL_MESSAGE)
            );
        } else {
            setEventVariable(fetchResp.data);
            if (!conditionPayload) {
                // set default data for create form
                setDefaultCreateData(fetchResp.data);
            } else {
                // set default data for update form
                setDefaultUpdateData(fetchResp.data);
            }
        }

        setIsPageLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buildConditionPayload = (): ConditionPost => {
        let slot = 0;
        if (selectedOperator === "AND") {
            slot = selectedSlotIndex ?? 0;
        } else if (selectedOperator === "OR") {
            slot = slotCount ?? 0;
        }

        if (!isNaN(Number(conditionPayload?.slot))) {
            slot = conditionPayload.slot;
        }

        const { uuid, type_ } = eventVariable;

        let formattedThreshold = threshold;
        if (type_ === TypeOption.Timer) {
            formattedThreshold = getScheduledTimeAsSeconds(threshold);
        } else if (
            [TypeOption.Integer, TypeOption.Float, TypeOption.Counter].includes(
                type_ as TypeOption
            )
        ) {
            formattedThreshold = Number(threshold).toString();
        }

        const variableInfo: EventCondtionVariable = {
            variable: uuid,
            operator: operator,
            threshold: formattedThreshold,
            trigger_once: typeTrigger === TYPE_TRIGGER.ONCE,
        } as EventCondtionVariable;

        return {
            slot: slot,
            type_: variableType,
            resource: {
                device: variableInfo,
            },
        };
    };

    const handleAddCondition = async () => {
        const payload = buildConditionPayload();

        const createdResp = await EventService.createCondition(
            eventId,
            payload
        );

        if (isHttpSuccess(createdResp.status)) {
            history.push(`/event-details/${eventId}`);
            showSuccessAlert({
                message: "New condition has been added.",
            });
        } else {
            showErrorAlert({
                ...getAPIError(
                    createdResp,
                    "Unable to create condition. Please try again."
                ),
                title: "Invalid Value",
            });
        }
    };

    const handleUpdateCondition = async () => {
        const payload = buildConditionPayload();

        const updatedResp = await EventService.updateCondition(
            eventId,
            conditionPayload.uuid,
            payload
        );

        if (isHttpSuccess(updatedResp.status)) {
            history.push(`/event-details/${eventId}`);
            showSuccessAlert({
                message: "The condition has been updated.",
            });
        } else {
            showErrorAlert({
                ...getAPIError(
                    updatedResp,
                    "Unable to update condition. Please try again."
                ),
                title: "Invalid Value",
            });
        }
    };

    const handleAddOrUpdateCondition = async () => {
        const errorMsg = validateThreshold(threshold);
        if (errorMsg) {
            return;
        }
        if (Object.keys(conditionPayload || {}).length) {
            await handleUpdateCondition();
        } else {
            await handleAddCondition();
        }
    };

    const getNoteMessage = () => {
        const typeCondition =
            eventVariable.type_ === TypeOption.Boolean
                ? `${typeTrigger}-${threshold}`
                : `${typeTrigger}-${operator}`;

        let value = threshold;
        if (
            eventVariable.type_ === TypeOption.Counter &&
            counterValueOption === "terminal"
        ) {
            value = "Terminal value";
        } else if (
            eventVariable.type_ === TypeOption.Counter &&
            counterValueOption === "initial"
        ) {
            value = "Initial value";
        }
        return ConditionInfos[typeCondition]?.note?.replace("$value", value);
    };

    const handleBoolValue = (e: ChangeEvent<HTMLInputElement>) => {
        const [typeTrigger, value] = e.target.value.split("-");
        setThreshold(value);
        setTypeTrigger(typeTrigger);
    };

    const renderBoolVal = () => {
        return (
            <Row>
                <Col sm="12">
                    <Form.Control
                        as="select"
                        value={typeTrigger + "-" + threshold}
                        onChange={handleBoolValue}
                    >
                        <option value={ONCE_TRUE}>
                            {ConditionInfos[ONCE_TRUE].label}
                        </option>
                        <option value={ONCE_FALSE}>
                            {ConditionInfos[ONCE_FALSE].label}
                        </option>
                        <option value={CONTINUES_TRUE}>
                            {ConditionInfos[CONTINUES_TRUE].label}
                        </option>
                        <option value={CONTINUES_FALSE}>
                            {ConditionInfos[CONTINUES_FALSE].label}
                        </option>
                    </Form.Control>
                </Col>
            </Row>
        );
    };

    const handleCommonOperator = (e: ChangeEvent<HTMLInputElement>) => {
        const [typeTrigger, value] = e.target.value.split("-");
        setOperator(value);
        setTypeTrigger(typeTrigger);
    };

    const renderCommonVal = () => {
        return (
            <Row>
                <Col sm="6">
                    <Form.Control
                        as="select"
                        value={typeTrigger + "-" + operator}
                        onChange={handleCommonOperator}
                    >
                        <option value={ONCE_LESS_THAN}>
                            {ConditionInfos[ONCE_LESS_THAN].label}
                        </option>
                        <option value={ONCE_GREATER_THAN}>
                            {ConditionInfos[ONCE_GREATER_THAN].label}
                        </option>
                        <option value={ONCE_EQUAL_TO}>
                            {ConditionInfos[ONCE_EQUAL_TO].label}
                        </option>
                        <option value={CONTINUES_LESS_THAN}>
                            {ConditionInfos[CONTINUES_LESS_THAN].label}
                        </option>
                        <option value={CONTINUES_GREATER_THAN}>
                            {ConditionInfos[CONTINUES_GREATER_THAN].label}
                        </option>
                        <option value={CONTINUES_EQUAL_TO}>
                            {ConditionInfos[CONTINUES_EQUAL_TO].label}
                        </option>
                    </Form.Control>
                </Col>
                <Col sm="6">
                    <Form.Control
                        onChange={(e) => {
                            setThreshold(e.target.value);
                            validateThreshold(e.target.value);
                        }}
                        value={threshold}
                        isInvalid={!!errorMessage}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errorMessage}
                    </Form.Control.Feedback>
                </Col>
            </Row>
        );
    };

    const getIsShowTerminal = (operatorOption: string, attributes: any) => {
        return (
            (attributes?.direction && operatorOption === Operator.LESS_THAN) ||
            (!attributes?.direction &&
                operatorOption === Operator.GREATER_THAN) ||
            operatorOption === Operator.EQUAL_TO
        );
    };

    const handleChangeOperator = (e: ChangeEvent<HTMLInputElement>) => {
        const [typeTrigger, operatorOption] = e.target.value.split("-");
        setTypeTrigger(typeTrigger);
        setOperator(operatorOption);
        const { attributes } = eventVariable;
        const isShowTerminal = getIsShowTerminal(operatorOption, attributes);
        setShowTerminal(isShowTerminal);
        if (isShowTerminal) {
            handleCounterValueOption("terminal");
        } else {
            handleCounterValueOption("initial");
        }
    };

    const handleCounterValueOption = (counterOption: string) => {
        setCounterValueOption(counterOption);
        const { initial, attributes } = eventVariable;
        if (counterOption === "terminal") {
            setThreshold(attributes?.terminal + "");
        } else if (counterOption === "initial") {
            setThreshold(initial);
        } else {
            setThreshold("");
        }
    };

    const renderCounterVal = () => {
        return (
            <Row>
                <Col sm="12">
                    <Form.Control
                        as="select"
                        value={typeTrigger + "-" + operator}
                        onChange={handleChangeOperator}
                    >
                        <option value={ONCE_LESS_THAN}>
                            {ConditionInfos[ONCE_LESS_THAN].label}
                        </option>
                        <option value={ONCE_GREATER_THAN}>
                            {ConditionInfos[ONCE_GREATER_THAN].label}
                        </option>
                        <option value={ONCE_EQUAL_TO}>
                            {ConditionInfos[ONCE_EQUAL_TO].label}
                        </option>
                        <option value={CONTINUES_LESS_THAN}>
                            {ConditionInfos[CONTINUES_LESS_THAN].label}
                        </option>
                        <option value={CONTINUES_GREATER_THAN}>
                            {ConditionInfos[CONTINUES_GREATER_THAN].label}
                        </option>
                        <option value={CONTINUES_EQUAL_TO}>
                            {ConditionInfos[CONTINUES_EQUAL_TO].label}
                        </option>
                    </Form.Control>
                </Col>
                <Col sm="12" className="mt-2">
                    <Form.Control
                        as="select"
                        value={counterValueOption}
                        onChange={(e) =>
                            handleCounterValueOption(e.target.value)
                        }
                    >
                        {(showTerminal || operator === Operator.EQUAL_TO) && (
                            <option value="terminal">Terminal value</option>
                        )}
                        {(!showTerminal || operator === Operator.EQUAL_TO) && (
                            <option value="initial">Initial value</option>
                        )}
                        <option value="custom">Custom value</option>
                    </Form.Control>
                </Col>

                {counterValueOption === "custom" && (
                    <Col sm="12" className="mt-2">
                        <Form.Control
                            value={threshold}
                            onChange={(e) => {
                                setThreshold(e.target.value);
                                validateThreshold(e.target.value);
                            }}
                            isInvalid={!!errorMessage}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorMessage}
                        </Form.Control.Feedback>
                    </Col>
                )}
            </Row>
        );
    };

    return (
        <ContentWrapper isPageLoading={isPageLoading}>
            <Container className="modify-event-variable">
                <Row>
                    <Col sm="12" className="event-detail-head">
                        <h5 className="page-title overflow-text">
                            Evaluate Event Variable
                        </h5>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <Breadcrumb className="w-100">
                            <Breadcrumb.Item active>
                                <Link to="/events">Events</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <Link to={`/event-details/${eventId}`}>
                                    Event Details
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Condition</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Row className="action-detail-box">
                    <Col>
                        <div className="form-box mb-3">
                            <h5 className="mb-4 event-variable-label">
                                When Event Variable
                            </h5>

                            {
                                <EventVariableInfo
                                    eventVariable={eventVariable}
                                />
                            }
                        </div>

                        <div className="form-box mb-3">
                            <h5 className="mb-4 event-variable-label">Value</h5>

                            {TypeOption.Boolean === eventVariable.type_ &&
                                renderBoolVal()}

                            {[
                                TypeOption.Integer,
                                TypeOption.Float,
                                TypeOption.Timer,
                            ].includes(eventVariable.type_ as TypeOption) &&
                                renderCommonVal()}

                            {TypeOption.Counter === eventVariable.type_ &&
                                renderCounterVal()}
                        </div>

                        <div className="form-box mb-3">
                            <h5 className="text-18px iot-font-500">Note:</h5>
                            <p className="text-primary-blue-4 text-16px iot-mb-0">
                                {getNoteMessage()}
                            </p>
                        </div>

                        {typeTrigger === TYPE_TRIGGER.CONTINUES && (
                            <div className="form-box mb-3">
                                <h5 className="text-18px iot-font-500">
                                    Reminder:
                                </h5>
                                <p className="text-primary-blue-4 text-16px iot-mb-0">
                                    To avoid triggering the event repeatedly, it
                                    must be guarded with an AND condition using
                                    a non-event variable or a non-time range
                                    condition.
                                </p>
                            </div>
                        )}

                        <Button
                            variant="secondary"
                            className="pl-4 pr-4 mr-2"
                            onClick={() => {
                                history.push(`/event-details/${eventId}`);
                            }}
                        >
                            CANCEL
                        </Button>

                        <Button
                            variant="primary"
                            className="pl-4 pr-4"
                            onClick={handleAddOrUpdateCondition}
                        >
                            {Object.keys(conditionPayload || {}).length
                                ? "UPDATE"
                                : "ADD"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </ContentWrapper>
    );
};

export default EvaluateEventVariable;
