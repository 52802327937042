import { useState } from "react";

export const useBasicPlanModal = () => {
    const [modalShow, setModalShow] = useState(false);

    const toggleShowBasicPlanModal = () => {
        setModalShow(!modalShow);
    };

    return {
        toggleShowBasicPlanModal,
        showBasicPlanModal: modalShow,
    };
};
