import { useEffect, useState, useContext, memo, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { AppContext } from "context/appContext";
import { Dropdown } from "react-bootstrap";
import { getOneRegistry } from "service/gatewayService";
import { getOnlineOfflineValue } from "service/proqueryService";
import {
    HttpStatus,
    LastTimeSelectionStatusChart,
    PANEL_TOKEN_ZERO_TOKEN,
} from "constant";
import { convertMinutesToHours, isHttpSuccess } from "utils/functions";
import { faker } from "@faker-js/faker";
import ChartBox from "./ChartBox";
import useChartWidth from "hooks/useChartWidth";
import ChartActionDropdown from "components/dashboard/ChartActionDropdown";
import ContentWrapper from "components/content-wrapper/ContentWrapper";

const StatusChart = (props: any) => {
    const {
        panel,
        panel: { uuid: panelId, token, chart, attributes, name },
        selectedDashboardUUID,
        handleOnChangeDashboardUUID,
        zoomLevel,
    } = props;
    const panelToken = token[0] || "";
    const thisGatewayId = attributes[0]?.gateway_id;
    const chartType = chart;
    const { FORBIDDEN } = HttpStatus;
    const { storeData } = useContext(AppContext);
    const { organization } = storeData;
    const { currentOrgInfo } = organization;
    const { uuid: currOrgID, owner } = currentOrgInfo;
    const [gatewayName, setGatewayName] = useState("");
    const lastData = LastTimeSelectionStatusChart.map((i) => i.time);
    const [lastDataValue, setLastDataValue] = useState("1 Day");
    const [timeValue, setTimeValue] = useState("1d");
    const [onlineTime, setOnlineTime] = useState("0");
    const [offlineTime, setOfflineTime] = useState("0");
    const [totalTime, setTotalTime] = useState("1440");
    const [isLoading, setIsLoading] = useState(true);
    const [isOutOfPaymentToken, setIsOutOfPaymentToken] = useState(false);

    const boxElementRef = useRef<HTMLDivElement>(null);
    const { boxWidth } = useChartWidth({
        ref: boxElementRef,
        occupiedWidth: 80,
    });

    useEffect(() => {
        if (!currOrgID || !owner) return;
        const fetch = async () => {
            const registryRes: any = await getOneRegistry(thisGatewayId);
            if (isHttpSuccess(registryRes.status)) {
                const { name } = registryRes.data;
                setGatewayName(name);
            }
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panel, currOrgID, owner, thisGatewayId]);

    useEffect(() => {
        const fetch = async () => {
            const onlineOfflineRes: any = await getOnlineOfflineValue(
                thisGatewayId,
                timeValue,
                panelToken
            );

            if (isHttpSuccess(onlineOfflineRes.status)) {
                const value =
                    onlineOfflineRes.data?.data?.result[0]?.value[1] || "0";
                const _value =
                    Number(value) > Number(totalTime) ? totalTime : value;
                setOnlineTime(convertMinutesToHours(_value));
                setOfflineTime(
                    convertMinutesToHours(Number(totalTime) - Number(_value))
                );
            } else {
                if (onlineOfflineRes.response?.status === FORBIDDEN) {
                    if (
                        onlineOfflineRes.config.url.includes(
                            PANEL_TOKEN_ZERO_TOKEN
                        )
                    ) {
                        setIsOutOfPaymentToken(true);
                    }
                }
            }
            setIsLoading(false);
        };
        if (isOutOfPaymentToken) {
            return;
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOutOfPaymentToken, thisGatewayId, timeValue, totalTime, panelToken]);

    const handleLastData = (value: any) => {
        setLastDataValue(value);
        for (const i of LastTimeSelectionStatusChart) {
            if (i.time === value) {
                setTimeValue(i.dataValue);
                setTotalTime(i.totalMinutes);
            }
        }
    };

    const option: any = {
        backgroundColor: "#2F3A51",
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
            trigger: "item",
        },
        title: {
            show: true,
            left: 14,
            top: 15,
            text: name || `${gatewayName} Status Chart`,
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 18,
                overflow: "truncate",
                ellipsis: "...",
                width: boxWidth,
            },
            subtext: `Online/Offline Status \n\n{s2|Online - ${onlineTime} Hours}\n\n{s3|Offline - ${offlineTime} Hours}`,
            subtextStyle: {
                color: "#828FAB",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 14,
                overflow: "truncate",
                ellipsis: "...",
                rich: {
                    s1: {
                        color: "#828fab",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: 14,
                    },
                    s2: {
                        color: "#FC6464",
                        fontSize: 14,
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                    },
                    s3: {
                        color: "#9A6363",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                    },
                    s4: {
                        color: "#FFFFFF",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                    },
                    s5: {
                        color: "#828fab",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: 18,
                    },
                },
            },
        },
        legend: {
            show: false,
        },
        color: ["#FC6464", "#9A6363", "#3E4B67"],
        series: [
            {
                type: "pie",
                radius:
                    chartType.toUpperCase() === "PIE" ? "55%" : ["30%", "55%"],
                minAngle: offlineTime === "0" || onlineTime === "0" ? 0 : 5,
                top: 60,
                avoidLabelOverlap: true,
                label: {
                    formatter: "{b|{b} -} {d|{d}%}",
                    show: true,
                    position: "outside",
                    fontSize: 12,
                    rich: {
                        b: {
                            lineHeight: 22,
                            align: "center",
                        },
                        d: {
                            lineHeight: 22,
                            align: "center",
                        },
                        e: {
                            lineHeight: 10,
                            align: "center",
                        },
                    },
                },
                labelLine: {
                    show: true,
                    length: 5,
                    length2: 20,
                    showAbove: true,
                },
                data: [
                    {
                        value: onlineTime,
                        name: "Online",
                    },
                    {
                        value: offlineTime,
                        name: "Offline",
                    },
                ],
            },
        ],
    };

    const notEnoughPaymentTokenOption: any = {
        backgroundColor: "#2F3A51",
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
            trigger: "item",
        },
        title: {
            show: true,
            left: 14,
            top: 15,
            text: name || `${gatewayName} Status Chart`,
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 18,
                overflow: "truncate",
                ellipsis: "...",
            },
            subtext: `Online/Offline Status`,
            subtextStyle: {
                color: "#828FAB",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 14,
                overflow: "truncate",
                ellipsis: "...",
            },
        },
    };

    return (
        <ChartBox
            className={
                zoomLevel <= 0.5
                    ? "widget-box temperature w-100 h-30vh"
                    : "widget-box temperature w-100"
            }
            ref={boxElementRef}
        >
            <div className="widget">
                <ChartActionDropdown
                    panelId={panelId}
                    onDelete={handleOnChangeDashboardUUID}
                    selectedDashboardUUID={selectedDashboardUUID}
                />
                <Dropdown alignRight>
                    <Dropdown.Toggle variant="" className="last-data">
                        {lastDataValue}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {lastData.map((item) => {
                            return (
                                <Dropdown.Item
                                    key={faker.datatype.uuid()}
                                    onClick={() => handleLastData(item)}
                                    value={item}
                                >
                                    {item}
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <ContentWrapper isLoading={isLoading}>
                    <ReactECharts
                        className="chart-box"
                        option={
                            isOutOfPaymentToken
                                ? notEnoughPaymentTokenOption
                                : option
                        }
                        theme={"dark"}
                        notMerge={true}
                    />
                </ContentWrapper>
            </div>
        </ChartBox>
    );
};

export default memo(StatusChart);
