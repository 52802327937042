import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as _ from "lodash";
import DashboardCreateModal, { DashboardCreateModalProps } from ".";
import { useCreateDashboardStore } from "..";

const DashboardCreateSelectGatewayModal: React.FunctionComponent<
    DashboardCreateModalProps
> = (props) => {
    const { allRegistry } = useCreateDashboardStore();
    const [filteredRegistry, setFilteredRegistry] = React.useState<any[]>([]);
    const [searchString, setSearchString] = React.useState("");
    const { show, onHide, onSave } = props;
    const [values, setValues] = React.useState<any>({});
    const filteredOnboardedRegistry = filteredRegistry.filter((r) => r.info);
    const allOnboardedRegistry = allRegistry.filter((r) => r.info);

    React.useEffect(() => {
        if (searchString) {
            setFilteredRegistry(
                allRegistry.filter((item) =>
                    item.name
                        .toLowerCase()
                        .includes(String(searchString).trim().toLowerCase())
                )
            );
        } else {
            setFilteredRegistry(allRegistry);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    React.useEffect(() => {
        if (show) {
            // When value is a string list
            if (Array.isArray(props.values)) {
                const selectedGateways = {} as any;
                props.values.forEach((value: any) => {
                    _.set(
                        selectedGateways,
                        value,
                        allRegistry.find((item) => item.name === value)
                    );
                });

                setValues(selectedGateways);
            } else {
                setValues(props.values || {});
            }

            setFilteredRegistry(allRegistry);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <>
            <DashboardCreateModal
                title="Select Gateways"
                show={show}
                onHide={() => onHide && onHide()}
                footer={() => (
                    <div className="row w-100 justify-content-between align-items-center">
                        <strong className="text-gray">
                            {!!Object.keys(values).length &&
                                `${
                                    Object.keys(values).length
                                } Gateway(s) are selected`}
                        </strong>
                        <Button
                            variant="primary"
                            onClick={(event) => {
                                onSave && onSave(event, values);
                                onHide && onHide();
                            }}
                        >
                            ADD
                        </Button>
                    </div>
                )}
            >
                <Form className="d-flex flex-wrap">
                    <div className="d-flex align-items-center w-100 mb-4 modal-filter">
                        <div className="modal-option">
                            <Form.Check
                                id="gateway-check-all"
                                type="checkbox"
                                className="text-gray"
                                label="Select All"
                                custom
                                checked={
                                    !!Object.keys(values).length &&
                                    Object.keys(values).length ===
                                        _.union(
                                            Object.keys(values),
                                            Object.keys(
                                                _.keyBy(
                                                    searchString
                                                        ? filteredOnboardedRegistry
                                                        : allOnboardedRegistry,
                                                    "gateway_id"
                                                )
                                            )
                                        ).length
                                }
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setValues(
                                            _.keyBy(
                                                searchString
                                                    ? filteredOnboardedRegistry
                                                    : allOnboardedRegistry,
                                                "gateway_id"
                                            )
                                        );
                                    } else {
                                        setValues({});
                                    }
                                }}
                            />
                        </div>
                        <Form.Control
                            type="text"
                            placeholder="Search"
                            onChange={(e) => setSearchString(e.target.value)}
                        ></Form.Control>
                    </div>

                    {allRegistry.length > 0 ? (
                        Object.keys(
                            _.groupBy(
                                searchString ? filteredRegistry : allRegistry,
                                "groupName"
                            ) || {}
                        ).map((name: string) => {
                            const group = _.get(
                                _.groupBy(
                                    searchString
                                        ? filteredRegistry
                                        : allRegistry,
                                    "groupName"
                                ),
                                name,
                                []
                            );

                            return (
                                <div
                                    key={name}
                                    className="d-block relative w-100 mb-3"
                                >
                                    <h4 className="mb-0">{name}</h4>
                                    {group.map((subDevice: any) => {
                                        return (
                                            <div
                                                className="modal-option"
                                                key={subDevice.gateway_id}
                                            >
                                                <Form.Check
                                                    name="selected-gateway"
                                                    value={`${subDevice.name}id:${subDevice.gateway_id}`}
                                                    checked={values?.hasOwnProperty(
                                                        subDevice.gateway_id
                                                    )}
                                                    disabled={!subDevice.info}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setValues({
                                                                ...values,
                                                                [subDevice.gateway_id]:
                                                                    subDevice,
                                                            });
                                                        } else {
                                                            setValues(
                                                                _.omit(
                                                                    values,
                                                                    subDevice.gateway_id
                                                                )
                                                            );
                                                        }
                                                    }}
                                                    id={
                                                        "group-gateway" +
                                                        subDevice.gateway_id
                                                    }
                                                    custom
                                                    type="checkbox"
                                                    label={
                                                        <div>
                                                            <h4>
                                                                {subDevice.name}
                                                            </h4>{" "}
                                                            <p>
                                                                UUID:{" "}
                                                                {
                                                                    subDevice.gateway_id
                                                                }
                                                            </p>
                                                        </div>
                                                    }
                                                    className="float-left"
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    ) : (
                        <p className="w-100 d-flex justify-content-center">
                            No gateways to display
                        </p>
                    )}
                </Form>
            </DashboardCreateModal>
            <style>{`
                    .modal-filter .modal-option {
                        flex: 0 0 160px;
                        margin-right: 0.5rem;
                        padding: 0;
                    }

                    .modal-filter .modal-option .custom-control-label:before,
                    .modal-filter .modal-option .custom-control-label:after {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                `}</style>
        </>
    );
};

export default DashboardCreateSelectGatewayModal;
