import { FC, useEffect, useState } from "react";
import localStorageService from "service/localStorageService";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDatetime from "@mui/lab/AdapterMoment";
import { TextField } from "@mui/material";
import moment, { Moment } from "moment-timezone";
import { getLocalTimezone, parseDateWithTimezone } from "utils/functions";
import {
    DATE_TIME_RANGE_EARLY_MESSAGE,
    DATE_TIME_RANGE_INVALID_MESSAGE,
    InputDateTimeFormat,
} from "constant";
import { Row } from "react-bootstrap";

type DateTimeRangePickerProps = {
    value?: Moment[];
    onChange?: (value: Moment[]) => void;
    onError?: (error: string) => void;
    className?: string;
    timezone?: string;
    inline?: boolean;
};

const DateTimeRangePicker: FC<DateTimeRangePickerProps> = ({
    value,
    timezone,
    onChange,
    onError,
    inline,
}) => {
    const defaultTimeZone = getLocalTimezone();
    const timeZoneObject = localStorageService.getItem("timezones");

    const [startDateTime, setStartDateTime] = useState(
        moment.utc().subtract(24, "hours").tz(defaultTimeZone)
    );
    const [endDateTime, setEndDateTime] = useState(
        moment.utc().tz(defaultTimeZone)
    );

    const [minDate, setMinDate] = useState(
        moment.utc("1970-01-01").tz(defaultTimeZone)
    );
    const [errMsg, setErrMsg] = useState("");
    const _timezone = timezone ?? defaultTimeZone;

    useEffect(() => {
        if (value && value.length > 0) {
            setStartDateTime(value[0]);
            setEndDateTime(value[1]);
        }
    }, [value]);

    useEffect(() => {
        const start = moment.tz(+startDateTime, _timezone);
        const end = moment(moment.tz(+endDateTime, _timezone));

        setStartDateTime(start);
        setEndDateTime(end);
        setMinDate(moment.tz(+minDate, _timezone));

        onChange?.([start, end]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone]);

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateTime, endDateTime, minDate]);

    const handleStartDateChange = (newValue?: Moment | null) => {
        if (newValue) {
            const value = parseDateWithTimezone(
                newValue,
                timeZoneObject[_timezone]
            ).tz(_timezone);
            setEndDateTime(value);
            onChange?.([value, endDateTime]);
        }
    };

    const handleEndDateChange = (newValue?: Moment | null) => {
        if (newValue) {
            const value = parseDateWithTimezone(
                newValue,
                timeZoneObject[_timezone]
            ).tz(_timezone);
            setEndDateTime(value);
            onChange?.([startDateTime, value]);
        }
    };

    const validate = () => {
        let message = "";
        const now = moment.utc().tz(_timezone);
        if (startDateTime.isBefore(minDate)) {
            message = DATE_TIME_RANGE_EARLY_MESSAGE.fill({
                time: minDate.format(InputDateTimeFormat),
            });
        } else if (!endDateTime.isAfter(startDateTime, "minutes")) {
            message = DATE_TIME_RANGE_INVALID_MESSAGE;
        } else if (endDateTime.isAfter(now, "minutes")) {
            message = "End time must be before future time";
        }

        setErrMsg(message);
        onError?.(message);
    };

    const render = () => (
        <Row>
            <div className={`dateTimeFieldWrapper ${inline ? "inline" : ""}`}>
                <div className="dateTimeField">From</div>
                <DateTimePicker
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label=""
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                ...props.InputProps,
                                inputProps: {
                                    ...props?.inputProps,
                                    "aria-label": "start-time",
                                },
                            }}
                            onChange={(e) => {
                                handleStartDateChange(
                                    parseDateWithTimezone(
                                        moment.tz(
                                            e.target.value,
                                            InputDateTimeFormat,
                                            _timezone
                                        ),
                                        timeZoneObject[_timezone]
                                    ).tz(_timezone)
                                );
                            }}
                        />
                    )}
                    inputFormat={InputDateTimeFormat}
                    value={startDateTime}
                    onChange={(newValue: any) => {
                        handleStartDateChange(newValue);
                    }}
                    mask="____/__/__ __:__ _M"
                    minDateTime={minDate}
                    maxDateTime={endDateTime}
                />
            </div>

            <div className={`dateTimeFieldWrapper ${inline ? "inline" : ""}`}>
                <div className="dateTimeField">To</div>
                <DateTimePicker
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label=""
                            InputLabelProps={{
                                shrink: false,
                            }}
                            InputProps={{
                                ...props.InputProps,
                                inputProps: {
                                    ...props?.inputProps,
                                    "aria-label": "end-time",
                                },
                            }}
                            onChange={(e) => {
                                handleEndDateChange(
                                    parseDateWithTimezone(
                                        moment.tz(
                                            e.target.value,
                                            InputDateTimeFormat,
                                            _timezone
                                        ),
                                        timeZoneObject[_timezone]
                                    ).tz(_timezone)
                                );
                            }}
                        />
                    )}
                    inputFormat={InputDateTimeFormat}
                    value={endDateTime}
                    onChange={(newValue: any) => {
                        handleEndDateChange(newValue);
                    }}
                    mask="____/__/__ __:__ _M"
                    minDateTime={startDateTime}
                />
            </div>

            {errMsg && (
                <div
                    className={`dateTimeFieldWrapper ${inline ? "w-100" : ""}`}
                >
                    <div
                        className={`dateTimeField ${inline ? "d-none" : ""}`}
                    />
                    <p role="alert" className="text-danger">
                        {errMsg}
                    </p>
                </div>
            )}
        </Row>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDatetime}>
            {render()}
        </LocalizationProvider>
    );
};

export default DateTimeRangePicker;
