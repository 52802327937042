import { Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import refreshIcon from "assets/svg/refresh-green.svg";

import { checkActiveStatus } from "utils/gatewayFunctions";
import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";

import "assets/css/gateway.css";
import { GatewayLDSUDetailsProps } from "types/Gateways";
import { GATEWAY_NAME_MESSAGE, Patterns } from "constant";
import { isValidText } from "utils/functions";
import { ArrowIcon, Box } from "components/common";

const GatewayLDSUDetails = ({
    nameEditable,
    handleNameChange,
    isNameEditing,
    handleClickEdit,
    handleClickUpdate,
    isRefreshing,
    refreshThisGateway,
    timestampDetails,
    registryDetails,
    hasOnboard,
    gatewayId,
}: GatewayLDSUDetailsProps) => {
    return (
        <Row>
            <Col sm={12}>
                <div className="form-box mb-2">
                    <div className="gateway-detail-container">
                        <div className="gateway-detail-item top">
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleClickUpdate();
                                }}
                            >
                                <Row>
                                    <Col xs="12">
                                        <Form.Label>Gateway Name</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="8">
                                        <Form.Group className="mb-0">
                                            <Form.Control
                                                type="text"
                                                required
                                                aria-label="name"
                                                defaultValue={nameEditable}
                                                onChange={handleNameChange}
                                                disabled={!isNameEditing}
                                                isInvalid={
                                                    !isValidText(
                                                        "name",
                                                        {
                                                            name: nameEditable,
                                                        },
                                                        { name: "" },
                                                        Patterns.gatewayNamePattern
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {GATEWAY_NAME_MESSAGE}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="4">
                                        {!isNameEditing ? (
                                            <HoverAuthorizeTooltip
                                                className="w-100 mt-2 mt-md-0"
                                                permission="gateway:update"
                                            >
                                                <Button
                                                    type="button"
                                                    variant={"primary"}
                                                    className="w-100"
                                                    onClick={handleClickEdit}
                                                >
                                                    Edit
                                                </Button>
                                            </HoverAuthorizeTooltip>
                                        ) : (
                                            <Button
                                                type="button"
                                                variant={"primary"}
                                                className="w-100 mt-md-0"
                                                onClick={handleClickUpdate}
                                                disabled={
                                                    !isValidText(
                                                        "name",
                                                        {
                                                            name: nameEditable,
                                                        },
                                                        { name: "" },
                                                        Patterns.gatewayNamePattern
                                                    )
                                                }
                                            >
                                                Update
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className="gateway-detail-bottom-container">
                            <div className="gateway-detail-item bottom">
                                <Row>
                                    <Col xs="12">
                                        <Form.Label>Status</Form.Label>
                                    </Col>
                                </Row>

                                <div className="d-flex align-items-center">
                                    <div className="ginfo">
                                        {isRefreshing
                                            ? "loading ..."
                                            : checkActiveStatus(
                                                  timestampDetails.heartbeat,
                                                  timestampDetails.info
                                              ) || "-"}
                                    </div>
                                    <img
                                        className={
                                            isRefreshing
                                                ? "btn refresh-animation"
                                                : "btn"
                                        }
                                        src={refreshIcon}
                                        alt="refresh"
                                        onClick={refreshThisGateway}
                                        onKeyDown={refreshThisGateway}
                                    />
                                </div>
                            </div>
                            <div className="gateway-detail-item bottom">
                                <Row>
                                    <Col xs="12">
                                        <Form.Label>UUID</Form.Label>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center h-38px">
                                    <h6 className="ginfo">
                                        {registryDetails.gateway_id}
                                    </h6>
                                </div>
                            </div>
                            <div className="gateway-detail-item bottom">
                                <Row>
                                    <Col xs="12">
                                        <Form.Label>
                                            {" "}
                                            Firmware Version
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center h-38px">
                                    <h6 className="ginfo">
                                        {registryDetails.info
                                            ? `${registryDetails.info.FWV}`
                                            : "-"}
                                    </h6>
                                </div>
                            </div>
                            <div className="gateway-detail-item bottom">
                                <Row>
                                    <Col xs="12">
                                        <Form.Label> Serial Number</Form.Label>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center h-38px">
                                    <h6 className="ginfo">
                                        {registryDetails.serial}
                                    </h6>
                                </div>
                            </div>
                            <div className="gateway-detail-item bottom last">
                                {hasOnboard && (
                                    <Box>
                                        <Link
                                            to={{
                                                pathname: `/gateway-details/${gatewayId}/m2m`,
                                                state: {
                                                    gatewayName:
                                                        registryDetails.name,
                                                },
                                            }}
                                            className="w-100"
                                        >
                                            Machine-to-Machine (M2M)
                                            <ArrowIcon className="float-right" />
                                        </Link>
                                    </Box>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default GatewayLDSUDetails;
