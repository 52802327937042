import moment, { DurationInputObject, Moment } from "moment";
import { Dropdown } from "react-bootstrap";
import DateRangePicker, {
    Props as DateRangePickerProps,
} from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "assets/css/daterangepicker.css";
import { CHART_CUSTOM_RANGE_OPTION } from "constant";
import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import styledConst from "styles";
import { debounce, keys } from "lodash";

type TimeRangePickerSelection = {
    chosenLabel: string;
    startDate: Moment;
    endDate: Moment;
};
export type TimeRangeSelection = {
    range: string;
    startDate: Moment;
    endDate: Moment;
};

type TimeRangeDropdownProps = DateRangePickerProps & {
    selectedTimeRange: string;
    ranges?: Record<string, Moment[]>;
    onChange?: (value: TimeRangeSelection) => void;
    startDate?: Moment;
    endDate?: Moment;
    maxDate?: Moment;
    maxSpan?: DurationInputObject | boolean;
    className?: string;
    dropdown?: boolean;
};

const DisplayTimeRangeText: Record<string, string> = {
    "Last 5 Minutes": "Last 5 mins",
    "Last 15 Minutes": "Last 15 mins",
    "Last 30 Minutes": "Last 30 mins",
    "Last 1 Hour": "Last 1 hr",
    "Last 6 Hours": "Last 6 hrs",
    "Last 12 Hours": "Last 12 hrs",
    "Last 24 Hours": "Last 24 hrs",
    "Last 7 Days": "Last 7 days",
    "Last 15 Days": "Last 15 days",
    "Last 1 Month": "Last 1 month",
    "Last 3 Months": "Last 3 months",
    "Last 6 Months": "Last 6 months",
};

const CustomDropDown = styled(Dropdown)`
    > .dropdown-toggle {
        color: ${styledConst.Primary_Green_1};
        background-color: #2f3a51;
        border-color: #2f3a51;
        padding: 0.375rem;

        &.btn-secondary:hover,
        &.btn-secondary:not(:disabled):not(.disabled):active,
        &.btn-secondary:focus {
            color: ${styledConst.Primary_Green_1};
            background-color: #2f3a51;
            border-color: #2f3a51;
        }
    }

    &.show {
        > .dropdown-toggle {
            color: ${styledConst.Primary_Green_1};
            background-color: #2f3a51;
            border-color: #2f3a51;
        }
    }

    &.is-btn {
        > .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }
`;

const TimeRangeDropdown: FC<TimeRangeDropdownProps> = ({
    ranges,
    selectedTimeRange = keys(ranges)[0],
    onChange,
    startDate = moment().subtract("minute", 5),
    endDate = moment(),
    maxDate = moment(),
    maxSpan = false,
    dropdown = false,
    className,
    ...restProps
}) => {
    const [initialStartDate, setInitialStartDate] = useState<Moment>();
    const [initialEndDate, setInitialEndDate] = useState<Moment>();
    useEffect(() => {
        let _startDate = startDate;
        let _endDate = endDate;

        if (ranges && selectedTimeRange !== CHART_CUSTOM_RANGE_OPTION) {
            const lastTimeOption = ranges[selectedTimeRange];
            _startDate = lastTimeOption[0];
            _endDate = lastTimeOption[1];
        }

        setInitialStartDate(_startDate);
        setInitialEndDate(_endDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges, selectedTimeRange]);

    const getSelectedLabel = () => {
        if (String(selectedTimeRange) === CHART_CUSTOM_RANGE_OPTION) {
            return CHART_CUSTOM_RANGE_OPTION;
        } else {
            return DisplayTimeRangeText[selectedTimeRange];
        }
    };

    const applySelectedTimeRange = debounce(
        (
            event: any,
            { chosenLabel: range, startDate, endDate }: TimeRangePickerSelection
        ) => {
            onChange?.({
                range,
                startDate,
                endDate,
            });
        },
        200
    );

    const onShow = (e: any, picker: any) => {
        picker.container.addClass("custom-daterangepicker");
    };

    return (
        <CustomDropDown
            alignRight
            {...restProps}
            className={`${className} ${dropdown ? "" : "is-btn"}`}
        >
            {initialEndDate && (
                <DateRangePicker
                    initialSettings={{
                        timePicker: true,
                        startDate: initialStartDate?.toDate(),
                        endDate: initialEndDate?.toDate(),
                        maxDate,
                        maxSpan: maxSpan,
                        locale: {
                            format: "DD/MM/YYYY hh:mm A",
                        },
                        ranges: ranges,
                    }}
                    onApply={applySelectedTimeRange}
                    onShow={onShow}
                >
                    <Dropdown.Toggle variant="secondary">
                        {getSelectedLabel()}
                    </Dropdown.Toggle>
                </DateRangePicker>
            )}
        </CustomDropDown>
    );
};

export default TimeRangeDropdown;
