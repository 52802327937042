import { Button, Modal, Spinner } from "react-bootstrap";
import styled from "@emotion/styled";

import { useContext, useEffect, useRef, useState } from "react";
import dropin from "braintree-web-drop-in";
import {
    getClientToken,
    updatePaymentDetails,
} from "service/subscriptionService";
import { isHttpSuccess } from "utils/functions";

import { AppContext } from "context/appContext";
import { ACTION_FAIL_MESSAGE, HttpStatus } from "constant";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { useSubscriptionContext } from "context/subscriptionContext";

type EditPaymentModalProps = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onClose?: () => void;
};

export const EditPaymentModal = (props: EditPaymentModalProps) => {
    // ========================= CONTEXT/STATES =========================
    const { show, onClose, setShow } = props;
    const {
        storeData: {
            subscription: { currentSubscriptionId },
        },
    } = useContext(AppContext);
    const {
        setSubscription,
        subscription: { uuid },
    }: any = useSubscriptionContext();

    const handleClose = () => {
        setShow(false);
        onClose && onClose();
    };

    const updateButton: any = useRef();
    const [braintreeInstance, setBraintreeInstance] = useState(Object);
    const [braintreeLoaded, setBraintreeLoaded] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);

    // ================== USE EFFECT ==================

    useEffect(() => {
        setShow(show);
    }, [show, setShow]);

    useEffect(() => {
        initializeDropin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ================== BRAINTREE DROPIN INITIALIZATION FUNCTIONS ==================

    const initializeDropin = async () => {
        const response: any = await getClientToken();
        if (isHttpSuccess(response.status)) {
            const { data: clientToken } = response;

            createDropin(clientToken);
        }
    };

    const createDropin = async (clientToken: any) => {
        setBraintreeLoaded(true);

        dropin.create(
            {
                authorization: clientToken,
                container: "#braintree-drop-in-div",
                preselectVaultedPaymentMethod: false,
            },
            (error: any, instance: any) => {
                setBraintreeInstance(instance);
                if (instance) {
                    const methodsLabel: any = document.querySelector(
                        '[data-braintree-id="methods-label"]'
                    );
                    methodsLabel.textContent = "Change selected payment method";
                }
            }
        );
    };
    // ================== FUNCTIONS ==================

    const updatePaymentInformation = async () => {
        setActionLoading(true);

        if (braintreeInstance) {
            braintreeInstance?.requestPaymentMethod(
                (error: any, payload: any) => {
                    if (error) {
                        braintreeInstance.clearSelectedPaymentMethod();
                        setActionLoading(false);
                        showErrorAlert({
                            message: ACTION_FAIL_MESSAGE,
                        });
                        return;
                    }

                    const updatePayment = async () => {
                        const response: any = await updatePaymentDetails({
                            subscriptionId: currentSubscriptionId || uuid,
                            nonce: payload.nonce,
                        });

                        if (isHttpSuccess(response.status)) {
                            showSuccessAlert({
                                message: `Successfully updated payment information.`,
                            });
                            setSubscription(response.data.data);
                            setActionLoading(false);
                            setShow(false);
                        } else {
                            showErrorAlert({
                                message:
                                    response?.response?.data?.description ||
                                    ACTION_FAIL_MESSAGE,
                            });
                            setActionLoading(false);
                        }
                        if (
                            response?.response?.status ===
                            HttpStatus.BAD_REQUEST
                        ) {
                            braintreeInstance.clearSelectedPaymentMethod();
                        }
                    };
                    updatePayment();
                }
            );
        } else {
            initializeDropin();
        }
    };

    return (
        <>
            <Wrapper
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="token-conversion-rate-modal"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Edit Payment
                    </Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleClose}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="updatePaymentModal"
                        id={"braintree-drop-in-div"}
                    />
                    {!braintreeLoaded && (
                        <div className="braintree-load-spinner-wrapper">
                            <Spinner
                                className="braintree-load-spinner"
                                animation="border"
                                variant="primary"
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        ref={updateButton}
                        onClick={updatePaymentInformation}
                    >
                        UPDATE
                    </Button>
                </Modal.Footer>
            </Wrapper>
            <Modal
                centered
                show={actionLoading}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-5 mr-4">
                    <Spinner
                        className="centered-spinner"
                        animation="border"
                        variant="primary"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const Wrapper = styled(Modal)`
    @media (min-width: 768px) {
        .modal-dialog {
            min-width: 780px;
        }
    }
`;
