import { useState, Dispatch, MouseEvent, FormEvent } from "react";
import { Link, Redirect } from "react-router-dom";
import { Col, Button, Form } from "react-bootstrap";
import { PasswordField } from "components/common/form";
import { TERMS_OF_SERVICE_LINK } from "constant";

type LoginFormProps = {
    email?: string;
    setEmail?: Dispatch<string>;
    setPassword?: Dispatch<string>;
    isLoggedIn?: boolean;
    handleLogin: (
        e?: MouseEvent<HTMLElement> | FormEvent<HTMLFormElement>
    ) => void;
    isError?: boolean;
    isLoading?: boolean;
    errorMessage?: string;
    setIsLoginWithPhoneNumber?: (isPhoneNumber: boolean) => void;
};

const LoginForm = (props: LoginFormProps) => {
    const {
        setEmail,
        setPassword,
        isLoggedIn,
        handleLogin,
        setIsLoginWithPhoneNumber,
        isLoading,
        isError,
        errorMessage,
    } = props;
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [valueIsMobileNumber, setValueIsMobileNumber] = useState(false);
    const [isNotFirstValidation, setIsNotFirstValidation] = useState(false);
    const [loginInfo, setLoginInfo] = useState({
        userName: "",
        password: "",
    });

    if (isLoggedIn) {
        return <Redirect to={`/dashboard`} />;
    }

    const validateAndLogin = (e: any) => {
        setIsNotFirstValidation(true);
        if (!loginInfo.password.trim()) {
            setLoginInfo({
                ...loginInfo,
                password: "",
            });
            setPassword?.("");
            return;
        }
        if (loginInfo.userName && loginInfo.password) {
            handleLogin();
        }
    };

    const handleEmailMobileNUmberChange = (e: any) => {
        const { value: _value } = e.target;
        const value = _value.trim().toLowerCase();
        const isPhoneNumber = /^[+\d]/.test(value);
        const isValidPhoneNumber = /^(\+)\d{8,}\d$/.test(value);

        setLoginInfo({
            ...loginInfo,
            userName: value,
        });

        if (!value) {
            setValueIsMobileNumber(false);
            setIsValidPhoneNumber(true);
            return;
        }

        if (isPhoneNumber) {
            setValueIsMobileNumber(isPhoneNumber);
            setIsValidPhoneNumber(isValidPhoneNumber);
        }

        setIsLoginWithPhoneNumber?.(isPhoneNumber);
        setEmail?.(value);
    };

    const handlePasswordChange = (e: any) => {
        const { value } = e.target;

        setLoginInfo({
            ...loginInfo,
            password: value,
        });
        setPassword?.(value);
    };

    return (
        <Col lg="6" sm="12" className="login-form">
            <div className="login-box">
                <div className="login-table">
                    <div className="login-text">
                        <h4>Sign In</h4>
                    </div>
                    <Form
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) validateAndLogin(e);
                        }}
                        onSubmit={validateAndLogin}
                    >
                        <Form.Group>
                            <Form.Label>Email / Mobile Number</Form.Label>
                            <Form.Control
                                type="text"
                                aria-label="username"
                                required
                                aria-describedby="emailHelp"
                                placeholder="Please enter your email/mobile number"
                                onChange={handleEmailMobileNUmberChange}
                            />
                            {isNotFirstValidation &&
                                !loginInfo.userName.trim() && (
                                    <p
                                        className="error-message my-0 mt-1"
                                        role="alert"
                                    >
                                        Please enter email address/mobile
                                        number.
                                    </p>
                                )}
                            {!isValidPhoneNumber && valueIsMobileNumber && (
                                <p
                                    className="error-message my-0 mt-1"
                                    role="alert"
                                >
                                    Mobile number start with '+' and minimum 8
                                    digits
                                </p>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>

                            <PasswordField
                                aria-label="password"
                                required
                                placeholder="Please enter your password"
                                onChange={handlePasswordChange}
                            />

                            {isNotFirstValidation &&
                                !loginInfo.password.trim() && (
                                    <p className="error-message my-0 mt-1">
                                        Please enter a password.
                                    </p>
                                )}
                        </Form.Group>
                        <Link className="forgot link" to="/forgot-password">
                            Forgot password?
                        </Link>
                        <Button
                            className="btn btn-primary"
                            onClick={validateAndLogin}
                            disabled={isLoading}
                        >
                            {props.isLoading ? "Loading ..." : "LOGIN"}
                        </Button>
                        <div className="loginf">
                            {isError && (
                                <p aria-label="error-label" role="alert">
                                    {errorMessage}
                                </p>
                            )}
                        </div>
                    </Form>

                    <div></div>
                    <div className="mt-4">
                        By logging into an account, you are agreeing with our{" "}
                        <a
                            href={TERMS_OF_SERVICE_LINK}
                            target="_blank"
                            rel="noreferrer"
                            className="link text-decoration-line"
                        >
                            Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                            href="https://brtsys.com/privacy-policy-2/"
                            target="_blank"
                            rel="noreferrer"
                            className="link text-decoration-line"
                        >
                            Privacy Policy
                        </a>{" "}
                        and you confirm that you are above 13 years of age.
                    </div>
                    <div className="mt-5 pb-5">
                        Don’t have an account?{" "}
                        <Link to="/sign-up" className="link">
                            Sign Up
                        </Link>
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default LoginForm;
