import { OrganisationPermissions, DashboardTagOptions } from "types";

export const CHANGE_ORGANIZATION = "CHANGE_ORGANIZATION";
export const STORE_ORGANIZATION = "STORE_ORGANIZATION";
export const VERIFY_ORG_OWNER = "VERIFY_ORG_OWNER";
export const IS_USER_ORG_LOADED = "IS_USER_ORG_LOADED";

export const STORE_USER = "STORE_USER";
export const STORE_ORG_MEMBERS = "STORE_ORG_MEMBERS";
export const STORE_ORG_GROUPS = "STORE_ORG_GROUPS";
export const STORE_ORG_ROLES = "STORE_ORG_ROLES";
export const STORE_ORG_ACTIVE_TAB = "STORE_ORG_ACTIVE_TAB";

export const STORE_DASHBOARD = "STORE_DASHBOARD";
export const STORE_PANELS = "STORE_PANELS";
export const CHANGE_DASHBOARD = "CHANGE_DASHBOARD";

export const STORE_TOKEN_BALANCE = "STORE_TOKEN_BALANCE";
export const UPDATE_SUBSCRIPTION_STATUS = "UPDATE_SUBSCRIPTION_STATUS";

export const STORE_SYSTEM_PERMISSIONS = "STORE_SYSTEM_PERMISSIONS";
export const RESTORE_DATA = "RESTORE_DATA";

export const UPDATE_MODAL_DATA = "UPDATE_MODAL_DATA";

export const STORE_SELECTED_TAB = "STORE_SELECTED_TAB";

export const STORE_SUBSCRIPTION_LIST = "STORE_SUBSCRIPTION_LIST";
export const STORE_SUBSCRIPTION_ID = "STORE_SUBSCRIPTION_ID";

export const changeOrganizationAction = (data: any) => ({
    type: CHANGE_ORGANIZATION,
    payload: data,
});

export const storeOrganizationAction = (data: { orgList: Array<any> }) => ({
    type: STORE_ORGANIZATION,
    payload: data,
});

export const verifyOrgOwnerAction = (data: { isOwner: boolean }) => ({
    type: VERIFY_ORG_OWNER,
    payload: data,
});

export const updateIsUserOrgLoadedAction = (data: { isLoaded: boolean }) => ({
    type: IS_USER_ORG_LOADED,
    payload: data,
});

export const storeUserAction = (data: {
    userData: object;
    isLoaded?: boolean;
}) => ({
    type: STORE_USER,
    payload: data,
});

export const storeOrgMembersAction = (data: { orgMembers: Array<any> }) => ({
    type: STORE_ORG_MEMBERS,
    payload: data,
});

export const storeOrgGroupsAction = (data: { orgGroups: Array<any> }) => ({
    type: STORE_ORG_GROUPS,
    payload: data,
});

export const storeOrgRolesAction = (data: { orgRoles: Array<any> }) => ({
    type: STORE_ORG_ROLES,
    payload: data,
});

export const storeOgrActiveTabAction = (data: { tab: string }) => ({
    type: STORE_ORG_ACTIVE_TAB,
    payload: data,
});

export const storeSystemPermissions = (data: {
    permissions: OrganisationPermissions;
    loaded: boolean;
}) => ({
    type: STORE_SYSTEM_PERMISSIONS,
    payload: data,
});

export const restoreData = () => ({
    type: RESTORE_DATA,
    payload: null,
});

export const updateModalDataAction = (data: {
    isShow: boolean;
    mainMessage: string;
    subMessage?: Array<string>;
    redirectTo?: string;
}) => {
    return {
        type: UPDATE_MODAL_DATA,
        payload: data,
    };
};

export const storeDashboardAction = (data: {
    dashboardList: Array<DashboardTagOptions>;
    targetId?: string;
}) => ({
    type: STORE_DASHBOARD,
    payload: data,
});

export const changeDashboardAction = (data: {
    dashboard: DashboardTagOptions;
}) => ({
    type: CHANGE_DASHBOARD,
    payload: data,
});

export const storePanelsAction = (data: { panels: Array<any> }) => ({
    type: STORE_PANELS,
    payload: data,
});

export const storeTokenBalanceAction = (data: {
    tokenBalance: number;
    isLoaded: boolean;
}) => ({
    type: STORE_TOKEN_BALANCE,
    payload: data,
});

export const storeSubscriptionAction = (data: any) => ({
    type: STORE_SUBSCRIPTION_LIST,
    payload: data,
});

export const storeSubscriptionID = (data: any) => ({
    type: STORE_SUBSCRIPTION_ID,
    payload: data,
});

export const updateSubscriptionStatus = (data: {
    isSubscribed: boolean;
    hasSubscribed: boolean;
}) => ({
    type: UPDATE_SUBSCRIPTION_STATUS,
    payload: data,
});

export const updateSelectedTab = (data: string) => ({
    type: STORE_SELECTED_TAB,
    payload: data,
});
