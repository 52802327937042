/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import {
  AccountInfoPatch,
  AssociateWithSwTokenData,
  ChangePassword,
  ChangePasswordData,
  ConfirmForgot,
  ConfirmForgotPasswordWithCodeData,
  ConfirmSighUpWithCodeData,
  ConfirmSignUp,
  CreateUserTokenData,
  DeleteDeviceTokenData,
  DeleteDeviceTokenInfoByUuidData,
  DeleteUserTokenByUuidData,
  ForgotPasswordData,
  GetAccountData,
  GetDeviceTokenByUuidData,
  GetDeviceTokensData,
  GetUserTokenByUuidData,
  GetUserTokensData,
  Login,
  LoginData,
  LoginWithChallengeData,
  LogoutData,
  MfaPreference,
  Password,
  RefreshAccessTokenData,
  RegisterDeviceTokenData,
  ReSendConfirmationCodeData,
  RespondAuth,
  RevokeUserTokenByUuidData,
  SendVerificationCodeData,
  SignUp,
  SignUpData,
  TokenPatch,
  TokenPost,
  UpdateAccountData,
  UpdateDeviceTokenInfoByUuidData,
  UpdateMfaData,
  UpdateUserTokenByUuidData,
  UserTokenPatch,
  UserTokenPost,
  VerifyPhone,
  VerifyPhoneNumberByCodeData,
  VerifySwTokenByCodeData,
  VerifyToken,
} from "../models";

export class Account<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags account
   * @name ReSendConfirmationCode
   * @summary Request to resend confirmation code
   * @request GET:/account/signup
   */
  reSendConfirmationCode = (
    query: {
      /** Email address */
      email: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ReSendConfirmationCodeData, void>({
      path: `/account/signup`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name ConfirmSighUpWithCode
   * @summary Confirm sign up with confirmation code
   * @request PATCH:/account/signup
   */
  confirmSighUpWithCode = (data: ConfirmSignUp, params: RequestParams = {}) =>
    this.http.request<ConfirmSighUpWithCodeData, void>({
      path: `/account/signup`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name SignUp
   * @summary Sign up new account
   * @request POST:/account/signup
   */
  signUp = (data: SignUp, params: RequestParams = {}) =>
    this.http.request<SignUpData, void>({
      path: `/account/signup`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name TerminateAccount
   * @summary Request to terminate account
   * @request POST:/account/terminate
   * @secure
   */
  terminateAccount = (data: Password, params: RequestParams = {}) =>
    this.http.request<any, void>({
      path: `/account/terminate`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name LoginWithChallenge
   * @summary Finish login with challenge response code
   * @request PATCH:/account/login
   */
  loginWithChallenge = (data: RespondAuth, params: RequestParams = {}) =>
    this.http.request<LoginWithChallengeData, void>({
      path: `/account/login`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name Login
   * @summary Login account
   * @request POST:/account/login
   */
  login = (data: Login, params: RequestParams = {}) =>
    this.http.request<LoginData, void>({
      path: `/account/login`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name Logout
   * @summary Global logout account
   * @request GET:/account/logout
   * @secure
   */
  logout = (params: RequestParams = {}) =>
    this.http.request<LogoutData, void>({
      path: `/account/logout`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags password
   * @name ChangePassword
   * @summary Change password
   * @request PATCH:/account/password
   * @secure
   */
  changePassword = (data: ChangePassword, params: RequestParams = {}) =>
    this.http.request<ChangePasswordData, void>({
      path: `/account/password`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags password
   * @name ForgotPassword
   * @summary Request forgot password confirmation code
   * @request GET:/account/forgot
   */
  forgotPassword = (
    query: {
      /** Email address */
      email: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ForgotPasswordData, void>({
      path: `/account/forgot`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags password
   * @name ConfirmForgotPasswordWithCode
   * @summary Confirm forgot password with confirmation code
   * @request PATCH:/account/forgot
   */
  confirmForgotPasswordWithCode = (data: ConfirmForgot, params: RequestParams = {}) =>
    this.http.request<ConfirmForgotPasswordWithCodeData, void>({
      path: `/account/forgot`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name GetAccount
   * @summary Get account info
   * @request GET:/account/info
   * @secure
   */
  getAccount = (params: RequestParams = {}) =>
    this.http.request<GetAccountData, void>({
      path: `/account/info`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name UpdateAccount
   * @summary Update account info
   * @request PATCH:/account/info
   * @secure
   */
  updateAccount = (data: AccountInfoPatch, params: RequestParams = {}) =>
    this.http.request<UpdateAccountData, void>({
      path: `/account/info`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name SendVerificationCode
   * @summary Request to send phone number verification code
   * @request GET:/account/phone
   * @secure
   */
  sendVerificationCode = (params: RequestParams = {}) =>
    this.http.request<SendVerificationCodeData, void>({
      path: `/account/phone`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name VerifyPhoneNumberByCode
   * @summary Verify phone number with code
   * @request PATCH:/account/phone
   * @secure
   */
  verifyPhoneNumberByCode = (data: VerifyPhone, params: RequestParams = {}) =>
    this.http.request<VerifyPhoneNumberByCodeData, void>({
      path: `/account/phone`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name AssociateWithSwToken
   * @summary Request to associate software token
   * @request GET:/account/swtoken
   * @secure
   */
  associateWithSwToken = (params: RequestParams = {}) =>
    this.http.request<AssociateWithSwTokenData, void>({
      path: `/account/swtoken`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name VerifySwTokenByCode
   * @summary Verify software token with code
   * @request PATCH:/account/swtoken
   * @secure
   */
  verifySwTokenByCode = (data: VerifyToken, params: RequestParams = {}) =>
    this.http.request<VerifySwTokenByCodeData, void>({
      path: `/account/swtoken`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name UpdateMfa
   * @summary Update MFA preference
   * @request PATCH:/account/mfa
   * @secure
   */
  updateMfa = (data: MfaPreference, params: RequestParams = {}) =>
    this.http.request<UpdateMfaData, void>({
      path: `/account/mfa`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags token
   * @name RefreshAccessToken
   * @summary Refresh access token
   * @request POST:/account/token
   * @secure
   */
  refreshAccessToken = (params: RequestParams = {}) =>
    this.http.request<RefreshAccessTokenData, void>({
      path: `/account/token`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags device_token
   * @name DeleteDeviceToken
   * @summary Delete device token info by uuid
   * @request DELETE:/account/devicetoken
   * @secure
   */
  deleteDeviceToken = (
    query: {
      /** Device token value */
      token: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DeleteDeviceTokenData, void>({
      path: `/account/devicetoken`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags device_token
   * @name GetDeviceTokens
   * @summary Get all device tokens
   * @request GET:/account/devicetoken
   * @secure
   */
  getDeviceTokens = (params: RequestParams = {}) =>
    this.http.request<GetDeviceTokensData, void>({
      path: `/account/devicetoken`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags device_token
   * @name RegisterDeviceToken
   * @summary Register new device token
   * @request POST:/account/devicetoken
   * @secure
   */
  registerDeviceToken = (data: TokenPost, params: RequestParams = {}) =>
    this.http.request<RegisterDeviceTokenData, void>({
      path: `/account/devicetoken`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags device_token
   * @name DeleteDeviceTokenInfoByUuid
   * @summary Delete device token info by uuid
   * @request DELETE:/account/devicetoken/{token_uuid}
   * @secure
   */
  deleteDeviceTokenInfoByUuid = (tokenUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteDeviceTokenInfoByUuidData, void>({
      path: `/account/devicetoken/${tokenUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags device_token
   * @name GetDeviceTokenByUuid
   * @summary Get device token info by uuid
   * @request GET:/account/devicetoken/{token_uuid}
   * @secure
   */
  getDeviceTokenByUuid = (tokenUuid: string, params: RequestParams = {}) =>
    this.http.request<GetDeviceTokenByUuidData, void>({
      path: `/account/devicetoken/${tokenUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags device_token
   * @name UpdateDeviceTokenInfoByUuid
   * @summary Update device token info by uuid
   * @request PATCH:/account/devicetoken/{token_uuid}
   * @secure
   */
  updateDeviceTokenInfoByUuid = (tokenUuid: string, data: TokenPatch, params: RequestParams = {}) =>
    this.http.request<UpdateDeviceTokenInfoByUuidData, void>({
      path: `/account/devicetoken/${tokenUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user_token
   * @name GetUserTokens
   * @summary Get all user tokens
   * @request GET:/account/usertoken
   * @secure
   */
  getUserTokens = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetUserTokensData, void>({
      path: `/account/usertoken`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags user_token
   * @name CreateUserToken
   * @summary Create new user token
   * @request POST:/account/usertoken
   * @secure
   */
  createUserToken = (data: UserTokenPost, params: RequestParams = {}) =>
    this.http.request<CreateUserTokenData, void>({
      path: `/account/usertoken`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user_token
   * @name DeleteUserTokenByUuid
   * @summary Delete user token by uuid
   * @request DELETE:/account/usertoken/{token_uuid}
   * @secure
   */
  deleteUserTokenByUuid = (tokenUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteUserTokenByUuidData, void>({
      path: `/account/usertoken/${tokenUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags user_token
   * @name GetUserTokenByUuid
   * @summary Get user token info by uuid
   * @request GET:/account/usertoken/{token_uuid}
   * @secure
   */
  getUserTokenByUuid = (tokenUuid: string, params: RequestParams = {}) =>
    this.http.request<GetUserTokenByUuidData, void>({
      path: `/account/usertoken/${tokenUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags user_token
   * @name UpdateUserTokenByUuid
   * @summary Update user token info by uuid
   * @request PATCH:/account/usertoken/{token_uuid}
   * @secure
   */
  updateUserTokenByUuid = (tokenUuid: string, data: UserTokenPatch, params: RequestParams = {}) =>
    this.http.request<UpdateUserTokenByUuidData, void>({
      path: `/account/usertoken/${tokenUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user_token
   * @name RevokeUserTokenByUuid
   * @summary Revoke user token by uuid
   * @request POST:/account/usertoken/{token_uuid}
   * @secure
   */
  revokeUserTokenByUuid = (tokenUuid: string, params: RequestParams = {}) =>
    this.http.request<RevokeUserTokenByUuidData, void>({
      path: `/account/usertoken/${tokenUuid}`,
      method: "POST",
      secure: true,
      ...params,
    });
}
