import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import LeftContent from "components/authentication/LeftContent";
import SignUpForm, {
    SignUpFormValues,
} from "components/authentication/SignUpForm";
import VerifyUserForm from "components/authentication/VerifyUserForm";
import VerificationSuccess from "components/authentication/VerificationSuccess";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [emailSentSuccess, setEmailSentSuccess] = useState(false);
    const [isRegisteredSuccessfully, setRegistered] = useState(false);

    const onVerifySuccess = () => {
        setRegistered(true);
    };

    const onSignUpSuccess = async ({ email }: SignUpFormValues) => {
        setEmail(email);
        setEmailSentSuccess(true);
    };

    let rightContent = <SignUpForm onSuccess={onSignUpSuccess} />;

    if (emailSentSuccess) {
        rightContent = (
            <VerifyUserForm onSuccess={onVerifySuccess} email={email} />
        );
    }
    if (isRegisteredSuccessfully) {
        rightContent = <VerificationSuccess />;
    }

    return (
        <section>
            <Container fluid>
                <Row className="flex-row-reverse">
                    {rightContent}
                    <LeftContent />
                </Row>
            </Container>
        </section>
    );
};

export default SignUp;
