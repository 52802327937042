import { useMemo, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import EventVariables from "pages/events/EventVariables";
import EventList from "pages/events/EventList";
import useCollectSort from "hooks/useCollectSort";
import {
    EVENT_ACTIVE_KEY,
    FETCH_EVENTS_FAIL_MESSAGE,
    FETCH_EVENT_VARIABLES_FAIL_MESSAGE,
    FETCH_GATEWAY_FAIL_MESSAGE,
    HttpStatus,
} from "constant";
import { canAccess } from "utils/authorize-action";
import { isHttpSuccess } from "utils/functions";
import { getAPIError, showErrorAlert } from "utils/alert";
import EventService, { EventBy } from "service/eventService";
import { getAllRegistry } from "service/gatewayService";
import EventVariableService from "service/eventVariableService";
import { orderBy, get, uniqBy } from "lodash";
import { Event, Variable } from "generated/models/Event";
import { Registry } from "generated/models";
import { useAppContext } from "context/appContext";

const eventTabs = {
    events: "all-events",
    eventVariables: "event-variables",
};

export type PaginationType = {
    page: number;
    rowsPerPage: number;
    count: number;
    lastPage: number;
    loading: boolean;
};

const Events = () => {
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [allGateways, setAllGateways] = useState<Registry[]>([]);
    const [eventList, setEventList] = useState<Event[]>([]);
    const [eventVariables, setEventVariables] = useState<Variable[]>([]);
    const [activeTab, setActiveTab] = useState<string>(eventTabs.events);
    const [isReady, setIsReady] = useState<boolean>(false);
    const history = useHistory();
    const { search } = useLocation();
    const [associatedEventMap, setAssociatedEventMap] = useState<
        Record<string, number>
    >({});
    const [pagination, setPagination] = useState<PaginationType>({
        page: 0,
        rowsPerPage: 10,
        count: 0,
        lastPage: 0,
        loading: true,
    });
    const [eventSearchText, setEventSearchText] = useState("");
    const { sortBy, sortIcon } = useCollectSort();
    const {
        storeData: {
            organization: { currentOrgId },
            systemPermissions: { permissions },
        },
    } = useAppContext();

    const hasPermission = useMemo(
        () => canAccess("event:read") && !isForbiddenResource,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [permissions, isForbiddenResource]
    );

    // Permission check
    useEffect(() => {
        const checkPermission = () => {
            if (!hasPermission) {
                setIsPageLoading(false);
                setIsForbiddenResource(true);
                return;
            }

            setIsForbiddenResource(false);
        };

        checkPermission();
    }, [hasPermission]);

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        const activeKey = queryParams.get(EVENT_ACTIVE_KEY);
        if (activeKey) setActiveTab(activeKey);
    }, [search]);

    const fetchEventVariables = async () => {
        const fetchResp = await EventVariableService.readEventVariables();

        if (!isHttpSuccess(fetchResp.status)) {
            showErrorAlert(
                getAPIError(fetchResp, FETCH_EVENT_VARIABLES_FAIL_MESSAGE)
            );
        } else {
            setAssociatedEventMap(numberOfAssociatedEvent(fetchResp.data));
            setEventVariables(
                orderBy(fetchResp.data, ["create_time"], ["asc"])
            );
        }
    };

    const numberOfAssociatedEvent = (variables: Variable[]) => {
        const eventVariableMap: Record<string, number> = {};
        variables.forEach((variable: Variable) => {
            const in_actions = get(variable, "in_actions", []);
            const in_conditions = get(variable, "in_conditions", []);
            eventVariableMap[variable.uuid] = uniqBy(
                [...in_actions, ...in_conditions],
                "uuid"
            ).length;
        });
        return eventVariableMap;
    };

    const fetchEventList = async () => {
        let { page, rowsPerPage, lastPage } = pagination;

        if (eventList.length === 1 && page === lastPage && lastPage !== 0) {
            page = page - 1;
        }

        const fetchResp = await EventService.readEvent({
            offset: eventSearchText ? 0 : page * rowsPerPage,
            limit: rowsPerPage,
            by: (sortBy.key || "create_time") as EventBy,
            order: sortBy.direction,
            ...(eventSearchText && {
                filter: "name",
                name: eventSearchText.toLowerCase(),
            }),
        });

        if (fetchResp.status === HttpStatus.FORBIDDEN) {
            setIsForbiddenResource(true);
        } else if (!isHttpSuccess(fetchResp.status)) {
            showErrorAlert(getAPIError(fetchResp, FETCH_EVENTS_FAIL_MESSAGE));
        } else {
            setEventList(fetchResp.data);
            const totalEvent = get(fetchResp, "total", 0);
            setPagination({
                ...pagination,
                page: eventSearchText ? 0 : page,
                count: totalEvent,
                lastPage: Math.ceil(totalEvent / rowsPerPage) - 1,
                loading: false,
            });
        }
    };

    useEffect(() => {
        if (hasPermission) {
            fetchEventList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        window.authorizationConfig,
        pagination.page,
        pagination.rowsPerPage,
        sortBy,
        currentOrgId,
        hasPermission,
    ]);

    const fetchAllGateways = async () => {
        const fetchResp = await getAllRegistry();
        if (!isHttpSuccess(fetchResp.status)) {
            showErrorAlert(getAPIError(fetchResp, FETCH_GATEWAY_FAIL_MESSAGE));
        } else {
            setAllGateways(get(fetchResp, "data", []));
        }
    };

    useEffect(() => {
        const fetchAllData = async () => {
            setIsPageLoading(true);
            await fetchEventList();
            setIsPageLoading(false);
            setIsReady(true);
        };

        if (hasPermission) {
            fetchAllData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPermission]);

    useEffect(() => {
        const fetchAllData = async () => {
            setIsPageLoading(true);

            await Promise.all([fetchEventVariables(), fetchAllGateways()]);

            setIsPageLoading(false);
        };

        if (hasPermission && isReady) {
            fetchAllData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPermission, isReady]);

    const handleSelectActiveTab = (tabKey: string | null) => {
        const selectedTab = tabKey ?? eventTabs.events;
        setActiveTab(selectedTab);
        // eslint-disable-next-line no-restricted-globals
        const queryParams = new URLSearchParams(search);
        queryParams.set(EVENT_ACTIVE_KEY, selectedTab);
        history.replace({
            search: queryParams.toString(),
        });
    };

    return (
        <ContentWrapper
            isForbiddenResource={isForbiddenResource}
            isLoading={isPageLoading}
            title="Event Management"
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h5 className="page-title">Event Management</h5>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <div className="page-tabs">
                                <Tab.Container
                                    defaultActiveKey={activeTab}
                                    activeKey={activeTab}
                                    onSelect={handleSelectActiveTab}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Nav>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="all-events">
                                                        <h6>
                                                            All Events{" "}
                                                            <span className="count">
                                                                {
                                                                    eventList.length
                                                                }
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="event-variables">
                                                        <h6>
                                                            Event Variables{" "}
                                                            <span className="count">
                                                                {
                                                                    eventVariables.length
                                                                }
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>

                                        <Col sm={12}>
                                            <Tab.Content className="mt-4">
                                                <Tab.Pane eventKey="all-events">
                                                    <EventList
                                                        eventList={eventList}
                                                        allGateways={
                                                            allGateways
                                                        }
                                                        fetchEventList={
                                                            fetchEventList
                                                        }
                                                        setEventList={
                                                            setEventList
                                                        }
                                                        eventSearchText={
                                                            eventSearchText
                                                        }
                                                        setEventSearchText={
                                                            setEventSearchText
                                                        }
                                                        pagination={pagination}
                                                        setPagination={
                                                            setPagination
                                                        }
                                                        sortIcon={sortIcon}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="event-variables">
                                                    <EventVariables
                                                        associatedEventMap={
                                                            associatedEventMap
                                                        }
                                                        eventVariables={
                                                            eventVariables
                                                        }
                                                        fetchEventVariables={
                                                            fetchEventVariables
                                                        }
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default Events;
