import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import moment from "moment";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { clearGatewayData } from "service/gatewayService";
import dltWhiteIcon from "assets/svg/delete-white.svg";
import refreshtIcon from "assets/svg/refresh-green.svg";
import DefaultModal from "components/modals/ModalTemplate";

export const ClearDataUI = ({
    gatewayId,
    isDeletionTaskRunning,
    isLoading,
    onRefresh,
}: {
    gatewayId: string;
    isDeletionTaskRunning: boolean;
    isLoading: boolean;
    onRefresh: () => void;
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const refreshThisGateway = async () => {
        if (typeof onRefresh === "function") {
            onRefresh();
        }
    };

    const handleClearData = () =>
        clearGatewayData(gatewayId, {
            start: 1,
            end: moment().unix(),
        })
            .then(() => {
                setModalShow(false);
                refreshThisGateway();
            })
            .catch((err) => {
                setSimpleModalData({
                    resObj: err,
                } as SimpleModalDataType);
            });

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <div className="storage-button mt-5">
                <HoverAuthorizeTooltip permission="gateway:update">
                    <Button
                        variant="danger"
                        disabled={isDeletionTaskRunning}
                        onClick={() => {
                            setModalShow(true);
                        }}
                    >
                        <img
                            src={dltWhiteIcon}
                            className="mr-2 ml-2"
                            alt="delete"
                        />{" "}
                        Clear Data
                    </Button>
                </HoverAuthorizeTooltip>
            </div>
            {isDeletionTaskRunning && (
                <div className="storage-button mt-5">
                    <div className="refresh-zone">
                        <p className="header">Clear data request</p>
                        <p>
                            Data clearing is in-progress...
                            <img
                                className={
                                    isLoading
                                        ? "btn-refresh refresh-animation"
                                        : "btn-refresh"
                                }
                                src={refreshtIcon}
                                alt="refresh"
                                onClick={refreshThisGateway}
                                onKeyDown={refreshThisGateway}
                            ></img>
                        </p>
                        <p>This may take a few hours to complete.</p>
                    </div>
                </div>
            )}

            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalType="updateConfirm"
                modalTitle="Confirm"
                modalContent={
                    <>
                        Do you want to clear all the data related to this
                        gateway?
                    </>
                }
                resendFunction={handleClearData}
            />
        </ContentWrapper>
    );
};
