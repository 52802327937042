import DefaultModal from "./ModalTemplate";

export const BasicPlanModal = ({
    showBasicPlanModal,
    toggleShowBasicPlanModal,
}: any) => {
    return (
        <DefaultModal
            modalShow={showBasicPlanModal}
            modalIcon="error_outline"
            iconVariant="danger"
            modalTitle="No Access"
            modalContent={
                <div>
                    Your current plan does not include access to this feature.
                    To upgrade your plan, please contact our support team at{" "}
                    <span className="text-primary-green-1">
                        {" "}
                        support@brtsys.com
                    </span>
                    .
                </div>
            }
            modalType="cancelConfirmOk"
            okAction={toggleShowBasicPlanModal}
        />
    );
};
