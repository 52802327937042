import { useState, ChangeEvent, useEffect } from "react";
import { Dropdown, Button, FormControl, FormCheck } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import styled from "@emotion/styled";
import styledConst from "styles";
import { BlueText } from "components/common";

const CustomToggle = styled(DropdownToggle)`
    background-color: ${styledConst.Primary_Blue_3};
    border-color: ${styledConst.Primary_Blue_3};

    &::after {
        display: none;
    }

    &.btn-primary {
        &.dropdown-toggle,
        &.dropdown-toggle:focus,
        &:not(:disabled):not(.disabled):active,
        &.dropdown-toggle:hover {
            background-color: ${styledConst.Primary_Blue_3};
            border-color: ${styledConst.Primary_Blue_3};
        }
    }

    .material-icons,
    .material-icons-outlined {
        color: ${styledConst.Primary_Green_1};
        vertical-align: middle;
    }

    .dot {
        height: 8px;
        width: 8px;
        background-color: ${styledConst.Primary_Green_1};
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }
`;

const CustomMenu = styled(Dropdown.Menu)`
    & > .search {
        background-color: #f0f0f0 !important;
        border-color: #ced4da;
        color: #000;
    }

    .check-list {
        max-height: 300px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #e8e8e8;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            background-color: #e8e8e8;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #7a7a7a;
        }
    }

    @media screen and (max-width: 576px) {
        min-width: calc(100vw - 3rem);
    }

    @media screen and (max-width: 768px) {
        min-width: 18rem;
    }
`;

const CustomCheck = styled(FormCheck)`
    .custom-control-label:before {
        background-color: #dddddd;
        border: 1px solid #dddddd;
    }
`;

type SensorTypeDropdownProps = {
    sensorTypes: { id: string; name: string }[];
    selected: string[];
    onChange: (types: string[]) => void;
    className?: string;
};
export const SensorTypeDropdown = ({
    sensorTypes,
    selected,
    onChange,
    ...restProps
}: SensorTypeDropdownProps) => {
    const [searchText, setSearchText] = useState("");
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [filteredList, setFilteredList] = useState<any[]>([]);
    const [show, setShow] = useState(false);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        setFilteredList(
            sensorTypes.filter(({ name }) =>
                name.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };

    const selectType = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked, value } = e.target;
        let types = selectedTypes.filter((t) => t !== value);

        if (checked) {
            types.push(value);
        }

        setSelectedTypes(types);
    };

    const clearFilter = () => {
        setSelectedTypes([]);
        onChange([]);
        setShow(false);
    };

    const applyFilter = () => {
        onChange([...selectedTypes]);
        setShow(false);
    };

    const handleOpenMenu = (isOpen: boolean) => {
        setShow(isOpen);
        setSelectedTypes(selected);
        setSearchText("");
        setFilteredList(sensorTypes);
    };

    useEffect(() => {
        setFilteredList(sensorTypes);
    }, [sensorTypes]);

    useEffect(() => {
        setSelectedTypes(selected);
    }, [selected]);

    return (
        <>
            <Dropdown show={show} onToggle={handleOpenMenu} {...restProps}>
                <CustomToggle>
                    <i className="material-icons-outlined mr-2">filter_alt</i>
                    <span title="Filter">Filter</span>
                    {selectedTypes.length > 0 && <i className="dot ml-2" />}
                    <i className="material-icons down ml-2">
                        keyboard_arrow_down
                    </i>
                </CustomToggle>

                <CustomMenu className="px-3 py-3">
                    <h6 className="">Filter</h6>
                    <FormControl
                        className="search mt-3 mb-2"
                        placeholder="Search for sensor types"
                        aria-label="search-dashboard"
                        onChange={handleSearch}
                        value={searchText}
                    />
                    {selectedTypes.length > 0 && (
                        <div>
                            <BlueText className="d-block mb-2">
                                Selected Types
                            </BlueText>
                            {selectedTypes.map((type) => (
                                <CustomCheck
                                    key={type}
                                    className="mb-2"
                                    name="selected-sensor"
                                    value={type}
                                    checked={true}
                                    custom
                                    onChange={selectType}
                                    id={type}
                                    label={<span className="ml-2">{type}</span>}
                                />
                            ))}
                        </div>
                    )}
                    <BlueText className="d-block mb-2">Sensor Types</BlueText>
                    <div className="check-list">
                        {filteredList
                            .filter((t) => !selectedTypes.includes(t.id))
                            .map((option: any) => (
                                <CustomCheck
                                    key={option.id}
                                    className="mb-2"
                                    name="selected-sensor"
                                    value={option.id}
                                    checked={selectedTypes.includes(option.id)}
                                    custom
                                    onChange={selectType}
                                    id={option.id}
                                    label={
                                        <span className="ml-2">
                                            {option.name}
                                        </span>
                                    }
                                />
                            ))}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <Button
                            variant="dark"
                            onClick={clearFilter}
                            size="sm"
                            className="px-md-3"
                        >
                            CLEAR
                        </Button>
                        <Button
                            className="px-md-3"
                            variant="primary"
                            onClick={applyFilter}
                            size="sm"
                        >
                            APPLY
                        </Button>
                    </div>
                </CustomMenu>
            </Dropdown>
        </>
    );
};
