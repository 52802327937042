import { COUNTED, INFINITE } from "constant";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { useEffect, useState } from "react";

import { Col, Form, FormControl } from "react-bootstrap";
import { Actuator } from "./ActuatorConfiguration";

const ActuatorCount = () => {
    const {
        editedActuator: { cycle_count },
        setEditedActuator,
    }: {
        editedActuator: Actuator;
        setEditedActuator: React.Dispatch<React.SetStateAction<Actuator>>;
    } = useActuatorConfigurationContext();

    const [selectedLoopCycle, setSelectedLoopCycle] = useState(
        cycle_count || 0
    );

    const [loopCycleOption, setLoopCycleOption] = useState(INFINITE);

    useEffect(() => {
        setEditedActuator((prevState) => ({
            ...prevState,
            cycle_count: loopCycleOption === INFINITE ? 0 : selectedLoopCycle,
        }));
    }, [selectedLoopCycle, loopCycleOption, setEditedActuator]);

    useEffect(() => {
        if (cycle_count)
            setLoopCycleOption(cycle_count === 0 ? INFINITE : COUNTED);
    }, [cycle_count]);

    return (
        <>
            <Form.Row className="mt-4 mb-4">
                <Col md={2}>
                    <Form.Check
                        name="loops"
                        id={INFINITE}
                        checked={loopCycleOption === INFINITE}
                        onChange={() => {
                            setLoopCycleOption(INFINITE);
                        }}
                        custom
                        type="radio"
                        label={<span className="ml-2">Infinite</span>}
                    />
                </Col>
                <Col md={2}>
                    <Form.Check
                        name="loops"
                        id={COUNTED}
                        checked={loopCycleOption === COUNTED}
                        onChange={() => {
                            setLoopCycleOption(COUNTED);
                        }}
                        custom
                        type="radio"
                        label={<span className="ml-2">Counted</span>}
                    />
                </Col>
            </Form.Row>
            {loopCycleOption === COUNTED ? (
                <Form.Row className="mb-4">
                    <Col md={1}>
                        <Form.Label className="mt-2">Count</Form.Label>
                    </Col>
                    <Col md={7}>
                        <FormControl
                            type="text"
                            value={selectedLoopCycle}
                            onChange={(e: any) => {
                                setSelectedLoopCycle(e.target.value);
                            }}
                        />
                    </Col>
                </Form.Row>
            ) : (
                ""
            )}
        </>
    );
};

export default ActuatorCount;
