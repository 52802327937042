/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import {
  CreateDashboardData,
  CreatePanelByDashboardIdData,
  DashboardPatch,
  DashboardPost,
  DeleteDashboardByIdData,
  DeletePanelByIdData,
  GetDashboardByIdData,
  GetDashboardsData,
  GetPanelByIdData,
  GetPanelsByDashboardIdData,
  GetPanelsByDashboardIdParamsSource,
  PanelOrderPatch,
  PanelV2Patch,
  PanelV2Post,
  UpdateDashboardByIdData,
  UpdatePanelByIdData,
  UpdatePanelOrderByIdData,
} from "../models";

export class Dashboards<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags dashboard
   * @name GetDashboards
   * @summary Get all dashboards of specified organization
   * @request GET:/dashboards
   * @secure
   */
  getDashboards = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetDashboardsData, void>({
      path: `/dashboards`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dashboard
   * @name CreateDashboard
   * @summary Create a new dashboard
   * @request POST:/dashboards
   * @secure
   */
  createDashboard = (data: DashboardPost, params: RequestParams = {}) =>
    this.http.request<CreateDashboardData, void>({
      path: `/dashboards`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags dashboard
   * @name DeleteDashboardById
   * @summary Delete dashboard info by uuid
   * @request DELETE:/dashboards/{dashboard_uuid}
   * @secure
   */
  deleteDashboardById = (dashboardUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteDashboardByIdData, void>({
      path: `/dashboards/${dashboardUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dashboard
   * @name GetDashboardById
   * @summary Get dashboard info by uuid
   * @request GET:/dashboards/{dashboard_uuid}
   * @secure
   */
  getDashboardById = (dashboardUuid: string, params: RequestParams = {}) =>
    this.http.request<GetDashboardByIdData, void>({
      path: `/dashboards/${dashboardUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dashboard
   * @name UpdateDashboardById
   * @summary Update dashboard info by uuid
   * @request PATCH:/dashboards/{dashboard_uuid}
   * @secure
   */
  updateDashboardById = (dashboardUuid: string, data: DashboardPatch, params: RequestParams = {}) =>
    this.http.request<UpdateDashboardByIdData, void>({
      path: `/dashboards/${dashboardUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags panel
   * @name GetPanelsByDashboardId
   * @summary Get all panels of specified dashboard
   * @request GET:/dashboards/{dashboard_uuid}/panels_v2
   * @secure
   */
  getPanelsByDashboardId = (
    dashboardUuid: string,
    query?: {
      /** Filtering option */
      source?: GetPanelsByDashboardIdParamsSource;
      /** Request for panel name only */
      name_only?: boolean;
      /** Offset for paginated result */
      offset?: number;
      /** Limit for paginated result */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetPanelsByDashboardIdData, void>({
      path: `/dashboards/${dashboardUuid}/panels_v2`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags panel
   * @name CreatePanelByDashboardId
   * @summary Create a new panel in specified dashboard
   * @request POST:/dashboards/{dashboard_uuid}/panels_v2
   * @secure
   */
  createPanelByDashboardId = (dashboardUuid: string, data: PanelV2Post, params: RequestParams = {}) =>
    this.http.request<CreatePanelByDashboardIdData, void>({
      path: `/dashboards/${dashboardUuid}/panels_v2`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags panel
   * @name UpdatePanelOrderById
   * @summary Update panels' order
   * @request PATCH:/dashboards/{dashboard_uuid}/panels_v2/order
   * @secure
   */
  updatePanelOrderById = (dashboardUuid: string, data: PanelOrderPatch, params: RequestParams = {}) =>
    this.http.request<UpdatePanelOrderByIdData, void>({
      path: `/dashboards/${dashboardUuid}/panels_v2/order`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags panel
   * @name DeletePanelById
   * @summary Delete panel info by uuid
   * @request DELETE:/dashboards/{dashboard_uuid}/panels_v2/{panel_uuid}
   * @secure
   */
  deletePanelById = (dashboardUuid: string, panelUuid: string, params: RequestParams = {}) =>
    this.http.request<DeletePanelByIdData, void>({
      path: `/dashboards/${dashboardUuid}/panels_v2/${panelUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Get current user's panel info by uuid
   *
   * @tags panel
   * @name GetPanelById
   * @summary Get panel info by uuid
   * @request GET:/dashboards/{dashboard_uuid}/panels_v2/{panel_uuid}
   * @secure
   */
  getPanelById = (dashboardUuid: string, panelUuid: string, params: RequestParams = {}) =>
    this.http.request<GetPanelByIdData, void>({
      path: `/dashboards/${dashboardUuid}/panels_v2/${panelUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags panel
   * @name UpdatePanelById
   * @summary Update panel info by uuid
   * @request PATCH:/dashboards/{dashboard_uuid}/panels_v2/{panel_uuid}
   * @secure
   */
  updatePanelById = (dashboardUuid: string, panelUuid: string, data: PanelV2Patch, params: RequestParams = {}) =>
    this.http.request<UpdatePanelByIdData, void>({
      path: `/dashboards/${dashboardUuid}/panels_v2/${panelUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
