import AxiosInstance from "./axiosInstance";
import { cloneDeep, orderBy, get } from "lodash";
import {
    combineResultArrays,
    convertPrometheusTimeToSeconds,
    monthsToSeconds,
    totalDoesNotExceedMax,
} from "utils/functions";
import moment from "moment";
const APP_DASHBOARD_API = process.env.REACT_APP_DASHBOARD_API;

const changeDataTo2DP = (data: any, chart_type: string) => {
    for (let i = 0; i < data.values.length; i++) {
        if (chart_type === "SINGLE") {
            data.values[i][0] = data.values[i][0] * 1000;
        }
        data.values[i][1] = Number.parseFloat(data.values[i][1]).toFixed(2);
    }
    return data;
};

const _chartMissingOption = {
    type: "line",
    itemStyle: {
        color: "background: rgba(243, 199, 173, 0.05)",
    },
    lineStyle: {
        color: "rgba(251, 108, 89, 1)",
        type: "dashed",
    },
    smooth: true,
    showSymbol: false,
    areaStyle: {},
    markLine: {
        symbol: ["none", "none"],
        lineStyle: {
            color: "#ECA48E",
            type: "dashed",
        },
        label: {
            show: false,
        },
        data: [
            {
                xAxis: 1652672793000,
            },
            {
                xAxis: 1652672862000,
            },
        ],
    },
};

const splitSeriesMissing = ({
    result,
    gapTime,
    chartOptions,
}: {
    result: any[];
    gapTime: number;
    chartOptions: any;
}) => {
    const _result = cloneDeep(result);

    const combinedResult: any = {
        metric: {},
        values: [],
    };
    _result.forEach((r) => {
        combinedResult.metric = r.metric;
        const mapped = r.values.map((v: any) => [v[0] * 1000, v[1]]);
        combinedResult.values.push(...mapped);
    });

    const dataArray = orderBy(combinedResult.values, ["[0]"], ["asc"]);

    let seriesGroup = [];
    let seriesGroupItem = [];
    let tailIndex = 0;
    let headIndex = 1;
    const interval = gapTime / 1000;
    const allowanceMargin = 1.5;

    for (tailIndex; tailIndex < dataArray.length; tailIndex++) {
        const difference =
            (dataArray[headIndex]?.[0] - dataArray[tailIndex]?.[0]) / 1000;
        let isMissing = false;
        isMissing =
            dataArray.length > 1 && difference > interval * allowanceMargin;

        if (isMissing) {
            if (seriesGroup.length === 0) {
                // first missing
                seriesGroupItem.push(dataArray[tailIndex]);
                seriesGroup.push({
                    label: "missing",
                    data: seriesGroupItem,
                    ..._chartMissingOption,
                });
            } else {
                // subsequent missing
                const currentLabel = seriesGroup[seriesGroup.length - 1].label;
                if (currentLabel === "missing") {
                    // if latest label is missing, continue push
                    seriesGroupItem.push(dataArray[tailIndex]);
                } else {
                    // if latest label is not missing, create new missing group
                    seriesGroup[seriesGroup.length - 1].data.push(
                        dataArray[tailIndex]
                    );
                    seriesGroupItem = [];
                    seriesGroupItem.push(dataArray[tailIndex]);
                    seriesGroup.push({
                        label: "missing",
                        data: seriesGroupItem,
                        ..._chartMissingOption,
                    });
                }
            }
        } else {
            if (seriesGroup.length === 0) {
                // first non-missing
                seriesGroupItem.push(dataArray[tailIndex]);
                seriesGroup.push({
                    data: seriesGroupItem,
                    label: "not-missing",
                    ...chartOptions,
                });
            } else {
                // subsequent non-missing
                const currentLabel = seriesGroup[seriesGroup.length - 1].label;
                if (currentLabel === "not-missing") {
                    // if latest label is not missing, continue push
                    seriesGroupItem.push(dataArray[tailIndex]);
                } else {
                    // if latest label is missing, create new not-missing group
                    seriesGroup[seriesGroup.length - 1].data.push(
                        dataArray[tailIndex]
                    );
                    seriesGroupItem = [];
                    seriesGroupItem.push(dataArray[tailIndex]);
                    seriesGroup.push({
                        data: seriesGroupItem,
                        label: "not-missing",
                        ...chartOptions,
                    });
                }
            }
        }

        headIndex++;
    }

    return {
        names: [combinedResult?.metric?.name],
        series: seriesGroup,
    };
};

const _getSampleQuery = ({
    response,
    gapTime,
    chartColor,
    prepareAggregated,
    minResponse,
    maxResponse,
    name,
}: any) => {
    const funcRet: {
        info: any;
        names: any;
        series: any;
        aggregatedSeries: any;
    } = {
        info: get(response, "data.data.result[0].metric", {}),
        names: [],
        series: [],
        aggregatedSeries: undefined,
    };

    const currentChartOption = {
        type: "line",
        smooth: true,
        showSymbol: false,
        itemStyle: {
            color: chartColor,
        },
    };

    const { names: seriesGroupNames, series: seriesGroup } = splitSeriesMissing(
        {
            result: get(response, "data.data.result", []),
            gapTime,
            chartOptions: currentChartOption,
        }
    );

    if (prepareAggregated) {
        if (
            !get(response, "data.data.result[0]", {}) ||
            !get(minResponse, "data.data.result[0]", {}) ||
            !get(maxResponse, "data.data.result[0]", {})
        ) {
            return {
                info: {},
                names: [""],
                series: [],
            };
        }

        // BE may return multiple data arrays. Combine them and remove duplicate timestamps
        combineResultArrays(response);
        combineResultArrays(minResponse);
        combineResultArrays(maxResponse);

        const results = changeDataTo2DP(response.data.data.result[0], "SINGLE");

        const minResults = changeDataTo2DP(
            minResponse.data.data.result[0],
            "SINGLE"
        );
        const maxResults = changeDataTo2DP(
            maxResponse.data.data.result[0],
            "SINGLE"
        );
        const series: any = [];
        const resBody = {
            symbolSize: 15,
            color: chartColor,
            type: "scatter",
            name,
            data: results.values,
        };

        const maxBody = {
            symbolSize: 7,
            color: "#9BD88C",
            type: "scatter",
            name: "Max",
            data: maxResults.values,
        };
        const minBody = {
            symbolSize: 7,
            color: "#ED8787",
            type: "scatter",
            name: "Min",
            data: minResults.values,
        };
        const lineDataList = (
            readinglst: any,
            maxReadinglst: any,
            minReadingLst: any
        ) => {
            const body = [];
            for (let i = 0; i < readinglst.values.length; i++) {
                body.push(cloneDeep(readinglst.values[i]));
                body.push(maxReadinglst.values[i]);
                body.push(minReadingLst.values[i]);
            }

            for (let i = 0; i < body.length; i = i + 3) {
                body[i][1] = NaN;
            }

            return body;
        };
        const lineBody = {
            color: "#BECAE3",
            type: "line",
            zlevel: -1,
            data: lineDataList(results, maxResults, minResults),
        };
        series.push(resBody, maxBody, minBody, lineBody);
        funcRet.names = [get(results, "metric.name", ""), "Max", "Min"];
        funcRet.aggregatedSeries = series;
        funcRet.series = seriesGroup;
    }

    funcRet.names = seriesGroupNames;
    funcRet.series = seriesGroup;

    return funcRet;
};

const getAggregateBy: any = ({
    timeValue,
    numberOfChart,
}: {
    timeValue: number;
    numberOfChart: number;
}) => {
    // Requirement: if > 6mths, use hourly aggregation
    if (timeValue > monthsToSeconds(6)) {
        return "1h";
    }

    const aggregationInArray = ["1m", "5m", "30m", "1h"];

    for (let aggregation of aggregationInArray) {
        const totalAggregatedSample = Math.floor(
            timeValue / convertPrometheusTimeToSeconds(aggregation)
        );

        if (
            totalDoesNotExceedMax({
                total: totalAggregatedSample,
                numberOfChart: numberOfChart,
            })
        ) {
            return aggregation;
        }
    }
};

const getMQTTquery: any = ({
    queryType,
    query,
    time_range,
    lastTimestamp,
    panelToken,
    timeValue,
    aggregationType,
    numberOfChart,
}: {
    queryType: "raw" | "aggregated";
    query: string;
    time_range: string;
    lastTimestamp: string;
    panelToken: string;
    timeValue: number;
    aggregationType?: "avg" | "min" | "max";
    numberOfChart: number;
}) => {
    if (queryType === "raw") {
        return (
            APP_DASHBOARD_API +
            `/query?query=${query}[${time_range}]&time=${lastTimestamp}&token=${panelToken}`
        );
    } else if (queryType === "aggregated") {
        query = query.replace("mqtt2tsdb", "");

        return (
            APP_DASHBOARD_API +
            `/query?query=${aggregationType}_${getAggregateBy({
                timeValue,
                numberOfChart,
            })}${query}[${time_range}]&time=${lastTimestamp}&token=${panelToken}`
        );
    }
};

export const getSampleQuery = async ({
    query,
    chartColor,
    sensorType,
    panelToken,
    gapTime,
    time_range,
    query_time,
    name,
}: {
    query: string;
    chartColor: any;
    sensorType: string;
    panelToken: string;
    gapTime: number;
    time_range: string;
    query_time: string | number | null;
    name: string;
}) => {
    let response: any;
    let minResponse: any;
    let maxResponse: any;

    const lastTimestamp = query_time || moment().unix();
    const timeValue = convertPrometheusTimeToSeconds(time_range);

    const sensorReportRate: number = gapTime / 1000;
    const totalSample = Math.floor(timeValue / sensorReportRate);

    let shouldAggregate = false;
    if (
        totalDoesNotExceedMax({ total: totalSample, numberOfChart: 1 }) &&
        timeValue <= monthsToSeconds(6)
    ) {
        try {
            response = await AxiosInstance.get(
                getMQTTquery({
                    queryType: "raw",
                    query,
                    time_range,
                    lastTimestamp,
                    panelToken,
                    timeValue,
                    numberOfChart: 1,
                })
            );
        } catch (err) {
            // TO BE ADDED: Handle error obj
            return err;
        }
    } else {
        gapTime =
            convertPrometheusTimeToSeconds(
                getAggregateBy({ timeValue, numberOfChart: 1 })
            ) * 1000;

        [response, minResponse, maxResponse] = await Promise.all(
            ["avg", "min", "max"].map((type) => {
                return AxiosInstance.get(
                    getMQTTquery({
                        queryType: "aggregated",
                        query,
                        time_range,
                        lastTimestamp,
                        panelToken,
                        timeValue,
                        aggregationType: type,
                        numberOfChart: 1,
                    })
                );
            })
        );
        shouldAggregate = true;
    }

    return _getSampleQuery({
        response,
        gapTime,
        chartColor,
        timeValue,
        prepareAggregated: shouldAggregate,
        minResponse,
        maxResponse,
        sensorType,
        name,
    });
};

export const getStackedChartQuery = async ({
    panels,
    namesAry,
    yAxisAry,
    panelToken,
    time_range,
    query_time,
    gapTime,
}: {
    panels: any;
    namesAry: any;
    yAxisAry: any;
    panelToken: any;
    time_range: string;
    query_time: string | number | null;
    gapTime: number;
}) => {
    const lastTimestamp = query_time || moment().unix();
    const timeValue = convertPrometheusTimeToSeconds(time_range);

    const sensorReportRate: number = gapTime / 1000;
    const totalSample = Math.floor(timeValue / sensorReportRate);

    const newQueries: any = [];
    for (let i = 0; i < panels.queries.length; i++) {
        if (
            totalDoesNotExceedMax({
                total: totalSample,
                numberOfChart: panels.queries.length,
            })
        ) {
            newQueries.push(
                getMQTTquery({
                    queryType: "raw",
                    query: panels.queries[i],
                    time_range,
                    lastTimestamp,
                    panelToken: panelToken[i],
                    timeValue,
                    numberOfChart: panels.queries.length,
                })
            );
        } else {
            newQueries.push(
                getMQTTquery({
                    queryType: "aggregated",
                    query: panels.queries[i],
                    time_range,
                    lastTimestamp,
                    panelToken: panelToken[i],
                    timeValue,
                    aggregationType: "avg",
                    numberOfChart: panels.queries.length,
                })
            );
        }
    }

    let queryRes: any = [];
    try {
        queryRes = await Promise.all(
            newQueries.map((i: any) => AxiosInstance.get(i))
        );
    } catch (err) {
        return err;
    }

    let result: any;
    let series: any = [];
    for (let h = 0; h < queryRes.length; h++) {
        if (
            totalDoesNotExceedMax({
                total: totalSample,
                numberOfChart: queryRes.length,
            })
        ) {
            result = queryRes[h].data.data.result;
        } else {
            combineResultArrays(queryRes[h]);

            result = [
                changeDataTo2DP(queryRes[h].data.data.result[0], "STACKED"),
            ];
        }

        const thisIndex = yAxisAry.findIndex(
            (info: any) =>
                info.time_series === panels.attributes[h]?.time_series
        );

        let dataResult = [];

        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].values.length; j++) {
                result[i].values[j][0] = result[i].values[j][0] * 1000;
            }

            dataResult = result[i].values;
        }

        const sample = {
            name: namesAry[h] ? `${namesAry[h]}` : "",
            type: "line",
            itemStyle: {
                color: panels.attributes[h]?.color,
            },
            yAxisIndex: thisIndex !== -1 ? thisIndex : 0,
            lineStyle: {
                type: thisIndex === 0 ? "solid" : [10, 2],
            },
            data: dataResult,
            smooth: true,
            showSymbol: false,
        };

        series = [...series, sample];
    }
    const transformedData = {
        series: series,
    };

    return transformedData;
};

export const getOnlineOfflineValue = async (
    gateway_id: string,
    timeValue: string,
    panelToken: string
) => {
    try {
        const res: any = await AxiosInstance.get(
            APP_DASHBOARD_API +
                `/query?query=sum(count_over_time(mqtt2tsdb_heartbeat_na{gateway="${gateway_id}"}[${timeValue}]))&token=${panelToken}`
        );
        return res;
    } catch (err) {
        return err;
    }
};
