import styled from "@emotion/styled";
import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Form,
    Modal,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as TrashSvg } from "assets/svg/delete-white.svg";
import styledConst from "styles";
import { useContext, useEffect, useState } from "react";
import { CustomModal } from "components/modals/ModalTemplate";
import accountService from "service/accountService";
import localStorageService from "service/localStorageService";
import { isHttpSuccess } from "utils/functions";
import { get } from "lodash";
import { showErrorAlert } from "utils/alert";
import { useAuth } from "context/auth";
import { restoreData } from "store/actions";
import { AppContext, useAppContext } from "context/appContext";
import { BasicPlanModal } from "components/modals/BasicPlanModal";
import { useBasicPlanModal } from "hooks/useBasicPlanModal";
import { useCheckIfBasicPlan } from "hooks/useCheckIfBasicPlan";
import { WalletAlertComponent, walletModalTrigger } from "hooks/wallet";

const MutedText = styled.div`
    color: ${styledConst.Primary_Blue_4};
    font-size: 0.85rem;
`;

const NormalText = styled.span`
    color: #ffffff;
    font-size: 0.85rem;
`;

const CustomCheckbox = styled(Form.Check)`
    .custom-control-label {
        padding-left: 10px;
        font-size: 14px;
    }
`;

const CustomRow = styled.div`
    padding: 0.75rem 1rem;
    background-color: ${styledConst.Primary_Blue_3};
    border-radius: 5px;
    width: 100%;
`;
const CircleIcon = styled.div`
    height: 50px;
    width: auto;

    > span {
        background-color: #fbdce0;
        border-radius: 50%;
        padding: 25px;
        position: relative;

        path {
            fill: ${styledConst.Secondary_Red};
        }
    }

    .check-icon {
        background-color: ${styledConst.Primary_Green_1};
        border-radius: 50%;
        color: #ffffff;
        position: absolute;
        bottom: 0;
    }
`;

const ArrowIcon = styled.i`
    color: ${styledConst.Primary_Green_1};
`;

const CreateButton = styled(Button)`
    color: ${styledConst.Primary_Green_1};
`;

export const TerminateAccount = () => {
    const {
        storeData: {
            user: {
                userData: { email },
            },
        },
    } = useAppContext();
    const [confirmed, setConfirmed] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [password, setPassword] = useState("");
    const history = useHistory();
    const { IS_BASIC_PLAN } = useCheckIfBasicPlan();
    const { toggleShowBasicPlanModal, showBasicPlanModal }: any =
        useBasicPlanModal();
    const showConfirmModal = () => {
        setModalShow(true);
    };

    const closeConfirmModal = () => {
        setModalShow(false);
        setPassword("");
    };

    const processTermination = async () => {
        const res = await accountService.terminate({
            password,
        });

        if (isHttpSuccess(res.status)) {
            history.push("/user/terminate-success");
        } else {
            const message = get(
                res,
                "data.description",
                "Unable to delete account"
            );
            showErrorAlert({
                message,
            });
        }
    };

    const showNoAccessPopup = (e: any) => {
        if (IS_BASIC_PLAN) {
            e.preventDefault();
            toggleShowBasicPlanModal();
        } else {
            const trigger: any = walletModalTrigger(() => {
                history.push(`/user/download-data`);
            });
            if (trigger === undefined) e.preventDefault();
        }
    };

    return (
        <div className="page-content">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h5 className="page-title">Delete Account</h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Breadcrumb className="w-100">
                            <Breadcrumb.Item href="/user/settings">
                                Account Settings
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Delete Account
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="cstm-table">
                    <Col sm="12">
                        <CustomRow className="px-3">
                            <Row className="no-checkbox">
                                <Col>
                                    <Link
                                        to={{
                                            pathname: `/user/download-data`,
                                        }}
                                        onClick={showNoAccessPopup}
                                    >
                                        Download your data
                                    </Link>
                                    <MutedText className="mt-2">
                                        You can download your data before
                                        deleting your account.
                                    </MutedText>
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 2 }}
                                >
                                    <Link
                                        to={{
                                            pathname: `/user/download-data`,
                                        }}
                                        onClick={showNoAccessPopup}
                                    >
                                        <ArrowIcon className="material-icons right">
                                            keyboard_arrow_right
                                        </ArrowIcon>
                                    </Link>
                                </Col>
                            </Row>
                        </CustomRow>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col sm="12">
                        <div className="form-box">
                            <div>Read carefully !</div>
                            <MutedText className="mt-2">
                                You're trying to delete your IoTPortal Account
                                associated to email address{" "}
                                <NormalText>{email}</NormalText>, which will
                                provide access to various IoTPortal services. If
                                you still want to proceed with account deletion,
                                click on the ‘Delete Account’ button below.
                            </MutedText>

                            <div className="mt-4">
                                Acknowledge before deleting your account
                            </div>
                            <CustomCheckbox
                                className="mt-3"
                                id="confirm-deletion"
                                checked={confirmed}
                                custom
                                onChange={(e) => setConfirmed(e.target.checked)}
                                label="Yes, I want to permanently delete this IoTPortal Account and all its data. I acknowledge that by deleting, all my data associated to this account including gateways, organisations, events, mobile number will get deleted permanently and I will be removed from all the organisations in which I am a member.  I am still responsible for any charges incurred due to any pending financial transaction."
                            />

                            <Button
                                disabled={!confirmed}
                                variant="danger"
                                className="mt-4"
                                onClick={showConfirmModal}
                            >
                                <TrashSvg className="mr-3" />
                                <span>Delete Account</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <CustomModal
                centered
                show={modalShow}
                backdrop="static"
                onHide={closeConfirmModal}
                className="dlt no-header danger"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <>
                        <div className="modal-icon-box">
                            <span className="material-icons">delete</span>
                        </div>
                        <h3 className="mb-3">Delete Your Account?</h3>
                        <div className="mb-4">
                            <p className="mt-2 d-block">
                                You are proceeding to delete your IoTPortal
                                account associated with the email address{" "}
                                <strong>{email}</strong>
                            </p>
                            <p className="my-4 d-block">
                                Enter your account password to proceed.
                            </p>

                            <Form.Control
                                placeholder="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <Button variant="secondary" onClick={closeConfirmModal}>
                            CANCEL
                        </Button>
                        <Button
                            variant="danger"
                            onClick={processTermination}
                            disabled={!password}
                        >
                            PROCEED
                        </Button>
                    </>
                </Modal.Body>
            </CustomModal>
            <WalletAlertComponent />
            <BasicPlanModal
                showBasicPlanModal={showBasicPlanModal}
                toggleShowBasicPlanModal={toggleShowBasicPlanModal}
            />
        </div>
    );
};

export const TerminateSuccess = () => {
    const {
        context: { setAuthTokens },
    } = useAuth();
    const { storeDispatchActions } = useContext(AppContext);
    const history = useHistory();

    const createAccount = async () => {
        setAuthTokens("");
        storeDispatchActions(restoreData());
        history.replace("/sign-up");
    };

    useEffect(() => {
        localStorageService.clearAll();
    }, []);

    return (
        <div className="page-content">
            <Container>
                <Row className="mt-2">
                    <Col sm="12">
                        <div className="form-box text-center">
                            <CircleIcon className="mb-3 mt-5">
                                <span>
                                    <TrashSvg />
                                    <span className="material-icons check-icon">
                                        check
                                    </span>
                                </span>
                            </CircleIcon>
                            <p role="alert" className="mt-3">
                                Your request to delete IoTPortal account has
                                been sent successfully. This will take a few
                                hours to process.{" "}
                            </p>
                            <MutedText className="mt-3">
                                We’d love to have you back.
                            </MutedText>
                            <MutedText className="mt-1 ">
                                If you change your mind, please sign up again to
                                use IoTPortal services. If you wish to use the
                                same email address, please note that there is a
                                24-hour waiting period for security reasons.
                            </MutedText>

                            <CreateButton
                                className="mt-3"
                                variant="outline-primary"
                                onClick={createAccount}
                            >
                                Create an IoTPortal account again
                            </CreateButton>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TerminateAccount;
