/* eslint-disable import/no-anonymous-default-export */
const styledConst = {
    secondTextColor: "rgba(130, 143, 171, 1)",
    linkColor: "#00CBAF",
    secondBackgroundColor: "#3A4763",
    thirdTextColor: "#2F3A51",
    warningTextColor: "#2F3A51",
    Primary_White: "#fff",
    Default_Green_2: "#27AE60",
    Default_Red: "#EB5757",

    //
    Primary_Blue_4: "#828FAB", // toast message
    Primary_Blue_3: "#2F3A51", // toast bold message

    //  error
    errorBackground: "#FFEBED",
    Primary_Red: "#EB5757",
    Secondary_Red: "#F2495E",
    //  success
    successBackground: "#E9FFFC",
    Primary_Green_1: "#00CBAF",
    Secondary_Green: "#27AE60",
    // info
    infoBackground: "#E9FAFF",
    Primary_Blue: "#56CCF2",
    Primary_Blue_1: "#3C5A99",
    Primary_Blue_2: "#3C4D71",

    Neutral_Grey_1: "#F2F2F2",
    Neutral_Grey_2: "#4c4c4c",
    Orange: "#F2994A"
};

export default styledConst;
