import { Col, Button, Form } from "react-bootstrap";
import { Patterns } from "constant";

import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";

import { isValidText } from "utils/functions";
import { ChangeEvent, useEffect, useState } from "react";

type NameFieldTemplate = {
    input: string;
    updateField: (e: ChangeEvent<HTMLInputElement>) => void;
    isNameEditing: boolean;
    toggleEditButton: () => void;
    toggleUpdateButton: () => void;
    resourceTitle: string;
    resourcePermission: string;
    validationMessage?: string;
    validationRegex?: RegExp;
    shouldDisableEdit?: boolean;
};

const ResourceNameField = ({
    input,
    updateField,
    isNameEditing,
    toggleEditButton,
    toggleUpdateButton,
    resourceTitle,
    resourcePermission,
    validationMessage,
    validationRegex,
    shouldDisableEdit,
}: NameFieldTemplate) => {
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (!isNameEditing) {
            setIsProcessing(false);
        }
    }, [isNameEditing]);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                toggleUpdateButton();
            }}
        >
            <div className="form-box mt-3">
                <Form.Row>
                    <Col md="12">
                        <Form.Label>{resourceTitle}</Form.Label>
                    </Col>
                    <Col md="10" xs="12">
                        <Form.Group className="mb-0">
                            <Form.Control
                                type="text"
                                required
                                aria-label="name"
                                name="name"
                                defaultValue={input}
                                onChange={updateField}
                                disabled={!isNameEditing}
                                isInvalid={
                                    !isValidText(
                                        "name",
                                        {
                                            name: input,
                                        },
                                        { name: "" },
                                        validationRegex
                                    )
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {validationMessage}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md="2" xs="12">
                        {!isNameEditing ? (
                            <HoverAuthorizeTooltip
                                permission={resourcePermission}
                            >
                                <Button
                                    type="button"
                                    className="w-100 mt-2 mt-md-0"
                                    onClick={toggleEditButton}
                                    disabled={shouldDisableEdit}
                                >
                                    Edit
                                </Button>
                            </HoverAuthorizeTooltip>
                        ) : (
                            <Button
                                type="button"
                                variant={"primary"}
                                className="w-100 mt-2 mt-md-0"
                                onClick={() => {
                                    setIsProcessing(true);
                                    toggleUpdateButton();
                                }}
                                disabled={
                                    !isValidText(
                                        "name",
                                        {
                                            name: input,
                                        },
                                        { name: "" },
                                        Patterns.orgNamePattern
                                    ) || isProcessing
                                }
                            >
                                Update
                            </Button>
                        )}
                    </Col>
                </Form.Row>
            </div>
        </Form>
    );
};

export default ResourceNameField;
