import AxiosInstance from "./axiosInstance";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "../utils/functions";
import { apiFactory } from "generated";
import {
    MemberGroup,
} from "generated/models";

class UserGroupsService {
    private organisationClient = apiFactory.getOrganisationInstance(AxiosInstance);

    /**
     * Create new role
     * @param { object } data - New role data
     * @returns { promise }
     */
    async createNewGroup(orgId: string, name: string) {
        try {
            const res = await this.organisationClient.createMemberGroup(orgId, {
                name
            });
            return createResponseStandard<MemberGroup>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Update Group name
     * @param { string } name - New Group name
     * @returns { promise }
     */
    async updateGroupName(
        orgId: string,
        groupId: string, 
        name: string
    ) {
        try {
            const res = await this.organisationClient.updateMemberGroupByUuid(orgId, groupId, {
                name
            });
            return createResponseStandard<MemberGroup>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async getGroupById(orgId: string, groupId: string) {
        try {
            const res = await this.organisationClient.getMemberGroupByUuid(orgId, groupId);
            return createResponseStandard<MemberGroup>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Add Members to Group
     * @param { string } groupId - Group id
     * @param { Array } memberIds - Member ids
     * @returns { Promise }
     */
    async addMembers(orgId: string, groupId: string, members: string[]) {
        try {
            const res = await this.organisationClient.updateMemberGroupByUuid(orgId, groupId, {
                add_members: members,
            });
            return createResponseStandard<MemberGroup>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Add Roles to Group
     * @param { string } groupId - Group id
     * @param { Array } roleIds - Role ids
     * @returns { Promise }
     */
    async addRoles(orgId: string, groupId: string, roleIds: Array<string>) {
        try {
            const res = await this.organisationClient.updateMemberGroupByUuid(orgId, groupId, {
                add_roles: roleIds,
            });

            return createResponseStandard<MemberGroup>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Remove Members from Group
     * @param { string } orgId - Organisation id
     * @param { string } groupId - Group id
     * @param { Array } members - List Member email
     * @returns { Promise }
     */
    async removeMembers(orgId: string, groupId: string, members: string[]) {
        try {
            const res = await this.organisationClient.updateMemberGroupByUuid(orgId, groupId, {
                remove_members: members,
            });

            return createResponseStandard<MemberGroup>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Remove Members from Group
     * @param { string } orgId - Organisation id
     * @param { string } groupId - Group id
     * @param { Array } roleIds - List Role id
     * @returns { Promise }
     */

    async removeRoles(orgId: string, groupId: string, roleIds: string[]) {
        try {
            const res = await this.organisationClient.updateMemberGroupByUuid(orgId, groupId, {
                remove_roles: roleIds,
            });

            return createResponseStandard<MemberGroup>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Remove Group
     * @param { string } groupId - Role id
     * @returns { promise }
     */
    async deleteGroup(orgId: string, groupId: string) {
        try {
            const res = await this.organisationClient.deleteMemberGroupByUuid(orgId, groupId);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }
}

const userGroupsService = new UserGroupsService();

export default userGroupsService;
