import { useState, useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { capitalize, get, isEmpty, startCase } from "lodash";
import { Container, Row, Col, Breadcrumb, Button, Form } from "react-bootstrap";

import EventService from "service/eventService";
import {
    getActuatorCommand,
    getDeviceListByGatewayId,
} from "service/gatewayService";
import LocalStorageService from "service/localStorageService";
import ContentWrapper from "components/content-wrapper/ContentWrapper";

import "assets/css/event.css";
import { AUTO_MODE, MANUAL_MODE } from "constant";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { NEW_ACTION_MESSAGE, UPDATE_ACTION_MESSAGE } from "constant/messages";
import { isHttpSuccess } from "utils/functions";
import { ResponseDto } from "types";
import { EventActionActuatorStatus } from "generated/models";
import { getDisplayedActuatorMode } from "pages/events/EventDetails";

const TriggerActuator = () => {
    const location: any = useLocation();
    const history: any = useHistory();
    const params: any = useParams();
    const selectedActuatorDetails = get(
        location,
        "state.selectedActuatorDetails",
        {}
    );
    const OFF = "off";
    const ON = "on";
    const [checkedOption, setCheckedOption] = useState(OFF);
    const [actuatorDetails, setActuatorDetails]: any = useState({});
    const [actionDetails, setActionDetails]: any = useState({
        name: "",
        type: "",
        mode: "",
        auto: false,
        gatewayId: "",
        ldsuUUID: "",
        said: "",
    });
    const [isPageLoading, updateIsPageLoading] = useState(true);

    useEffect(() => {
        if (!params.actionId) {
            const fetchActuator = async () => {
                const response: any = await getActuatorCommand(
                    selectedActuatorDetails.gatewayId,
                    selectedActuatorDetails.ldsuUUID,
                    selectedActuatorDetails.said
                );

                if (isHttpSuccess(response.status)) {
                    const { data: actuator } = response;

                    setActuatorDetails(actuator);
                    updateIsPageLoading(false);
                }
            };
            fetchActuator();
        } else {
            const fetchCurrentSensor = async () => {
                const response: any = await EventService.readOneAction(
                    params.eventId,
                    params.actionId
                );
                if (isHttpSuccess(response.status)) {
                    const { device_id, gateway_id, said, status } =
                        response.data.resource;

                    const actuatorRes: any = await getActuatorCommand(
                        gateway_id,
                        device_id,
                        said
                    );

                    const metricCLSList =
                        LocalStorageService.getItem("metricInfo").cls;

                    const {
                        auto = false,
                        mode = "LEVEL",
                        name = "",
                    } = get(actuatorRes, "data", {});
                    const deviceList: any = await getDeviceListByGatewayId(
                        gateway_id
                    );

                    const getClassType: any = () => {
                        // eslint-disable-next-line  array-callback-return
                        const selectedActuator = deviceList.find((d: any) => {
                            const { SAID, ldsu_uuid, gatewayId } = d;
                            if (
                                gatewayId === gateway_id &&
                                ldsu_uuid === device_id &&
                                SAID === said
                            )
                                return d;
                        });

                        const class_: any = get(
                            metricCLSList,
                            `${selectedActuator.CLS}.name`,
                            ""
                        );

                        return class_;
                    };

                    updateIsPageLoading(false);

                    setActionDetails({
                        name,
                        auto,
                        said,
                        mode,
                        type: getClassType(),
                        gatewayId: gateway_id,
                        ldsuUUID: device_id,
                    });

                    if (status === "on") {
                        setCheckedOption(ON);
                    }
                }
            };
            fetchCurrentSensor();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeAction = () => {
        history.push(`/event-details/${params.eventId}`);
    };

    const handleButtonAction = async () => {
        let value: EventActionActuatorStatus =
            checkedOption === OFF ? "off" : "on";

        let response: ResponseDto<any>;

        if (!params.actionId) {
            const { gatewayId, ldsuUUID, said } = selectedActuatorDetails;
            const body: any = {
                type_: `ACTUATOR`,
                resource: {
                    said: said,
                    device_id: ldsuUUID,
                    gateway_id: gatewayId,
                    status: value,
                },
            };
            response = await EventService.createAction(params.eventId, body);
        } else {
            const { gatewayId, ldsuUUID, said }: any = actionDetails;
            response = await EventService.updateAction(
                params.eventId,
                params.actionId,
                {
                    type_: `ACTUATOR`,
                    resource: {
                        said: said,
                        device_id: ldsuUUID,
                        gateway_id: gatewayId,
                        status: value,
                    },
                }
            );
        }

        if (isHttpSuccess(response.status)) {
            showSuccessAlert({
                message: params.actionId
                    ? UPDATE_ACTION_MESSAGE
                    : NEW_ACTION_MESSAGE,
            });
            closeAction();
        } else {
            showErrorAlert({
                title: get(response, "data.title", "Error"),
                message: get(
                    response,
                    "data.description",
                    "Unable to save the Trigger Actuator action"
                ),
            });
        }
    };

    const renderActuatorInfo = () => {
        if (selectedActuatorDetails || !isEmpty(actionDetails)) {
            let name: any;
            let type: any;
            let configMode: any;
            let actionMode: any;

            name =
                selectedActuatorDetails?.name ||
                actionDetails.name ||
                actuatorDetails.name;
            type = selectedActuatorDetails?.type || actionDetails.type;
            configMode = actuatorDetails?.mode || actionDetails.mode;
            actionMode =
                (!isEmpty(actuatorDetails) &&
                    (actuatorDetails?.auto ? AUTO_MODE : MANUAL_MODE)) ||
                (!isEmpty(actionDetails) &&
                    (actionDetails.auto ? AUTO_MODE : MANUAL_MODE));

            return (
                <div className="form-box mb-3">
                    <h5 className="mb-4">Actuator</h5>
                    <div className="action-device-details">
                        <h5>{name}</h5>
                        <p>{startCase(type)}</p>
                        {getDisplayedActuatorMode(configMode) && (
                            <h6>
                                Mode:{" "}
                                <span className="text-primary-blue-4 ml-2">
                                    {getDisplayedActuatorMode(configMode)}
                                </span>
                            </h6>
                        )}
                        <h6>
                            Control:{" "}
                            <span className="text-primary-blue-4 ml-2">
                                {capitalize(actionMode)}
                            </span>
                        </h6>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <ContentWrapper isLoading={isPageLoading}>
                <Container>
                    <Row>
                        <Col sm="12" className="event-detail-head">
                            <h5 className="page-title overflow-text">
                                Trigger Actuator
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item href="/events">
                                    Events
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={`/event-details/${params.eventId}`}
                                >
                                    Event Details
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Action</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row className="condition-detail-box">
                        <Col>
                            {renderActuatorInfo()}
                            <div className="form-box mb-3">
                                <h5 className="mb-4">Action</h5>
                                <Form.Row>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="mode"
                                            id={ON}
                                            custom
                                            type="radio"
                                            onChange={() => {
                                                setCheckedOption(ON);
                                            }}
                                            checked={checkedOption === ON}
                                            label={
                                                <span className="ml-2">
                                                    Activate
                                                </span>
                                            }
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="mode"
                                            id={OFF}
                                            custom
                                            type="radio"
                                            onChange={() => {
                                                setCheckedOption(OFF);
                                            }}
                                            checked={checkedOption === OFF}
                                            label={
                                                <span className="ml-2">
                                                    Deactivate
                                                </span>
                                            }
                                        />
                                    </Col>
                                </Form.Row>
                            </div>
                            <Button
                                variant="secondary"
                                className="mr-2"
                                onClick={closeAction}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="pl-4 pr-4"
                                onClick={handleButtonAction}
                            >
                                {!params.actionId ? "ADD" : "UPDATE"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ContentWrapper>
        </>
    );
};

export default TriggerActuator;
