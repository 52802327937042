import { styled } from "@mui/material";
import { TokenConversionRateModal } from "components/modals/TokenConversionRateModal";
import { useState } from "react";
import { Link } from "react-router-dom";

type Props = {
    className?: string;
};
const TokenConversionLink = ({ className = "" }: Props) => {
    const [tokenRateShow, setTokenRateShow] = useState(false);

    const handleShowTokenRate = () => {
        setTokenRateShow(true);
    };

    const handleCloseTokenRate = () => {
        setTokenRateShow(false);
    };
    return (
        <>
            <CustomLink
                to="#"
                className={`green ${className}`}
                onClick={handleShowTokenRate}
            >
                Token conversion rate
            </CustomLink>
            <TokenConversionRateModal
                show={tokenRateShow}
                onClose={handleCloseTokenRate}
            ></TokenConversionRateModal>
        </>
    );
};

const CustomLink = styled(Link)`
    text-decoration: underline;
`

export default TokenConversionLink;
