import createVanilla from "zustand/vanilla";
import type { AxiosResponse } from "axios";
import { HttpStatus, HeartbeatUpdateAfter } from "constant";
import * as _ from "lodash";
import { checkIfReachable } from "utils/functions";

const readingUrlPattern = /^\/gateways\/([^/]*)\/reading2/;
const configUrlPattern = /^\/gateways\/([^/]*)\/config/;

export const gatewayReadingStatusStore = createVanilla<{
    gatewayReadingData: { [key: string]: boolean };
    gatewayConfigData: {};
    setGatewayReadingData: (response: AxiosResponse) => void;
    setGatewayConfigData: (response: AxiosResponse) => void;
}>()((set) => ({
    gatewayReadingData: {},
    setGatewayReadingData: (response) =>
        set((state) => {
            const urlObj = new URL(response.config.url!);
            const gatewayId = urlObj.pathname.match(readingUrlPattern)?.[1];

            if (gatewayId && response.status === HttpStatus.OK) {
                const { gatewayConfigData = {} } = state;
                const gatewayReadingData = _.get(response, "data.data", {});
                const timestamp = Math.round(new Date().getTime() / 1000);
                const data: { [key: string]: boolean } = {};
                data[gatewayId] =
                    timestamp - gatewayReadingData.heartbeat <
                    HeartbeatUpdateAfter;
                gatewayReadingData.devices?.forEach((device: any) => {
                    data[device.UID] = checkIfReachable(
                        device.ts,
                        _.get(gatewayConfigData, `[${device.UID}].INTVL`, 5)
                    );
                });

                return { gatewayReadingData: data };
            } else {
                return state;
            }
        }),

    gatewayConfigData: {},
    setGatewayConfigData: (response) =>
        set((state) => {
            const urlObj = new URL(response.config.url!);
            const gatewayId = urlObj.pathname.match(configUrlPattern)?.[1];

            if (gatewayId && response.status === HttpStatus.OK) {
                const gatewayConfigData = _.get(
                    response,
                    "data.data",
                    []
                ).reduce((dict: any, item: any) => {
                    dict[item.UID] = item;
                    return dict;
                }, {});
                return { gatewayConfigData };
            } else {
                return state;
            }
        }),
}));

export {};
