import { TypeOption } from "constant";
import { Variable } from "generated/models";
import { getTypeOptionKeyByValue } from "utils/eventFunctions";

type EventVariableInfoProps = {
    eventVariable: Variable;
};

const EventVariableInfo = ({ eventVariable }: EventVariableInfoProps) => {
    const getDirectionText = () => {
        const { attributes } = eventVariable;
        return attributes?.direction ? "Up count" : "Down count";
    };

    const getWraparoundText = () => {
        const { attributes } = eventVariable;
        return attributes?.overflow ? "Enabled" : "Disabled";
    };

    const renderEventVariableInfo = () => {
        if (TypeOption.Counter === eventVariable.type_) {
            return (
                <>
                    <p>
                        Type:{" "}
                        <span>
                            {getTypeOptionKeyByValue(eventVariable.type_)}
                        </span>
                    </p>
                    <p>
                        Count: <span>{getDirectionText()}</span>
                    </p>
                    <p>
                        Initial value: <span>{Number(eventVariable.initial)}</span>
                    </p>
                    <p>
                        Terminal value:{" "}
                        <span>{eventVariable.attributes?.terminal}</span>
                    </p>
                    <p>
                        Step size: <span>{eventVariable.attributes?.step}</span>
                    </p>
                    <p>
                        Wraparound: <span>{getWraparoundText()}</span>
                    </p>
                </>
            );
        } else {
            return (
                <p className="not-counter-info">
                    Type:{" "}
                    <span>{getTypeOptionKeyByValue(eventVariable.type_)}</span>
                </p>
            );
        }
    };

    return (
        <div className="event-variable-info">
            <h4>{eventVariable.name}</h4>

            {renderEventVariableInfo()}
        </div>
    );
};

export default EventVariableInfo;
