import React from "react";
import * as _ from "lodash";
import styled from "@emotion/styled";

type SortDirection = "asc" | "desc";

type SortBy = {
    key: string;
    direction: SortDirection;
};

type TransformObject = (value: any, index?: number) => any;

const sortCollection = (collection: any[], sortBy: SortBy) => {
    const { key, direction } = sortBy;
    const sortedCollection = _.orderBy(collection, key, direction);
    return sortedCollection;
};

type SortIconProps = {
    keyName: string;
    sortBy: SortBy;
    setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
};

const SortIconTag = styled.i`
    position: absolute;
    top: 50%;
    font-size: ${(props: any) => props.size || 16}px;
    margin-left: 0.75rem;
    color: #828fab;
    transform: translateY(-50%);
    cursor: pointer;
    &.active {
        color: #00cbaf;
    }
`;

const SortIcon: React.FC<SortIconProps> = ({ sortBy, setSortBy, keyName }) => {
    const { direction } = sortBy;
    if (keyName !== sortBy.key) {
        return (
            <SortIconTag
                className="material-icons"
                onClick={() => {
                    setSortBy({ key: keyName, direction: "desc" });
                }}
            >
                unfold_more
            </SortIconTag>
        );
    }

    return direction === "asc" ? (
        <SortIconTag
            className="material-icons active"
            onClick={() => {
                setSortBy({ key: keyName, direction: "desc" });
            }}
        >
            expand_less
        </SortIconTag>
    ) : (
        <SortIconTag
            className="material-icons active"
            onClick={() => {
                setSortBy({ key: keyName, direction: "asc" });
            }}
        >
            expand_more
        </SortIconTag>
    );
};

const useCollectSort = (collection?: any[], transform?: TransformObject) => {
    const [sortBy, setSortBy] = React.useState<SortBy>({
        key: "",
        direction: "asc",
    });

    React.useEffect(() => {
        _.size(collection) && setSortBy({ key: "", direction: "asc" });
    }, [collection]);

    if (transform && collection) {
        collection = collection.map(transform);
    }

    const data = collection ? sortCollection(collection, sortBy) : [];

    const sortIcon = (key: string) => (
        <SortIcon keyName={key} sortBy={sortBy} setSortBy={setSortBy} />
    );

    return { data, sortBy, setSortBy, sortIcon };
};

export default useCollectSort;
