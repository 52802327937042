import { MouseEvent, useEffect, useState, ChangeEvent } from "react";
import { Modal, Button, Form, FormControl } from "react-bootstrap";
import { COLORS, Patterns, DASHBOARD_NAME_MESSAGE, FormType } from "constant";
import { ReactComponent as DashboardInactiveIcon } from "assets/svg/dashboards-not-active.svg";
import { DashboardTagOptions } from "types";
import { isValidText } from "utils/functions";
import { ColorPickerBackground } from "components/common";

type DashboardModalProps = {
    show: boolean;
    onClose: (e: MouseEvent<HTMLElement>) => void;
    currentDashboard?: DashboardTagOptions;
    onSubmit: Function;
    type: FormType;
};

export const DashboardModal = ({
    show,
    onClose,
    currentDashboard,
    onSubmit,
    type,
}: DashboardModalProps) => {
    const [activeColor, setActiveColor] = useState("");
    const [dashboardName, setDashboardName] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setIsSubmitting(false);
    }, [show]);

    useEffect(() => {
        if (type === FormType.UPDATE && currentDashboard) {
            setActiveColor(currentDashboard.label.color);
            setDashboardName(currentDashboard.value);
        } else {
            setActiveColor("");
            setDashboardName("");
        }
    }, [show, currentDashboard, type]);

    const handleChangeDashboardName = (e: ChangeEvent<HTMLInputElement>) => {
        setDashboardName(e.target.value);
    };

    const submitDashboard = () => {
        if (
            !isValidText(
                "name",
                { name: dashboardName },
                { name: "" },
                Patterns.dashboardNamePattern
            ) ||
            !activeColor
        ) {
            return;
        }
        setIsSubmitting(true);
        onSubmit(dashboardName, activeColor);
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            className="new-dash"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {type === FormType.CREATE ? "New" : "Edit"} Dashboard
                </Modal.Title>
                <Button variant="" className="close-button" onClick={onClose}>
                    <span className="material-icons">close</span>
                </Button>
            </Modal.Header>

            <Modal.Body>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        submitDashboard();
                    }}
                >
                    <FormControl
                        type="text"
                        autoFocus
                        placeholder="Dashboard Name"
                        className="mb-5"
                        name="name"
                        aria-label="dashboard-name"
                        value={dashboardName}
                        onChange={handleChangeDashboardName}
                        isInvalid={
                            !isValidText(
                                "name",
                                { name: dashboardName },
                                { name: "" },
                                Patterns.dashboardNamePattern
                            )
                        }
                    />
                    <Form.Control.Feedback
                        className="dashboard-name-error-msg"
                        type="invalid"
                    >
                        {DASHBOARD_NAME_MESSAGE}
                    </Form.Control.Feedback>

                    <h4 className="mb-4">Icon Color</h4>
                    <div className="icons" key="icons">
                        {COLORS.map((color) => {
                            return (
                                <ColorPickerBackground
                                    $inputColor={color}
                                    key={color}
                                    className="dash-icon"
                                    tabIndex={0}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            submitDashboard();
                                        }
                                    }}
                                    onClick={() => setActiveColor(color)}
                                >
                                    <div aria-label="dash-icon">
                                        <DashboardInactiveIcon />

                                        {activeColor === color ? (
                                            <span className="material-icons done">
                                                done
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </ColorPickerBackground>
                            );
                        })}
                    </div>
                    <p className="dashboard-color-error-msg">
                        {activeColor === "" && "Dashboard must have a color"}
                    </p>

                    <Button
                        disabled={
                            isSubmitting ||
                            !isValidText(
                                "name",
                                { name: dashboardName },
                                { name: "" },
                                Patterns.dashboardNamePattern
                            ) ||
                            !activeColor
                        }
                        variant="primary"
                        className="btn-create w-100"
                        onClick={submitDashboard}
                    >
                        {type === FormType.CREATE ? "CREATE" : "SUBMIT"}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
