import { useEffect, useState, KeyboardEvent, ChangeEvent } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Form, Button } from "react-bootstrap";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import EventVariableService from "service/eventVariableService";
import EventService from "service/eventService";
import { isHttpSuccess } from "utils/functions";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import {
    FETCH_EVENT_VARIABLES_FAIL_MESSAGE,
    TypeOption,
    variableType,
} from "constant";
import {
    ActionPostType,
    EventActionVariable,
    Variable,
} from "generated/models";
import EventVariableInfo from "components/events/EventVariableInfo";
import SelectEventVariableModal from "components/events/SelectEventVariableModal";
import { MathExpressionValidator } from "utils/math-expression-validator";
import { faker } from "@faker-js/faker";

type ModifyEventVariableParam = {
    eventId: string;
    actionId: string;
};

const expressions = ["+", "-", "*", "/", "(", ")"];

type Tag = {
    id?: string;
    type: string;
    value: string;
    initial?: string;
    uuid?: string;
};

const ModifyEventVariable = () => {
    const params: ModifyEventVariableParam = useParams();
    const history = useHistory();
    const location: {
        state?: {
            actionPayload: any;
        };
    } = useLocation();
    const actionPayload = location?.state?.actionPayload;
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [eventVariable, setEventVariable] = useState<Variable>(
        {} as Variable
    );
    const [isShowEventVariables, setIsShowEventVariables] = useState(false);
    const [eventVariables, setEventVariables] = useState<Variable[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [newTags, setNewTags] = useState<Tag[]>([]);
    const [eventVariableSelected, setEventVariableSelected] = useState("");
    const [action, setAction] = useState("");

    const expressionStringToExpressionArray = (expressionString: string) => {
        const result: Tag[] = [];
        // Regular expression to match each part
        const regex = /{([^{}]+)}|([+\-*/()])|(\d+(?:\.\d+)?)/g;
        let match;
        while ((match = regex.exec(expressionString)) !== null) {
            if (match[1]) {
                // It's a variable
                result.push({
                    type: "variable",
                    value: actionPayload.variable?.name,
                    id: actionPayload.variables[match[1]],
                    initial: Number(actionPayload.variable?.initial).toString(),
                    uuid: faker.datatype.uuid(),
                });
            } else if (match[2]) {
                // It's an expression
                result.push({
                    type: "expression",
                    value: match[2],
                    uuid: faker.datatype.uuid(),
                });
            } else if (match[3]) {
                // It's a number
                result.push({
                    type: "number",
                    value: match[3],
                    uuid: faker.datatype.uuid(),
                });
            }
        }
        return result;
    };

    const setDefaultCreateData = ({ type_ }: Variable) => {
        switch (type_) {
            case TypeOption.Boolean:
                setAction("true");
                break;
            case TypeOption.Integer:
                setAction("");
                break;
            case TypeOption.Float:
                setAction("");
                break;
            case TypeOption.Timer:
                setAction("start");
                break;
            case TypeOption.Counter:
                setAction("bump");
                break;
        }
    };

    const setDefaultUpdateData = ({ type_ }: Variable) => {
        switch (type_) {
            case TypeOption.Boolean:
            case TypeOption.Timer:
            case TypeOption.Counter:
                setAction(actionPayload?.action);
                break;
            case TypeOption.Integer:
            case TypeOption.Float:
                setNewTags(
                    expressionStringToExpressionArray(actionPayload?.action)
                );
                break;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsPageLoading(true);
            const fetchResp = await EventVariableService.getEventVariableById(
                params.actionId
            );

            if (!isHttpSuccess(fetchResp.status)) {
                showErrorAlert(
                    getAPIError(fetchResp, FETCH_EVENT_VARIABLES_FAIL_MESSAGE)
                );
            } else {
                setEventVariable(fetchResp.data);
                if (actionPayload) {
                    setDefaultUpdateData(fetchResp.data);
                } else {
                    setDefaultCreateData(fetchResp.data);
                }
            }
            setIsPageLoading(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buildActionPayload = () => {
        let updatedValue = "";
        let mathematicalExpression = "";
        let variables: Record<string, string> = {};
        if (
            [TypeOption.Float, TypeOption.Integer].includes(
                eventVariable.type_ as TypeOption
            )
        ) {
            newTags.forEach((tag: Tag, index: number) => {
                if (tag.type === "variable" && tag.id) {
                    mathematicalExpression += tag.initial;
                    variables[tag.value] = tag.id;
                    updatedValue += `{${tag.value}}`;
                } else {
                    updatedValue += tag.value;
                    mathematicalExpression += tag.value;
                }
            });

            mathematicalExpression = mathematicalExpression.replace(/\s+/g, "");

            const validator = new MathExpressionValidator();
            const isValid = validator.validateExpression(
                mathematicalExpression
            );
            if (!isValid) {
                showErrorAlert({
                    message: "Invalid mathematical expression.",
                });
                return;
            }
        } else {
            updatedValue = action;
        }

        updatedValue = updatedValue.replace(/\s+/g, "");

        const resource: EventActionVariable = {
            variable: eventVariable.uuid,
            value: updatedValue,
        };

        if (Object.keys(variables).length) {
            resource.variables = variables;
        }

        return {
            type_: variableType as ActionPostType,
            resource: resource,
        };
    };

    const handleAddAction = async () => {
        const payload = buildActionPayload();
        if (!payload) return;

        const updatedResp = await EventService.createAction(
            params.eventId,
            payload
        );

        if (isHttpSuccess(updatedResp.status)) {
            history.push(`/event-details/${params.eventId}`);
            showSuccessAlert({
                message: "New action has been added.",
            });
        } else {
            showErrorAlert(
                getAPIError(
                    updatedResp,
                    "Unable to create action. Please try again."
                )
            );
        }
    };

    const handleUpdateAction = async () => {
        const body = buildActionPayload();
        if (!body) return;

        const updatedResp = await EventService.updateAction(
            params.eventId,
            actionPayload.uuid,
            body
        );

        if (isHttpSuccess(updatedResp.status)) {
            history.push(`/event-details/${params.eventId}`);
            showSuccessAlert({
                message: "The action has been updated.",
            });
        } else {
            showErrorAlert(
                getAPIError(
                    updatedResp,
                    "Unable to update action. Please try again."
                )
            );
        }
    };

    const handleRemoveExpression = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            const lastValue = newTags[newTags.length - 1];
            if (lastValue && lastValue.type === "number") {
                const cloneTags = [...newTags];
                const updatedValue = lastValue.value.slice(0, -1);
                cloneTags[cloneTags.length - 1] = {
                    ...cloneTags[cloneTags.length - 1],
                    type: "number",
                    value: updatedValue,
                };
                if (updatedValue) {
                    setNewTags(cloneTags);
                } else {
                    setNewTags(newTags.slice(0, -1));
                }
            } else {
                setNewTags(newTags.slice(0, -1));
            }
        }
    };

    const addExpression = (expression: string) => {
        setNewTags([
            ...newTags,
            {
                type: "expression",
                value: expression,
                uuid: faker.datatype.uuid(),
            },
        ]);
    };

    const handleUpdateNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue("");
        const currentValue = e.target.value;

        if (["+", "-", "*", "/", "(", ")"].includes(currentValue)) {
            setNewTags([
                ...newTags,
                {
                    type: "expression",
                    value: currentValue,
                    uuid: faker.datatype.uuid(),
                },
            ]);
            return;
        }

        const lastValue = newTags[newTags.length - 1];

        if (lastValue && lastValue.type === "number") {
            const cloneTags = [...newTags];
            cloneTags[cloneTags.length - 1] = {
                ...cloneTags[cloneTags.length - 1],
                type: "number",
                value: lastValue.value + currentValue,
            };
            setNewTags(cloneTags);
        } else {
            setNewTags([
                ...newTags,
                {
                    type: "number",
                    value: currentValue,
                    uuid: faker.datatype.uuid(),
                },
            ]);
        }
    };

    const renderExpressionButton = () => {
        return expressions.map((expression: string) => (
            <Button key={expression} onClick={() => addExpression(expression)}>
                {expression}
            </Button>
        ));
    };

    const fetchEventVariables = async () => {
        const fetchResp = await EventVariableService.readEventVariables();

        if (!isHttpSuccess(fetchResp.status)) {
            showErrorAlert(
                getAPIError(fetchResp, FETCH_EVENT_VARIABLES_FAIL_MESSAGE)
            );
        } else {
            setEventVariables(
                fetchResp.data.filter(
                    (item: Variable) => item.type_ === eventVariable.type_
                )
            );
        }
    };

    const showEventVariables = async () => {
        await fetchEventVariables();
        setIsShowEventVariables(true);
    };

    const handleAddOrUpdateAction = async () => {
        if (Object.keys(actionPayload || {}).length) {
            await handleUpdateAction();
        } else {
            await handleAddAction();
        }
    };

    const handleSelectedEventVariable = () => {
        setIsShowEventVariables(false);
        const variable = eventVariables.find(
            (item: Variable) => item.uuid === eventVariableSelected
        );
        if (variable) {
            setNewTags([
                ...newTags,
                {
                    type: "variable",
                    value: variable.name,
                    id: eventVariableSelected,
                    initial: Number(variable.initial).toString(),
                    uuid: faker.datatype.uuid(),
                },
            ]);
        }

        setEventVariableSelected("");
    };

    return (
        <ContentWrapper isPageLoading={isPageLoading}>
            <Container className="modify-event-variable">
                <Row>
                    <Col sm="12" className="event-detail-head">
                        <h5 className="page-title overflow-text">
                            Modify Event Variable
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Breadcrumb className="w-100">
                            <Breadcrumb.Item active>
                                <Link to="/events">Events</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <Link to={`/event-details/${params.eventId}`}>
                                    Event Details
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {params.actionId ? "Action" : "Condition"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="action-detail-box">
                    <Col>
                        <div className="form-box mb-3">
                            <h5 className="mb-4 event-variable-label">
                                Event Variable
                            </h5>
                            {
                                <EventVariableInfo
                                    eventVariable={eventVariable}
                                />
                            }
                        </div>

                        <div className="form-box mb-3">
                            <h5 className="mb-4 event-variable-label">
                                {[
                                    TypeOption.Boolean,
                                    TypeOption.Float,
                                    TypeOption.Integer,
                                ].includes(eventVariable.type_ as TypeOption)
                                    ? "Modify Value"
                                    : "Action"}
                                {[
                                    TypeOption.Float,
                                    TypeOption.Integer,
                                ].includes(
                                    eventVariable.type_ as TypeOption
                                ) && (
                                    <p className="mt-2">
                                        Enter a mathematical expression to
                                        modify the value of Event Variable
                                    </p>
                                )}
                            </h5>

                            {TypeOption.Boolean === eventVariable.type_ && (
                                <Row>
                                    <Col sm="12">
                                        <Form.Control
                                            as="select"
                                            value={action}
                                            onChange={(e) => {
                                                setAction(e.target.value);
                                            }}
                                        >
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                            )}

                            {[TypeOption.Float, TypeOption.Integer].includes(
                                eventVariable.type_ as TypeOption
                            ) && (
                                <>
                                    <div className="modify-label">
                                        <p>{eventVariable.name} = </p>
                                    </div>
                                    <div className="tag-input-container">
                                        <ul className="tags">
                                            {newTags.map(
                                                ({
                                                    type,
                                                    value: tag,
                                                    uuid,
                                                }) => (
                                                    <li
                                                        key={uuid}
                                                        className={
                                                            type === "variable"
                                                                ? "tag"
                                                                : ""
                                                        }
                                                    >
                                                        <span className="tag-title">
                                                            {tag}
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                            <li className="input-tag">
                                                <input
                                                    type="text"
                                                    value={inputValue}
                                                    onKeyDown={
                                                        handleRemoveExpression
                                                    }
                                                    onChange={
                                                        handleUpdateNumberValue
                                                    }
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <Row className="mt-2">
                                        <Col className="list-operation-btn">
                                            {renderExpressionButton()}

                                            <Button
                                                onClick={showEventVariables}
                                            >
                                                Add an Event Variable
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {TypeOption.Counter === eventVariable.type_ && (
                                <Row>
                                    <Col sm="12">
                                        <Form.Control
                                            as="select"
                                            value={action}
                                            onChange={(e) => {
                                                setAction(e.target.value);
                                            }}
                                        >
                                            <option value="bump">Bump</option>
                                            <option value="reset">
                                                Reset to initial value
                                            </option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                            )}

                            {TypeOption.Timer === eventVariable.type_ && (
                                <Row>
                                    <Col sm="12">
                                        <Form.Control
                                            as="select"
                                            value={action}
                                            onChange={(e) => {
                                                setAction(e.target.value);
                                            }}
                                        >
                                            <option value="start">Start</option>
                                            <option value="stop">Stop</option>
                                            <option value="reset">Reset</option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                            )}
                        </div>

                        <Button
                            variant="secondary"
                            className="pl-4 pr-4 mr-2"
                            onClick={() => {
                                history.push(
                                    `/event-details/${params.eventId}`
                                );
                            }}
                        >
                            CANCEL
                        </Button>

                        <Button
                            variant="primary"
                            className="pl-4 pr-4"
                            onClick={handleAddOrUpdateAction}
                        >
                            {Object.keys(actionPayload || {}).length
                                ? "UPDATE"
                                : "ADD"}
                        </Button>
                    </Col>
                </Row>
            </Container>

            <SelectEventVariableModal
                isShowEventVariables={isShowEventVariables}
                setIsShowEventVariables={setIsShowEventVariables}
                eventVariableSelected={eventVariableSelected}
                eventVariables={eventVariables}
                handleSelectedEventVariable={handleSelectedEventVariable}
                setEventVariableSelected={setEventVariableSelected}
                modalTitle="Add an Event Variable"
            />
        </ContentWrapper>
    );
};

export default ModifyEventVariable;
