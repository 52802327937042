import { MemberDto, MemberGroupDto, OrganisationDto, OrganisationPermissions, RoleDto, UserDto } from "types";
import { EventKey } from "../constant";
import LocalStorageService from "service/localStorageService";
import { DefaultPermissions } from "components/organizations/PermissionList";

export interface AppState {
    dashboard: {
        dashboardList: any[];
        currentDashboard: any;
        panels: any[],
        currentSelectedTab: "All" | "Gateways" | "Sensors" | "Actuators" | "Others",
    },
    organization: {
        orgList: OrganisationDto[],
        currentOrgId: string,
        currentOrgInfo: OrganisationDto,
        currentOrgMembers: MemberDto[],
        currentOrgGroups: MemberGroupDto[],
        currentOrgRoles: RoleDto[],
        currentUserIsOwner: boolean,
        currentActiveTab: EventKey,
        isUserOrgLoaded: boolean,
    },
    user: {
        userData: UserDto,
        isLoaded: boolean,
    },
    subscription: {
        subList: any[],
        tokenBalance: number,
        isSubscribed: boolean,
        hasSubscribed: boolean,
        isLoaded: boolean,
        currentSubscriptionId: string,
    },
    systemPermissions: {
        loaded: boolean,
        permissions: OrganisationPermissions,
    },
    globalModal: {
        isShow: boolean,
        mainMessage: string,
        subMessage: any[],
        redirectTo: string,
    },
}

const DefaultAppState: AppState = {
    dashboard: {
        dashboardList: [],
        currentDashboard: {},
        panels: [],
        currentSelectedTab: "All",
    },
    organization: {
        orgList: LocalStorageService.getItem("orgIdList") || [],
        currentOrgId: LocalStorageService.getItem("lastLoggedOrgId") || "",
        currentOrgInfo: {
            isDefault: false,
            owner: "",
            name: "",
            ownerName: [],
            uuid: "",
            createTime: new Date(),
            members: [],
            groups: [],
            roles: []
        },
        currentOrgMembers: [],
        currentOrgGroups: [],
        currentOrgRoles: [],
        currentUserIsOwner: false,
        currentActiveTab: EventKey.members,
        isUserOrgLoaded: false,
    },
    user: {
        userData: {
            emailVerified: false,
            email: "",
            familyName: "",
            givenName: ""
        },
        isLoaded: false,
    },
    subscription: {
        subList: [],
        tokenBalance: 0,
        isSubscribed: false,
        hasSubscribed: false,
        isLoaded: false,
        currentSubscriptionId: "",
    },
    systemPermissions: {
        loaded: false,
        permissions: DefaultPermissions,
    },
    globalModal: {
        isShow: false,
        mainMessage: "",
        subMessage: [],
        redirectTo: "",
    },
};

export default DefaultAppState;
