import { CheckoutType } from "constant";

import "assets/css/subscription.css";

type PurchasePreviewT = {
    checkoutType: string;
    customer: {
        billing_cycles: number;
        id: string;
        prn: string;
        uuid: string;
        verified: boolean;
    };
    children: any;
};

const PurchasePreview: any = ({
    checkoutType,
    customer,
    children,
}: PurchasePreviewT) => {
    return (
        (checkoutType === CheckoutType.ADDON ||
            (checkoutType === CheckoutType.PLAN && customer.verified)) && (
            <div className="form-box">
                <h5 className="bill-title" aria-label="preview-title">
                    {checkoutType === CheckoutType.PLAN
                        ? "Your Subscription"
                        : "You are buying"}
                </h5>
                {children}
            </div>
        )
    );
};

export default PurchasePreview;
