export enum SubscriptionStatus {
    ACTIVE = "ACTIVE",
    CANCELED = "CANCELED",
    EXPIRED = "EXPIRED",
    PENDING = "PENDING",
    CREATED = "CREATED",
    PAST_DUE = "PAST_DUE",
}

export enum BraintreeId {
    MONTHLY_PLAN = "monthly_plan_addon",
    TOKEN_ADDON = "token_1m",
}

export enum FESubscriptionStatus {
    SUBSCRIBED = "subscribed",
    RESUBSCRIBED = "resubscribed",
    PENDING_CANCEL = "pendingCancel",
    CANCELLATION_PERIOD_ENDED = "cancellationPeriodEnded",
    EXPIRED = "expired",
    UNSUBSCRIBED = "unsubscribed",
}

export enum TokenType {
    ALLOCATED = "ALLOCATED",
    BONUS = "BONUS",
    PURCHASED = "PURCHASED",
}

export enum PlanType {
    SUBSCRIPTION = "SUBSCRIPTION",
}

export enum EventKey {
    members = "members",
    groups = "groups",
    roles = "roles",
}

export enum LDSUTabPanel {
    LDSU = "ldsus",
    Sensor = "sensors",
    Actuator = "actuators",
}

export enum AccessTokenScopeOptions {
    SENSOR = "SENSOR",
    ACTUATOR = "ACTUATOR",
}

export enum SensorChartTypeEnum {
    single,
    multiple,
}

export enum MultipleSensorChartType {
    same = "Same type",
    different = "Two different types",
}

export enum FormType {
    CREATE,
    UPDATE,
    DEFAULT,
}

export enum TypeOption {
    Boolean = "BOOLEAN",
    Integer = "INTEGER",
    Float = "FLOAT",
    Counter = "COUNTER",
    Timer = "TIMER",
}

export enum SensorDataType {
    Boolean = "boolean",
    Integer = "integer",
    Float = "float",
}

export enum Direction {
    Up = 1,
    Down = 0,
}
