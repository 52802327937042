import {
    orgId,
    createErrorResponseStandard,
    createResponseStandard,
} from "utils/functions";
import AxiosInstance from "./axiosInstance";
import { RequestParams, apiFactory } from "generated";
import { AxiosError } from "axios";
import {
    DashboardPatch,
    DashboardPost,
    PanelV2Post,
    GetPanelsByDashboardIdParamsSource,
    Dashboard,
    PanelOrderPatch,
} from "generated/models";

type PanelSource = GetPanelsByDashboardIdParamsSource;

export class DashboardService {
    private dashboardClient = apiFactory.getDashboardsInstance(AxiosInstance);

    addNewDashBoard = async (name: string, iconColor: string) => {
        const body: DashboardPost = {
            name: name,
            icon_color: iconColor,
            organization_id: orgId(),
        };

        try {
            const response = await this.dashboardClient.createDashboard(body);
            return createResponseStandard<Dashboard>(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };

    getDashboards = async (organizationId: string) => {
        try {
            const response = await this.dashboardClient.getDashboards({
                organization_id: organizationId,
            });
            return createResponseStandard<Dashboard[]>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    };

    getSingleDashboard = async (dashboardId: string) => {
        try {
            const response = await this.dashboardClient.getDashboardById(
                dashboardId
            );
            return createResponseStandard<Dashboard>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    };

    deleteDashboard = async (dashboardId: string) => {
        try {
            const response = await this.dashboardClient.deleteDashboardById(
                dashboardId
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    };

    updateDashboardDetails = async (
        dashboardId: string,
        iconColor: string,
        name: string
    ) => {
        const body: DashboardPatch = {
            icon_color: iconColor,
            name: name,
        };

        try {
            const response = await this.dashboardClient.updateDashboardById(
                dashboardId,
                body
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    };

    createChartPanel = async (dashboardId: string, body: PanelV2Post) => {
        try {
            const response =
                await this.dashboardClient.createPanelByDashboardId(
                    dashboardId,
                    body
                );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    };

    getPanelDashboard = async (
        dashboardId: string,
        query: {
            offset?: number | undefined;
            limit?: number | undefined;
            source?: PanelSource;
        },
        params?: RequestParams
    ) => {
        try {
            const response = await this.dashboardClient.getPanelsByDashboardId(
                dashboardId,
                query,
                params
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    };

    getSinglePanel = async (dashboard_uuid: string, panel_uuid: string) => {
        try {
            const response = await this.dashboardClient.getPanelById(
                dashboard_uuid,
                panel_uuid
            );
            return createResponseStandard(response);
        } catch (err) {
            return createErrorResponseStandard(err);
        }
    };

    updatePanel = async (
        dashboard_uuid: string,
        panel_uuid: string,
        body: any
    ) => {
        try {
            const response = await this.dashboardClient.updatePanelById(
                dashboard_uuid,
                panel_uuid,
                body
            );

            return createResponseStandard(response);
        } catch (err) {
            return createErrorResponseStandard(err);
        }
    };

    deletePanel = async (dashboardId: string, panelId: string) => {
        try {
            const response = await this.dashboardClient.deletePanelById(
                dashboardId,
                panelId
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    };

    updateOrderPanel = async (
        dashboard_uuid: string,
        data: PanelOrderPatch
    ) => {
        try {
            const response = await this.dashboardClient.updatePanelOrderById(
                dashboard_uuid,
                data
            );
            return createResponseStandard(response);
        } catch (err) {
            return createErrorResponseStandard(err);
        }
    };
}

const dashboardService = new DashboardService();

export default dashboardService;
