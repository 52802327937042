import moment from "moment";
import { Dropdown } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CHART_CUSTOM_RANGE_OPTION, LastTimeSelection } from "constant";
import {
    convertPrometheusTimeToSeconds,
    convertSecondsToPrometheusTime,
    isHttpSuccess,
} from "utils/functions";
import { isEmpty } from "lodash";
import dashboardService from "service/dashboardService";
import { getAPIError, showErrorAlert } from "utils/alert";

const ChartTimeSelectionDropdown = ({
    lastTimeRangeSelect,
    getStartDateTime,
    getEndDateTime,
    setLastTimeRangeSelect,
    dashboardId,
    panelId,
    getTimeGapObject,
    getUpdatedChartConfig,
}: any) => {
    const getSelectedLabel = () => {
        if (lastTimeRangeSelect.query_time) {
            return CHART_CUSTOM_RANGE_OPTION;
        } else {
            const lastTimeOption: any = LastTimeSelection.find(
                ({ timeGapToNow }) =>
                    timeGapToNow ===
                    convertPrometheusTimeToSeconds(
                        lastTimeRangeSelect.time_range
                    )
            );
            return lastTimeOption?.time;
        }
    };

    const applySelectedTimeRange = async (event: any, picker: any) => {
        const { chosenLabel, endDate, startDate } = picker;
        const endDateTimestamp = endDate.unix();

        const timeValue = Math.round(
            moment.duration(endDate.diff(startDate)).asSeconds()
        );

        const panelRes = await dashboardService.updatePanel(
            dashboardId,
            panelId,
            {
                time_range: convertSecondsToPrometheusTime(timeValue),

                query_time:
                    chosenLabel === CHART_CUSTOM_RANGE_OPTION
                        ? endDateTimestamp
                        : 0,
            }
        );
        if (!isHttpSuccess(panelRes?.status)) {
            showErrorAlert(
                getAPIError(
                    panelRes,
                    "Something went wrong when changing the time range. Please try again."
                )
            );
            return;
        }

        const timeGapObj = getTimeGapObject(timeValue);

        const newLastTimeRangeSelect: any = {
            ...lastTimeRangeSelect,
        };

        // This function helps to add in INTVL, showAggregated, showCandlestickChart
        if (!isEmpty(timeGapObj)) {
            const _newLastTimeRangeSelect = await getUpdatedChartConfig(
                timeValue
            );

            Object.keys(_newLastTimeRangeSelect).forEach((k) => {
                newLastTimeRangeSelect[k] = _newLastTimeRangeSelect[k];
            });
        }

        setLastTimeRangeSelect({
            ...newLastTimeRangeSelect,
            time_range: convertSecondsToPrometheusTime(timeValue),
            query_time:
                chosenLabel === CHART_CUSTOM_RANGE_OPTION
                    ? endDateTimestamp
                    : 0,
        });
    };

    return (
        <Dropdown alignRight>
            <DateRangePicker
                initialSettings={{
                    timePicker: true,
                    startDate: getStartDateTime(),
                    endDate: getEndDateTime(),
                    maxDate: moment(),
                    locale: {
                        format: "DD/MM/YYYY hh:mm A",
                    },
                    maxSpan: {
                        years: 3,
                    },
                    ranges: LastTimeSelection.reduce<any>(
                        (acc, timeSelection) => {
                            const { time, timeGapToNow } = timeSelection;
                            acc[`${time}`] = [
                                moment().subtract(timeGapToNow, "seconds"),
                                moment(),
                            ];
                            return acc;
                        },
                        {}
                    ),
                }}
                onApply={applySelectedTimeRange}
            >
                <Dropdown.Toggle variant="" className="last-data">
                    {getSelectedLabel()}
                </Dropdown.Toggle>
            </DateRangePicker>
        </Dropdown>
    );
};

export default ChartTimeSelectionDropdown;
