import { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    InputGroup,
    Button,
} from "react-bootstrap";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DateAdapter from "@mui/lab/AdapterMoment";
import TextField from "@mui/material/TextField";

import EventService from "service/eventService";

import DefaultModal from "components/modals/ModalTemplate";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { TimeScheduleTemplate } from "components/events/ConditionDetail/TimeScheduleTemplate";
import {
    TimeSchedule,
    ERROR_NOT_INTEGER,
    ERROR_INVALID,
    ERROR_NO_INTERVAL,
    ERROR_INVALID_DATE,
    ERROR_NO_DURATION,
    ERROR_EMPTY,
    ERROR_INCORRECT_VALUE,
    HttpStatus,
    ERROR_INVALID_BEFORE_AFTER_DATE,
    START_OF_DAY_AT_FIRST_MINUTE,
    ERROR_BEFORE_OR_AFTER,
} from "constant";

import "assets/css/event.css";
import { ConditionPatch } from "generated/models";

const OnDate = () => {
    const params: any = useParams();
    const history: any = useHistory();
    const location: any = useLocation();
    const selectedOperator = location?.state?.selectedOperator;
    const slotCount = location?.state?.slotCount;
    const selectedSlotIndex = location?.state?.selectedSlotIndex;

    const [date, setDate] = useState<any>(moment().startOf("day"));
    const [invalidBeforeAfterDateMsg, setInvalidBeforeAfterDateMsg] =
        useState("");

    const [selectedTimeOption, setSelectedTimeOption] = useState({
        default: TimeSchedule.ALL_DAY,
    });
    const [priTime, setPriTime] = useState<any>({
        default: START_OF_DAY_AT_FIRST_MINUTE,
    });

    const [secTime, setSecTime] = useState<any>({
        default: START_OF_DAY_AT_FIRST_MINUTE,
    });

    const [selectedInterval, setSelectedInterval] = useState({
        default: "hours",
    });

    const [duration, setDuration] = useState({
        default: "",
    });
    const [timeList, setTimeList]: any = useState({
        default: [],
    });
    const [timeRangeList, setTimeRangeList] = useState({
        default: [],
    });

    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [errorArray, setErrorArray]: any = useState([]);
    const [errorType, setErrorType] = useState("");
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const [currentDateDetails, setCurrentDateDetails]: any = useState({});
    const [isPageLoading, updateIsPageLoading] = useState(true);

    useEffect(() => {
        if (params.conditionId) {
            const fetchCondition = async () => {
                const response: any = await EventService.readOneCondition(
                    params.conditionId,
                    params.eventId
                );

                if (response.status === HttpStatus.OK) {
                    const {
                        resource: {
                            date1: {
                                day,
                                time_: { schedule, interval, points, ranges },
                            },
                        },
                    } = response.data;
                    setCurrentDateDetails(response.data);
                    setSelectedTimeOption({
                        default: schedule,
                    });

                    setDate(moment(day, "YYYY-MM-DD"));

                    switch (schedule) {
                        case TimeSchedule.BEFORE:
                            setPriTime({
                                default: moment(ranges[0][1], "HH:mm:ss"),
                            });
                            break;
                        case TimeSchedule.AFTER:
                            setPriTime({
                                default: moment(ranges[0][0], "HH:mm:ss"),
                            });
                            break;
                        case TimeSchedule.BEFORE_OR_AFTER:
                            setPriTime({
                                default: moment(ranges[0][1], "HH:mm:ss"),
                            });
                            setSecTime({
                                default: moment(ranges[1][0], "HH:mm:ss"),
                            });
                            break;
                        case TimeSchedule.EVERY:
                            setDuration({
                                default: interval,
                            });

                            break;
                        case TimeSchedule.AT:
                            setTimeList({
                                default: points,
                            });
                            break;
                        case TimeSchedule.BETWEEN:
                            setTimeRangeList({
                                default: ranges,
                            });
                            break;
                    }

                    updateIsPageLoading(false);
                }
            };
            fetchCondition();
        } else {
            updateIsPageLoading(false);
        }
    }, [params.conditionId, params.eventId]);

    // PROPS TO PASS TO CHILDREN
    const updatePriTime = (priTime: any) => {
        setPriTime(priTime);
    };

    const updateSecTime = (secTime: any) => {
        setSecTime(secTime);
    };

    const updateSelectedTimeOption = (selectedTimeOption: any) => {
        setError({});
        setSelectedTimeOption(selectedTimeOption);
    };

    const updateDuration = (duration: any) => {
        setDuration(duration);
    };

    const updateSelectedInterval = (interval: any) => {
        setSelectedInterval(interval);
    };

    const updateTimeList = (timeList: any) => {
        setTimeList(timeList);
    };

    const updateTimeRangeList = (timeRangeList: any) => {
        setTimeRangeList(timeRangeList);
    };

    const updateErrorType = (errorType: any) => {
        setErrorType(errorType);
    };

    const [error, setError]: any = useState({
        component: "",
        errorType: "",
        day: "",
    });

    const renderErrorMsg = (
        component: any,
        field: any,
        day?: any,
        month?: any
    ) => {
        if (component === error.component) {
            switch (field) {
                case "repeat":
                    switch (error.errorType) {
                        case ERROR_NO_INTERVAL:
                            return (
                                <Col sm="12" className="mt-2">
                                    <p className="text-danger">
                                        Please enter an interval.
                                    </p>
                                </Col>
                            );
                        case ERROR_NOT_INTEGER:
                            return (
                                <Col sm="12" className="mt-2">
                                    <p className="text-danger">
                                        Please enter a whole number.
                                    </p>
                                </Col>
                            );
                    }
                    break;
                case "range":
                    switch (error.errorType) {
                        case ERROR_INVALID_DATE:
                            return (
                                <Col sm="12" className="mt-2">
                                    <p className="text-danger">
                                        Invalid date format.
                                    </p>
                                </Col>
                            );
                    }
                    break;

                case "timeSchedule":
                    if (error.day === day) {
                        switch (error.errorType) {
                            case ERROR_NO_DURATION:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            {ERROR_NO_DURATION}
                                        </p>
                                    </Col>
                                );

                            case ERROR_INVALID:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            Invalid time format.
                                        </p>
                                    </Col>
                                );
                            case ERROR_EMPTY:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            Please set a time schedule.
                                        </p>
                                    </Col>
                                );

                            case ERROR_INCORRECT_VALUE:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            The value needs to be at least 5
                                            seconds.
                                        </p>
                                    </Col>
                                );
                            case ERROR_INVALID_BEFORE_AFTER_DATE:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            {invalidBeforeAfterDateMsg}
                                        </p>
                                    </Col>
                                );
                            case ERROR_BEFORE_OR_AFTER:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            {ERROR_BEFORE_OR_AFTER}
                                        </p>
                                    </Col>
                                );
                        }
                        break;
                    }
            }
        }
    };

    const validateFormData = () => {
        const isValid = true;
        const errorMsg = "";

        if (!date._isValid)
            return { isValid: false, errorMsg: ERROR_INVALID_DATE };

        if (
            selectedTimeOption.default === TimeSchedule.EVERY &&
            !duration.default
        )
            return { isValid: false, errorMsg: ERROR_NO_DURATION };

        if (
            selectedTimeOption.default === TimeSchedule.EVERY &&
            !Number.isInteger(Number(duration.default))
        )
            return { isValid: false, errorMsg: ERROR_NOT_INTEGER };

        if (
            selectedTimeOption.default === TimeSchedule.EVERY &&
            selectedInterval.default === "seconds" &&
            Number(duration.default) < 5
        )
            return {
                isValid: false,
                errorMsg: ERROR_INCORRECT_VALUE,
            };

        if (
            (selectedTimeOption.default === TimeSchedule.AT &&
                timeList.default.length < 1) ||
            (selectedTimeOption.default === TimeSchedule.BETWEEN &&
                timeRangeList.default.length < 1)
        )
            return {
                isValid: false,
                errorMsg: ERROR_EMPTY,
            };

        return { isValid, errorMsg };
    };

    const handleAddUpdateCondition = async () => {
        let response: any;
        setInvalidBeforeAfterDateMsg("");

        const { isValid, errorMsg } = validateFormData();
        if (!isValid) {
            setError({
                component: "onDate",
                errorType: errorMsg,
                day: "default",
            });
            return;
        }

        if (
            (selectedTimeOption.default === TimeSchedule.BEFORE ||
                selectedTimeOption.default === TimeSchedule.AFTER ||
                selectedTimeOption.default === TimeSchedule.BEFORE_OR_AFTER) &&
            (!moment(priTime.default.format("HH:mm:ss"), "HH:mm:ss").isAfter(
                moment().startOf("day")
            ) ||
                !moment(secTime.default.format("HH:mm:ss"), "HH:mm:ss").isAfter(
                    moment().startOf("day")
                ))
        ) {
            if (selectedTimeOption.default === TimeSchedule.BEFORE) {
                setInvalidBeforeAfterDateMsg(
                    "The time entered for 'Before' must be later than 12:00 AM"
                );
            }

            if (selectedTimeOption.default === TimeSchedule.AFTER) {
                setInvalidBeforeAfterDateMsg(
                    "The time entered for 'After' must be later than 12:00 AM"
                );
            }

            if (selectedTimeOption.default === TimeSchedule.BEFORE_OR_AFTER) {
                setInvalidBeforeAfterDateMsg(
                    "The time entered for 'Before' and 'After' must be later than 12:00 AM"
                );
            }

            setError({
                component: "onDate",
                errorType: ERROR_INVALID_BEFORE_AFTER_DATE,
                day: "default",
            });
            return;
        }

        if (
            (selectedTimeOption.default === TimeSchedule.BEFORE ||
                selectedTimeOption.default === TimeSchedule.AFTER ||
                selectedTimeOption.default === TimeSchedule.BEFORE_OR_AFTER) &&
            (!moment(
                priTime.default.format("HH:mm:ss"),
                "HH:mm:ss"
            ).isValid() ||
                !moment(
                    secTime.default.format("HH:mm:ss"),
                    "HH:mm:ss"
                ).isValid())
        ) {
            setError({
                component: "onDate",
                errorType: ERROR_INVALID,
                day: "default",
            });
            return;
        }

        if (
            selectedTimeOption.default === TimeSchedule.BEFORE_OR_AFTER &&
            priTime.default.isSameOrAfter(secTime.default, "second")
        ) {
            setError({
                component: "onDate",
                errorType: ERROR_BEFORE_OR_AFTER,
                day: "default",
            });
            return;
        }

        let slot = 0;
        if (selectedOperator === "AND") {
            slot = selectedSlotIndex;
        } else if (selectedOperator === "OR") {
            slot = slotCount;
        }

        if (!params.conditionId) {
            const body: any = {
                slot: slot,
                type_: `DATETIME`,
                resource: {

                    date1: {
                        day: moment(date).format(`YYYY-MM-DD`),
                        time_: EventService.getEventTimeRange(
                            selectedTimeOption.default,
                            priTime.default,
                            secTime.default,
                            timeList.default,
                            timeRangeList.default,
                            duration.default
                        ),
                    },
                },
            };

            response = await EventService.createCondition(params.eventId, body);
        } else {
            const body: ConditionPatch = {
                slot: currentDateDetails.slot,
                type_: `DATETIME`,
                resource: {
                    date1: {
                        day: moment(date).format(`YYYY-MM-DD`),
                        time_: EventService.getEventTimeRange(
                            selectedTimeOption.default,
                            priTime.default,
                            secTime.default,
                            timeList.default,
                            timeRangeList.default,
                            duration.default
                        ),
                    },
                },
            };

            response = await EventService.updateCondition(
                params.eventId,
                params.conditionId,
                body
            );
        }

        if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 204
        ) {
            closeCondition();
        } else {
            if (response.data?.errors) {
                const errors: any = [];
                for (const err of response?.data?.errors || []) {
                    !errors.includes(...err.value) &&
                        errors.push(`${[...err.value]}`);
                }
                setModalShow(true);
                setModalType("err");
                setErrorArray(errors);
            } else {
                setSimpleModalData({
                    resObj: response,
                } as SimpleModalDataType);
            }
        }
    };

    const closeCondition = () => {
        history.push(`/event-details/${params.eventId}`);
    };

    const renderDate = () => {
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                    label="Date desktop"
                    inputFormat="DD/MM/yyyy"
                    value={date}
                    onChange={(newValue) => {
                        setDate(newValue);
                    }}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label=""
                            InputLabelProps={{
                                shrink: false,
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        );
    };
    return (
        <>
            <ContentWrapper
                isLoading={isPageLoading}
                simpleModalData={simpleModalData}
            >
                <Container>
                    <Row>
                        <Col sm="12" className="event-detail-head">
                            <h5 className="page-title overflow-text">
                                On a Date
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item>
                                    <Link to="/events">Events</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={`/event-details/${params.eventId}`}
                                    >
                                        Event Details
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Condition
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row className="action-detail-box">
                        <Col>
                            <div className="form-box mb-3">
                                <h5 className="mb-4">On</h5>

                                <InputGroup>{renderDate()}</InputGroup>
                                {renderErrorMsg("onDate", "range", "default")}
                            </div>
                            <div className="form-box mb-3">
                                <h5 className="mb-4">Time Schedule</h5>
                                <TimeScheduleTemplate
                                    priTime={priTime}
                                    setPriTime={updatePriTime}
                                    secTime={secTime}
                                    setSecTime={updateSecTime}
                                    selectedTimeOption={selectedTimeOption}
                                    setSelectedTimeOption={
                                        updateSelectedTimeOption
                                    }
                                    selectedInterval={selectedInterval}
                                    setSelectedInterval={updateSelectedInterval}
                                    duration={duration}
                                    setDuration={updateDuration}
                                    timeList={timeList}
                                    setTimeList={updateTimeList}
                                    timeRangeList={timeRangeList}
                                    setTimeRangeList={updateTimeRangeList}
                                    errorType={errorType}
                                    setErrorType={updateErrorType}
                                    day={"default"}
                                    renderErrorMsg={renderErrorMsg}
                                    recurrence={"onDate"}
                                />
                            </div>
                            <Button
                                variant="secondary"
                                className="mr-2"
                                onClick={closeCondition}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="pl-4 pr-4"
                                onClick={handleAddUpdateCondition}
                            >
                                {params.conditionId ? "UPDATE" : "ADD"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <DefaultModal
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalType={modalType}
                    okAction={() => {
                        setModalShow(false);
                    }}
                    errorArray={errorArray}
                />
            </ContentWrapper>
        </>
    );
};
export default OnDate;
