/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import { GetInvitationsData, InvitationPost, ResponseInvitationByUuidData } from "../models";

export class Invitation<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags invitation
   * @name GetInvitations
   * @summary Get all invitations
   * @request GET:/invitation
   * @secure
   */
  getInvitations = (params: RequestParams = {}) =>
    this.http.request<GetInvitationsData, void>({
      path: `/invitation`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags invitation
   * @name ResponseInvitationByUuid
   * @summary Response to an invitation by uuid
   * @request POST:/invitation/{member_uuid}
   * @secure
   */
  responseInvitationByUuid = (memberUuid: string, data: InvitationPost, params: RequestParams = {}) =>
    this.http.request<ResponseInvitationByUuidData, void>({
      path: `/invitation/${memberUuid}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
