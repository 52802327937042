/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "../http-client";
import { GetPaymentTokenData, GetWalletData, GetWalletStatusByOrganizationIdData } from "../models";

export class Wallet<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags wallet
   * @name GetWallet
   * @summary Get wallet info
   * @request GET:/wallet
   * @secure
   */
  getWallet = (params: RequestParams = {}) =>
    this.http.request<GetWalletData, void>({
      path: `/wallet`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags wallet
   * @name GetPaymentToken
   * @summary Get total amount of payment token
   * @request GET:/wallet/token
   * @secure
   */
  getPaymentToken = (params: RequestParams = {}) =>
    this.http.request<GetPaymentTokenData, void>({
      path: `/wallet/token`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags wallet
   * @name GetWalletStatusByOrganizationId
   * @summary For member to get the owner's wallet status of the specified organization.
   * @request GET:/wallet/status
   * @secure
   */
  getWalletStatusByOrganizationId = (
    query: {
      /** Organization ID */
      organization_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetWalletStatusByOrganizationIdData, void>({
      path: `/wallet/status`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
}
