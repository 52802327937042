import { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

import "./style.css";
import { RoleDto } from "types";

const defaultRoleIds: Array<string> = [];
const refInitData: any = null;
type SelectRoleModalProps = {
    roles: RoleDto[];
    show: boolean;
    values: string[];
    onClose: (values?: string[]) => void;
    canRemoveSelected?: boolean;
};
const SelectRoleModal = (props: SelectRoleModalProps) => {
    const { roles, values, show, onClose, canRemoveSelected } = props;
    const [isShowModal, updateModal] = useState(show);
    const [selectedRoleIds, updateSelectedRoleIds] = useState(defaultRoleIds);
    const [searchResults, updateSearchResults] = useState<RoleDto[]>(roles);
    const [searchStr, updateSearchStr] = useState("");
    const searchRef = useRef(refInitData);

    useEffect(() => {
        updateModal(show);
        updateSearchResults(roles);
        updateSelectedRoleIds(values);
        updateSearchStr("");
    }, [show, roles, values]);

    const handleSelectRole = (e: any, roleId: string, currentRole: any) => {
        const { checked } = e.target;
        const ids = checked
            ? [...selectedRoleIds, roleId]
            : selectedRoleIds.filter((id: string) => id !== roleId);
        updateSelectedRoleIds(ids);
    };

    const handleCloseModal = (save = false) => {
        let ids = [...selectedRoleIds];
        if (!canRemoveSelected)
            ids = selectedRoleIds.filter((id: string) => !values.includes(id));
        updateModal(false);
        onClose(save ? ids : undefined);
    };

    const handleSearchRole = (e: any) => {
        const { value } = e.target;
        const _value = value.toLowerCase().replace(/\s{2,}/, ""); // Trim multi spaces

        updateSearchStr(value);
        if (_value === "") {
            updateSearchResults(roles);
            return;
        }

        const results = roles.filter((role: any) =>
            role.name.toLocaleLowerCase().includes(_value)
        );
        updateSearchResults(results);
    };

    const handleClearSearch = () => {
        updateSearchResults(roles);
        updateSearchStr("");
        searchRef.current.value = "";
    };

    return (
        <Modal
            show={isShowModal}
            onHide={() => handleCloseModal()}
            centered
            className="modal-add-roles"
        >
            <Modal.Header>
                <Modal.Title>Select Roles</Modal.Title>
                <Button
                    variant=""
                    className="close-button"
                    onClick={() => handleCloseModal()}
                >
                    <span className="material-icons">close</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="search-role">
                    <Form.Control
                        type="text"
                        required
                        aria-label="search-role"
                        placeholder="Search"
                        onChange={handleSearchRole}
                        ref={searchRef}
                    />
                    {searchStr && (
                        <Button
                            variant=""
                            className="clear-search"
                            onClick={handleClearSearch}
                        >
                            <span className="material-icons">close</span>
                        </Button>
                    )}
                </Form.Group>
                <ListGroup>
                    {[...searchResults].map((role) => (
                        <ListGroup.Item key={role.uuid}>
                            <Form.Check
                                type="checkbox"
                                id={`role-${role.uuid}`}
                                value={role.uuid}
                                label={role.name}
                                custom
                                disabled={
                                    canRemoveSelected || role.isDefault
                                        ? role.isDefault
                                        : values.includes(role.uuid)
                                }
                                checked={
                                    role.isDefault ||
                                    selectedRoleIds.includes(role.uuid)
                                }
                                onChange={(e) =>
                                    handleSelectRole(e, role.uuid, role)
                                }
                            />
                            <span className="role-type text-center">
                                {role.isDefault
                                    ? "Default Role"
                                    : "Custom Role"}
                            </span>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    className="btn-add"
                    onClick={() => handleCloseModal(true)}
                >
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectRoleModal;
