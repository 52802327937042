import { has, get, startCase } from "lodash";
import { memo } from "react";
import { LEVEL_MODE, UnknownActuator } from "constant";
import BasicSensorChart from "./BasicSensorChart";
import styledConst from "styles";
import { getDisplayedActuatorMode } from "pages/events/EventDetails";
import { CustomEChartParams } from "types/Charts";

export const getMode = ({ sensorConfigRes, sensorType = "" }: any) => {
    if (!has(sensorConfigRes, "CMD.mode")) return "Unknown";
    // Added for backward compatibility: Generic Analog Output created by gateway using old config may still be "LEVEL" mode instead of "ANALOG"
    if (sensorType.toLowerCase() === "generic analog output") return "-";
    const configMode = getDisplayedActuatorMode(
        get(sensorConfigRes, "CMD.mode", LEVEL_MODE)
    );

    return configMode || "-";
};

function ActuatorChart(props: any) {
    return (
        <BasicSensorChart
            {...{
                ...props,
                customEChartData: ({
                    liveData,
                    chartState,
                    liveTime,
                    liveDate,
                    sensorUnit,
                    sensorType,
                }: CustomEChartParams) => {
                    const sensorConfigRes = chartState?.sensorConfigRes || {};
                    return [
                        {
                            path: "title.subtext",
                            value: `{s6|${startCase(
                                sensorType
                            )}}\n{s3|Mode: }{s5|${getMode({
                                sensorConfigRes,
                                sensorType,
                            })}}\n{s2|${
                                (isNaN(liveData) || liveData === "NaN"
                                    ? "-"
                                    : liveData) + (sensorUnit || "")
                            }}{s1|    ${liveDate + " " + liveTime}}`,
                        },
                    ];
                },
                customEChartStyle: (chartColor: string) => {
                    return [
                        {
                            path: "title.subtextStyle.rich",
                            value: {
                                s1: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    height: 25,
                                },
                                s2: {
                                    color: chartColor,
                                    fontSize: 22,
                                    fontWeight: "bold",
                                },
                                s3: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 600,
                                    fontSize: 14,
                                    height: 25,
                                },
                                s4: {
                                    fontSize: 6,
                                },
                                s5: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    height: 25,
                                },
                                s6: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    height: 25,
                                },
                            },
                        },
                    ];
                },
                displayName: (chartState) => {
                    return get(
                        chartState,
                        "sensorConfigRes.sensor_name",
                        UnknownActuator
                    );
                },
                onDelete: props.handleOnChangeDashboardUUID,
            }}
        />
    );
}

export default memo(ActuatorChart);
