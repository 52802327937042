import PermissionService from "service/permissionService";
import localStorageService from "service/localStorageService";
import { OrganisationPermissions } from "types";

type AffectProps = "hide" | "show" | "lock" | "grayscale";

const PermissionMap: Record<string, string[]> = {
    Editor: ["create", "update", "delete", "read"],
    Viewer: ["read"],
    Null: [],
};

declare global {
    interface Window {
        authorizationConfig: OrganisationPermissions;
        getAuthorizationConfig: () => OrganisationPermissions;
        loadAuthorizationConfig: () => Promise<void>;
        setAuthorizationConfig: (config: OrganisationPermissions) => void;
    }

    interface Element {
        authorizationAffect: (affect: AffectProps) => void;
    }
}

window.getAuthorizationConfig = () => {
    if (!window.authorizationConfig) {
        window.authorizationConfig = localStorageService.getItem("authConfig");
    }

    return window.authorizationConfig;
};

window.setAuthorizationConfig = (config: OrganisationPermissions) => {
    window.authorizationConfig = config;
    localStorage.setItem("authConfig", JSON.stringify(config));
};

window.loadAuthorizationConfig = async () => {
    const orgId = localStorageService.getItem("orgId");
    if (orgId) {
        const res = await PermissionService.getDefaultPermissions(orgId);
        if (res.data) {
            window.setAuthorizationConfig(res.data);
        }
    }
};

if (!window.authorizationConfig) {
    window.loadAuthorizationConfig();
    // Add Style
    const style = document.createElement("style");
    style.innerHTML = `
    [data-can] {
        pointer-events: none !important;
        -webkit-mask:linear-gradient(-90deg ,#000 20%,#0005,#000 80%) right/300% 100% !important;
        background-repeat: no-repeat !important;
        animation: shimmer 2.5s infinite !important;
    }


    [data-display="show"] {
        pointer-events: unset !important;
        -webkit-mask: none !important;
        opacity: 1 !important;
    }

    [data-display="hide"] {
        opacity: 0 !important;
        -webkit-mask: none !important;

    }

    [data-display="grayscale"] {
        -webkit-mask: none !important;
        opacity: 0.6 !important;
        filter: grayscale(0.6) !important;
    }

    [data-display="grayscale"]  .custom-switch,
    [data-display="grayscale"]  .custom-switch .custom-control-label::before,
    [data-display="grayscale"]  .custom-switch .custom-control-label::after {
         cursor: no-drop;
         pointer-events: none;
    }

    button[data-display="grayscale"] {
        opacity: 0.5 !important;
        filter: none !important;
    }

    [data-display="grayscale"]  .custom-switch,
    [data-display="grayscale"]  .custom-switch .custom-control-label::before,
    [data-display="grayscale"]  .custom-switch .custom-control-label::after {
         cursor: no-drop;
         pointer-events: none;
    }


    [data-display="lock"] {
        opacity: 1 !important;
        position: relative !important;
        filter: grayscale(0.6) !important;
    }

    @keyframes shimmer {
        100% {
            -webkit-mask-position: left;
        }
    }`;
    document.body.appendChild(style);
}

export const canAccess = (permission: string | null) => {
    try {
        if (!permission) {
            return true;
        }

        const authorizationConfig = window.getAuthorizationConfig();

        if (authorizationConfig) {
            const [resource, action] = permission.split(":");
            const resourcePermission =
                authorizationConfig[resource as keyof OrganisationPermissions];
            return PermissionMap[resourcePermission].includes(action);
        }

        return true;
    } catch (err) {
        return true;
    }
};

export const authorizationAction = () => {
    setInterval(() => {
        const authorizationElements = document.querySelectorAll("[data-can]");
        const authorizationConfig = window.getAuthorizationConfig();

        const authorizationElement = () => {
            authorizationElements.forEach((ele) => {
                try {
                    const permission = ele?.getAttribute("data-can");
                    if (canAccess(permission)) {
                        ele.authorizationAffect("show");
                    } else {
                        ele.authorizationAffect("grayscale");
                    }
                } catch (err) {
                    // No action
                }
            });
        };

        if (authorizationElements.length && authorizationConfig) {
            authorizationElement();
        }
    }, 10);
};

Element.prototype.authorizationAffect = function (command) {
    const self = this as HTMLElement;
    self.setAttribute("data-display", command);
};

authorizationAction();

export {};
