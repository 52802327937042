import { get } from "lodash";
import { Row, Col } from "react-bootstrap";

const gatewayDescriptorTerms: any = {
    enable: "Enabled",
};

const formatDisplayedValue = ({
    value,
    custom,
    nullException,
}: {
    value: any;
    custom?: string;
    nullException?: string;
}) => {
    if (!value || value === "NA") return nullException || "-";

    return custom || value;
};

const DescriptorTab = ({ registryDetails }: any) => {
    return (
        <Row className="justify-content-md-center">
            <Col sm={9} className="mt-4 mb-5">
                <Row>
                    <Col lg xs={12} className="pl-5 pr-5 discriptor-box">
                        <div className="descriptor-item">
                            <h6 className="float-left">UUID</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.UUID,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Serial Number</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.SNO,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Ethernet MAC Address</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.PMAC,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">WiFi MAC Address</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.WMAC,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Model Number</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.model,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Product Version</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.PRV,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Firmware Version</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.FWV,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">
                                Sensor Cache Storage Size (KB)
                            </h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.ICAC,
                                })}
                            </h6>
                        </div>
                    </Col>
                    <Col lg xs={12} className="pl-5 pr-5 discriptor-box">
                        <div className="descriptor-item">
                            <h6 className="float-left">Number of LDS Ports</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.IPRT,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">
                                Configuration Storage (KB)
                            </h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.ICFG,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">
                                Maximum LDSUs per Gateway
                            </h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.MLG,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">
                                Machine to Machine Configuration
                            </h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.M2M,
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">GPS Location</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.location,
                                    custom: `${registryDetails.location?.[0]}, ${registryDetails.location?.[1]}`,
                                    nullException: "0, 0",
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Auto-Scan</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.WASC,
                                    custom: get(
                                        gatewayDescriptorTerms,
                                        registryDetails.info?.WASC,
                                        registryDetails.info?.WASC
                                    ),
                                })}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Sensor Cache Status</h6>
                            <h6 className="ginfo float-right">
                                {formatDisplayedValue({
                                    value: registryDetails.info?.WSCS,
                                    custom: get(
                                        gatewayDescriptorTerms,
                                        registryDetails.info?.WSCS,
                                        registryDetails.info?.WSCS
                                    ),
                                })}
                            </h6>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DescriptorTab;
