import { Link } from "react-router-dom";

import { Container, Row, Col, Button } from "react-bootstrap";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useState, useEffect } from "react";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { Box } from "components/common";
import TimezoneDropdown from "components/common/TimezoneDropdown";
import { useAppContext } from "context/appContext";
import AccountService from "service/accountService";
import OrganisationService from "service/organisationService";
import { isHttpSuccess } from "utils/functions";
import {
    changeOrganizationAction,
    storeOrganizationAction,
    storeUserAction,
} from "store/actions";
import TimezoneDescription from "components/settings/TimezoneDescription";
import {
    UPDATE_TIMEZONE_FAIL_MESSAGE,
    UPDATE_TIMEZONE_SUCCESS_MESSAGE,
} from "constant";
import { canAccess } from "utils/authorize-action";

const DefaultTZ = "UTC";

const TimezoneSettings = () => {
    // HOOKS, STATES
    const {
        storeData: {
            user: {
                userData: {
                    timezone: currentTZ = DefaultTZ,
                    givenName,
                    familyName,
                    phoneNumber,
                    ...restAttr
                },
            },
            organization: {
                currentOrgInfo: {
                    uuid: orgId,
                    timeZone = DefaultTZ,
                    isDefault,
                },
                orgList,
            },
        },
        storeDispatchActions,
    } = useAppContext();
    const [originTimezone, setOriginTimezone] = useState("");
    const [timezone, setTimezone] = useState("");
    const [hasPermission, setHasPermission] = useState(false);

    // USE-EFFECT
    useEffect(() => {
        setOriginTimezone(currentTZ);
        setTimezone(currentTZ);

        if (!isDefault) {
            setOriginTimezone(timeZone);
            setTimezone(timeZone);
        }
    }, [currentTZ, timeZone, isDefault]);

    useEffect(() => {
        setHasPermission(canAccess("organisation:update"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.authorizationConfig]);


    const canSave = () => originTimezone !== timezone;

    const handleTimezoneChange = (tz: string) => {
        setTimezone(tz);
    };

    const updateTimeZone = async () => {
        if (!isDefault) {
            return await updateOrgTimezone();
        }

        const res = await AccountService.updateAccountInfo({
            time_zone: timezone,
            first_name: givenName,
            last_name: familyName,
            phone: phoneNumber,
        });

        if (isHttpSuccess(res.status)) {
            showSuccessAlert({
                message: UPDATE_TIMEZONE_SUCCESS_MESSAGE,
            });

            setOriginTimezone(timezone);
            storeDispatchActions(
                storeUserAction({
                    userData: {
                        familyName,
                        givenName,
                        phoneNumber,
                        timezone,
                        ...restAttr,
                    },
                    isLoaded: true,
                })
            );
        } else {
            showErrorAlert(getAPIError(res, UPDATE_TIMEZONE_FAIL_MESSAGE));
        }
    };

    const updateOrgTimezone = async () => {
        const res = await OrganisationService.updateOrganisation(orgId, {
            time_zone: timezone,
        });

        if (isHttpSuccess(res.status)) {
            showSuccessAlert({
                message: "Time zone has been updated successfully.",
            });

            setOriginTimezone(timezone);

            orgList.forEach((org) => {
                if (org.uuid === orgId) {
                    org.timeZone = res.data.time_zone;
                }
            });
            storeDispatchActions(storeOrganizationAction({ orgList }));
            storeDispatchActions(
                changeOrganizationAction({
                    orgInfo: res.data,
                })
            );
        } else {
            showErrorAlert(
                getAPIError(
                    res,
                    "Something went wrong when updating time zone. Please try again."
                )
            );
        }
    };

    return (
        <>
            <ContentWrapper title="Time Zone Settings">
                <div className="page-content gateway-list-page">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <h5 className="page-title">
                                    Time Zone Settings
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Breadcrumb className="w-100">
                                    <Breadcrumb.Item linkAs="span">
                                        <Link to="/settings">Settings</Link>{" "}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Time Zone Settings
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Box>
                            <Row className="d-flex flex-column align-items-start flex-sm-row align-items-sm-center px-2 px-xl-4">
                                <span className="mr-3 mr-xl-5 flex-shrink-1">
                                    Time zone
                                </span>
                                <div className="d-flex flex-column justify-content-between flex-sm-row flex-fill">
                                    <TimezoneDropdown
                                        value={timezone}
                                        onChange={handleTimezoneChange}
                                        className="dark mr-2 flex-grow-1"
                                        disabled={!hasPermission}
                                    />
                                    <div className="mt-2 mt-sm-auto ">
                                        <Button
                                            className="px-xl-4"
                                            disabled={!canSave()}
                                            onClick={updateTimeZone}
                                        >
                                            SAVE
                                        </Button>
                                    </div>
                                </div>
                            </Row>
                        </Box>
                        <div className=" mt-3">
                            <TimezoneDescription />
                        </div>
                    </Container>
                </div>
            </ContentWrapper>
        </>
    );
};

export default TimezoneSettings;
