import {
    ACTION_FAIL_MESSAGE,
    CheckoutType,
    SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE,
    SUBSCRIPTION_DATE_FORMAT,
    SubscriptionDict,
} from "constant";
import { useSubscriptionContext } from "context/subscriptionContext";
import { capitalize, inRange, orderBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
    cancelSubscription,
    getAllPlans,
    updateSubscriptionAddonById,
} from "service/subscriptionService";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { renderPlanStatus } from "utils/subscriptionFunctions";
import { isHttpSuccess } from "utils/functions";
import { BraintreeId, SubscriptionStatus } from "constant/enums";
import DefaultModal, { LoadingModal } from "components/modals/ModalTemplate";
import { Addon } from "generated/models";
import styled from "@emotion/styled";

// NOTE: Updating BE schema now might introduce unexpected issues as of 04/04/24. Wrote a manual type as a temporary workaround
type RevisedAddon = Omit<Addon, "amount" | "content"> & {
    amount: string | number;
    content: {
        token: {
            type_: string;
            amount: string;
        };
    };
};

// ========================= STYLES =========================

const BasicPlanCustomWrapper = styled.div`
    flex: auto;
    display: flex;
    justify-content: end;
    gap: 10rem;
`;

// ========================= DEFINITIONS =========================
const MODAL_SUBSCRIPTION_CONFIRM = "subscriptionConfirm";
const MODAL_CANCELLATION_CONFIRM = "cancellationConfirm";
const MODAL_CANCELLATION_PRECONFIRM = "cancellationPreconfirm";
const MODAL_REMOVE_ADDON_PRECONFIRM = "removeAddonPreconfirm";
const MODAL_SHOW_CONTACT_SUPPORT = "contactSupport";

export enum SubscriptionPlanType {
    MONTHLY = "monthly",
    PREPAID = "prepaid",
    BASIC = "basic",
}

const planDisplayName: any = {
    [SubscriptionPlanType.MONTHLY]: "Monthly Plan",
    [SubscriptionPlanType.PREPAID]: "Prepaid  Plan",
    [SubscriptionPlanType.BASIC]: "Basic Plan",
};

const isLastBillingPeriod = ({ billing_cycles, current_cycle }: any) =>
    billing_cycles - current_cycle === 0;

export const getSubscriptionPlanType = ({
    is_prepaid,
    is_cancellable,
}: {
    is_prepaid: boolean | null;
    is_cancellable: boolean | null;
}) => {
    if (!is_prepaid) return SubscriptionPlanType.MONTHLY;
    if (is_prepaid && !is_cancellable) return SubscriptionPlanType.PREPAID;
    if (is_prepaid && is_cancellable) return SubscriptionPlanType.BASIC;
};

const getEntireSubscriptionPeriod = ({
    create_time,
    billing_cycles,
}: {
    create_time: string;
    billing_cycles: number;
}) => {
    return {
        startDate: moment(create_time).format(SUBSCRIPTION_DATE_FORMAT),
        endDate: moment(create_time)
            .add(billing_cycles, "M")
            .subtract(1, "days")
            .format(SUBSCRIPTION_DATE_FORMAT),
    };
};

const CurrentPlanInformation = () => {
    // ========================= CONTEXT/STATES =========================
    const {
        subscription: {
            plan: {
                addons = [],
                amount: subscriptionAmount = "0",
                current_cycle = 0,
            } = {},
            billing_period_end_date,
            billing_day_of_month,
            next_billing_date,
            uuid,
            status,
            billing_period_start_date,
            create_time,
            customer: {
                is_prepaid = false,
                is_cancellable = true,
                billing_cycles = 0,
            } = {},
        },
        setSubscription,
    }: any = useSubscriptionContext();
    const subscriptionType: any = getSubscriptionPlanType({
        is_prepaid,
        is_cancellable,
    });

    const history = useHistory();
    const [isActionPending, setIsActionPending] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState<any>("");
    const [planUUID, setPlanUUID] = useState("");

    // ========================= USE-EFFECT =========================

    useEffect(() => {
        (async () => {
            const response = await getAllPlans();
            if (isHttpSuccess(response.status)) {
                const planUUID_ = response.data.find(
                    ({ bt_id }: { bt_id: string }) => bt_id === "monthly_plan"
                ).uuid;
                setPlanUUID(planUUID_);
            }
        })();
    }, []);

    // ========================= FUNCTIONS =========================

    const handleCancelSubscription = async () => {
        closeModal();
        setIsActionPending(true);
        try {
            const response = await cancelSubscription(uuid);

            if (isHttpSuccess(response.status)) {
                setIsActionPending(false);
                showSuccessAlert({
                    message: "Subscription cancelled successfully.",
                });
                setSubscription(response.data);
            } else {
                setIsActionPending(false);
                showErrorAlert({
                    message: ACTION_FAIL_MESSAGE,
                });
            }
        } catch (err) {}
    };

    const removePurchasedToken = async () => {
        closeModal();
        setIsActionPending(true);
        const purchasedTokenAddonUUID = addons.find(
            ({ bt_id }: { bt_id: string }) => bt_id === BraintreeId.TOKEN_ADDON
        ).uuid;

        try {
            const response = await updateSubscriptionAddonById({
                subscriptionId: uuid,
                addonId: purchasedTokenAddonUUID,
                action: "remove",
            });
            if (isHttpSuccess(response.status)) {
                showSuccessAlert({ message: `Add-on removed successfully.` });
                setIsActionPending(false);
                setSubscription(response.data);
            } else {
                setIsActionPending(false);
                showErrorAlert(getAPIError(response, ACTION_FAIL_MESSAGE));
            }
        } catch (err) {}
    };

    const handleSubscribeNow = () => {
        history.push("/checkout/billing-address", {
            checkoutType: CheckoutType.PLAN,
            planUUID,
        });
    };

    // ========================= MODAL POPUP =========================

    const showRemovePurchasedTokenConfirmation = (totalAmount: string) => {
        setModalShow(true);
        setModalType(MODAL_REMOVE_ADDON_PRECONFIRM);
        setModalContent(
            `Your Purchased Tokens add-on will be cancelled immediately. Note that by cancelling, you will not be charged (USD ${totalAmount}) and credited additional purchased tokens from next billing date.`
        );
    };

    const showSubscriptionCancellationConfirmation = () => {
        setModalShow(true);
        setModalType(MODAL_CANCELLATION_PRECONFIRM);
        setModalContent("Do you want to cancel subscription?");
    };

    const showContactSupportTeam = () => {
        setModalShow(true);
        setModalType(MODAL_SHOW_CONTACT_SUPPORT);
        setModalContent(
            <div>
                Your current subscription is a prepaid plan. To cancel your
                subscription, please contact our support team at{" "}
                <span className="text-primary-green-1">support@brtsys.com</span>{" "}
                for assistance.
            </div>
        );
    };

    const showSubscribeConfirmation = () => {
        setModalShow(true);
        setModalType(MODAL_SUBSCRIPTION_CONFIRM);
        setModalContent(
            `Your new subscription will begin today, ${moment().format(
                SUBSCRIPTION_DATE_FORMAT
            )}. Cancel anytime. Plan automatically renews until cancelled.`
        );
    };

    const confirmToCancelSubscription = () => {
        setModalShow(true);
        setModalType(MODAL_CANCELLATION_CONFIRM);
        setModalContent(
            subscriptionType === SubscriptionPlanType.BASIC ? (
                <div>
                    Your{" "}
                    <span className="font-weight-bold">
                        IoTPortal Basic Subscription
                    </span>{" "}
                    will be cancelled immediately.
                </div>
            ) : (
                SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE.fill({
                    billing_period_end_date: moment(
                        billing_period_end_date
                    ).format(SUBSCRIPTION_DATE_FORMAT),
                    next_billing_date: moment(next_billing_date)
                        .add(1, "M")
                        .format(SUBSCRIPTION_DATE_FORMAT),
                })
            )
        );
    };

    const closeModal = () => {
        setModalShow(false);
    };

    const getModalIcon = () => {
        if (modalType === MODAL_CANCELLATION_PRECONFIRM) {
            return "warning";
        } else if (
            [
                MODAL_CANCELLATION_CONFIRM,
                MODAL_SUBSCRIPTION_CONFIRM,
                MODAL_REMOVE_ADDON_PRECONFIRM,
            ].includes(modalType)
        ) {
            return "help_outline";
        } else if (modalType === MODAL_SHOW_CONTACT_SUPPORT) {
            return "";
        }
        return "done";
    };

    const getModalTitle = () => {
        if (modalType === MODAL_CANCELLATION_PRECONFIRM) {
            return "Confirmation";
        } else if (modalType === MODAL_SUBSCRIPTION_CONFIRM) {
            return "Confirm Subscription";
        } else if (modalType === MODAL_CANCELLATION_CONFIRM) {
            return "Cancel Subscription?";
        } else if (modalType === MODAL_REMOVE_ADDON_PRECONFIRM) {
            return "Remove Add-on?";
        } else if (modalType === MODAL_SHOW_CONTACT_SUPPORT) {
            return "Subscription Cancellation";
        }
        return "Success";
    };

    const renderModalButton = () => {
        if (modalType === MODAL_SUBSCRIPTION_CONFIRM) {
            return (
                <>
                    <Button variant="secondary" onClick={closeModal}>
                        CANCEL
                    </Button>

                    <Button variant="primary" onClick={handleSubscribeNow}>
                        SUBSCRIBE NOW
                    </Button>
                </>
            );
        } else if (modalType === MODAL_CANCELLATION_PRECONFIRM) {
            return (
                <>
                    <Button variant="secondary" onClick={closeModal}>
                        NO
                    </Button>

                    <Button
                        variant="primary"
                        onClick={confirmToCancelSubscription}
                    >
                        YES
                    </Button>
                </>
            );
        } else if (modalType === MODAL_CANCELLATION_CONFIRM) {
            return (
                <>
                    <Button variant="primary" onClick={closeModal}>
                        KEEP SUBSCRIPTION
                    </Button>

                    <Button variant="danger" onClick={handleCancelSubscription}>
                        CANCEL SUBSCRIPTION
                    </Button>
                </>
            );
        } else if (modalType === MODAL_REMOVE_ADDON_PRECONFIRM) {
            return (
                <>
                    <Button variant="primary" onClick={closeModal}>
                        KEEP ADD-ON
                    </Button>

                    <Button variant="danger" onClick={removePurchasedToken}>
                        CANCEL ADD-ON
                    </Button>
                </>
            );
        } else if (modalType === MODAL_SHOW_CONTACT_SUPPORT) {
            return (
                <Button variant="primary" onClick={closeModal}>
                    OK
                </Button>
            );
        }
    };

    const { startDate, endDate } = getEntireSubscriptionPeriod({
        create_time,
        billing_cycles,
    });

    return (
        <Row className="manage-subscription-info mt-5">
            <Col className="subscr-details" xs={6}>
                <p>Current Plan</p>
                <p className="period">
                    Subscription period: {startDate} - {endDate}
                </p>
            </Col>
            <Col xs={6} className="cancel-subscription">
                <CurrentPlanButton
                    status={status}
                    showSubscriptionCancellationConfirmation={
                        showSubscriptionCancellationConfirmation
                    }
                    showContactSupportTeam={showContactSupportTeam}
                    billing_period_end_date={billing_period_end_date}
                    showSubscribeConfirmation={showSubscribeConfirmation}
                    subscriptionType={subscriptionType}
                    confirmToCancelSubscription={confirmToCancelSubscription}
                />
            </Col>

            <CurrentPlanAddons
                addons={addons}
                showRemovePurchasedTokenConfirmation={
                    showRemovePurchasedTokenConfirmation
                }
                billing_day_of_month={billing_day_of_month}
                status={status}
                billing_period_end_date={billing_period_end_date}
                subscriptionAmount={subscriptionAmount}
                billing_period_start_date={billing_period_start_date}
                subscriptionType={subscriptionType}
                create_time={create_time}
                billing_cycles={billing_cycles}
                current_cycle={current_cycle}
                next_billing_date={next_billing_date}
                isLastBillingPeriod={isLastBillingPeriod}
                endDate={endDate}
            />
            {SubscriptionPlanType.MONTHLY === subscriptionType &&
                [
                    SubscriptionStatus.ACTIVE,
                    SubscriptionStatus.PAST_DUE,
                ].includes(status) &&
                !isLastBillingPeriod({ billing_cycles, current_cycle }) && (
                    <CurrentPlanSummary
                        addons={addons}
                        subscriptionAmount={subscriptionAmount}
                        next_billing_date={next_billing_date}
                    />
                )}

            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalIcon={getModalIcon()}
                modalTitle={getModalTitle()}
                iconVariant={
                    [
                        MODAL_REMOVE_ADDON_PRECONFIRM,
                        MODAL_SUBSCRIPTION_CONFIRM,
                        MODAL_CANCELLATION_CONFIRM,
                    ].includes(modalType)
                        ? "primary"
                        : "danger"
                }
                modalType="custom"
                modalContent={modalContent}
                modalButton={renderModalButton()}
            />

            <LoadingModal showModal={isActionPending} />
        </Row>
    );
};

const CurrentPlanButton = ({
    status,
    billing_period_end_date,
    subscriptionType,
    showSubscriptionCancellationConfirmation,
    showContactSupportTeam,
    confirmToCancelSubscription,
    showSubscribeConfirmation,
}: {
    status: string;
    billing_period_end_date: string;
    subscriptionType:
        | SubscriptionPlanType.MONTHLY
        | SubscriptionPlanType.PREPAID
        | SubscriptionPlanType.BASIC;
    showSubscriptionCancellationConfirmation: () => void;
    showContactSupportTeam: () => void;
    confirmToCancelSubscription: () => void;
    showSubscribeConfirmation: () => void;
}) => {
    if (status === SubscriptionStatus.ACTIVE) {
        const planCancellationModals: any = {
            [SubscriptionPlanType.MONTHLY]:
                showSubscriptionCancellationConfirmation,
            [SubscriptionPlanType.PREPAID]: showContactSupportTeam,
            [SubscriptionPlanType.BASIC]: confirmToCancelSubscription,
        };

        return (
            <Button
                onClick={planCancellationModals[subscriptionType]}
                variant="danger"
            >
                CANCEL SUBSCRIPTION{" "}
            </Button>
        );
    } else if (
        status === SubscriptionStatus.EXPIRED ||
        (status === SubscriptionStatus.CANCELED &&
            moment() > moment(billing_period_end_date))
    ) {
        return (
            <Button onClick={showSubscribeConfirmation} variant="primary">
                SUBSCRIBE{" "}
            </Button>
        );
    } else {
        return null;
    }
};

const CurrentPlanAddons: any = ({
    addons,
    showRemovePurchasedTokenConfirmation,
    status,
    billing_period_end_date,
    subscriptionAmount,
    billing_day_of_month,
    billing_period_start_date,
    subscriptionType,
    create_time,
    billing_cycles,
    current_cycle,
    next_billing_date,
    isLastBillingPeriod,
    endDate,
}: {
    addons: RevisedAddon[];
    showRemovePurchasedTokenConfirmation: (totalAmount: string) => void;
    status: string;
    billing_period_end_date: string;
    subscriptionAmount: string;
    billing_day_of_month: number;
    billing_period_start_date: string;
    subscriptionType:
        | SubscriptionPlanType.BASIC
        | SubscriptionPlanType.MONTHLY
        | SubscriptionPlanType.PREPAID;
    create_time: string;
    billing_cycles: number;
    current_cycle: number;
    next_billing_date: string;
    isLastBillingPeriod: any;
    endDate: string;
}) => {
    const onlyAllocatedAndPurchasedAddons = addons.filter(
        ({
            content: {
                token: { type_ },
            },
        }: {
            content: { token: { type_: string } };
        }) => type_ !== "BONUS"
    );
    const sortedAddons = orderBy(
        onlyAllocatedAndPurchasedAddons,
        ["create_time"],
        ["asc"]
    );

    const renderStartDate = ({
        bt_id,
        create_time,
    }: {
        bt_id: string;
        create_time: Date;
    }) => {
        if (
            bt_id === BraintreeId.MONTHLY_PLAN ||
            [SubscriptionPlanType.BASIC, SubscriptionPlanType.PREPAID].includes(
                subscriptionType
            )
        ) {
            return moment(create_time).format(SUBSCRIPTION_DATE_FORMAT);
        }

        const addOnCreatedYear = moment(create_time).year();
        const addOnCreatedMonth = moment(create_time).month() + 1;
        const addOnCreatedDay = moment(create_time).date();

        let billingYear = addOnCreatedYear;
        let billingMonth = addOnCreatedMonth;

        // NOTE: If addon created day has past billing date of that month, use next month's billing date
        if (billing_day_of_month <= addOnCreatedDay) {
            const billingDate = moment(
                `${addOnCreatedYear}-${addOnCreatedMonth}`,
                "YYYY-M"
            ).add(1, "M");
            billingYear = moment(billingDate).year();
            billingMonth = moment(billingDate).month() + 1; // moment.month() returns month in 0 to 11
        }

        const numberOfDaysInBillingMonth = moment(
            `${billingYear}-${billingMonth}`,
            "YYYY-M"
        ).daysInMonth();

        const billingDayIsValid = inRange(
            billing_day_of_month,
            1,
            numberOfDaysInBillingMonth
        );

        if (billingDayIsValid) {
            return moment(
                `${billingYear}-${billingMonth}-${billing_day_of_month}`,
                "YYYY-M-D"
            ).format(SUBSCRIPTION_DATE_FORMAT);
        } else {
            return moment(
                `${billingYear}-${billingMonth}-${numberOfDaysInBillingMonth}`,
                "YYYY-M-D"
            ).format(SUBSCRIPTION_DATE_FORMAT);
        }
    };

    const renderBillingPeriodEndDate = () => {
        if (status === SubscriptionStatus.PAST_DUE) {
            return moment(billing_period_start_date).format(
                SUBSCRIPTION_DATE_FORMAT
            );
        } else {
            return moment(billing_period_end_date).format(
                SUBSCRIPTION_DATE_FORMAT
            );
        }
    };

    const CurrentPlanTypeLabel = ({ bt_id, amount, quantity }: any) => {
        const getPlanName = () => {
            if (bt_id === BraintreeId.TOKEN_ADDON) return "Add-on";
            return planDisplayName[subscriptionType];
        };

        const getPlanType = () => {
            if (bt_id === BraintreeId.TOKEN_ADDON) return "addon";
            return subscriptionType;
        };

        if (subscriptionType === SubscriptionPlanType.BASIC) {
            return (
                <Col sm={5}>
                    <div
                        className="currentPlanTitle"
                        aria-label="current-plan-type"
                    >
                        {`${getPlanName()} -  `}
                        {renderPlanStatus(status)}
                    </div>
                    <div>{SubscriptionDict[getPlanType()]}</div>
                </Col>
            );
        }

        return (
            <Col sm={5}>
                <div
                    className="currentPlanTitle"
                    aria-label="current-plan-type"
                >
                    {`${getPlanName()} -  `}
                    {renderPlanStatus(status)}
                </div>
                <div>{SubscriptionDict[getPlanType()]}</div>
                {bt_id !== BraintreeId.MONTHLY_PLAN &&
                    status === SubscriptionStatus.ACTIVE && (
                        <div>
                            {
                                <button
                                    className="removeAddon"
                                    onClick={() => {
                                        showRemovePurchasedTokenConfirmation(
                                            (Number(amount) * quantity).toFixed(
                                                2
                                            )
                                        );
                                    }}
                                >
                                    Remove Add-on
                                </button>
                            }
                        </div>
                    )}
            </Col>
        );
    };

    const TokenTypeAndQuantityLabel = ({ content, bt_id, quantity }: any) => {
        return (
            <>
                {subscriptionType !== SubscriptionPlanType.BASIC && (
                    <Col sm={2}>
                        <div
                            className="currentPlanTitle"
                            aria-label="token-type"
                        >
                            Monthly {capitalize(content.token.type_)} Tokens
                        </div>
                        <div aria-label="token-quantity">
                            {bt_id !== BraintreeId.MONTHLY_PLAN &&
                                `${quantity} x `}
                            {content.token.amount.toLocaleString()}
                        </div>
                    </Col>
                )}
            </>
        );
    };

    const StartDateTimeLabel = ({ bt_id, create_time }: any) => {
        return (
            <Col sm={2}>
                <div className="currentPlanTitle" aria-label="start-date-label">
                    {moment().isAfter(
                        moment(
                            renderStartDate({
                                bt_id,
                                create_time,
                            })
                        )
                    )
                        ? "Started On"
                        : "Starts On"}
                </div>

                <div aria-label="start-date">
                    {" "}
                    {bt_id === BraintreeId.TOKEN_ADDON &&
                    status === SubscriptionStatus.CANCELED &&
                    moment(renderStartDate({ bt_id, create_time })).isAfter(
                        moment(billing_period_end_date)
                    )
                        ? "Canceled Prior to Start Date"
                        : renderStartDate({
                              bt_id,
                              create_time,
                          })}
                </div>
            </Col>
        );
    };

    const RenewalLabel = () => {
        return (
            <Col sm={2}>
                <div className="currentPlanTitle" aria-label="renewal-label">
                    Renewal
                </div>
                <div aria-label="renewal-frequency">Monthly</div>
            </Col>
        );
    };

    const now = moment();
    const next_billing_date_moment = moment(next_billing_date, "YYYY-MM-DD");

    const EndDateTimeLabel = ({
        create_time,
        bt_id,
        isLastBillingPeriod_boolean,
    }: {
        create_time: any;
        isLastBillingPeriod_boolean: any;
        bt_id?: any;
    }) => {
        const getEndLabel = ({ status }: any) => {
            switch (status) {
                case SubscriptionStatus.CANCELED:
                    return now.isAfter(next_billing_date_moment)
                        ? "Ended On"
                        : "Ends On";
                case SubscriptionStatus.EXPIRED:
                    return "Expired On";
                case SubscriptionStatus.PAST_DUE:
                    return "Past Due On";
                case SubscriptionStatus.ACTIVE:
                    return isLastBillingPeriod_boolean
                        ? "Expires On"
                        : "End Date";
                default:
                    return "End Date";
            }
        };

        const getEndValue = () => {
            switch (status) {
                case SubscriptionStatus.CANCELED:
                    return bt_id === BraintreeId.TOKEN_ADDON &&
                        moment(
                            renderStartDate({
                                bt_id,
                                create_time,
                            })
                        ).isAfter(moment(billing_period_end_date))
                        ? "-"
                        : renderBillingPeriodEndDate();
                case SubscriptionStatus.EXPIRED:
                case SubscriptionStatus.PAST_DUE:
                    return renderBillingPeriodEndDate();
                case SubscriptionStatus.ACTIVE:
                    return !isLastBillingPeriod_boolean &&
                        [
                            SubscriptionPlanType.BASIC,
                            SubscriptionPlanType.PREPAID,
                        ].includes(subscriptionType)
                        ? endDate
                        : renderBillingPeriodEndDate();
            }
        };

        return (
            <Col sm={2}>
                <div className="currentPlanTitle">
                    {getEndLabel({ status })}
                </div>

                <div>{getEndValue()}</div>
            </Col>
        );
    };

    const PerMonthLabel = ({ bt_id, amount, quantity }: any) => {
        return (
            <Col sm={1} className="text-right">
                <div className="currentPlanTitle">Per Month</div>
                <div aria-label="monthly-charge">
                    {bt_id === BraintreeId.MONTHLY_PLAN
                        ? `USD ${subscriptionAmount}`
                        : `USD ${(Number(amount) * quantity).toFixed(2)}`}
                </div>
            </Col>
        );
    };

    const PaymentStatusLabel = () => {
        return (
            <Col sm={1} className="text-right">
                <div className="currentPlanTitle">Payment Status</div>
                <div aria-label="monthly-charge">Paid</div>
            </Col>
        );
    };

    if (subscriptionType === SubscriptionPlanType.BASIC) {
        return (
            <div className="d-flex flex-column h-100 w-100">
                <Row className="currentPlanRow">
                    <CurrentPlanTypeLabel />
                    <BasicPlanCustomWrapper>
                        <StartDateTimeLabel create_time={create_time} />

                        <EndDateTimeLabel
                            create_time={create_time}
                            isLastBillingPeriod_boolean={isLastBillingPeriod({
                                billing_cycles,
                                current_cycle,
                            })}
                        />
                    </BasicPlanCustomWrapper>
                </Row>
            </div>
        );
    }

    return sortedAddons.map(
        ({
            bt_id,
            amount,
            content,
            create_time,
            quantity,
            billing_cycles,
        }: RevisedAddon) => {
            return (
                <div key={bt_id} className="d-flex flex-column h-100 w-100">
                    <Row className="currentPlanRow">
                        <CurrentPlanTypeLabel
                            bt_id={bt_id}
                            amount={amount}
                            quantity={quantity}
                        />
                        <TokenTypeAndQuantityLabel
                            content={content}
                            bt_id={bt_id}
                            quantity={quantity}
                        />
                        <StartDateTimeLabel
                            bt_id={bt_id}
                            create_time={create_time}
                        />
                        {SubscriptionPlanType.MONTHLY === subscriptionType &&
                        status === SubscriptionStatus.ACTIVE &&
                        !isLastBillingPeriod({
                            billing_cycles,
                            current_cycle,
                        }) ? (
                            <RenewalLabel />
                        ) : (
                            <EndDateTimeLabel
                                bt_id={bt_id}
                                create_time={create_time}
                                isLastBillingPeriod_boolean={isLastBillingPeriod(
                                    {
                                        billing_cycles,
                                        current_cycle,
                                    }
                                )}
                            />
                        )}

                        {SubscriptionPlanType.MONTHLY === subscriptionType && (
                            <PerMonthLabel
                                bt_id={bt_id}
                                amount={amount}
                                quantity={quantity}
                            />
                        )}
                        {SubscriptionPlanType.PREPAID === subscriptionType && (
                            <PaymentStatusLabel />
                        )}
                    </Row>
                </div>
            );
        }
    );
};

const CurrentPlanSummary = ({
    addons,
    subscriptionAmount,
    next_billing_date,
}: {
    addons: RevisedAddon[];
    subscriptionAmount: string;
    next_billing_date: string;
}) => {
    const nextBillingTotal: number = addons.reduce(
        (acc: number, { amount, quantity }: RevisedAddon) => {
            acc += Number(amount) * quantity;
            return acc;
        },
        Number(subscriptionAmount)
    );
    return (
        <div className="d-flex flex-column h-100 w-100">
            <Row className="currentPlanRow current-plan-footer">
                <Col sm={9}></Col>
                <Col sm={2}>
                    <div
                        className="current-plan-footer-label"
                        aria-label="summarized-next-billing-date-label"
                    >
                        Next Billing Date
                    </div>
                    <div aria-label="summarized-next-billing-date">
                        {" "}
                        {moment(next_billing_date).format(
                            SUBSCRIPTION_DATE_FORMAT
                        )}
                    </div>
                </Col>
                <Col sm={1} className="text-right">
                    <div
                        className="current-plan-footer-label"
                        aria-label="next-billing-total-label"
                    >
                        {" "}
                        Next Billing Total
                    </div>
                    <div aria-label="monthly-total">
                        USD {nextBillingTotal.toFixed(2).toLocaleString()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CurrentPlanInformation;
