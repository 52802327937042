import DefaultModal from "components/modals/ModalTemplate";
import { DOWNLOAD_REMINDER_MESSAGE } from "constant";
import { Button } from "react-bootstrap";

type RemindDownloadDataModalProps = {
    show: boolean;
    onConfirm: (confirmation: boolean) => Promise<void>;
};

const RemindDownloadDataModal = ({
    show,
    onConfirm,
}: RemindDownloadDataModalProps) => {
    const closeModal = () => {
        onConfirm(false);
    };

    const confirm = () => {
        onConfirm(true);
    };

    return (
        <DefaultModal
            modalTitle="Download Data"
            modalType="custom"
            modalIcon=""
            modalContent={DOWNLOAD_REMINDER_MESSAGE}
            modalButton={() => (
                <>
                    <Button variant="secondary" onClick={closeModal}>
                        CANCEL
                    </Button>
                    <Button variant="primary" onClick={confirm}>
                        PROCEED
                    </Button>
                </>
            )}
            modalShow={show}
        />
    );
};

export default RemindDownloadDataModal;
