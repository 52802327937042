import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    InputGroup,
    FormControl,
    Form,
    Button,
    Dropdown,
} from "react-bootstrap";
import dlt from "assets/svg/delete.svg";
import dltWhite from "assets/svg/delete-white.svg";
import {
    deleteOneLDSU,
    downloadStorage,
    updateMultipleDevicesConfig,
} from "service/gatewayService";
import DefaultModal, { useModal } from "components/modals/ModalTemplate";
import useCollectSort from "hooks/useCollectSort";
import { gatewayReadingStatusStore } from "hooks/gatewayReading";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import ContentWrapper from "components/content-wrapper/ContentWrapper";

import { isHttpSuccess } from "utils/functions";
import { showErrorAlert, showInfoAlert, showSuccessAlert } from "utils/alert";
import {
    DefaultMaxCharacter,
    DefaultMinCharacter,
    FAILED_ACTION_MESSAGE,
    Patterns,
    DELETE_LDSU_SUCCESS_MESSAGE,
} from "constant";
import { walletModalTrigger } from "hooks/wallet";
import { ResponseDto } from "types";
import { get, map, some, uniq } from "lodash";
import TimezoneDropdown from "components/common/TimezoneDropdown";
import { useBasicPlanModal } from "hooks/useBasicPlanModal";
import { BasicPlanModal } from "components/modals/BasicPlanModal";
import { useCheckIfBasicPlan } from "hooks/useCheckIfBasicPlan";
import moment, { Moment } from "moment-timezone";
import { useOrganisation } from "hooks/organization";
import DateTimeRangePicker from "components/common/DateTimeRangePicker";

enum AddNameType {
    Prefix = "name_prefix",
    Postfix = "name_postfix",
}

const NAME_PREFIX_MAX_LENGTH = 32;
const { hasLeadingSpaces, hasTrailingSpaces } = Patterns;

type DownloadModalData = {
    start: Moment;
    end: Moment;
    timezone: string;
    error?: string;
};

const ReachableStatus = {
    REACHABLE: "Reachable",
    NOT_REACHABLE: "Not Reachable",
};

const LdsuList: React.FunctionComponent<any> = ({
    UUID: gatewayId,
    ldsus,
    gatewayName,
    portNumber,
    setLdsuLength,
}) => {
    const { defaultTimeZone } = useOrganisation();
    const [ldsuList, setLdsuList] = useState<Array<any>>([]);
    const [initialLdsuList, setInitialLdsuList] = useState<Array<any>>([]);
    const { data: ldsuCollect, sortIcon } = useCollectSort(ldsuList);
    const [checkedLdsu, setCheckedLdsu] = useState<any[]>([]);
    const knownDevices = checkedLdsu.filter((l) => l.name);
    const unknownDevices = checkedLdsu.filter((l) => !l.name);
    const {
        openModal,
        modal: ActionModal,
        setModalShow: setActionModalShow,
        setModalData,
        modalData,
    } = useModal();

    // New API State
    const params: any = useParams();
    const history = useHistory();
    // Modal State
    const [modalType, setModalType] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [uuidToDelete, setUUIDToDelete] = useState("");

    const gatewayReadingData =
        gatewayReadingStatusStore.getState().gatewayReadingData;
    const { IS_BASIC_PLAN } = useCheckIfBasicPlan();
    const { toggleShowBasicPlanModal, showBasicPlanModal }: any =
        useBasicPlanModal();

    useEffect(() => {
        const transformedLdsus = ldsus.map((item: any) => {
            return {
                ...item,
                status: gatewayReadingData[item.UID]
                    ? ReachableStatus.REACHABLE
                    : ReachableStatus.NOT_REACHABLE,
            };
        });
        setLdsuList(transformedLdsus);
        setInitialLdsuList(transformedLdsus);
        setLdsuLength(transformedLdsus.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ldsus, setLdsuLength]);

    const getLdsuName = (ldsu: any) => {
        return get(ldsu, "name", ldsu.NAME);
    };

    const checkReachableStatus = (i: any) => {
        return (
            <div className="ldsuList-row-status">
                <i
                    className={`material-icons circle ${
                        i.status === ReachableStatus.REACHABLE
                            ? "online"
                            : "offline"
                    }`}
                >
                    circle
                </i>{" "}
                {i.status}
            </div>
        );
    };

    const renderLdsus = () => {
        const lst: any = ldsuCollect.map((i: any) => {
            return (
                <div className="table-row" key={i.UID}>
                    <Row className="no-checkbox ldsuList">
                        <div className="ldsuList-row">
                            <div className="ldsuList-row-start">
                                <Form.Check
                                    type="checkbox"
                                    id={i.UID}
                                    checked={some(checkedLdsu, {
                                        UID: i.UID,
                                        name: i.name,
                                    })}
                                    onChange={(e: any) => {
                                        if (e.target.checked) {
                                            setCheckedLdsu((prev: any) =>
                                                uniq([
                                                    ...prev,
                                                    {
                                                        UID: i.UID,
                                                        name: i.name,
                                                    },
                                                ])
                                            );
                                        } else {
                                            setCheckedLdsu((prev: any) =>
                                                prev.filter(
                                                    ({
                                                        UID,
                                                    }: {
                                                        UID: string;
                                                    }) => UID !== i.UID
                                                )
                                            );
                                        }
                                    }}
                                    custom
                                    label={<span className="mr-sm-2"></span>}
                                    aria-label="single-checkbox"
                                />
                            </div>
                            <div className="ldsuList-row-middle">
                                <div className="ldsuList-row-ldsu-name">
                                    <Link
                                        to={{
                                            pathname: `/gateway-details/${params.gatewayId}/LDSBus/${params.busNumber}/${i.UID}`,
                                            state: {
                                                gatewayName: gatewayName,
                                                portNumber: portNumber,
                                                ldsuUUID: i.UID,
                                                UUID: gatewayId,
                                                ldsuDetails: i,
                                            },
                                        }}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: getLdsuName(i)?.replace(
                                                    / /g,
                                                    "&nbsp;"
                                                ),
                                            }}
                                        />
                                    </Link>
                                </div>
                                <div className="ldsuList-row-uuid">
                                    <span className="reading-label ">
                                        UUID:{" "}
                                    </span>
                                    {i.UID}
                                </div>
                                {checkReachableStatus(i)}
                                <div className="ldsuList-row-reporting-rate">
                                    {i.INTVL} {i.INTVL ? `sec(s)` : "-"}
                                </div>
                                <div className="ldsuList-row-termination-status">
                                    {i.CMDS ? i.TERM.toUpperCase() : "-"}
                                </div>
                            </div>
                            <div className="ldsuList-row-end">
                                <div className="ldsuList-row-delete-action">
                                    <HoverAuthorizeTooltip permission="gateway:delete">
                                        <img
                                            src={dlt}
                                            alt="delete"
                                            onClick={() => {
                                                handleClickDelete(
                                                    getLdsuName(i),
                                                    i.UID
                                                );
                                            }}
                                            onKeyDown={() => {
                                                handleClickDelete(
                                                    getLdsuName(i),
                                                    i.UID
                                                );
                                            }}
                                        />
                                    </HoverAuthorizeTooltip>
                                </div>
                                <div className="ldsuList-row-detail-action">
                                    <Link
                                        to={{
                                            pathname: `/gateway-details/${params.gatewayId}/LDSBus/${params.busNumber}/${i.UID}`,
                                            state: {
                                                gatewayName: gatewayName,
                                                portNumber: portNumber,
                                                ldsuUUID: i.UID,
                                                UUID: gatewayId,
                                                ldsuDetails: i,
                                            },
                                        }}
                                    >
                                        <i className="material-icons text-primary-green-1">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            );
        });

        if (lst.length === 0) {
            lst.push(
                <div className="mt-5 text-center w-100">
                    No LDSUs to display
                </div>
            );
        }
        return lst;
    };

    const searchLdsu = (value: string) => {
        if (value) {
            const filteredLdsu = [];
            for (const i of initialLdsuList) {
                if (
                    getLdsuName(i)?.toLowerCase().includes(value.toLowerCase())
                ) {
                    filteredLdsu.push(i);
                }
            }
            setLdsuList(filteredLdsu);
            setLdsuLength(filteredLdsu.length);
        } else {
            setLdsuList(ldsus);
            setLdsuLength(ldsus.length);
        }
    };

    const handleClickDelete = (ldsu_name: string, ldsu_uuid: string) => {
        setUUIDToDelete(ldsu_uuid);
        setModalType("dlt");
        setModalTitle("Delete LDSU?");
        setModalShow(true);
        setModalContent(`Are you sure you want to delete LDSU ${ldsu_name}?`);
    };

    const deleteThisLdsu = async () => {
        try {
            const deleteRes: any = await deleteOneLDSU(
                params.gatewayId,
                uuidToDelete
            );

            if (isHttpSuccess(deleteRes.status)) {
                showSuccessAlert({
                    message: DELETE_LDSU_SUCCESS_MESSAGE,
                });

                history.push(
                    `/gateway-details/${params.gatewayId}/LDSBus/${params.busNumber}`,
                    {
                        refresh: [uuidToDelete],
                    }
                );

                setCheckedLdsu(
                    checkedLdsu.filter(
                        (checked) => checked.UID !== uuidToDelete
                    )
                );
            } else {
                showErrorAlert({
                    message: deleteRes.message,
                });
            }
            setModalShow(true);
            setModalShow(false);
        } catch (err: any) {
            showErrorAlert({
                message: FAILED_ACTION_MESSAGE,
            });
        }

        setModalTitle("");
        setModalShow(false);
    };

    const deleteSelectedLdsuHandler = async () => {
        openModal({
            key: "delete_ldsu",
            modalShow: true,
            modalType: "dlt",
            modalTitle: "Delete LDSUs?",
            modalContent:
                "Are you sure you want to delete {length} LDSU(s) from LDSBus {port}? This will delete associated Sensors and Actuators.".fill(
                    {
                        length: checkedLdsu.length,
                        port: params.busNumber,
                    }
                ),
            deleteFunction: async () => {
                setActionModalShow(false);

                const successResList: ResponseDto[] = [];
                const failedResList: ResponseDto[] = [];

                const responses = await Promise.all(
                    checkedLdsu.map(({ UID }: { UID: string }) => {
                        return deleteOneLDSU(params.gatewayId, UID);
                    })
                );

                responses.forEach((r) => {
                    if (r && typeof r === "object" && "status" in r) {
                        isHttpSuccess(r.status)
                            ? successResList.push(r)
                            : failedResList.push(r);
                    }
                });

                if (successResList.length > 0) {
                    showSuccessAlert({
                        message: `${successResList.length} LDSUs are deleted successfully.`,
                    });

                    const successResListUIDs = successResList.map(
                        (r) => r.data.device
                    );
                    history.push(
                        `/gateway-details/${params.gatewayId}/LDSBus/${params.busNumber}`,
                        {
                            refresh: successResListUIDs,
                        }
                    );
                }

                failedResList.forEach((frl) => {
                    frl.message &&
                        showErrorAlert({
                            message: frl.message,
                        });
                });

                setLdsuList((prev) =>
                    prev.filter(
                        (ldsu) =>
                            !successResList.some(
                                (r) => r.data.device === ldsu.UID
                            )
                    )
                );
                setLdsuLength(
                    (prev: number) => Number(prev) - successResList.length
                );

                setCheckedLdsu(
                    checkedLdsu.filter(
                        (cl) =>
                            !successResList
                                .map((srl) => srl.data.device)
                                .includes(cl.UID)
                    )
                );
            },
        });
    };

    const lengthExceed = ({
        type,
        prefix,
        postfix,
    }: {
        type: string;
        prefix: string;
        postfix: string;
    }) => {
        const charactersToAdd =
            type === AddNameType.Prefix ? prefix.length : postfix.length;

        return knownDevices.some((l: any) => {
            return l.name.length + charactersToAdd > DefaultMaxCharacter;
        });
    };

    const isInputVerified = (openedModalData: any) => {
        if (
            openedModalData.type === AddNameType.Prefix &&
            hasLeadingSpaces.test(openedModalData.name_prefix)
        ) {
            showErrorAlert({
                message: "Not allowed to have leading spaces for prefix.",
            });
            return false;
        } else if (
            openedModalData.type === AddNameType.Postfix &&
            hasTrailingSpaces.test(openedModalData.name_postfix)
        ) {
            showErrorAlert({
                message: "Not allowed to have trailing spaces for suffix.",
            });
            return false;
        }

        if (
            lengthExceed({
                type: openedModalData.type,
                prefix: openedModalData.name_prefix,
                postfix: openedModalData.name_postfix,
            })
        ) {
            showErrorAlert({
                message: `LDSU Name must be between ${DefaultMinCharacter}-${DefaultMaxCharacter} characters`,
            });
            return false;
        }
        return true;
    };

    const unknownDeviceInfoAlert = () => {
        unknownDevices.length &&
            showInfoAlert({
                message: `Please note that this operation will not be performed on unknown LDSU(s). Unknown LDSU(s): ${unknownDevices.map(
                    (d) => d.UID
                )}`,
            });
    };

    const updatePrefixSuffix = (openedModalData: any) => {
        if (!isInputVerified(openedModalData)) return;

        openModal({
            key: "add_prefix_suffix_confirm",
            modalTitle: "Confirmation",
            modalType: "updateConfirm",
            modalContent:
                "Are you sure you want to update the name of {length} LDSUs?".fill(
                    checkedLdsu
                ),
            resendFunction: async () => {
                unknownDeviceInfoAlert();

                if (knownDevices.length) {
                    const { status, data }: { status: number; data?: any } =
                        (await updateMultipleDevicesConfig({
                            devices: knownDevices.map(
                                ({ UID }: { UID: string }) => ({
                                    gateway_id: gatewayId,
                                    device_id: UID,
                                })
                            ),
                            ...(openedModalData.type === AddNameType.Prefix &&
                                openedModalData.name_prefix && {
                                    name_prefix: openedModalData.name_prefix,
                                }),
                            ...(openedModalData.type === AddNameType.Postfix &&
                                openedModalData.name_postfix && {
                                    name_postfix: openedModalData.name_postfix,
                                }),
                        })) ?? {};

                    if (isHttpSuccess(status)) {
                        showSuccessAlert({
                            message: `Name of ${knownDevices.length} LDSUs are updated successfully.`,
                        });

                        setLdsuList((prev) =>
                            prev.map((lds) => {
                                if (
                                    some(knownDevices, {
                                        UID: lds.UID,
                                        name: lds.name,
                                    })
                                ) {
                                    return {
                                        ...lds,
                                        name:
                                            ((openedModalData.type ===
                                                AddNameType.Prefix &&
                                                openedModalData.name_prefix) ||
                                                "") +
                                            lds.name +
                                            ((openedModalData.type ===
                                                AddNameType.Postfix &&
                                                openedModalData.name_postfix) ||
                                                ""),
                                    };
                                } else {
                                    return lds;
                                }
                            })
                        );
                        setModalData({
                            type: AddNameType.Prefix,
                            name_prefix: "",
                            name_postfix: "",
                        });
                    } else {
                        showErrorAlert({
                            title: data?.title,
                            message: data?.description,
                        });
                    }
                }
                setCheckedLdsu([]);
            },
        });
    };

    const closePrefixSuffixHandlerModal = () => {
        setActionModalShow(false);
        setModalData({
            type: AddNameType.Prefix,
            name_prefix: "",
            name_postfix: "",
        });
    };

    const updatePrefixSuffixHandler = async () => {
        setModalData({
            type: AddNameType.Prefix,
            name_prefix: "",
            name_postfix: "",
        });
        openModal({
            key: "add_prefix_suffix",
            modalType: "content",
            modalTitle: "Add Prefix/Suffix",
            width: 635,
            modalHeaderButton: (openedModalData: any) => (
                <Button
                    variant=""
                    className="close-button"
                    onClick={closePrefixSuffixHandlerModal}
                >
                    <span className="material-icons">close</span>
                </Button>
            ),
            modalContent: (openedModalData: any) => (
                <Form
                    onChange={(event) => {
                        const data = event.currentTarget.toData<{
                            type: AddNameType.Prefix | AddNameType.Postfix;
                            [key: string]: string;
                        }>();

                        setModalData((prev) => ({
                            ...prev,
                            type: data.type,
                            [data.type]: data[data.type],
                        }));
                    }}
                >
                    <Row className="text-gray open-modal">
                        {Object.values(AddNameType).map((type) => (
                            <Col
                                key={type}
                                lg={{
                                    span: 3,
                                }}
                                xs={{
                                    span: 6,
                                }}
                            >
                                <Form.Check
                                    id={`ps_type_${type}`}
                                    type="radio"
                                    label={
                                        type === AddNameType.Prefix
                                            ? "Prefix"
                                            : "Suffix"
                                    }
                                    name="type"
                                    value={type}
                                    checked={openedModalData.type === type}
                                    onChange={() => {}}
                                    custom
                                />
                            </Col>
                        ))}
                    </Row>

                    <Form.Group className="d-flex align-items-center mt-4">
                        <span
                            className={`text-gray ${
                                openedModalData.type === AddNameType.Prefix
                                    ? "order-2 ml-2 "
                                    : "order-1 mr-2"
                            }`}
                        >
                            [Name]
                        </span>
                        {Object.values(AddNameType).map((i) => (
                            <Form.Control
                                key={i}
                                type="text"
                                id={i}
                                className={`${
                                    openedModalData.type !== i ? "d-none" : ""
                                } ${
                                    openedModalData.type === AddNameType.Prefix
                                        ? "order-1"
                                        : "order-2"
                                } ldsu-prefix-suffix-input`}
                                name={i}
                                onChange={(event: any) => {
                                    if (event.target.value.length > 0) {
                                        // Replace all non-ASCII characters

                                        const cleanedInput =
                                            event.target.value.replace(
                                                /[^\x20-\x7F]/g,
                                                ""
                                            );
                                        setModalData({
                                            ...modalData,
                                            type: i,
                                            [event.target.name]: cleanedInput,
                                        });
                                    }
                                }}
                                isInvalid={
                                    openedModalData[i]?.trim().length >
                                    NAME_PREFIX_MAX_LENGTH
                                }
                                aria-label={"input " + i.split("_")[1]}
                            />
                        ))}
                    </Form.Group>
                </Form>
            ),
            modalFooter: (openedModalData: any) => (
                <div className="w-100 d-flex justify-content-between align-items-center m-0">
                    <span className="text-gray">
                        {"{length} LDSU(s) selected".fill(checkedLdsu)}
                    </span>

                    <Button
                        disabled={
                            (openedModalData.type === AddNameType.Prefix &&
                                !openedModalData.name_prefix) ||
                            (openedModalData.type === AddNameType.Postfix &&
                                !openedModalData.name_postfix) ||
                            (openedModalData.type === AddNameType.Prefix &&
                                openedModalData.name_prefix?.length >
                                    NAME_PREFIX_MAX_LENGTH) ||
                            (openedModalData.type === AddNameType.Postfix &&
                                openedModalData.name_postfix?.length >
                                    NAME_PREFIX_MAX_LENGTH)
                        }
                        onClick={() => {
                            updatePrefixSuffix(openedModalData);
                        }}
                    >
                        UPDATE
                    </Button>
                </div>
            ),
        });
    };

    const updateReportRate = (openedModalData: any) => {
        openModal({
            key: "change_report_rate_confirm",
            modalType: "updateConfirm",
            modalTitle: "Confirmation",
            modalContent:
                "Are you sure you want to update the Report Rate of sensors in {length} LDSUs?".fill(
                    checkedLdsu
                ),
            resendFunction: async () => {
                unknownDeviceInfoAlert();

                if (knownDevices.length) {
                    const { status, data }: { status: number; data?: any } =
                        (await updateMultipleDevicesConfig({
                            INTVL: Number(openedModalData.report_rate),
                            devices: knownDevices.map(
                                ({ UID }: { UID: string }) => ({
                                    gateway_id: gatewayId,
                                    device_id: UID,
                                })
                            ),
                        })) ?? {};

                    if (isHttpSuccess(status)) {
                        showSuccessAlert({
                            message: `Report Rate of sensors in ${knownDevices.length} LDSUs are updated successfully.`,
                        });
                    } else {
                        showErrorAlert({
                            title: data?.title,
                            message: data?.description,
                        });
                        return;
                    }
                }

                setLdsuList((prev) =>
                    prev.map((lds) => {
                        if (
                            knownDevices.some(
                                (checked) => checked.UID === lds.UID
                            )
                        ) {
                            return {
                                ...lds,
                                INTVL: Number(openedModalData.report_rate),
                            };
                        }
                        return lds;
                    })
                );

                setCheckedLdsu([]);
            },
        });
    };

    const changeReportRateHandler = () => {
        setModalData({
            report_rate: 5,
        });
        openModal({
            key: "change_report_rate",
            modalType: "content",
            modalTitle: "Change Report Rate",
            width: 635,
            modalContent: (openedModalData: any) => (
                <Form>
                    <Form.Group className="text-gray input-right-text">
                        <Form.Label className="text-left w-100">
                            Report Rate
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                name="report_rate"
                                value={openedModalData.report_rate}
                                isInvalid={
                                    !openedModalData.report_rate ||
                                    openedModalData.report_rate % 5 !== 0 ||
                                    openedModalData.report_rate < 5 ||
                                    openedModalData.report_rate > 1800
                                }
                                onChange={(e) => {
                                    setModalData({
                                        report_rate: e.currentTarget.value,
                                    });
                                }}
                                aria-label="input report rate"
                            />
                            <span>sec(s)</span>
                        </InputGroup>
                    </Form.Group>
                    <p className="w-100 text-left fs-14">
                        Minimum - 5 seconds, Maximum - 1800 seconds (30 minutes)
                        and value should be multiple of 5 seconds.
                    </p>
                </Form>
            ),
            modalFooter: (openedModalData: any) => (
                <div className="w-100 d-flex justify-content-between align-items-center m-0">
                    <span className="text-gray">
                        {"{length} LDSU(s) selected".fill(checkedLdsu)}
                    </span>

                    <Button
                        disabled={
                            !Number(openedModalData.report_rate) ||
                            Number(openedModalData.report_rate) % 5 !== 0 ||
                            Number(openedModalData.report_rate) < 5 ||
                            Number(openedModalData.report_rate) > 1800
                        }
                        onClick={() => {
                            updateReportRate(openedModalData);
                        }}
                    >
                        UPDATE
                    </Button>
                </div>
            ),
        });
    };

    const handleDateTimeRangeChange = (value: Moment[]) => {
        setModalData((prev) => ({
            ...prev,
            start: value[0],
            end: value[1],
        }));
    };

    const handleDateTimeRangeError = (error: string) => {
        setModalData((prev) => ({
            ...prev,
            error,
        }));
    };

    const handleChangeTimeZone = (
        timezone: string,
        data: DownloadModalData
    ) => {
        const { start, end, error } = data;
        const startDate = moment.tz(+start, timezone);
        const endDate = moment.tz(+end, timezone);
        setModalData({
            start: startDate,
            end: endDate,
            timezone,
            error,
        });
    };

    const downloadData = (openedModalData: {
        start: Moment;
        end: Moment;
        timezone: string;
    }) => {
        openModal({
            key: "download_data_confirm",
            modalType: "updateConfirm",
            modalTitle: "Confirmation",
            modalContent: (
                <p
                    dangerouslySetInnerHTML={{
                        __html: `By confirming, an email will be sent to your email address with instructions on how to download.<br />Do you want to continue?`,
                    }}
                />
            ),
            resendFunction: async () => {
                unknownDeviceInfoAlert();

                if (knownDevices.length) {
                    const { status } =
                        (await downloadStorage({
                            start: openedModalData.start.seconds(0).unix(),
                            end: openedModalData.end.seconds(0).unix(),
                            attributes: knownDevices.map(
                                ({ UID }: { UID: string }) => ({
                                    gateway_id: gatewayId,
                                    device_id: UID,
                                })
                            ),
                            time_zone: openedModalData.timezone,
                        })) || {};

                    if (isHttpSuccess(status)) {
                        showSuccessAlert({
                            message: `An email will be sent to you shortly once it is ready to download.`,
                        });
                    }
                }

                setCheckedLdsu([]);
            },
        });
    };

    const downloadDataHandler = () => {
        if (IS_BASIC_PLAN) {
            toggleShowBasicPlanModal();
            return;
        }
        walletModalTrigger(() => {
            setModalData({
                start: moment.utc().tz(defaultTimeZone).subtract(1, "day"),
                end: moment.utc().tz(defaultTimeZone),
                timezone: defaultTimeZone,
            });

            openModal({
                key: "download_data",
                modalType: "content",
                modalTitle: "Download LDSU Data",
                width: 635,
                modalContent: (openedModalData: any) => {
                    const { start, end, timezone } = openedModalData;
                    return (
                        <Form>
                            <p className="text-gray w-full text-left">
                                Time Zone
                            </p>
                            <Row className="modal-option p-3">
                                <Col>
                                    <Form.Group className="text-gray input-right-text modal-date-picker">
                                        <Form.Label className="text-left w-100">
                                            Select a time zone
                                        </Form.Label>
                                        <TimezoneDropdown
                                            value={openedModalData.timezone}
                                            onChange={(tz) =>
                                                handleChangeTimeZone(
                                                    tz,
                                                    openedModalData
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <p className="text-gray w-full text-left">
                                Date/time Range
                            </p>
                            <Row className="modal-option">
                                <Col>
                                    <DateTimeRangePicker
                                        inline={true}
                                        value={[start, end]}
                                        timezone={timezone}
                                        onChange={handleDateTimeRangeChange}
                                        onError={handleDateTimeRangeError}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    );
                },
                modalFooter: (openedModalData: any) => (
                    <div className="w-100 d-flex justify-content-between align-items-center m-0">
                        <span className="text-gray">
                            {"{length} LDSU(s) selected".fill(checkedLdsu)}
                        </span>
                        <Button
                            data-testid="ldsuDownloadData"
                            disabled={!!openedModalData.error}
                            onClick={() => {
                                downloadData(openedModalData);
                            }}
                        >
                            DOWNLOAD
                        </Button>
                    </div>
                ),
            });
        });
    };

    return (
        <ContentWrapper>
            <Row className="mt-4">
                <Col className="table-head-options d-flex">
                    <Button
                        variant="danger"
                        className="mini-button mr-2"
                        onClick={deleteSelectedLdsuHandler}
                        disabled={checkedLdsu.length === 0}
                        aria-label="batch delete"
                    >
                        <img src={dltWhite} alt="delete" />
                    </Button>
                    <Button
                        variant="default"
                        className="mini-button btn-mute add-prefix-suffix mr-2"
                        disabled={checkedLdsu.length === 0}
                        onClick={updatePrefixSuffixHandler}
                        aria-label="add prefix/suffix"
                    >
                        <span className="symbol">+</span>
                        {""}
                        Add Prefix/Suffix to Name
                    </Button>
                    <Dropdown className="dash-more">
                        <Dropdown.Toggle
                            variant="default"
                            className="mini-button btn-mute"
                            disabled={checkedLdsu.length === 0}
                            aria-label="more"
                        >
                            <span className="material-icons">more_horiz</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={changeReportRateHandler}
                                className="dropdown-item"
                                aria-label="change report rate"
                            >
                                Change Report Rate
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={downloadDataHandler}
                                className="dropdown-item"
                                aria-label="download data"
                            >
                                Download Data
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="table-head-options">
                    <div className="search float-right">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                aria-describedby="button-addon2"
                                aria-label="search ldsu"
                                onChange={(e) => searchLdsu(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Row className="cstm-table mt-4">
                <div className="table-head">
                    <Row className="no-checkbox">
                        <Col sm="1">
                            <Form.Check
                                type="checkbox"
                                id="ldsus-select-all"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCheckedLdsu(
                                            map(
                                                ldsuList,
                                                ({
                                                    UID,
                                                    name,
                                                }: {
                                                    UID: string;
                                                    name: string;
                                                }) => {
                                                    return {
                                                        UID,
                                                        name,
                                                    };
                                                }
                                            )
                                        );
                                    } else {
                                        setCheckedLdsu([]);
                                    }
                                }}
                                checked={
                                    checkedLdsu.length > 0 &&
                                    checkedLdsu.length === ldsuList.length
                                }
                                custom
                                label={<span className="mr-sm-2"></span>}
                                aria-label="select all ldsus"
                            />
                        </Col>
                        <Col>LDSU Name {sortIcon("name")}</Col>
                        <Col>UUID {sortIcon("UID")}</Col>
                        <Col>Status {sortIcon("status")}</Col>
                        <Col>Reporting Rate {sortIcon("INTVL")}</Col>
                        <Col>Termination Status {sortIcon("TERM")}</Col>
                        <Col sm="1">Action</Col>
                        <Col sm="1"></Col>
                    </Row>
                </div>
                {renderLdsus()}
            </Row>
            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalType={modalType}
                modalContent={modalContent}
                modalTitle={modalTitle}
                deleteFunction={deleteThisLdsu}
                okAction={() => {
                    setModalTitle("");
                    history.go(0);
                }}
            />
            {ActionModal}
            <BasicPlanModal
                showBasicPlanModal={showBasicPlanModal}
                toggleShowBasicPlanModal={toggleShowBasicPlanModal}
            />
        </ContentWrapper>
    );
};

export default LdsuList;
