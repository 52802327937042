import { useState, useEffect, useMemo, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

import "./style.css";
import { MemberDto, MemberStatus } from "types";
import { getMemberName } from "utils/organisationFunction";

type SelectMemberModalProps = {
    members: MemberDto[];
    values: string[];
    show: boolean;
    onClose: (members: string[]) => void;
};
const SelectMembersModal = (props: SelectMemberModalProps) => {
    const { members, values, show, onClose } = props;

    const [showModal, setShowModal] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [searchResults, setSearchResults] = useState<MemberDto[]>([]);
    const [searchText, setSearchText] = useState("");
    const searchRef = useRef<HTMLInputElement>({} as any);
    const validMembers = useMemo(() => {
        return members.filter(
            (member: any) => member.status === MemberStatus.Accepted
        );
    }, [members]);

    useEffect(() => {
        setShowModal(show);
        setSearchResults(validMembers);
        setSelectedMembers(values);
        setSearchText("");
        if(searchRef.current) searchRef.current.value = "";
    }, [show, values, validMembers]);

    useEffect(() => {
        setSearchResults(validMembers);
    }, [validMembers]);

    const handleSelectMembers = (e: any, memberId: string) => {
        const { checked } = e.target;
        const ids = checked
            ? [...selectedMembers, memberId]
            : selectedMembers.filter((id: string) => id !== memberId);
        setSelectedMembers(ids);
    };

    const handleAddClick = () => {
        setShowModal(false);
        const ids = selectedMembers.filter(
            (id: string) => !values.includes(id)
        );
        onClose(ids);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        onClose([]);
    };

    const handleSearchMembers = (e: any) => {
        const { value } = e.target;
        const _value = value.toLowerCase().replace(/\s{2,}/, ""); // Trim multi spaces

        setSearchText(value);
        if (_value === "") {
            setSearchResults(validMembers);
            return;
        }

        const results = validMembers.filter((member: any) =>
            getMemberName(member).toLowerCase().includes(_value)
        );

        setSearchResults(results);
    };

    const handleClearSearch = () => {
        setSearchResults(validMembers);
        setSearchText("");
        searchRef.current.value = "";
    };

    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            className="modal-add-members"
        >
            <Modal.Header>
                <Modal.Title>Select Members</Modal.Title>
                <Button
                    variant=""
                    className="close-button"
                    onClick={handleCloseModal}
                >
                    <span className="material-icons">close</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="search-members">
                    <Form.Control
                        type="text"
                        required
                        name="search-member"
                        aria-label="search-member"
                        placeholder="Search"
                        onChange={handleSearchMembers}
                        ref={searchRef}
                    />
                    {searchText && (
                        <Button
                            variant=""
                            className="clear-search"
                            onClick={handleClearSearch}
                        >
                            <span className="material-icons">close</span>
                        </Button>
                    )}
                </Form.Group>
                <ListGroup>
                    {searchResults.map((member, index: number) => (
                        <ListGroup.Item key={member.uuid}>
                            <Form.Check
                                type="checkbox"
                                id={`member-${member.uuid}`}
                                value={member.username}
                                label={getMemberName(member)}
                                custom
                                disabled={values.includes(member.username)}
                                checked={selectedMembers.includes(
                                    member.username
                                )}
                                onChange={(e) =>
                                    handleSelectMembers(e, member.username)
                                }
                            />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    className="btn-add"
                    onClick={handleAddClick}
                >
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectMembersModal;
