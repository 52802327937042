import { ACTION_FAIL_MESSAGE, BraintreeId, SubscriptionDict } from "constant";
import { useSubscriptionContext } from "context/subscriptionContext";
import { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import {
    getAllAddOns,
    updateSubscriptionAddonById,
} from "service/subscriptionService";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { isHttpSuccess } from "utils/functions";
import DefaultModal, { LoadingModal } from "components/modals/ModalTemplate";
import TokenQuantityField from "./TokenQuantityField";
import {
    SubscriptionPlanType,
    getSubscriptionPlanType,
} from "./CurrentPlanInformation";

const AddOnInformation = () => {
    // ========================= CONTEXT/STATES =========================
    const {
        subscription: {
            plan: { addons = [] } = {},
            uuid,
            status,
            customer: { is_prepaid = false, is_cancellable = true } = {},
        },
        setSubscription,
    }: any = useSubscriptionContext();
    const [purchasedQuantity, setPurchasedQuantity] = useState("1");
    const [modalShow, setModalShow] = useState(false);
    const [isActionPending, setIsActionPending] = useState(false);
    const [purchasedTokenAddon, setPurchasedTokenAddon] = useState<any>({});

    // ========================= USEEFFECT =========================
    useEffect(() => {
        const fetchAvailableAddons = async () => {
            const addOnRes: any = await getAllAddOns();

            if (isHttpSuccess(addOnRes.status)) {
                const purchasedTokenAddon = addOnRes.data.find(
                    ({
                        bt_id,
                        content: {
                            token: { type_ },
                        },
                    }: {
                        bt_id: string;
                        content: { token: { type_: string } };
                    }) => bt_id === "token_1m" && type_ === "PURCHASED"
                );

                setPurchasedTokenAddon(purchasedTokenAddon);
            }
        };
        fetchAvailableAddons();
    }, []);

    // ========================= FUNCTIONS =========================
    const addPurchasedTokenConfirmation = () => {
        setModalShow(true);
    };

    const subscriptionType: any = getSubscriptionPlanType({
        is_prepaid,
        is_cancellable,
    });

    const addPurchasedToken = async () => {
        setModalShow(false);
        setIsActionPending(true);

        const response = await updateSubscriptionAddonById({
            subscriptionId: uuid,
            addonId: purchasedTokenAddon.uuid,
            quantity: Number(purchasedQuantity),
            action: "add",
        });

        if (isHttpSuccess(response.status)) {
            setSubscription(response.data);
            showSuccessAlert({ message: `Add-on purchased successfully.` });
            setPurchasedQuantity("1");
            setIsActionPending(false);
        } else {
            showErrorAlert(getAPIError(response, ACTION_FAIL_MESSAGE));
            setIsActionPending(false);
        }
    };

    const showPurchaseComponent = () => {
        let showAddon: boolean;

        const purchasedTokenAddOn_ = addons.find(
            ({
                bt_id,
                content: {
                    token: { type_ },
                },
            }: {
                bt_id: string;
                content: { token: { type_: string } };
            }) => bt_id === "token_1m" && type_ === "PURCHASED"
        );

        if (
            purchasedTokenAddOn_ ||
            status !== "ACTIVE" ||
            [SubscriptionPlanType.BASIC, SubscriptionPlanType.PREPAID].includes(
                subscriptionType
            )
        ) {
            showAddon = false;
        } else {
            showAddon = true;
        }

        return showAddon;
    };

    const getPlanType = (bt_id: string) => {
        if (bt_id === BraintreeId.TOKEN_ADDON) return "addon";
        return subscriptionType;
    };

    return (
        <>
            {showPurchaseComponent() && (
                <Row className="manage-subscription-info mt-5">
                    <Col className="subscr-details" sm={12}>
                        <p>Add-on</p>
                    </Col>

                    <div className="purchase-tokens">
                        <div>
                            <p>
                                {
                                    SubscriptionDict[
                                        getPlanType(purchasedTokenAddon.bt_id)
                                    ]
                                }
                            </p>
                            <p className="period">
                                Monthly renewing additional tokens, starting
                                from next billing date.
                            </p>
                        </div>
                        <div className="d-flex align-items-center add-on-field">
                            <Form className="form d-flex align-items-center add-on-form flex-wrap">
                                <TokenQuantityField
                                    quantity={purchasedQuantity}
                                    setQuantity={setPurchasedQuantity}
                                />
                                <div aria-label="displayedAmount">
                                    USD{" "}
                                    {(
                                        Number(purchasedTokenAddon.amount) *
                                        Number(purchasedQuantity)
                                    ).toFixed(2)}
                                    /mo
                                </div>
                            </Form>
                            <Button
                                onClick={addPurchasedTokenConfirmation}
                                disabled={!purchasedQuantity}
                            >
                                BUY ADD-ON
                            </Button>
                        </div>
                    </div>
                </Row>
            )}

            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalIcon="help"
                modalTitle="Confirm"
                modalType="custom"
                modalContent={
                    <div>
                        <p>
                            Your added payment method will charge additional{" "}
                            <span className="font-weight-bold">
                                USD{" "}
                                {(
                                    Number(purchasedTokenAddon?.amount) *
                                    Number(purchasedQuantity)
                                ).toFixed(2)}{" "}
                                every month
                            </span>{" "}
                            together with your monthly subscription charge.
                        </p>
                        <p>
                            The new billing will start on the next billing date
                            and your account will be credited additional{" "}
                            <span className="font-weight-bold">
                                {" "}
                                {purchasedQuantity} x{" "}
                                {purchasedTokenAddon?.content?.token?.amount.toLocaleString()}{" "}
                                purchased tokens together with your subscription
                                plan from the next billing date onwards
                            </span>{" "}
                            until you cancel.
                        </p>
                        <p>Do you want to continue?</p>
                    </div>
                }
                modalButton={
                    <>
                        <Button
                            variant="secondary"
                            className="default-button-width"
                            onClick={() => {
                                setModalShow(false);
                            }}
                        >
                            NO
                        </Button>
                        <Button
                            variant="primary"
                            className="default-button-width"
                            onClick={() => {
                                setModalShow(false);
                                addPurchasedToken();
                            }}
                        >
                            YES
                        </Button>
                    </>
                }
            />

            <LoadingModal showModal={isActionPending} />
        </>
    );
};

export default AddOnInformation;
