import AxiosInstance from "./axiosInstance";
import { ResponseDto } from "types/common";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "../utils/functions";
import { apiFactory } from "generated";
import {
    Organisation,
    Member,
    Group,
    MemberGroup,
    OrganisationPatch,
    OrganisationPost,
} from "generated/models";
import { get } from "lodash";
import { MemberDto, MemberStatus } from "types";

export class OrganisationService {
    private orgClient = apiFactory.getOrganisationInstance(AxiosInstance);
    private invitationClient = apiFactory.getInvitationInstance(AxiosInstance);

    /**
     * Get the Organisations of current user
     * @returns { Array } List of Organisations
     */
    async getUserOrgs() {
        try {
            const res = await this.orgClient.getOrganisations();
            return createResponseStandard<Organisation[]>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async getOrgById(orgId: string) {
        try {
            const res = await this.orgClient.getOrganisationByUuid(orgId);
            return createResponseStandard<Organisation>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async createNewOrg(
        body: OrganisationPost
    ): Promise<ResponseDto<Organisation>> {
        try {
            const res = await this.orgClient.createOrganisation(body);
            return createResponseStandard<Organisation>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Get Organisation Members
     * @param {string} orgId - Org id
     * @returns { Array } List of Members
     */
    async getOrgMembers(orgId: string) {
        try {
            const res = await this.orgClient.getMembers(orgId);
            return createResponseStandard<Member[]>(res);
        } catch (error) {
            return createErrorResponseStandard<Member[]>(error);
        }
    }

    /**
     * Get Available Organisation Members
     * @param {string} orgId - Org id
     * @returns { Array } List of Members
     */
    async getAvailableOrgMembers(orgId: string): Promise<MemberDto[]> {
        const membersResponse = await this.getOrgMembers(orgId);
        const members = get(membersResponse, "data", []);

        const _members = toCamelCaseObject<MemberDto>(members);

        return _members.filter((m) =>
            [MemberStatus.Owner, MemberStatus.Accepted].includes(m.status)
        );
    }

    /**
     * Get Organisation Groups
     * @param {string} orgId - Org id
     * @returns { Array } List of Groups
     */
    async getOrgGroups(orgId: string) {
        try {
            const res = await this.orgClient.getMemberGroups(orgId);
            return createResponseStandard<Group[]>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Get Organisation Roles
     * @param {string} orgId - Org id
     * @returns { Array } List of Roles
     */
    async getOrgRoles(orgId: string) {
        try {
            const res = await this.orgClient.getRoles(orgId);

            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async inviteMembers(orgId: string, emails: string[]): Promise<ResponseDto> {
        try {
            const res = await this.orgClient.editMembers(orgId, {
                add_members: emails,
            });
            return createResponseStandard<Member[]>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Delete member from The Organisation
     * @returns {Promise }
     */

    async acceptInvitation(
        memberId: string,
        accept: boolean
    ): Promise<ResponseDto> {
        try {
            const res = await this.invitationClient.responseInvitationByUuid(
                memberId,
                {
                    accept,
                }
            );
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Delete member from The Organisation
     * @returns {Promise }
     */

    async deleteMembers(
        orgId: string,
        members: string[]
    ): Promise<ResponseDto> {
        try {
            const res = await this.orgClient.editMembers(orgId, {
                remove_members: members,
            });
            return createResponseStandard<boolean>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async getUserDetailGroups(orgId: string): Promise<ResponseDto> {
        try {
            const res = await this.orgClient.getMemberGroups(orgId);
            return createResponseStandard<MemberGroup[]>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async getMember(orgId: string, memberId: string) {
        try {
            const res = await this.orgClient.getMemberByUuid(orgId, memberId);
            return createResponseStandard<Member>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async deleteOrganisation(orgId: string) {
        try {
            const res = await this.orgClient.deleteOrganisationByUuid(orgId);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async updateOrganisation(orgId: string, body: OrganisationPatch) {
        try {
            const res = await this.orgClient.updateOrganisationByUuid(
                orgId,
                body
            );
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async leaveOrganisation(orgId: string) {
        try {
            const res = await this.orgClient.exitMember(orgId);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }
}

const organizationService = new OrganisationService();

export default organizationService;
