import { Col, Form, Row } from "react-bootstrap";

type EventVariableFormInputProps = {
    name: string;
    label: string;
    value: string;
    onChange?: Function;
    isInvalid?: boolean;
    invalidMessage?: string;
    note?: string;
    disabled?: boolean;
};

const EventVariableFormInput = ({
    name,
    label,
    value,
    onChange,
    isInvalid = false,
    invalidMessage = "",
    note = "",
    disabled = false,
}: EventVariableFormInputProps) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={name}>
            <Form.Label column sm="2">
                {label}
            </Form.Label>
            <Col sm="10">
                <Form.Control
                    className={disabled ? "disabled-input" : ""}
                    type="text"
                    required
                    name={name}
                    aria-label={name}
                    value={value}
                    onChange={(e) => onChange?.(e.target.value)}
                    isInvalid={isInvalid}
                    disabled={disabled}
                ></Form.Control>
                {note && (
                    <Form.Text className="wrap-around-hint">{note}</Form.Text>
                )}
                <Form.Control.Feedback type="invalid">
                    {invalidMessage}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
};

export default EventVariableFormInput;
