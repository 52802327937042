import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import { ReactComponent as TemperatureIcon } from "assets/svg/sensor/temperature.svg";
import { ReactComponent as HumidityIcon } from "assets/svg/sensor/humidity.svg";
import { ReactComponent as CO2Icon } from "assets/svg/sensor/CO2.svg";
import { ReactComponent as ECO2Icon } from "assets/svg/sensor/eCO2.svg";
import { ReactComponent as AmbientIcon } from "assets/svg/sensor/ambient-light.svg";
import { ReactComponent as MotionIcon } from "assets/svg/sensor/motion.svg";
import { ReactComponent as MoistureIcon } from "assets/svg/sensor/moisture.svg";
import { ReactComponent as PHIcon } from "assets/svg/sensor/ph.svg";
import { ReactComponent as ECIcon } from "assets/svg/sensor/EC.svg";
import { ReactComponent as DOIcon } from "assets/svg/sensor/DO.svg";
import { ReactComponent as SalinityIcon } from "assets/svg/sensor/salinity.svg";
import { ReactComponent as ORPIcon } from "assets/svg/sensor/ORP.svg";
import { ReactComponent as CurrentIcon } from "assets/svg/sensor/current.svg";
import { ReactComponent as WaterLevelIcon } from "assets/svg/sensor/water-level.svg";
import { ReactComponent as GenericAnalogIcon } from "assets/svg/sensor/generic-analog-input.svg";
import { ReactComponent as GenericDigitalIcon } from "assets/svg/sensor/generic-digital-input.svg";
import { ReactComponent as WindVaneIcon } from "assets/svg/sensor/wind-vane.svg";
import { ReactComponent as WindSpeedIcon } from "assets/svg/sensor/wind-speed-anemometer.svg";
import { ReactComponent as RainGaugeIcon } from "assets/svg/sensor/rain-gauge.svg";
import { ReactComponent as VOCIcon } from "assets/svg/sensor/VOC.svg";
import { ReactComponent as IAQIcon } from "assets/svg/sensor/IAQ.svg";
import { ReactComponent as PressureIcon } from "assets/svg/sensor/pressure.svg";
import { ReactComponent as NitrogenIcon } from "assets/svg/sensor/nitrogen.svg";
import { ReactComponent as PhosphorusIcon } from "assets/svg/sensor/phosphorus.svg";
import { ReactComponent as PotassiumIcon } from "assets/svg/sensor/potassium.svg";
import { ReactComponent as AmmoniaIcon } from "assets/svg/sensor/ammonia.svg";
import { ReactComponent as LeafVapour } from "assets/svg/sensor/leaf-vapour.svg";
import { ReactComponent as AirVapour } from "assets/svg/sensor/air-vapour.svg";
import { ReactComponent as WaterDetectionIcon } from "assets/svg/sensor/water-detection.svg";

type SensorIconProps = HTMLAttributes<HTMLDivElement> &
    JSX.IntrinsicAttributes & {
        sensorType: string;
    };

const SensorIconMap: Record<string, JSX.Element> = {
    "Temperature sensor": <TemperatureIcon />,
    "Humidity sensor": <HumidityIcon />,
    "CO2 sensor": <CO2Icon />,
    "Ambient light sensor": <AmbientIcon />,
    "Motion sensor": <MotionIcon />,
    "Moisture sensor": <MoistureIcon />,
    "pH sensor": <PHIcon />,
    "EC sensor": <ECIcon />,
    "DO sensor": <DOIcon />,
    "Salinity sensor": <SalinityIcon />,
    "ORP sensor": <ORPIcon />,
    "Current Sensor": <CurrentIcon />,
    "Water level sensor": <WaterLevelIcon />,
    "Generic Analog Input": <GenericAnalogIcon />,
    "Water Level": <WaterLevelIcon />,
    "Wind Vane": <WindVaneIcon />,
    "Wind Speed": <WindSpeedIcon />,
    "Air Temperature": <TemperatureIcon />,
    "Generic Digital Input": <GenericDigitalIcon />,
    "Anemometer Wind Speed": <WindSpeedIcon />,
    "Rainfall Gauge": <RainGaugeIcon />,
    "eCO2 sensor": <ECO2Icon />,
    "VOC sensor": <VOCIcon />,
    "IAQ sensor": <IAQIcon />,
    "Pressure sensor": <PressureIcon />,
    "Nitrogen sensor": <NitrogenIcon />,
    "Phosphorus sensor": <PhosphorusIcon />,
    "Potassium sensor": <PotassiumIcon />,
    "Water detection sensor": <WaterDetectionIcon />,
    "Ammonia sensor": <AmmoniaIcon />,
    "Air Vapour Pressure Deficit": <AirVapour />,
    "Leaf Vapour Pressure Deficit": <LeafVapour />,
};

const SensorIconLowerMap: Record<string, any> = {};
Object.keys(SensorIconMap).forEach((key: string) => SensorIconLowerMap[key.toLowerCase()] =  SensorIconMap[key]);

const Icon = styled.span`
    width: 37px;
    height: 37px;
    padding: 0;

    svg {
        width: 35px;
    }

    @media (max-width: 992px) {
        svg {
            width: 30px;
        }
    }
`;

const SensorIcon = ({ sensorType, ...restProps }: SensorIconProps) => {
    return <Icon {...restProps}>{SensorIconLowerMap[sensorType.toLowerCase()]}</Icon>;
};

export default SensorIcon;
