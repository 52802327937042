import { CheckoutType, SUBSCRIPTION_DATE_FORMAT } from "constant";
import { Form } from "react-bootstrap";
import { subscriptionPeriodDetail } from "utils/subscriptionFunctions";
import TokenQuantityField from "./TokenQuantityField";
import moment from "moment";
import { SubscriptionPlanType } from "./CurrentPlanInformation";

type PreviewItemT = {
    checkoutType: string;
    monthlyChargePreview: string;
    billingStartDate: string;
    customer: {
        billing_cycles: number;
        id: string;
        prn: string;
        uuid: string;
        verified: boolean;
    };
    bundlePrice: string;
    bundleTokenQty: number;
    bundleQty: string;
    subscriptionType:
        | SubscriptionPlanType.MONTHLY
        | SubscriptionPlanType.PREPAID
        | SubscriptionPlanType.BASIC;
    setBundleQty: () => void;
    customOnChange?: ((e: any) => void) | null;
};

const subscriptionPreviewItems = ({
    monthlyChargePreview,
    billingStartDate,
    customer,
    subscriptionType,
}: Pick<
    PreviewItemT,
    | "monthlyChargePreview"
    | "billingStartDate"
    | "customer"
    | "subscriptionType"
>) => {
    const items = {
        monthly: [
            { label: "Subscription Type:", value: "Monthly Subscription" },
            {
                label: "Monthly Amount:",
                value: `USD ${monthlyChargePreview}/mo`,
            },
            {
                label: "Subscription Period:",
                value: subscriptionPeriodDetail(
                    billingStartDate,
                    customer.billing_cycles
                ),
            },
            {
                label: "Billing Starts From:",
                value: `${billingStartDate} (Today)`,
            },
        ],
        prepaid: [
            { label: "Subscription Type:", value: "Prepaid Subscription" },
            {
                label: "Payment Status:",
                value: `Already Paid`,
            },
            {
                label: "Subscription Period:",
                value: subscriptionPeriodDetail(
                    billingStartDate,
                    customer.billing_cycles
                ),
            },
        ],
        basic: [
            { label: "Subscription Type:", value: "Basic Subscription" },
            {
                label: "Amount to Pay:",
                value: `USD ${monthlyChargePreview}`,
            },
            {
                label: "Subscription Period:",
                value: subscriptionPeriodDetail(
                    billingStartDate,
                    customer.billing_cycles
                ),
            },
        ],
    };

    return items[subscriptionType];
};

const PreviewItem: any = ({
    checkoutType,
    monthlyChargePreview,
    billingStartDate,
    customer,
    bundlePrice,
    bundleTokenQty,
    bundleQty,
    subscriptionType,
    setBundleQty,
    customOnChange,
}: PreviewItemT) => {
    if (checkoutType === CheckoutType.PLAN) {
        if (!customer.verified) return null;
        return (
            <div className="monthly-charge">
                {subscriptionPreviewItems({
                    monthlyChargePreview,
                    billingStartDate,
                    customer,
                    subscriptionType,
                })?.map(({ label, value }) => {
                    return (
                        <div
                            key={label}
                            className="d-flex justify-content-between mb-2"
                        >
                            <h5>{label}</h5>
                            <div>
                                <h5 className="text-end">{value}</h5>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    } else if (checkoutType === CheckoutType.ADDON) {
        return (
            <>
                <div className="monthly-charge">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h6 aria-label="purchased-token-preview">
                                Bundle of 1,000,000 Purchased Tokens
                            </h6>
                            <p>No expiry</p>
                        </div>
                        <div>
                            <h5>USD {bundlePrice}</h5>
                        </div>
                    </div>
                </div>
                <div className="qty qty-checkout">
                    <Form className="form d-flex flex-wrap">
                        <TokenQuantityField
                            quantity={bundleQty}
                            setQuantity={setBundleQty}
                            customOnChange={customOnChange ?? null}
                        />
                    </Form>
                    <div className="total">
                        <h5 aria-label="purchased-token-displayed-quantity">
                            {Number(
                                Number(bundleQty) * Number(bundleTokenQty)
                            ).toLocaleString()}{" "}
                            Tokens
                        </h5>
                    </div>
                </div>
            </>
        );
    }
};

export const PreviewSubscriptionBillingInfo: any = ({
    billingStartDate,
    customer,
    subscriptionType,
}: Pick<
    PreviewItemT,
    "billingStartDate" | "customer" | "subscriptionType"
>) => {
    if (subscriptionType === SubscriptionPlanType.MONTHLY) {
        return (
            <>
                <span className="font-weight-400 text-primary-blue-4">
                    Your subscription starts on {billingStartDate} (today) and
                    will renew automatically every month, until{" "}
                    {moment()
                        .add(customer.billing_cycles, "M")
                        .subtract(1, "days")
                        .format(SUBSCRIPTION_DATE_FORMAT)}{" "}
                    (subscription period end date) or unless cancelled.
                </span>
                <p className="mt-3">
                    Payments won’t be refunded for partial billing periods.
                    Cancel anytime.
                </p>
            </>
        );
    } else if (subscriptionType === SubscriptionPlanType.PREPAID) {
        return (
            <div className="font-weight-400 text-primary-blue-4">
                Your subscription starts on {billingStartDate} (today) and is
                prepaid for a period of {customer.billing_cycles} month
                {customer.billing_cycles > 1 && "s"}, ending on{" "}
                {moment()
                    .add(customer.billing_cycles, "M")
                    .subtract(1, "days")
                    .format(SUBSCRIPTION_DATE_FORMAT)}
                . You will not be billed during this period. The subscription
                will need to be renewed at the end of the prepaid period unless
                cancelled.
            </div>
        );
    } else if (subscriptionType === SubscriptionPlanType.BASIC) {
        return (
            <div className="font-weight-400 text-primary-blue-4">
                Your subscription starts on {billingStartDate} (today) and stops
                on{" "}
                {moment()
                    .add(customer.billing_cycles, "M")
                    .subtract(1, "days")
                    .format(SUBSCRIPTION_DATE_FORMAT)}{" "}
                (subscription period end date). Cancel anytime.
            </div>
        );
    }
    return "";
};

export default PreviewItem;
