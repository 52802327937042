import { useEffect, useState } from "react";
import { get, size, map, groupBy, cloneDeep } from "lodash";

const usePanelConfiguration = ({ panelData, deviceList }: any) => {
    const [defaultValue, setDefaultValue] = useState<any>({
        multipleChartType: "same",
        chart_name: "",
        data: {},
    });

    useEffect(() => {
        const attributeList = get(panelData, "attributes", []).map(
            (item: any) => {
                const device = deviceList?.find(
                    (device: any) =>
                        device.device_id === item.device_id + "/" + item.said
                );

                return {
                    key: device?.device_id,
                    ...device,
                    ...item,
                };
            }
        );

        const deviceTypes = groupBy(attributeList, "name");
        const multipleChartType = size(deviceTypes) > 1 ? "different" : "same";
        const deviceData = map(deviceTypes, (value, key) => {
            const uniqueDevices = value.reduce((acc, curr): any => {
                const key = `${curr.device_id}/${curr.said}`;
                if (key) {
                    acc[`${key}`] = curr;
                }
                return acc;
            }, {});
            return {
                sensor_type: key !== "undefined" ? key : value[0].name,
                devices: { ...uniqueDevices },
            };
        });

        const allDeviceData = cloneDeep(deviceData).reduce(
            (acc: any, curr: any) => {
                const checkIfSensorTypeExist = acc.find(
                    ({ sensor_type }: { sensor_type: string }) =>
                        sensor_type === curr.sensor_type
                );
                if (!checkIfSensorTypeExist) {
                    acc.push(curr);
                } else {
                    const undefinedDevice = curr.devices;
                    checkIfSensorTypeExist.devices = {
                        ...checkIfSensorTypeExist.devices,
                        ...undefinedDevice,
                    };
                }
                return acc;
            },
            []
        );

        setDefaultValue({
            multipleChartType,
            chart_name: get(panelData, "name", ""),
            data: { ...allDeviceData },
        });
    }, [deviceList, panelData]);

    return { defaultValue };
};

export default usePanelConfiguration;
