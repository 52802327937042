import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import EventVariableService from "service/eventVariableService";
import {
    TypeOption,
    Direction,
    Patterns,
    MAX_TERMINAL_VALUE,
    MIN_TERMINAL_VALUE,
    OVERFLOW_DEFAULT,
    STEP_DEFAULT,
    HttpStatus,
} from "constant";
import { isHttpSuccess, orgId } from "utils/functions";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import {
    getInitialValue,
    validateEventVariableValue,
    validateStepSizeValue,
    validateTerminalValue,
} from "utils/eventFunctions";
import { VariablePost } from "generated/models";
import EventVariableForm from "components/events/EventVariableForm";

const defaultEventVariable = {
    [TypeOption.Boolean]: "true",
    [TypeOption.Integer]: "0",
    [TypeOption.Float]: "0.00",
    [TypeOption.Timer]: "00:00:00",
    [TypeOption.Counter]: "0",
};

const AddEventVariable = () => {
    const history = useHistory();
    const [eventVariableName, setEventVariableName] = useState<string>("");
    const [eventVariableType, setEventVariableType] = useState<TypeOption>(
        TypeOption.Boolean
    );
    const [eventVariableValue, setEventVariableValue] =
        useState(defaultEventVariable);
    const [direction, setDirection] = useState<Direction>(Direction.Up);
    const [overflow, setOverflow] = useState(OVERFLOW_DEFAULT);
    const [terminal, setTerminal] = useState(MAX_TERMINAL_VALUE);
    const [step, setStep] = useState(STEP_DEFAULT);
    const [eventVariableValueError, setEventVariableValueError] = useState("");
    const [terminalValueError, setTerminalValueError] = useState("");
    const [stepSizeValueError, setStepSizeValueError] = useState("");
    const [isAdding, setIsAdding] = useState(false);

    const handleEventVariableName = (value: string) => {
        setEventVariableName(value);
    };

    const handleEventVariableType = (value: TypeOption) => {
        setEventVariableValueError("");
        setTerminalValueError("");
        setStepSizeValueError("");
        setEventVariableType(value);
        setEventVariableValue(defaultEventVariable);
    };

    const handleEventVariableValue = (value: string) => {
        setEventVariableValue({
            ...eventVariableValue,
            [eventVariableType]: value,
        });

        const errorMsg = validateEventVariableValue(eventVariableType, value);
        setEventVariableValueError(errorMsg);
        if (eventVariableType === TypeOption.Counter) {
            const terminalErrorMsg = validateTerminalValue(
                terminal,
                value,
                direction
            );
            setTerminalValueError(terminalErrorMsg);

            setStepSizeValueError(validateStepSizeValue(step, value, terminal));
        }
    };

    const handleDirection = (value: Direction) => {
        const directionUpdated = Number(value);
        const initialValue =
            directionUpdated === Direction.Up
                ? String(Number(MIN_TERMINAL_VALUE) - 1)
                : MAX_TERMINAL_VALUE;
        const terminalValue =
            directionUpdated === Direction.Up
                ? MAX_TERMINAL_VALUE
                : MIN_TERMINAL_VALUE;
        setEventVariableValue({
            ...eventVariableValue,
            [eventVariableType]: initialValue,
        });
        setTerminal(terminalValue);
        setDirection(directionUpdated);
        setOverflow(OVERFLOW_DEFAULT);
        setStep(STEP_DEFAULT);
        setEventVariableValueError("");
        setTerminalValueError("");
        setStepSizeValueError("");
    };

    const addNewEventVariable = async (e: any) => {
        e.preventDefault();
        setIsAdding(true);
        const initialValue = getInitialValue(
            eventVariableType,
            eventVariableValue[eventVariableType]
        );

        const payload: VariablePost = {
            organisation_uuid: orgId(),
            name: eventVariableName.trim(),
            type_: eventVariableType,
            initial: initialValue,
        };

        if (eventVariableType === TypeOption.Counter) {
            payload.attributes = {
                terminal: Number(terminal),
                step: Number(step),
                direction: Boolean(direction),
                overflow,
            };
        }

        if (
            [TypeOption.Integer, TypeOption.Float, TypeOption.Counter].includes(
                eventVariableType
            )
        ) {
            payload.initial = Number(payload.initial).toString();
        }

        const addedResp = await EventVariableService.createEventVariable(
            payload
        );

        if (isHttpSuccess(addedResp.status)) {
            history.push(`/events?activeKey=event-variables`);
            showSuccessAlert({
                message: "Event variable has been created successfully.",
            });
        } else {
            const alertOption = {
                ...getAPIError(
                    addedResp,
                    "Unable to create Event Variable. Please try again."
                ),
            };

            if (addedResp.status === HttpStatus.BAD_REQUEST) {
                alertOption.title = "Invalid Value";
            }
            showErrorAlert(alertOption);
        }

        setEventVariableValueError("");
        setTerminalValueError("");
        setStepSizeValueError("");
        setIsAdding(false);
    };

    const handleTerminalValue = (value: string) => {
        setTerminal(value);
        const errorMsg = validateTerminalValue(
            value,
            eventVariableValue[eventVariableType],
            direction
        );
        setTerminalValueError(errorMsg);

        setStepSizeValueError(
            validateStepSizeValue(
                step,
                eventVariableValue[eventVariableType],
                value
            )
        );
    };

    const handleStepSize = (value: string) => {
        setStep(value);
        setStepSizeValueError(
            validateStepSizeValue(
                value,
                eventVariableValue[eventVariableType],
                terminal
            )
        );
    };

    return (
        <ContentWrapper>
            <div className="page-content">
                <Container>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title">
                                Create Event Variable
                            </h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item active>
                                    <Link to="/events">Event Management</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <Link to="/events?activeKey=event-variables">
                                        Event Variables
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Create Event Variable
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col sm="12">
                            <Form>
                                <div className="form-box">
                                    <EventVariableForm
                                        eventVariableName={eventVariableName}
                                        onChangeEventVariableName={
                                            handleEventVariableName
                                        }
                                        eventVariableType={eventVariableType}
                                        onChangeEventVariableType={
                                            handleEventVariableType
                                        }
                                        initialValue={
                                            eventVariableValue[
                                                eventVariableType
                                            ]
                                        }
                                        onChangeInitialValue={
                                            handleEventVariableValue
                                        }
                                        eventVariableValueError={
                                            eventVariableValueError
                                        }
                                        direction={direction}
                                        onChangeDirection={handleDirection}
                                        terminalValue={terminal}
                                        onChangeTerminalValue={
                                            handleTerminalValue
                                        }
                                        terminalValueError={terminalValueError}
                                        stepValue={step}
                                        onChangeStepValue={handleStepSize}
                                        overflowValue={overflow}
                                        setOverflowValue={setOverflow}
                                        stepSizeValueError={stepSizeValueError}
                                    />
                                </div>

                                <Button
                                    variant="secondary"
                                    className="mt-4 mr-2"
                                    onClick={() => {
                                        history.push(
                                            `/events?activeKey=event-variables`
                                        );
                                    }}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    variant="primary"
                                    className="mt-4"
                                    onClick={addNewEventVariable}
                                    disabled={
                                        !!eventVariableValueError ||
                                        !!terminalValueError ||
                                        !!stepSizeValueError ||
                                        !Patterns.eventVariableNamePattern.test(
                                            eventVariableName
                                        ) ||
                                        isAdding
                                    }
                                >
                                    SAVE
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default AddEventVariable;
