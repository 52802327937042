import { useState } from "react";
import { Col, Button, Row } from "react-bootstrap";
import { EditPaymentModal } from "components/modals/EditPaymentModal";
import { useSubscriptionContext } from "context/subscriptionContext";
import {
    SubscriptionPlanType,
    getSubscriptionPlanType,
} from "./CurrentPlanInformation";
import { SubscriptionStatus } from "constant";

const BraintreeInformation = () => {
    const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);

    const {
        subscription: {
            status,
            payment_card_id,
            payment_card_type,
            customer: { is_prepaid = false, is_cancellable = true } = {},
        },
    }: any = useSubscriptionContext();

    const subscriptionType: any = getSubscriptionPlanType({
        is_prepaid,
        is_cancellable,
    });

    const handleCloseEditPaymentModal = () => {
        setShowEditPaymentModal(false);
    };

    return (
        <>
            {SubscriptionPlanType.MONTHLY === subscriptionType && (
                <Row className="manage-subscription-info mt-5">
                    {" "}
                    <Col className="subscr-details" xs={6}>
                        <p>Payment Information</p>
                        <p className="period">
                            Edit payment method for subscription
                        </p>
                    </Col>
                    <Col className="edit-subscription" xs={6}>
                        <Button
                            onClick={() => {
                                setShowEditPaymentModal(true);
                            }}
                            hidden={
                                ![
                                    SubscriptionStatus.ACTIVE,
                                    SubscriptionStatus.PAST_DUE,
                                    SubscriptionStatus.PENDING,
                                ].includes(status)
                            }
                        >
                            EDIT PAYMENT
                        </Button>
                    </Col>
                    {showEditPaymentModal && (
                        <EditPaymentModal
                            show={showEditPaymentModal}
                            setShow={setShowEditPaymentModal}
                            onClose={handleCloseEditPaymentModal}
                        />
                    )}
                    <Col sm={12}>
                        Payment method used for current subscription
                    </Col>
                    <Col className="mt-2">
                        <div className="payment-card-info">
                            <div>
                                <div className="payment-card-id">
                                    {payment_card_type === "PayPal"
                                        ? ""
                                        : "Ending in "}
                                    {payment_card_id}
                                </div>
                                <div className="payment-card-type">
                                    {payment_card_type}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default BraintreeInformation;
