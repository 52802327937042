import {
    Direction,
    INVALID_EVENT_VARIABLE_NAME_MESSAGE,
    Patterns,
    TypeOption,
} from "constant";
import EventVariableFormInput from "components/events/EventVariableFormInput";
import EventVariableFormSelect from "components/events/EventVariableFormSelect";
import { Col, Form, Row } from "react-bootstrap";

type EventVariableFormProps = {
    eventVariableName: string;
    onChangeEventVariableName: Function;
    eventVariableType: TypeOption;
    onChangeEventVariableType?: Function;
    initialValue: string;
    onChangeInitialValue: Function;
    eventVariableValueError: string;
    direction: Direction;
    onChangeDirection: Function;
    terminalValue: string;
    onChangeTerminalValue: Function;
    terminalValueError: string;
    stepValue: string;
    onChangeStepValue: Function;
    overflowValue: boolean;
    setOverflowValue: Function;
    isUpdateForm?: boolean;
    stepSizeValueError: string;
};

const EventVariableForm = ({
    eventVariableName,
    onChangeEventVariableName,
    eventVariableType,
    onChangeEventVariableType,
    initialValue,
    onChangeInitialValue,
    eventVariableValueError,
    direction,
    onChangeDirection,
    terminalValue,
    onChangeTerminalValue,
    terminalValueError,
    stepValue,
    onChangeStepValue,
    overflowValue,
    setOverflowValue,
    isUpdateForm = false,
    stepSizeValueError,
}: EventVariableFormProps) => {
    const getEventVariableTypeOptions = () => {
        return Object.entries(TypeOption).map(([key, value]) => ({
            key: key,
            value: value,
            label: key,
        }));
    };

    const getBoolOptions = () => {
        return [
            {
                key: "true",
                value: "true",
                label: "True",
            },
            {
                key: "false",
                value: "false",
                label: "False",
            },
        ];
    };

    const getDirectionOptions = () => {
        return Object.entries(Direction)
            .filter(([key]) => !key.isNumeric())
            .map(([key, value]) => ({
                key: key,
                value: value,
                label: `${key} count`,
            }));
    };

    return (
        <>
            <EventVariableFormInput
                name="event-variable-name"
                label="Event Variable Name"
                value={eventVariableName}
                onChange={onChangeEventVariableName}
                isInvalid={
                    !Patterns.eventVariableNamePattern.test(
                        eventVariableName
                    )
                }
                invalidMessage={INVALID_EVENT_VARIABLE_NAME_MESSAGE}
            />

            {isUpdateForm ? (
                <EventVariableFormInput
                    name="event-variable-type"
                    label="Type"
                    value={eventVariableType.toLowerCase().capitalize()}
                    disabled={isUpdateForm}
                />
            ) : (
                <EventVariableFormSelect
                    name="event-variable-type"
                    label="Type"
                    value={eventVariableType}
                    onChange={(type: TypeOption) =>
                        onChangeEventVariableType?.(type)
                    }
                    options={getEventVariableTypeOptions()}
                />
            )}

            {eventVariableType === TypeOption.Boolean && (
                <EventVariableFormSelect
                    name="event-variable-value"
                    label="Value"
                    value={initialValue}
                    onChange={onChangeInitialValue}
                    options={getBoolOptions()}
                />
            )}

            {[TypeOption.Integer, TypeOption.Float, TypeOption.Timer].includes(
                eventVariableType
            ) && (
                <EventVariableFormInput
                    name="event-variable-value"
                    label="Value"
                    value={initialValue}
                    onChange={onChangeInitialValue}
                    isInvalid={!!eventVariableValueError}
                    invalidMessage={eventVariableValueError}
                />
            )}

            {TypeOption.Counter === eventVariableType && (
                <>
                    <EventVariableFormSelect
                        name="direction"
                        label="Count"
                        value={direction}
                        onChange={onChangeDirection}
                        options={getDirectionOptions()}
                    />

                    <EventVariableFormInput
                        name="event-variable-value"
                        label="Initial Value"
                        value={initialValue}
                        onChange={onChangeInitialValue}
                        isInvalid={!!eventVariableValueError}
                        invalidMessage={eventVariableValueError}
                    />

                    <EventVariableFormInput
                        name="terminal-value"
                        label="Terminal Value"
                        value={terminalValue}
                        onChange={onChangeTerminalValue}
                        isInvalid={!!terminalValueError}
                        invalidMessage={terminalValueError}
                    />

                    <EventVariableFormInput
                        name="step-value"
                        label="Step size"
                        value={stepValue}
                        onChange={onChangeStepValue}
                        note="The absolute difference between the Initial Value and Terminal Value must be a multiple of Step size."
                        isInvalid={!!stepSizeValueError}
                        invalidMessage={stepSizeValueError}
                    />

                    <Form.Group as={Row} controlId="overflow">
                        <Form.Label column sm="2">
                            Wraparound
                        </Form.Label>
                        <Col sm="10" className="wrap-around-col">
                            <Form.Check
                                custom
                                name="wraparound"
                                aria-label="wraparound"
                                type="switch"
                                checked={overflowValue}
                                onChange={() =>
                                    setOverflowValue(!overflowValue)
                                }
                                label={
                                    <span className="wrap-around-hint">
                                        (If enabled : Terminal value + Step size
                                        = Initial Value, if disabled: Terminal
                                        value + Step size = Terminal value)
                                    </span>
                                }
                            />
                        </Col>
                    </Form.Group>
                </>
            )}
        </>
    );
};

export default EventVariableForm;
