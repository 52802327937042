import { useEffect, useState, ChangeEvent } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import ContentWrapper from "components/content-wrapper/ContentWrapper";
import {
    getRegistryGroups,
    addRegistryGroup,
    addRegistryToGroup,
} from "service/gatewayService";
import { Patterns, GATEWAY_GROUP_NAME_MESSAGE, UNGROUPED_NAME } from "constant";
import SelectGatewayModal from "components/modals/SelectGatewayModal";
import { isHttpSuccess, isValidText } from "utils/functions";
import { showSuccessAlert, showErrorAlert } from "utils/alert";
import { Registry, Group } from "generated/models";
import { ResponseDto } from "types";

const AddNewGWGroup = () => {
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [gwGroupName, setGwGroupName] = useState<string>("");
    const [isShowSelectGW, setIsShowSelectGW] = useState<boolean>(false);
    const [standaloneGateways, setStandaloneGateWays] = useState<Registry[]>(
        []
    );
    const [confirmedGWs, setConfirmedGWs] = useState<string[]>([]);
    const [searchedConfirmedGWsText, setSearchedConfirmedGWsText] =
        useState<string>("");
    const [ungrouped, setUngrouped] = useState<Group[]>([]);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const { status, data = [] } = await getRegistryGroups();

            if (isHttpSuccess(status)) {
                const standaloneGroup = data.filter(
                    (item: { name: string }) => item.name === UNGROUPED_NAME
                );
                setUngrouped(standaloneGroup);
                if (standaloneGroup.length > 0) {
                    const { gateways } = standaloneGroup[0];
                    setStandaloneGateWays(gateways);
                }
            }
        })();
    }, []);

    const handleGroupCreationSuccess = () => {
        showSuccessAlert({
            message: "Device group added successfully.",
        });
        history.push("gateways-group");
    };

    const addNewGWGroup = async () => {
        setIsSaving(true);
        const gwGroupCreated: ResponseDto = await addRegistryGroup(
            gwGroupName.trim()
        );
        if (isHttpSuccess(gwGroupCreated.status)) {
            if (confirmedGWs.length > 0) {
                const gwGroupUpdated: ResponseDto = await addRegistryToGroup(
                    gwGroupCreated.data?.uuid,
                    confirmedGWs
                );
                if (isHttpSuccess(gwGroupUpdated.status)) {
                    handleGroupCreationSuccess();
                } else {
                    showErrorAlert({
                        message:
                            gwGroupUpdated.message ??
                            "Unable to add Device to Device Group. Please try again.",
                    });
                }
            } else {
                handleGroupCreationSuccess();
            }
        } else {
            showErrorAlert({
                message:
                    gwGroupCreated.message ??
                    "Unable to create new Device Group. Please try again.",
            });
        }
        setIsSaving(false);
    };

    const goBackToGatewayGroupPage = () => {
        history.push("gateways-group");
    };

    const handleCloseModal = () => {
        setIsShowSelectGW(false);
    };

    const handleOpenModal = () => {
        setIsShowSelectGW(true);
    };

    const handleAddGWsToGroup = (listSelectedGWs: string[]) => {
        setConfirmedGWs(listSelectedGWs);
        handleCloseModal();
    };

    const handleRemoveConfirmedGWs = (gatewayId: string) => {
        setConfirmedGWs(
            confirmedGWs.filter(
                (confirmedGWId: string) => confirmedGWId !== gatewayId
            )
        );
    };

    const handleChangeGroupName = (e: ChangeEvent<HTMLInputElement>) => {
        setGwGroupName(e.target.value);
    };

    const renderConfirmedGWs = () => {
        const standaloneGatewayMap = new Map<string, Registry>(
            standaloneGateways.map((standaloneGateway: Registry) => [
                standaloneGateway.gateway_id,
                standaloneGateway,
            ])
        );
        const listConfirmedGWs = confirmedGWs
            .map((gwId: string) => {
                const {
                    name,
                    gateway_id,
                }: { name: string; gateway_id: string } =
                    standaloneGatewayMap.get(gwId) ?? {
                        name: "",
                        gateway_id: "",
                    };
                if (
                    searchedConfirmedGWsText &&
                    !name
                        .toLowerCase()
                        .includes(searchedConfirmedGWsText.toLowerCase())
                ) {
                    return undefined;
                } else {
                    return (
                        <div className="select-items mb-2" key={gateway_id}>
                            <span
                                className="material-icons"
                                onClick={() =>
                                    handleRemoveConfirmedGWs(gateway_id)
                                }
                                onKeyDown={() =>
                                    handleRemoveConfirmedGWs(gateway_id)
                                }
                            >
                                close
                            </span>
                            <div className="select-items-text">{name}</div>
                            <div className="select-items-subtext">{`UUID: ${gateway_id}`}</div>
                        </div>
                    );
                }
            })
            .filter(Boolean);

        if (!listConfirmedGWs.length && searchedConfirmedGWsText) {
            return (
                <div className="mt-4 text-center">
                    No selected gateways to display.
                </div>
            );
        }

        return listConfirmedGWs;
    };

    return (
        <>
            <ContentWrapper>
                <div className="page-content">
                    <Container>
                        <Row>
                            <Col sm="12">
                                <h5 className="page-title">
                                    New Gateway Group
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Form>
                                    <div className="form-box">
                                        <Form.Group>
                                            <Form.Label>Group Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={gwGroupName}
                                                onChange={handleChangeGroupName}
                                                required
                                                aria-label="name"
                                                name="name"
                                                isInvalid={
                                                    !isValidText(
                                                        "name",
                                                        { name: gwGroupName },
                                                        { name: "" },
                                                        Patterns.orgNamePattern
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {GATEWAY_GROUP_NAME_MESSAGE}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="form-box mt-3">
                                        <h5 className="mb-4">
                                            Select Gateways
                                        </h5>

                                        <div className="w-100 mb-3">
                                            <Button
                                                variant="primary"
                                                className="mb-2"
                                                onClick={handleOpenModal}
                                            >
                                                Select Gateways
                                            </Button>
                                            <div className="search float-right">
                                                <Form.Control
                                                    aria-label="search-confirmed-gateway"
                                                    placeholder="Search..."
                                                    onChange={(e) =>
                                                        setSearchedConfirmedGWsText(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        {!confirmedGWs.length && (
                                            <div className="mt-4 text-center">
                                                No gateways selected.
                                            </div>
                                        )}

                                        {confirmedGWs.length !== 0 &&
                                            renderConfirmedGWs()}
                                    </div>
                                </Form>
                                <Button
                                    variant="secondary"
                                    className="mt-4 mr-2 gw-group-btn"
                                    onClick={goBackToGatewayGroupPage}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    variant="primary"
                                    className="mt-4 gw-group-btn"
                                    disabled={
                                        isSaving ||
                                        !isValidText(
                                            "name",
                                            { name: gwGroupName },
                                            { name: "" },
                                            Patterns.orgNamePattern
                                        )
                                    }
                                    onClick={addNewGWGroup}
                                >
                                    SAVE
                                </Button>
                            </Col>
                        </Row>
                    </Container>

                    <SelectGatewayModal
                        isShow={isShowSelectGW}
                        initGroups={ungrouped}
                        selectedGWs={confirmedGWs}
                        onClose={handleCloseModal}
                        handleAddGWsToGroup={handleAddGWsToGroup}
                    />
                </div>
            </ContentWrapper>
        </>
    );
};

export default AddNewGWGroup;
