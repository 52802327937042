import { faker } from "@faker-js/faker";
import { DeactivateOptionsLookup } from "constant";
import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { Actuator } from "./ActuatorConfiguration";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";

export const DeactivateOptions = ({ options = [] }: any) => {
    const {
        editedActuator: { deactivate },
        setEditedActuator,
    }: {
        actuator: Actuator;
        editedActuator: Actuator;
        setEditedActuator: React.Dispatch<React.SetStateAction<Actuator>>;
    } = useActuatorConfigurationContext();

    const [selectedOption, setSelectedOption] = useState(
        deactivate || DeactivateOptionsLookup[options[0]]
    );

    useEffect(() => {
        setEditedActuator((prevState) => ({
            ...prevState,
            deactivate: selectedOption,
        }));
    }, [selectedOption, setEditedActuator]);

    return (
        <>
            <hr />
            <Form.Label className="mt-3">Deactivate Option</Form.Label>
            <Form.Row className="mt-4 mb-4">
                <Col>
                    <Form.Control
                        as="select"
                        custom
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                        }}
                        value={selectedOption}
                    >
                        {options.map((o: any) => {
                            return (
                                <option
                                    value={DeactivateOptionsLookup[o]}
                                    key={faker.datatype.uuid()}
                                >
                                    {o}
                                </option>
                            );
                        }, [])}
                    </Form.Control>
                </Col>
            </Form.Row>{" "}
        </>
    );
};
