import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "./invitation.css";
import { useHistory } from "react-router-dom";
import InvitationService from "service/invitationService";
import { useAppContext } from "context/appContext";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import ripple from "assets/img/ripple.gif";
import { MemberDto, MemberStatus } from "types";
import { formatDate, isHttpSuccess } from "utils/functions";
import { fetchOrgList, getOrgOwnerName } from "utils/organisationFunction";
import { storeOrganizationAction } from "store/actions";
import { showErrorAlert, showSuccessAlert } from "utils/alert";

const Invitations = () => {
    const { storeData, storeDispatchActions } = useAppContext();
    const { user } = storeData;
    const { userData } = user;
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [invitationList, setInvitationList] = useState<MemberDto[]>([]);
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const history: any = useHistory();
    const preSearch: string | undefined =
        history.location.state?.preSearch || history.location.search;

    useEffect(() => {
        if (userData.email) {
            const fetch = async () => {
                const { status, data } =
                    await InvitationService.getInvitationList();
                if (isHttpSuccess(status)) {
                    const invitations = data?.toCamelCase() || [];
                    setInvitationList(invitations.reverse());
                }

                setIsPageLoading(false);
            };
            fetch();
        }
    }, [userData.email]);

    useEffect(() => {
        if (userData.email) {
            const searchParams = new URLSearchParams(preSearch);
            const currentEmail = searchParams.get("currentEmail");

            if (currentEmail && userData.email !== currentEmail) {
                showErrorAlert({
                    message: `The invitation is not applicable for this user`,
                });
            }
        }
    }, [preSearch, userData.email]);

    const handleAction = async (
        invitation: MemberDto,
        status: MemberStatus
    ) => {
        setLoadingModalShow(true);
        let isAccept = status === MemberStatus.Accepted;
        let { data, status: httpStatus }: any =
            await InvitationService.updateInvitationStatus(
                invitation.uuid,
                isAccept
            );

        if (isHttpSuccess(httpStatus)) {
            showSuccessAlert({
                message: `The invitation has been ${status.toLowerCase()}.`,
            });

            const cloned = [...invitationList];
            invitation.status = status;
            setInvitationList(cloned);
            if (isAccept) {
                const orgList = await fetchOrgList();
                storeDispatchActions(storeOrganizationAction({ orgList }));
            }
        } else {
            const message =
                data.message ||
                data.description ||
                `Unable to update invitation. Please try again later.`;
            showErrorAlert({
                message,
            });
        }

        setLoadingModalShow(false);
    };

    const renderStatus = (status: string, member: MemberDto) => {
        if (status === "Accepted") {
            return (
                <Button variant="" className="mt-2 mr-2 accepted">
                    <i className="material-icons check">done</i> Accepted
                </Button>
            );
        } else if (status === "Declined") {
            return (
                <Button variant="" className="mt-2 mr-2 declined">
                    <i className="material-icons">close</i> Declined
                </Button>
            );
        } else {
            return (
                <React.Fragment>
                    <Button
                        variant="danger"
                        className="mt-2 mr-2"
                        onClick={() => {
                            handleAction(member, MemberStatus.Declined);
                        }}
                    >
                        DECLINE
                    </Button>
                    <Button
                        variant="primary"
                        className="mt-2"
                        onClick={() => {
                            handleAction(member, MemberStatus.Accepted);
                        }}
                    >
                        ACCEPT
                    </Button>
                </React.Fragment>
            );
        }
    };

    const renderInviteList = () => {
        if (invitationList.length > 0) {
            return invitationList.map((i) => {
                return (
                    <div className="invitation-box" key={i.uuid}>
                        <div className="first-letter">
                            {i.organisation?.name[0]}
                        </div>
                        <div className="invitation-details overflow-text">
                            <h5 className="overflow-text">
                                {i.organisation?.name}
                            </h5>
                            <p className="invite-date">
                                Invited On: {formatDate(i.createTime)}
                            </p>
                            <p className="overflow-text">
                                You are invited to the Organisation{" "}
                                {i.organisation?.name}.
                            </p>
                        </div>
                        <div className="accept-decline text-right">
                            {renderStatus(i.status, i)}
                        </div>
                        <div className="invite-org-details">
                            <Row>
                                <Col md="6" sm="12">
                                    <div className="descriptor-item overflow-text">
                                        <h6 className="float-left">
                                            Organisation
                                        </h6>
                                        <h6 className="ginfo float-right overflow-text text-limitor">
                                            {i.organisation?.name}
                                        </h6>
                                        <div className="b-bottom"></div>
                                    </div>
                                </Col>
                                <Col md="6" sm="12">
                                    <div className="descriptor-item overflow-text">
                                        <h6 className="float-left">Owner</h6>
                                        <h6 className="ginfo float-right overflow-text text-limitor">
                                            {getOrgOwnerName(i.organisation!)}
                                        </h6>
                                        <div className="b-bottom"></div>
                                    </div>
                                </Col>
                                <Col md="6" sm="12">
                                    <div className="descriptor-item">
                                        <h6 className="float-left">
                                            Invited On
                                        </h6>
                                        <h6 className="ginfo float-right">
                                            {formatDate(i.createTime)}
                                        </h6>
                                    </div>
                                </Col>
                                <Col md="6" sm="12">
                                    <div className="descriptor-item overflow-text">
                                        <h6 className="float-left">
                                            Invited By
                                        </h6>
                                        <h6 className="ginfo float-right overflow-text text-limitor">
                                            {i.inviter || "-"}
                                        </h6>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                );
            });
        }
        return (
            <div className="invitation-box">
                <h6 className="text-center">No Invitations</h6>
            </div>
        );
    };

    return (
        <ContentWrapper isLoading={isPageLoading}>
            <div className="page-content">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h5 className="page-title">Invitations</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">{renderInviteList()}</Col>
                    </Row>
                </Container>
            </div>
            <Modal
                centered
                show={loadingModalShow}
                backdrop="static"
                keyboard={false}
                className="border-0"
            >
                <img className="loader-gif" src={ripple} alt="loading" />
            </Modal>
        </ContentWrapper>
    );
};

export default Invitations;
