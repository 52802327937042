import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import profileIcon from "assets/svg/icon-park-outline_id-card-v.svg";
import "assets/css/subscription.css";

const EditCustomer = (props: any) => {
    const { state } = props;
    const { customer } = state;

    return (
        <div className="form-box mb-3">
            <div className="customer-id-title position-relative">
                <img
                    src={profileIcon}
                    className="customer-id-img"
                    alt="customer-id-PRN"
                />
                <h5>Customer ID and PRN</h5>
                <p>These are required to complete the transaction.</p>

                <Link
                    to={{
                        pathname: "/checkout/billing-address",
                        state: state,
                    }}
                    className="link"
                >
                    Edit
                </Link>
                <div className="d-flex mt-2 flex-wrap align-items-center">
                    <div className="customer-form-wrap">
                        <Form className="m-sm-2 text-break">
                            <span className="customer-form-label">
                                Customer ID:
                            </span>
                            <span>{customer.verified ? customer.id : `-`}</span>
                        </Form>
                    </div>
                    <div className="customer-form-wrap">
                        <Form className="m-sm-2 text-break">
                            <span className="customer-form-label">PRN:</span>{" "}
                            <span>
                                {customer.verified ? customer.prn : `-`}
                            </span>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCustomer;
