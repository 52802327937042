import { MemberDto, OrganisationDto } from "types";
import OrganisationService from "service/organisationService";
import LocalStorageService from "service/localStorageService";
import { HttpStatus } from "constant";

export const getMemberName = (member: MemberDto) => {
    if(!member){
        return "-"
    }
    const [giveName = "" , familyName = ""] = member.name || [];
    
    if(giveName || familyName){
        return `${giveName} ${familyName}`;
    }

    return member.username;
}

export const getOrgOwnerName = (org: OrganisationDto) => {
    if(!org){
        return "-"
    }
    const [giveName = "" , familyName = ""] = org.ownerName || [];
    
    if(giveName || familyName){
        return `${giveName} ${familyName}`;
    }

    return org.name;
}

export const fetchOrgList = async () => {
    const { data, status } = await OrganisationService.getUserOrgs();
    let orgList:OrganisationDto[] = [];

    if (status === HttpStatus.OK) {
        const { owner, member } = data;

        orgList = [...owner, ...member].toCamelCase<OrganisationDto>();

        LocalStorageService.setItem("orgIdList", orgList);
    }

    return orgList;
}