import { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import OrganisationService from "service/organisationService";
import {
    DefaultMaxCharacter,
    DefaultMinCharacter,
    HttpStatus,
    OrgValidationMessage,
    Patterns,
} from "constant";
import {
    storeOrganizationAction,
    changeOrganizationAction,
} from "store/actions";
import { AppContext } from "context/appContext";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import "assets/css/CreateOrganisation.css";
import LocalStorageService from "service/localStorageService";
import { getLocalTimezone, isHttpSuccess, isValidText } from "utils/functions";
import * as _ from "lodash";
import { OrganisationPost } from "generated/models";
import useOrganizationVanilla from "hooks/organization";
import TimezoneDropdown from "components/common/TimezoneDropdown";

const defaultFormData: OrganisationPost = {
    name: "",
    time_zone: getLocalTimezone(),
};

const EditOrganization = () => {
    const history = useHistory();
    const params: any = useParams();
    const { storeData, storeDispatchActions } = useContext(AppContext);
    const [formData, setFormData] = useState(defaultFormData);
    const { organization } = storeData;
    const { currentOrgInfo } = organization;
    const [isEditing, setIsEditing] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const [validated, setValidated] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (params.orgId) {
            setIsEditing(true);
            const { name } = currentOrgInfo;
            setFormData({
                name,
            });
        } else {
            setIsEditing(false);
        }
        setIsPageLoading(false);
    }, [params, currentOrgInfo]);

    const handleCancel = () => {
        if (params.orgId) {
            history.goBack();
        }
        history.push("/dashboard");
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        const _formData = {
            ...formData,
            [name]: value.trim(),
        };

        setFormData(_formData);
        setValidated(
            !_.isEqual(_formData, defaultFormData) &&
            isValidText(
                "name",
                _formData,
                defaultFormData,
                Patterns.orgNamePattern
            )
        );
    };

    const handleTimezoneChange = (value: string) => {
        const _formData = {
            ...formData,
            time_zone: value,
        };

        setFormData(_formData);
    };

    const handleCreate = async () => {
        setIsProcessing(true);
        const res = await OrganisationService.createNewOrg(formData);
        const { data, status } = res;

        if (status !== HttpStatus.CREATED) {
            setSimpleModalData({
                resObj: res,
            } as SimpleModalDataType);
        } else {
            setSimpleModalData({
                resObj: res,
                body: "The Organisation has been created successfully!",
            } as SimpleModalDataType);
            const orgList = [...organization.orgList, data];
            storeDispatchActions(
                storeOrganizationAction({
                    orgList,
                })
            );
            storeDispatchActions(
                changeOrganizationAction({
                    orgInfo: data,
                })
            );
            LocalStorageService.setItem("orgId", data?.uuid);
            LocalStorageService.setItem("orgIdList", orgList);
            useOrganizationVanilla.setState({
                orgId: data?.uuid,
                organizationList: orgList,
            });
            history.push(`/invite-users/${data?.uuid}`);
        }
        setIsProcessing(false);
    };

    const handleUpdate = async () => {
        setIsProcessing(true);
        const res: any = await OrganisationService.updateOrganisation(
            params.orgId,
            formData
        );

        if (isHttpSuccess(res.status)) {
            setSimpleModalData({
                resObj: res,
                body: "The Organisation has been updated successfully!",
            } as SimpleModalDataType);

            const index = organization.orgList.findIndex((i) => {
                return i.uuid === params.orgId;
            });
            const { orgList } = organization;

            orgList[index] = toCamelCaseObject(res.data);

            storeDispatchActions(
                storeOrganizationAction({
                    orgList: orgList,
                })
            );
            storeDispatchActions(
                changeOrganizationAction({
                    orgInfo: orgList[index],
                })
            );
            LocalStorageService.setItem("orgIdList", orgList);
            history.push(`/organisation/${params.orgId}`);
        } else {
            setSimpleModalData({
                resObj: res,
            } as SimpleModalDataType);
        }
        setIsProcessing(false);
    };

    return (
        <ContentWrapper
            isLoading={isPageLoading}
            simpleModalData={simpleModalData}
        >
            <div className="page-content">
                <Container>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title">
                                {isEditing ? "Edit" : "Create"} Organisation
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Form
                                noValidate
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (
                                        !isValidText(
                                            "name",
                                            formData,
                                            defaultFormData,
                                            Patterns.orgNamePattern
                                        )
                                    ) {
                                        return;
                                    }

                                    if (isEditing) {
                                        handleUpdate();
                                    } else {
                                        handleCreate();
                                    }
                                }}
                            >
                                <div className="form-box">
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Organisation Name
                                        </Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            required
                                            aria-label="org-name"
                                            defaultValue={formData.name}
                                            onChange={handleFormChange}
                                            isInvalid={
                                                !isValidText(
                                                    "name",
                                                    formData,
                                                    defaultFormData,
                                                    Patterns.orgNamePattern
                                                )
                                            }
                                        />
                                        <Form.Control.Feedback
                                            role="alert"
                                            type="invalid"
                                        >
                                            {OrgValidationMessage.name.fill({
                                                MinChar: DefaultMinCharacter,
                                                MaxChar: DefaultMaxCharacter,
                                            })}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {!isEditing && (
                                        <Form.Group>
                                            <Form.Label>Time zone</Form.Label>
                                            <TimezoneDropdown
                                                value={formData.time_zone}
                                                onChange={handleTimezoneChange}
                                                className="dark"
                                            />
                                            <Form.Text className="reusable-description mt-2">
                                                This time zone is used for time
                                                related events only. All other
                                                time information is relative to
                                                local device time. Events
                                                created in the past will
                                                continue to work in the previous
                                                time zone.
                                            </Form.Text>
                                        </Form.Group>
                                    )}
                                </div>
                            </Form>
                            <Button
                                variant="secondary"
                                className="mt-4 mr-2"
                                onClick={handleCancel}
                            >
                                CANCEL
                            </Button>
                            {isEditing ? (
                                <Button
                                    variant="primary"
                                    className="mt-4"
                                    onClick={handleUpdate}
                                    disabled={!validated || isProcessing}
                                >
                                    UPDATE
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    className="mt-4"
                                    onClick={handleCreate}
                                    disabled={!validated || isProcessing}
                                >
                                    CREATE
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default EditOrganization;
