import { ChangeEvent, useState } from "react";
import { Col, InputGroup, Button, FormControl, Form } from "react-bootstrap";
import moment from "moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/DesktopTimePicker";
import DateAdapter from "@mui/lab/AdapterMoment";
import TextField from "@mui/material/TextField";
import { faker } from "@faker-js/faker";
import {
    TimeSchedule,
    ERROR_DUPLICATE,
    ERROR_INVALID,
    ERROR_BEFORE_OR_AFTER,
    EventConfigLimit,
    Patterns,
    ERROR_TIME_RANGE,
} from "constant";
import { HelpText } from "../../modals/EventModals/HelpTextModal";
import { RepeatDateTime } from "types/Event";
import { RemoveIcon } from "components/common";

type MonthYearTimeScheduleProps = {
    allDates: RepeatDateTime[];
    monthDate: RepeatDateTime;
    setAllDates: (days: RepeatDateTime[]) => void;
    index: number;
    renderErrorMsg: (
        component: any,
        field: any,
        day?: any,
        month?: any
    ) => Element;
    recurrence: string;
    onChangeTimeOption: (date: RepeatDateTime) => void;
};
export const MonthYearTimeScheduleTemplate = ({
    allDates,
    monthDate,
    setAllDates,
    index,
    renderErrorMsg,
    recurrence,
    onChangeTimeOption,
}: MonthYearTimeScheduleProps) => {
    const [errorType, setErrorType] = useState("");
    const [appointment, setAppointment] = useState(moment().startOf("day"));
    const [startTime, setStartTime] = useState(moment().startOf("day"));
    const [endTime, setEndTime] = useState(moment().startOf("day"));

    const deleteTime = (indexToDelete: number, type: string) => {
        if (type === "time") {
            const updatedAllDates = allDates.map((d: any, dIndex: number) => {
                if (index === dIndex) {
                    const cloneTimeList: any = [...d.timeList];
                    cloneTimeList.splice(indexToDelete, 1);
                    return ({ ...d, timeList: cloneTimeList });
                } else {
                    return d;
                }
            });
            setAllDates(updatedAllDates);
        } else if (type === "range") {
            const updatedAllDates = allDates.map((d: any, dIndex: number) => {
                if (dIndex === index) {
                    const cloneTimeRangeList: any = [...d.timeRangeList];
                    cloneTimeRangeList.splice(indexToDelete, 1);
                    return ({ ...d, timeRangeList: cloneTimeRangeList });
                } else {
                    return d;
                }
            });
            setAllDates(updatedAllDates);
        }
    };

    const addToTimeList = (type: string) => {
        if (type === "time") {
            const updatedAllDates = allDates.map((d: any, dIndex: number) => {
                if (dIndex === index) {
                    const cloneTimeList: any = [...d.timeList];
                    const newTime = appointment.format("HH:mm:ss");

                    if (!moment(newTime, "HH:mm:ss").isValid()) {
                        setErrorType(ERROR_INVALID);
                        return d;
                    }

                    if (cloneTimeList.includes(newTime)) {
                        setErrorType(ERROR_DUPLICATE);
                        return d;
                    }
                    setErrorType("");

                    cloneTimeList.push(newTime);
                    return ({ ...d, timeList: cloneTimeList });
                } else {
                    return d;
                }
            });

            setAllDates(updatedAllDates);
        } else if (type === "range") {
            if (startTime.isAfter(endTime)) {
                setErrorType(ERROR_TIME_RANGE);
                return;
            }

            const updatedAllDates = allDates.map((d: any, dIndex: number) => {
                if (dIndex === index) {
                    const cloneTimeRangeList: any = [...d.timeRangeList];

                    const newTimeRange = [
                        startTime.format("HH:mm:ss"),
                        endTime.format("HH:mm:ss"),
                    ];

                    if (
                        !moment(
                            startTime.format("HH:mm:ss"),
                            "HH:mm:ss"
                        ).isValid() ||
                        !moment(
                            endTime.format("HH:mm:ss"),
                            "HH:mm:ss"
                        ).isValid()
                    ) {
                        setErrorType(ERROR_INVALID);
                        return d;
                    }

                    for (const c of cloneTimeRangeList) {
                        if (
                            c[0] === newTimeRange[0] &&
                            c[1] === newTimeRange[1]
                        ) {
                            setErrorType(ERROR_DUPLICATE);
                            return d;
                        }
                    }

                    setErrorType("");

                    cloneTimeRangeList.push(newTimeRange);
                    return ({ ...d, timeRangeList: cloneTimeRangeList });
                } else {
                    return d;
                }
            });

            setAllDates(updatedAllDates);
        }
    };

    const renderTimeOrRangeList = (type: string) => {
        let array: any;
        if (type === "time") {
            array = monthDate.timeList;
        } else if (type === "range") {
            array = monthDate.timeRangeList;
        }

        const renderListFormat = (t: any, type: string) => {
            if (type === "time") {
                return moment(t, "HH:mm:ss").format("h:mm A");
            } else if (type === "range") {
                return `${moment(t[0], "HH:mm:ss").format(
                    "h:mm A"
                )} to ${moment(t[1], "HH:mm:ss").format("h:mm A")} `;
            }
        };

        return array.map((t: any, index: number) => (
            <div className="added-item mt-3" key={faker.datatype.uuid()}>
                {renderListFormat(t, type)}
                <RemoveIcon
                    className="material-icons"
                    onClick={() => {
                        deleteTime(index, type);
                    }}
                />
            </div>
        ));
    };

    const renderErrorMessage = () => {
        if (errorType === ERROR_DUPLICATE) {
            return (
                <Col sm="12" className="mt-2">
                    <p className="text-danger">
                        This time schedule has already been added.
                    </p>
                </Col>
            );
        } else if (errorType === ERROR_INVALID) {
            return (
                <Col sm="12" className="mt-2">
                    <p className="text-danger">Invalid time format.</p>
                </Col>
            );
        } else if (errorType === ERROR_BEFORE_OR_AFTER) {
            return (
                <Col sm="12" className="mt-2">
                    <p className="text-danger">{ERROR_BEFORE_OR_AFTER}</p>
                </Col>
            );
        } else if (errorType === ERROR_TIME_RANGE) {
            return (
                <Col sm="12" className="mt-2">
                    <p className="text-danger">{ERROR_TIME_RANGE}</p>
                </Col>
            );
        }
    };

    const renderAllDay = () => {
        return (
            <Form.Row>
                <Col>
                    <div className="added-item mt-3">
                        12:00:00 AM to 11:59:59 PM
                    </div>
                </Col>
            </Form.Row>
        );
    };

    const renderAt = () => {
        return (
            <Form.Row>
                <Col className="mt-3">
                    <InputGroup>
                        {renderTime(TimeSchedule.AT)}
                        <Button
                            variant="primary"
                            className="flex-zero-point-one"
                            onClick={() => {
                                addToTimeList("time");
                            }}
                        >
                            Add
                        </Button>
                    </InputGroup>
                </Col>

                <Col sm="12">{renderTimeOrRangeList("time")}</Col>
                {renderErrorMessage() ??
                    renderErrorMsg(
                        recurrence,
                        "timeSchedule",
                        monthDate.day,
                        monthDate.month
                    )}
            </Form.Row>
        );
    };

    const renderBetween = () => {
        return (
            <Form.Row>
                <Col sm="12" className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>Between</InputGroup.Text>
                        {renderTime(TimeSchedule.BETWEEN)}
                        <InputGroup.Text>and</InputGroup.Text>
                        {renderTime(TimeSchedule.BETWEEN, true)}
                        <Button
                            variant="primary"
                            onClick={() => {
                                addToTimeList("range");
                            }}
                            disabled={!!errorType}
                        >
                            Add
                        </Button>
                    </InputGroup>
                </Col>

                <Col sm="12">{renderTimeOrRangeList("range")}</Col>
                {renderErrorMessage() ??
                    renderErrorMsg(
                        recurrence,
                        "timeSchedule",
                        monthDate.day,
                        monthDate.month
                    )}
            </Form.Row>
        );
    };

    const renderBeforeAfter = () => {
        return (
            <Form.Row>
                <Col sm="6" className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>Before</InputGroup.Text>
                        {renderTime(TimeSchedule.BEFORE_OR_AFTER)}
                    </InputGroup>
                </Col>
                <Col sm="6" className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>After</InputGroup.Text>
                        {renderTime(TimeSchedule.BEFORE_OR_AFTER, true)}
                    </InputGroup>
                </Col>
                {renderErrorMessage() ??
                    renderErrorMsg(
                        recurrence,
                        "timeSchedule",
                        monthDate.day,
                        monthDate.month
                    )}
            </Form.Row>
        );
    };

    const renderBefore = () => {
        return (
            <Form.Row>
                <Col className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>Before</InputGroup.Text>
                        {renderTime(TimeSchedule.BEFORE)}
                    </InputGroup>
                </Col>

                {renderErrorMsg(
                    recurrence,
                    "timeSchedule",
                    monthDate.day,
                    monthDate.month
                )}
            </Form.Row>
        );
    };

    const renderAfter = () => {
        return (
            <Form.Row>
                <Col className="mt-3">
                    <InputGroup>
                        <InputGroup.Text>After</InputGroup.Text>
                        {renderTime(TimeSchedule.AFTER)}
                    </InputGroup>
                </Col>

                {renderErrorMsg(
                    recurrence,
                    "timeSchedule",
                    monthDate.day,
                    monthDate.month
                )}
            </Form.Row>
        );
    };

    const renderEvery = () => {
        const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (
                e.target.value &&
                !Patterns.timeScheduleEveryPattern.test(e.target.value)
            ) {
                return;
            }

            const updatedAllDates = allDates.map((d: any, dIndex: number) => {
                if (dIndex === index) {
                    return ({
                        ...d,
                        duration: e.target.value,
                    });
                } else {
                    return d;
                }
            });

            setAllDates(updatedAllDates);
        };

        return (
            <Form.Row>
                <Col className="mt-2">
                    <InputGroup className="justify-content-end align-items-center">
                        <FormControl
                            value={monthDate.duration}
                            onChange={handleInputChange}
                        />

                        <div className="every-input-unit">Minute(s)</div>
                    </InputGroup>
                    <HelpText
                        min={1}
                        max={EventConfigLimit.TIME_SCHEDULE_MINUTES_MAX}
                        timeUnit="minute"
                    />
                    {renderErrorMsg(
                        recurrence,
                        "timeSchedule",
                        monthDate.day,
                        monthDate.month
                    )}
                </Col>
            </Form.Row>
        );
    };

    const renderTime = (timeOption: string, additional?: any) => {
        const handleTimeChange = (newValue: any) => {
            checkBeforeOrAfter(
                newValue,
                allDates[index][additional ? "priTime" : "secTime"],
                additional
            );

            if (timeOption === TimeSchedule.AT) {
                setAppointment(newValue);
                return;
            }

            if (timeOption === TimeSchedule.BETWEEN && !additional) {
                setStartTime(newValue);
                return;
            }

            if (timeOption === TimeSchedule.BETWEEN && additional) {
                setEndTime(newValue);
                return;
            }

            if (timeOption === TimeSchedule.BEFORE_OR_AFTER && additional) {
                const updatedAllDates = allDates.map(
                    (d: any, dIndex: number) => {
                        if (dIndex === index) {
                            return ({ ...d, secTime: newValue });
                        } else {
                            return d;
                        }
                    }
                );
                setAllDates(updatedAllDates);
                return;
            }

            const updatedAllDates = allDates.map((d: any, dIndex: number) => {
                if (dIndex === index) {
                    return ({ ...d, priTime: newValue });
                } else {
                    return d;
                }
            });
            setAllDates(updatedAllDates);
        };

        const checkBeforeOrAfter = (
            value: moment.Moment,
            compare: moment.Moment,
            additional = false
        ) => {
            setErrorType("");
            if (!value.isValid()) {
                setErrorType(ERROR_INVALID);

                return false;
            }

            if (
                compare?.isValid() &&
                value[additional ? "isSameOrBefore" : "isSameOrAfter"]?.(
                    compare
                ) &&
                timeOption === TimeSchedule.BEFORE_OR_AFTER
            ) {
                setErrorType(ERROR_BEFORE_OR_AFTER);

                return false;
            }

            return true;
        };

        if (
            (timeOption === TimeSchedule.BEFORE_OR_AFTER ||
                timeOption === TimeSchedule.BETWEEN) &&
            additional
        ) {
            return (
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                        label="Time"
                        inputFormat="h:mm A"
                        value={
                            timeOption === TimeSchedule.BETWEEN
                                ? endTime
                                : monthDate.secTime
                        }
                        onChange={(newValue) => {
                            handleTimeChange(moment(newValue));
                        }}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                label=""
                                InputLabelProps={{
                                    shrink: false,
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            );
        }

        let defaultStartTime = monthDate.priTime;
        if (timeOption === TimeSchedule.AT) defaultStartTime = appointment;
        if (timeOption === TimeSchedule.BETWEEN && !additional)
            defaultStartTime = startTime;

        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                    label="Time"
                    inputFormat="h:mm A"
                    value={defaultStartTime}
                    onChange={(newValue: any) => {
                        handleTimeChange(moment(newValue));
                    }}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label=""
                            InputLabelProps={{
                                shrink: false,
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        );
    };

    const renderFormat = () => {
        switch (monthDate.selectedTimeOption) {
            case TimeSchedule.ALL_DAY:
                return renderAllDay();
            case TimeSchedule.AT:
                return renderAt();

            case TimeSchedule.BETWEEN:
                return renderBetween();

            case TimeSchedule.BEFORE_OR_AFTER:
                return renderBeforeAfter();

            case TimeSchedule.BEFORE:
                return renderBefore();
            case TimeSchedule.AFTER:
                return renderAfter();

            case TimeSchedule.EVERY:
                return renderEvery();
        }
    };

    const handleTimeOptionChange = (newValue: any) => {
        let newDate = allDates[0];
        const updatedAllDates = allDates.map(
            (d: RepeatDateTime, dIndex: number) => {
                if (dIndex === index) {
                    if (newValue === TimeSchedule.EVERY) {
                        newDate = {
                            ...d,
                            selectedTimeOption: newValue,
                            selectedInterval: "hours",
                        };
                        return newDate;
                    }
                    newDate = { ...d, selectedTimeOption: newValue };
                    return newDate;
                } else {
                    return d;
                }
            }
        );
        setAllDates(updatedAllDates);
        onChangeTimeOption(newDate);
    };

    return (
        <>
            <Form.Row>
                <Col>
                    <Form.Control
                        as="select"
                        custom
                        value={monthDate.selectedTimeOption}
                        onChange={(e: any) => {
                            setErrorType("");
                            handleTimeOptionChange(e.target.value);
                        }}
                    >
                        <option value={TimeSchedule.ALL_DAY}>All Day</option>
                        <option value={TimeSchedule.AT}>At</option>
                        <option value={TimeSchedule.BETWEEN}>Between</option>
                        <option value={TimeSchedule.BEFORE_OR_AFTER}>
                            Before or After
                        </option>
                        <option value={TimeSchedule.BEFORE}>Before</option>
                        <option value={TimeSchedule.AFTER}>After</option>
                        <option value={TimeSchedule.EVERY}>Every</option>
                    </Form.Control>
                </Col>
            </Form.Row>
            {renderFormat()}
        </>
    );
};
