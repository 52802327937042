import { useState, useEffect } from "react";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    InputGroup,
    Button,
    FormControl,
    Form,
} from "react-bootstrap";

import EventService from "service/eventService";
import { getDeviceConfigById, getAllRegistry } from "service/gatewayService";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import "assets/css/event.css";
import { HttpStatus } from "constant";
import { showErrorAlert } from "utils/alert";

interface Device {
    device_id: string;
    for_: number;
    gateway_id: string;
    status: string;
    edge_trigger?: boolean
}
interface Resource {
    device: Device;
}
interface Condition {
    create_time: string;
    event_uuid: string;
    modify_time: string;
    order?: number;
    resource: Resource;
    slot: number;
    type_: string;
    uuid: string;
}

const SensorStatus = (props: any) => {
    const location: any = useLocation();
    const params: any = useParams();
    const history = useHistory();
    const selectedSensorDetails = location?.state?.selectedSensorDetails;
    const selectedOperator = location?.state?.selectedOperator;
    const slotCount = location?.state?.slotCount;
    const selectedSlotIndex = location?.state?.selectedSlotIndex;
    const [sensorDetails, setSensorDetails]: any = useState({});
    const [duration, setDuration] = useState("");
    const OFFLINE = "offline";
    const ONLINE = "online";
    const [checkedOption, setCheckedOption] = useState(OFFLINE);

    const [currentCondition, setCurrentCondition] = useState<Condition>({
        create_time: "",
        event_uuid: "",
        modify_time: "",
        order: 0,
        resource: {
            device: {
                device_id: "",
                for_: 0,
                gateway_id: "",
                status: "",
                edge_trigger: true
            },
        },
        slot: 0,
        type_: "",
        uuid: "",
    });

    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const [isPageLoading, updateIsPageLoading] = useState(true);

    const fetchSensorConfig = async (gatewayId: string, deviceId: string) => {
        const [ldsuRes, allRegistryInfo]: any = await Promise.all([
            getDeviceConfigById(gatewayId, deviceId),
            getAllRegistry(),
        ]);

        const { name, UID } = ldsuRes?.data || {};
        if (
            ldsuRes.status === HttpStatus.OK &&
            allRegistryInfo.status === HttpStatus.OK
        ) {
            const { data: gateways } = allRegistryInfo;

            const gatewayDetail: any = gateways.reduce((acc: any, g: any) => {
                if (g.gateway_id === gatewayId) {
                    acc.gateway_name = g.name;

                    const ldsu: any = g.info.devices.find((l: any) => {
                        return l.UID === deviceId;
                    });

                    if (ldsu) acc.bus = ldsu.PORT;
                }
                return acc;
            }, {});
            setSensorDetails({
                sensor_name: name,
                ldsu_id: UID,
                gateway_name: gatewayDetail.gateway_name,
                gateway_id: gatewayId,
                bus: gatewayDetail.bus,
            });
        }
    };

    useEffect(() => {
        // this condition should only run if the condition is already set
        if (params.conditionId) {
            const fetchSensor = async () => {
                const response = await EventService.readOneCondition(
                    params.conditionId,
                    params.eventId
                );
                if (response.status === 200) {
                    const { data: LDSU } = response;
                    const { status, for_, device_id, gateway_id } =
                        LDSU.resource.device;

                    fetchSensorConfig(gateway_id, device_id);
                    if (status === "online") {
                        setCheckedOption(ONLINE);
                    } else {
                        setCheckedOption(OFFLINE);
                    }

                    setDuration(for_);
                    setCurrentCondition(LDSU);

                    updateIsPageLoading(false);
                }
            };
            fetchSensor();
        } else {
            const { gatewayId, ldsuUUID } = selectedSensorDetails || {};
            fetchSensorConfig(gatewayId, ldsuUUID);
            updateIsPageLoading(false);
        }
    }, [selectedSensorDetails, params.conditionId, params.eventId]);

    const closeCondition = () => {
        history.push(`/event-details/${params.eventId}`);
    };

    const handleAddUpdateCondition = async () => {
        let response: any;

        if (
            Number(duration) < 5 ||
            Number(duration) > 3600 ||
            Number(duration) % 5 !== 0
        ) {
            showErrorAlert({
                message:
                    "Duration must be a multiple of 5 and between 5 - 3600s.",
            });
            return;
        }

        let slot = 0;
        if (selectedOperator === "AND") {
            slot = selectedSlotIndex;
        } else if (selectedOperator === "OR") {
            slot = slotCount;
        }

        if (!params.conditionId) {
            response = await EventService.createCondition(params.eventId, {
                slot: slot,
                type_: "LDSU",
                resource: {
                    device: {
                        gateway_id: selectedSensorDetails.gatewayId,
                        device_id: selectedSensorDetails.ldsuUUID,
                        status:
                            checkedOption === OFFLINE ? "offline" : "online",
                        for_: Number(duration),
                        edge_trigger: true
                    },
                },
            });
        } else {
            response = await EventService.updateCondition(
                params.eventId,
                params.conditionId,
                {
                    slot: currentCondition.slot,
                    type_: "LDSU",
                    resource: {
                        device: {
                            gateway_id: sensorDetails.gateway_id,
                            device_id: sensorDetails.ldsu_id,
                            status:
                                checkedOption === OFFLINE
                                    ? "offline"
                                    : "online",
                            for_: Number(duration),
                            edge_trigger: true
                        },
                    },
                }
            );
        }

        if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 204
        ) {
            closeCondition();
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const renderSensorInfo = () => {
        const { sensor_name, ldsu_id, gateway_name, bus } = sensorDetails;
        return (
            <div className="action-device-details">
                <h6>{sensor_name}</h6>
                <p>
                    {gateway_name} - LDS BUS {bus}
                </p>
                <p>LDSU UUID: {ldsu_id}</p>
            </div>
        );
    };

    return (
        <>
            <ContentWrapper
                isLoading={isPageLoading}
                simpleModalData={simpleModalData}
            >
                <Container>
                    <Row>
                        <Col sm="12" className="event-detail-head">
                            <h5 className="page-title overflow-text">
                                LDSU Status
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item>
                                    <Link to="/events">Events</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={`/event-details/${params.eventId}`}
                                    >
                                        Event Details
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Condition
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row className="action-detail-box">
                        <Col>
                            <div className="form-box mb-3">
                                <h5 className="mb-4">When LDSU</h5>
                                {renderSensorInfo()}
                            </div>
                            <div className="form-box  mb-3">
                                <h5 className="mb-4">Status</h5>
                                <Form.Row>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="mode"
                                            id={OFFLINE}
                                            custom
                                            type="radio"
                                            onClick={() => {
                                                setCheckedOption(OFFLINE);
                                            }}
                                            checked={checkedOption === OFFLINE}
                                            label={
                                                <span className="ml-2">
                                                    Goes offline
                                                </span>
                                            }
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="mode"
                                            id={ONLINE}
                                            custom
                                            type="radio"
                                            onClick={() => {
                                                setCheckedOption(ONLINE);
                                            }}
                                            checked={checkedOption === ONLINE}
                                            label={
                                                <span className="ml-2">
                                                    Comes online
                                                </span>
                                            }
                                        />
                                    </Col>
                                </Form.Row>
                            </div>
                            <div className="form-box mb-3">
                                <h5 className="mb-4">Last for (Duration)</h5>
                                <InputGroup className="mt-2">
                                    <FormControl
                                        defaultValue=""
                                        value={duration}
                                        onChange={(e: any) => {
                                            setDuration(e.target.value);
                                        }}
                                    />
                                    <InputGroup.Text>sec(s)</InputGroup.Text>
                                </InputGroup>
                            </div>
                            <Button
                                variant="secondary"
                                className="mr-2"
                                onClick={closeCondition}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="pl-4 pr-4"
                                onClick={handleAddUpdateCondition}
                            >
                                {params.conditionId ? "UPDATE" : "ADD"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ContentWrapper>
        </>
    );
};

export default SensorStatus;
