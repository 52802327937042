import IOTLogo from "assets/svg/IoTPortal-logo.svg";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import "../../assets/css/home.css";
import "../../assets/css/responsive.css";
import { TERMS_OF_SERVICE_LINK } from "constant";

const LeftContent = () => {
    return (
        <Col lg="6" sm="12" className="hero">
            <div className="hero-box">
                <Link to="">
                    <img src={IOTLogo} alt="" />
                </Link>

                <div className="app-details">
                    <h4>Introducing a solution to meet your IoT needs. </h4>
                    <p>
                        An IoT monitoring system free from the issues faced by
                        wireless networks apt for applications such as urban
                        farming, industrial monitoring and data centres etc.
                    </p>

                    <p className="summary-item">
                        <span className="material-icons check">star</span>
                        {""}
                        Setup Gateways.
                    </p>
                    <p className="summary-item">
                        <span className="material-icons check">star</span>
                        {""}
                        Configure Sensors and Actuators.
                    </p>
                    <p className="summary-item">
                        <span className="material-icons check">star</span>
                        {""}
                        Configure Alerts and Events to receive Email/SMS/Push
                        Notifications.
                    </p>
                    <p className="summary-item">
                        <span className="material-icons check">star</span>
                        {""}
                        Setup your organisation for better user authorization.
                    </p>
                </div>

                <ul>
                    <li>
                        <a
                            href="https://brtsys.com/iotportal/"
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            About{" "}
                        </a>
                    </li>
                    <li>
                        <a
                            href={TERMS_OF_SERVICE_LINK}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            Terms of Service
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://brtsys.com/privacy-policy-2/"
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            Privacy Policy
                        </a>
                    </li>
                </ul>
                <p>
                    © {new Date().getFullYear()}{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://brtsys.com/"
                    >
                        BRT Systems Pte Ltd
                    </a>
                    {""}. All Rights Reserved.
                </p>
            </div>
        </Col>
    );
};

export default LeftContent;
