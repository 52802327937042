/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import {
  AddonPatch2,
  AdminGetMonthlyTransactionData,
  AdminUpdateExchangeRatesData,
  CancelSubscriptionByIdData,
  ConversionRatePost,
  CouponPatch2,
  CreateCustomerData,
  CreateStandaloneAddOnData,
  CreateSubscriptionData,
  CustomerPatch,
  CustomerPost,
  DeleteSubscriptionByIdData,
  GetAvailableAddOnByIdData,
  GetAvailableAddOnData,
  GetAvailablePlansData,
  GetCouponByNameData,
  GetCouponsData,
  GetCustomerData,
  GetExchangeRatesData,
  GetInvoiceByFileNameData,
  GetInvoicesData,
  GetMonthlyTransactionData,
  GetSubscriptionByIdData,
  GetSubscriptionPriceByIdData,
  GetSubscriptionsData,
  PaymentPost,
  PaySubscriptionByIdData,
  SubscribePatch,
  SubscribePost,
  SubscriptionPost2,
  TransactionReport,
  UpdateCustomerData,
  UpdateSubscriptionAddonByIdData,
  UpdateSubscriptionDiscountByIdData,
  UpdateSubscriptionPaymentByIdData,
  ValidateCustomerData,
} from "../models";

export class Subscriptions<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags subscription
   * @name GetSubscriptions
   * @summary Get all subscription info
   * @request GET:/subscriptions
   * @secure
   */
  getSubscriptions = (params: RequestParams = {}) =>
    this.http.request<GetSubscriptionsData, void>({
      path: `/subscriptions`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name CreateSubscription
   * @summary Create a new subscription
   * @request POST:/subscriptions
   * @secure
   */
  createSubscription = (data: SubscriptionPost2, params: RequestParams = {}) =>
    this.http.request<CreateSubscriptionData, void>({
      path: `/subscriptions`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name DeleteSubscriptionById
   * @summary Delete unpaid subscription info by uuid
   * @request DELETE:/subscriptions/{subscription_uuid}
   * @secure
   */
  deleteSubscriptionById = (subscriptionUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteSubscriptionByIdData, void>({
      path: `/subscriptions/${subscriptionUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name GetSubscriptionById
   * @summary Get subscription info by ID
   * @request GET:/subscriptions/{subscription_uuid}
   * @secure
   */
  getSubscriptionById = (subscriptionUuid: string, params: RequestParams = {}) =>
    this.http.request<GetSubscriptionByIdData, void>({
      path: `/subscriptions/${subscriptionUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name UpdateSubscriptionAddonById
   * @summary Update subscription addon info by uuid
   * @request PATCH:/subscriptions/{subscription_uuid}/addon
   * @secure
   */
  updateSubscriptionAddonById = (subscriptionUuid: string, data: AddonPatch2, params: RequestParams = {}) =>
    this.http.request<UpdateSubscriptionAddonByIdData, void>({
      path: `/subscriptions/${subscriptionUuid}/addon`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name UpdateSubscriptionDiscountById
   * @summary Update subscription coupon info by uuid
   * @request PATCH:/subscriptions/{subscription_uuid}/coupon
   * @secure
   */
  updateSubscriptionDiscountById = (subscriptionUuid: string, data: CouponPatch2, params: RequestParams = {}) =>
    this.http.request<UpdateSubscriptionDiscountByIdData, void>({
      path: `/subscriptions/${subscriptionUuid}/coupon`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name GetSubscriptionPriceById
   * @summary Get subscription total price by ID
   * @request GET:/subscriptions/{subscription_uuid}/price
   * @secure
   */
  getSubscriptionPriceById = (
    subscriptionUuid: string,
    query?: {
      /** Country of billing address */
      country?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetSubscriptionPriceByIdData, void>({
      path: `/subscriptions/${subscriptionUuid}/price`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name UpdateSubscriptionPaymentById
   * @summary Update subscription payment info
   * @request PATCH:/subscriptions/{subscription_uuid}/subscribe
   * @secure
   */
  updateSubscriptionPaymentById = (subscriptionUuid: string, data: SubscribePatch, params: RequestParams = {}) =>
    this.http.request<UpdateSubscriptionPaymentByIdData, void>({
      path: `/subscriptions/${subscriptionUuid}/subscribe`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name PaySubscriptionById
   * @summary Do subscribe a subscription by ID
   * @request POST:/subscriptions/{subscription_uuid}/subscribe
   * @secure
   */
  paySubscriptionById = (subscriptionUuid: string, data: SubscribePost, params: RequestParams = {}) =>
    this.http.request<PaySubscriptionByIdData, void>({
      path: `/subscriptions/${subscriptionUuid}/subscribe`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags subscription
   * @name CancelSubscriptionById
   * @summary Cancel subscription by ID
   * @request POST:/subscriptions/{subscription_uuid}/cancel
   * @secure
   */
  cancelSubscriptionById = (subscriptionUuid: string, params: RequestParams = {}) =>
    this.http.request<CancelSubscriptionByIdData, void>({
      path: `/subscriptions/${subscriptionUuid}/cancel`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags plan
   * @name GetAvailablePlans
   * @summary Get all available plans
   * @request GET:/subscriptions/plans
   * @secure
   */
  getAvailablePlans = (params: RequestParams = {}) =>
    this.http.request<GetAvailablePlansData, void>({
      path: `/subscriptions/plans`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags addon
   * @name GetAvailableAddOn
   * @summary Get all available add-on
   * @request GET:/subscriptions/addons
   * @secure
   */
  getAvailableAddOn = (params: RequestParams = {}) =>
    this.http.request<GetAvailableAddOnData, void>({
      path: `/subscriptions/addons`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags addon
   * @name GetAvailableAddOnById
   * @summary Get add-on total price by ID
   * @request GET:/subscriptions/addons/{addon_uuid}/price
   * @secure
   */
  getAvailableAddOnById = (
    addonUuid: string,
    query?: {
      /** Quantity of add-on */
      quantity?: string;
      /** Coupon name */
      coupon?: string;
      /** The country in billing address */
      country?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetAvailableAddOnByIdData, void>({
      path: `/subscriptions/addons/${addonUuid}/price`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags addon
   * @name CreateStandaloneAddOn
   * @summary Make payment for a standalone add-on without subscription or in subscription trial period
   * @request POST:/subscriptions/addons/{addon_uuid}/pay
   * @secure
   */
  createStandaloneAddOn = (addonUuid: string, data: PaymentPost, params: RequestParams = {}) =>
    this.http.request<CreateStandaloneAddOnData, void>({
      path: `/subscriptions/addons/${addonUuid}/pay`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags customer
   * @name ValidateCustomer
   * @summary Validate customer code and PRN
   * @request GET:/subscriptions/customer
   * @secure
   */
  validateCustomer = (
    query: {
      /** Customer code */
      code: string;
      /** Project registration number */
      project: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ValidateCustomerData, void>({
      path: `/subscriptions/customer`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin
   * @name GetCustomer
   * @summary Get customer info
   * @request GET:/subscriptions/customer_
   * @secure
   */
  getCustomer = (
    query: {
      /** Customer code */
      code: string;
      /** Project registration number */
      project: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetCustomerData, void>({
      path: `/subscriptions/customer_`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin
   * @name UpdateCustomer
   * @summary Update customer info
   * @request PATCH:/subscriptions/customer_
   * @secure
   */
  updateCustomer = (data: CustomerPatch, params: RequestParams = {}) =>
    this.http.request<UpdateCustomerData, void>({
      path: `/subscriptions/customer_`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin
   * @name CreateCustomer
   * @summary Add new customer info
   * @request POST:/subscriptions/customer_
   * @secure
   */
  createCustomer = (data: CustomerPost, params: RequestParams = {}) =>
    this.http.request<CreateCustomerData, void>({
      path: `/subscriptions/customer_`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags coupon
   * @name GetCoupons
   * @summary Get coupon info
   * @request GET:/subscriptions/coupons
   * @secure
   */
  getCoupons = (params: RequestParams = {}) =>
    this.http.request<GetCouponsData, void>({
      path: `/subscriptions/coupons`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags coupon
   * @name GetCouponByName
   * @summary Get coupon info by name
   * @request GET:/subscriptions/coupons/{coupon_name}
   * @secure
   */
  getCouponByName = (couponName: string, params: RequestParams = {}) =>
    this.http.request<GetCouponByNameData, void>({
      path: `/subscriptions/coupons/${couponName}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags invoice
   * @name GetInvoices
   * @summary Get the list of invoices
   * @request GET:/subscriptions/{subscription_uuid}/invoices
   * @secure
   */
  getInvoices = (subscriptionUuid: string, params: RequestParams = {}) =>
    this.http.request<GetInvoicesData, void>({
      path: `/subscriptions/${subscriptionUuid}/invoices`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags invoice
   * @name GetInvoiceByFileName
   * @summary Get the invoice by file_name
   * @request GET:/subscriptions/{subscription_uuid}/invoices/{file_name}
   * @secure
   */
  getInvoiceByFileName = (subscriptionUuid: string, fileName: string, params: RequestParams = {}) =>
    this.http.request<GetInvoiceByFileNameData, void>({
      path: `/subscriptions/${subscriptionUuid}/invoices/${fileName}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags info
   * @name GetExchangeRates
   * @summary Get exchange rate info
   * @request GET:/subscriptions/exchange
   * @secure
   */
  getExchangeRates = (
    query?: {
      /** The country in billing address */
      country?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetExchangeRatesData, void>({
      path: `/subscriptions/exchange`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin
   * @name AdminUpdateExchangeRates
   * @summary Update exchange rate info
   * @request POST:/subscriptions/exchange_
   * @secure
   */
  adminUpdateExchangeRates = (data: ConversionRatePost, params: RequestParams = {}) =>
    this.http.request<AdminUpdateExchangeRatesData, void>({
      path: `/subscriptions/exchange_`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin
   * @name AdminGetMonthlyTransaction
   * @summary Get monthly transaction report
   * @request POST:/subscriptions/report_
   * @secure
   */
  adminGetMonthlyTransaction = (data: TransactionReport, params: RequestParams = {}) =>
    this.http.request<AdminGetMonthlyTransactionData, void>({
      path: `/subscriptions/report_`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags report
   * @name GetMonthlyTransaction
   * @summary Get monthly transaction report
   * @request POST:/subscriptions/report
   * @secure
   */
  getMonthlyTransaction = (data: TransactionReport, params: RequestParams = {}) =>
    this.http.request<GetMonthlyTransactionData, void>({
      path: `/subscriptions/report`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
