import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import { set, union, get, omit, uniqBy } from "lodash";
import DashboardCreateModal, { DashboardCreateModalProps } from ".";
import { useCreateDashboardStore } from "..";
import { SensorType } from "types";
import { getSensorTypes } from "utils/gatewayFunctions";
import { displaySensorType } from "utils/functions";

const DashboardCreateSelectDeviceModal: React.FunctionComponent<
    DashboardCreateModalProps & {
        deviceType: "Sensor" | "Actuator";
        sensorTypes?: string[];
        maxDevices?: number;
    }
> = (props) => {
    const [filteredDevices, setFilteredDevices] = React.useState<any[]>([]);
    const [searchCondition, setSearchCondition] = React.useState<{
        name: string;
        gateway_id: string;
        sensor_type: string;
    }>({
        name: "",
        gateway_id: "",
        sensor_type: "",
    });

    const { show, onHide, onSave, maxDevices, deviceType, sensorTypes } = props;
    const [values, setValues] = React.useState<any>({});
    const [sensorTypeList, setSensorTypeList] = React.useState<SensorType[]>(
        []
    );

    const { deviceList, allRegistry, loading } = useCreateDashboardStore();

    const getDeviceList = (): any[] => {
        const filterActuator = !deviceType || deviceType === "Actuator";

        return uniqBy(deviceList, (d) => d.device_id + d.gatewayId).filter(
            (device) => device.isActuator === filterActuator
        );
    };

    React.useEffect(() => {
        if (show && deviceList.length > 0) {
            // When value is a string list
            if (Array.isArray(props.values)) {
                const selectedGateways = {} as any;
                props.values.forEach((value: any) => {
                    set(
                        selectedGateways,
                        value,
                        deviceList.find((item) => item.name === value)
                    );
                });
                setValues(selectedGateways);
            } else {
                setValues(props.values || {});
            }

            setFilteredDevices(getDeviceList());

            setSensorTypeList(
                getSensorTypes(getDeviceList()).filter(
                    ({ id }) => !sensorTypes || sensorTypes.includes(id)
                )
            );
        }

        setSearchCondition({
            name: "",
            gateway_id: "",
            sensor_type: "",
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, deviceList, allRegistry]);

    React.useEffect(() => {
        setFilteredDevices(
            getDeviceList().filter(
                (device) =>
                    (!searchCondition.name ||
                        device.device_name
                            .toLowerCase()
                            .includes(
                                searchCondition.name.toLowerCase().trim()
                            )) &&
                    (!searchCondition.gateway_id ||
                        device.gatewayId === searchCondition.gateway_id) &&
                    (!searchCondition.sensor_type ||
                        device.sensor_type === searchCondition.sensor_type ||
                        get(device.APP, `[${device.SAID}].NAME`) ===
                            searchCondition.sensor_type) &&
                    (!sensorTypes ||
                        sensorTypes.includes(device.sensor_type) ||
                        sensorTypes.includes(
                            get(device.APP, `[${device.SAID}].NAME`)
                        ))
            )
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCondition, sensorTypes]);

    return (
        <DashboardCreateModal
            title={"Select {deviceType}s".fill(props)}
            dialogClassName="devices-modal"
            show={show}
            onHide={() => onHide && onHide()}
            footer={() => (
                <div className="row w-100 justify-content-between align-items-center">
                    <strong className="text-gray">
                        {!!Object.keys(values).length &&
                            `${Object.keys(values).length}${
                                maxDevices ? `/${maxDevices}` : ""
                            } ${props.deviceType}(s) are selected`}
                    </strong>
                    <Button
                        variant="primary"
                        onClick={(event) => {
                            onHide && onHide();
                            onSave && onSave(event, values);
                        }}
                    >
                        ADD
                    </Button>
                </div>
            )}
        >
            {allRegistry?.length > 0 && (
                <Row className="mb-2">
                    <Col sm="12" className="m-0 p-0">
                        <InputGroup className="text-gray flex align-items-center p-3 border rounded bg-light-gray">
                            <span className="mr-3">Filter By:</span>
                            <Form.Control
                                className="rounded"
                                as="select"
                                type="text"
                                placeholder="Search.."
                                size="sm"
                                onChange={(e) =>
                                    setSearchCondition({
                                        ...searchCondition,
                                        gateway_id: e.target.value,
                                    })
                                }
                                custom
                            >
                                <option key="all" value="">
                                    All Gateways
                                </option>
                                {allRegistry.map(({ name, gateway_id }) => (
                                    <option key={gateway_id} value={gateway_id}>
                                        {name}
                                    </option>
                                ))}
                            </Form.Control>
                            {deviceType === "Sensor" && (
                                <Form.Control
                                    className="rounded ml-3"
                                    as="select"
                                    type="text"
                                    placeholder="Search.."
                                    size="sm"
                                    onChange={(e) =>
                                        setSearchCondition({
                                            ...searchCondition,
                                            sensor_type: e.target.value,
                                        })
                                    }
                                    custom
                                >
                                    {!sensorTypes && (
                                        <option value="">All Types</option>
                                    )}

                                    {sensorTypeList.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Form.Control>
                            )}
                        </InputGroup>
                    </Col>
                </Row>
            )}
            <Row className="align-items-center mb-3 modal-filter">
                <Col
                    sm="4"
                    className="modal-option text-gray-important mx-0 p-0"
                >
                    <Form.Check
                        id="device-check-all"
                        type="checkbox"
                        label="Select All"
                        disabled={
                            typeof maxDevices !== "undefined" &&
                            filteredDevices.length > Number(maxDevices || 0)
                        }
                        custom
                        checked={
                            !!filteredDevices.length &&
                            !!Object.keys(values).length &&
                            Object.keys(values).length ===
                                union(
                                    Object.keys(values),
                                    Object.keys(
                                        filteredDevices.pluck("device_id")
                                    )
                                ).length
                        }
                        onChange={(event) => {
                            const { checked } = event.target;
                            if (
                                checked &&
                                (!maxDevices ||
                                    filteredDevices.length <= maxDevices)
                            ) {
                                setValues(filteredDevices.pluck("device_id"));
                            } else {
                                setValues({});
                            }
                        }}
                    />
                </Col>
                <Col sm="8" className="pl-lg-3 pr-0">
                    <Form.Control
                        type="text"
                        placeholder="Search.."
                        onChange={(e) =>
                            setSearchCondition({
                                ...searchCondition,
                                name: e.target.value,
                            })
                        }
                    ></Form.Control>
                </Col>
            </Row>

            <Form className="d-flex flex-wrap modal-options">
                {!!filteredDevices.length && !loading ? (
                    filteredDevices.map((subDevice: any, index: number) => {
                        return (
                            <div
                                className="modal-option"
                                key={subDevice.device_id + subDevice.gatewayId}
                            >
                                <Form.Check
                                    name="selected-device"
                                    value={subDevice.device_id}
                                    checked={values?.hasOwnProperty(
                                        subDevice.device_id
                                    )}
                                    disabled={
                                        typeof maxDevices !== "undefined" &&
                                        !get(values, subDevice.device_id) &&
                                        Object.keys(values).length >= maxDevices
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setValues({
                                                ...values,
                                                [subDevice.device_id]:
                                                    subDevice,
                                            });
                                        } else {
                                            setValues(
                                                omit(
                                                    values,
                                                    subDevice.device_id
                                                )
                                            );
                                        }
                                    }}
                                    id={index + subDevice.device_id}
                                    custom
                                    type="checkbox"
                                    label={
                                        <div>
                                            <h4>{subDevice.device_name}</h4>{" "}
                                            <p>{subDevice.busName}</p>
                                            <p>UUID: {subDevice.ldsu_uuid}</p>
                                            <p>
                                                {displaySensorType(subDevice)}
                                            </p>
                                        </div>
                                    }
                                    className="float-left"
                                />
                            </div>
                        );
                    })
                ) : Boolean(filteredDevices.length) ? ( // eslint-disable-line no-extra-boolean-cast
                    <Spinner animation="border" variant="primary" />
                ) : (
                    <p className="w-100 d-flex justify-content-center">
                        No {props.deviceType.toLowerCase()}s to display
                    </p>
                )}
            </Form>
            <style>
                {`
                .devices-modal {
                    width: 650px;
                    max-width: 650px;
                }
                .modal-option .custom-control-label:before,
                .modal-option .custom-control-label:after {
                    top: 50%;
                    transform: translateY(-50%);
                }
                .modal-options {
                    position: relative;
                    min-height: 10vh;
                    max-height: 50vh;
                    overflow-y: auto;
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                }
                .modal-options::-webkit-scrollbar {
                    display: none;
                }

                @media (max-width: 768px) {
                    .devices-modal {
                        width: unset !important;
                        max-width: unset !important;
                    }
                }
            `}
            </style>
        </DashboardCreateModal>
    );
};

export default React.memo(DashboardCreateSelectDeviceModal);
