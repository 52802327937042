/* eslint-disable no-extend-native */
import { camelCase, isObject } from "lodash";

const _global = (window /* browser */ || global) /* node */ as any;

_global.toCamelCaseObject = function <T>(source: any) {
    if (Array.isArray(source) && typeof source !== "string") {
        return source.toCamelCase<T>();
    }

    const camelCaseObject: Record<string, any> = {};
    for (let key in source) {
        camelCaseObject[camelCase(key)] = Array.isArray(source[key])
            ? source[key].toCamelCase()
            : isObject(source[key])
            ? toCamelCaseObject(source[key])
            : source[key];
    }

    return camelCaseObject as T;
};

Array.prototype.toCamelCase = function <T = any>() {
    const camelCaseObject: T[] = [];
    for (let obj of this) {
        camelCaseObject.push(isObject(obj) ? toCamelCaseObject<T>(obj) : obj);
    }

    return camelCaseObject as T[];
};
