import { memo } from "react";
import BasicSensorChart from "./BasicSensorChart";
import styledConst from "styles";
import { CustomEChartParams } from "types/Charts";
import { get } from "lodash";
import { UnknownSensor } from "constant";

function SingleSensorChart(props: any) {
    return (
        <BasicSensorChart
            {...{
                ...props,
                customEChartData: ({
                    liveData,
                    liveTime,
                    liveDate,
                    sensorUnit,
                    min,
                    max,
                    accuracy,
                    sensorType,
                }: CustomEChartParams) => {
                    return [
                        {
                            path: "title.subtext",
                            value: `{s6|${sensorType}}\n{s2|${
                                (isNaN(liveData) || liveData === "NaN"
                                    ? "-"
                                    : liveData) + (sensorUnit ?? "")
                            }} {s5|    Min/Max} {s3| ${
                                !isNaN(min) ? min.toFixed(accuracy) : "-"
                            }/${!isNaN(max) ? max.toFixed(accuracy) : "-"}${
                                sensorUnit || ""
                            }}\n{s1|${liveDate + " " + liveTime}}`,
                        },
                    ];
                },
                customEChartStyle: (chartColor: string) => {
                    return [
                        {
                            path: "title.subtextStyle.rich",
                            value: {
                                s1: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    height: 25,
                                },
                                s2: {
                                    color: chartColor,
                                    fontSize: 22,
                                    fontWeight: "bold",
                                },
                                s3: {
                                    color: "white",
                                    fontSize: 15,
                                    fontFamily: "Roboto",
                                    height: 25,
                                },
                                s4: {
                                    fontSize: 6,
                                },
                                s5: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 15,
                                    height: 25,
                                },
                                s6: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    height: 25,
                                },
                            },
                        },
                    ];
                },
                displayName: (chartState) => {
                    return get(
                        chartState,
                        "sensorConfigRes.sensor_name",
                        UnknownSensor
                    );
                },
                onDelete: props.handleOnChangeDashboardUUID,
            }}
        />
    );
}

export default memo(SingleSensorChart);
