import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "./components/shared/Layout";
import { useAuth } from "./context/auth";
import ErrorBoundary from "utils/errorBoundary";

const PrivateRoute = ({ component: Component, location, ...rest }: any) => {
    const { context: { authTokens } } = useAuth();
    const { pathname, search } = location;
    const prePath = location?.state?.referer?.state?.pre;
    const preSearch = location?.state?.referer?.state?.search;
    let renderFunc = (props: any) => (
        <Redirect
            to={{
                pathname: "/",
                state: { referer: props.location, pre: `${pathname}`, search},
            }}
        />
    );
    if (prePath) {
        renderFunc = (props) => (
            <Redirect
                to={{
                    pathname: prePath,
                    state: { preSearch },
                }}
            />
        );
    } else if (authTokens) {
        renderFunc = (props) => (
            <Layout>
                <ErrorBoundary>
                    <Component {...props} />
                </ErrorBoundary>
            </Layout>
        );
    }
    return <Route {...rest} render={renderFunc} />;
};

export default PrivateRoute;
