import AxiosInstance from "./axiosInstance";
import { ResponseDto } from "types";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "../utils/functions";
import { getAccountInstance } from "generated/factory";
import {
    ChangePassword,
    ConfirmForgot,
    ConfirmSignUp,
    Login,
    LoginData,
    RespondAuth,
    SignUp,
} from "generated/models";
import { AxiosError } from "axios";

export class AuthenticationService {
    private accountClient = getAccountInstance(AxiosInstance);

    login = async (body: Login): Promise<ResponseDto<LoginData>> => {
        try {
            const response = await this.accountClient.login(body);
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };

    sendForgotPasswordEmail = async (email: string) => {
        try {
            const response = await this.accountClient.forgotPassword({
                email,
            });
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };

    changePassword = async (body: ConfirmForgot) => {
        try {
            const response =
                await this.accountClient.confirmForgotPasswordWithCode(body);
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };

    signUp = async (body: SignUp) => {
        try {
            const response = await this.accountClient.signUp(body);
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };

    submitSignUpVerificationCode = async (body: ConfirmSignUp) => {
        try {
            const response = await this.accountClient.confirmSighUpWithCode(
                body
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };

    submitMFACode = async (body: RespondAuth) => {
        try {
            const response = await this.accountClient.loginWithChallenge(body);
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };

    changeProfilePassword = async (body: ChangePassword) => {
        try {
            const response = await this.accountClient.changePassword(body);
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };

    resendConfirmationCode = async (email: string) => {
        try {
            const response = await this.accountClient.reSendConfirmationCode({
                email: email,
            });
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    };
}

const authService = new AuthenticationService();
export default authService;
