import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { ReactComponent as ProfileSvg } from "assets/svg/profile.svg";
import { ReactComponent as LockSvg } from "assets/svg/lock.svg";
import { ReactComponent as TrashSvg } from "assets/svg/delete.svg";
import styledConst from "styles";

const ProfileIcon = styled(ProfileSvg)`
    path {
        fill: ${styledConst.Primary_Blue_4};
    }
`;

const DeleteLink = styled(Link)`
    color: ${styledConst.Secondary_Red};
`;

const AccountSettings = () => {
    return (
        <div className="page-content">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h5 className="page-title">Account Settings</h5>
                    </Col>
                </Row>
                <Row className="cstm-table">
                    <Col sm="12">
                        <div className="table-row">
                            <Row className="no-checkbox">
                                <Col md={{ span: 1 }} xs={{ span: 2 }}>
                                    <ProfileIcon />
                                </Col>
                                <Col>
                                    <Link
                                        to={{
                                            pathname: `/user/profile`,
                                        }}
                                    >
                                        Profile Details
                                    </Link>
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 2 }}
                                >
                                    <Link
                                        to={{
                                            pathname: `/user/profile`,
                                        }}
                                    >
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm="12">
                        <div className="table-row">
                            <Row className="no-checkbox">
                                <Col md={{ span: 1 }} xs={{ span: 2 }}>
                                    <LockSvg />
                                </Col>
                                <Col>
                                    <Link
                                        to={{
                                            pathname: `/user/change-password`,
                                        }}
                                    >
                                        Change Password
                                    </Link>
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 2 }}
                                >
                                    <Link
                                        to={{
                                            pathname: `/user/change-password`,
                                        }}
                                    >
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm="12">
                        <div className="table-row">
                            <Row className="no-checkbox">
                                <Col md={{ span: 1 }} xs={{ span: 2 }}>
                                    <TrashSvg className="profile-icon" />
                                </Col>
                                <Col>
                                    <DeleteLink
                                        to={{
                                            pathname: `/user/terminate`,
                                        }}
                                    >
                                        Delete Account
                                    </DeleteLink>
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 2 }}
                                >
                                    <Link
                                        to={{
                                            pathname: `/user/terminate`,
                                        }}
                                    >
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AccountSettings;
