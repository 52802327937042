import { useState, useEffect } from "react";

export const useIntersection = (
    element: { current: Element },
    rootMargin: any
) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { rootMargin }
        );

        const observeSchedule = setTimeout(() => {
            if (element?.current && element?.current instanceof HTMLElement) {
                observer.unobserve(element.current);
            }

            element.current && observer.observe(element.current);
        }, 1000);

        return () => {
            clearTimeout(observeSchedule);

            if (element?.current instanceof HTMLElement) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(element?.current);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isVisible;
};
// https://www.webtips.dev/webtips/react-hooks/element-in-viewport
