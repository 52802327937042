import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./style.css";
import { Modal } from "react-bootstrap";
import {
    createErrorResponseStandard,
    createResponseStandard,
} from "utils/functions";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { replaceServerResStr } from "utils/layout";
import { ForbiddenErrorMessage, HttpStatus } from "constant";
import { AxiosError, AxiosResponse } from "axios";
import { ResponseDto } from "types";
import { faker } from "@faker-js/faker";

const ErrorsContainer = styled("div")`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    gap: 10px;
    background: #fff8f8;
    border: 1px solid #f2495e;
    border-radius: 4px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;

    .material-icons {
        color: #f2495e;
    }

    p {
        text-align: left;
    }

    a {
        color: #007bff;
    }
`;

export type SimpleModalDataType = {
    type?: "success" | "error" | "warning" | "info";
    title?: string | null;
    subTitle?: string | null;
    body?: string | React.ElementType | null;
    errors?: any[];
    renderModalButtons?: any;
    renderLoading?: boolean;
    icon?: string | null;
    // auto parse to data
    resObj?: Error | AxiosResponse | ResponseDto;
};

const ContentWrapper = (props: any) => {
    const {
        title,
        routes,
        children,
        isLoading,
        isForbiddenResource,
        isOutOfPaymentToken,
        simpleModalData = null,
    }: {
        [key: string]: any;
        simpleModalData: null | SimpleModalDataType;
    } = props;
    const [errModalVisible, setErrModalVisible] = useState(!!simpleModalData);
    const {
        // ! later used
        type: modalType,
        title: modalTitle,
        subTitle: modalTitleSubTitle,
        body: modalBody,
        errors: modalErrorsBody,
        renderModalButtons,
        renderLoading: modalLoadingIcon,
        icon: modalIcon,
    } = simpleModalData ?? {};

    const resObj =
        simpleModalData?.resObj instanceof Error
            ? createErrorResponseStandard(simpleModalData?.resObj as AxiosError)
            : (simpleModalData?.resObj as AxiosResponse)?.request
            ? createResponseStandard(simpleModalData?.resObj as AxiosResponse)
            : (simpleModalData?.resObj as ResponseDto);

    const _title = modalTitle || resObj?.data?.["title"] || "",
        _subTitle = modalTitleSubTitle || "",
        _description =
            modalBody ||
            resObj?.data?.["description"] ||
            resObj?.["message"] ||
            (resObj?.["status"] === HttpStatus.FORBIDDEN
                ? ForbiddenErrorMessage
                : undefined) ||
            "",
        _type =
            modalType !== undefined
                ? modalType
                : resObj &&
                  resObj["status"] >= HttpStatus.OK &&
                  resObj["status"] < HttpStatus.MULTIPLE_CHOICES
                ? "success"
                : "error",
        _errors = modalErrorsBody || resObj?.data?.["errors"] || [],
        _icon = modalIcon;

    useEffect(() => {
        if (_title || _subTitle || _description) {
            setErrModalVisible(true);
        } else {
            setErrModalVisible(false);
        }
    }, [simpleModalData, _title, _subTitle, _description, _type]);

    useEffect(() => {
        if (errModalVisible && !renderModalButtons) {
            toast(
                <div>
                    <b className="title">
                        {replaceServerResStr(
                            _title ||
                                _subTitle ||
                                _type.charAt(0).toUpperCase() + _type.slice(1)
                        )}
                    </b>
                    <div role="alert" className="description">
                        {typeof _description === "function"
                            ? _description()
                            : replaceServerResStr(_description || _title)}
                        {Array.isArray(_errors) && _errors.length > 0 ? (
                            <>
                                {_errors.map(({ key, value = [] }, index) => {
                                    return (
                                        <div key={key}>
                                            {Array.isArray(value)
                                                ? value.map((t) => (
                                                      <span
                                                          key={faker.datatype.uuid()}
                                                      >
                                                          {replaceServerResStr(
                                                              t
                                                          )}
                                                      </span>
                                                  ))
                                                : replaceServerResStr(value)}
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            _errors
                        )}
                    </div>
                </div>,
                {
                    type: _type,
                    theme: "colored",
                    onOpen: () => setErrModalVisible(false),
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errModalVisible]);

    const renderContent = () => {
        if (isForbiddenResource)
            return (
                <div className="page-content forbidden-resource">
                    <Container fluid>
                        {title && (
                            <Row>
                                <Col sm={12}>
                                    <h5 className="page-title">{title}</h5>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col sm="12">
                                <Breadcrumb className="w-100">
                                    {routes &&
                                        routes.map((route: any) => (
                                            <Breadcrumb.Item
                                                active={!route.url}
                                                key={faker.datatype.uuid()}
                                            >
                                                {route.url ? (
                                                    <Link to={route.url}>
                                                        {route.name}
                                                    </Link>
                                                ) : (
                                                    route.name
                                                )}
                                            </Breadcrumb.Item>
                                        ))}
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center content">
                                <Alert
                                    variant="danger"
                                    className="my-0 mt-4 text-center"
                                >
                                    {ForbiddenErrorMessage}
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );

        if (isOutOfPaymentToken?.state)
            return (
                <div className="page-content forbidden-resource">
                    <Container fluid>
                        {title && (
                            <Row>
                                <Col sm={12}>
                                    <h5 className="page-title">{title}</h5>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col sm="12">
                                <Breadcrumb className="w-100">
                                    {routes &&
                                        routes.map(
                                            (route: any, index: number) => (
                                                <Breadcrumb.Item
                                                    active={!route.url}
                                                    key={faker.datatype.uuid()}
                                                >
                                                    {route.url ? (
                                                        <Link to={route.url}>
                                                            {route.name}
                                                        </Link>
                                                    ) : (
                                                        route.name
                                                    )}
                                                </Breadcrumb.Item>
                                            )
                                        )}
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center content">
                                <Alert
                                    variant="danger"
                                    className="my-0 mt-4 text-center"
                                >
                                    {isOutOfPaymentToken.msg}
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        return children;
    };

    return (
        <>
            {isLoading ? (
                <Spinner animation="border" variant="primary" />
            ) : (
                renderContent()
            )}
            {renderModalButtons && (
                <Modal
                    centered
                    backdrop="static"
                    show={errModalVisible}
                    onHide={() => setErrModalVisible(false)}
                    keyboard={false}
                    aria-labelledby="example-modal-sizes-title-sm"
                    className={`no-header ${
                        modalTitleSubTitle === "Success" ||
                        modalTitleSubTitle === "Pending"
                            ? "primary"
                            : "danger"
                    }`}
                >
                    <Modal.Body className="text-center mt-3 mb-3">
                        {_icon && (
                            <div className="modal-icon-box">
                                {
                                    <span className="material-icons">
                                        {_icon}
                                    </span>
                                }
                            </div>
                        )}
                        {_title && <h3 className="mb-3">{_title}</h3>}
                        {_subTitle && <h3>{_subTitle}</h3>}
                        {typeof _description === "string" ? (
                            <p
                                className="mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: _description,
                                }}
                            ></p>
                        ) : (
                            _description
                        )}
                        {Array.isArray(_errors) && _errors.length > 0 ? (
                            <ErrorsContainer>
                                <span className="material-icons">error</span>
                                {_errors.map(({ key, value = [] }) => {
                                    return (
                                        <div key={key}>
                                            {Array.isArray(value)
                                                ? value.map((t) => (
                                                      <p
                                                          key={faker.datatype.uuid()}
                                                      >
                                                          {t}
                                                      </p>
                                                  ))
                                                : value}
                                        </div>
                                    );
                                })}
                            </ErrorsContainer>
                        ) : (
                            _errors
                        )}
                        {renderModalButtons?.(setErrModalVisible)}
                        {modalLoadingIcon && (
                            <Spinner
                                className="content-wrapper-spinner"
                                animation="border"
                            />
                        )}
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default ContentWrapper;
