import { Variable } from "generated/models";
import { Button, Form, Modal } from "react-bootstrap";
import { getTypeOptionKeyByValue } from "utils/eventFunctions";

type SelectEventVariableModalProps = {
    isShowEventVariables: boolean;
    setIsShowEventVariables: Function;
    eventVariableSelected: string;
    setEventVariableSelected: Function;
    handleSelectedEventVariable: Function;
    eventVariables: Variable[];
    modalTitle: string;
};

const SelectEventVariableModal = ({
    isShowEventVariables,
    setIsShowEventVariables,
    eventVariableSelected,
    setEventVariableSelected,
    handleSelectedEventVariable,
    eventVariables,
    modalTitle,
}: SelectEventVariableModalProps) => {
    return (
        <Modal
            show={isShowEventVariables}
            onHide={() => {
                setIsShowEventVariables(false);
                setEventVariableSelected("");
            }}
            animation={false}
            centered
            className="select-event-variable-modal"
        >
            <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
                <Button
                    variant=""
                    className="close-button"
                    onClick={() => {
                        setIsShowEventVariables(false);
                        setEventVariableSelected("");
                    }}
                >
                    <span className="material-icons">close</span>
                </Button>
            </Modal.Header>

            <Modal.Body>
                <div className="scroll-list float-left">
                    {eventVariables.map((eventVariable: Variable) => {
                        return (
                            <div
                                className="modal-option"
                                key={eventVariable.uuid}
                            >
                                <Form.Check
                                    name="selected-event-variable"
                                    value={eventVariable.uuid}
                                    onChange={(e) => {
                                        setEventVariableSelected(
                                            e.target.value
                                        );
                                    }}
                                    custom
                                    type="radio"
                                    id={eventVariable.uuid}
                                    label={
                                        <div className="select-event-variable-item">
                                            <h4>{eventVariable.name}</h4>
                                            <span className="event-variable-type">
                                                Type:{" "}
                                                {getTypeOptionKeyByValue(
                                                    eventVariable.type_
                                                )}
                                            </span>
                                        </div>
                                    }
                                    className="float-left"
                                />
                            </div>
                        );
                    })}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="primary"
                    className="float-right"
                    onClick={() => handleSelectedEventVariable()}
                    disabled={!eventVariableSelected}
                >
                    DONE
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectEventVariableModal;
