import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";
import { HttpStatus } from "constant";
import { downloadStorage, getOwnedRegistry } from "service/gatewayService";
import localStorageService from "service/localStorageService";
import { get, size, map, values, omit } from "lodash";
import DashboardCreateSelectGatewayModal from "components/dashboard/create/modal/SelectGateways";
import { useCreateDashboardStore } from "components/dashboard/create";
import downloadIcon from "assets/svg/download.svg";
import styledConst from "styles";
import { useModal } from "components/modals/ModalTemplate";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { useOrganisation } from "hooks/organization";
import { isHttpSuccess } from "utils/functions";
import TimezoneDropdown from "components/common/TimezoneDropdown";
import DateTimeRangePicker from "components/common/DateTimeRangePicker";

const Box = styled.div`
    padding: 20px;
    background-color: #3e4b67;
    width: 100%;
    border-radius: 5px;
`;

const DeleteIcon = styled.span`
    color: ${styledConst.Secondary_Red};
    cursor: pointer;
`;

const CustomRow = styled.div`
    padding: 0.75rem 1rem;
    background-color: #3e4b67;
    border-radius: 5px;
    width: 100%;
`;

const MutedText = styled.span`
    color: ${styledConst.Primary_Blue_4};
    font-size: 12px;
`;

enum DownloadOptions {
    All = "all",
    ByDate = "by-day",
}

const DownloadData = () => {
    const timeZoneObject = localStorageService.getItem("timezones");
    const { defaultTimeZone, ownerOrgIds } = useOrganisation();

    const [downloadOption, setDownloadOption] = useState(
        DownloadOptions.ByDate
    );
    const [timezone, setTimezone] = useState<string>(defaultTimeZone);
    const [startDateTime, setStartDateTime] = useState(
        moment.utc().subtract(24, "hours").tz(defaultTimeZone)
    );
    const [endDateTime, setEndDateTime] = useState(
        moment.utc().tz(defaultTimeZone)
    );
    const [selectedGateways, setSelectedGateways] = useState<{}>({});
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState(false);

    const {
        openModal,
        modal: ActionModal,
        setModalShow: setActionModalShow,
    } = useModal();

    const isValid = () => {
        return size(selectedGateways) > 0 && !error;
    };

    const handleDateTimeRangeChange = (value: Moment[]) => {
        setStartDateTime(value[0]);
        setEndDateTime(value[1]);
    };

    const handleDateTimeRangeError = (error: string) => {
        setError(!!error);
    };

    const removeGateway = (gateway_id: string) => {
        setSelectedGateways(omit(selectedGateways, [gateway_id]));
    };

    const onSelectedGateway = async (data: Record<string, any>) => {
        setSelectedGateways(data);
    };

    const downloadData = () => {
        const isDownloadAll = downloadOption === DownloadOptions.All;
        const params = {
            start: isDownloadAll ? 0 : moment(startDateTime).seconds(0).unix(),
            end: isDownloadAll ? moment().seconds(0).unix() : moment(endDateTime).seconds(0).unix(),
            attributes: map(
                values(selectedGateways),
                ({ gateway_id }: any) => ({
                    gateway_id,
                })
            ),
            time_zone: timezone,
        };

        downloadStorage(params).then(({ data, status }) => {
            if (isHttpSuccess(status)) {
                showSuccessAlert({
                    message:
                        "An email will be sent to you shortly once it is ready to download.",
                });
            } else {
                showErrorAlert({
                    title: get(data, "title", "Error"),
                    message: get(data, "description"),
                });
            }

            setActionModalShow(false);
            setSelectedGateways({});
        });
    };

    const confirmDownload = () => {
        openModal({
            key: "download_data_confirm",
            modalType: "updateConfirm",
            modalTitle: "Confirmation",
            modalIcon: "help_outline",
            modalContent: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: `By confirming, an email will be sent to your email address with instructions on how to download. 
                        <br/>Do you want to continue?<br/>`,
                    }}
                />
            ),
            resendFunction: downloadData,
        });
    };

    const handleChangeTimeZone = (timezone: string) => {
        setTimezone(timezone);
        setStartDateTime(
            moment(startDateTime).utcOffset(timeZoneObject[timezone])
        );
        setEndDateTime(endDateTime.tz(timezone));
    };

    const handleChangeOption = (value: DownloadOptions) => {
        setDownloadOption(value);
        if (value === DownloadOptions.ByDate) {
            setStartDateTime(startDateTime.tz(timezone));
            setEndDateTime(endDateTime.tz(timezone));
        }
    };

    useEffect(() => {
        const fetch = async () => {
            const res = await getOwnedRegistry(ownerOrgIds);

            let allRegistry: any[] = [];
            if (res.status === HttpStatus.OK) {
                const data = get(res, "data", []);
                allRegistry = map(data, (gateway) => {
                    let groupName = get(gateway, "group", "Standalone");
                    groupName = groupName === null ? "Standalone" : groupName;
                    return {
                        ...gateway,
                        groupName,
                    };
                }).filter(({ info }: { info: any }) => info);
            }

            useCreateDashboardStore.setState({
                allRegistry,
            });
        };
        ownerOrgIds.length && fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerOrgIds]);

    useEffect(() => {
        setTimezone(defaultTimeZone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultTimeZone]);

    return (
        <div className="page-content">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h3 className="page-title">Download Data</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col sm="12">
                        <div className="form-box">
                            <Row>
                                <Col
                                    md={{
                                        span: 6,
                                        offset: 3,
                                    }}
                                    sm={{
                                        span: 8,
                                        offset: 2,
                                    }}
                                    xs={{
                                        span: 10,
                                        offset: 1,
                                    }}
                                >
                                    <div className="mb-4">
                                        <Form.Label>
                                            Select a time zone
                                        </Form.Label>
                                        <TimezoneDropdown
                                            value={timezone}
                                            onChange={handleChangeTimeZone}
                                            className="dark"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        Select data to include
                                    </div>
                                    <Box>
                                        <Form.Check
                                            name="download-all-data"
                                            custom
                                            type="radio"
                                            value={DownloadOptions.All}
                                            id="download-all"
                                            checked={
                                                downloadOption ===
                                                DownloadOptions.All
                                            }
                                            onChange={() =>
                                                handleChangeOption(
                                                    DownloadOptions.All
                                                )
                                            }
                                            label={
                                                <span className="ml-2">
                                                    Download all data
                                                </span>
                                            }
                                        />
                                    </Box>
                                    <Box className="mt-2">
                                        <Form.Check
                                            name="date-range"
                                            custom
                                            type="radio"
                                            value={DownloadOptions.ByDate}
                                            id="date-range"
                                            checked={
                                                downloadOption ===
                                                DownloadOptions.ByDate
                                            }
                                            onChange={() =>
                                                handleChangeOption(
                                                    DownloadOptions.ByDate
                                                )
                                            }
                                            label={
                                                <span className="ml-2">
                                                    Pick date range
                                                </span>
                                            }
                                        />

                                        {downloadOption ===
                                            DownloadOptions.ByDate && (
                                            <div className="mt-3">
                                                <DateTimeRangePicker
                                                    value={[
                                                        startDateTime,
                                                        endDateTime,
                                                    ]}
                                                    timezone={timezone}
                                                    onChange={
                                                        handleDateTimeRangeChange
                                                    }
                                                    onError={
                                                        handleDateTimeRangeError
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Box>

                                    <div className="mt-5 mb-3">
                                        <div className="mb-2">Gateways</div>
                                        <Button
                                            onClick={() => setModalShow(true)}
                                        >
                                            Select Gateways
                                        </Button>
                                        <DashboardCreateSelectGatewayModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            values={selectedGateways}
                                            onSave={(event, data) =>
                                                onSelectedGateway(data)
                                            }
                                        />
                                    </div>

                                    {size(selectedGateways) > 0 && (
                                        <div>
                                            {map(
                                                values(selectedGateways),
                                                ({ name, gateway_id }: any) => (
                                                    <CustomRow
                                                        className="px-3 mb-2"
                                                        key={gateway_id}
                                                    >
                                                        <Row className="no-checkbox">
                                                            <Col>
                                                                <div>
                                                                    {name}
                                                                </div>
                                                                <MutedText>
                                                                    UUID:{" "}
                                                                    {gateway_id}
                                                                </MutedText>
                                                            </Col>
                                                            <Col
                                                                md={{
                                                                    span: 1,
                                                                    order: "last",
                                                                }}
                                                                xs={{
                                                                    span: 1,
                                                                    order: 2,
                                                                }}
                                                            >
                                                                <DeleteIcon
                                                                    className="material-icons"
                                                                    aria-label="delete-gateway"
                                                                    onClick={() => {
                                                                        removeGateway(
                                                                            gateway_id
                                                                        );
                                                                    }}
                                                                >
                                                                    close
                                                                </DeleteIcon>
                                                            </Col>
                                                        </Row>
                                                    </CustomRow>
                                                )
                                            )}
                                        </div>
                                    )}
                                    <Button
                                        className="w-100 mt-5"
                                        disabled={!isValid()}
                                        onClick={confirmDownload}
                                    >
                                        <img
                                            src={downloadIcon}
                                            alt=""
                                            className="mr-1"
                                        />
                                        Download Data
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            {ActionModal}
        </div>
    );
};

export default DownloadData;
