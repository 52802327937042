import axios from "axios";
import LocalStorageService from "./localStorageService";
import useAxiosResponse from "hooks/axiosResponse";
import { isHttpSuccess } from "utils/functions";
import { HttpStatus, PANEL_TOKEN_ZERO_TOKEN } from "constant";

const tokens = LocalStorageService.getItem("tokens");
const axiosInstance = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
    baseURL: process.env.REACT_APP_DASHBOARD_API,
});

// Reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

// Initial Authorization
if (tokens) {
    axiosInstance.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${tokens.AccessToken}`;
}

const clearStorageExpireSession = () => {
    LocalStorageService.clearAll();
    window.location.replace("/callback?session=expired");
};

window.panelQueryToken = {};

// add request interceptors
axiosInstance.interceptors.request.use(
    (config) => {
        if (config.url?.includes("mqtt2tsdb")) {
            const url = new URL(config.url)
            const params = new URLSearchParams(url.search)
            let panelQuery = params.get("query")?.replace(/\[(.*?)\]/, "");
            const [queryUrl] = config.url.split("&token=");
            if(panelQuery && window.panelQueryToken[panelQuery]){
                config.url = `${queryUrl}&token=${
                    window.panelQueryToken[panelQuery]
                }`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//add response interceptors
axiosInstance.interceptors.response.use(
    (response) => useAxiosResponse(response),
    async (error) => {
        let originalRequest = error.config;
        const { url } = originalRequest;
        const exceptPath = ["/login", "/account/terminate"].find((u) =>
            url.includes(u)
        );
        const statusCode = error?.response?.status;
        const panelQueryTokenPermissionDenied =
            error.response &&
            [HttpStatus.UNAUTHORIZED, HttpStatus.FORBIDDEN].includes(statusCode) &&
            url.includes("mqtt2tsdb");

        if (panelQueryTokenPermissionDenied) {
            if (url.includes(PANEL_TOKEN_ZERO_TOKEN)) {
                return Promise.reject(error);
            }
            const dashboardResponse = await axiosInstance.get(
                `${process.env.REACT_APP_DASHBOARD_API}/dashboards/${
                    LocalStorageService.getItem("dbInfo").key
                }/panels_v2`,
                {}
            );

            if (isHttpSuccess(dashboardResponse.status)) {
                let token: string = "" ;
                for (let index = 0; index < dashboardResponse.data.data.length; index++) {
                    const panel = dashboardResponse.data.data[index];
                    if(!panel.token[0]){
                        continue;
                    }

                    // eslint-disable-next-line no-loop-func
                    panel.queries.forEach((q: string, index: number) => {
                        window.panelQueryToken[q] = panel.token[index];// update the token for each panel
                        if(url.includes(q)) {
                            token = panel.token[index];
                        }
                    });

                    if(token) {
                        break;
                    }
                }

                if (token === PANEL_TOKEN_ZERO_TOKEN) {
                    return Promise.reject(error);
                }

                const queryUrl = url.split("&token=")[0];
                originalRequest = `${queryUrl}&token=${token}`;
                return axiosInstance(originalRequest);
            }
        }

        if (
            error.response &&
            statusCode === HttpStatus.UNAUTHORIZED &&
            !originalRequest._retry &&
            !exceptPath
        ) {
            if (
                [
                    "Invalid Refresh Token",
                    "Refresh Token has been revoked",
                ].includes(error.response.data.description)
            ) {
                clearStorageExpireSession();
            }

            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers["Authorization"] =
                            "Bearer " + token;

                        return axiosInstance(originalRequest);
                    })
                    .catch((err) => {
                        clearStorageExpireSession();
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const { RefreshToken } =
                LocalStorageService.getItem("tokens") || {};

            if (!RefreshToken) {
                clearStorageExpireSession();
            }

            try {
                const response = await axiosInstance.post(
                    `${process.env.REACT_APP_DASHBOARD_API}/account/token`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${RefreshToken}`,
                        },
                    }
                );
                if (isHttpSuccess(response.status)) {
                    const { AccessToken: NewAccessToken } = response.data.data;

                    LocalStorageService.setItem("tokens", {
                        ...LocalStorageService.getItem("tokens"),
                        AccessToken: NewAccessToken,
                    });

                    axiosInstance.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer  ${NewAccessToken}`;
                    originalRequest.headers[
                        "Authorization"
                    ] = `Bearer ${NewAccessToken}`;

                    processQueue(null, NewAccessToken);

                    return axiosInstance(originalRequest);
                } else {
                    return Promise.reject(error);
                }
            } catch (err: any) {
                processQueue(err, null);
                return axiosInstance.request(err.config);
            } finally {
                isRefreshing = false;
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
