import { defaultChartRefreshRate } from "constant";
import { useState } from "react";

const useChartRefreshRate = (existingRefreshRate?: string) => {
    const formattedExistingRefreshRate = existingRefreshRate?.replace("s", "");

    const [refreshRate, setRefreshRate] = useState<any>({
        seconds: formattedExistingRefreshRate ?? defaultChartRefreshRate,
    });

    const [editableCustomRate, setEditableCustomRate] = useState(
        defaultChartRefreshRate
    );

    return {
        refreshRate,
        setRefreshRate,
        editableCustomRate,
        setEditableCustomRate,
    };
};

export default useChartRefreshRate;
