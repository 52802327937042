import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import {
    updateFirmwareUpdates,
    getOneRegistry,
    getOtaStatus,
} from "service/gatewayService";
import { ACTION_FAIL_MESSAGE, FirmwareStatusLookup } from "constant";
import UpdateTabVersionSelected from "./UpdateTabVersionSelected";
import { fetchGWStatus } from "utils/gatewayFunctions";
import { isHttpSuccess } from "utils/functions";
import { useModal } from "components/modals/ModalTemplate";
import {
    getAPIError,
    showErrorAlert,
    showInfoAlert,
    showSuccessAlert,
} from "utils/alert";

type otaStateType = {
    status: string;
    timestamp: any;
    updateVersion: string;
    currentVersion: string;
};
const UpdateTab = ({
    registryDetails: { info, gateway_id },
    setIsFirmwareUpdated,
}: any) => {
    const [gatewayOtaDetails, setGatewayOtaDetails] = useState<otaStateType>({
        status: "-",
        timestamp: "-",
        updateVersion: "-",
        currentVersion: info ? info.FWV : "-",
    });
    const { modal, openModal, setModalShow } = useModal();
    const [selectedFWVersion, setSelectedFWVersion] = useState("");
    const [componentState, setComponentState] = useState("idle");

    useEffect(() => {
        const fetch = async () => {
            const { data, status } = await getOneRegistry(gateway_id);

            if (isHttpSuccess(status)) {
                let ota = data.ota;

                if (!ota) {
                    const { data } = await getOtaStatus(gateway_id);
                    ota = data;
                }

                const { status, modify_time, version } = ota;
                const { FWV } = data.info;

                setIsFirmwareUpdated(
                    gatewayOtaDetails.currentVersion !==
                        gatewayOtaDetails.updateVersion
                );
                setGatewayOtaDetails({
                    ...gatewayOtaDetails,
                    currentVersion: FWV,
                    updateVersion: version,
                    status,
                    timestamp: Date.parse(modify_time),
                });
            }
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gateway_id, componentState, gatewayOtaDetails.status]);

    const renderDate = (value: any) => {
        if (isNaN(value)) return `-`;
        if (typeof value === "number") {
            return new Date(value).toLocaleDateString("en-GB");
        }
        return value;
    };

    const updateFirmware = async () => {
        closeModal();
        const isGWOnline: any = await fetchGWStatus(gateway_id);

        showInfoAlert({
            title: "Updating...",
        });

        const updateResponse = await updateFirmwareUpdates(
            gateway_id,
            selectedFWVersion
        );

        if (isHttpSuccess(updateResponse.status)) {
            if (isGWOnline) {
                showSuccessAlert({
                    message: `Successfully requested to update firmware for this gateway . Firmware update may take some time to complete.`,
                });
            } else {
                showErrorAlert({
                    title: "Pending",
                    message: `This gateway is offline. A request to update the firmware will be made when this gateway goes online.`,
                });
            }
            setComponentState("refresh");
        } else {
            showErrorAlert(getAPIError(updateResponse, ACTION_FAIL_MESSAGE));
        }
        setComponentState("idle");
    };

    const closeModal = () => {
        setModalShow(false);
    };

    const openUpdateConfirmation = () => {
        openModal({
            key: "open_update_confirmation",
            modalType: "custom",
            modalTitle: "Confirm Update",
            modalIcon: "help_outline",
            iconVariant: "primary",
            modalContent: "Do you want to update now?",
            modalButton: () => {
                return (
                    <>
                        <Button variant="secondary" onClick={closeModal}>
                            CANCEL
                        </Button>
                        <Button variant="primary" onClick={updateFirmware}>
                            UPDATE
                        </Button>
                    </>
                );
            },
        });
    };

    return (
        <>
            <Row className="justify-content-md-center">
                <Col lg={5} xs={12}>
                    <Row>
                        <Col sm={12} md className="update-info mt-4">
                            <div className="gateway-info">
                                <h6>Current Version</h6>
                                <h6 className="ginfo">
                                    {gatewayOtaDetails.currentVersion
                                        ? `V ${gatewayOtaDetails.currentVersion}`
                                        : `-`}
                                </h6>
                            </div>
                        </Col>
                    </Row>
                    <h6 className="mb-3 mt-5">Last Update Details</h6>
                    <Row>
                        <Col sm={12} md className="update-info mt-4">
                            <div className="gateway-info">
                                <h6>Version</h6>
                                <h6 className="ginfo">
                                    {gatewayOtaDetails.updateVersion
                                        ? `V ${gatewayOtaDetails.updateVersion}`
                                        : `-`}
                                </h6>
                            </div>
                        </Col>
                        <Col sm={12} md className="update-info mt-4">
                            <div className="gateway-info" id="lastUpdateDate">
                                <div>
                                    <h6>Last Update Date</h6>
                                    <h6 className="ginfo">
                                        {renderDate(
                                            gatewayOtaDetails.timestamp
                                        )}
                                    </h6>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md className="update-info mt-4">
                            <div className="gateway-info" id="lastUpdateStatus">
                                <div>
                                    <h6>Last Update Status</h6>
                                    <h6 className="ginfo">
                                        {FirmwareStatusLookup[
                                            gatewayOtaDetails.status
                                        ] || `-`}
                                    </h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-5 mb-5">
                            <h6 className="mb-3">Available Updates</h6>
                            <UpdateTabVersionSelected
                                {...{
                                    defaultVersion:
                                        gatewayOtaDetails.currentVersion,
                                    curVersion: selectedFWVersion,
                                    setVersion: setSelectedFWVersion,
                                }}
                            />
                            <Button
                                variant="primary pr-5 pl-5 mt-4"
                                disabled={!selectedFWVersion}
                                onClick={openUpdateConfirmation}
                            >
                                UPDATE
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {modal}
        </>
    );
};

export default UpdateTab;
