import React, { createContext, useContext, useState } from "react";

export const SubscriptionContext: any = createContext(null);

export const useSubscriptionContext = () => {
    return useContext(SubscriptionContext);
};

const SubscriptionProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [subscription, setSubscription] = useState({});

    return (
        <SubscriptionContext.Provider value={{ subscription, setSubscription }}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export default SubscriptionProvider;
