import AxiosInstance from "./axiosInstance";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "../utils/functions";
import { Account, apiFactory } from "generated";
import { AxiosError } from "axios";
import {
    AccountInfoPatch,
    MfaPreference,
    Password,
    VerifyPhone,
} from "generated/models";
import { ResponseDto } from "types";

export class AccountService {
    private accountClient: Account =
        apiFactory.getAccountInstance(AxiosInstance);

    private parseData(account: any) {
        const {
            phone_number_verified,
            email_verified,
            given_name,
            family_name,
            phone_number,
            email,
            UserMFASettingList,
            zoneinfo: timezone
        } = account;

        return {
            given_name,
            family_name,
            email,
            phone_number,
            email_verified: email_verified === "true",
            phone_number_verified: phone_number_verified === "true",
            UserMFASettingList,
            timezone
        };
    }
    /**
     * Get Account info
     * @returns { promise }
     */
    async getAccountInfo(): Promise<ResponseDto<any>> {
        try {
            const { status, data } = await this.accountClient.getAccount();
            const account = this.parseData(data.data);
            return {
                status,
                data: account,
            } as ResponseDto;
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Update Account info
     * @param { AccountInfoPatch } data - Account info
     * @returns { Promise }
     */
    async updateAccountInfo(data: AccountInfoPatch) {
        try {
            // const res = await AxiosInstance.patch(`${this.host}/account/info`, data);
            const res = await this.accountClient.updateAccount(data);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    }

    /**
     * Update MFA Status
     * @param { MfaPreference } data - MFA data
     * @returns { Promise }
     */
    async updateMFAStatus(data: MfaPreference) {
        try {
            const res = await this.accountClient.updateMfa(data);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    }

    /**
     * Get Verification Code
     * @returns { Promise }
     */
    async getVerifyCode() {
        try {
            const res = await this.accountClient.sendVerificationCode();
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    }

    /**
     * Submit Verification Code
     * @param { VerifyPhone } data - Verify Code data
     * @returns { Promise }
     */
    async submitVerifyCode(data: VerifyPhone) {
        try {
            const res = await this.accountClient.verifyPhoneNumberByCode(data);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    }

    /**
     * Terminate the Account
     * @param { Password } data - terminate data
     * @returns { Promise }
     */
    async terminate(data: Password) {
        try {
            const res = await this.accountClient.terminateAccount(data);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error as AxiosError);
        }
    }
}

const accountService = new AccountService();

export default accountService;
