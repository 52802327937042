import { FormEvent, ReactNode, useEffect, useState } from "react";
import _, { get, isEmpty, startCase, cloneDeep } from "lodash";
import { useParams, useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import {
    CHART_DUPLICATE_COLOR_MESSAGE,
    FETCH_CHART_FAIL_MESSAGE,
    INVALID_OPERATION_MESSAGE,
    HttpStatus,
} from "constant";
import create from "zustand";
import {
    UnitPicker,
    ColorPicker,
    ContainerStyled,
    useCreateDashboardStore,
    CHART_NAME_PATTERN,
    CHART_ERROR_MESSAGE,
} from "../create";
import { GatewayChartFor, GateWayChartType } from "../create/GatewayCharts";
import { OtherChartFor } from "../create/OtherCharts";
import { MultipleChartBlock } from "../create/MultipleChartBlock";
import { DeviceMapRequest, SensorChartType } from ".";
import dashboardService from "service/dashboardService";
import { showErrorAlert, getAPIError } from "utils/alert";
import { fetchDevices } from "utils/gatewayFunctions";
import usePanelConfiguration from "hooks/usePanelConfiguration";
import { useMetricDefined } from "hooks/useMetricDefined";
import { isHttpSuccess } from "utils/functions";

type useStoreProps = {
    deviceList: any[];
    setDeviceList: (deviceList: any[]) => void;
    allRegistry: any[];
    setAllRegistry: (allRegistry: any[]) => void;
    dashboardId: string;
    setDashboardId: (dashboardId: string) => void;
    panel: any;
    setPanel: (panel: any) => void;
    updatePanel: (key: string, value: any) => void;
    getDeviceInfo: (data: any) => any;
    isSaving: boolean;
    setIsSaving: (isSaving: boolean) => void;
};

const useStore = create<useStoreProps>((set, get) => ({
    deviceList: [],
    setDeviceList: (deviceList) => {
        set({ deviceList });
        const store = get();
        store.getDeviceInfo(store.panel);
    },
    allRegistry: [],
    setAllRegistry: (allRegistry) => set({ allRegistry }),
    dashboardId: "",
    setDashboardId: (dashboardId) => set({ dashboardId }),
    panel: null,
    setPanel: (panel) => set({ panel }),
    updatePanel: (key, value) => {
        if (
            typeof _.get(get().panel, String(key)) === "undefined" ||
            _.get(get().panel, String(key)) === value
        )
            return;

        set((state) => _.set(state.panel, String(key), value));
    },
    getDeviceInfo: (data) => {
        if (!isEmpty(data)) {
            let deviceInfo: any = {};

            if (data.attributes?.length === 0) {
                return deviceInfo;
            }

            if (data.source === "SENSOR" || data.source === "ACTUATOR") {
                deviceInfo = get().deviceList?.find(
                    (device) =>
                        device.ldsu_uuid === data.attributes[0].device_id &&
                        Number(device.SAID) === Number(data.attributes[0].said)
                );
            } else if (data.source === "GATEWAY") {
                deviceInfo = get().allRegistry?.find(
                    (registry) =>
                        registry.gateway_id === data.attributes[0].gateway_id
                );
            }

            return deviceInfo || {};
        }
    },
    isSaving: false,
    setIsSaving: (isSaving) => set({ isSaving }),
}));

type UpdateSingleChartProp = {
    [key: string]: {
        key: string;
        label: string;
        render?: (data: any, store: useStoreProps) => ReactNode | string;
    }[];
};

const UpdateSingleChartTemplateRenderForName = (data: any, store: any) => (
    <Form.Control
        type="text"
        className="single-update-form-control"
        placeholder="Enter chart name"
        defaultValue={data.name}
        onChange={(e) => store.updatePanel("name", e.target.value)}
    />
);

const MultipleChartUpdate = ({
    panel,
    onSubmitMultipleChart,
}: {
    panel: any;
    onSubmitMultipleChart: Function;
}) => {
    const { deviceList, updatePanel } = useStore();

    const { defaultValue } = usePanelConfiguration({
        panelData: panel,
        deviceList,
    });

    return (
        <MultipleChartBlock
            blockKey={{ key: _.get(panel, "uuid", "sensor") }}
            defaultValue={defaultValue}
            isSingleUpdate
            isEdit
            onChange={(updateData) => {
                if (!_.isEmpty(updateData.data)) {
                    updatePanel("name", updateData.chart_name);
                    const updateDataDevices = {
                        ...(_.get(updateData, "data[0].devices") || {}),
                        ...(_.get(updateData, "data[1].devices") || {}),
                    };

                    updatePanel("attributes", Object.values(updateDataDevices));
                }
            }}
            onSubmitMultipleChart={onSubmitMultipleChart}
        />
    );
};

const UpdateSingleChartTemplate: UpdateSingleChartProp = {
    SENSOR: [
        {
            key: "name",
            label: "Chart Name",
            render: UpdateSingleChartTemplateRenderForName,
        },
        {
            key: "sensor_name",
            label: "Sensor",
            render: (data, store) => {
                const deviceName = store.getDeviceInfo(data).device_name;
                return (
                    <span
                        className={`d-flex ${
                            deviceName ? "mute" : "text-secondary-red-1"
                        }`}
                    >
                        {!deviceName && (
                            <span className="material-icons mr-2">
                                error_outline
                            </span>
                        )}
                        <span>
                            {deviceName ?? "Sensor information not found"}
                        </span>
                    </span>
                );
            },
        },
        {
            key: "type",
            label: "Type",
            render: (data, store) => (
                <span className="mute">
                    {get(data, "attributes[0].name", "-")}
                </span>
            ),
        },
        {
            key: "gateway",
            label: "Gateway",
            render: (data, store) => {
                const gatewayId = get(data, "attributes[0].gateway_id", "");

                const gateway = store.allRegistry.find(
                    ({ gateway_id }: { gateway_id: string }) =>
                        gateway_id === gatewayId
                );
                const gatewayName = get(gateway, "name", "");

                return (
                    <span
                        className={`d-flex ${
                            gatewayName ? "mute" : "text-secondary-red-1"
                        }`}
                    >
                        {!gatewayName && (
                            <span className="material-icons mr-2">
                                error_outline
                            </span>
                        )}
                        <span>
                            {gatewayName ?? "Gateway information not found"}
                        </span>
                    </span>
                );
            },
        },
        {
            key: "ldsu",
            label: "LDSU UUID",
            render: (data) => {
                const ldsuId = get(data, "attributes[0].device_id", "");

                return (
                    <span
                        className={`d-flex ${
                            ldsuId ? "mute" : "text-secondary-red-1"
                        }`}
                    >
                        {!ldsuId && (
                            <span className="material-icons mr-2">
                                error_outline
                            </span>
                        )}
                        <span>{ldsuId ?? "LDSU information not found"}</span>
                    </span>
                );
            },
        },
        {
            key: "unit",
            label: "Unit",
            render: (data, store) => {
                const deviceInfo = store.getDeviceInfo(data);

                return (
                    <UnitPicker
                        defaultValue={_.get(data, "attributes[0].unit")}
                        data={
                            deviceInfo?.units ||
                            deviceInfo?.unit
                                ?.split(",")
                                .map((item: string) => item.trim())
                        }
                        onChange={(e, value) =>
                            store.updatePanel("attributes[0].unit", value)
                        }
                        className="chart-col"
                    />
                );
            },
        },
        {
            key: "color",
            label: "Color",
            render: (data, store) => (
                <ColorPicker
                    defaultValue={_.get(data, "attributes[0].color")}
                    onChange={(e, value) =>
                        store.updatePanel("attributes[0].color", value)
                    }
                    className="chart-col"
                />
            ),
        },
        {
            key: "show_alert",
            label: "Show alert lines",
            render: (data, store) => {
                const deviceInfo = store.getDeviceInfo(data);
                const alertAvailable = get(
                    deviceInfo,
                    "alert_available",
                    false
                );
                return (
                    <div className="single-edit-switch">
                        <Form.Check
                            type="switch"
                            id={`switch-alert-${get(
                                deviceInfo,
                                "device_id",
                                ""
                            )}`}
                            label=""
                            checked={
                                alertAvailable
                                    ? Boolean(
                                          get(data, "attributes[0].show_alert")
                                      )
                                    : false
                            }
                            disabled={!alertAvailable}
                            onClick={(event) => {
                                store.updatePanel(
                                    "attributes[0].show_alert",
                                    get(event, "target.checked")
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ],
    ACTUATOR: [
        {
            key: "name",
            label: "Chart Name",
            render: UpdateSingleChartTemplateRenderForName,
        },
        {
            key: "actuator_name",
            label: "Actuator",
            render: (data, store) => {
                const deviceName = store.getDeviceInfo(data).device_name;
                return (
                    <span
                        className={`d-flex ${
                            deviceName ? "mute" : "text-secondary-red-1"
                        }`}
                    >
                        {!deviceName && (
                            <span className="material-icons mr-2">
                                error_outline
                            </span>
                        )}
                        <span>
                            {deviceName ?? "Actuator information not found"}
                        </span>
                    </span>
                );
            },
        },
        {
            key: "type",
            label: "Type",
            render: (data, store) => (
                <span className="mute">
                    {startCase(get(data, "attributes[0].name", "-"))}
                </span>
            ),
        },
        {
            key: "gateway",
            label: "Gateway",
            render: (data, store) => {
                const gatewayId = get(data, "attributes[0].gateway_id", "");

                const gateway = store.allRegistry.find(
                    ({ gateway_id }: { gateway_id: string }) =>
                        gateway_id === gatewayId
                );
                const gatewayName = get(gateway, "name", "");
                return (
                    <span
                        className={`d-flex ${
                            gatewayName ? "mute" : "text-secondary-red-1"
                        }`}
                    >
                        {!gatewayName && (
                            <span className="material-icons mr-2">
                                error_outline
                            </span>
                        )}
                        <span>
                            {gatewayName ?? "Gateway information not found"}
                        </span>
                    </span>
                );
            },
        },
        {
            key: "ldsu",
            label: "LDSU UUID",
            render: (data) => {
                const ldsuId = get(data, "attributes[0].device_id", "");

                return (
                    <span
                        className={`d-flex ${
                            ldsuId ? "mute" : "text-secondary-red-1"
                        }`}
                    >
                        {!ldsuId && (
                            <span className="material-icons mr-2">
                                error_outline
                            </span>
                        )}
                        <span>{ldsuId ?? "LDSU information not found"}</span>
                    </span>
                );
            },
        },
        {
            key: "color",
            label: "Color",
            render: (data, store) => (
                <ColorPicker
                    defaultValue={_.get(data, "attributes[0].color")}
                    onChange={(e, value) =>
                        store.updatePanel("attributes[0].color", value)
                    }
                    className="chart-col"
                />
            ),
        },
    ],
    GATEWAY: [
        {
            key: "name",
            label: "Chart Name",
            render: UpdateSingleChartTemplateRenderForName,
        },
        {
            key: "gateway_name",
            label: "Gateway",
            render: (data, store) => (
                <span className="mute">
                    {store.getDeviceInfo(data).name || "-"}
                </span>
            ),
        },
        {
            key: "attributes[0].type_",
            label: "Chart for",
            render: (data, store) => (
                <span className="mute">
                    {
                        GatewayChartFor[
                            _.get(
                                data,
                                "attributes[0].type_"
                            ).capitalize() as keyof typeof GatewayChartFor
                        ]
                    }
                </span>
            ),
        },
        {
            key: "chart",
            label: "Chart Type",
            render: (data, store) => (
                <Form.Control
                    className="single-update-form-control"
                    as="select"
                    defaultValue={_.get(data, "chart")}
                    onChange={(e) => store.updatePanel("chart", e.target.value)}
                >
                    {Object.keys(GateWayChartType).map((item) => (
                        <option key={item} value={item.toUpperCase()}>
                            {
                                GateWayChartType[
                                    item as keyof typeof GateWayChartType
                                ]
                            }
                        </option>
                    ))}
                </Form.Control>
            ),
        },
    ],
    OTHER: [
        {
            key: "name",
            label: "Chart Name",
            render: UpdateSingleChartTemplateRenderForName,
        },
        {
            key: "attributes[0].type_",
            label: "Chart for",
            render: (data, store) => (
                <span className="mute">
                    {
                        OtherChartFor[
                            _.get(data, "attributes[0].type_") === "COUNT_ALERT"
                                ? "Notification"
                                : "Event"
                        ]
                    }
                </span>
            ),
        },
    ],
};

const UpdateChartPanel = (props: any) => {
    const store = useStore();
    const { panel, isSaving } = store;
    const template = cloneDeep(UpdateSingleChartTemplate[panel?.source]);

    return (
        <fieldset disabled={isSaving}>
            <Row>
                <div className="w-100 mb-5">
                    <Col sm={12} className="form-box">
                        <Row className="cstm-table">
                            <Col sm={12}>
                                <div className="table-line border-0 py-0">
                                    {panel?.source === "SENSOR" &&
                                    panel.chart === SensorChartType.STACKED ? (
                                        <MultipleChartUpdate
                                            panel={panel}
                                            onSubmitMultipleChart={
                                                props.onSubmitMultipleChart
                                            }
                                        />
                                    ) : (
                                        template?.map((item) => {
                                            return (
                                                <Row
                                                    key={item.key}
                                                    className="update-row justify-content-between align-items-center"
                                                >
                                                    <Col>{item.label}</Col>
                                                    <Col className="d-flex justify-content-end">
                                                        {item.render?.(
                                                            panel,
                                                            store
                                                        ) ||
                                                            _.get(
                                                                panel,
                                                                item.key
                                                            ) ||
                                                            "-"}
                                                    </Col>
                                                </Row>
                                            );
                                        })
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Row>
        </fieldset>
    );
};

const UpdatePanel = () => {
    const { dashboardId, panelId } = useParams<any>();
    const [loading, setLoading] = useState(true);
    const {
        panel,
        setPanel,
        setDeviceList,
        setAllRegistry,
        isSaving,
        setIsSaving,
    } = useStore();
    const history = useHistory();
    useMetricDefined();

    useEffect(() => {
        fetchDevices().then((res: any) => {
            setDeviceList(res.deviceList || []);
            setAllRegistry(res.allRegistry || []);
            useCreateDashboardStore.setState({
                deviceList: res.deviceList || [],
                allRegistry: res.allRegistry || [],
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoading(true);
        setPanel({});
        dashboardService
            .getSinglePanel(dashboardId, panelId)
            .then((res: any) => {
                if (!isHttpSuccess(res?.status)) {
                    showErrorAlert(getAPIError(res, FETCH_CHART_FAIL_MESSAGE));
                    return;
                }

                setPanel(_.get(res, "data"));
            })
            .finally(() => setLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardId, panelId]);

    const UpdateContainer =
        panel?.source === "SENSOR" && SensorChartType.STACKED === panel?.chart
            ? ContainerStyled
            : Container;

    const handleUpdate = async (e: FormEvent<HTMLFormElement> | any) => {
        e.preventDefault();
        if (!panel?.uuid) {
            return;
        }

        setIsSaving(true);
        const paramsTemplate = _.cloneDeep(
            DeviceMapRequest[panel.source as keyof typeof DeviceMapRequest]
        );

        const params = objectFluent(paramsTemplate, panel);

        const uniqueColors = _.unionBy(params.attributes, ({ color }) =>
            _.lowerCase(color)
        );

        if (!CHART_NAME_PATTERN.test(panel.name.trim())) {
            setIsSaving(false);
            showErrorAlert({ message: CHART_ERROR_MESSAGE });
            return;
        }

        if (uniqueColors.length < params.attributes.length) {
            setIsSaving(false);
            showErrorAlert({
                message: CHART_DUPLICATE_COLOR_MESSAGE,
            });
            return;
        }

        try {
            const updatePanelResponse = await dashboardService.updatePanel(
                dashboardId,
                panel?.uuid,
                {
                    ...params,
                    name: _.trim(params.name.replace(/\s+/g, " ")),
                }
            );

            if (!isHttpSuccess(updatePanelResponse.status)) {
                showErrorAlert(
                    getAPIError(updatePanelResponse, INVALID_OPERATION_MESSAGE)
                );
            }

            if (updatePanelResponse.status === HttpStatus.NO_CONTENT) {
                history.push("/dashboard");
            }
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <div className="page-content update-single-screen">
                {loading ? (
                    <Spinner animation="border" variant="primary" />
                ) : (
                    <UpdateContainer>
                        <Form onSubmit={handleUpdate}>
                            <Row>
                                <Col sm="12">
                                    <h5 className="page-title">
                                        Edit Dashboard Chart
                                    </h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <UpdateChartPanel
                                        onSubmitMultipleChart={handleUpdate}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    sm="12"
                                    className="d-flex justify-content-center"
                                >
                                    <Button
                                        className="text-uppercase"
                                        variant="secondary"
                                        onClick={() => {
                                            history.push("/dashboard");
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        className="text-uppercase ml-2"
                                        variant="primary"
                                        type="submit"
                                        disabled={isSaving}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </UpdateContainer>
                )}
            </div>
            <style>{`
                .table-line {
                    margin-top: -0.75rem;
                    margin-bottom: -0.75rem;
                }

                .update-row {
                    height: 3.5rem;
                }

                .update-row + .update-row {
                    border-top: 1px solid #3E4B67;
                }

                .single-update-form-control.form-control {
                    width: unset;
                }

                .mute {
                    color: #828FAB
                }

                .single-edit-switch .custom-switch {
                    right: 1rem;
                }
            `}</style>
        </>
    );
};

export default UpdatePanel;
