import { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { faker } from "@faker-js/faker";
import {
    OrganisationPermissions,
    Permission,
    PermissionDto,
    PermissionKey,
} from "types";

export const DefaultPermissions: OrganisationPermissions = {
    gateway: "Viewer",
    event: "Viewer",
    dashboard: "Viewer",
    organisation: "Viewer",
};

const DefaultAccordionActive: Record<string, boolean> = {
    gateway: true,
    event: true,
    dashboard: true,
    organisation: true,
};

type PermissionListProps = {
    values: OrganisationPermissions;
    onChange: (values: OrganisationPermissions) => void;
    isDefault?: boolean;
};

const DefaultPermissionList: PermissionDto[] = [
    {
        name: "Gateway Management",
        description: "All Gateways and Gateway Groups",
        subDescription: "Gateways settings, Sensors, Actuators, etc.",
        value: "Viewer",
        key: "gateway",
    },
    {
        name: "Dashboard Management",
        description: "All Dashboards",
        subDescription: "Dashboards and Charts",
        value: "Viewer",
        key: "dashboard",
    },
    {
        name: "Event Management",
        description: "All events",
        subDescription: "Create, Edit, View and Delete Events",
        value: "Viewer",
        key: "event",
    },
    {
        name: "Organisation Management",
        description: "Manage organisations",
        subDescription: "Groups, Roles and Members",
        value: "Null",
        key: "organisation",
    },
];

const PermissionList = (props: PermissionListProps) => {
    const { values, onChange, isDefault = false } = props;
    const [selectedPermissions, setSelectedPermissions] =
        useState(DefaultPermissions);
    const [accordionActive, setAccordionActive] = useState(
        DefaultAccordionActive
    );
    const [permissions, setPermissions] = useState<PermissionDto[]>(
        DefaultPermissionList
    );

    useEffect(() => {
        setSelectedPermissions(values || DefaultPermissions);
        processPermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const processPermissions = () => {
        setPermissions(
            permissions.map((p) => ({ ...p, value: values[p.key] }))
        );
    };

    const handleChangePermission = (e: any) => {
        const { name: permission, value } = e.target;
        let newData = {
            ...selectedPermissions,
            [permission]: value,
        };

        if (permission === "gateway") {
            if (value === "Null") {
                newData = {
                    ...newData,
                    dashboard: "Null",
                    event: "Null",
                };
            } else if (value === "Viewer") {
                newData = {
                    ...newData,
                    event: newData.event !== "Null" ? "Viewer" : "Null",
                };
            }
        }

        setSelectedPermissions(newData);
        onChange(newData);
    };

    const isDisabled = (key: PermissionKey, permission: Permission) => {
        if (isDefault) {
            return true;
        }

        if (["gateway", "organisation"].includes(key)) {
            return false;
        }

        if (
            key === "event" &&
            permission === "Editor" &&
            selectedPermissions.gateway === "Viewer"
        ) {
            return true;
        }

        return selectedPermissions.gateway === "Null";
    };

    const handleChangeAccordion = (activeKey: string, e: string | null) => {
        setAccordionActive({
            ...accordionActive,
            [activeKey]: e !== null,
        });
    };

    const generatePermissionContent = (permission: PermissionDto) => {
        return (
            <Row className="permission-item" key={faker.datatype.uuid()}>
                <Col md={6} xs={12}>
                    <h6 className="mb-2 mb-md-0">
                        {permission.subDescription}
                    </h6>
                </Col>
                {generateAccessGroups(permission)}
            </Row>
        );
    };

    const generateAccessGroups = ({ value, key }: PermissionDto) => {
        return (
            <>
                <Col
                    data-can="organisation:update"
                    md={2}
                    xs={12}
                    className="mt-2 mb-2 mb-md-0 mt-md-0"
                >
                    <Form.Check
                        disabled={isDisabled(key, "Editor")}
                        name={key}
                        id={`${key}-can-manage`}
                        custom
                        type="radio"
                        value="Editor"
                        checked={selectedPermissions[key] === "Editor"}
                        onChange={handleChangePermission}
                        label={<span className="ml-2">Can Manage</span>}
                    />
                </Col>
                <Col
                    data-can="organisation:update"
                    md={2}
                    xs={12}
                    className="mt-2 mb-2 mb-md-0 mt-md-0"
                >
                    <Form.Check
                        disabled={isDisabled(key, "Viewer")}
                        name={key}
                        id={`${key}-view-only`}
                        custom
                        type="radio"
                        value="Viewer"
                        checked={selectedPermissions[key] === "Viewer"}
                        onChange={handleChangePermission}
                        label={<span className="ml-2">View Only</span>}
                    />
                </Col>
                {key !== "organisation" && (
                    <Col
                        data-can="organisation:update"
                        md={2}
                        xs={12}
                        className="mt-2 mb-2 mb-md-0 mt-md-0"
                    >
                        <Form.Check
                            disabled={isDisabled(key, "Null")}
                            name={key}
                            id={`${key}-no-access`}
                            custom
                            type="radio"
                            value="Null"
                            checked={selectedPermissions[key] === "Null"}
                            onChange={handleChangePermission}
                            label={<span className="ml-2">No Access</span>}
                        />
                    </Col>
                )}
            </>
        );
    };

    return (
        <>
            {permissions.map((permission: any) => (
                <Accordion
                    onSelect={(eventKey) =>
                        handleChangeAccordion(permission.key, eventKey)
                    }
                    defaultActiveKey={`${permission.key}`}
                    key={permission.key}
                    activeKey={
                        accordionActive[permission.key] ? permission.key : ""
                    }
                >
                    <div className="form-box permission">
                        <Accordion.Toggle
                            eventKey={`${permission.key}`}
                            className="permission-box"
                        >
                            <h6>{permission.name}</h6>
                            <p>{permission.description}</p>
                            <i className={`material-icons down`}>
                                keyboard_arrow_
                                {accordionActive[permission.key]
                                    ? "down"
                                    : "up"}
                            </i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`${permission.key}`}>
                            {generatePermissionContent(permission)}
                        </Accordion.Collapse>
                    </div>
                </Accordion>
            ))}
        </>
    );
};

export default PermissionList;
