import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import moment, { Moment } from "moment";
import { getOneRegistry, downloadStorage } from "service/gatewayService";
import downloadIcon from "assets/svg/download.svg";
import { walletModalTrigger } from "hooks/wallet";
import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { HttpStatus } from "constant";
import TimezoneDropdown from "components/common/TimezoneDropdown";
import { useOrganisation } from "hooks/organization";
import DateTimeRangePicker from "components/common/DateTimeRangePicker";
import { BasicPlanModal } from "./BasicPlanModal";
import { useBasicPlanModal } from "hooks/useBasicPlanModal";
import { useCheckIfBasicPlan } from "hooks/useCheckIfBasicPlan";

const { OK, CREATED } = HttpStatus;

export const DownloadDataUI = ({
    registryDetails,
    gatewayId,
    ldsuId,
    said,
    downloadType,
    disabled,
}: any) => {
    const { defaultTimeZone } = useOrganisation();
    const [startDateTime, setStartDateTime] = useState(
        moment().tz(defaultTimeZone)
    );
    const [endDateTime, setEndDateTime] = useState(
        moment().tz(defaultTimeZone)
    );
    const [minDate, setMinDate] = useState(
        moment.utc("1970-01-01").tz(defaultTimeZone)
    );
    const [onboardTime, setOnboardTime] = useState(0);
    const [downloadOption, setDownloadOption] = useState("downloadAll");

    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const MODAL_CONFIRM = "confirm";
    const MODAL_SUCCESS = "success";
    const MODAL_ERROR = "err";
    const DOWNLOAD_BY_DATE = "downloadByDate";
    const [error, setError] = useState(false);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const [timezone, setTimezone] = useState<string>(defaultTimeZone);
    const { IS_BASIC_PLAN } = useCheckIfBasicPlan();
    const { toggleShowBasicPlanModal, showBasicPlanModal }: any =
        useBasicPlanModal();

    useEffect(() => {
        if (registryDetails) {
            const gatewayOnboardTime = moment(
                registryDetails.create_time
            ).unix();
            setOnboardTime(gatewayOnboardTime);
            const twentyFourHoursAgo = moment().subtract(24, "hours").unix();

            if (gatewayOnboardTime > twentyFourHoursAgo) {
                setStartDateTime(registryDetails.create_time);
            } else {
                setStartDateTime(
                    moment().subtract(24, "hours").tz(defaultTimeZone)
                );
            }
        } else {
            const fetch = async () => {
                const response: any = await getOneRegistry(gatewayId);
                const { data: registry, status } = response;
                if (status === OK) {
                    const gatewayOnboardTime = moment(
                        registry.create_time
                    ).unix();
                    setOnboardTime(gatewayOnboardTime);
                    const twentyFourHoursAgo = moment()
                        .subtract(24, "hours")
                        .unix();

                    if (gatewayOnboardTime > twentyFourHoursAgo) {
                        setStartDateTime(registry.create_time);
                    } else {
                        setStartDateTime(
                            moment().subtract(24, "hours").tz(defaultTimeZone)
                        );
                    }
                }
            };
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gatewayId, registryDetails]);

    useEffect(() => {
        setTimezone(defaultTimeZone);
        setMinDate(minDate.tz(timezone));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultTimeZone]);

    const updateOption = (e: any) => {
        const {
            target: { value },
        } = e;
        setDownloadOption(value);
        if (value === DOWNLOAD_BY_DATE) {
            setStartDateTime(startDateTime.tz(timezone));
            setEndDateTime(endDateTime.tz(timezone));
            setMinDate(minDate.tz(timezone));
        }
    };

    const handleDownload = async () => {
        let response: any = await downloadStorage({
            start:
                downloadOption === DOWNLOAD_BY_DATE
                    ? moment(startDateTime).seconds(0).unix()
                    : moment(onboardTime).tz(timezone).unix(),
            end:
                downloadOption === DOWNLOAD_BY_DATE
                    ? moment(endDateTime).seconds(0).unix()
                    : moment().tz(timezone).unix(),
            attributes: [
                {
                    gateway_id:
                        downloadType === "downloadGateway"
                            ? registryDetails.gateway_id
                            : gatewayId,
                    ...(downloadType === "downloadLDSU" ||
                    downloadType === "downloadSensor"
                        ? { device_id: ldsuId }
                        : {}),
                    ...(downloadType === "downloadSensor"
                        ? { said: Number(said) }
                        : {}),
                },
            ],
            time_zone: timezone,
        });

        if (response.status === CREATED) {
            setSimpleModalData({
                resObj: response,
                body: `An email will be sent to you shortly once it is ready to download.`,
            } as SimpleModalDataType);
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const renderModalIcon = () => {
        if (modalType === MODAL_ERROR) {
            return <span className="material-icons">warning</span>;
        } else if (modalType === MODAL_CONFIRM) {
            return <span className="material-icons">forward_to_inbox</span>;
        }
        return <span className="material-icons">done</span>;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        } else if (modalType === MODAL_CONFIRM) {
            return <h3 className="mb-3">Confirmation</h3>;
        }
        return <h3 className="mb-3">Success</h3>;
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR || modalType === MODAL_SUCCESS) {
            return (
                <Button variant="primary" onClick={() => setModalShow(false)}>
                    OK
                </Button>
            );
        }
        return (
            <>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setModalShow(false);
                    }}
                >
                    Cancel
                </Button>

                <Button
                    variant="primary"
                    onClick={() => {
                        setModalShow(false);
                        handleDownload();
                    }}
                >
                    OK
                </Button>
            </>
        );
    };

    const handleDateTimeRangeChange = (value: Moment[]) => {
        setStartDateTime(value[0]);
        setEndDateTime(value[1]);
    };

    const handleDateTimeRangeError = (error: string) => {
        setError(!!error);
    };

    const handleChangeTimeZone = (timezone: string) => {
        setTimezone(timezone);
    };

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <div className="mt-3">
                <Form.Label>Select a time zone</Form.Label>
                <TimezoneDropdown
                    value={timezone}
                    onChange={handleChangeTimeZone}
                    className="dark"
                    disabled={disabled}
                />
            </div>
            <Form.Label className="mt-4">Select data to include</Form.Label>
            <HoverAuthorizeTooltip permission="gateway:update">
                <div className="download-data-option ">
                    <Form.Check
                        name="download-data"
                        custom
                        type="radio"
                        value="downloadAll"
                        id={"download-all"}
                        checked={downloadOption === "downloadAll"}
                        onChange={(e: any) => {
                            updateOption(e);
                        }}
                        label={<span className="ml-2">Download all data</span>}
                        disabled={disabled}
                    />
                </div>
            </HoverAuthorizeTooltip>
            <HoverAuthorizeTooltip permission="gateway:update">
                <div className="download-data-option">
                    <Form.Check
                        name="download-data"
                        custom
                        type="radio"
                        value={DOWNLOAD_BY_DATE}
                        id={"pick-date"}
                        checked={downloadOption === DOWNLOAD_BY_DATE}
                        onChange={(e: any) => {
                            updateOption(e);
                        }}
                        label={<span className="ml-2">Pick date range</span>}
                        disabled={disabled}
                    />
                    {downloadOption === DOWNLOAD_BY_DATE ? (
                        <div className="mt-4 mb-4">
                            <DateTimeRangePicker
                                value={[startDateTime, endDateTime]}
                                timezone={timezone}
                                onChange={handleDateTimeRangeChange}
                                onError={handleDateTimeRangeError}
                            />
                        </div>
                    ) : null}
                </div>
            </HoverAuthorizeTooltip>
            <HoverAuthorizeTooltip
                permission="gateway:update"
                className="w-100"
            >
                <Button
                    disabled={disabled || (error && downloadOption === DOWNLOAD_BY_DATE)}
                    className="w-100 mt-3"
                    onClick={() => {
                        if (IS_BASIC_PLAN) {
                            toggleShowBasicPlanModal();
                        } else {
                            walletModalTrigger(() => {
                                setModalShow(true);
                                setModalType(MODAL_CONFIRM);
                                setModalContent(
                                    " By confirming, an email will be sent to your email address with instructions on how to download. Do you want to continue?"
                                );
                            });
                        }
                    }}
                >
                    <img src={downloadIcon} alt="" />
                    Download Data
                </Button>
            </HoverAuthorizeTooltip>
            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    modalType === MODAL_ERROR ? "danger" : "primary"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>
                    {renderModalButton()}
                </Modal.Body>
            </Modal>
            <BasicPlanModal
                showBasicPlanModal={showBasicPlanModal}
                toggleShowBasicPlanModal={toggleShowBasicPlanModal}
            />
        </ContentWrapper>
    );
};
