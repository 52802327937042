import { isHttpSuccess } from "./../utils/functions";
import {
    orgIdQuery,
    orgId,
    createResponseStandard,
    getPrimaryUnit,
    getPrimaryUnitName,
} from "../utils/functions";
import AxiosInstance from "./axiosInstance";
import { get, orderBy, size } from "lodash";
import LocalStorageService from "service/localStorageService";
import { HttpStatus } from "constant";
import {
    createErrorResponseStandard,
    isActuator,
    isSensor,
    clsToAttributeTable,
} from "utils/functions";
import { RequestParams, apiFactory } from "generated";
import {
    ActuatorCommand,
    ActuatorCommandPatch,
    Alert,
    AlertNoticePatch,
    AlertPatch,
    AlertPost,
    Deletion,
    DeletionPost,
    DeviceConfig,
    Gateway,
    GatewayStatus,
    Group,
    MultipleDevicesConfigPatch,
    Ota,
    Registry,
    RegistryPatch,
    RegistryPost,
    SensorConfigPatch,
    Takeout2,
    UartConfig,
    UartConfigPost,
    ActuatorCommandPost,
    MessageNoticePatch,
    Takeout2Post,
} from "generated/models";
import { AxiosError } from "axios";

const gatewayClient = apiFactory.getGatewaysInstance(AxiosInstance);
const gatewayGroupClient = apiFactory.getGatewaygroupsInstance(AxiosInstance);
const firmwareClient = apiFactory.getFirmwareInstance(AxiosInstance);

export const addDevices = async (
    uuid: string,
    serialNumber: string,
    macAddress: string,
    gatewayName: string
) => {
    try {
        const body: RegistryPost = {
            gateway_id: uuid,
            serial: serialNumber,
            mac: macAddress,
            organization_id: orgId(),
            name: gatewayName,
        };
        const response = await gatewayClient.createRegisteredGw(body);
        return createResponseStandard<Registry>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getGatewayUART = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getGwuartById(gatewayId, {
            query: {
                organization_id: orgId(),
            },
        });
        return createResponseStandard<UartConfig>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateGatewayUART = async ({
    gatewayId,
    body,
}: {
    gatewayId: string;
    body: UartConfigPost;
}) => {
    try {
        const response = await gatewayClient.updateUartById(gatewayId, body, {
            query: {
                organization_id: orgId(),
            },
        });
        return createResponseStandard<UartConfig>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getUARTnoticeConfig = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getMessageNotificationByGwId(
            gatewayId
        );

        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateUARTnoticeConfig = async ({
    gatewayId,
    data,
}: {
    gatewayId: string;
    data: MessageNoticePatch;
}) => {
    try {
        const response = await gatewayClient.updateMessageNotificationByGwId(
            gatewayId,
            data
        );

        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateSensor = async (
    gatewayId: string,
    deviceId: string,
    said: string,
    body: SensorConfigPatch
) => {
    try {
        const response = await gatewayClient.updateSensorConfigBySaid(
            gatewayId,
            deviceId,
            said,
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getOtaStatus = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getGwFirmwareByGwId(gatewayId, {
            query: orgIdQuery(),
        });
        return createResponseStandard<GatewayStatus>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateFirmwareUpdates = async (
    gatewayId: string,
    version: string
) => {
    try {
        const response = await gatewayClient.updateGwFirmwareByGwId(gatewayId, {
            version,
        });
        return createResponseStandard<Ota>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const createNewAlertNotification = async (
    gatewayId: string,
    deviceId: string,
    said: string,
    body: AlertPost
) => {
    try {
        const response = await gatewayClient.createAlert(
            gatewayId,
            deviceId,
            said,
            body
        );
        return createResponseStandard<Alert>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getAllAlertNotification = async (
    gatewayId: string,
    deviceId: string,
    said: string,
    params?: RequestParams
) => {
    try {
        const response = await gatewayClient.getAlertBySaid(
            gatewayId,
            deviceId,
            said,
            params
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getGatewayAlertNotification = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getAlerts(gatewayId);
        return createResponseStandard<Alert[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateAlertNotification = async (
    gatewayId: string,
    deviceId: string,
    said: string,
    alertId: string,
    body: AlertPatch
) => {
    try {
        const response = await gatewayClient.updateAlertById(
            gatewayId,
            deviceId,
            said,
            alertId,
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const downloadStorage = async (body: Takeout2Post) => {
    try {
        const response = await gatewayClient.createTakeoutTask2(body);
        return createResponseStandard<Takeout2>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const clearGatewayData = async (
    gatewayId: string,
    body: DeletionPost
) => {
    try {
        const response = await gatewayClient.createNewDeletionTask(
            gatewayId,
            body
        );
        return createResponseStandard<Deletion>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

const getClearGatewayTasks = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getDeletionTasks(gatewayId);
        return createResponseStandard<Deletion[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getIsDeletionTaskRunning = async (gatewayId: string) => {
    try {
        const response = await getClearGatewayTasks(gatewayId);
        const { status, data } = response;
        const sortedList = orderBy(data, ["create_time"], ["desc"]);

        if (isHttpSuccess(status) && sortedList?.[0]?.status === null) {
            return true;
        }

        return false;
    } catch (error) {
        if (error instanceof AxiosError) {
            return false;
        }
        throw error;
    }
};

export const deleteRegistry = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.deleteRegisteredGwById(gatewayId);
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getAllRegistry = async (organizationId: string = orgId()) => {
    try {
        const response = await gatewayClient.getRegisteredGWs({
            organization_id: organizationId,
        });
        return createResponseStandard<Registry[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getOwnedRegistry = async (orgIds: string[] = [orgId()]) => {
    try {
        const query = {
            organisation_ids: orgIds.join(","),
        };
        const response = await gatewayClient.getRegistriesByOrganisationIds(
            query
        );
        return createResponseStandard<Registry[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getOneRegistry = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getRegisteredGwById(gatewayId);
        return createResponseStandard<Registry>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateOneRegistry = async (
    gatewayId: string,
    body: RegistryPatch
) => {
    try {
        const response = await gatewayClient.updateRegisteredGwById(
            gatewayId,
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getRegistryInfo = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getGwById(gatewayId);
        return createResponseStandard<Gateway>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getRegistryConfig = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getGwConfigById(gatewayId);
        return createResponseStandard<DeviceConfig[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateLDSUName = async (
    gatewayId: string,
    deviceId: string,
    newName: string
) => {
    try {
        const response = await gatewayClient.updateDeviceConfigById(
            gatewayId,
            deviceId,
            {
                name: newName,
            }
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const resetGateway = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.resetGwConfigById(gatewayId);
        return createResponseStandard<DeviceConfig[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const restartGateway = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.updateGwStatusById(gatewayId, {
            status: "RESTART",
        });
        return createResponseStandard<string>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const identifyLDSU = async (gatewayId: string, deviceId: string) => {
    try {
        const response =
            await gatewayClient.requestTriggerDeviceBlinkingLedById(
                gatewayId,
                deviceId
            );
        return createResponseStandard<string>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getGWReading = async () => {
    try {
        const response = await gatewayClient.getGwReadingByOrganizationId(
            orgIdQuery()
        );
        return createResponseStandard<object[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getGwAndLDSUStatus = async (gatewayId: string) => {
    try {
        const response = await gatewayClient.getDevicesReadingByGwId(gatewayId);
        return createResponseStandard<object[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const scanGateway = async (
    gatewayId: string,
    portNumber?: number,
    powerCycle: boolean = true
) => {
    try {
        const response = await gatewayClient.updateGwById(gatewayId, {
            PORT: portNumber,
            power_cycle: powerCycle,
        });
        return createResponseStandard<string>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const toggleBusPower = async (
    gatewayId: string,
    portNumber: number = 1,
    power: boolean
) => {
    try {
        const res = await gatewayClient.changePowerStateByPort(
            gatewayId,
            portNumber.toString(),
            {
                power,
            }
        );
        return createResponseStandard(res);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const getAllLastBusReading = async (
    gatewayId: string,
    portNumber: string,
    timeRange?: string,
    offset?: string
) => {
    try {
        const response = await gatewayClient.getDevicesReadingByPort(
            gatewayId,
            portNumber,
            {
                time_range: timeRange,
                offset,
            }
        );
        return createResponseStandard<object[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getSensorLastReading = async (
    gatewayId: string,
    deviceId: string,
    timeRange?: string,
    offset?: string
) => {
    try {
        const response = await gatewayClient.getDevicesReadingByDeviceId(
            gatewayId,
            deviceId,
            {
                time_range: timeRange,
                offset,
            }
        );
        return createResponseStandard<object[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getDeviceConfigById = async (
    gatewayId: string,
    deviceId: string,
    params?: RequestParams
) => {
    try {
        const response = await gatewayClient.getDeviceConfigById(
            gatewayId,
            deviceId,
            params
        );
        return createResponseStandard<DeviceConfig>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateDeviceConfigById = async (
    gatewayId: string,
    deviceId: string,
    body: any
) => {
    try {
        const response = await gatewayClient.updateDeviceConfigById(
            gatewayId,
            deviceId,
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getSensorConfig = async (
    gatewayId: string,
    deviceId: string,
    said: string
) => {
    try {
        const response = await gatewayClient.getSensorConfigById(
            gatewayId,
            deviceId,
            said
        );
        return createResponseStandard<object>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getRegistryGroups = async () => {
    try {
        const response = await gatewayGroupClient.getGwGroups(orgIdQuery());
        return createResponseStandard<Group[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const deleteRegistryGroup = async (groupId: string) => {
    try {
        const response = await gatewayGroupClient.deleteGwGroupById(groupId);
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const addRegistryGroup = async (groupName: string) => {
    try {
        const response = await gatewayGroupClient.createGwGroup({
            ...orgIdQuery(),
            name: groupName,
        });
        return createResponseStandard<Group>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const addRegistryToGroup = async (
    groupId: string,
    gatewayList: Array<string>
) => {
    try {
        const response = await gatewayGroupClient.updateGwGroupById(groupId, {
            add: gatewayList,
        });
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getOneGroupDetails = async (groupId: string) => {
    try {
        const response = await gatewayGroupClient.getGwGroupById(groupId);
        return createResponseStandard<Group>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateGatewayGroupName = async (
    groupId: string,
    groupName: string
) => {
    try {
        const response = await gatewayGroupClient.updateGwGroupById(groupId, {
            name: groupName,
        });
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateGatewayGroupFirmware = async (
    groupId: string,
    body: {
        selected: string[];
        version: string;
    }
) => {
    try {
        const response = await gatewayGroupClient.updateGwGroupFirmwareByGwId(
            groupId,
            body
        );
        return createResponseStandard<Ota[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const deleteGatewayFromGroup = async (
    groupId: string,
    deviceId: string
) => {
    try {
        const response = await gatewayGroupClient.updateGwGroupById(groupId, {
            remove: [deviceId],
        });
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const setGatewaysToGroup = async (
    groupId: string,
    deviceList: Array<string>
) => {
    try {
        const response = await gatewayGroupClient.updateGwGroupById(groupId, {
            add: deviceList,
        });
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getAllFirmwareList = async () => {
    try {
        const response = await firmwareClient.getFirmware();
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const deleteLDSBus = async (gatewayId: string, port: string) => {
    try {
        const response = await gatewayClient.deleteDeviceByPort(
            gatewayId,
            port,
            {
                query: orgIdQuery(),
            }
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const deleteOneLDSU = async (gatewayId: string, deviceId: string) => {
    try {
        const response = await gatewayClient.deleteDeviceById(
            gatewayId,
            deviceId,
            {
                query: orgIdQuery(),
            }
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getActuatorCommand = async (
    gatewayId: string,
    deviceId: string,
    said: string
) => {
    try {
        const response = await gatewayClient.getActuatorConfigBySaid(
            gatewayId,
            deviceId,
            said
        );
        return createResponseStandard<ActuatorCommand>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateActuatorCommand = async (
    gatewayId: string,
    deviceId: string,
    said: string,
    body: ActuatorCommandPatch
) => {
    try {
        const response = await gatewayClient.updateActuatorConfigBySaid(
            gatewayId,
            deviceId,
            said,
            body
        );
        return createResponseStandard<ActuatorCommand>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const toggleActuatorActivation = async (body: {
    gatewayId: string;
    deviceId: string;
    said: string;
    activate: boolean;
    immediately?: boolean;
}) => {
    try {
        const response = await gatewayClient.toggleActuatorBySaid(
            body.gatewayId,
            body.deviceId,
            body.said,
            {
                activate: body.activate,
                immediately: body.immediately,
            }
        );
        return createResponseStandard<ActuatorCommandPost>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getAssociationConfig = async (
    gatewayId: string,
    deviceId: string,
    said: string
) => {
    try {
        const response = await gatewayClient.getSensorConfigById(
            gatewayId,
            deviceId,
            said
        );
        return createResponseStandard<object>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateAssociationConfig = async (
    gatewayId: string,
    deviceId: string,
    said: string,
    body: SensorConfigPatch
) => {
    try {
        const response = await gatewayClient.updateSensorConfigBySaid(
            gatewayId,
            deviceId,
            said,
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const updateSensorNotice = async (
    gatewayId: string,
    deviceId: string,
    said: string,
    alertId: string,
    body: AlertNoticePatch
) => {
    try {
        const response = await gatewayClient.updateNotificationById(
            gatewayId,
            deviceId,
            said,
            alertId,
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

const forIOControllerTransform: (data: any) => any = (data) => {
    const appData = data.APP[data.SAID];

    // handle exception for Generic digital output (e.g. DIO Output 2) that have no UNITS []
    if (appData) {
        return {
            units: appData?.UNITS ?? ["na"],
            unit: getPrimaryUnit(appData) ?? "",
            APPID: appData?.APPID,
            min: getPrimaryUnitName(appData).MIN,
            max: getPrimaryUnitName(appData).MAX,
            accuracy: getPrimaryUnitName(appData).ACCURACY,
        };
    }
};

/* eslint-disable */
export const transformDeviceList = (
    infoList: any,
    configList: DeviceConfig[],
    registry: any = {}
) => {
    // Map for Sensor list
    let jsonList = LocalStorageService.getItem("listOfJSON");
    // Map with json File
    let filteredList: any[] = [];
    infoList.filter((i: any) => {
        configList.some((j: DeviceConfig) => {
            if (i.UID === j.UID) {
                let body = {
                    UID: i.UID,
                    product_name: i.NAME,
                    editable_name: j.name,
                    OBJ: i.OBJ,
                    SAID: j.SAIDS,
                    sensor_name: j.sensor_names,
                    bus: i.PORT,
                    gatewayName: registry.name,
                    gatewayId: registry.gateway_id,
                    APP: i.APP || null,
                    INTVL: j.INTVL,
                };
                filteredList.push(body);
            }
        });
    });
    let sensorFilteredLst: any = [];
    const classIds = clsToAttributeTable();
    filteredList.forEach((i: any) => {
        jsonList.some((j: any) => {
            if (i.OBJ === j.OBJ) {
                for (let k = 0; k < i.SAID.length; k++) {
                    let body: any = {
                        APP: i.APP,
                        ldsu_uuid: i.UID,
                        gatewayName: i.gatewayName,
                        SAID: k,
                        name: i.editable_name || i.sensor_name[k],
                        device_name: i.sensor_name[k],
                        sensor_type: i?.APP
                            ? i?.APP[k]?.NAME
                            : get(classIds, j.SNS[k]?.CLS),
                        bus: i.bus,
                        CLS: j.SNS[k]?.CLS,
                        gatewayId: i.gatewayId,
                        unit: j.SNS[k]?.UNIT,
                        min: j.SNS[k]?.MIN,
                        max: j.SNS[k]?.MAX,
                        accuracy: j.SNS[k]?.ACCURACY,
                        mode: j.SNS[k]?.MODE ?? null,
                        OBJ: i.OBJ,
                        INTVL: i.INTVL,
                        format: j.SNS[k]?.FORMAT,
                    };

                    if (body.APP) {
                        if (!size(body.APP[k])) {
                            continue;
                        }

                        body = {
                            ...body,
                            ...forIOControllerTransform(body),
                        };
                    }
                    sensorFilteredLst.push(body);
                }
            }
        });
    });

    // Filter away any actuator in the list.
    // TODO: When other sensor_type are accepted, amend filter condition
    // For 1.5.4 only "Temperature" sensor can be associated.
    // sensorFilteredLst = sensorFilteredLst.filter(
    //     (i: any) => i.sensor_type === "Temperature"
    // );
    return sensorFilteredLst;
};
/* eslint-enable */

export const getDeviceListByGatewayId = async (gatewayId: string) => {
    try {
        const [configRes, infoRes] = await Promise.all([
            getRegistryConfig(gatewayId),
            getRegistryInfo(gatewayId),
        ]);
        if (isHttpSuccess(configRes.status) && isHttpSuccess(infoRes.status)) {
            return transformDeviceList(
                get(infoRes, "data.devices", []),
                get(configRes, "data", []),
                get(infoRes, "data.registry", [])
            );
        }
        return [];
    } catch (error) {
        console.error(`Get sensors of gatewayID: ${gatewayId} error`);
        throw error;
    }
};

const transformLDSUList = async (
    infoList: any,
    configList: DeviceConfig[] = [],
    registry: any = {}
) => {
    return configList.map((i: any) => {
        return {
            ldsu_uuid: i.UID,
            gatewayName: registry.name,
            name: i.name,
            gatewayId: registry.gateway_id,
            bus: infoList.find((j: any) => j.UID === i.UID)?.PORT,
        };
    });
};

const getLDSUListByGatewayId = async (gatewayId: string) => {
    try {
        const configRes: any = await getRegistryConfig(gatewayId);
        const infoRes: any = await getRegistryInfo(gatewayId);
        if (
            configRes.status === HttpStatus.OK &&
            infoRes.status === HttpStatus.OK
        ) {
            return transformLDSUList(
                get(infoRes, "data.devices", []),
                get(configRes, "data", []),
                get(infoRes, "data.registry", [])
            );
        }
        return [];
    } catch (error) {
        console.error(`Get sensors of gatewayID: ${gatewayId} error`);
        throw error;
    }
};

export const getLDSUListByGatewayIds = async (gatewayIds: string[] = []) => {
    try {
        if (!gatewayIds || gatewayIds.length === 0) {
            const resAllRedisTry = await getAllRegistry();
            if (resAllRedisTry.status === HttpStatus.OK) {
                const { data = [] } = resAllRedisTry;
                gatewayIds = data.map(({ gateway_id }: any) => gateway_id);
            }
        }
        const sensorListArr = await Promise.all(
            gatewayIds.map((gateway_id: string) =>
                getLDSUListByGatewayId(gateway_id)
            )
        );
        return (sensorListArr || []).reduce(
            (retArr, arr) => [...retArr, ...arr],
            []
        );
    } catch (error) {
        throw error;
    }
};

const getDeviceListByGatewayIds = async (gatewayIds: string[] = []) => {
    try {
        if (!gatewayIds || gatewayIds.length === 0) {
            const resAllRedisTry = await getAllRegistry();
            if (resAllRedisTry.status === HttpStatus.OK) {
                const { data = [] } = resAllRedisTry;
                gatewayIds = data.map(({ gateway_id }: any) => gateway_id);
            }
        }
        const sensorListArr = await Promise.all(
            gatewayIds.map((gateway_id: string) =>
                getDeviceListByGatewayId(gateway_id)
            )
        );
        return (sensorListArr || []).reduce(
            (retArr, arr) => [...retArr, ...arr],
            []
        );
    } catch (error) {
        throw error;
    }
};

export const getSensorListByGatewayIds = async (gatewayIds: string[] = []) =>
    (await getDeviceListByGatewayIds(gatewayIds)).filter(({ CLS }: any) =>
        isSensor(CLS)
    );

export const getActuatorListByGatewayIds = async (gatewayIds: string[] = []) =>
    (await getDeviceListByGatewayIds(gatewayIds)).filter(({ CLS }: any) =>
        isActuator(CLS)
    );

export const fetchGWStatus = async (gatewayId: string) => {
    const res = await getGwAndLDSUStatus(gatewayId);

    if (res.status === HttpStatus.OK) {
        const { heartbeat = null } = res.data;
        if (heartbeat) {
            const timestamp = Math.round(new Date().getTime() / 1000);
            const difference = timestamp - heartbeat;
            return difference < 120;
        }
    }
    return false;
};

export const updateMultipleDevicesConfig = async (
    data: MultipleDevicesConfigPatch
) => {
    try {
        const res = await gatewayClient.updateMultipleDevicesConfig(data);
        return createResponseStandard(res);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const fetchDetailDevice = async (data: {
    gateway_id: string;
    device_id: string;
    said?: string | number;
}) => {
    const [registryInfo, registryConfig, allLdsuStatus] = await Promise.all([
        getRegistryInfo(data.gateway_id),
        getRegistryConfig(data.gateway_id),
        getGwAndLDSUStatus(data.gateway_id),
    ]);

    if (
        [
            registryInfo.status,
            registryConfig.status,
            allLdsuStatus.status,
        ].includes(HttpStatus.FORBIDDEN)
    ) {
        return registryInfo || registryConfig || allLdsuStatus;
    }

    const device = get(registryInfo, "data.devices", []).find(
        (device: any) => device.UID === data.device_id
    );
    const deviceConfig = get(registryConfig, "data", []).find(
        (device: any) => device.UID === data.device_id
    );
    const deviceStatus = get(allLdsuStatus, "data.devices", []).find(
        (device: any) => device.UID === data.device_id
    );

    const deviceInfo = {
        ...device,
        ...deviceConfig,
    };

    const infoJson = LocalStorageService.getItem("listOfJSON").find(
        (json: any) => json.OBJ === deviceInfo.OBJ
    );

    const metricInfo = LocalStorageService.getItem("metricInfo");

    let deviceData = {} as any;

    if (data.said) {
        const infoJsonDevice = infoJson.SNS.find(
            (json: any) => json.SAID === data.said
        );

        deviceData = {
            MIN: get(deviceInfo, `min.${data.said}`),
            MAX: get(deviceInfo, `max.${data.said}`),
            MODE: get(deviceInfo, `MODES.${data.said}`),
            SAID: data.said,
            deviceInfo: infoJsonDevice,
            device_name: deviceInfo.editable_name,
            device_id: deviceInfo.UID,
            type: deviceInfo?.APP
                ? metricInfo.app[deviceInfo.APP[data.said].APPID].name
                : metricInfo.cls[infoJsonDevice.CLS].name,
            readings: {
                SAID: data.said,
                lowest: get(deviceStatus, `min.${data.said}`),
                highest: get(deviceStatus, `max.${data.said}`),
                value: get(deviceStatus, `value.${data.said}`),
                timestamp: deviceStatus.ts,
            },
            port: deviceInfo.PORT,
        };
    }

    return {
        ...deviceInfo,
        gateway_name: get(registryInfo, "data.registry.name"),
        ...deviceData,
        infoJson,
    };
};
