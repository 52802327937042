import { useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import styled from "@emotion/styled";
import WifiOnboardImage from "assets/svg/wifiOnboard.svg";
import EthernetOnboardImage from "assets/svg/ethernetOnboard.svg";
import styledConst from "styles";
import { getOneRegistry } from "service/gatewayService";
import { showErrorAlert, showSuccessAlert } from "utils/alert";

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    width: 100%;
    height: fit-content;
    @media (max-width: 1500px) {
        grid-template-columns: 1fr;
    }
`;
const OnboardWrapper = styled.div`
    padding: 2.5rem;
    display: flex;
    align-items: start;
    gap: 1rem;

    @media (max-width: 700px) {
        display: grid;
    }
`;

const Image = styled.img`
    height: 250px;
    width: 250px;

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 1.5rem;
    }
`;

const Instruction = styled.div`
    font-size: 16px;
    padding-top: 35px;

    h2 {
        font-size: 16px;
    }

    p {
        color: ${styledConst.Primary_Blue_4};
        text-align: justify;
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

const OnboardInstructions = ({ fetchRegistry }: any) => {
    const { gatewayId }: { gatewayId: string } = useParams();

    const handleCheckStatus = async () => {
        const registryInfoRes: any = await getOneRegistry(gatewayId);

        if (!registryInfoRes?.data?.info) {
            showErrorAlert({ message: "No On-boarded gateway found" });
        } else {
            showSuccessAlert({
                message: "Gateway has been on-boarded successfully",
            });
            fetchRegistry();
        }
    };

    return (
        <Row>
            <Col sm={12}>
                <div className="form-box mb-2">
                    <h1 className="mb-3 not-onboard-notification">
                        This gateway is not on-boarded yet.
                    </h1>
                    <ContentWrapper>
                        <OnboardWrapper>
                            <Image src={EthernetOnboardImage}></Image>
                            <Instruction>
                                <h2>On-board Gateway using Ethernet</h2>
                                <p>
                                    Connect Gateway to an Ethernet network (PoE
                                    or otherwise). The network shall provide
                                    DHCP service and have a connection to the
                                    Internet. Then check Gateway status.
                                </p>
                                <Button onClick={handleCheckStatus}>
                                    Check Gateway Status
                                </Button>
                            </Instruction>
                        </OnboardWrapper>
                        <OnboardWrapper>
                            <Image src={WifiOnboardImage}></Image>
                            <Instruction>
                                <h2>
                                    On-board Gateway via Wi-Fi using an
                                    Android/iOS app
                                </h2>
                                <p>
                                    1. Log in to this account on your
                                    Android/iOS IoT Portal app. <br />
                                    2. If this Gateway belongs to any
                                    organisation, switch to your organisation.
                                    <br /> 3. Find this Gateway on the Gateway
                                    listing page and then navigate to the
                                    Gateway detail page.
                                    <br /> 4. Click on the ‘ON-BOARD GATEWAY’
                                    button.
                                    <br /> 5. Select on-board using ‘Wi-Fi’ and
                                    follow the instructions shown on the
                                    following pages.
                                </p>
                            </Instruction>
                        </OnboardWrapper>
                    </ContentWrapper>
                </div>
            </Col>
        </Row>
    );
};

export default OnboardInstructions;
