import {
    createResponseStandard,
    createErrorResponseStandard,
} from "utils/functions";
import AxiosInstance from "./axiosInstance";
import { apiFactory } from "generated";
import { orgId } from "utils/functions";

let accountClient = apiFactory.getAccountInstance(AxiosInstance);

const getAccountClient = () => {
    if (!accountClient.http.instance.defaults.headers.common["Authorization"]) {
        accountClient = apiFactory.getAccountInstance(AxiosInstance);
    }

    return accountClient;
};

export const getAllAccessKeys = async (organization_id: string = orgId()) => {
    try {
        const response = await getAccountClient().getUserTokens({
            organization_id,
        });
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};
export const generateAccessKey = async ({
    scope,
    name,
    expiration,
}: {
    scope: string[];
    name: string;
    expiration: number;
}) => {
    const data = {
        scope,
        name,
        expiration,
        organization_id: orgId(),
    };
    try {
        const response = await getAccountClient().createUserToken(data);
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const revokeAccessKey = async (tokenUuid: string) => {
    try {
        const response = await getAccountClient().revokeUserTokenByUuid(
            tokenUuid
        );
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const removeAccessKey = async (tokenUuid: string) => {
    try {
        const response = await getAccountClient().deleteUserTokenByUuid(
            tokenUuid
        );
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};
