/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "../http-client";
import { GetPermissionByUuidData } from "../models";

export class Permission<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags permission
   * @name GetPermissionByUuid
   * @summary Get permission info by organisation uuid
   * @request GET:/permission/{organisation_uuid}
   * @secure
   */
  getPermissionByUuid = (organisationUuid: string, params: RequestParams = {}) =>
    this.http.request<GetPermissionByUuidData, void>({
      path: `/permission/${organisationUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
}
