import { useAppContext } from "context/appContext";
import useLocalStorage from "./useLocalStorage";
import { useWalletStore } from "./wallet";
import { SubscriptionPlanType } from "components/subscriptions/CurrentPlanInformation";

export const useCheckIfBasicPlan = () => {
    const {
        storeData: {
            organization: { currentOrgInfo: { isDefault } = {} } = {},
        },
    } = useAppContext();

    const { localStorageValue } = useLocalStorage({
        key: "subscriptionPlanType",
        defaultValue: "",
    });
    const { wallet } = useWalletStore();

    const IS_BASIC_PLAN =
        (isDefault && localStorageValue === SubscriptionPlanType.BASIC) ||
        (!isDefault && wallet.subscriptionPlanType === "basic_monthly_plan");

    return { IS_BASIC_PLAN };
};
