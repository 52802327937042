import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";

import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import dltImg from "assets/svg/delete.svg";
import DefaultModal from "components/modals/ModalTemplate";
import { EVENT_VARIABLE_TIMER_FORMAT, TypeOption } from "constant";
import EventVariableService from "service/eventVariableService";
import { isHttpSuccess } from "utils/functions";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { getTypeOptionKeyByValue } from "utils/eventFunctions";
import { Variable } from "generated/models/Event";
import { convertSecondsToTimeString } from "utils/functions";
import { walletModalTrigger } from "hooks/wallet";

type EventVariableProps = {
    eventVariables: Variable[];
    fetchEventVariables: Function;
    associatedEventMap: Record<string, number>;
};

const EventVariables = ({
    eventVariables,
    fetchEventVariables,
    associatedEventMap,
}: EventVariableProps) => {
    const history = useHistory();
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [variableDeleting, setVariableDeleting] = useState<Variable>(
        {} as Variable
    );
    const [searchText, setSearchText] = useState("");

    const formatEventVariableValue = (type: string, value: string) => {
        switch (type) {
            case TypeOption.Boolean:
                return value.capitalize();
            case TypeOption.Float:
                return Number(value).toFixed(2);
            case TypeOption.Timer:
                return convertSecondsToTimeString(
                    Number(value),
                    EVENT_VARIABLE_TIMER_FORMAT
                );
            default:
                return Number(value);
        }
    };

    const showDeleteEventVariableModal = (eventVariable: Variable) => {
        setIsShowDeleteModal(true);
        setVariableDeleting(eventVariable);
    };

    const handleDeleteEventVariable = async () => {
        const deletedResp = await EventVariableService.deleteEventVariableById(
            variableDeleting.uuid
        );
        if (isHttpSuccess(deletedResp.status)) {
            fetchEventVariables();
            showSuccessAlert({
                message: "Event variable has been deleted successfully.",
            });
        } else {
            showErrorAlert(
                getAPIError(
                    deletedResp,
                    "Unable to delete this Event Variable. Please try again."
                )
            );
        }

        setIsShowDeleteModal(false);
        setVariableDeleting({} as Variable);
    };

    const renderEventVariableData = () => {
        return eventVariables
            .filter(
                (eventVariable) =>
                    !searchText ||
                    eventVariable.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
            )
            .map((eventVariable) => {
                return (
                    <div className="table-row" key={eventVariable.uuid}>
                        <Row className="no-checkbox">
                            <Col className="pl-4" md={{ span: 4, offset: 0 }}>
                                <Link
                                    to={{
                                        pathname: `/event-variables/${eventVariable.uuid}`,
                                    }}
                                >
                                    <span>{eventVariable.name}</span>
                                </Link>
                            </Col>

                            <Col md={{ span: 2, offset: 0 }}>
                                <span className="text-primary-green-1">
                                    {formatEventVariableValue(
                                        eventVariable.type_,
                                        eventVariable.initial
                                    )}
                                </span>
                            </Col>

                            <Col md={{ span: 2, offset: 0 }}>
                                <span>
                                    {getTypeOptionKeyByValue(
                                        eventVariable.type_
                                    )}
                                </span>
                            </Col>

                            <Col md={{ span: 2, offset: 0 }}>
                                <span>
                                    {associatedEventMap[eventVariable.uuid] ||
                                        0}
                                </span>
                            </Col>

                            <Col md={{ span: 1, offset: 0 }}>
                                <HoverAuthorizeTooltip permission="event:delete">
                                    <button
                                        onClick={() => {
                                            showDeleteEventVariableModal(
                                                eventVariable
                                            );
                                        }}
                                        className="btn"
                                    >
                                        <img
                                            src={dltImg}
                                            aria-label="delete-event-variable"
                                            alt="delete"
                                        />
                                    </button>
                                </HoverAuthorizeTooltip>
                            </Col>

                            <Col md={{ span: 1, offset: 0 }}>
                                <Link
                                    to={{
                                        pathname: `/event-variables/${eventVariable.uuid}`,
                                    }}
                                >
                                    <i className="material-icons right">
                                        keyboard_arrow_right
                                    </i>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                );
            });
    };

    return (
        <>
            <Row>
                <Col className="d-md-flex justify-content-end order-md-2 order-1">
                    <HoverAuthorizeTooltip permission="event:create">
                        <Link
                            to="/event-variables-add"
                            className="btn btn-primary add-gateway mr-3"
                            onClick={(e) => {
                                const trigger: any = walletModalTrigger(() =>
                                    history.push("/event-variables-add")
                                );
                                if (trigger === undefined) e.preventDefault();
                            }}
                        >
                            + Create Event Variable
                        </Link>
                    </HoverAuthorizeTooltip>
                    <div className="search">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                aria-label="search-gateway"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            ></FormControl>
                        </InputGroup>
                    </div>
                </Col>
            </Row>

            <Row className="cstm-table mt-4">
                <Col sm="12">
                    <div className="table-head">
                        <Row className="no-checkbox">
                            <Col sm="4" className="pl-4">
                                Variable Name
                            </Col>
                            <Col sm="2">Value</Col>
                            <Col sm="2">Type</Col>
                            <Col sm="2">No. of Events Associated</Col>
                            <Col sm="1">Actions</Col>
                            <Col sm="1"></Col>
                        </Row>
                    </div>

                    {eventVariables.length ? (
                        renderEventVariableData()
                    ) : (
                        <div className="mt-4 text-center">
                            No Event Variables to display
                        </div>
                    )}
                </Col>
            </Row>

            <DefaultModal
                modalShow={isShowDeleteModal}
                setModalShow={(isShow) => {
                    setIsShowDeleteModal(isShow);
                    setVariableDeleting({} as Variable);
                }}
                modalType={"dlt"}
                modalContent={"Do you want to delete this Event variable?"}
                deleteFunction={handleDeleteEventVariable}
            />
        </>
    );
};

export default EventVariables;
