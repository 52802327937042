import { Button, Modal, Form, Row, Card } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import styled from "@emotion/styled";
import styledConst from "styles";
import { get, lowerCase, map, union, uniqBy, upperFirst } from "lodash";
import {
    getSensorConfig,
    downloadStorage,
    getAllRegistry,
} from "service/gatewayService";
import { FETCH_GATEWAY_FAIL_MESSAGE } from "constant";
import { showSuccessAlert, showErrorAlert, getAPIError } from "utils/alert";
import TimezoneDropdown from "components/common/TimezoneDropdown";
import { isHttpSuccess } from "utils/functions";
import { Registry } from "generated/models/Dashboard";
import { useOrganisation } from "hooks/organization";
import DateTimeRangePicker from "components/common/DateTimeRangePicker";

interface Props {
    show: boolean;
    panel: any;
    deviceConfig?: any;
    onClose?: () => void;
}

type Device = {
    port: string;
    gatewayName: string;
    name?: string;
    sensorName: string;
    id: string;
    gatewayId: string;
    deviceId: string;
    said: number;
};

const DownloadModal = styled(Modal)`
    .modal-dialog {
        max-width: 635px;
    }
`;

const Box = styled(Card)`
    border: 2px solid #eaeaea;
`;

const DeviceItem = styled(Card)`
    margin-bottom: 1rem;
    border: 2px solid #eaeaea;

    .card-body {
        display: flex;
        justify-content: space-between;
    }
`;

const DeviceInfo = styled.div`
    color: ${styledConst.Primary_Blue_2};

    .subtext {
        font-size: 0.75rem;
        color: #828fab;
        opacity: 0.75;
    }
`;

const DeviceControl = styled.div`
    align-self: center;
`;

const CheckBox = styled(Form.Check)`
    .custom-control-label {
        color: ${styledConst.Primary_Blue_3};

        &::before {
            background-color: #dddddd;
            border-color: #dddddd;
        }
    }
`;

const DownloadChartModalComponent = (props: Props) => {
    const { defaultTimeZone } = useOrganisation();
    const [showDeviceModal, setShowDeviceModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [startDateTime, setStartDateTime] = useState(
        moment.utc().subtract(24, "hours").tz(defaultTimeZone)
    );
    const [endDateTime, setEndDateTime] = useState(
        moment().tz(defaultTimeZone)
    );
    const [timezone, setTimezone] = useState<string>(defaultTimeZone);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);
    const [errMsg, setErrMsg] = useState("");
    const { show, panel, onClose } = props;
    const [isDownloading, setIsDownloading] = useState(false);

    const getSourceName = () => {
        return panel ? upperFirst(lowerCase(panel.source)) : "";
    };

    const getDevicesInfo = async () => {
        const { attributes } = panel;
        let gateways: Registry[] = [];

        const allGatewayRes = await getAllRegistry();
        if (isHttpSuccess(allGatewayRes.status)) {
            gateways = get(allGatewayRes, "data", []);
        } else {
            showErrorAlert(
                getAPIError(allGatewayRes, FETCH_GATEWAY_FAIL_MESSAGE)
            );
        }

        const promises = uniqBy(attributes, (v: any) =>
            [v.device_id, v.said].join()
        ).map(({ gateway_id, device_id, said }: any) =>
            getSensorConfig(gateway_id, device_id, said)
        );

        Promise.all(promises).then((results: any[]) => {
            const _devices = results.map((res: any, index) => {
                const { gateway_name, port, sensor_name } = get(
                    res,
                    "data",
                    {}
                );

                const {
                    gateway_id: gatewayId,
                    device_id: id,
                    said,
                } = attributes[index];

                const gateway = gateways.find(
                    ({ gateway_id }: { gateway_id: string }) =>
                        gateway_id === gatewayId
                );

                const gatewayName = get(gateway, "name", gateway_name);

                return {
                    port,
                    gatewayId,
                    said,
                    gatewayName,
                    sensorName: attributes[index].name,
                    name: sensor_name,
                    id: `${id}_${said}`,
                    deviceId: id,
                };
            });

            setSelectedIds(map(_devices, "id"));
            setDevices(_devices);
        });
    };

    const isDisabledDownload = () => {
        return selectedIds.length === 0 || errMsg !== "";
    };

    const handleDownloadClick = () => {
        setShowDeviceModal(false);
        setShowConfirmModal(true);
    };

    const handleConfirmClick = async (e: any) => {
        e.preventDefault();
        setIsDownloading(true);
        const _devices = devices
            .filter((item) => selectedIds.includes(item.id))
            .map(({ gatewayId: gateway_id, deviceId: device_id, said }) => ({
                gateway_id,
                device_id,
                said,
            }));
        const res = await downloadStorage({
            start: moment(startDateTime).seconds(0).unix(),
            end: moment(endDateTime).seconds(0).unix(),
            time_zone: timezone,
            attributes: _devices,
        });

        if (isHttpSuccess(res.status)) {
            handleCloseModal();
            showSuccessAlert({
                message:
                    "An email will be sent to you shortly once it is ready to download.",
            });
        } else {
            showErrorAlert(
                getAPIError(res, "Unable to download data. Please try again.")
            );
        }

        setIsDownloading(false);
    };

    const handleCloseModal = () => {
        setShowDeviceModal(false);
        setShowConfirmModal(false);
        setSelectedIds(map(devices, "id"));
        onClose && onClose();
    };

    const handleSelectDevice = (
        e: React.ChangeEvent<HTMLInputElement>,
        device: Device | string
    ) => {
        const { checked } = e.target;
        let ids = selectedIds;
        const _device = device as Device;

        if (checked) {
            if (device === "all") {
                ids = map(devices, "id");
            } else {
                ids = union(selectedIds, [_device.id]);
            }
        } else {
            if (device === "all") {
                ids = [];
            } else {
                ids = ids.filter((id: string) => id !== _device.id);
            }
        }

        setSelectedIds(ids);
    };

    const handleChangeTimeZone = (timezone: string) => {
        setTimezone(timezone);
    };

    useEffect(() => {
        setShowDeviceModal(show);
    }, [show]);

    useEffect(() => {
        setTimezone(defaultTimeZone);
    }, [defaultTimeZone]);

    useEffect(
        () => {
            if (panel && showDeviceModal) {
                getDevicesInfo();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [panel, showDeviceModal]
    );

    const getDisplayGatewayName = ({
        port,
        gatewayName,
    }: {
        port: string;
        gatewayName: string;
    }) => {
        return port ? `${gatewayName} - LDS BUS ${port}` : `${gatewayName}`;
    };

    const handleDateTimeRangeChange = (value: Moment[]) => {
        setStartDateTime(value[0]);
        setEndDateTime(value[1]);
    };

    const handleDateTimeRangeError = (error: string) => {
        setErrMsg(error);
    };

    return (
        <>
            <DownloadModal
                className=""
                show={showDeviceModal}
                onHide={handleCloseModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                scrollable
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Download {getSourceName()} Data
                    </Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleCloseModal}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div>
                            <Form.Label className="text-primary-blue-3 mb-3">
                                Time Zone
                            </Form.Label>
                            <Box body>
                                <Form.Label className="text-primary-blue-3">
                                    Select a time zone
                                </Form.Label>
                                <TimezoneDropdown
                                    value={timezone}
                                    onChange={handleChangeTimeZone}
                                />
                            </Box>
                        </div>
                        <div className="mt-3">
                            <Form.Label className="text-primary-blue-3 mb-3">
                                Date/time Range
                            </Form.Label>
                            <Box body>
                                <DateTimeRangePicker
                                    inline={true}
                                    value={[startDateTime, endDateTime]}
                                    timezone={timezone}
                                    onChange={handleDateTimeRangeChange}
                                    onError={handleDateTimeRangeError}
                                />
                            </Box>
                        </div>

                        <div className="mt-4">
                            <div className="d-flex justify-content-between">
                                <Form.Label className="text-primary-blue-3 mb-3">
                                    {getSourceName()}
                                    {panel?.source === "SENSOR" && "(s)"}
                                </Form.Label>
                                {devices.length > 1 && (
                                    <CheckBox
                                        custom
                                        className="pr-1"
                                        type="checkbox"
                                        id="device-all"
                                        label="All"
                                        checked={
                                            selectedIds.length > 0 &&
                                            selectedIds.length ===
                                                devices.length
                                        }
                                        onChange={(e) =>
                                            handleSelectDevice(e, "all")
                                        }
                                    />
                                )}
                            </div>
                            {devices.map((device, index) => (
                                <DeviceItem body key={device.id}>
                                    <DeviceInfo>
                                        <Row className="device-name">
                                            <span
                                                className={`d-flex ${
                                                    device.name
                                                        ? "mute"
                                                        : "text-secondary-red-1"
                                                }`}
                                            >
                                                {!device.name && (
                                                    <span className="material-icons mr-2">
                                                        error_outline
                                                    </span>
                                                )}
                                                <span aria-label="device_display_name">
                                                    {device.name ??
                                                        `${
                                                            panel.source ===
                                                            "SENSOR"
                                                                ? "Sensor"
                                                                : "Actuator"
                                                        } information not found`}
                                                </span>
                                            </span>
                                        </Row>
                                        <Row className="subtext device-ldsu">
                                            {getDisplayGatewayName({
                                                port: device.port,
                                                gatewayName: device.gatewayName,
                                            })}
                                        </Row>
                                        <Row className="subtext device-id">
                                            LDSU UUID: {device.deviceId}
                                        </Row>
                                        <Row className="subtext sensor-name">
                                            {device.sensorName}
                                        </Row>
                                    </DeviceInfo>
                                    <DeviceControl>
                                        {devices.length > 1 && (
                                            <CheckBox
                                                custom
                                                type="checkbox"
                                                id={`device-${index}`}
                                                checked={selectedIds.includes(
                                                    device.id
                                                )}
                                                onChange={(e) =>
                                                    handleSelectDevice(
                                                        e,
                                                        device
                                                    )
                                                }
                                            />
                                        )}
                                    </DeviceControl>
                                </DeviceItem>
                            ))}
                        </div>
                    </Form>
                    <Button
                        variant="primary"
                        className="btn-create float-right"
                        disabled={isDisabledDownload()}
                        onClick={handleDownloadClick}
                    >
                        Download
                    </Button>
                </Modal.Body>
            </DownloadModal>
            <Modal
                centered
                show={showConfirmModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header primary"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons outlined">
                            help_outline
                        </span>
                    </div>
                    <h3 className="mb-3">Confirmation</h3>
                    <p className="mb-4">
                        By confirming, an email will be sent to your email
                        address with instructions on how to download. Do you
                        want to continue?
                    </p>

                    <Button variant="danger" onClick={handleCloseModal}>
                        NO
                    </Button>
                    <Button
                        disabled={isDownloading || !showConfirmModal}
                        variant="primary"
                        onClick={handleConfirmClick}
                    >
                        YES
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export const DownloadChartModal = memo(DownloadChartModalComponent);
