import AxiosInstance from "./axiosInstance";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "../utils/functions";
import { apiFactory } from "generated";
import { Member } from "generated/models";
import { ResponseDto } from "types/common";

class InvitationService {
    private invitationClient = apiFactory.getInvitationInstance(AxiosInstance);

    /**
     * Create new role
     * @param { string } memberId - Member id
     * @param { boolean } accept - Accept or Decline
     * @returns { promise }
     */
    async updateInvitationStatus(
        memberId: string,
        accept: boolean
    ) {
        try {
            const res = await this.invitationClient.responseInvitationByUuid(memberId, {
                accept,
            });
            return createResponseStandard<Member>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Get the Invitation list
     * @returns { promise }
     */
    async getInvitationList(): Promise<ResponseDto<Member[]>> {
        try {
            const res = await this.invitationClient.getInvitations();
            return createResponseStandard<Member[]>(res);
        } catch (err) {
            return createErrorResponseStandard(err);
        }
    }
}

const invitationService = new InvitationService();

export default invitationService;
