import { useEffect, useState, memo, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { meteringService } from "service/meteringService";
import { humanReadableFileSize, isHttpSuccess, orgId } from "utils/functions";
import { getOneRegistry } from "service/gatewayService";
import ChartBox from "./ChartBox";
import useChartWidth from "hooks/useChartWidth";
import ChartActionDropdown from "components/dashboard/ChartActionDropdown";
import ContentWrapper from "components/content-wrapper/ContentWrapper";

const bytePerSample = 4;
const totalStrInBytes = 5368709120;

export const adjustedDisplayData = (data: number) => {
    const minAngle = 5 / 360;
    const minDisplayedData = minAngle * totalStrInBytes;
    return data < minDisplayedData && data > 0 ? minDisplayedData : data;
};

const StorageChart = (props: any) => {
    const {
        panel: { uuid: panelId, chart, attributes, name },
        selectedDashboardUUID,
        handleOnChangeDashboardUUID,
        zoomLevel,
    } = props;
    const thisGatewayId = attributes[0]?.gateway_id;
    const [gatewayName, setGatewayName] = useState("");
    const [storageUsedByGateway, setStorageUsedByGateway] = useState(0);
    const [storageUsedByOtherGateway, setStorageUsedByOtherGateway] =
        useState(0);
    const boxElementRef = useRef<HTMLDivElement>(null);
    const { boxWidth } = useChartWidth({
        ref: boxElementRef,
        occupiedWidth: 80,
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchStorage = async () => {
            const [registryRes, storageRes]: any = await Promise.all([
                getOneRegistry(thisGatewayId),
                meteringService.getStorageInfo(thisGatewayId),
            ]);
            if (
                isHttpSuccess(registryRes.status) &&
                isHttpSuccess(storageRes.status)
            ) {
                const { name } = registryRes.data;
                const totalBytesUsed: number =
                    storageRes?.data?.[orgId()] * bytePerSample || 0;
                const currGWBytesUsed: number =
                    storageRes?.data?.gateway?.[thisGatewayId] *
                        bytePerSample || 0;
                setStorageUsedByOtherGateway(
                    Number(totalBytesUsed - currGWBytesUsed)
                );
                setStorageUsedByGateway(Number(currGWBytesUsed));
                setGatewayName(name);
            }
            setIsLoading(false);
        };
        fetchStorage();
    }, [thisGatewayId]);

    const option: any = {
        backgroundColor: "#2F3A51",
        responsive: true,
        maintainAspectRatio: false,
        title: {
            show: true,
            left: 14,
            top: 15,
            text: name || `${gatewayName} Storage Chart`,
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 18,
                overflow: "truncate",
                ellipsis: "...",
                width: boxWidth,
            },
            subtext: `Storage Usage`,
            subtextStyle: {
                color: "#828FAB",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 14,
                overflow: "breakAll",
                ellipsis: "...",
            },
        },
        legend: {
            show: false,
        },
        color: ["#00CBAF", "#128D7C", "#3E4B67"],
        series: [
            {
                type: "pie",
                radius: chart.toUpperCase() === "PIE" ? "55%" : ["30%", "55%"],
                avoidLabelOverlap: true,
                label: {
                    formatter: (params: any) => {
                        const {
                            data: { actualValue: bytes, name },
                        } = params;
                        const dataUsed: string = humanReadableFileSize(
                            bytes,
                            false,
                            2
                        );
                        const percent = (bytes / totalStrInBytes) * 100;

                        return `${name}: \n\n ${dataUsed} (${percent.toFixed(
                            2
                        )}%)`;
                    },
                    show: true,
                    position: "outside",
                    fontSize: 12,
                },
                labelLine: {
                    show: true,
                    length: 20,
                    length2: 20,
                    showAbove: true,
                },

                data: [
                    {
                        actualValue: storageUsedByGateway,
                        name: "Sensor cache usage by this Gateway",
                        value: adjustedDisplayData(storageUsedByGateway),
                    },
                    {
                        actualValue: storageUsedByOtherGateway,
                        name: "Sensor cache usage by all other Gateway(s)",
                        value: adjustedDisplayData(storageUsedByOtherGateway),
                    },
                    {
                        actualValue:
                            totalStrInBytes -
                            storageUsedByGateway -
                            storageUsedByOtherGateway,
                        name: "Free sensor cache storage capacity",
                        value: adjustedDisplayData(
                            totalStrInBytes -
                                storageUsedByGateway -
                                storageUsedByOtherGateway
                        ),
                    },
                ],
            },
        ],
    };
    return (
        <ChartBox
            className={
                zoomLevel <= 0.5
                    ? "widget-box temperature w-100 h-30vh"
                    : "widget-box temperature w-100"
            }
            ref={boxElementRef}
        >
            <div className="widget">
                <ChartActionDropdown
                    panelId={panelId}
                    onDelete={handleOnChangeDashboardUUID}
                    selectedDashboardUUID={selectedDashboardUUID}
                />
                <ContentWrapper isLoading={isLoading}>
                    <ReactECharts
                        className="chart-box"
                        option={option}
                        theme={"dark"}
                    />
                </ContentWrapper>
            </div>
        </ChartBox>
    );
};

export default memo(StorageChart);
