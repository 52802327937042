import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { capitalize, cloneDeep } from "lodash";
import moment from "moment";

import {
    getAllAddOns,
    getWallet,
    getAllPlans,
    cancelSubscription,
} from "service/subscriptionService";

import TokenConversionLink from "./TokenConversionLink";

import {
    CheckoutType,
    FESubscriptionStatus,
    PlanType,
    SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE,
    SUBSCRIPTION_DATE_FORMAT,
    SubscriptionStatus,
    TokenType,
} from "constant";

import "assets/css/subscription.css";
import { AppContext } from "context/appContext";
import { storeSubscriptionID } from "store/actions";
import { renderPlanStatus } from "utils/subscriptionFunctions";
import { isHttpSuccess } from "utils/functions";
import TokenQuantityField from "./TokenQuantityField";
import { LoadingModal } from "components/modals/ModalTemplate";
import { SubscriptionPlanType } from "./CurrentPlanInformation";

const MODAL_ERROR = "err";
const MODAL_CONFIRM = "confirm";
const MODAL_RESUBSCRIPTION_CONFIRM = "resubscriptionConfirm";
const MODAL_SUBSCRIPTION_CONFIRM = "subscriptionConfirm";
const MODAL_CANCELLATION_CONFIRM = "cancellationConfirm";
const MODAL_CANCELLATION_PRECONFIRM = "cancellationPreconfirm";
const MODAL_SUCCESS = "success";
const MODAL_PENDING = "pending";

const renderSubscriptionStatusMessage = ({
    unformattedDate,
    status,
    customStyle = "",
}: any) => {
    return (
        <p className={`period ${customStyle}`}>
            Your subscription {status} on{" "}
            {moment(unformattedDate, "YYYY-MM-DD").format(
                SUBSCRIPTION_DATE_FORMAT
            )}
        </p>
    );
};

const renderNextBillingDateMsg = (next_billing_date: any) => {
    return (
        <p className="period">
            Your next billing date is{" "}
            {moment(next_billing_date).format(SUBSCRIPTION_DATE_FORMAT)}
        </p>
    );
};

const SubscriptionBalanceDetails = (props: any) => {
    const {
        allSubscription,
        currentSubscription,
        currentSubscription: {
            status,
            billing_period_end_date,
            plan: { current_cycle, billing_cycles },
            next_billing_date,
        },
        billingEndDate,
        subscriptionStatus,
        refresh,
        subscriptionType,
    } = props;

    const history = useHistory();

    const [planUUID, setPlanUUID] = useState("");
    const [bundleInfo, setBundleInfo] = useState({
        amount: "",
        tokenQty: "",
        addonUUID: "",
    });
    const [subscriptionInfo, setSubscriptionInfo]: any = useState({
        uuid: "",
        amount: "",
        billingEndDate: "",
        monthlyAllocatedToken: "",
        subscriptionSettled: true,
        subscriptionStatus: "",
    });
    const [walletInfo, setWalletInfo] = useState({
        allocated: {
            amount: "",
            create_time: "",
            expire_time: "",
        },
        bonus: {
            amount: "",
            expire_time: "",
        },
        purchased: {
            amount: "",
            expire_time: "",
        },
    });

    const { storeDispatchActions } = useContext(AppContext);
    const [isActionPending, setIsActionPending] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");

    useEffect(() => {
        const fetch = async () => {
            const [addonRes, walletRes]: any = await Promise.all([
                getAllAddOns(),
                getWallet(),
            ]);

            if (
                !isHttpSuccess(addonRes.status) ||
                !isHttpSuccess(walletRes.status)
            ) {
                setModalShow(true);
                setModalType(MODAL_ERROR);
                setModalContent("Something went wrong. Please try again.");
                return;
            }

            if (isHttpSuccess(addonRes.status)) {
                for (const addon of addonRes.data) {
                    if (addon.bt_id === "token_1m") {
                        setBundleInfo({
                            amount: addon.amount,
                            tokenQty: addon.content.token.amount,
                            addonUUID: addon.uuid,
                        });
                        break;
                    }
                }
            }

            if (isHttpSuccess(walletRes.status)) {
                const walletInfoClone = cloneDeep(walletInfo);
                const wallet: any = walletRes.data.tokens;
                for (const token of wallet) {
                    if (token.type_ === TokenType.ALLOCATED) {
                        walletInfoClone.allocated.amount = token.amount;
                        walletInfoClone.allocated.create_time =
                            token.create_time;
                        walletInfoClone.allocated.expire_time =
                            token.expire_time;
                    } else if (token.type_ === TokenType.BONUS) {
                        walletInfoClone.bonus.amount = token.amount;
                        walletInfoClone.bonus.expire_time = token.expire_time;
                    } else if (token.type_ === TokenType.PURCHASED) {
                        walletInfoClone.purchased.amount = token.amount;
                        walletInfoClone.purchased.expire_time =
                            token.expire_time;
                    }
                }
                setWalletInfo(walletInfoClone);
            }
        };
        fetch();

        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let monthlyAllocatedToken: any;
        for (const addon of currentSubscription.plan.addons) {
            if (addon.name === "Monthly Plan Addon") {
                monthlyAllocatedToken = addon.content.token.amount;
                break;
            }
        }

        let isSubscriptionSettled = true;

        if (currentSubscription.status === SubscriptionStatus.ACTIVE) {
            if (currentSubscription.plan.current_cycle <= 0)
                isSubscriptionSettled = false;
        }

        isSubscriptionSettled = allSubscription.some((s: any) => {
            return (
                s.type_ === PlanType.SUBSCRIPTION &&
                [
                    SubscriptionStatus.ACTIVE,
                    SubscriptionStatus.CANCELED,
                    SubscriptionStatus.EXPIRED,
                    SubscriptionStatus.PAST_DUE,
                ].includes(s.status)
            );
        });

        const latestSubscriptionUUID = currentSubscription.uuid;
        storeDispatchActions(storeSubscriptionID(latestSubscriptionUUID));

        setSubscriptionInfo({
            uuid: latestSubscriptionUUID,
            amount: currentSubscription.plan.amount,
            billingEndDate: billingEndDate,
            monthlyAllocatedToken: monthlyAllocatedToken,
            subscriptionSettled: isSubscriptionSettled,
            subscriptionStatus: subscriptionStatus,
        });

        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchPlans = async () => {
            const response: any = await getAllPlans();
            if (isHttpSuccess(response.status)) {
                const planUUID_ = response.data.find(
                    ({ bt_id }: { bt_id: string }) => bt_id === "monthly_plan"
                ).uuid;
                setPlanUUID(planUUID_);
            } else {
                setModalShow(true);
                setModalType(MODAL_ERROR);
                setModalContent("Something went wrong. Please try again.");
            }
        };
        fetchPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubscription = () => {
        setModalShow(false);
        history.push("/checkout/billing-address", {
            checkoutType: CheckoutType.PLAN,
            planUUID: planUUID,
        });
    };

    const handleCancelSubscription = async () => {
        setModalShow(false);
        setIsActionPending(true);

        const response: any = await cancelSubscription(subscriptionInfo.uuid);
        if (isHttpSuccess(response.status)) {
            setIsActionPending(false);
            setModalShow(true);
            setModalType(MODAL_SUCCESS);
            setModalContent("Succesfully cancelled subscription.");
        } else {
            setIsActionPending(false);
            setModalShow(true);
            setModalType(MODAL_ERROR);
            setModalContent("Something went wrong. Please try again later.");
        }
    };

    const renderModalIcon = () => {
        if (
            modalType === MODAL_ERROR ||
            modalType === MODAL_CONFIRM ||
            modalType === MODAL_PENDING ||
            modalType === MODAL_CANCELLATION_PRECONFIRM
        ) {
            return <span className="material-icons">warning</span>;
        } else if (
            modalType === MODAL_RESUBSCRIPTION_CONFIRM ||
            modalType === MODAL_CANCELLATION_CONFIRM ||
            modalType === MODAL_SUBSCRIPTION_CONFIRM
        ) {
            return <span className="material-icons">help_outline</span>;
        }
        return <span className="material-icons">done</span>;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        } else if (
            modalType === MODAL_CONFIRM ||
            modalType === MODAL_RESUBSCRIPTION_CONFIRM ||
            modalType === MODAL_SUBSCRIPTION_CONFIRM ||
            modalType === MODAL_CANCELLATION_PRECONFIRM
        ) {
            return <h3 className="mb-3">Confirmation</h3>;
        } else if (modalType === MODAL_CANCELLATION_CONFIRM) {
            return <h3 className="mb-3">Cancel Subscription?</h3>;
        } else if (modalType === MODAL_PENDING) {
            return <h3 className="mb-3">Pending</h3>;
        }
        return <h3 className="mb-3">Success</h3>;
    };

    const confirmToCancelSubscription = () => {
        setModalType(MODAL_CANCELLATION_CONFIRM);

        setModalContent(
            SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE.fill({
                billing_period_end_date: moment(billing_period_end_date).format(
                    SUBSCRIPTION_DATE_FORMAT
                ),
                next_billing_date: moment(next_billing_date)
                    .add(1, "M")
                    .format(SUBSCRIPTION_DATE_FORMAT),
            })
        );
    };

    const refreshSubscriptionPage = () => {
        setModalShow(false);
        refresh();
        history.go(0);
    };

    const showPendingPurchaseError = () => {
        setModalShow(true);
        setModalType(MODAL_PENDING);
        setModalContent(
            "Unable to purchase tokens as subscription purchase is currently pending. Please try again later."
        );
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR || modalType === MODAL_PENDING) {
            return (
                <Button variant="primary" onClick={() => setModalShow(false)}>
                    OK
                </Button>
            );
        } else if (modalType === MODAL_SUCCESS) {
            return (
                <Button variant="primary" onClick={refreshSubscriptionPage}>
                    OK
                </Button>
            );
        } else if (modalType === MODAL_SUBSCRIPTION_CONFIRM) {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        Cancel
                    </Button>

                    <Button variant="primary" onClick={handleSubscription}>
                        Subscribe Now
                    </Button>
                </>
            );
        } else if (modalType === MODAL_CANCELLATION_PRECONFIRM) {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        NO
                    </Button>

                    <Button
                        variant="primary"
                        onClick={confirmToCancelSubscription}
                    >
                        YES
                    </Button>
                </>
            );
        } else if (modalType === MODAL_CANCELLATION_CONFIRM) {
            return (
                <>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        Keep subscription
                    </Button>

                    <Button variant="danger" onClick={handleCancelSubscription}>
                        Cancel subscription
                    </Button>
                </>
            );
        }
    };

    return (
        <Container fluid>
            <Row className="subscription-row">
                {[
                    SubscriptionPlanType.PREPAID,
                    SubscriptionPlanType.MONTHLY,
                ].includes(subscriptionType) && (
                    <CurrentBalance
                        walletInfo={walletInfo}
                        subscriptionInfo={subscriptionInfo}
                        currentSubscription={currentSubscription}
                    />
                )}

                {![
                    FESubscriptionStatus.EXPIRED,
                    FESubscriptionStatus.PENDING_CANCEL,
                    FESubscriptionStatus.CANCELLATION_PERIOD_ENDED,
                    FESubscriptionStatus.PAST_DUE,
                ].includes(subscriptionInfo.subscriptionStatus) &&
                    [
                        SubscriptionPlanType.PREPAID,
                        SubscriptionPlanType.MONTHLY,
                    ].includes(subscriptionType) && (
                        <PurchaseAdditionalTokens
                            subscriptionInfo={subscriptionInfo}
                            bundleInfo={bundleInfo}
                            showPendingPurchaseError={showPendingPurchaseError}
                        />
                    )}

                <SubscriptionInformationPanel>
                    <CustomerInfo currentSubscription={currentSubscription} />
                    <SubscriptionInfo
                        currentSubscription={currentSubscription}
                        next_billing_date={next_billing_date}
                        billing_cycles={billing_cycles}
                        current_cycle={current_cycle}
                        subscriptionInfo={subscriptionInfo}
                        status={status}
                        subscriptionType={subscriptionType}
                        billing_period_end_date={billing_period_end_date}
                    />
                </SubscriptionInformationPanel>
            </Row>
            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    [MODAL_SUCCESS, MODAL_RESUBSCRIPTION_CONFIRM].includes(
                        modalType
                    )
                        ? "primary"
                        : "danger"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>
                    {renderModalButton()}
                </Modal.Body>
            </Modal>
            <LoadingModal showModal={isActionPending} />
        </Container>
    );
};

export default SubscriptionBalanceDetails;

const SubscriptionInfo = ({
    currentSubscription,
    billing_period_end_date,
    next_billing_date,
    billing_cycles,
    current_cycle,
    subscriptionInfo,
    status,
    subscriptionType,
}: any) => {
    const {
        plan: { addons },
    } = currentSubscription;

    const purchasedTokenAddOn_ = addons.find(
        ({
            bt_id,
            content: {
                token: { type_ },
            },
        }: {
            bt_id: string;
            content: { token: { type_: string } };
        }) => bt_id === "token_1m" && type_ === "PURCHASED"
    );

    const renderBillingPeriodMsg = () => {
        if (
            [
                FESubscriptionStatus.PENDING_CANCEL,
                FESubscriptionStatus.CANCELLATION_PERIOD_ENDED,
            ].includes(subscriptionInfo.subscriptionStatus)
        ) {
            return renderSubscriptionStatusMessage({
                unformattedDate: billing_period_end_date,
                status: moment()
                    .startOf("day")
                    .isAfter(moment(billing_period_end_date))
                    ? "ended"
                    : "ends",
            });
        } else if (
            subscriptionInfo.subscriptionStatus === FESubscriptionStatus.EXPIRED
        ) {
            return renderSubscriptionStatusMessage({
                unformattedDate: billing_period_end_date,
                status: "expired",
                customStyle: "text-secondary-red-1",
            });
        } else if (
            subscriptionInfo.subscriptionStatus ===
            FESubscriptionStatus.PAST_DUE
        ) {
            return (
                <>
                    {renderSubscriptionStatusMessage({
                        unformattedDate:
                            currentSubscription.billing_period_start_date,
                        status: "is past due",
                    })}
                    {renderNextBillingDateMsg(next_billing_date)}
                </>
            );
        }

        if (billing_cycles - current_cycle === 0) {
            return renderSubscriptionStatusMessage({
                unformattedDate: billing_period_end_date,
                status: "expires",
                customStyle: "text-secondary-red-1",
            });
        } else if (subscriptionType === SubscriptionPlanType.MONTHLY) {
            return renderNextBillingDateMsg(next_billing_date);
        }
    };

    return (
        <div className="subscr-details">
            {subscriptionInfo.subscriptionSettled && renderBillingPeriodMsg()}
            <div className="mt-3">
                <p>
                    IoTPortal {capitalize(subscriptionType)} Subscription{" "}
                    {subscriptionType === SubscriptionPlanType.MONTHLY &&
                        `- USD ${subscriptionInfo.amount}/mo`}
                </p>
                <p className="plan-status">
                    {capitalize(subscriptionType)} Plan -{" "}
                    {renderPlanStatus(status)}
                </p>
            </div>
            {purchasedTokenAddOn_ && (
                <div className="mt-3">
                    <p>
                        {purchasedTokenAddOn_.quantity} x Bundle of 1,000,000
                        Purchased Tokens - USD{" "}
                        {(
                            Number(purchasedTokenAddOn_.amount) *
                            purchasedTokenAddOn_.quantity
                        ).toFixed(2)}
                        /mo
                    </p>
                    <p className="plan-status">
                        Add-on - {renderPlanStatus(status)}
                    </p>
                </div>
            )}
        </div>
    );
};

const CustomerInfo = ({ currentSubscription }: any) => {
    if (currentSubscription.customer?.code) {
        const {
            customer: { code: customer_id = "", project: prn = "" },
        } = currentSubscription;
        return (
            <div className="d-flex flex-column">
                <div className="customer-information">
                    <span className="sub-info-customer">
                        Customer ID:{" "}
                        <span className="text-primary-blue-4">
                            {customer_id ?? "-"}
                        </span>
                    </span>
                    <span className="sub-info-customer">
                        PRN:{" "}
                        <span className="text-primary-blue-4">
                            {prn ?? "-"}
                        </span>
                    </span>
                </div>
            </div>
        );
    }
    return null;
};

const CurrentBalance = ({
    walletInfo,
    subscriptionInfo,
    currentSubscription,
}: any) => {
    const renderAllocatedTokenBillingInfo = () => {
        if (subscriptionInfo.subscriptionSettled) {
            if (
                !currentSubscription.billing_period_start_date ||
                !currentSubscription.billing_period_end_date
            ) {
                return `${moment(currentSubscription.next_billing_date)
                    .subtract(30, "d")
                    .format(SUBSCRIPTION_DATE_FORMAT)} - ${moment(
                    currentSubscription.next_billing_date
                ).format(SUBSCRIPTION_DATE_FORMAT)}`;
            } else {
                return `${moment(
                    currentSubscription.billing_period_start_date
                ).format(SUBSCRIPTION_DATE_FORMAT)} - ${moment(
                    currentSubscription.billing_period_end_date
                ).format(SUBSCRIPTION_DATE_FORMAT)}`;
            }
        } else {
            return `Adding tokens... Please wait as this process might take awhile.`;
        }
    };
    return (
        <Col lg={4} md={6} sm={6} xs={12} className="mb-3">
            <div className="balance-box">
                <div className="box-head">
                    <h5>Current Balance</h5>
                    <h4>
                        <span>
                            {Number(
                                walletInfo.allocated.amount +
                                    walletInfo.bonus.amount +
                                    walletInfo.purchased.amount
                            ).toLocaleString()}
                        </span>{" "}
                        Tokens
                    </h4>
                </div>
                <div className="box-body">
                    <div className="token-usage">
                        <div className="float-left">
                            <p>Monthly Allocated Tokens</p>
                            <p className="period">
                                {renderAllocatedTokenBillingInfo()}
                            </p>
                        </div>
                        <div className="float-right">
                            <p>
                                {walletInfo.allocated.amount.toLocaleString()}{" "}
                                <span>
                                    /
                                    {subscriptionInfo.monthlyAllocatedToken.toLocaleString()}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="token-usage">
                        <div className="float-left">
                            <p>Bonus Tokens</p>
                            {walletInfo.bonus.expire_time ? (
                                <p className="period">
                                    {`Expires on ${moment(
                                        walletInfo.bonus.expire_time
                                    ).format(SUBSCRIPTION_DATE_FORMAT)}`}
                                </p>
                            ) : (
                                <p className="period">-</p>
                            )}
                        </div>
                        <div className="float-right">
                            <p>{walletInfo.bonus.amount.toLocaleString()} </p>
                        </div>
                    </div>
                    <div className="token-usage">
                        <div className="float-left">
                            <p>Purchased Tokens</p>
                            <p className="period">
                                {walletInfo.purchased.expire_time
                                    ? `Expires on ${moment(
                                          walletInfo.purchased.expire_time
                                      ).format(SUBSCRIPTION_DATE_FORMAT)}`
                                    : `No expiry`}
                            </p>
                        </div>
                        <div className="float-right">
                            <p>
                                {Number(
                                    walletInfo.purchased.amount
                                ).toLocaleString()}{" "}
                            </p>
                        </div>
                    </div>
                    <div className="token-usage">
                        <TokenConversionLink className="billing-link" />
                    </div>
                </div>
            </div>
        </Col>
    );
};

const PurchaseAdditionalTokens = ({
    bundleInfo,
    subscriptionInfo,
    showPendingPurchaseError,
}: any) => {
    const history = useHistory();
    const [bundleQty, setBundleQty] = useState("1");

    const handleBuy = () => {
        if (!subscriptionInfo.subscriptionSettled) {
            showPendingPurchaseError();
            return;
        }

        history.push("/checkout/billing-address", {
            checkoutType: CheckoutType.ADDON,
            addonUUID: bundleInfo.addonUUID,
            bundleQty,
            bundleInfo,
        });
    };

    return (
        <Col lg={4} md={6} sm={6} xs={12} className="mb-3">
            <div className="subscription-benefits form-box">
                <h5>Purchase Additional Tokens</h5>
                <div className="purchase-tokens">
                    <div className="float-left">
                        <p>Bundle of 1,000,000 Purchased Tokens</p>
                        <p className="period">No expiry</p>
                    </div>
                    <div>
                        <p>USD {bundleInfo.amount}</p>
                    </div>
                </div>
                <div className="qty">
                    <Form className="form d-flex flex-wrap">
                        {" "}
                        <TokenQuantityField
                            quantity={bundleQty}
                            setQuantity={setBundleQty}
                        />
                    </Form>
                    <div className="total">
                        <h5>
                            {Number(
                                Number(bundleInfo.tokenQty) * Number(bundleQty)
                            ).toLocaleString()}{" "}
                            Tokens
                        </h5>
                    </div>
                </div>
                <div className="total-amount">
                    <h5>
                        USD{" "}
                        {(
                            Number(bundleQty) * Number(bundleInfo.amount)
                        ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                        })}
                    </h5>
                    <Button
                        variant="primary default-button-width"
                        onClick={handleBuy}
                    >
                        BUY
                    </Button>
                </div>
            </div>
        </Col>
    );
};

const SubscriptionInformationPanel = ({ children }: any) => {
    return (
        <Col lg={4} md={6} sm={6} xs={12} className="mb-3">
            <div className="form-box subscription-info">
                <h5>Subscription Information</h5>

                {children}

                <Link
                    to="/manage-subscription-details"
                    className="manage-subscr"
                >
                    Manage Subscription
                </Link>
            </div>
        </Col>
    );
};
