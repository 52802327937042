import { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Tab,
    Nav,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import _, { debounce } from "lodash";

import LocationTab from "components/gateways/LocationTab";
import UpdateTab from "components/gateways/UpdateTab";
import StorageTab from "components/gateways/StorageTab";
import DescriptorTab from "components/gateways/DescriptorTab";
import HierarchyTab from "components/gateways/HierarchyTab";
import chartIcon from "assets/svg/hierarchy.svg";
import mapIcon from "assets/svg/location.svg";
import storageIcon from "assets/svg/storage.svg";
import updateIcon from "assets/svg/update.svg";
import descriptorIcon from "assets/svg/descriptor.svg";
import dltWhiteIcon from "assets/svg/delete-white.svg";
import restartIcon from "assets/svg/restart.svg";
import searchIcon from "assets/svg/search.svg";
import refreshIcon from "assets/svg/refresh-green.svg";
import { ReactComponent as InfoSvg } from "assets/svg/info.svg";
import {
    getOneRegistry,
    updateOneRegistry,
    scanGateway,
    getRegistryConfig,
    resetGateway,
    deleteRegistry,
    restartGateway,
    getGwAndLDSUStatus,
    toggleBusPower,
    deleteLDSBus,
} from "service/gatewayService";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import {
    ACTION_FAIL_MESSAGE,
    DELETE_GATEWAY_SUCCESS_MESSAGE,
    DELETE_LDSUS_SUCCESS_MESSAGE,
    GATEWAY_SCAN_MESSAGE,
    HttpStatus,
    LDSUTabPanel,
    PORT_FAULTY_SCAN_MESSAGE,
    PORT_POWERED_OFF_SCAN_MESSAGE,
    PORT_POWERED_ON_SCAN_MESSAGE,
    RESTART_REQUEST_MESSAGE,
    SCAN_GATEWAY_FAIL_MESSAGE,
    SCAN_REQUEST_MESSAGE,
    TOGGLE_PORT_POWER_SUCCESS_MESSAGE,
    TURN_PORT_POWER_OFF_MESSAGE,
    TURN_PORT_POWER_ON_MESSAGE,
} from "constant";
import {
    getLDSBusInfo,
    getPowerStatusText,
    isFaulty,
} from "utils/gatewayFunctions";
import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import { canAccess } from "utils/authorize-action";

import { LoadingModal, useModal } from "components/modals/ModalTemplate";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { faker } from "@faker-js/faker";
import styled from "@emotion/styled";
import styledConst from "styles";
import { get, keyBy } from "lodash";
import { LDSBus, PortStatus } from "types/Gateways";
import GatewayLDSUDetails from "./GatewayLDSUDetails";
import OnboardInstructions from "components/gateways/OnboardInstructions";
import { WalletAlertComponent } from "hooks/wallet";
import { isHttpSuccess } from "utils/functions";
import { ArrowIcon, BlueText, Box } from "components/common";
import { PowerCycleCheckBox } from "components/gateways/Gateway";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import RemindDownloadDataModal from "components/gateways/RemindDownloadDataModal";
import { gatewayReadingStatusStore } from "hooks/gatewayReading";
import { HeartbeatUpdateAfter } from "constant";
import { checkIfReachable } from "utils/functions";

const { FORBIDDEN } = HttpStatus;
const FaultyMessages = {
    "1": "Port is faulty because of hardware issue.",
    "2": "Port is faulty because port power exceeded.",
    "3": "Port is faulty because power exceeded in all ports. ",
};

interface LDSBusInfo {
    ldsus?: any[];
    sensors?: any[];
    actuators?: any[];
}

type LDSBusState = LDSBus & {
    refreshing: boolean;
    info?: LDSBusInfo;
};

const DeviceStatus = styled.div`
    display: inline-flex;
    margin-left: 20px;

    &.off {
        .status {
            color: ${styledConst.Secondary_Red};
        }
    }

    &.on {
        .status {
            color: ${styledConst.Secondary_Green};
        }
    }

    &.faulty {
        .status {
            color: ${styledConst.Orange};
        }
    }
`;

const LDSBusBox = styled(Box)`
    padding: 20px;

    .box-header {
        display: flex;
        align-items: center;

        .box-title {
            font-size: 1.125rem;
        }
    }

    &.on {
        background-color: #223228;
        border: 1px solid ${styledConst.Secondary_Green};
    }

    &.off {
        background-color: #382229;
        border: 1px solid ${styledConst.Primary_Red};
    }

    &.faulty {
        background-color: #40311c;
        border: 1px solid ${styledConst.Orange};
    }
`;

const InfoIcon = styled.span`
    margin-left: 1rem;
    display: inline-flex;

    path {
        fill: ${styledConst.Primary_Green_1};
    }
`;

const CustomTooltip = styled(Tooltip)`
    .tooltip-inner {
        background-color: #ffffff;
        color: #000000;
    }

    .arrow {
        &::before {
            border-top-color: #ffffff;
        }
    }
`;

const CustomButton = styled(Button)`
    @media (max-width: 1330px) {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }
`;

const FaultyTooltip = ({ status }: { status: number }) => {
    const renderTooltip = (props: OverlayInjectedProps) => (
        <CustomTooltip id={faker.datatype.uuid()} {...props}>
            {get(FaultyMessages, status)}
        </CustomTooltip>
    );
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <InfoIcon>
                <InfoSvg />
            </InfoIcon>
        </OverlayTrigger>
    );
};

const GatewayDetails = () => {
    const history = useHistory();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isNameEditing, setIsNameEditing] = useState(false);
    const [loadMap, setLoadMap] = useState("idle");
    const [isFirmwareUpdated, setIsFirmwareUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState("");
    const { gatewayId }: { gatewayId: string } = useParams();
    const [registryDetails, setRegistryDetails]: any = useState({});
    const [ldsuStatuses, setLdsuStatuses] = useState<any>(null);
    const [LDSUPowerState, setLDSUPowerState] = useState<LDSBusState[]>([
        {
            id: 1,
            power: false,
            status: PortStatus.NoDevice,
            refreshing: false,
            info: {},
        },
        {
            id: 2,
            power: false,
            status: PortStatus.NoDevice,
            refreshing: false,
            info: {},
        },
        {
            id: 3,
            power: false,
            status: PortStatus.NoDevice,
            refreshing: false,
            info: {},
        },
    ]);
    const [nameEditable, setNameEditable] = useState("");
    const [timestampDetails, setTimestampDetails] = useState({
        heartbeat: 0,
        info: false,
    });
    const [isForbidden, setIsForbidden] = useState(false);
    const [hasOnboard, setHasOnboard] = useState<boolean>(false);
    const [showRemindDownload, setShowRemindDownload] = useState(false);

    const { modal, openModal, modalData, setModalData, setModalShow } =
        useModal();

    useEffect(() => {
        setModalData({
            powerCycle: false,
            selectedPortToScan: 0,
        });
    }, [setModalData]);

    const gatewayConfigData =
        gatewayReadingStatusStore.getState().gatewayConfigData;

    const isDeviceOnline = (device: any) => {
        return checkIfReachable(
            device.ts,
            _.get(gatewayConfigData, `[${device.UID}].INTVL`, 5)
        );
    };

    const getDeviceStatuses = (devices: any[]) => {
        return devices.reduce(
            (status: { [key: string]: boolean }, device: any) => {
                status[device.UID] = isDeviceOnline(device);
                return status;
            },
            {}
        );
    };

    const fetchRegistry = async (portId?: number) => {
        const [registryInfoRes, registryGwAndLdsuStatusRes] = await Promise.all(
            [getOneRegistry(gatewayId), getGwAndLDSUStatus(gatewayId)]
        );

        if (
            [
                registryInfoRes.status,
                registryGwAndLdsuStatusRes.status,
            ].includes(FORBIDDEN)
        ) {
            setIsForbidden(true);
        } else if (isHttpSuccess(registryInfoRes.status)) {
            if (!registryInfoRes.data.info) {
                const { data: infoData } = registryInfoRes;
                setRegistryDetails(infoData);
                setNameEditable(infoData.name);
                setHasOnboard(false);
                setIsPageLoading(false);
                return;
            } else {
                setHasOnboard(true);
            }

            const { data: infoData } = registryInfoRes;
            setRegistryDetails(infoData);
            setNameEditable(infoData.name);

            const powers = get(infoData, "info.bus_power", []);
            const powerStatuses = get(infoData, "port.PORT", [
                PortStatus.On,
                PortStatus.On,
                PortStatus.On,
            ]);

            const ldsBusState: LDSBusState[] = portId
                ? _.cloneDeep(LDSUPowerState)
                : [
                      {
                          id: 1,
                          power: powers[0],
                          status: powerStatuses[0],
                          refreshing: false,
                          info: {},
                      },
                      {
                          id: 2,
                          power: powers[1],
                          status: powerStatuses[1],
                          refreshing: false,
                          info: {},
                      },
                      {
                          id: 3,
                          power: powers[2],
                          status: powerStatuses[2],
                          refreshing: false,
                          info: {},
                      },
                  ];

            const { devices, heartbeat } =
                registryGwAndLdsuStatusRes?.data || {};

            // Reuse the existing device status if it exists, otherwise create a new one
            let updateLdsuStatus: Record<string, boolean> = {};
            if (ldsuStatuses) {
                updateLdsuStatus = { ...ldsuStatuses };
            }
            // Add condition to ensure that the devices exist
            else if (devices) {
                const deviceStatuses = getDeviceStatuses(devices || []);

                const timestamp = Math.round(new Date().getTime() / 1000);
                deviceStatuses[gatewayId] =
                    timestamp - heartbeat < HeartbeatUpdateAfter;

                updateLdsuStatus = deviceStatuses;
            }

            ldsBusState.forEach((l) => {
                if (portId !== undefined && l.id !== portId) {
                    return;
                }

                l.power = powers[l.id - 1];
                l.status = powerStatuses[l.id - 1];
                l.refreshing = false;
                l.info = getLDSBusInfo(
                    l.id.toString(),
                    infoData.info.devices,
                    infoData.configs,
                    registryGwAndLdsuStatusRes.data.devices
                );

                if (updateLdsuStatus) {
                    // Get all the LDSUs from this LDSBus
                    const ldsuIds = (l.info?.ldsus || []).map(
                        (ldsu: any) => ldsu.UID
                    );

                    // Go through all the LDSUs and update their statuses
                    ldsuIds.forEach((ldsuId: any) => {
                        const device: any =
                            registryGwAndLdsuStatusRes.data.devices.find(
                                (d: any) => d.UID === ldsuId
                            );
                        updateLdsuStatus[ldsuId] = isDeviceOnline(device);
                    });
                }
            });

            setLDSUPowerState(ldsBusState);

            if (updateLdsuStatus) {
                setLdsuStatuses(updateLdsuStatus);
            }

            setTimestampDetails({
                heartbeat: get(
                    registryGwAndLdsuStatusRes,
                    "data.heartbeat",
                    ""
                ),
                info: !!infoData.info,
            });
        }

        setIsPageLoading(false);
    };

    useEffect(() => {
        if (!canAccess("gateway:read")) {
            setIsPageLoading(false);
            setIsForbidden(true);
        } else {
            fetchRegistry();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gatewayId, window.authorizationConfig, isFirmwareUpdated]);

    const getLDSBus = (busNumber: number) => {
        const LDSBus = LDSUPowerState.find(({ id }) => {
            return id === busNumber;
        });

        return LDSBus || LDSUPowerState[0];
    };

    const isFaultyLDSBus = (busNumber: number) => {
        if (busNumber === 0) {
            return false;
        }

        const LDSBus = getLDSBus(busNumber);

        return isFaulty(LDSBus.status || PortStatus.NoDevice);
    };

    const closeModal = () => {
        setModalShow(false);
        setModalData({});
    };

    const refreshOneBus = (busToRefresh: number) => {
        const refreshingState = LDSUPowerState.map((ldsu: any) => {
            if (ldsu.id === busToRefresh) {
                return { ...ldsu, refreshing: true };
            }
            return ldsu;
        });
        setLDSUPowerState(refreshingState);
        fetchRegistry(busToRefresh);
    };

    const refreshThisGateway = async () => {
        setIsRefreshing(true);
        setLoadMap("refresh");
        await fetchRegistry();
        setLoadMap("loaded");
        setIsRefreshing(false);
    };

    const toggleBusPowerSwitch = debounce(async (currentBus: number) => {
        const currentPower = keyBy(LDSUPowerState, "id")[currentBus].power;

        const res: any = await toggleBusPower(
            gatewayId,
            currentBus,
            !currentPower
        );
        if (isHttpSuccess(res.status)) {
            showSuccessAlert({
                message: TOGGLE_PORT_POWER_SUCCESS_MESSAGE,
            });
        } else {
            showErrorAlert(getAPIError(res, ACTION_FAIL_MESSAGE));
        }
    }, 300);

    const scanThisGateway = async (
        busNumber: number,
        openedModalData?: any
    ) => {
        setModalShow(false);
        setIsLoading(true);
        setLoadingTitle("Scanning...");

        const isBusFaulty = isFaultyLDSBus(busNumber);
        const shouldScanWithPowerCycle = isBusFaulty
            ? true
            : !!openedModalData.powerCycle;

        const res = await scanGateway(
            gatewayId,
            busNumber,
            shouldScanWithPowerCycle
        );

        if (isHttpSuccess(res.status)) {
            // Call config API
            const registryConfigRes = await getRegistryConfig(gatewayId);

            if (isHttpSuccess(registryConfigRes.status)) {
                // Check if the config has any details.
                if (registryConfigRes.data.length === 0) {
                    // call reset API
                    const registryResetRes = await resetGateway(gatewayId);
                    if (isHttpSuccess(registryResetRes.status)) {
                        showSuccessAlert({
                            message: SCAN_REQUEST_MESSAGE,
                        });
                    } else {
                        showErrorAlert(
                            getAPIError(
                                registryResetRes,
                                SCAN_GATEWAY_FAIL_MESSAGE
                            )
                        );
                    }
                } else {
                    showSuccessAlert({
                        message: SCAN_REQUEST_MESSAGE,
                    });
                }
            } else {
                showErrorAlert(
                    getAPIError(registryConfigRes, SCAN_GATEWAY_FAIL_MESSAGE)
                );
            }
        } else {
            showErrorAlert(getAPIError(res, ACTION_FAIL_MESSAGE));
        }

        setIsLoading(false);
        closeModal();
    };

    const restartThisGateway = async () => {
        setIsLoading(true);
        setLoadingTitle("Restarting...");
        const registryRestartRes = await restartGateway(gatewayId);
        if (isHttpSuccess(registryRestartRes.status)) {
            showSuccessAlert({
                message: RESTART_REQUEST_MESSAGE,
            });
        } else {
            showErrorAlert(
                getAPIError(registryRestartRes, ACTION_FAIL_MESSAGE)
            );
        }

        setIsLoading(false);
        closeModal();
    };

    const handleClickEdit = () => {
        setIsNameEditing(true);
    };

    const handleClickUpdate = async () => {
        const trimmedEditableName = nameEditable.trim();
        if (trimmedEditableName === registryDetails.name) {
            setIsNameEditing(false);
            return;
        }

        const res = await updateOneRegistry(gatewayId, {
            name: trimmedEditableName,
        });

        if (isHttpSuccess(res.status)) {
            setIsNameEditing(false);
            showSuccessAlert({
                message: `Gateway name has been updated successfully`,
            });

            setRegistryDetails({
                ...registryDetails,
                name: trimmedEditableName,
            });
        } else {
            showErrorAlert(getAPIError(res, ACTION_FAIL_MESSAGE));
        }
    };

    const handleNameChange = (e: any) => {
        setNameEditable(e.target.value);
    };

    const openDownloadReminderModal = () => {
        setShowRemindDownload(true);
    };

    const handleRemindDownload = async (confirmation: boolean) => {
        setShowRemindDownload(false);

        if (confirmation) {
            openDeleteModal();
        }
    };

    const openDeleteModal = (busNumber: number = 0) => {
        const isDeleteGateway = busNumber === 0;

        const modalTitle_ = isDeleteGateway
            ? "Delete Gateway?"
            : "Delete LDSUs?";
        const modalMessage_ = isDeleteGateway
            ? `Are you sure you want to delete ${registryDetails.name}?
        This cannot be undone.`
            : `Are you sure you want to delete all the LDSUs from LDSBus ${busNumber}?This will delete associated Sensors and Actuators.`;

        openModal({
            key: "open_delete_modal",
            modalType: "custom",
            modalIcon: "delete",
            iconVariant: "danger",
            modalTitle: modalTitle_,
            modalContent: modalMessage_,
            modalButton: () => {
                return (
                    <>
                        <Button variant="secondary" onClick={closeModal}>
                            CANCEL
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                handleDelete(busNumber);
                            }}
                        >
                            DELETE
                        </Button>
                    </>
                );
            },
        });
    };

    const handleDelete = async (busNumber: number) => {
        closeModal();
        const res = await (busNumber > 0
            ? deleteLDSBus(gatewayId, busNumber.toString())
            : deleteRegistry(gatewayId));

        if (isHttpSuccess(res.status)) {
            let message = DELETE_GATEWAY_SUCCESS_MESSAGE;

            if (busNumber > 0) {
                const LDSBus = getLDSBus(busNumber);
                message = DELETE_LDSUS_SUCCESS_MESSAGE.fill({
                    ldsuNo: get(LDSBus, "info.ldsus.length"),
                });

                refreshOneBus(busNumber);
            }
            showSuccessAlert({
                message,
            });

            busNumber === 0 && history.push("/gateways-all");
        } else {
            showErrorAlert(getAPIError(res, ACTION_FAIL_MESSAGE));
        }
    };

    const openTogglePowerModal = (
        currentBus: number,
        currentPowerMode: boolean
    ) => {
        openModal({
            key: "open_toggle_power_modal",
            modalType: "custom",
            modalTitle: "Confirm",
            modalIcon: "help_outline",
            iconVariant: "primary",
            modalContent: currentPowerMode
                ? TURN_PORT_POWER_OFF_MESSAGE
                : TURN_PORT_POWER_ON_MESSAGE,
            modalButton: () => {
                return (
                    <>
                        <Button variant="secondary" onClick={closeModal}>
                            NO
                        </Button>
                        <Button
                            onClick={() => {
                                closeModal();
                                toggleBusPowerSwitch(currentBus);
                            }}
                        >
                            YES
                        </Button>
                    </>
                );
            },
        });
    };

    const openGatewayRestartModal = () => {
        openModal({
            key: "open_gateway_restart_modal",
            modalType: "custom",
            modalIcon: "help_outline",
            iconVariant: "primary",
            modalTitle: "Confirmation",
            modalContent: `Are you sure you want to restart this Gateway?`,
            modalButton: () => {
                return (
                    <>
                        <Button variant="danger" onClick={closeModal}>
                            NO
                        </Button>
                        <Button
                            onClick={() => {
                                restartThisGateway();
                            }}
                        >
                            YES
                        </Button>
                    </>
                );
            },
        });
    };

    const openScanModal = (busNumber: number) => {
        const isGatewayScan = busNumber === 0;
        const LDSBus = getLDSBus(busNumber);
        const status = getPowerStatusText(LDSBus.power, LDSBus.status);

        let modalTitle_ = "";
        let modalMessage_ = "";

        if (isGatewayScan) {
            modalTitle_ = `Gateway Scan`;
            modalMessage_ = GATEWAY_SCAN_MESSAGE;
        } else {
            if (["On", "Off"].includes(status)) {
                modalTitle_ = `Scan in Powered ${status} Port`;
                modalMessage_ =
                    status === "On"
                        ? PORT_POWERED_ON_SCAN_MESSAGE
                        : PORT_POWERED_OFF_SCAN_MESSAGE;
            } else if (status === "Faulty") {
                modalTitle_ = `Scan in Faulty Port`;
                modalMessage_ = PORT_FAULTY_SCAN_MESSAGE;
            }
        }

        openModal({
            key: "open_scan_modal",
            modalType: "custom",
            modalIcon: "help_outline",
            iconVariant: "primary",
            modalTitle: modalTitle_,
            modalContent: (openedModalData: any) => {
                return (
                    <>
                        <div className="mb-3"> {modalMessage_}</div>
                        {!isFaultyLDSBus(busNumber) && (
                            <PowerCycleCheckBox
                                custom
                                id="power-cycle"
                                className="mb-3"
                                checked={openedModalData.powerCycle}
                                onChange={(e) => {
                                    setModalData({
                                        ...modalData,
                                        powerCycle: !openedModalData.powerCycle,
                                    });
                                }}
                                label={
                                    <span className="ml-1">
                                        Scan with power cycle
                                    </span>
                                }
                            />
                        )}
                    </>
                );
            },
            modalButton: (openedModalData: any) => {
                return (
                    <>
                        <Button variant="danger" onClick={closeModal}>
                            CANCEL
                        </Button>
                        <Button
                            disabled={
                                !isGatewayScan &&
                                status === "Off" &&
                                !openedModalData.powerCycle
                            }
                            onClick={() => {
                                scanThisGateway(busNumber, openedModalData);
                            }}
                        >
                            SCAN
                        </Button>
                    </>
                );
            },
        });
    };

    const openWarningConfirmationModal = ({
        resource,
        actionType,
        selectedPortToScan = 0,
    }: {
        resource: "gateway" | "port";
        actionType: "scan" | "restart";
        selectedPortToScan?: number;
    }) => {
        openModal({
            key: "handle_warning_confirmation_modal",
            modalType: "custom",
            modalIcon: "help_outline",
            iconVariant: "primary",
            modalTitle: "Disable Related Events",
            modalContent: `A ${actionType} request may cause events to be falsely triggered. This message may be ignored if you have already disabled ${
                resource === "gateway"
                    ? "all events on this gateway"
                    : "events involving this port"
            }.`,
            modalButton: (
                <>
                    <Button variant="danger" onClick={closeModal}>
                        CANCEL
                    </Button>
                    <Button
                        onClick={() => {
                            if (
                                resource === "gateway" &&
                                actionType === "scan"
                            ) {
                                openScanModal(selectedPortToScan);
                            } else if (
                                resource === "gateway" &&
                                actionType === "restart"
                            ) {
                                openGatewayRestartModal();
                            } else if (
                                resource === "port" &&
                                actionType === "scan"
                            ) {
                                openScanModal(selectedPortToScan);
                            }
                        }}
                    >
                        CONTINUE
                    </Button>
                </>
            ),
        });
    };

    return (
        <ContentWrapper
            isLoading={isPageLoading}
            isForbiddenResource={isForbidden}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12} className="gateway-header">
                            {registryDetails.name && (
                                <h5
                                    className="page-title"
                                    dangerouslySetInnerHTML={{
                                        __html: registryDetails.name.replace(
                                            / /g,
                                            "&nbsp;"
                                        ),
                                    }}
                                ></h5>
                            )}
                            <div className="gateway-header-buttons">
                                {hasOnboard && (
                                    <HoverAuthorizeTooltip
                                        className="mr-2"
                                        permission="gateway:update"
                                    >
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                openWarningConfirmationModal({
                                                    resource: "gateway",
                                                    actionType: "scan",
                                                    selectedPortToScan: 0,
                                                });
                                            }}
                                        >
                                            <span>
                                                <img
                                                    className="mr-2 ml-1"
                                                    src={searchIcon}
                                                    alt="search"
                                                />
                                            </span>
                                            Scan
                                        </Button>
                                    </HoverAuthorizeTooltip>
                                )}

                                {hasOnboard && (
                                    <HoverAuthorizeTooltip
                                        className="mr-2"
                                        permission="gateway:update"
                                    >
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                openWarningConfirmationModal({
                                                    resource: "gateway",
                                                    actionType: "restart",
                                                });
                                            }}
                                        >
                                            <span>
                                                <img
                                                    className="mr-2 ml-1"
                                                    src={restartIcon}
                                                    alt="restart"
                                                />
                                            </span>
                                            Restart
                                        </Button>
                                    </HoverAuthorizeTooltip>
                                )}

                                <HoverAuthorizeTooltip
                                    className=""
                                    permission="gateway:delete"
                                >
                                    <Button
                                        variant="danger"
                                        onClick={openDownloadReminderModal}
                                    >
                                        <span>
                                            <img
                                                className="mr-2 ml-2"
                                                src={dltWhiteIcon}
                                                alt="delete"
                                            />
                                        </span>
                                        Delete
                                    </Button>
                                </HoverAuthorizeTooltip>
                            </div>
                        </Col>
                    </Row>
                    <GatewayLDSUDetails
                        nameEditable={nameEditable}
                        handleNameChange={handleNameChange}
                        isNameEditing={isNameEditing}
                        handleClickEdit={handleClickEdit}
                        handleClickUpdate={handleClickUpdate}
                        isRefreshing={isRefreshing}
                        refreshThisGateway={refreshThisGateway}
                        timestampDetails={timestampDetails}
                        registryDetails={registryDetails}
                        hasOnboard={hasOnboard}
                        gatewayId={gatewayId}
                    />

                    {!hasOnboard && (
                        <OnboardInstructions
                            setHasOnboard={setHasOnboard}
                            refreshThisGateway={refreshThisGateway}
                            fetchRegistry={fetchRegistry}
                        />
                    )}

                    {hasOnboard && (
                        <>
                            <Row>
                                <Col xs={12} className="gateway-ports">
                                    {LDSUPowerState.map(
                                        ({
                                            id,
                                            power,
                                            status,
                                            refreshing,
                                            info,
                                        }: LDSBusState) => (
                                            <LDSBusBox
                                                key={id}
                                                className={`${getPowerStatusText(
                                                    power,
                                                    status
                                                ).toLowerCase()}`}
                                            >
                                                <div className="mb-4 box-header">
                                                    <Link
                                                        to={{
                                                            pathname: `/gateway-details/${gatewayId}/LDSBus/${id}`,
                                                            state: {
                                                                gatewayName:
                                                                    registryDetails.name,
                                                                portNumber: `${id}`,
                                                                UUID: gatewayId,
                                                            },
                                                        }}
                                                        className="box-title"
                                                    >
                                                        LDSBus {id}
                                                    </Link>
                                                    <DeviceStatus
                                                        className={`${getPowerStatusText(
                                                            power,
                                                            status
                                                        ).toLowerCase()}`}
                                                    >
                                                        <span className="status mr-1">
                                                            {getPowerStatusText(
                                                                power,
                                                                status
                                                            )}
                                                        </span>
                                                        <img
                                                            className={
                                                                refreshing
                                                                    ? "refresh-icon refresh-animation"
                                                                    : "refresh-icon"
                                                            }
                                                            src={refreshIcon}
                                                            alt="refresh"
                                                            onClick={() => {
                                                                refreshOneBus(
                                                                    id
                                                                );
                                                            }}
                                                            onKeyDown={() => {
                                                                refreshOneBus(
                                                                    id
                                                                );
                                                            }}
                                                        />
                                                    </DeviceStatus>
                                                    {isFaulty(status) && (
                                                        <FaultyTooltip
                                                            status={status}
                                                        />
                                                    )}
                                                    <Link
                                                        to={{
                                                            pathname: `/gateway-details/${gatewayId}/LDSBus/${id}`,
                                                            state: {
                                                                gatewayName:
                                                                    registryDetails.name,
                                                                portNumber: `${id}`,
                                                                UUID: gatewayId,
                                                            },
                                                        }}
                                                        className="ml-auto"
                                                    >
                                                        <ArrowIcon className="align-middle" />
                                                    </Link>
                                                </div>
                                                <Box className="mb-2 d-flex">
                                                    <div>
                                                        {get(
                                                            info,
                                                            "ldsus.length",
                                                            0
                                                        )}{" "}
                                                        <BlueText className="ml-2 large">
                                                            LDSUs
                                                        </BlueText>
                                                    </div>
                                                    <Link
                                                        to={{
                                                            pathname: `/gateway-details/${gatewayId}/LDSBus/${id}`,
                                                            state: {
                                                                gatewayName:
                                                                    registryDetails.name,
                                                                portNumber: `${id}`,
                                                                UUID: gatewayId,
                                                            },
                                                        }}
                                                        className="ml-auto"
                                                    >
                                                        <ArrowIcon className="material-icons align-middle">
                                                            keyboard_arrow_right
                                                        </ArrowIcon>
                                                    </Link>
                                                </Box>
                                                <div className="mb-2">
                                                    <BlueText>
                                                        Sensors and Actuators
                                                        reported from connected
                                                        LDSUs
                                                    </BlueText>
                                                </div>
                                                <Row>
                                                    <Col>
                                                        <Box className="mb-2 d-flex">
                                                            <div>
                                                                {get(
                                                                    info,
                                                                    "sensors.length",
                                                                    0
                                                                )}{" "}
                                                                <BlueText className="ml-2 large">
                                                                    Sensors
                                                                </BlueText>
                                                            </div>
                                                            <Link
                                                                to={{
                                                                    pathname: `/gateway-details/${gatewayId}/LDSBus/${id}`,
                                                                    state: {
                                                                        gatewayName:
                                                                            registryDetails.name,
                                                                        portNumber: `${id}`,
                                                                        UUID: gatewayId,
                                                                        panel: LDSUTabPanel.Sensor,
                                                                    },
                                                                }}
                                                                className="ml-auto"
                                                            >
                                                                <ArrowIcon className="material-icons align-middle">
                                                                    keyboard_arrow_right
                                                                </ArrowIcon>
                                                            </Link>
                                                        </Box>
                                                    </Col>
                                                    <Col>
                                                        <Box className="mb-2 d-flex">
                                                            <div>
                                                                {get(
                                                                    info,
                                                                    "actuators.length",
                                                                    0
                                                                )}{" "}
                                                                <BlueText className="ml-2 large">
                                                                    Actuators
                                                                </BlueText>
                                                            </div>
                                                            <Link
                                                                to={{
                                                                    pathname: `/gateway-details/${gatewayId}/LDSBus/${id}`,
                                                                    state: {
                                                                        gatewayName:
                                                                            registryDetails.name,
                                                                        portNumber: `${id}`,
                                                                        UUID: gatewayId,
                                                                        panel: LDSUTabPanel.Actuator,
                                                                    },
                                                                }}
                                                                className="ml-auto"
                                                            >
                                                                <ArrowIcon className="material-icons align-middle">
                                                                    keyboard_arrow_right
                                                                </ArrowIcon>
                                                            </Link>
                                                        </Box>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex flex-wrap">
                                                    <HoverAuthorizeTooltip
                                                        className="mr-2 mt-2"
                                                        permission="gateway:update"
                                                    >
                                                        <CustomButton
                                                            onClick={() => {
                                                                openWarningConfirmationModal(
                                                                    {
                                                                        resource:
                                                                            "port",
                                                                        actionType:
                                                                            "scan",
                                                                        selectedPortToScan:
                                                                            id,
                                                                    }
                                                                );
                                                            }}
                                                            variant="primary"
                                                        >
                                                            <span>
                                                                <img
                                                                    className="mr-2"
                                                                    src={
                                                                        searchIcon
                                                                    }
                                                                    alt="search"
                                                                />
                                                            </span>
                                                            Scan
                                                        </CustomButton>
                                                    </HoverAuthorizeTooltip>
                                                    <HoverAuthorizeTooltip
                                                        className="mr-2 mt-2"
                                                        permission="gateway:update"
                                                    >
                                                        <CustomButton
                                                            onClick={() =>
                                                                openDeleteModal(
                                                                    id
                                                                )
                                                            }
                                                            variant="danger"
                                                        >
                                                            <span className="d-flex align-items-center">
                                                                <img
                                                                    className="mr-2"
                                                                    src={
                                                                        dltWhiteIcon
                                                                    }
                                                                    alt="delete"
                                                                />
                                                                <span>
                                                                    Delete
                                                                </span>
                                                            </span>
                                                        </CustomButton>
                                                    </HoverAuthorizeTooltip>
                                                    {!isFaulty(status) && (
                                                        <HoverAuthorizeTooltip
                                                            className="mt-2 ml-xl-auto"
                                                            permission="gateway:update"
                                                        >
                                                            <CustomButton
                                                                onClick={() =>
                                                                    openTogglePowerModal(
                                                                        id,
                                                                        power
                                                                    )
                                                                }
                                                                variant={`${
                                                                    power
                                                                        ? "danger"
                                                                        : "primary"
                                                                }`}
                                                                className=""
                                                            >
                                                                Power{" "}
                                                                {power
                                                                    ? "Off"
                                                                    : "On"}
                                                            </CustomButton>
                                                        </HoverAuthorizeTooltip>
                                                    )}
                                                </div>
                                            </LDSBusBox>
                                        )
                                    )}
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <div className="gateway-details-tabs">
                                        <Tab.Container
                                            id="left-tabs-example"
                                            defaultActiveKey="chart"
                                        >
                                            <Row>
                                                <Col sm={12}>
                                                    <Nav>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="chart">
                                                                <img
                                                                    src={
                                                                        chartIcon
                                                                    }
                                                                    alt="chart"
                                                                />
                                                                <h6>
                                                                    Hierarchy
                                                                    Chart
                                                                </h6>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                eventKey="location"
                                                                onClick={() => {
                                                                    setLoadMap(
                                                                        "loaded"
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        mapIcon
                                                                    }
                                                                    alt="map"
                                                                />
                                                                <h6>
                                                                    Gateway
                                                                    Location
                                                                </h6>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="storage">
                                                                <img
                                                                    src={
                                                                        storageIcon
                                                                    }
                                                                    alt="storage"
                                                                />
                                                                <h6>
                                                                    Manage
                                                                    Storage
                                                                </h6>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="update">
                                                                <img
                                                                    src={
                                                                        updateIcon
                                                                    }
                                                                    alt="update"
                                                                />
                                                                <h6>
                                                                    Firmware
                                                                    Update
                                                                </h6>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="descriptor">
                                                                <img
                                                                    src={
                                                                        descriptorIcon
                                                                    }
                                                                    alt="descriptor"
                                                                />
                                                                <h6>
                                                                    Gateway
                                                                    Descriptor
                                                                </h6>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col sm={12}>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="chart">
                                                            <HierarchyTab
                                                                name={
                                                                    registryDetails.name
                                                                }
                                                                ldsuStatuses={
                                                                    ldsuStatuses
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="location">
                                                            <LocationTab
                                                                loadMap={
                                                                    loadMap
                                                                }
                                                                registryDetails={
                                                                    registryDetails
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="storage">
                                                            <StorageTab
                                                                registryDetails={
                                                                    registryDetails
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="update">
                                                            <UpdateTab
                                                                registryDetails={
                                                                    registryDetails
                                                                }
                                                                setIsFirmwareUpdated={
                                                                    setIsFirmwareUpdated
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="descriptor">
                                                            <DescriptorTab
                                                                registryDetails={
                                                                    registryDetails
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </div>
            {modal}

            <LoadingModal showModal={isLoading} title={loadingTitle} />
            <RemindDownloadDataModal
                show={showRemindDownload}
                onConfirm={handleRemindDownload}
            />
            <WalletAlertComponent />
        </ContentWrapper>
    );
};

export default GatewayDetails;
