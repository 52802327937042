import { createContext, useContext } from "react";
import { AppContext } from "./appContext";
import { restoreData } from "store/actions";
import LocalStorageService from "service/localStorageService";
import { Redirect } from "react-router-dom";

type AuthContextType = {
    authTokens: string;
    setAuthTokens: any;
};

export const AuthContext = createContext<AuthContextType>({
    authTokens: "",
    setAuthTokens: null,
});

export const useAuth = () => {
    const authContext = useContext(AuthContext);
    const {
        storeData: { organization },
        storeDispatchActions,
    } = useContext(AppContext);

    const signOut = () => {
        const cacheOrgId: string = organization.currentOrgId;
        authContext.setAuthTokens("");
        storeDispatchActions(restoreData());
        LocalStorageService.clearAll();
        LocalStorageService.setItem("lastLoggedOrgId", cacheOrgId);
        return <Redirect to="/" />;
    };

    return {
        context: authContext,
        signOut,
    };
};
