import { TypeOption, Direction } from "constant";
import { Col, Form, Row } from "react-bootstrap";

type EventVariableOption = {
    label: string;
    value: string | number;
    key: string;
};

type EventVariableFormSelectProps = {
    name: string;
    label: string;
    value: string | TypeOption | Direction;
    onChange: Function;
    options: EventVariableOption[];
    disabled?: boolean;
};

const EventVariableFormSelect = ({
    name,
    label,
    value,
    onChange,
    options,
    disabled = false,
}: EventVariableFormSelectProps) => {
    return (
        <Form.Group as={Row} className="mb-3" controlId={name}>
            <Form.Label column sm="2">
                {label}
            </Form.Label>
            <Col sm="10">
                <Form.Control
                    className={disabled ? "disabled-input" : ""}
                    as="select"
                    required
                    aria-label={name}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={disabled}
                >
                    {options.map((option) => (
                        <option value={option.value} key={option.key}>
                            {option.label}
                        </option>
                    ))}
                </Form.Control>
            </Col>
        </Form.Group>
    );
};

export default EventVariableFormSelect;
