import AxiosInstance from "./axiosInstance";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "../utils/functions";
import { apiFactory } from "generated";
import { OrganisationRole, Role } from "generated/models";
import { OrganisationPermissions } from "types";

class RolesService {
    private orgClient = apiFactory.getOrganisationInstance(AxiosInstance);

    /**
     * Create new role
     * @param { object } data - New role data
     * @returns { promise }
     */
    async createNewRole(orgId: string, data: {
        name: string,
        permissions: OrganisationPermissions
    }) {
        try {

            const {name, permissions } = data;
            const res = await this.orgClient.createRole(orgId, {
                name, 
                permission: permissions as OrganisationRole
            });
            return createResponseStandard<Role>(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }


    /**
     * Remove the Role from Organisation
     * @param { string } orgId - Organisation id
     * @param { string } roleId - Role id
     * @returns { promise }
     */
    async deleteRole(orgId: string, roleId: string) {
        try {
            const res = await this.orgClient.deleteRoleByUuid(orgId, roleId);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Update the Role
     * @param { string } orgId - Organisation id
     * @param { string } roleId - Role id
     * @param { string } roleName - The Role name
     * @returns { promise }
     */

    async updateRole(orgId: string, roleId: string, data: {
        permissions?: OrganisationPermissions,
        name?: string
    }) {
        try {
            const {name, permissions } = data;
            const res = await this.orgClient.updateRoleByUuid(orgId, roleId, {
                name,
                permission: permissions as OrganisationRole
            });
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    /**
     * Get Role Details
     * @param { string } roleId - Role id
     * @returns { Promise }
     */
    async getRoleById(orgId: string, roleId: string) {
        try {
            const res = await this.orgClient.getRoleByUuid(orgId, roleId);
            return createResponseStandard(res);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }
}

const rolesService = new RolesService();

export default rolesService;
