import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { Col, Form } from "react-bootstrap";

const ControlOptions = () => {
    const {
        editedActuator,
        editedActuator: { auto },
        setEditedActuator,
    } = useActuatorConfigurationContext();
    return (
        <>
            <Form.Label className="mt-2 mb-3">Control</Form.Label>

            <Form.Row className="sensor-mode mb-5">
                <Col md={2}>
                    <Form.Check
                        name="auto-manual"
                        id={"manual"}
                        custom
                        type="radio"
                        checked={!auto}
                        onChange={() => {
                            setEditedActuator({
                                ...editedActuator,
                                auto: false,
                            });
                        }}
                        label={<span className="ml-2">Manual</span>}
                    />
                </Col>
                <Col md={2}>
                    <Form.Check
                        name="auto-manual"
                        id={"auto"}
                        custom
                        type="radio"
                        checked={auto}
                        onChange={() => {
                            setEditedActuator({
                                ...editedActuator,
                                auto: true,
                            });
                        }}
                        label={<span className="ml-2">Auto</span>}
                    />
                </Col>
            </Form.Row>
        </>
    );
};

export default ControlOptions;
