import { faker } from "@faker-js/faker";
import { toast, ToastOptions } from "react-toastify";
import { ResponseDto } from "types/common";

interface AlertOptions extends ToastOptions {
    title?: string;
    message?: string | React.ElementType | JSX.Element;
}

const showAlert = (options: AlertOptions) => {
    const { theme = "colored", type = "info", title, message } = options;
    return toast(
        <div className="content">
            {title && <b className="title">{title}</b>}
            {message && <p className="description">{message}</p>}
        </div>,
        {
            hideProgressBar: true,
            ...options,
            type,
            theme,
        }
    );
};

export const showErrorAlert = (options: AlertOptions) => {
    const { type = "error", title = "Error" } = options;
    return showAlert({ ...options, type, title });
};

export const showSuccessAlert = (options: AlertOptions) => {
    const { type = "success", title = "Success" } = options;
    return showAlert({ ...options, type, title });
};

export const showInfoAlert = (options: AlertOptions) => {
    const { type = "info", title = "Info" } = options;
    return showAlert({ ...options, type, title });
};

export const getAPIError = (res: ResponseDto, defaultMessage: string) => {
    const {
        data: { description, title = "Error", errors = [] },
    } = res;
    let message = description ?? defaultMessage;
    if (errors && errors.length > 0) {
        message = errors.map(
            ({ key, value = [] }: { key: string; value: string[] }) => {
                return (
                    <div key={key}>
                        {Array.isArray(value)
                            ? value.map((t) => (
                                  <span key={faker.datatype.uuid()}>{t}</span>
                              ))
                            : value}
                    </div>
                );
            }
        );
    }

    return { title, message };
};
