import { createContext, useContext } from 'react';
import DefaultAppState, { AppState } from 'store/state';

export type AppContextType = {
    storeData: AppState;
    storeDispatchActions: any;
};

export const AppContext = createContext<AppContextType>({
    storeData: DefaultAppState,
    storeDispatchActions: () => {},
});

export const useAppContext = () => {
    return useContext(AppContext);
};
