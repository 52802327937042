import React, { useState, useEffect, useContext, KeyboardEvent } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Form,
    FormControl,
    Modal,
} from "react-bootstrap";
import { isEmpty, get, isNull } from "lodash";

import refreshIcon from "assets/svg/refresh-green.svg";
import {
    updateSensor,
    createNewAlertNotification,
    updateAlertNotification,
    getAllAlertNotification,
    getSensorLastReading,
    updateSensorNotice,
    fetchDetailDevice,
} from "service/gatewayService";
import OrganisationService from "service/organisationService";
import LocalStorageService from "service/localStorageService";

import { AppContext } from "context/appContext";
import ripple from "assets/img/ripple.gif";
import {
    SensorDefaultMessage,
    LDSUTabPanel,
    HttpStatus,
    Patterns,
    Operator,
    SensorThreshold,
    SensorAlertMode,
    AlertPeriod,
    BinarySensorsCLSList,
    SensorAssociationCLS,
    CalendarFormat,
    SensorUnitText,
    SensorDataType,
} from "constant";
import {
    orgId,
    formatUnitWithSymbol,
    getPrimaryUnitName,
    isHttpSuccess,
    customMinMax,
    handleAccuracyException,
    getPrimaryUnitKey,
    getPrimaryUnit,
} from "utils/functions";

import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { DownloadDataUI } from "components/modals/DownloadDataUI";
import SensorAssociation from "components/gateways/SensorAssociation";

import { WalletAlertComponent, useWalletStore } from "hooks/wallet";
import {
    convertIdToUserInfoArray,
    decimalPlaceMatches,
    extractedIdList,
    formatReading,
} from "utils/gatewayFunctions";
import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import FormDeviceValueControl from "components/form-control/FormDeviceValueControl";
import { showErrorAlert, showInfoAlert, showSuccessAlert } from "utils/alert";
import { AlertStatus, LocationState, SensorJsonInfoProps } from "types/Sensors";
import { AlertConfig, MemberDto } from "types";
import DefaultModal from "components/modals/ModalTemplate";
import moment from "moment";
import AlertNoticeConfig, {
    getDefaultAlertConfig,
} from "components/gateways/AlertNoticeConfig";
import "../../assets/css/sensor.css";
import { BlueText } from "components/common";

const { NO_CONTENT, PAYMENT_REQUIRED, FORBIDDEN } = HttpStatus;
const { OUT_OF_RANGE, WITHIN_RANGE, LESS_THAN, GREATER_THAN, EQUAL_TO } =
    Operator;
const { ALERT_ONCE, ALERT_CONTINUOUSLY } = SensorAlertMode;

const SensorDetails = () => {
    const location = useLocation();
    const history = useHistory();
    const [details, setDetails] = useState<any>(
        location.state as LocationState
    );
    const { sensorDetails = {}, gatewayName, portNumber } = details || {};
    const params: any = useParams();
    const [editableSensorDetails, setEditableSensorDetails] =
        useState(sensorDetails);
    const { cls = "" }: { cls: string } = editableSensorDetails;
    const [editableSensorName, setEditableSensorName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [configureModalShow, setConfigureModalShow] = useState(false);
    const [showConfirmNoAlertMethod, setShowConfirmNoAlertMethod] =
        useState(false);

    // new API State Management
    const [alertConfig, setAlertConfig] = useState<AlertConfig>(
        getDefaultAlertConfig()
    );
    const [alertUUID, setAlertUUID] = useState("");
    const [sensorMode, setSensorMode] = useState(
        BinarySensorsCLSList.includes(cls)
            ? SensorThreshold.NO_THRESHOLD
            : SensorThreshold.SINGLE_THRESHOLD
    );

    const [singleThresholdValue, setSingleThresholdValue] = useState("0");
    const [binaryValue, setBinaryValue] = useState("1");
    const [singleActivateCondition, setSingleActivateCondition] =
        useState(GREATER_THAN);
    const [dualMinValue, setDualMinValue] = useState("0");
    const [dualMaxValue, setDualMaxValue] = useState("0");
    const [dualActivateCondition, setDualActivateCondition] = useState(
        OUT_OF_RANGE.toString()
    );
    const [alertMode, setAlertMode] = useState(ALERT_ONCE);
    const [alertPeriod, setAlertPeriod] = useState(30);
    const [activateMessageContent, setActivateMessageContent] = useState(
        SensorDefaultMessage.activationMessage
    );
    const [activateHeaderContent, setActivateHeaderContent] = useState(
        SensorDefaultMessage.activationHeader
    );
    const [deactivateMessageContent, setDeactivateMessageContent] = useState(
        SensorDefaultMessage.deactivationMessage
    );
    const [deactivateHeaderContent, setDeactivateHeaderContent] = useState(
        SensorDefaultMessage.deactivationHeader
    );
    const [activationCheckboxState, setActivationCheckboxState] =
        useState(false);
    const [deactivationCheckboxState, setDeactivationCheckboxState] =
        useState(false);
    const [isAlertDisabled, setIsAlertDisabled] = useState(false);
    const [opMode, setOpMode] = useState(0);
    const [thresholdError, setThresholdError] = useState(false);
    const [thresholdErrorMessage, setThresholdErrorMessage] = useState("");
    const [periodError, setPeriodError] = useState(false);
    const [periodErrorMessage, setPeriodErrorMessage] = useState("");
    const [currEnabledStatus, setCurrEnabledStatus] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [initialMemberList, setInitialMemberList] = useState<
        Array<MemberDto>
    >([]);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const [isRefreshing, setIsRefreshing] = useState(false);
    const [sensorJsonInfo, setSensorJsonInfo]: any = useState<
        SensorJsonInfoProps | undefined
    >();

    const { storeData } = useContext(AppContext);
    const { isDefault, owner } = storeData.organization.currentOrgInfo;
    const { wallet } = useWalletStore();
    const getValueModeOrObj = (obj: any, valueName: string, opMode: number) => {
        if (obj?.APPLICATION?.length ?? undefined) {
            const app = obj.APPLICATION.find(({ APPID }: any) => {
                return APPID === sensorDetails.app_id;
            });
            const defaultUnit = getPrimaryUnitName(app);

            return defaultUnit[valueName];
        }

        const modeValue = get(obj, `${valueName}`),
            objValue = get(obj, `MODE`)?.find(
                ({ ID }: { ID: string }) => ID === opMode.toString()
            )?.[valueName];
        return modeValue !== undefined ? modeValue : objValue || "";
    };
    const accuracy = getValueModeOrObj(sensorJsonInfo, "ACCURACY", opMode);
    const accuracyException = handleAccuracyException(accuracy);
    const min = Number(getValueModeOrObj(sensorJsonInfo, "MIN", opMode));
    const max = Number(getValueModeOrObj(sensorJsonInfo, "MAX", opMode));
    const format = getValueModeOrObj(sensorJsonInfo, "FORMAT", opMode);
    const userIsNotSubscribed = !isEmpty(wallet) && !wallet.isSubscribe;

    const DOWNLOAD_SENSOR = "downloadSensor";

    useEffect(() => {
        const fetch = async () => {
            fetchDetailDevice({
                gateway_id: params.gatewayId,
                device_id: params.ldsuId,
                said: params.said,
            }).then((res) => {
                if (get(res, "status") === FORBIDDEN) {
                    setIsForbiddenResource(true);
                    return;
                }

                const primaryUnitKey = res.APP
                    ? getPrimaryUnitKey(res.APP[Number(res.SAID)])
                    : null;

                const sensorDetails = {
                    ldsu_uuid: res.UID,
                    ldsu_name: res.name,
                    SAID: Number(res.SAID),
                    MODE: Number(res.MODE),
                    sensor_name: get(res.sensor_names, params.said),
                    sensor_type: res.type,
                    unit: res.APP
                        ? getPrimaryUnit(res.APP[Number(res.SAID)])
                        : res.deviceInfo.UNIT,
                    format: res.APP? getPrimaryUnitName(res.APP[Number(res.SAID)]).FORMAT :  res.deviceInfo.FORMAT,
                    minmax: [min, max],
                    opMode: [res.deviceInfo.MODE?.[params.said]],
                    readings: res.readings,
                    port: res.port,
                    gateway_name: res.gateway_name,
                    associated_sensors: res.association[res.deviceInfo.CLS],
                    cls: res.deviceInfo.CLS,
                    app_id: get(res.APP, `${Number(res.SAID)}.APPID`, ""),
                    primaryUnitKey,
                };

                setEditableSensorDetails(sensorDetails);
                setEditableSensorName(sensorDetails.sensor_name);

                setDetails({
                    sensorDetails,
                    gatewayName: res.gateway_name,
                    portNumber: res.port,
                    UUID: res.UID,
                });

                setSensorJsonInfo(res.deviceInfo);
            });

            const alertResponse: any = await getAllAlertNotification(
                params.gatewayId,
                params.ldsuId,
                params.said
            );
            const { status, data = [] } =
                await OrganisationService.getOrgMembers(orgId());

            let members: MemberDto[] = [];
            if (isHttpSuccess(status)) {
                members = data.toCamelCase<MemberDto>().filter((acc) => {
                    return (
                        acc.status !== "Pending" && acc.status !== "Declined"
                    );
                });
                setInitialMemberList(members);
            }

            if (isHttpSuccess(alertResponse.status)) {
                // CHECK IF THERE IS AN ALERT UUID FOR THIS SENSOR
                if (alertResponse.data.length) {
                    const data = alertResponse.data[0];
                    setAlertUUID(data.uuid);
                    setCurrEnabledStatus(data.enabled);
                    if (data.ops[0] === EQUAL_TO) {
                        setSensorMode(SensorThreshold.NO_THRESHOLD);
                        setBinaryValue(data.vars_[1]);
                    } else if (data.vars_.length === 2) {
                        setSensorMode(SensorThreshold.SINGLE_THRESHOLD);
                        setSingleThresholdValue(
                            reformattedNumberString(data.vars_[1])
                        );
                        setDualMinValue(reformattedNumberString("0"));
                        setDualMaxValue(reformattedNumberString("0"));
                    } else {
                        setSensorMode(SensorThreshold.DUAL_THRESHOLD);
                        setDualMinValue(reformattedNumberString(data.vars_[1]));
                        setDualMaxValue(reformattedNumberString(data.vars_[3]));
                        setSingleThresholdValue(reformattedNumberString("0"))
                    }
                    if (data.ops.length === 1) {
                        data.ops[0] === GREATER_THAN
                            ? setSingleActivateCondition(GREATER_THAN)
                            : setSingleActivateCondition(LESS_THAN);
                    }
                    if (data.ops.length === 3) {
                        data.ops[0] === Operator.GREATER_THAN &&
                        data.ops[2] === Operator.LESS_THAN
                            ? setDualActivateCondition(WITHIN_RANGE.toString())
                            : setDualActivateCondition(OUT_OF_RANGE.toString());
                    }
                    if (data.repeat === AlertPeriod.ALERT_ONCE_MAX) {
                        setAlertMode(ALERT_ONCE);
                    } else {
                        setAlertMode(ALERT_CONTINUOUSLY);
                        setAlertPeriod(data.repeat);
                    }

                    const {
                        enabled_email,
                        recipients_email,
                        enabled_sms,
                        recipients_sms,
                        enabled_push,
                        recipients_push,
                        subject,
                        text,
                        enabled: [
                            activationEnabled = false,
                            deactivationEnabled = false,
                        ],
                    } = data.notice;

                    if (subject) {
                        if (!isNull(subject[0])) {
                            setActivateHeaderContent(subject[0]);
                            setActivateMessageContent(text[0]);
                        }
                        if (!isNull(subject[1])) {
                            setDeactivateHeaderContent(subject[1]);
                            setDeactivateMessageContent(text[1]);
                        }
                    }
                    setActivationCheckboxState(activationEnabled);
                    setDeactivationCheckboxState(deactivationEnabled);

                    setAlertConfig({
                        Email: {
                            isEnabled: enabled_email,
                            recipientMode: "Email",
                            recipients: convertIdToUserInfoArray(
                                members,
                                recipients_email
                            ),
                        },
                        SMS: {
                            isEnabled: enabled_sms,
                            recipientMode: "SMS",
                            recipients: convertIdToUserInfoArray(
                                members,
                                recipients_sms
                            ),
                        },
                        Notification: {
                            isEnabled: enabled_push,
                            recipientMode: "Notification",
                            recipients: convertIdToUserInfoArray(
                                members,
                                recipients_push
                            ),
                        },
                    });
                } else {
                    setSensorMode(
                        sensorDetails.format === SensorDataType.Boolean
                            ? SensorThreshold.NO_THRESHOLD
                            : SensorThreshold.SINGLE_THRESHOLD
                    );
                }
            }

            if (sensorDetails.MODE) {
                setOpMode(sensorDetails.MODE);
            }

            !isEmpty(editableSensorDetails) &&
                setEditableSensorName(editableSensorDetails.sensor_name);
            setIsPageLoading(false);
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accuracyException]);

    useEffect(() => {
        setIsAlertDisabled(
            !activationCheckboxState && !deactivationCheckboxState
        );
    }, [activationCheckboxState, deactivationCheckboxState]);

    const isAlertEnabled = () =>
        alertConfig.Email.isEnabled ||
        alertConfig.SMS.isEnabled ||
        alertConfig.Notification.isEnabled;

    const { min: displayedMin, max: displayedMax } = customMinMax({
        min: Number(min),
        max: Number(max),
        acc: Number(accuracyException),
    });

    const reformattedNumberString = (value: number | string) =>
        Number(value).toFixed(accuracyException).toString();

    const onAlertConfigChange = (config: AlertConfig) => {
        setAlertConfig(config);
    };

    const onChangeActivateCheckbox = () => {
        setActivationCheckboxState(!activationCheckboxState);
    };

    const onChangeDeactivateCheckbox = () => {
        setDeactivationCheckboxState(!deactivationCheckboxState);
    };

    const goToSensorListPage = () => {
        history.push(
            `/gateway-details/${params.gatewayId}/LDSBus/${params.busNumber}`,
            {
                gatewayName: gatewayName || editableSensorDetails.gateway_name,
                portNumber: portNumber || editableSensorDetails.port,
                panel: LDSUTabPanel.Sensor,
            }
        );
    };

    const validateNumber = (value: any) => {
        return Patterns.thresholdPattern.test(value);
    };

    const renderUnits = (units: string) => {
        return get(SensorUnitText, units, " " + units);
    };

    const renderDateTime = (reading: any) => {
        if (reading) {
            return moment(reading.timestamp, "X").calendar(CalendarFormat);
        } else {
            return "Offline";
        }
    };

    const saveSensorName = async () => {
        if (editableSensorName === editableSensorDetails.sensor_name) {
            return setIsEditing(false);
        }

        if (!editableSensorName || editableSensorName.length > 32) {
            setIsEditing(true);
            return showErrorAlert({
                message: "Sensor Name must be between 1-32 characters.",
            });
        }

        setIsLoading(true);
        let { status, data = {} } = await updateSensor(
            params.gatewayId,
            params.ldsuId,
            params.said,
            {
                name: editableSensorName,
            }
        );

        if (isHttpSuccess(status)) {
            showSuccessAlert({
                message: "Sensor name has been updated successfully",
            });
            setEditableSensorDetails({
                ...editableSensorDetails,
                sensor_name: editableSensorName,
            });
            setIsEditing(false);
        } else if (status === PAYMENT_REQUIRED) {
            useWalletStore.setState?.({
                modalShow: true,
            });
        } else {
            showErrorAlert({
                message: get(
                    data,
                    "description",
                    "Something went wrong! Please try again."
                ),
            });
        }
        setIsLoading(false);
    };

    const refreshThisSensor = async () => {
        setIsRefreshing(true);
        const refreshResponse: any = await getSensorLastReading(
            params.gatewayId,
            sensorDetails.ldsu_uuid
        );
        if (isHttpSuccess(refreshResponse.status)) {
            const { data } = refreshResponse;
            const readings = {
                highest: data.max[sensorDetails.SAID],
                lowest: data.min[sensorDetails.SAID],
                timestamp: data.ts,
                value: data.value[sensorDetails.SAID],
            };
            setEditableSensorDetails({
                ...editableSensorDetails,
                readings: readings,
            });
        }
        setIsRefreshing(false);
    };

    const generateMQTTString = (
        editableSensorDetails: any,
        value: string,
        isIOController: boolean
    ) => {
        const metricInfo = LocalStorageService.getItem("metricInfo");

        const { cls, app_id, primaryUnitKey } = editableSensorDetails;

        const filteredObj = isIOController
            ? metricInfo.app[app_id].metrics[primaryUnitKey]
            : metricInfo.cls[cls].metric;

        return filteredObj;
    };

    const validateMsgLength = (msg = "", header?: string) => {
        if (
            header !== undefined &&
            (header.length < 1 || header.length > 280)
        ) {
            return `Subject header should contain 1 to 280 characters.`;
        }

        if (msg.length < 1 || msg.length > 280) {
            return `Message should contain 1 to 280 characters.`;
        }
        return "";
    };

    const singleThresholdModeValidation = () => {
        // Check if SingleValue is a valid number format
        if (
            !validateNumber(singleThresholdValue) ||
            !decimalPlaceMatches(singleThresholdValue, accuracyException)
        ) {
            setThresholdError(true);
            setIsLoading(false);
            setThresholdErrorMessage(
                `Threshold must be a valid number with up to ${accuracyException} d.p.`
            );
            return false;
        }

        if (Number(singleThresholdValue) > displayedMax) {
            setThresholdError(true);
            setThresholdErrorMessage(
                `Threshold value cannot be higher than the sensor's maximum limit of ${displayedMax.toFixed(
                    accuracyException
                )} ${formatUnitWithSymbol(sensorDetails.unit)}.`
            );
            return false;
        }
        if (Number(singleThresholdValue) < displayedMin) {
            setThresholdError(true);
            setThresholdErrorMessage(
                `Threshold value cannot be lower than the sensor's minimum limit of ${displayedMin.toFixed(
                    accuracyException
                )} ${formatUnitWithSymbol(sensorDetails.unit)}.`
            );
            return false;
        }

        return true;
    };

    const dualThresholdModeValidation = () => {
        // check if dualMin/Max value is a valid number format or not.
        if (
            !validateNumber(dualMinValue) ||
            !validateNumber(dualMaxValue) ||
            !decimalPlaceMatches(dualMinValue, accuracyException) ||
            !decimalPlaceMatches(dualMaxValue, accuracyException)
        ) {
            setThresholdError(true);
            setIsLoading(false);
            setThresholdErrorMessage(
                `Limit must be a valid number with up to ${accuracyException} d.p.`
            );
            return false;
        }

        if (Number(dualMinValue) > displayedMax) {
            setThresholdError(true);
            setThresholdErrorMessage(
                `Min limit threshold value cannot be higher than Max limit threshold value of ${displayedMax.toFixed(
                    accuracyException
                )} ${formatUnitWithSymbol(sensorDetails.unit)}.`
            );
            return false;
        }

        if (Number(dualMaxValue) < displayedMin) {
            setThresholdError(true);
            setThresholdErrorMessage(
                `Max limit threshold value cannot be lower than Min limit threshold value of ${displayedMin.toFixed(
                    accuracyException
                )} ${formatUnitWithSymbol(sensorDetails.unit)}.`
            );
            return false;
        }

        // // check if dualMinValue is below min reading.
        if (Number(dualMinValue) < displayedMin) {
            setThresholdError(true);
            setThresholdErrorMessage(
                `Min limit threshold value cannot be lower than the sensor's minimum limit of ${displayedMin.toFixed(
                    accuracyException
                )} ${formatUnitWithSymbol(sensorDetails.unit)}.`
            );
            return false;
        }
        // check if dualMaxValue is above max reading.
        if (Number(dualMaxValue) > displayedMax) {
            setThresholdError(true);
            setThresholdErrorMessage(
                `Max limit threshold value cannot be higher than the sensor's maximum limit of ${displayedMax.toFixed(
                    accuracyException
                )} ${formatUnitWithSymbol(sensorDetails.unit)}.`
            );
            return false;
        }
        // }

        if (Number(dualMinValue) >= Number(dualMaxValue)) {
            setThresholdError(true);
            setThresholdErrorMessage(
                `Max limit threshold value must be higher than Min limit threshold value`
            );
            return false;
        }
        return true;
    };

    const validateRecipientsToggleField = () => {
        // if Send Email is selected, ensure header and content is not empty.
        const {
            Email: { isEnabled: enabledEmail, recipients: emailList },
            SMS: { isEnabled: enabledSMS, recipients: smsList },
            Notification: {
                isEnabled: enabledNotification,
                recipients: notificationList,
            },
        } = alertConfig;

        if (enabledEmail) {
            if (activationCheckboxState) {
                if (activateHeaderContent && activateMessageContent) {
                    const errorMessage = validateMsgLength(
                        activateMessageContent,
                        activateHeaderContent
                    );
                    if (errorMessage) {
                        showErrorAlert({
                            message: errorMessage,
                        });
                        return false;
                    }
                } else {
                    showErrorAlert({
                        message:
                            "Activation subject header and message cannot be empty.",
                    });
                    return false;
                }
            }
            if (deactivationCheckboxState) {
                if (deactivateHeaderContent && deactivateMessageContent) {
                    const errorMessage = validateMsgLength(
                        deactivateMessageContent,
                        deactivateHeaderContent
                    );
                    if (errorMessage) {
                        showErrorAlert({
                            message: errorMessage,
                        });
                        return false;
                    }
                } else {
                    showErrorAlert({
                        message:
                            "Deactivation subject header and message cannot be empty.",
                    });
                    return false;
                }
            }
            // Check if email list is empty
            if (emailList.length < 1 && !isDefault) {
                showErrorAlert({
                    message: "Unable to send email to empty recipients.",
                });
                return false;
            }
        }
        // if Send sms/notification is selected, ensure content is not empty.
        if (enabledSMS || enabledNotification) {
            if (activationCheckboxState) {
                const errorMessage = validateMsgLength(activateMessageContent);
                if (errorMessage) {
                    showErrorAlert({
                        message: errorMessage,
                    });
                    return false;
                }
            }

            if (deactivationCheckboxState) {
                const errorMessage = validateMsgLength(
                    deactivateMessageContent
                );
                if (errorMessage) {
                    showErrorAlert({
                        message: errorMessage,
                    });
                    return false;
                }
            }
            // Check if sms list is empty
            if (
                enabledSMS &&
                isDefault &&
                !initialMemberList[0]?.phoneNumberVerified
            ) {
                showErrorAlert({
                    message: "Unable to send SMS to empty recipients.",
                });
                return false;
            }

            if (enabledSMS && smsList.length < 1 && !isDefault) {
                showErrorAlert({
                    message: "Unable to send SMS to empty recipients.",
                });
                return false;
            }
            // Check if notification list is empty
            if (
                enabledNotification &&
                notificationList.length < 1 &&
                !isDefault
            ) {
                showErrorAlert({
                    message: "Unable to send notification to empty recipients.",
                });
                return false;
            }
        }
        return true;
    };

    const alertPeriodValidate = (alertPeriod_: number) => {
        if (
            alertMode === ALERT_CONTINUOUSLY &&
            (alertPeriod_ < AlertPeriod.ALERT_CONTINUOUSLY_MIN ||
                alertPeriod_ > AlertPeriod.ALERT_CONTINUOUSLY_MAX)
        ) {
            setPeriodError(true);
            setPeriodErrorMessage(
                `Alert period must be between ${AlertPeriod.ALERT_CONTINUOUSLY_MIN} and ${AlertPeriod.ALERT_CONTINUOUSLY_MAX} seconds.`
            );
            return false;
        }
        setPeriodError(false);
        setPeriodErrorMessage("");
        return true;
    };

    const confirmWithoutAlertMethod = async () => {
        if (!alertPeriodValidate(alertPeriod)) return;
        if (!validateRecipientsToggleField()) return;
        if (!isAlertEnabled()) {
            setShowConfirmNoAlertMethod(true);
        } else {
            saveAlertConfig();
        }
    };

    const saveAlertConfig = async () => {
        setThresholdError(false);
        setThresholdErrorMessage("");
        setPeriodError(false);
        setPeriodErrorMessage("");

        const body: any = getAlertBody();

        if (!body) return;

        setConfigureModalShow(true);
        setIsLoading(true);
        let alertResponse;
        let thisUUID = alertUUID ?? "";
        if (alertUUID) {
            alertResponse = await updateAlertNotification(
                params.gatewayId,
                params.ldsuId,
                params.said,
                alertUUID,
                body
            );
        } else {
            alertResponse = await createNewAlertNotification(
                params.gatewayId,
                params.ldsuId,
                params.said,
                body
            );

            if (isHttpSuccess(alertResponse.status)) {
                thisUUID = alertResponse.data.uuid;
                setAlertUUID(thisUUID);
            }
        }

        const noticeResponse: any = await updateSensorNotice(
            params.gatewayId,
            params.ldsuId,
            params.said,
            thisUUID,
            getNoticeBody()
        );

        const alertStatus = alertResponse.status;
        const noticeStatus = noticeResponse.status;

        if (isHttpSuccess(alertStatus) && isHttpSuccess(noticeStatus)) {
            showSuccessAlert({
                message: `Configuration saved successfully. Please note that you can only update the configuration after 2 minutes.`,
            });

            if (isAlertEnabled() && !currEnabledStatus) {
                setIsLoading(false);
            } else {
                if (!isAlertEnabled() && alertStatus === NO_CONTENT) {
                    showInfoAlert({
                        message:
                            "Sensor alert has been disabled since no alert method (Email, SMS, Push Notification) is selected.",
                    });
                }
                setConfigureModalShow(false);
                goToSensorListPage();
            }
        } else if ([alertStatus, noticeStatus].includes(PAYMENT_REQUIRED)) {
            setConfigureModalShow(false);
            useWalletStore.setState?.({
                modalShow: true,
            });
        } else {
            const message = !isHttpSuccess(alertStatus)
                ? get(
                      alertResponse,
                      "data.description",
                      "Unable to configure. Try again"
                  )
                : get(
                      noticeResponse,
                      "data.description",
                      "Unable to configure. Try again"
                  );
            showErrorAlert({
                message,
            });
            setConfigureModalShow(false);
            setIsLoading(false);
        }
    };

    const getNoticeBody = () => {
        let subject = ["", ""];
        let text = ["", ""];

        if (activationCheckboxState) {
            subject[0] = activateHeaderContent;
            text[0] = activateMessageContent;
        }

        if (deactivationCheckboxState) {
            subject[1] = deactivateHeaderContent;
            text[1] = deactivateMessageContent;
        }

        const {
            Email: { isEnabled: enabledEmail, recipients: emailList },
            SMS: { isEnabled: enabledSMS, recipients: smsList },
            Notification: {
                isEnabled: enabledNotification,
                recipients: notificationList,
            },
        } = alertConfig;

        let noticeBody = {
            enabled_email: enabledEmail,
            recipients_email: extractedIdList(emailList),
            enabled_sms: enabledSMS,
            recipients_sms: extractedIdList(smsList),
            enabled_push: enabledNotification,
            recipients_push: extractedIdList(notificationList),
            subject: subject,
            text: text,
            enabled: [activationCheckboxState, deactivationCheckboxState],
        };

        if (isDefault) {
            noticeBody = {
                ...noticeBody,
                recipients_email: [`${owner}`],
                recipients_push: [`${owner}`],
                recipients_sms: [`${owner}`],
            };
        }

        return noticeBody;
    };

    const getAlertBody = () => {
        const mqttString = `$value(${generateMQTTString(
            editableSensorDetails,
            editableSensorDetails.sensor_type,
            !!editableSensorDetails.app_id
        )})`;
        let vars_: Array<string> = [];
        let ops: Array<string> = [];
        if (sensorMode === SensorThreshold.NO_THRESHOLD) {
            vars_ = [mqttString, String(binaryValue)];
            ops = [EQUAL_TO];
        } else if (sensorMode === SensorThreshold.SINGLE_THRESHOLD) {
            if (!singleThresholdModeValidation()) return;
            vars_ = [mqttString, reformattedNumberString(singleThresholdValue)];
            ops =
                singleActivateCondition === GREATER_THAN
                    ? [GREATER_THAN]
                    : [LESS_THAN];
        } else if (sensorMode === SensorThreshold.DUAL_THRESHOLD) {
            if (!dualThresholdModeValidation()) return;
            ops =
                dualActivateCondition.toString() === OUT_OF_RANGE.toString()
                    ? OUT_OF_RANGE
                    : WITHIN_RANGE;

            vars_ = [
                mqttString,
                reformattedNumberString(dualMinValue),
                mqttString,
                reformattedNumberString(dualMaxValue),
            ];
        }

        const repeat =
            alertMode === ALERT_CONTINUOUSLY
                ? alertPeriod
                : AlertPeriod.ALERT_ONCE_MAX;
        const body = {
            for_: 1,
            name: editableSensorDetails.ldsu_name,
            vars_,
            repeat,
            enabled: !isAlertEnabled() ? false : currEnabledStatus,
            ops,
            interval: 5,
        };
        return body;
    };

    const updateNewStatus = async (changeTo?: AlertStatus) => {
        setConfigureModalShow(false);
        let body: any = getAlertBody();
        const alertEnabled = changeTo === "enabled";

        if (!body || !changeTo) return;

        body = { ...body, enabled: alertEnabled };

        const response = await updateAlertNotification(
            params.gatewayId,
            params.ldsuId,
            params.said,
            alertUUID,
            body
        );

        if (isHttpSuccess(response.status)) {
            showSuccessAlert({
                message: `Alert has been ${changeTo}. It will take up to 2 minutes for the operation to take effect.`,
            });
            goToSensorListPage();
            setCurrEnabledStatus(alertEnabled);
        } else {
            const message = get(
                response,
                "data.description",
                "Alert cannot be updated. Please try again later."
            );
            showErrorAlert({
                message,
            });
        }
    };

    // RENDERS
    const renderNonBinarySensors = () => {
        return (
            <>
                <Form.Label className="mt-3 mb-3">Mode</Form.Label>
                <Form.Row className="sensor-mode">
                    <Col md={3}>
                        <Form.Check
                            name="mode"
                            id={"single"}
                            custom
                            type="radio"
                            value={SensorThreshold.SINGLE_THRESHOLD}
                            checked={
                                sensorMode === SensorThreshold.SINGLE_THRESHOLD
                            }
                            onChange={(e: any) => {
                                setSensorMode(e.target.value);
                                setThresholdError(false);
                            }}
                            label={
                                <span className="ml-2">Single Threshold</span>
                            }
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Check
                            name="mode"
                            id={"dual"}
                            custom
                            type="radio"
                            value={SensorThreshold.DUAL_THRESHOLD}
                            checked={
                                sensorMode === SensorThreshold.DUAL_THRESHOLD
                            }
                            onChange={(e: any) => {
                                setSensorMode(e.target.value);
                                setThresholdError(false);
                            }}
                            label={<span className="ml-2">Dual Threshold</span>}
                        />
                    </Col>
                </Form.Row>

                {sensorMode === SensorThreshold.SINGLE_THRESHOLD && (
                    <React.Fragment>
                        <Form.Row className="mt-4 mb-3">
                            <Col md={2}>
                                <Form.Label>Threshold</Form.Label>
                            </Col>
                            <Col md={10}>
                                <FormDeviceValueControl
                                    value={singleThresholdValue}
                                    {...{
                                        format,
                                        accuracy: accuracyException,
                                        min,
                                        max,
                                    }}
                                    aria-label="threshold"
                                    onChange={(e) => {
                                        setSingleThresholdValue(e.target.value);
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col md={2}>
                                <Form.Label>Activate when</Form.Label>
                            </Col>
                            <Col md={10}>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={singleActivateCondition}
                                    onChange={(e: any) => {
                                        setSingleActivateCondition(
                                            e.target.value
                                        );
                                    }}
                                >
                                    <option value={GREATER_THAN}>
                                        Greater than
                                    </option>
                                    <option value={LESS_THAN}>Less than</option>
                                </Form.Control>
                            </Col>
                        </Form.Row>
                    </React.Fragment>
                )}

                {sensorMode === SensorThreshold.DUAL_THRESHOLD && (
                    <>
                        <Form.Row className="mt-4 mb-3">
                            <Col md={2}>
                                <Form.Label>Minimum Limit</Form.Label>
                            </Col>
                            <Col md={10}>
                                <FormDeviceValueControl
                                    {...{
                                        format,
                                        accuracy: accuracyException,
                                        min,
                                        max,
                                    }}
                                    aria-label="min-limit"
                                    value={dualMinValue}
                                    onChange={(e) => {
                                        setDualMinValue(e.target.value);
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                            <Col md={2}>
                                <Form.Label>Maximum Limit</Form.Label>
                            </Col>
                            <Col md={10}>
                                <FormDeviceValueControl
                                    {...{
                                        format,
                                        accuracy: accuracyException,
                                        min,
                                        max,
                                    }}
                                    aria-label="max-limit"
                                    value={dualMaxValue}
                                    onChange={(e) => {
                                        setDualMaxValue(e.target.value);
                                    }}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                            <Col md={2}>
                                <Form.Label>Activate when</Form.Label>
                            </Col>
                            <Col md={10}>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={dualActivateCondition}
                                    onChange={(e: any) => {
                                        setDualActivateCondition(
                                            e.target.value.split(",")
                                        );
                                    }}
                                >
                                    <option value={OUT_OF_RANGE}>
                                        Out of range
                                    </option>
                                    <option value={WITHIN_RANGE}>
                                        Within range
                                    </option>
                                </Form.Control>
                            </Col>
                        </Form.Row>
                    </>
                )}

                {thresholdError && (
                    <p role="alert" className="text-danger">
                        {thresholdErrorMessage}
                    </p>
                )}
            </>
        );
    };

    const renderBinarySensors = () => {
        return (
            <Form.Row>
                <Col md={2}>
                    <Form.Label>Activate when</Form.Label>
                </Col>
                <Col md={10}>
                    <Form.Control
                        as="select"
                        custom
                        aria-label="binary"
                        value={binaryValue}
                        onChange={(e: any) => {
                            setBinaryValue(e.target.value);
                        }}
                    >
                        <option value="0">0</option>
                        <option value="1">1</option>
                    </Form.Control>
                </Col>
            </Form.Row>
        );
    };

    return (
        <ContentWrapper
            isLoading={isPageLoading}
            isForbiddenResource={isForbiddenResource}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12} className="sensor-header">
                            <h5 className="page-title">
                                {editableSensorDetails.sensor_name}
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <li className="breadcrumb-item">
                                    <Link
                                        to={{
                                            pathname: `/gateway-details/${params.gatewayId}`,
                                            state: {
                                                gatewayName:
                                                    gatewayName ||
                                                    editableSensorDetails.gateway_name,
                                            },
                                        }}
                                    >
                                        Gateway
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link
                                        to={{
                                            pathname: `/gateway-details/${params.gatewayId}/LDSBus/${params.busNumber}`,
                                            state: {
                                                gatewayName:
                                                    gatewayName ||
                                                    editableSensorDetails.gateway_name,
                                                portNumber:
                                                    portNumber ||
                                                    editableSensorDetails.port,
                                                panel: LDSUTabPanel.Sensor,
                                            },
                                        }}
                                    >
                                        All Sensors
                                    </Link>
                                </li>
                                <Breadcrumb.Item active>Sensor</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row className="flex-row-reverse">
                        <Col lg={4} xs={12}>
                            <div className="sensor-summary d-flex justify-content-between">
                                <div className="sensor-info">
                                    <p className="w-100 mt-0 ml-0">
                                        {editableSensorDetails.sensor_type}
                                    </p>
                                    <div className="w-100 d-inline-block">
                                        <h2 className="d-inline-block mr-3">
                                            {editableSensorDetails.readings
                                                ? formatReading(
                                                      editableSensorDetails
                                                          .readings.value,
                                                      accuracy
                                                  ) +
                                                  renderUnits(
                                                      editableSensorDetails.unit
                                                  )
                                                : "-"}
                                        </h2>
                                        <BlueText>Min</BlueText>{" "}
                                        <span>
                                            {editableSensorDetails.readings
                                                ? formatReading(
                                                      editableSensorDetails
                                                          .readings.lowest,
                                                      accuracy
                                                  ) +
                                                  renderUnits(
                                                      editableSensorDetails.unit
                                                  )
                                                : "-"}
                                        </span>
                                        <BlueText className="ml-2">
                                            Max
                                        </BlueText>{" "}
                                        <span>
                                            {editableSensorDetails.readings
                                                ? formatReading(
                                                      editableSensorDetails
                                                          .readings.highest,
                                                      accuracy
                                                  ) +
                                                  renderUnits(
                                                      editableSensorDetails.unit
                                                  )
                                                : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div className="last-active float-right text-right mt-3">
                                    <p>Last active</p>
                                    <p>
                                        <span>
                                            <img
                                                className={
                                                    isRefreshing
                                                        ? "mr-2 bigRefresh refresh-animation"
                                                        : "mr-2 bigRefresh"
                                                }
                                                src={refreshIcon}
                                                onClick={refreshThisSensor}
                                                onKeyDown={refreshThisSensor}
                                                alt="refresh"
                                            ></img>
                                            <span className="last-active-date-time">
                                                {renderDateTime(
                                                    editableSensorDetails.readings
                                                )}
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="form-box mt-2">
                                <Form.Row>
                                    <Col md="12">
                                        <Form.Label>Sensor Name</Form.Label>
                                    </Col>
                                    <Col lg={8} xs={12} className="mb-2">
                                        <Form.Group className="mb-0">
                                            <Form.Control
                                                type="text"
                                                required
                                                aria-label="name"
                                                disabled={!isEditing}
                                                value={editableSensorName}
                                                onChange={(e) => {
                                                    setEditableSensorName(
                                                        e.target.value
                                                    );
                                                }}
                                                onKeyPress={(
                                                    e: KeyboardEvent<HTMLInputElement>
                                                ) => {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        saveSensorName();
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <HoverAuthorizeTooltip
                                            permission="gateway:update"
                                            className="w-100"
                                        >
                                            {!isEditing ? (
                                                <Button
                                                    type="button"
                                                    className="w-100"
                                                    onClick={() => {
                                                        setIsEditing(true);
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    className="w-100"
                                                    onClick={saveSensorName}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        </HoverAuthorizeTooltip>
                                    </Col>
                                </Form.Row>
                            </div>
                            <div className="form-box mt-2 pb-5">
                                <Form.Row>
                                    <Col md="12">
                                        <div className="descriptor-item">
                                            <h6 className="float-left">Type</h6>
                                            <h6 className="ginfo float-right">
                                                {
                                                    editableSensorDetails.sensor_type
                                                }
                                            </h6>
                                        </div>
                                        {/* Start: IO controller UI */}
                                        {sensorDetails.app_id ? (
                                            <div className="descriptor-item">
                                                <h6 className="float-left">
                                                    IO Controller App ID
                                                </h6>
                                                <h6 className="ginfo float-right">
                                                    {sensorDetails.app_id}
                                                </h6>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* End: IO controller UI */}
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                LDSU Name
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {
                                                    editableSensorDetails.ldsu_name
                                                }
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                LDSU ID
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {
                                                    editableSensorDetails.ldsu_uuid
                                                }
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                LDSU Bus Port
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {portNumber ||
                                                    editableSensorDetails.port}
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                Gateway Name
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {gatewayName ||
                                                    editableSensorDetails.gateway_name}
                                            </h6>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </div>
                            <div className="sensor-association-box">
                                {
                                    // only Salinity, ph, EC and DO, ORP will have this feature

                                    SensorAssociationCLS.includes(
                                        editableSensorDetails.cls
                                    ) &&
                                    details?.sensorDetails
                                        ?.associated_sensors ? (
                                        <SensorAssociation
                                            associatedSensors={
                                                details?.sensorDetails
                                                    ?.associated_sensors
                                            }
                                            gatewayName={gatewayName}
                                            postIsLoading={setIsLoading}
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                            </div>
                            <div className="form-box download-sensor-data mt-2 pb-5">
                                <h5>Download sensor data</h5>
                                <DownloadDataUI
                                    gatewayId={params.gatewayId}
                                    ldsuId={params.ldsuId}
                                    said={params.said}
                                    downloadType={DOWNLOAD_SENSOR}
                                />
                            </div>
                        </Col>
                        <Col lg={8} xs={12}>
                            {userIsNotSubscribed && (
                                <WalletAlertComponent showWarningWhen="Unsubscribed" />
                            )}

                            <Form>
                                <fieldset disabled={userIsNotSubscribed}>
                                    <div className="form-box-head pb-4">
                                        <h5 className="float-left">
                                            Configuration
                                        </h5>
                                    </div>

                                    <div className="form-box">
                                        {editableSensorDetails.format ===
                                        SensorDataType.Boolean
                                            ? renderBinarySensors()
                                            : renderNonBinarySensors()}

                                        <hr />

                                        <Form.Label className="mt-3 mb-3">
                                            Activation Alert Options{" "}
                                        </Form.Label>
                                        <Form.Row className="alert-once-continuous">
                                            <Col md={2}>
                                                <Form.Check
                                                    name="alert"
                                                    id={"once"}
                                                    custom
                                                    type="radio"
                                                    value={ALERT_ONCE}
                                                    checked={
                                                        alertMode === ALERT_ONCE
                                                    }
                                                    onChange={(e: any) => {
                                                        setAlertMode(
                                                            e.target.value
                                                        );
                                                    }}
                                                    label={
                                                        <span className="ml-2">
                                                            Once
                                                        </span>
                                                    }
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Check
                                                    name="alert"
                                                    id={"continuously"}
                                                    custom
                                                    type="radio"
                                                    value={ALERT_CONTINUOUSLY}
                                                    checked={
                                                        alertMode ===
                                                        ALERT_CONTINUOUSLY
                                                    }
                                                    onChange={(e: any) => {
                                                        setAlertMode(
                                                            e.target.value
                                                        );
                                                    }}
                                                    label={
                                                        <span className="ml-2">
                                                            Continuously
                                                        </span>
                                                    }
                                                />
                                            </Col>
                                        </Form.Row>
                                        {alertMode === ALERT_CONTINUOUSLY ? (
                                            <Form.Row className="mt-4 mb-4 continuous-alert-option-label">
                                                <Col
                                                    md={{
                                                        span: 2,
                                                        order: 1,
                                                    }}
                                                    xs={{
                                                        span: 12,
                                                        order: 1,
                                                    }}
                                                    className="continuous-alert-option-label"
                                                >
                                                    <Form.Label>
                                                        Send Activation Alert
                                                        Every
                                                    </Form.Label>
                                                </Col>
                                                <Col
                                                    md={{
                                                        span: 8,
                                                        order: 2,
                                                    }}
                                                    xs={{
                                                        span: 12,
                                                        order: 3,
                                                    }}
                                                >
                                                    <Row>
                                                        <Col xs={{ span: 8 }}>
                                                            <FormControl
                                                                type="number"
                                                                value={
                                                                    alertPeriod
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setAlertPeriod(
                                                                        Number(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    );
                                                                    alertPeriodValidate(
                                                                        Number(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs={{ span: 4 }}
                                                            className="continuous-alert-option-label"
                                                        >
                                                            Second(s)
                                                        </Col>
                                                    </Row>
                                                    {periodError ? (
                                                        <p className="text-danger">
                                                            {periodErrorMessage}
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                            </Form.Row>
                                        ) : (
                                            ""
                                        )}
                                        <hr />
                                        <Form.Label className="mt-3 mb-3">
                                            Message
                                        </Form.Label>
                                        <Form.Row>
                                            <Col md xs={12} className="mb-4">
                                                <Form.Check
                                                    id={"activation"}
                                                    custom
                                                    type="checkbox"
                                                    checked={
                                                        activationCheckboxState
                                                    }
                                                    onChange={
                                                        onChangeActivateCheckbox
                                                    }
                                                    label={
                                                        <span className="ml-2">
                                                            Message on
                                                            activation
                                                        </span>
                                                    }
                                                    className="mb-3"
                                                />
                                                <Form.Control
                                                    className="mb-2"
                                                    type="text"
                                                    aria-label="activate-subject"
                                                    placeholder="Type subject header here"
                                                    value={
                                                        activateHeaderContent
                                                    }
                                                    onChange={(e) => {
                                                        setActivateHeaderContent(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Type message here"
                                                    aria-label="activate-message"
                                                    rows={4}
                                                    value={
                                                        activateMessageContent
                                                    }
                                                    onChange={(e) => {
                                                        setActivateMessageContent(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col md xs={12} className="mb-4">
                                                <Form.Check
                                                    id={"deactivation"}
                                                    custom
                                                    type="checkbox"
                                                    checked={
                                                        deactivationCheckboxState
                                                    }
                                                    onChange={
                                                        onChangeDeactivateCheckbox
                                                    }
                                                    label={
                                                        <span className="ml-2">
                                                            Message on
                                                            deactivation
                                                        </span>
                                                    }
                                                    className="mb-3"
                                                />
                                                <Form.Control
                                                    className="mb-2"
                                                    type="text"
                                                    aria-label="deactivate-subject"
                                                    placeholder="Type subject header here"
                                                    value={
                                                        deactivateHeaderContent
                                                    }
                                                    onChange={(e) => {
                                                        setDeactivateHeaderContent(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Type message here"
                                                    aria-label="deactivate-message"
                                                    value={
                                                        deactivateMessageContent
                                                    }
                                                    onChange={(e) => {
                                                        setDeactivateMessageContent(
                                                            e.target.value
                                                        );
                                                    }}
                                                    rows={4}
                                                />
                                            </Col>
                                        </Form.Row>

                                        <hr />

                                        {alertConfig && (
                                            <AlertNoticeConfig
                                                config={alertConfig}
                                                initialRecipients={
                                                    initialMemberList
                                                }
                                                onChange={onAlertConfigChange}
                                                disabled={isAlertDisabled}
                                            />
                                        )}
                                    </div>
                                    <div className="form-box-footer">
                                        <HoverAuthorizeTooltip
                                            permission="gateway:update"
                                            className="float-left"
                                        >
                                            <Button
                                                className="save-btn"
                                                variant="primary"
                                                onClick={
                                                    confirmWithoutAlertMethod
                                                }
                                                disabled={
                                                    alertMode ===
                                                        ALERT_CONTINUOUSLY &&
                                                    periodError
                                                }
                                            >
                                                SAVE
                                            </Button>
                                        </HoverAuthorizeTooltip>
                                    </div>
                                </fieldset>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
            <DefaultModal
                modalShow={showConfirmNoAlertMethod}
                setModalShow={setShowConfirmNoAlertMethod}
                modalType="cancelConfirm"
                modalIcon="help_outline"
                modalContent={
                    "Do you want to save the configuration without enabling any alert methods (Email, SMS, Push Notification)?"
                }
                modalTitle={"No Alert Methods Selected"}
                resendFunction={saveAlertConfig}
            />
            <Modal
                centered
                show={configureModalShow}
                onHide={() => setConfigureModalShow(false)}
                backdrop="static"
                keyboard={false}
                className="border-0"
            >
                <img
                    className={
                        isLoading === true
                            ? "loader-gif d-block"
                            : "loader-gif d-none"
                    }
                    src={ripple}
                    alt="loading"
                />
                <Modal.Body
                    className={
                        isLoading === true
                            ? "alert-modal text-center d-none"
                            : "alert-modal text-center d-block"
                    }
                >
                    <div>
                        <div className="modal-icon-box">
                            <span className="material-icons">help_outline</span>
                        </div>
                        <h3 className="mb-3">Enable Alert?</h3>
                        <p className="mb-4">
                            Do you want to enable the alert now?
                        </p>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                updateNewStatus();
                                goToSensorListPage();
                            }}
                        >
                            NOT NOW
                        </Button>
                        <Button
                            variant="primary"
                            className="ml-2"
                            onClick={() => updateNewStatus("enabled")}
                        >
                            ENABLE
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </ContentWrapper>
    );
};

export default SensorDetails;
