import { useState, useEffect } from "react";
import { Col, Button, Form, Modal, Spinner } from "react-bootstrap";

import authService from "service/authenticationService";

import { isHttpSuccess, renderCountdown } from "utils/functions";
import {
    ACTION_FAIL_MESSAGE,
    Patterns,
    RESEND_VERIFY_CODE_MESSAGE,
    SEND_VERIFY_CODE_SUCCESS_MESSAGE,
    SIGN_UP_VERIFY_CODE_MESSAGE,
    SecondsToCountdown,
} from "constant";
import { get } from "lodash";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";

type VerifyUserFormProps = {
    email: string;
    onSuccess: () => void;
};
const VerifyUserForm = ({ email, onSuccess }: VerifyUserFormProps) => {
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [isLoaded, setIsLoaded] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [verificationCode, setVerificationCode] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const MODAL_CONFIRM = "confirm";
    const MODAL_SUCCESS = "success";
    const MODAL_ERROR = "error";

    const [countdown, setCountdown] = useState(SecondsToCountdown);

    const handleSubmitCode = async () => {
        setIsLoading(true);
        const _verificationCode = verificationCode.trim();
        if (!Patterns.codePattern.test(_verificationCode)) {
            setErrorMessage(SIGN_UP_VERIFY_CODE_MESSAGE);
        } else {
            const response = await authService.submitSignUpVerificationCode({
                code: _verificationCode,
                email: email.toLowerCase(),
            });

            if (isHttpSuccess(response.status)) {
                onSuccess?.();
            } else {
                setErrorMessage(
                    get(
                        response,
                        "data.description",
                        SIGN_UP_VERIFY_CODE_MESSAGE
                    )
                );
            }
        }

        setIsLoading(false);
    };

    const handleResendCode = async () => {
        setIsLoaded(false);

        const response: any = await authService.resendConfirmationCode(
            email.toLowerCase()
        );
        if (isHttpSuccess(response.status)) {
            showSuccessAlert({
                title: `New Code Sent`,
                message: SEND_VERIFY_CODE_SUCCESS_MESSAGE,
            });
            setCountdown(SecondsToCountdown);
        } else {
            showErrorAlert(getAPIError(response, ACTION_FAIL_MESSAGE));
        }
        setIsLoaded(true);
    };

    const openConfirmationResendModal = () => {
        setModalShow(true);
        setModalType(MODAL_CONFIRM);
        setModalContent(RESEND_VERIFY_CODE_MESSAGE);
    };

    const closeModal = () => {
        setModalShow(false);
    };

    const onKeyDownHandler = (e: any) => {
        const keyCode = parseInt(e.which || e.keyCode);
        if (keyCode === 13) {
            e.preventDefault();
            handleSubmitCode();
        }
    };

    const renderModalIcon = () => {
        if (modalType === MODAL_CONFIRM) {
            return <span className="material-icons">forward_to_inbox</span>;
        } else if (modalType === MODAL_SUCCESS) {
            return <span className="material-icons">done</span>;
        }

        return <span className="material-icons">warning</span>;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_CONFIRM) {
            return <h3 className="mb-3">Confirmation</h3>;
        } else if (modalType === MODAL_SUCCESS) {
            return <h3 className="mb-3">New Code Sent</h3>;
        }

        return <h3 className="mb-3">Error</h3>;
    };

    const renderModalButton = () => {
        if (modalType === MODAL_CONFIRM) {
            return (
                <>
                    <Button variant="danger" onClick={closeModal}>
                        No
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setModalShow(false);
                            handleResendCode();
                        }}
                    >
                        Yes
                    </Button>
                </>
            );
        }

        return (
            <Button variant="primary" onClick={() => setModalShow(false)}>
                OK
            </Button>
        );
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [countdown, setCountdown]);

    return (
        <Col lg="6" sm="12" className="login-form">
            <div className="login-box">
                <div className="login-table">
                    <div className="login-text">
                        <h4>Verify your Account</h4>
                    </div>
                    <div className="mt-5 mb-5">
                        Please enter the verification code that is sent to your
                        email address.
                    </div>
                    <Form onKeyDown={onKeyDownHandler}>
                        <Form.Group>
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control
                                type="text"
                                aria-label="verification-code"
                                maxLength={6}
                                pattern="\d{6}"
                                onChange={(e) =>
                                    setVerificationCode(e.target.value)
                                }
                                required
                            />
                        </Form.Group>

                        {isLoading ? (
                            <Button variant="primary">Loading</Button>
                        ) : (
                            <Button
                                variant="primary"
                                onClick={handleSubmitCode}
                            >
                                VERIFY
                            </Button>
                        )}
                        <div className="loginf">
                            {errorMessage && (
                                <p aria-label="errorMessage" role="alert">
                                    {errorMessage}
                                </p>
                            )}
                        </div>
                    </Form>

                    <div className="mt-5">
                        Didn’t get code?{" "}
                        {countdown > 0 ? (
                            <span className="pl-1 green">
                                {renderCountdown(countdown)}
                            </span>
                        ) : (
                            <a
                                href="/#"
                                className="link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    openConfirmationResendModal();
                                }}
                            >
                                Resend code
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                centered
                show={modalShow}
                onHide={closeModal}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    modalType !== MODAL_ERROR ? "primary" : "danger"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box ">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>

                    {renderModalButton()}
                </Modal.Body>
            </Modal>
            <Modal
                centered
                show={!isLoaded}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-5 mr-4">
                    <Spinner
                        className="centered-spinner"
                        animation="border"
                        variant="primary"
                    />
                </Modal.Body>
            </Modal>
        </Col>
    );
};

export default VerifyUserForm;
