import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { EditDropdownLink } from "components/dashboard/update";
import { DownloadChartModal } from "components/modals/DownloadChartModal";
import DefaultModal from "components/modals/ModalTemplate";
import { CHART_DELETE_SUCCESS_MESSAGE, DELETE_CHART_MESSAGE } from "constant";
import { useCallback, useState } from "react";
import { Dropdown } from "react-bootstrap";
import dashboardService from "service/dashboardService";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { isHttpSuccess } from "utils/functions";

type DeletePanelType = {
    selectedDashboardUUID: string;
    panelId: string;
    onDelete: (selectedDashboardUUID: string) => void;
    setShowDeleteModal: (boolean: boolean) => void;
};

const handleDeletePanel = async ({
    selectedDashboardUUID,
    panelId,
    onDelete,
    setShowDeleteModal,
}: DeletePanelType) => {
    try {
        const deletePanelRes = await dashboardService.deletePanel(
            selectedDashboardUUID,
            panelId
        );
        if (isHttpSuccess(deletePanelRes.status)) {
            onDelete(selectedDashboardUUID);
            setShowDeleteModal(false);
            showSuccessAlert({
                message: CHART_DELETE_SUCCESS_MESSAGE,
            });
        } else {
            showErrorAlert(
                getAPIError(
                    deletePanelRes,
                    "Unable to delete panel. Please try again."
                )
            );
        }
    } catch (err) {
        console.error(err);
    }
};

const ChartActionDropdown = ({
    panelId,
    selectedDashboardUUID,
    onDelete,
    panel,
    deviceConfig,
}: any) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const closeDownloadModal = useCallback(() => {
        setShowDownloadModal(false);
    }, []);

    return (
        <>
            <Dropdown alignRight>
                <Dropdown.Toggle variant="" className="more-icon">
                    <i className="material-icons">more_vert</i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <HoverAuthorizeTooltip permission="dashboard:update">
                        <EditDropdownLink panelId={panelId} />
                    </HoverAuthorizeTooltip>
                    <HoverAuthorizeTooltip permission="dashboard:delete">
                        <Dropdown.Item
                            onClick={() => {
                                setShowDeleteModal(true);
                            }}
                        >
                            Delete
                        </Dropdown.Item>
                    </HoverAuthorizeTooltip>
                    {deviceConfig && (
                        <HoverAuthorizeTooltip permission="dashboard:update">
                            <Dropdown.Item
                                onClick={() => {
                                    setShowDownloadModal(true);
                                }}
                            >
                                Download Data
                            </Dropdown.Item>
                        </HoverAuthorizeTooltip>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <DefaultModal
                modalShow={showDeleteModal}
                setModalShow={setShowDeleteModal}
                modalType="dlt"
                modalContent={DELETE_CHART_MESSAGE()}
                deleteFunction={() => {
                    handleDeletePanel({
                        selectedDashboardUUID,
                        panelId,
                        onDelete,
                        setShowDeleteModal,
                    });
                }}
            />
            <DownloadChartModal
                show={showDownloadModal}
                panel={panel}
                deviceConfig={deviceConfig}
                onClose={closeDownloadModal}
            />
        </>
    );
};

export default ChartActionDropdown;
