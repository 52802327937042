import { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { styled } from "@mui/system";
import styledConst from "styles";

import { updateGatewayGroupFirmware } from "service/gatewayService";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import UpdateTabVersionSelected from "../gateways/UpdateTabVersionSelected";
import { fetchGWStatuses } from "utils/gatewayFunctions";
import { isHttpSuccess } from "utils/functions";
import DefaultModal from "components/modals/ModalTemplate";
import { showInfoAlert } from "utils/alert";

const StyledContainer = styled(ContentWrapper)``;
const StyledModalSelected = styled(Modal)`
    .title {
        color: ${styledConst.thirdTextColor};
    }

    .versions-zone {
        max-height: 300px;
        display: inline-block;
        overflow-y: scroll;

        .custom-control {
            width: 40%;
        }
    }
`;

function GroupsListFirmwareUpdateModal(props: any) {
    const {
        isVisible = false,
        selectedIds = [],
        onboardedSelectedGatewayIds = [],
        setSelectedIds,
        setVisible,
        groupId,
    } = props;
    const [versionSelected, setVersionSelected] = useState<null | string>(null);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const notOnboardedSelectedGatewayIds = selectedIds.filter((sI: string) => {
        return !onboardedSelectedGatewayIds.includes(sI);
    });

    const handleUpdateFirmware = async () => {
        if (versionSelected) {
            setConfirmationModal(false);

            const [statuses, updatedResult] = await Promise.all([
                fetchGWStatuses(onboardedSelectedGatewayIds),
                updateGatewayGroupFirmware(groupId, {
                    version: versionSelected,
                    selected: selectedIds,
                }),
            ]);

            if (isHttpSuccess(updatedResult.status)) {
                setSelectedIds?.([]);
                const isAllOffline = statuses.every((item) => item === false);
                const isAllOnline = statuses.every((item) => item === true);
                let modalOptions = {};

                if (notOnboardedSelectedGatewayIds.length) {
                    showInfoAlert({
                        message: (
                            <div>
                                Please note that firmware update will not be
                                performed on not on-boarded Gateway(s).{" "}
                                <div className="mt-1 font-weight-bold">
                                    Not on-boarded Gateway(s):{" "}
                                    {notOnboardedSelectedGatewayIds
                                        .toString()
                                        .replaceAll(",", ", ")}
                                </div>
                            </div>
                        ),
                    });
                }

                if (onboardedSelectedGatewayIds.length) {
                    if (isAllOffline) {
                        modalOptions = {
                            type: "error",
                            subTitle: "Pending",
                            body: "All gateways are offline. A request to update the firmware will be made when the gateways go online.",
                            icon: "history_toggle_off",
                        };
                    } else if (isAllOnline) {
                        modalOptions = {
                            type: "success",
                            subTitle: "Success",
                            body: "Successfully requested to update firmware for all gateways. Firmware update may take some time to complete.",
                            icon: "done",
                        };
                    } else {
                        modalOptions = {
                            type: "success",
                            subTitle: "Success",
                            body: "Successfully requested to update firmware for online gateways. Firmware update may take some time to complete. For offline gateways, a request to update the firmware will be made when the gateways go online.",
                            icon: "done",
                        };
                    }
                }

                setSimpleModalData(modalOptions as SimpleModalDataType);
            } else {
                setSimpleModalData({
                    resObj: updatedResult,
                } as SimpleModalDataType);
            }
        }
    };

    return (
        <StyledContainer simpleModalData={simpleModalData}>
            <StyledModalSelected
                show={isVisible}
                onHide={() => setVisible(false)}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className="mb-3 title">Available Updates</h6>
                    <div className="versions-zone">
                        <UpdateTabVersionSelected
                            defaultVersion={undefined}
                            curVersion={versionSelected}
                            setVersion={setVersionSelected}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={!versionSelected}
                        variant="primary"
                        onClick={() => {
                            setVisible(false);
                            setConfirmationModal(true);
                        }}
                    >
                        UPDATE
                    </Button>
                </Modal.Footer>
            </StyledModalSelected>
            <DefaultModal
                modalShow={confirmationModal}
                setModalShow={setConfirmationModal}
                modalType="custom"
                modalIcon="help_outline"
                modalTitle="Confirm Update"
                modalContent="Do you want to update now?"
                modalButton={() => {
                    return (
                        <>
                            <Button
                                variant="secondary"
                                onClick={() => setConfirmationModal(false)}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleUpdateFirmware}
                            >
                                UPDATE
                            </Button>
                        </>
                    );
                }}
            />
        </StyledContainer>
    );
}

GroupsListFirmwareUpdateModal.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isVisible: PropTypes.bool,
    setVisible: PropTypes.func.isRequired,
    groupId: PropTypes.string.isRequired,
};

export default GroupsListFirmwareUpdateModal;
