import { Button, FormControl, Form, Modal } from "react-bootstrap";
import { MemberDto, RecipientMode } from "types";
import { getMemberName } from "utils/organisationFunction";
import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

type SelectRecipientModalProps = {
    show: boolean;
    onClose?: () => void;
    initialMemberList: MemberDto[];
    recipientMode: RecipientMode;
    emailList?: MemberDto[];
    setEmailList?: any;
    notificationList?: MemberDto[];
    setNotificationList?: any;
    smsList?: MemberDto[];
    setSMSList?: any;
};

const SelectRecipientModal = (props: SelectRecipientModalProps) => {
    const {
        show,
        recipientMode,
        emailList = [],
        setEmailList,
        notificationList = [],
        setNotificationList,
        smsList = [],
        setSMSList,
        initialMemberList = [],
        onClose,
    } = props;

    const [selectedList, setSelectedList] = useState<MemberDto[]>([]);
    const [memberList, setMemberList] =
        useState<MemberDto[]>(initialMemberList);
    const [showModal, setShowModal] = useState(false);
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setShowModal(show);
        setMemberList(initialMemberList);
        if(searchRef.current) {
            searchRef.current.value = "";
        }

    }, [show, setMemberList, initialMemberList]);

    const closeModal = () => {
        onClose && onClose();
    };

    const onChangeRadioButtonRecipientValue = (value: any) => {
        let cloned = [...selectedList];
        // Check if value exist
        if (cloned.find((i: any) => i.uuid === value.uuid)) {
            // if yes, remove it
            cloned = cloned.filter((i: any) => i.uuid !== value.uuid);
        } else {
            // if no, add it
            cloned.push(value);
        }
        setSelectedList(cloned);
    };

    const handleRecipientDone = () => {
        const selectedCloned = [...selectedList];
        if (recipientMode === "Email") {
            const cloned = [...emailList];
            selectedCloned.forEach((i: MemberDto) => {
                cloned.push(i);
            });
            setEmailList && setEmailList(cloned);
        }
        if (recipientMode === "Notification") {
            const cloned = [...notificationList];
            selectedCloned.forEach((i: MemberDto) => {
                cloned.push(i);
            });
            setNotificationList && setNotificationList(cloned);
        }
        if (recipientMode === "SMS") {
            const cloned = [...smsList];
            selectedCloned.forEach((i: MemberDto) => {
                cloned.push(i);
            });
            setSMSList && setSMSList(cloned);
        }
        setSelectedList([]);

        closeModal();
    };

    const isSelected = (org: MemberDto) => {
        return (
            selectedList.find((o: MemberDto) => org.uuid === o.uuid) !==
            undefined
        );
    };

    const renderRecipientList = () => {
        let recipientList: any[] = [];
        if (recipientMode === "Email") {
            // filter away already selected ids on the specific recipients
            const filtered = memberList.filter(
                (i) => !emailList.find((j) => j.uuid === i.uuid)
            );
            recipientList = filtered.map((item, i = 0) => {
                i++;
                return (
                    <div key={i} className="modal-option">
                        <Form.Check
                            name="selected-sensor"
                            onChange={() =>
                                onChangeRadioButtonRecipientValue(item)
                            }
                            custom
                            type="checkbox"
                            id={item.uuid}
                            checked={isSelected(item)}
                            label={
                                <div>
                                    <h4>{getMemberName(item)}</h4>{" "}
                                    <p>{item.username}</p>
                                </div>
                            }
                            className="float-left"
                        />
                    </div>
                );
            });
            return recipientList;
        }
        if (recipientMode === "Notification") {
            const filtered = memberList.filter(
                (i: MemberDto) =>
                    !notificationList.find((j: MemberDto) => j.uuid === i.uuid)
            );
            recipientList = filtered.map((item: MemberDto, i = 0) => {
                i++;
                return (
                    <div key={i} className="modal-option">
                        <Form.Check
                            name="selected-sensor"
                            onChange={() =>
                                onChangeRadioButtonRecipientValue(item)
                            }
                            custom
                            type="checkbox"
                            id={item.uuid}
                            checked={isSelected(item)}
                            label={
                                <div>
                                    <h4>{getMemberName(item)}</h4> <p>{item.username}</p>
                                </div>
                            }
                            className="float-left"
                        />
                    </div>
                );
            });
            return recipientList;
        }
        if (recipientMode === "SMS") {
            const filtered = memberList.filter(
                (i: MemberDto) =>
                    !smsList.find((j: MemberDto) => j.uuid === i.uuid) &&
                    i.phoneNumberVerified
            );
            recipientList = filtered.map((item: MemberDto, i = 0) => {
                i++;
                return (
                    <div key={i} className="modal-option">
                        <Form.Check
                            name="selected-sensor"
                            onChange={() =>
                                onChangeRadioButtonRecipientValue(item)
                            }
                            custom
                            type="checkbox"
                            id={item.uuid}
                            checked={isSelected(item)}
                            label={
                                <div>
                                    <h4>{getMemberName(item)}</h4>{" "}
                                    <p>{item.username}</p>
                                </div>
                            }
                            className="float-left"
                        />
                    </div>
                );
            });
            return recipientList;
        }
    };

    const onChangeSearchRecipient = debounce(
        (value: string) => {
            if (value !== "") {
                const cloned: any = [];
    
                for (const i of initialMemberList) {
                    if (
                        getMemberName(i).toLowerCase().includes(value.toLowerCase()) ||
                        i.username.toLowerCase().includes(value.toLowerCase())
                    ) {
                        cloned.push(i);
                    }
                }
                setMemberList(cloned);
            } else {
                setMemberList(initialMemberList);
            }
        },
        300
    );

    return (
        <Modal show={showModal} onHide={closeModal} animation={false} centered>
            <Modal.Header>
                <Modal.Title>Add {recipientMode} Recipient</Modal.Title>
                <Button
                    variant=""
                    className="close-button"
                    onClick={closeModal}
                >
                    <span className="material-icons">close</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FormControl
                        ref={searchRef}
                        type="text"
                        placeholder="Search.."
                        className="mb-4"
                        onChange={(e) =>
                            onChangeSearchRecipient(e.target.value)
                        }
                    ></FormControl>
                    <div className="scroll-list float-left">
                        {renderRecipientList()}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleRecipientDone()}>
                    DONE
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectRecipientModal;
