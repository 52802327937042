import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { addDevices } from "../../service/gatewayService";
import { Patterns, GATEWAY_NAME_MESSAGE } from "constant";
import { isHttpSuccess, isValidValueInForm } from "utils/functions";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { showSuccessAlert, showErrorAlert, getAPIError } from "utils/alert";

const formConfig: any = {
    uuid: { def: "", regex: Patterns.uuidPattern },
    serialNumber: { def: "", regex: Patterns.serialPattern },
    macAddress: { def: "", regex: Patterns.macAddressPattern },
    gatewayName: { def: "", regex: Patterns.gatewayNamePattern },
};

const defaultFormData = Object.keys(formConfig).reduce(
    (dict: any, k: string) => {
        dict[k] = formConfig[k].def;
        return dict;
    },
    {}
);

const AddGateWay = () => {
    const history = useHistory();
    const [uuid, setUuid] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [macAddress, setMacAddress] = useState("");
    const [gatewayName, setGatewayName] = useState("");
    const [validated, setValidated] = useState(false);

    const formChange = (e: ChangeEvent<HTMLFormElement>) => {
        const { name, value } = e.target;
        const _formData = {
            ...formData,
            [name]: value,
        };

        const invalid = Object.keys(_formData).some(
            (key) => !isValidText(key, _formData)
        );
        setValidated(!invalid);
    };
    const handleSaveGatewayToApi = async () => {
        const response: any = await addDevices(
            uuid.trim(),
            serialNumber.trim(),
            macAddress.trim(),
            gatewayName.trim()
        );

        if (isHttpSuccess(response.status)) {
            showSuccessAlert({
                message:
                    response.message ||
                    "New Gateway has been added successfully.",
            });
            history.push("gateways-all");
        } else {
            showErrorAlert({
                ...getAPIError(response, "Adding a new gateway has failed."),
            });
        }
    };
    const formData = {
        uuid,
        serialNumber,
        macAddress,
        gatewayName,
    };
    const isValidText = (
        path: string,
        data = formData,
        defaultData = defaultFormData
    ) => isValidValueInForm(path, data, defaultData, formConfig[path].regex);
    return (
        <ContentWrapper>
            <div className="page-content">
                <Container>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title">Add Gateway</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Form
                                className="d-inline-block w-100"
                                onChange={formChange}
                            >
                                <div className="form-box">
                                    <Form.Group className="mb-4">
                                        <Form.Label>UUID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="uuid"
                                            aria-label="uuid"
                                            isInvalid={!isValidText("uuid")}
                                            onChange={(e) =>
                                                setUuid(e.target.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Gateway UUID must be 19 characters
                                            long.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Serial Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="serialNumber"
                                            aria-label="serial-number"
                                            isInvalid={
                                                !isValidText("serialNumber")
                                            }
                                            onChange={(e) =>
                                                setSerialNumber(e.target.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Serial Number must be 8 characters
                                            long.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Ethernet MAC Address
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="macAddress"
                                            aria-label="mac-address"
                                            isInvalid={
                                                !isValidText("macAddress")
                                            }
                                            onChange={(e) =>
                                                setMacAddress(e.target.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Ethernet MAC Address must follow
                                            XX:XX:XX:XX:XX:XX.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Gateway Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="gatewayName"
                                            aria-label="name-gateway"
                                            isInvalid={
                                                !isValidText("gatewayName")
                                            }
                                            onChange={(e) =>
                                                setGatewayName(e.target.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {GATEWAY_NAME_MESSAGE}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Form>
                            <Button
                                variant="secondary"
                                className="mt-4 mr-2"
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="mt-4"
                                onClick={handleSaveGatewayToApi}
                                disabled={!validated}
                            >
                                SAVE
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default AddGateWay;
