import { useCallback, useEffect, useState } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { styled } from "@mui/system";
import { customMinMax } from "utils/functions";
import { Patterns } from "constant";

const StyledContainer = styled(Form.Group)`
    margin-bottom: 0;
    width: ${(props) => (props.width ? props.width : "100%")};
`;

interface FormDeviceValueControlProps extends FormControlProps {
    width?: string;
    accuracy?: number;
    min?: number;
    max?: number;
}

function FormDeviceValueControl(props: Readonly<FormDeviceValueControlProps>) {
    const { accuracy = 0, min, max, onChange, value, width } = props;
    const [errorMessage, setErrorMessage] = useState("");

    const { min: displayedMin, max: displayedMax } = customMinMax({
        min: Number(min),
        max: Number(max),
        acc: Number(accuracy),
    });

    const placeholder = `${displayedMin.toFixed(
        accuracy
    )} .. ${displayedMax.toFixed(accuracy)} [${accuracy}d.p]`;
    useEffect(() => {
        getExpectedValue(value?.toString() || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [min, max, accuracy, value]);
    const getExpectedValue = (value: string): string | number | null => {
        if (["-", ""].indexOf(value) >= 0 || value.match(/\.$/)) {
            setErrorMessage(`Value must be number`);
        } else if (value.isNumeric()) {
            if (
                ((displayedMax === 0 || !!displayedMax) &&
                    Number(value) > displayedMax) ||
                ((displayedMin === 0 || !!displayedMin) &&
                    Number(value) < displayedMin)
            ) {
                setErrorMessage(placeholder);
            } else {
                setErrorMessage(``);
            }

            value = value.toNumFixedDown(accuracy);
        } else {
            return null;
        }

        return value;
    };

    const handleFieldChangeValidation = useCallback(
        (e: any) => {
            const inputValue = e.target.value;
            const fixedValue = inputValue.toNumFixedDown(accuracy);
            if (
                inputValue !== fixedValue ||
                (inputValue && !Patterns.floatPattern.test(fixedValue))
            ) {
                e.preventDefault();
                return;
            }
            return onChange?.(e);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value, accuracy, onChange]
    );

    return (
        <StyledContainer width={width}>
            <Form.Control
                {...{
                    ...props,
                    placeholder,
                    onChange: handleFieldChangeValidation,
                    isInvalid: !!errorMessage,
                }}
            />
            <Form.Control.Feedback
                type="invalid"
                className={errorMessage && "invalid-input"}
            >
                {errorMessage}
            </Form.Control.Feedback>
        </StyledContainer>
    );
}

export default FormDeviceValueControl;
