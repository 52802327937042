import { Col, Form } from "react-bootstrap";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { LEVEL_MODE, LOOP_MODE, PULSE_MODE, PWM_MODE } from "constant";

const ModeOptions = () => {
    const {
        editedActuator,
        editedActuator: { mode },
        setEditedActuator,
        isIOControllerState,
    } = useActuatorConfigurationContext();

    const handleActuatorMode = (item: string) => {
        switch (item.toUpperCase()) {
            case LEVEL_MODE:
                const { timing, ...rest } = editedActuator;
                const updatedActuator = {
                    ...rest,
                    mode: LEVEL_MODE,
                };
                setEditedActuator(updatedActuator);
                break;
            case PULSE_MODE:
                setEditedActuator({
                    ...editedActuator,
                    timing: [[0, 0]],
                    mode: PULSE_MODE,
                });
                break;
            case LOOP_MODE:
                setEditedActuator({
                    ...editedActuator,
                    timing: [[0, 0]],
                    mode: LOOP_MODE,
                });
                break;
            case PWM_MODE:
                setEditedActuator({
                    ...editedActuator,
                    timing: [
                        [0, 0],
                        [0, 0],
                    ],

                    mode: PWM_MODE,
                });
                break;
        }
    };

    const modeList = () => {
        if (isIOControllerState.mode) {
            return isIOControllerState.mode;
        } else if (!isIOControllerState.isIOController) {
            return ["Level", "Pulse"];
        }
        return [];
    };
    return (
        <>
            {modeList().length > 0 && (
                <>
                    <Form.Label className="mt-2 mb-3">Mode</Form.Label>
                    <Form.Row className="sensor-mode mb-5">
                        {modeList().map((item: string) => (
                            <Col md={2} key={item}>
                                <Form.Check
                                    name="mode"
                                    id={item}
                                    checked={mode === item.toUpperCase()}
                                    custom
                                    type="radio"
                                    label={<span className="ml-2">{item}</span>}
                                    onChange={() => handleActuatorMode(item)}
                                />
                            </Col>
                        ))}
                    </Form.Row>
                </>
            )}
        </>
    );
};

export default ModeOptions;
