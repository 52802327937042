import { Action, ActionType } from "generated/models";
import { uniq } from "lodash";
import ActionEmail from "assets/svg/event-action-email.svg";
import ActionSMS from "assets/svg/event-action-sms.svg";
import ActionNotification from "assets/svg/event-action-notification.svg";
import ActionActuator from "assets/svg/event-action-actuator.svg";
import EvaluateIcon from "assets/svg/evaluate-backdrop.svg";

type ActionColProps = {
    actions: Action[];
};

const ActionCol = ({ actions }: ActionColProps) => {
    const actionTypeOrders: ActionType[] = [
        "EMAIL",
        "SMS",
        "PUSH",
        "VARIABLE",
        "ACTUATOR",
    ];

    const getEventActions = () => {
        const validActionTypes = actions
            .map(({ type_ }) => type_)
            .filter((type) => actionTypeOrders.includes(type))
            .sort((a, b) => {
                const indexA = actionTypeOrders.indexOf(a);
                const indexB = actionTypeOrders.indexOf(b);
                return indexA - indexB;
            });

        return uniq(validActionTypes);
    };

    const renderActionIcon = () => {
        const actionTypes = getEventActions();

        if (!actionTypes.length) {
            return <div className="text-secondary-red-1">No Actions added</div>;
        } else {
            return actionTypes.map((actionType: string) => {
                switch (actionType) {
                    case "EMAIL":
                        return (
                            <img
                                src={ActionEmail}
                                alt="action-email"
                                key={actionType}
                            />
                        );
                    case "SMS":
                        return (
                            <img
                                src={ActionSMS}
                                alt="action-sms"
                                key={actionType}
                            />
                        );
                    case "PUSH":
                        return (
                            <img
                                src={ActionNotification}
                                alt="action-notification"
                                key={actionType}
                            />
                        );
                    case "ACTUATOR":
                        return (
                            <img
                                src={ActionActuator}
                                alt="action-actuator"
                                key={actionType}
                            />
                        );
                    default:
                        return (
                            <img
                                src={EvaluateIcon}
                                alt="action-evaluate"
                                key={actionType}
                            />
                        );
                }
            });
        }
    };

    return <div className="associated-event-actions">{renderActionIcon()}</div>;
};

export default ActionCol;
