import { Condition } from "generated/models";

type ConditionColProps = {
    conditions: Condition[];
};

const ConditionCol = ({ conditions }: ConditionColProps) => {
    const conditionLength = conditions.length;
    if (!conditionLength) {
        return (
            <span className="text-secondary-red-1">No Conditions added</span>
        );
    } else if (conditionLength === 1) {
        return <span>Single Condition</span>;
    } else {
        const slots = conditions.reduce((acc, condition) => {
            acc[condition.slot] = (acc[condition.slot] || 0) + 1;
            return acc;
        }, [] as number[]);

        const hasMultipleConditionsInSlot = slots.some((count) => count > 1);
        const hasSingleConditionInAllSlots = slots.every(
            (count) => count === 1
        );

        if (hasMultipleConditionsInSlot && slots.length > 1) {
            return <span>AND and OR Conditions</span>;
        } else if (hasSingleConditionInAllSlots) {
            return <span>OR Conditions</span>;
        }

        return <span>AND Conditions</span>;
    }
};

export default ConditionCol;
