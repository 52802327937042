import { AxiosResponse } from "axios";
import { Variable, VariablePatch, VariablePost } from "generated/models/Event";
import {
    createErrorResponseStandard,
    createResponseStandard,
    orgId,
} from "utils/functions";
import { apiFactory } from "generated";
import AxiosInstance from "./axiosInstance";

class EventVariableService {
    private eventVariableClient = apiFactory.getEventsInstance(AxiosInstance);

    async readEventVariables() {
        try {
            const response: AxiosResponse =
                await this.eventVariableClient.getEventVariablesByOrganisationId(
                    {
                        organisation_uuid: orgId(),
                    }
                );
            return createResponseStandard<Variable[]>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async getEventVariableById(id: string) {
        try {
            const response: AxiosResponse =
                await this.eventVariableClient.getEventVariableById(id);
            return createResponseStandard<Variable>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async createEventVariable(eventVariable: VariablePost) {
        try {
            const response: AxiosResponse =
                await this.eventVariableClient.createEventVariable(
                    eventVariable
                );
            return createResponseStandard<Variable>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async updateEventVariable(id: string, payload: VariablePatch) {
        try {
            const response: AxiosResponse =
                await this.eventVariableClient.updateEventVariableById(
                    id,
                    payload
                );
            return createResponseStandard<Variable>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async deleteEventVariableById(id: string) {
        try {
            const response: AxiosResponse =
                await this.eventVariableClient.deleteEventVariableById(id);
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }
}

const eventVariableService = new EventVariableService();

export default eventVariableService;
